import React from 'react'
import GenericCard from '../components/GenericCard'
import GenericList from '../components/GenericList'
import GenericBreadCrumb from 'src/frontend/app/components/GenericBreadCrumb'
import '../../style/style.scss'
import add from '../../app/images/add.png'
import GenericSpinner from 'src/backoffice/app/components/GenericSpinner'
import {findSTPByPIva, getSoggettoByCf} from 'src/processes/Soggetto'
import GenericAlert from '../components/GenericAlert'
import {TokenStore} from 'src/keycloak/jwt/TokenStore'
import {
    getIscrizioneByCodiceFiscaleResponsabileStp,
    nuovaIscrizioneOConaf,
    nuovaIscrizioneOOT,
    nuovaIscrizioneOrdine,
    nuovaIscrizioneOSTP
} from 'src/processes/ProcessoIscrizione'
import {getTipologieIscrizione} from 'src/processes/TipologiaIscrizione'
import {
    PATH_TO_BO_CREAZIONE_RIVISTA,
    PATH_TO_BO_ENTE_ESTERNO,
    PATH_TO_FO_ANAG_ISCRIZIONE_OCONAF,
    PATH_TO_FO_ANAG_ISCRIZIONE_OF,
    PATH_TO_FO_ANAG_ISCRIZIONE_OOT,
    PATH_TO_FO_ANAG_ISCRIZIONE_ORDINE,
    PATH_TO_FO_ANAG_ISCRIZIONE_ORDINE_MODIFICA,
    PATH_TO_FO_ANAG_ISCRIZIONE_ORDINE_VISUALIZZA,
    PATH_TO_FO_ANAG_ISCRIZIONE_OSTP,
    PATH_TO_FO_ANAG_ISCRIZIONE_STP,
    PATH_TO_FO_ANAG_ISCRIZIONE_STP_MODIFICA,
    PATH_TO_FO_ANAG_ISCRIZIONE_STP_VISUALIZZA,
    PATH_TO_FO_ANAG_RICHIEDI_NUOVO_PROFILO,
    PATH_TO_FO_FORMAZIONE_ISCRIZIONE_AF,
    PATH_TO_FO_ISCRIZIONE_FORMATORE,
    RO_GEST_FORM_CONAF
} from 'src/app/utils/RoutesConstants'
import {goTo} from 'src/utilities/utility'
import {gestisciIscrizioneSTPMenuItem, isEnabledModificaOrdineFunction} from '../components/GenericHeaderNav'

const labelButton = { label: 'SELEZIONA', disabled: false }
const colorButton = 'primary'
const cardHeader = 'Seleziona il nuovo profilo'
const title = 'Richiedi nuovo profilo'
const breadcrumbOptions = [{ label: 'Richiedi nuovo profilo', link: PATH_TO_FO_ANAG_RICHIEDI_NUOVO_PROFILO }]

class RichiediNuovoProfilo extends React.Component<any, any> {
  constructor(props: any) {
    super(props)
    this.state = {
      tipologie: [],
      isLoaded: false,
      labelButton: [],
      dropdownList: [],
      alertMessage: '',
    }
  }

  async componentDidMount() {
    /** Recupero dati dal Token per inviare la richiesta per il processo di iscrizione */
    var fiscalNumber = TokenStore.getInstance().getDecodedTokenJWT().spid_fiscalNumber
    const spidData = TokenStore.getInstance().getSpidData()
    var specificGroup = Object.keys(TokenStore?.getInstance()?.getCurrentAccount()?.accounts)[0]
    var pIva = specificGroup.split('_')[1]

    if (!spidData && fiscalNumber) {
      ;(spidData as any).codiceFiscale = fiscalNumber
    }

    this.setState({
      spidData: {
        ...spidData,
        codiceFiscale: spidData.codiceFiscale ? spidData.codiceFiscale : fiscalNumber,
      },
    })

    //chiamata al servizio 'getSoggettoByCf' per recuperare le tipologie di iscrizione effettuate dall'utente loggato
    await getSoggettoByCf(spidData.codiceFiscale ? spidData.codiceFiscale : fiscalNumber)
      .then(async (response: any) => {
        var iscrizioniAttive: any = []
        response.data.map((soggetto) => {
          soggetto.elencoIscrizioni.map((iscrizione) => {
            return iscrizioniAttive.push({
              codiceTipologiaIscrizioni: iscrizione.tipologiaIscrizioni.codice,
              statoIscrizione: iscrizione.statoIscrizione,
            })
          })
        })
        //salvataggio nello stato dell'array di iscrizioni attive senza duplicati
        this.setState({ listaIscrizioniAttive: [...new Set(iscrizioniAttive)] })
      })
      .catch((err) => {
        throw new Error(err)
      })

    /**
     * se è presente un'STP recupero il cf del rappresentante legale e lo sostituisco al cf corrente
     */
    if (TokenStore.getInstance().isCurrentAccountSTP() && TokenStore.getInstance().isOperatoreResponsabileSTP()) {
      var soggettoSTP = await findSTPByPIva(pIva)
      if (soggettoSTP?.data) {
        fiscalNumber = soggettoSTP.data.codiceFiscaleResponsabileStp
      }
    }

    await getIscrizioneByCodiceFiscaleResponsabileStp(fiscalNumber)
      .then(async (response: any) => {
        var iscrizioniAttiveStp: any = []
        response.data.returnedObject?.elencoIscrizioni.map((iscrizione) => {
          return iscrizioniAttiveStp.push({
            codiceTipologiaIscrizioni: iscrizione.tipologiaIscrizioni.codice,
            statoIscrizione: iscrizione.statoIscrizione,
          })
        })
        //salvataggio nello stato dell'array di iscrizioni attive stp senza duplicati
        this.setState({ listaIscrizioniAttiveStp: [...new Set(iscrizioniAttiveStp)] })
      })
      .catch((err) => {
        //throw new Error(err)
      })

    //chiamata al servizio 'getTipologieIscrizione' per recuperare la lista di tutte le tipologie di iscrizione
    await getTipologieIscrizione().then((response: any) => {
      var tipologie_iscrizione = [{ data: [{}] }]

      response.data.map((tipologia) => {
        /**
         * Questo if è stato inserito durante i test in produzione perché questi pulsanti non sono attivi e non sono specificati nel documento
         * di specifica.
         */
        if (tipologia.codice !== 'operatore_fornitore_di_servizi' && tipologia.codice !== 'operatore_in_convenzione') {
          if (
            (this.state.listaIscrizioniAttive?.length > 0 && this.isIscrizioniOperatoreActive(tipologia) !== undefined) ||
            (this.state.listaIscrizioniAttiveStp?.length > 0 && this.isIscrizioneSTPActive(tipologia) !== undefined)
          ) {
            tipologie_iscrizione.push({ data: [{ label: tipologia['descrizione'], value: tipologia['codice'], isDisabled: true }] })
          } else {
            if (tipologia.codice === 'operatore_enti_esterni' && !TokenStore.getInstance().currentAccountHasRolesOR([RO_GEST_FORM_CONAF])) {
              tipologie_iscrizione.push({ data: [{ label: tipologia['descrizione'], value: tipologia['codice'], isDisabled: true }] })
            } else if (this.isIscrizioneValida(tipologia)) {
              tipologie_iscrizione.push({ data: [{ label: tipologia['descrizione'], value: tipologia['codice'], isDisabled: false, cancellato: true }] })
            } else {
              tipologie_iscrizione.push({ data: [{ label: tipologia['descrizione'], value: tipologia['codice'], isDisabled: false, cancellato: false }] })
            }
          }
        }
      })

      tipologie_iscrizione.splice(0, 1)
      tipologie_iscrizione = tipologie_iscrizione.sort((p1, p2) =>
        p1.data[0]['label'] > p2.data[0]['label'] ? 1 : p1.data[0]['label'] < p2.data[0]['label'] ? -1 : 0
      )

      this.setState({ tipologie: tipologie_iscrizione })
    })
    await this.setState({ isLoaded: true })

    this.setState({
      isRespPropriaSTP: await TokenStore.getInstance().isOperatoreResponsabilePropriaSTP(),
    })
  }

  isIscrizioneValida(tipologia) {
    return (
      this.state.listaIscrizioniAttive?.find(
        (element) => tipologia['codice'] === 'iscritto_all_ordine' && (element.statoIscrizione === 5 || element.statoIscrizione === 8)
      ) ||
      this.state.listaIscrizioniAttiveStp?.find(
        (element) => tipologia['codice'] === 'iscritto_stp' && (element.statoIscrizione === 5 || element.statoIscrizione === 8)
      )
    )
  }

  /**
   * Ritorna l'iscrizione secondo le condizioni stabilite nella find
   * @param tipologia
   * @returns
   */
  isIscrizioniOperatoreActive = (tipologia) => {
    return this.state.listaIscrizioniAttive.find(
      (element) =>
        element.codiceTipologiaIscrizioni === tipologia['codice'] &&
        tipologia['codice'] !== 'operatore_ordine_territoriale' &&
        tipologia['codice'] !== 'operatore_federazione' &&
        tipologia['codice'] !== 'operatore_stp' &&
        tipologia['codice'] !== 'operatore_enti_formazione' &&
        element.statoIscrizione !== 6 &&
        element.statoIscrizione !== 5 &&
        element.statoIscrizione !== 8
    )
  }

  /**
   * Ritorna l'iscrizione STP secondo le condizioni stabilite nella find
   * @param tipologia
   * @returns
   */
  isIscrizioneSTPActive = (tipologia) => {
    return this.state.listaIscrizioniAttiveStp.find(
      (element) =>
        element.codiceTipologiaIscrizioni === tipologia['codice'] &&
        element.statoIscrizione !== 6 &&
        element.statoIscrizione !== 5 &&
        element.statoIscrizione !== 8
    )
  }

  /**
   * Disabilita la voce "Modifica" della dropdown per l'iscrizione stp, se si trova un'iscrizione stp
   * @returns
   */
  isModificaSTPDisabled = (iscrizioniSTP) => {
    var isIscrizioneValida = iscrizioniSTP?.find(
      (iscrizione) => iscrizione.statoIscrizione === 2 || iscrizione.statoIscrizione === 5 || iscrizione.statoIscrizione === 8
    )
    if (
      iscrizioniSTP?.find(
        (iscrizione) => (iscrizione.codiceTipologiaIscrizioni === 'iscritto_stp' && iscrizione.statoIscrizione === 1) || iscrizione.statoIscrizione === 4
      )
    ) {
      return false
    } else if (isIscrizioneValida) return true
    else return false
  }

  /**
   * Gestisce l'abilitazione o la disabilitazione del tasto visualizza
   * @param categoria
   * @returns
   */
  manageIsVisualizzaDisabled = (categoria) => {
    if (
      categoria.value === 'operatore_conaf_nazionali' ||
      categoria.value === 'operatore_enti_formazione' ||
      categoria.value === 'operatore_riviste' ||
      categoria.value === 'operatore_enti_esterni' ||
      categoria.value === 'operatore_formatore'
    ) {
      return true
    } else if (
      (categoria.value === 'iscritto_all_ordine' || categoria.value === 'iscritto_stp') &&
      this.state.listaIscrizioniAttive?.find((elem) => elem.statoIscrizione === 5 || elem.statoIscrizione === 8 || elem.statoIscrizione === 2)
    ) {
      return false
    }
    return false
  }

  /**
   * Recupera i dati della riga selezionata tramite il click sul dropdown toggle e compone la dropdown list
   * @param categoria
   */
  selezionaIcona = (categoria: any) => {
    this.setState({
      dropdownList: [
        {
          title: 'Visualizza',
          onClick: (e) => this.gestisciPulsanti(true, categoria),
          itemKey: PATH_TO_FO_ANAG_ISCRIZIONE_ORDINE_VISUALIZZA,
          isDisabled: this.manageIsVisualizzaDisabled(categoria),
        },
        {
          title: 'Modifica',
          onClick: (e) => this.gestisciPulsanti(false, categoria),
          itemKey: PATH_TO_FO_ANAG_ISCRIZIONE_ORDINE_MODIFICA,
          isDisabled:
            (!isEnabledModificaOrdineFunction(this.state.listaIscrizioniAttive) && categoria.value === 'iscritto_all_ordine') ||
            (categoria.value === 'operatore_conaf_nazionali' && true) ||
            (this.isModificaSTPDisabled(this.state.listaIscrizioniAttiveStp) && categoria.value === 'iscritto_stp'),
        },
      ],
    })
    this.setState({ selectedRow: categoria })
  }

  /**
   * Gestisce il click dei pulsanti "Visualizza" e "Modifica" nella pagina "Richiedi nuovo profilo"
   * @param categoria
   * @param event
   */
  gestisciPulsanti = (isVisualizza: boolean, categoria: { value: string }) => {
    // Se ho cliccato per STP
    if (categoria.value === 'iscritto_stp') {
      !TokenStore.getInstance().isOperatoreResponsabileSTP()
        ? TokenStore.getInstance().setCurrentAccountAsAuthenticated()
        : TokenStore.getInstance().setCurrentAccountAsSTP()
      isVisualizza ? goTo(PATH_TO_FO_ANAG_ISCRIZIONE_STP_VISUALIZZA) : goTo(PATH_TO_FO_ANAG_ISCRIZIONE_STP_MODIFICA)
    }

    // Altrimenti se ho cliccato per ORDINE
    else if (categoria.value === 'iscritto_all_ordine') {
      !TokenStore.getInstance().isIscrittoOrdine()
        ? TokenStore.getInstance().setCurrentAccountAsAuthenticated()
        : TokenStore.getInstance().setCurrentAccountAsIscrittoOrdine()
      isVisualizza ? goTo(PATH_TO_FO_ANAG_ISCRIZIONE_ORDINE_VISUALIZZA) : goTo(PATH_TO_FO_ANAG_ISCRIZIONE_ORDINE_MODIFICA)
    }

    // Altrimenti errore
    else {
      throw new Error('Evento non risconosciuto')
    }
  }

  render() {
    const selezionaTipologia = async (categoria: any) => {
      let tipologiaIscrizione
      categoria = categoria.data[0]
      this.setState({ nuovaIscrizione: true })

      switch (categoria.value) {
        case 'iscritto_all_ordine':
          tipologiaIscrizione = 'iscritto_all_ordine'
          !TokenStore.getInstance().isIscrittoOrdine()
            ? TokenStore.getInstance().setCurrentAccountAsAuthenticated()
            : TokenStore.getInstance().setCurrentAccountAsIscrittoOrdine()


          //gestisciIscrizioneOrdineMenuItem(PATH_TO_FO_ANAG_ISCRIZIONE_ORDINE)
          nuovaIscrizioneOrdine(TokenStore.getInstance().getSpidData()).then(() => {
            goTo(PATH_TO_FO_ANAG_ISCRIZIONE_ORDINE);
        }).catch((err) => {
          setTimeout(() => {
            this.setState({ alertMessage: "C'è stato un errore durante il recupero dei dati: " + err, typeMessage: 'danger' })
        }, 1000)
      })
          break

        case 'iscritto_stp':
          tipologiaIscrizione = 'iscritto_stp'
          !this.state.isRespPropriaSTP ? TokenStore.getInstance().setCurrentAccountAsAuthenticated() : TokenStore.getInstance().setCurrentAccountAsSTP()
          gestisciIscrizioneSTPMenuItem(PATH_TO_FO_ANAG_ISCRIZIONE_STP)
          break

        case 'operatore_ordine_territoriale':
          tipologiaIscrizione = 'operatore_ordine_territoriale'
          nuovaIscrizioneOOT(this.state.spidData)
            .then((response) => {
              goTo(PATH_TO_FO_ANAG_ISCRIZIONE_OOT)
            })
            .catch((err) => {
              throw new Error(err)
            })
          break

        case 'operatore_stp':
          tipologiaIscrizione = 'operatore_stp'
          nuovaIscrizioneOSTP(this.state.spidData)
            .then((response) => {
              goTo(PATH_TO_FO_ANAG_ISCRIZIONE_OSTP)
            })
            .catch((err) => {
              throw new Error(err)
            })
          break

        case 'operatore_conaf_nazionali':
          tipologiaIscrizione = 'operatore_conaf_nazionali'
          nuovaIscrizioneOConaf(this.state.spidData)
            .then((response) => {
              goTo(PATH_TO_FO_ANAG_ISCRIZIONE_OCONAF)
            })
            .catch((err) => {
              throw new Error(err)
            })
          break

        case 'operatore_federazione':
          tipologiaIscrizione = 'operatore_federazione'
          nuovaIscrizioneOOT(this.state.spidData)
            .then((response) => {
              goTo(PATH_TO_FO_ANAG_ISCRIZIONE_OF)
            })
            .catch((err) => {
              throw new Error(err)
            })
          break

        case 'operatore_enti_formazione':
          tipologiaIscrizione = 'operatore_enti_formazione'
          goTo(PATH_TO_FO_FORMAZIONE_ISCRIZIONE_AF)
          break
        case 'operatore_riviste':
          tipologiaIscrizione = 'operatore_riviste'
          goTo(PATH_TO_BO_CREAZIONE_RIVISTA)
          break
        case 'operatore_enti_esterni':
          tipologiaIscrizione = 'operatore_enti_esterni'
          goTo(PATH_TO_BO_ENTE_ESTERNO)
          break
        case 'operatore_formatore':
          tipologiaIscrizione = 'operatore_formatore'
          goTo(PATH_TO_FO_ISCRIZIONE_FORMATORE)
          break
        case 'Operatore fornitori di servizi':
          /* TODO nuovaIscrizione(this.state.codiceFiscale, categoria, 0);*/
          break
      }
    }

    return (
      <>
        {this.state.isLoaded && this.state.tipologie ? (
          <div className="container">
            <div className="container register-container">
              <GenericBreadCrumb paths={breadcrumbOptions} />
              <div>
                <h1>{title}</h1>
              </div>
              <GenericAlert hidden={this.state.alertMessage === ''} color={this.state.typeMessage} label={this.state.alertMessage}></GenericAlert>
              <div className="Card" hidden={this.state.alertMessage !== ''}>
                <GenericCard
                  key={'cardCategorie'}
                  cardHeader={cardHeader}
                  cardBody={
                    <div>
                      <GenericList
                        key={'tabellaCategorie'}
                        onSelect={selezionaTipologia}
                        rows={this.state.tipologie}
                        isBorderless={true}
                        labelButton={labelButton}
                        colorButton={colorButton}
                        hideActionColumn={false}
                        iconButton={add}
                        onSelectActions={this.selezionaIcona}
                        dropdownList={this.state.dropdownList}
                      />
                    </div>
                  }
                />
              </div>
            </div>
          </div>
        ) : (
          <GenericSpinner></GenericSpinner>
        )}
      </>
    )
  }
}

export default RichiediNuovoProfilo
