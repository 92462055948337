import {SezioneByIdDTO} from '@parsec/msga'
import {
    getAnagrafeResourcesApi,
    getDefaultAxiosConfig,
    getIscrizioneOrdineResourceApi
} from 'src/app/utils/ManagerRestGateway'

/**
 * GET Elenco Sezioni
 * @returns
 *
 */
export async function getSezioni() {
  try {
    const response = await getIscrizioneOrdineResourceApi().msgaIotSezioniGet(getDefaultAxiosConfig());
    return response;
  } catch (err) {
    console.error("Errore durante la chiamata msgaIotSezioniGet - " + err);
    throw new Error("Errore durante la chiamata msgaIotSezioniGet - " + err);
  }
}

/**
 * GET della sezione by codice
 * @returns
 * @param idSezione
 */
export async function getSezioneByCodice(codiceSezione: number) {
  try {
    var sezione: SezioneByIdDTO = {
      "idSezione": codiceSezione
    }
    const response = await getAnagrafeResourcesApi().msgaAnagrafeBoGetSezioneByIdPost(sezione, getDefaultAxiosConfig())
    //Manca il ResponseBuilder su Backend
    return response;
  } catch (err) {
    //TODO - Gestire meglio l'errore
    console.error("Errore durante la chiamata per il recupero della sezione. " );
    throw new Error("Errore durante la chiamata per il recupero della sezione. " );

  }
}
