import {getAnagrafeResourcesApi, getDefaultAxiosConfig} from 'src/app/utils/ManagerRestGateway'

/**
 * GET Elenco codici ATECO
 * @returns
 *
 */
export async function getCodiciAteco() {
  try {
    const response = await getAnagrafeResourcesApi().msgaAnagrafeFoListaCodiciAtecoGet(getDefaultAxiosConfig());
    //Manca il ResponseBuilder su Backend
    return response;
  } catch (err: any) {
    //TODO - Gestire errore
    //let errorMessage: any = ErrorStore.getInstance().returnMessageError(err, undefined)
    console.error("Errore durante la chiamata per il recupero dei codici Ateco. " + err);
    throw new Error("Errore durante la chiamata per il recupero dei codici Ateco. " + err);

  }
}
