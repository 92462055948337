import {getDefaultAxiosConfig, getIscrizioneOrdineResourceApi} from 'src/app/utils/ManagerRestGateway'

/**
 * GET delle tipologie di pagamenti by contesto
 * @returns
 * @param contesto
 */
export async function getListaTipologiePagamentiByContesto(contesto: string) {
    try {
        var tipologiePagamenti = {
            "param": contesto
        }
        const response = await getIscrizioneOrdineResourceApi().msgaIotFoElencoTipologiePagamentiPost(tipologiePagamenti, getDefaultAxiosConfig())
        return response;
    } catch (err) {
        console.error("Errore durante la chiamata msgaIotElencoTipologiePagamentiPost per: " + contesto + " - " + err);
        throw new Error("Errore durante la chiamata msgaIotElencoTipologiePagamentiPost per: " + contesto + " - " + err);
    }
}
