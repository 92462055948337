import {Spinner} from '@patternfly/react-core'
import React from 'react'

class GenericSpinner extends React.Component<GeneralSpinnerProps, any>{
    constructor(props) {
        super(props);
        this.state = {
          label: props.label,
          size: props.size,
          diameter: props.diameter,
        };
    }

    render() {
        const { diameter, label, size} = this.state;
        return (
          <div className="pf-l-bullseye">
          <div className="pf-l-bullseye__item"> <Spinner hidden={this.props.hidden} isSVG size={size} diameter={diameter} aria-label={label} /></div>
        </div>
          );
      }
    }

    export declare interface GeneralSpinnerProps {
        label?: any;
        size?: any;
        diameter?: any;
        hidden?: boolean;
    }

    export default GenericSpinner;
