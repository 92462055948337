import React, {useEffect, useRef, useState} from 'react'
import {useHistory, useLocation} from 'react-router-dom'
import {PageSection, Title} from '@patternfly/react-core'
import styled from 'styled-components'

import {
    PATH_TO_BO_DETTAGLIO_RICHIESTA_ESONERO,
    PATH_TO_BO_ELENCO_RICHIESTE_ESONERO,
    PATH_TO_FO_DETTAGLIO_RICHIESTA_ESONERO,
    PATH_TO_FO_ELENCO_RICHIESTE_ESONERO
} from 'src/app/utils/RoutesConstants'
import GenericBreadCrumb from 'src/frontend/app/components/GenericBreadCrumb'

import {createFormData, getTabActiveFormId, handleChangeFormData, validateFormData} from 'src/app/utils/form-utils'
import {getDefaultAxiosConfig, getFormazioneResourceApi} from 'src/app/utils/ManagerRestGateway'
import {DETAILS_ACTIONS} from 'src/formazione'

// Form azioni
import FormRichiestaEsonero from 'src/frontend/app/pages/richiestaEsonero/formRichiestaEsonero/FormRichiestaEsonero'
import FormApprovazione from './formRichiesteEsonero/FormApprovazione'
import {LABEL_STATI, MOTIVAZIONI_ESONERO, STATI_ESONERO} from 'src/model/Esoneri'
import FormRigetto from './formRichiesteEsonero/FormRigetto'
import FormIntegrazione from './formRichiesteEsonero/FormIntegrazione'
import HistoryModal from '../../components/HistoryModal'
import {Label} from 'design-react-kit'
import FormSubmit, {STATUS} from 'src/frontend/app/components/Form/GenericSubmit'
import formValidationRules from 'src/frontend/app/pages/richiestaEsonero/formRichiestaEsonero/FormRules'
import moment from 'moment'
import {GenericSpinner} from '../../components'
import GenericCard from 'src/frontend/app/components/GenericCard'
import useFrontOffice from 'src/hooks/useFrontOffice'

const Spacer = () => (
  <>
    <br></br>
  </>
)

const basePath = { label: 'Formazione', link: '/backoffice/formazione' }

const myPath = {
  label: 'Richiesta Esonero',
  link: PATH_TO_BO_DETTAGLIO_RICHIESTA_ESONERO,
}

export const FlexDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px 20px;
`

const HistoryButton = styled.button`
  padding: 10px;
  background-color: white;
  border: 1px solid #dddddd;
`

const DettaglioRichiestaEsonero: React.FunctionComponent = (props) => {
  const isFrontOffice = useFrontOffice()
  const pathToElenco = isFrontOffice ? PATH_TO_FO_ELENCO_RICHIESTE_ESONERO : PATH_TO_BO_ELENCO_RICHIESTE_ESONERO
  const pathToDettaglio = isFrontOffice ? PATH_TO_FO_DETTAGLIO_RICHIESTA_ESONERO : PATH_TO_BO_DETTAGLIO_RICHIESTA_ESONERO
  const basePath = { label: 'Elenco richieste di esonero', link: pathToElenco }
  const myPath = {
    label: 'Richiesta di esonero',
    link: pathToDettaglio,
  }

  const history = useHistory()
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const id = searchParams.get('id') || ''
  const modalita = searchParams.get('modalita')

  // Form State
  const refForm = useRef<any>([])

  const [data, setData] = useState<any>({})
  const [status, setStatus] = useState<any>({})
  const [errors, setErrors] = useState<any>({})
  const [alertMessage, setAlertMessage] = useState<any>({})
  const formData = createFormData(data, setData, errors, setErrors)
  const [isModalOpen, setIsModalOpen] = useState(false)

  useEffect(() => {
    const doRequest = async (id) => {
      const { data } = (await getFormazioneResourceApi().msfoFormazioneEsoneroGet(id, getDefaultAxiosConfig())) as any
      let idMotivazione = getIdMotivazione(data.motivazione.value);
      data.motivazione.label = MOTIVAZIONI_ESONERO[idMotivazione]?.label || 'Altro';
      data.allegato = data?.allegatiRecuperati?.map((val) => ({
        ...val,
        allegatoDTO: {
          filename: val.titoloDocumento,
        },
      }))
      data.stato = data.updateEsonero.stato
      setData(data)
    }
    doRequest(id)
  }, [id])

  const onInitInput = (tab) => (cmp) => {
    refForm.current.push({ tab, cmp })
  }

  const onSubmit = async () => {
    try {
      // TODO EVENTO CATALOGO API check
      const inputs = getTabActiveFormId(refForm, 0)
      const valid = validateFormData(formData, formValidationRules, inputs)
      const data = formData.data
      data.id = id
      data.updateEsonero.stato = STATI_ESONERO.INVIATA
      data.dt_cambio_stato = moment().format('YYYY-MM-DD')
      if (!valid) {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
        return
      }

      setStatus(STATUS.LOADING)
      await getFormazioneResourceApi().msfoFormazioneAggiornaEsoneroPut(data as any, getDefaultAxiosConfig())
      setStatus(STATUS.SUCCESS)
      setAlertMessage('Dati salvati con successo')
      setTimeout(() => {
        history.goBack()
      }, 500)
    } catch (e) {
      setStatus(STATUS.FAILURE)
      setAlertMessage('Impossibile salvare i dati')
    }
  }

  const handleInput = (code, value) => {
    handleChangeFormData(formData, code, value, formValidationRules)
  }

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen)
  }

/**
 * Restituisce l'indice dell'oggetto Motivazione con il valore specificato.
 * @param value Il valore da cercare.
 * @returns L'indice dell'oggetto Motivazione o -1 se non trovato.
 */
function getIdMotivazione(value: string): number {
  const indice = MOTIVAZIONI_ESONERO.findIndex((motivazione) => motivazione.value === value);
  return indice;
}

  //TODO: verificare in seguito, non chiaro il funzionamento
  const setHistoricData = (dato) => {
    let idMotiv = getIdMotivazione(dato)
    dato.motivazione = { label: MOTIVAZIONI_ESONERO[idMotiv].label, value: dato.id_motivaz_esonero }
    setData(dato)
    toggleModal()
  }

  let isDisabled = true

  if (!data || !data.updateEsonero) {
    return (
      <>
        <GenericSpinner size="lg" />
        Download in corso...
      </>
    )
  }

  return (
    <PageSection>
      <HistoryModal
        id={id}
        isModalOpen={isModalOpen}
        toggleModal={toggleModal}
        setHistoricData={setHistoricData}
        tableHead={['ID Storico', 'Note', 'Data', 'Stato']}
        dataFields={['thread_storico', 'note_conferma', 'dt_cambio_stato', 'stato']}
        isButtonActive={true}
      ></HistoryModal>
      <div className="container  register-container">
        <GenericBreadCrumb paths={[basePath, myPath]} />
        <Spacer />

        <Title headingLevel="h1" size="lg">
          {myPath.label}
        </Title>

        <FlexDiv>
          <div>
            {data.updateEsonero && data.updateEsonero.stato === STATI_ESONERO.RIFIUTATA ? (
              <Title headingLevel="h2" size="lg">
                Richiesta rifiutata: {data.updateEsonero.note_conferma}
              </Title>
            ) : (
              ' '
            )}
          </div>
          {data && <p>Stato : {LABEL_STATI[data.stato]}</p>}
          <div>
            <HistoryButton onClick={toggleModal}>Storico</HistoryButton>
          </div>
        </FlexDiv>

        {modalita === DETAILS_ACTIONS.APPROVA && <FormApprovazione id={id} />}
        {modalita === DETAILS_ACTIONS.RICHIESTA_INTEG && <FormIntegrazione id={id} />}
        {modalita === DETAILS_ACTIONS.RIGETTA && <FormRigetto id={id} />}
        {modalita === DETAILS_ACTIONS.MODIFICA ? (isDisabled = false) : (isDisabled = true)}
        {modalita === DETAILS_ACTIONS.MODIFICA && data.updateEsonero.stato === STATI_ESONERO.INTEGRAZIONE ? (
          <GenericCard cardHeader={<Title headingLevel="h2">Sono richieste integrazioni. Motivo:</Title>}>
            <Title headingLevel="h3">{data.updateEsonero.note_conferma}</Title>
          </GenericCard>
        ) : null}
        {modalita === DETAILS_ACTIONS.MODIFICA && (
          <FormSubmit title="Conferma modifica" buttonLabel="Modifica" alertMessage={alertMessage} status={status} onSubmit={onSubmit}>
            <Label>Per modificare la richiesta cliccare "Modifica".</Label>
          </FormSubmit>
        )}
        <FormRichiestaEsonero
          title="Dati Richiesta"
          data={data}
          errors={errors}
          onInitInput={onInitInput(0)}
          handleInput={handleInput}
          isDisabled={isDisabled}
        />
      </div>
      <PageSection>
        <div className="container register-container">
          <Title headingLevel="h3">Legenda</Title>
          <p>* Campo obbligatorio</p>
          <p>** Campo non modificabile compilato automaticamente</p>
        </div>
      </PageSection>
    </PageSection>
  )
}

export default DettaglioRichiestaEsonero
