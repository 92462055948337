import * as React from 'react'
import {Form} from '@patternfly/react-core'
import {inputRequiredValidation} from 'src/utilities/utility'
import {getDefaultAxiosConfig, getTrasferimentoOrdineResourceApi} from 'src/app/utils/ManagerRestGateway'
import GenericTextArea from 'src/backoffice/app/components/GenericTextArea'
import {isCurrentMobileDevice} from 'src/utilities/deviceUtility'

export class FormRifiutaTrasferimentoOrdine extends React.Component<GeneralFormRifiutaTrasferimentoOrdineProps, any> {
  handleSubmit = async (event) => {
    /*--------------------------------------------- Validazione campi obbligatori ------------------------------------------------*/
    var requiredFields = [{ value: event.target.motivoRifiutoTrasfOrd.value, label: 'Motivo del rifiuto' }]

    var errorRequired: any = inputRequiredValidation(requiredFields)
    if (errorRequired !== undefined && errorRequired !== '') {
      event.preventDefault()
      return this.props.messaggio({ messaggio: errorRequired.messaggio, type: errorRequired.type })
    } else {
    /*----------------------------------------------------------------------------------------------------------------------------*/
      event.preventDefault()
      //chiamata al servizio TrasferimentoOrdineValuta

      const param = {
        esitoValutazione: 'respinta',
        trasferimentoOrdineDTO: {
          motivoRifiutoTrasferimentoOrdine: event.target.motivoRifiutoTrasfOrd.value,
          idTrasferimentoOrdine: this.props.idTrasferimentoOrdine,
          idIscrizioneOrdineTerritoriale: this.props.idIscrizione,
          idSoggettoIscritto: this.props.idSoggetto,
        },
      }

      await getTrasferimentoOrdineResourceApi()
        .mswfTrasferimentoOrdineValutaPost(param, getDefaultAxiosConfig())
        .then(() => {
          return this.props.messaggio({ messaggio: 'Dati salvati con successo', type: 'success' })
        })
        .catch((err) => {
          return this.props.messaggio({ messaggio: "C'è stato un problema nell'invio dei dati", type: 'danger' })
        })
    }
  }

  render() {
    return (
      <div>
        <Form id="rifiutaTrasfOrdine" onSubmit={this.handleSubmit}>
          <div className="row">
            <div className={isCurrentMobileDevice() ? null : 'col-6'}>
              <GenericTextArea
                className="w-75"
                label={'Motivo del rifiuto'}
                id={'motivoRifiutoTrasfOrd'}
                ariaLabel={'textarea-motivoRigetto'}
                placeholder={'Inserire motivo'}
                isRequired
              />
            </div>
          </div>
        </Form>
      </div>
    )
  }
}

export declare interface GeneralFormRifiutaTrasferimentoOrdineProps {
  messaggio?: any
  idIscrizione?: any
  idTrasferimentoOrdine?: any
  idSoggetto?: any
}
