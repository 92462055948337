import * as React from 'react'
import {Form} from '@patternfly/react-core'
import {GenericTextArea} from '../../../components/GenericTextArea'
import {rifiutaPolizza} from 'src/processes/assicurazioni/Assicurazione'
import {PATH_TO_BO_POLIZZE_ELENCO} from 'src/app/utils/RoutesConstants'
import GenericModal from 'src/backoffice/app/components/GenericModal'
import {GenericSpinner} from 'src/backoffice/app/components'
import {inputRequiredValidation} from 'src/utilities/utility'
import {isCurrentMobileDevice} from 'src/utilities/deviceUtility'

export class FormRigettaPolizza extends React.Component<GeneralFormRigettaPolizzaProps, any> {
    constructor(props) {
        super(props);
        this.state = {
            dateFrom: "",
            dateTo: "",
            polizzaIndividuale: false,
            errorFlag: false,
            isLoaded: false,

        };
      }
    async componentDidMount(): Promise<void> {
        this.setState({ isLoaded : true })
    }
    handleSubmit = async event => {
        var requiredFields = [{ value: event.target.idMotivoRigettoPolizza.value, label: 'Motivo' }]
        var errorRequired: any = inputRequiredValidation(requiredFields)
        if (errorRequired !== undefined && errorRequired !== "") {
            event.preventDefault()
            return this.props.messaggio({ messaggio: errorRequired.messaggio, type: errorRequired.type })
        } else {
            await this.setState({isLoaded:false});

            //chiamata al servizio valutaIscrizione per rigetta iscrizione
            var idPolizza = this.props.idPolizza
            var parametriRigetta = {
                idPolizza: idPolizza,
                note: event.target.idMotivoRigettoPolizza.value ? event.target.idMotivoRigettoPolizza.value : "",
            }
            event.preventDefault();
            try {
                event.preventDefault();
                await rifiutaPolizza(parametriRigetta).then(response=>{
                })
                event.preventDefault();
                await this.setState({errorFlag: false})
                this.props.messaggio({ messaggio: "Dati salvati con successo", type: "success" })
                await this.showModal(true, "Messaggio","Polizza rifiutata");
                await this.setState({isLoaded:true})

                return false;
            }
            catch (err: any) {
                event.preventDefault();
                await this.setState({errorFlag: true})

                this.showModal(true, "Errore",err.message);
                return false;
            }
        }
    }

    closeModal = () => {
        this.setState({modalOpen:false});
        if(this.state.errorFlag === false) window.location.href = PATH_TO_BO_POLIZZE_ELENCO;
    }

    showModal = async (booleanValue, title,text) => {
        await this.setState({
            modalOpen:booleanValue,
            modalTitle: title,
            modalText:text,
            isLoaded: true,
        });
    }

    render() {
        return (
            <div>
                {
                    this.state.isLoaded ?
                        <>

                            <Form id='idRigettaPolizza' onSubmit={this.handleSubmit}>
                                <GenericTextArea className={isCurrentMobileDevice() ? 'col-12' : 'w-50' } id={"idMotivoRigettoPolizza"} label={"Motivo"} name={"idMotivoRigetto"} placeholder={"Motivo del rifiuto"} ariaLabel={'textarea-idMotivoRigetto'} isRequired />
                            </Form>

                            <GenericModal
                                    title={this.state.modalTitle}
                                    text={this.state.modalText}
                                    modalOpen={this.state.modalOpen}
                                    closeButton={this.closeModal}
                            />
                        </>
                    : <GenericSpinner/>
                }
            </div>
        )
    }
}

export declare interface GeneralFormRigettaPolizzaProps {
    messaggio?: any;
    idPolizza?: any;
    idSoggetto?: any;
}
