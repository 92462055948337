import React, {useMemo} from 'react'
import {Form} from 'design-react-kit'
import createWrpInput from 'src/frontend/app/components/Form/WrpInput'
import GenericCard from 'src/frontend/app/components/GenericCard'
import GenericInput from 'src/frontend/app/components/Form/Input'
import {isCurrentMobileDevice} from 'src/utilities/deviceUtility'

const FormDateInizioFine: React.FunctionComponent<FormDatiSedeLegaleProps> = ({ title, data, handleInput, onInitInput, errors, viewOnly }) => {
  const objKey = 'datiSedeLegale'
  const WrpInput = useMemo(() => createWrpInput({ objKey, onInit: onInitInput, onChange: handleInput, viewOnly }), [])

  return (
    <GenericCard cardHeader={title}>
      <Form>
        <div className="row">
          <div className={isCurrentMobileDevice() ? null : 'col-6'}>
            <WrpInput as={GenericInput} id="dataInizio" type="date" label="Data inizio convenzione*" data={data} errors={errors} />
          </div>
          <div className={isCurrentMobileDevice() ? null : 'col-6'}>
            <WrpInput as={GenericInput} id="dataFine" type="date" label="Data fine convenzione*" data={data} errors={errors} />
          </div>
        </div>
      </Form>
    </GenericCard>
  )
}

interface FormDatiSedeLegaleProps {
  data: any
  errors: any
  title: string
  handleInput: any
  onInitInput?: any
  viewOnly?: boolean
}

export default FormDateInizioFine
