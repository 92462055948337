/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable @typescript-eslint/no-redeclare */
import {Form, FormGroup, Icon, Label} from 'design-react-kit'
import React from 'react'
import GenericButton from '../../../components/GenericButton'
import GenericCard from '../../../components/GenericCard'
import GenericCheckbox from '../../../components/GenericCheckbox'
import GenericInput from '../../../components/GenericInput'
import GenericSelect from '../../../components/GenericSelect'
import GenericUploadFront from '../../../components/GenericUpload'
import GenericSpinner from 'src/backoffice/app/components/GenericSpinner'
import {
    getDatiQuotaIscrizioneByOrdine,
    getFileByIdAttachmentFO,
    getFilesByIdIscrizioneFo,
    getTipologieDocumento
} from 'src/processes/ProcessoIscrizione'
import {getListaTipologiePagamentiByContesto} from 'src/processes/TipologiePagamenti'
import {generateUUID, getBigIntToConvertToBoolean} from 'src/utilities/utility'
import GenericModal from 'src/backoffice/app/components/GenericModal'
import {AppCostants} from 'src/app/utils/AppConstant'
import GenericTableCompact from 'src/frontend/app/components/GenericTableCompact'
import GenericAlert from 'src/frontend/app/components/GenericAlert'
import {isCurrentMobileDevice} from 'src/utilities/deviceUtility'
import {PDFLinks} from 'src/constants/pdfLinks'

const statoCompilazione = 1
const statoRichiestaIntegrazioni = 3
const TIPO_PAGAMENTI = "pagamenti"
const TIPO_ALLEGATI = "allegati"
const TIPO_ALTRI = "altri"
const FILENAME_ANNULL_BOLLO = "DichiarazioneSostitutivaDiPagamentoBollo.pdf"
const yes_no_options = [
  { label: "Si", value: "Si" },
  { label: "No", value: "No" }
]

let tipoPagamentiMenu: any = []
let tipoDocumentoMenu: any = []

export default class Documenti extends React.Component<DocumentiProps, any>{
  constructor(props) {
    super(props);
    this.state = {
      activeTab: props.activeTab,
      tabClick: props.tabClick,
      prevData: props.prevData,
      prevClicked: props.prevClicked,
      fields: (props.prevData && props.prevClicked) ? props.prevData[0] : {},
      isLoaded: false,
      fieldsTipoPagamento: [],
      message: '',
      listaFiles: [],
      dataUri: null,
      editFiles: true,
    };
  }

  /*********************** Azioni iniziali ****************************************/
  async componentDidMount(): Promise<void> {
    var contesto = "iot"

    var tipologiePagamenti: any = await getListaTipologiePagamentiByContesto(contesto)
    if (tipologiePagamenti.status === 200 && tipologiePagamenti.data.length > 0) {
      tipologiePagamenti.data.forEach(pagamento => {
        tipoPagamentiMenu.push({ label: pagamento.descrizione, value: pagamento.codice })
      })
    }

    var tipologieDocumento: any = await getTipologieDocumento()
    if (tipologieDocumento.data.httpStatus === 200 && tipologieDocumento.data.returnedObject.length > 0) {
      tipologieDocumento.data.returnedObject.forEach(doc => {
        tipoDocumentoMenu.push({ label: doc.descrizioneDocumento, value: doc.idTipoDoc })
      })
    }

    if (!this.props.visualizzaFlag) {
      if (this.props.data) {
        var fields = this.props.data;
        this.setState({ fields: fields })
      }
    }
    else {
      this.setState({ fields: this.props.data })
      var idIscrizione = this.props.data.idIscrizione
      getFilesByIdIscrizioneFo(idIscrizione).then((response) => {
        this.setState({ listaFiles: response.data })
      })
    }

    if (this.props.data !== undefined) {
      var filesIscrizione = await getFilesByIdIscrizioneFo(this.props.data?.idIscrizione)

      if(filesIscrizione?.data.length < 1) {
        var fields = this.props.data;
        fields.uploadFiles = []
        this.setState({ filesIscrizione: null })
      }
      else {

          if (filesIscrizione && filesIscrizione.status !== 200)
            throw new Error("C'è stato un errore durante la get dei files");

          var fields = this.props.data;
          fields.uploadFiles = filesIscrizione.data


          fields.uploadFiles.map((file) => {
            if (file.codiceTipoAllegato === "bollo_iot" ||
              file.codiceTipoAllegato === "tassa_iot" ||
              file.codiceTipoAllegato === "quota_iscrizione_iot" ||
              file.codiceTipoAllegato === "bollo_iot" ||
              file.codiceTipoAllegato === "quota_smartcard_iot") {
              file["groupAttachmentFilesType"] = TIPO_PAGAMENTI


            }
            else if (file.codiceTipoAllegato === "allegato_iot") {
              file["groupAttachmentFilesType"] = TIPO_ALLEGATI

            }
            else {
              file["groupAttachmentFilesType"] = TIPO_ALTRI

            }
          })

          //Se manca qualche file nella tipologia pagamenti -> Me lo fa aggiungere in modifica
          let foundBollo = fields.uploadFiles.find(element => element.codiceTipoAllegato === "bollo_iot");
          let foundTassa = fields.uploadFiles.find(element => element.codiceTipoAllegato === "tassa_iot");
          let foundQuota = fields.uploadFiles.find(element => element.codiceTipoAllegato === "quota_iscrizione_iot");

          if(foundBollo && foundTassa && foundQuota) this.setState({editFiles: false})
          else this.setState({editFiles: true})


      }


      this.setState({ fields: fields })

      if (filesIscrizione?.status === 200) {
        await this.setState({ filesIscrizione: filesIscrizione.data })
      } else {
        throw new Error("File non caricato");
      }
    }

    await this.setState({ isLoaded: true })
  }

  getFile = (idAttachment, filename) => {
    try {
      let dataUri: any = '';
      getFileByIdAttachmentFO(idAttachment).then(response => {
        if (response === undefined) {
          this.showModal(true, "Attenzione", "File non presente");
        }
        dataUri = "data:" + response.data.contentType + ";base64," + response.data.content;
        const link = document.createElement("a");
        link.download = filename;
        link.href = dataUri;
        link.click();
      })
    } catch (err) {
      throw err
    }
  }

  closeModal = () => {
    this.setState({ modalOpen: false });
  }

  showModal = async (booleanValue, title, text) => {
    await this.setState({
      modalOpen: booleanValue,
      modalTitle: title,
      modalText: text,
      isLoaded: true,
    });
  }

  componentWillReceiveProps(nextProps: Readonly<DocumentiProps>, nextContext: any) {
    if (this.props.data?.idOrdine?.value) {
      getDatiQuotaIscrizioneByOrdine(this.props.data.idOrdine?.value).then((response) => {
        if (response && response.data && response.data.httpStatus === 200) {
          var quote: any = []
          response.data.returnedObject.map((item, id) => {
            quote[id] = {
              anno: item.anno,
              categoria: item.categoria,
              quota: item.quotaAssociativa
            };
          })
          this.setState({ datiQuotaIscrizione: quote })
        }

        else {
          this.setState({ messageQuotaMancante: response.data.error, datiQuotaIscrizione: [] })
        }
      })
    }
  }

  render() {
    const { tabNumber, message, statoIscrizione } = this.props;
    const { idAllegatoDeleted } = this.state;

    /**
     * Gestisce l'aggiunta di nuovi file in uploadFiles.
     * @param tipo
     * @param fields
     */
    const aggiungiFile = (tipo: string) => {
      // La struttura dati è composta dai campi di AllegatoDTO, da quelli di GenericUpload e altri
      var fields = this.state.fields;
      fields.uploadFiles?.push({
        // Fields di genericUpload
        filename: null,
        contentBase64: null,
        //tipologiaAllegato: null,
        contentType: null,
        descrizioneBreve: null,

        // Fields di documenti-pagamenti/allegati
        //key: fields.uploadFiles.length+1,
        label: "",
        value: "",
        uploaded: false,

        // Fields del db
        codiceTipoAllegato: null,
        dataCancellazione: null,
        descrizioneEstesa: null,
        idAllegato: null,
        idAttachment: null,
        idIscrizione: null,
        idSoggettoRichiedenteAllegati: null,

        // Altri
        uuid: generateUUID(),
        groupAttachmentFilesType: tipo, // tipo: pagamenti|allegati|altri
        cancellato: false
      });
      this.setState({ fields: fields });
    }

    /**
     * Gestisce la rimozione dei file da uploadFiles.
     * @param doDeleteFromArray
     * @param fields
     * @param index
     */
    const rimuoviFile = (fields: any, index: any) => {
      // Se non deve essere rimosso dall'array lo cancella logicamente impostando cancellato a true

      if (fields.uploadFiles[index].cancellato !== undefined)
        fields.uploadFiles[index].cancellato = true;
      this.setState({ fields: fields })
    }

    /**
     * Gestisce gli eventi di inserimento, modifica e rimozione di file, input text, tendine e checkbox.
     * @param e
     * @param type
     * @param field
     * @param index
     */
    const onChange = (e, type: any, field: any, index: any) => {
      let fields = this.state.fields;

      // Caso SELECT
      if (type === 'select') {
        if (field === TIPO_PAGAMENTI && index !== undefined) {
          fields.uploadFiles[index].label = e.label
          fields.uploadFiles[index].value = e.value
          fields.uploadFiles[index].codiceTipoAllegato = e.value
        }
        else if (field === 'tipoDocumento')
          fields[field] = { label: e.label, value: e.value };
        else
          fields[field] = { label: e.label, value: e.label };
      }

      // Caso TEXT
      else if (type === 'text' || type === 'date') {
        if (field === TIPO_ALLEGATI && index !== undefined) {

          if (fields.uploadFiles[index].descrizioneBreve !== e.target.value) {
            fields.uploadFiles[index].label = "allegato"
            fields.uploadFiles[index].value = "allegato_iot"
            fields.uploadFiles[index].codiceTipoAllegato = "allegato_iot"
            fields.uploadFiles[index].descrizioneBreve = e.target.value
          }
        }
        else fields[field] = e.target.value;
      }

      // Caso: CHECKBOX
      else if (type === 'checkbox') {
        fields[field] = e.target.checked;
      }

      // Altri casi ???
      else {
        fields[field] = e.target.value;
      }

      this.setState({ fields: fields });
      this.props.getSelectedValue(fields)
    }

    /**
     * Gestisce onChange dei file.
     * @param data
     * @param tipo
     * @param index
     */
    const onChangeFile = (data, tipo, index?) => {
      var fields = this.state.fields

      // Caso documento identità
      if (tipo === "documentoIdentita") {
        fields["uploadFiles"]?.push(data);
        fields["uploadFiles"]?.map((e, id) => {
          if((e.tipologiaAllegato === data.tipologiaAllegato || e.codiceTipoAllegato === data.tipologiaAllegato)){
            if(e.idAttachment && !e.cancellato){
              e["cancellato"] = true
            }else if(e.idAttachment && e.cancellato){
              if(id < fields["uploadFiles"].length-1){
                e["cancellato"] = true
              }
            }else if(!e.idAttachment && id < fields["uploadFiles"].length-1){ //Se id attachment non presente ma non è cancellato
              e["cancellato"] = true
            }
        }else{
          return e
        }
      })
      this.props.getSelectedValue(fields)
      }

      // Se c'è l'index e non c'è idAttachment imposto il filename, se c'è lo prende dal db
      if (index && !fields.uploadFiles[index]?.idAttachment) {
        fields.uploadFiles[index]["filename"] = data.filename
      }

      // Se c'è l'index imposto i campi che vengono dal file appena caricato
      if (index) {
        fields.uploadFiles[index].contentBase64 = data.contentBase64
        fields.uploadFiles[index].contentType = data.contentType
        fields.uploadFiles[index].filename = data.filename
        fields.uploadFiles[index].tipologiaAllegato = data.tipologiaAllegato
      }

      // Se c'è l'index e il campo cancellato è presente invoca la funzione rimuoviFile
      if (index && fields.uploadFiles.index?.cancellato !== undefined)
        rimuoviFile(fields, index)

      this.props.getSelectedValue(fields)
    }

    /********************************* Form Iscrizione ************************************/
    const idAttachment = this.state.fields?.uploadFiles?.filter(e => e.codiceTipoAllegato === "documento_riconoscimento_iot")[0]?.idAttachment
    const formIscrizione = [

      <Form key="formIscrizioneDocumenti" id="formIscrizioneDocumenti">
        <GenericCard key={'pagamentiCard'} cardHeader="Allegati e dichiarazioni" cardBody={

          <div key="docContrainer">
            <div className="row">
              <div className={isCurrentMobileDevice() ? null : "col-6"} >
                <FormGroup label={"rilascioToken"}>
                  <GenericSelect elementId="rilascioToken" name={'rilascioToken'} onChange={(e) => onChange(e, 'select', 'flagRichBusinessKey', null)} label='Richiesta rilascio token *' placeholder='Si / No'
                    defaultOptions={yes_no_options}
                    defaultValue={(this.state.fields?.flagRichBusinessKey) ? this.state.fields?.flagRichBusinessKey : null} errorMessageAlert={message} isRequired currentStep={tabNumber}
                    isDisabled={this.props.statoIscrizione !== statoCompilazione && this.props.statoIscrizione !== statoRichiestaIntegrazioni}
                  />
                </FormGroup>
              </div>
              <div className={isCurrentMobileDevice() ? null : "col-6"}>
                <FormGroup label={"rilascioCard"}>
                  <GenericSelect elementId={"idRilascioCard"} label='Richiesta rilascio smart card *' placeholder='Si / No'
                    maxLabelLength={10}
                    defaultValue={
                      (this.state.fields?.flagRichSmartCard &&
                        this.state.fields?.flagRichSmartCard?.label &&
                        this.state.fields?.flagRichSmartCard?.label !== null &&
                        this.state.fields?.flagRichSmartCard?.label !== "") ?
                        this.state.fields?.flagRichSmartCard :
                        null
                    }
                    onChange={(e) => onChange(e, 'select', 'flagRichSmartCard', null)} defaultOptions={yes_no_options} errorMessageAlert={message} isRequired currentStep={tabNumber} isDisabled={this.props.statoIscrizione !== statoCompilazione && this.props.statoIscrizione !== statoRichiestaIntegrazioni} />
                </FormGroup>
              </div>
              <div className={isCurrentMobileDevice() ? null : "col-6"}>
                <FormGroup label={"assegnazionePEC"}>
                  <GenericSelect elementId={"idAssegnazionePEC"} label='Richiesta assegnazione PEC *' placeholder='Si / No'
                    defaultValue={(this.state.fields?.flagRichPec) ? this.state.fields?.flagRichPec : null}
                    onChange={(e) => onChange(e, 'select', 'flagRichPec', null)} defaultOptions={yes_no_options} errorMessageAlert={message} isRequired currentStep={tabNumber} isDisabled={this.props.statoIscrizione !== statoCompilazione && this.props.statoIscrizione !== statoRichiestaIntegrazioni} />
                </FormGroup>
              </div>
            </div>

          <div className="row">
              <Label><strong>Pagamenti</strong></Label><br></br><br></br>
            </div>
            <div className='row'>
              <div className={isCurrentMobileDevice() ? "row" : "col-6"}>
                <div className="row">
                  <Label for="annullamentoBolloID">Dichiarazione sostitutiva di pagamento dell’imposta di bollo</Label><br></br><br></br>
                  <Label for="annullamentoBolloID">Per annullare la marca da bollo scaricare il modulo e ricaricarlo compilato e con la marca da bollo.</Label>
                </div>
                <div className='row'>
                  <div className={isCurrentMobileDevice() ? null : "col-6"}>
                    <Icon icon={'it-download'} size='sm' />
                    <a href={AppCostants.PUBLIC_SHARE + PDFLinks.DICHIARAZIONE_SOSTITUTIVA_PAGAMENTO_BOLLO} target={"_blank"} rel="noopener">{FILENAME_ANNULL_BOLLO}</a><br></br><br></br>
                  </div>
                </div>
              </div>
            </div>
            {(this.state.datiQuotaIscrizione && this.state.datiQuotaIscrizione?.length > 0) ? (
              <>
                <div className="row">
                  <Label for="datiQuotaIscrizione">Dati quota di iscrizione</Label>
                </div>
                <div className="row">
                  <div className={isCurrentMobileDevice() ? null : "col-5"}>
                    <GenericTableCompact
                      style={{ border: "1px solid grey" }}
                      columns={['Anno', 'Categoria', 'Quota']}
                      rows={this.state.datiQuotaIscrizione?.map((quota) => (
                        <tr>
                          <td>{quota?.anno ? quota.anno : '-'}</td>
                          <td>{quota?.categoria ? quota.categoria : '-'}</td>
                          <td>{quota?.quota ? quota.quota : '-'} €</td>
                        </tr>
                      ))} />
                  </div>
                </div>
              </>
            ) : (
              <div className={isCurrentMobileDevice() ? null : "col-6"}>
                <GenericAlert label={this.state.messageQuotaMancante} color='info'/>
              </div>
            )
            }
            <br></br>
            {this.state.fields?.uploadFiles?.map((item, index) => {

              if (item.cancellato === false && item.groupAttachmentFilesType === TIPO_PAGAMENTI) {

                var tipologiaPagamentoDefaultValue = this.state.fields.uploadFiles[index]?.tipologiaAllegato ?
                  { label: this.state.fields.uploadFiles[index]?.tipologiaAllegato, value: this.state.fields.uploadFiles[index]?.tipologiaAllegato }
                  : null
                var pagamentiDiDefault = item.value !== undefined ? { label: item.label, value: item.value } : tipologiaPagamentoDefaultValue

                return (
                  <div>
                    {this.state.hidden ? null :
                      <div className='row' key={index}>
                        <div className={isCurrentMobileDevice() ? null : "col-6"}>
                          <FormGroup label={TIPO_PAGAMENTI}>
                            <GenericSelect
                              isRequired
                              itemIdDeleted={TIPO_PAGAMENTI + index}
                              key={TIPO_PAGAMENTI + index}
                              elementId={TIPO_PAGAMENTI + index}
                              name={TIPO_PAGAMENTI}
                              onChange={(e) => onChange(e, 'select', TIPO_PAGAMENTI, index)}
                              label='Tipo pagamento *'
                              placeholder="Selezionare un'opzione"
                              defaultOptions={tipoPagamentiMenu} errorMessageAlert={message} currentStep={tabNumber}
                              defaultValue={pagamentiDiDefault}
                              isDisabled={item.codiceTipoAllegato && this.props.statoIscrizione !== statoRichiestaIntegrazioni/* || statoIscrizione !== statoCompilazione*/}
                            />
                          </FormGroup>
                        </div>
                        <div className={isCurrentMobileDevice() ? null : "col-5"}>
                          <FormGroup label={"docTipoPagamento"}>
                            <GenericUploadFront
                              name={"Pagamento"}
                              datiAllegato={e => onChangeFile(e, TIPO_PAGAMENTI, index)}
                              tipologiaAllegato={this.state.fields.uploadFiles[index]?.codiceTipoAllegato}
                              acceptedExtensionFile={["pdf"]}
                              key={"uploadTipoPagamento" + index}
                              errorMessageAlert={message}
                              currentStep={tabNumber}
                              id={"uploadTipoPagamento" + index}
                              itemIdDeleted={"uploadTipoPagamento" + index}
                              value={this.state.fields.uploadFiles[index]?.filename ?? undefined}
                              idAttachment={this.state.fields.uploadFiles[index]?.idAttachment}
                              isRequired
                              hideResetFile={this.state.fields.uploadFiles[index]?.filename && this.props.statoIscrizione !== statoRichiestaIntegrazioni}
                              isDisabled={(!this.state.editFiles || this.state.fields.uploadFiles[index]?.filename) && this.props.statoIscrizione !== statoRichiestaIntegrazioni/* || statoIscrizione !== statoCompilazione*/} />
                          </FormGroup>
                        </div>
                        <div className='col-1'><GenericButton onClickEvent={() => rimuoviFile(this.state.fields, index)} size="xs" children={<Icon size="sm" className='mt-3' color='danger' icon="it-delete" /> }
                        /*isDisabled={this.props.statoIscrizione !== statoCompilazione && this.props.statoIscrizione !== statoRichiestaIntegrazioni}*/
                        isHidden={this.state.editFiles === false && this.props.statoIscrizione !== statoRichiestaIntegrazioni}
                        /></div>
                      </div>}

                  </div>
                )
              } else return null
            })}

            <div className="row" >
              <div className={isCurrentMobileDevice() ? null : "col-6"}>
                <GenericButton size="xs" type="button" color="primary" label="Aggiungi pagamento" onClickEvent={() => aggiungiFile(TIPO_PAGAMENTI)} /*isDisabled={this.props.statoIscrizione !== statoCompilazione && this.props.statoIscrizione !== statoRichiestaIntegrazioni}*/
                  isDisabled={this.state.editFiles === false && this.props.statoIscrizione !== statoRichiestaIntegrazioni}
                />
              </div>
            </div>
          </div>
        }></GenericCard>
        <GenericCard key={'allegatiCard'} cardHeader="Allegati e dichiarazioni" cardBody={
          <div className="allegatiContainer">
            <div className='row'>
              <div className={isCurrentMobileDevice() ? null : "col-6"}>
                <FormGroup label={"tipoDocumento"}>
                  <GenericSelect elementId={"tipoDocumento"} label='Tipologia di documento *' placeholder='Selezionare tipologia'
                     defaultValue={
                      (this.state.fields?.tipoDocumento &&
                        this.state.fields?.tipoDocumento?.label &&
                        this.state.fields?.tipoDocumento?.label !== null &&
                        this.state.fields?.tipoDocumento?.label !== "") ?
                        this.state.fields?.tipoDocumento :
                        null
                    }
                    onChange={(e) => onChange(e, 'select', 'tipoDocumento', null)} defaultOptions={tipoDocumentoMenu} errorMessageAlert={message} isRequired currentStep={tabNumber} />
                </FormGroup>
              </div>
              <div className={isCurrentMobileDevice() ? null : "col-6"}>
                <FormGroup label={'numeroDocumento'}>
                  <GenericInput isRequired id={"numeroDocumento"} name={"numeroDocumento"} errorMessageAlert={message} currentStep={tabNumber}
                    defaultValue={this.state.fields?.numeroDocumento ? this.state.fields?.numeroDocumento : null}
                    onChange={(e) => onChange(e, 'text', 'numeroDocumento', null)} label='Numero documento *' placeholder='Inserire numero documento' type='text'
                  />
                </FormGroup>
              </div>
            </div>
            <div className='row'>
              <div className={isCurrentMobileDevice() ? null : "col-6"}>
                <FormGroup label={'emessoDa'}>
                  <GenericInput isRequired id={"emessoDa"} name={"emessoDa"} errorMessageAlert={message} currentStep={tabNumber}
                    defaultValue={this.state.fields?.emessoDa ? this.state.fields?.emessoDa : null}
                    onChange={(e) => onChange(e, 'text', 'emessoDa', null)} label='Emesso da *' placeholder='Inserire ente' type='text'
                  />
                </FormGroup>
              </div>
              <div className={isCurrentMobileDevice() ? null : "col-6"}>
                <FormGroup label={'emessoIl'}>
                  <GenericInput isRequired id={"emessoIl"} name={"emessoIl"} errorMessageAlert={message} currentStep={tabNumber}
                    defaultValue={this.state.fields?.emessoIl ? this.state.fields?.emessoIl : null}
                    onChange={(e) => onChange(e, 'date', 'emessoIl', null)} label='Data emissione *' placeholder='Inserire data emissione' type='date'
                  />
                </FormGroup>
              </div>
            </div>
            <div className='row'>
              <div className={isCurrentMobileDevice() ? null : "col-6"}>
                <FormGroup label={'scadenzaIl'}>
                  <GenericInput isRequired id={"scadenzaIl"} name={"scadenzaIl"} errorMessageAlert={message} currentStep={tabNumber}
                    defaultValue={this.state.fields?.scadenzaIl ? this.state.fields?.scadenzaIl : null}
                    onChange={(e) => onChange(e, 'date', 'scadenzaIl', null)} label='Data scadenza *' placeholder='Inserire data scadenza' type='date'
                  />
                </FormGroup>
              </div>
            </div>
            <div className='row'>
              <div className={isCurrentMobileDevice() ? null : "col-6"}>
                <Label>Documento di identità *</Label>
              </div>
              <div className={isCurrentMobileDevice() ? null : "col-6"}><FormGroup label={"docIdentita"}>
                <GenericUploadFront
                  name={"Documento di identità"}
                  value={this.state.fields.uploadFiles?.filter(e => e.codiceTipoAllegato === "documento_riconoscimento_iot")[0]?.filename}
                  datiAllegato={e => onChangeFile(e, "documentoIdentita")}
                  key="docIdentita" id={"idDocumentoIdentita"}
                  acceptedExtensionFile={["pdf", "jpg"]}
                  tipologiaAllegato={"documento_riconoscimento_iot"}
                  isHidden={this.state.fields['docIdentita']}
                  errorMessageAlert={message}
                  idAttachment={idAttachment}
                  currentStep={tabNumber}
                  isRequired
                  /*isDisabled={this.props.statoIscrizione !== statoCompilazione && this.props.statoIscrizione !== statoRichiestaIntegrazioni}*/
                />
              </FormGroup></div>
            </div>
            <div className="row">
              <Label><strong>Allegati</strong></Label>
            </div>
            {this.state.fields.uploadFiles?.map((item: any, index: any) => {
              if (item.cancellato === false && item.groupAttachmentFilesType === TIPO_ALLEGATI) {
                return (
                  <div className='row' key={index}>
                    <div className={isCurrentMobileDevice() ? null : "col-6"}>
                      <FormGroup label={TIPO_ALLEGATI}>
                        <GenericInput itemIdDeleted={"descrizioneAllegato" + idAllegatoDeleted} isRequired id={"descrizioneAllegato" + index} name={TIPO_ALLEGATI} errorMessageAlert={message} currentStep={tabNumber}
                          defaultValue={this.state.fields.uploadFiles[index]?.descrizioneBreve}
                          onChange={(e) => onChange(e, 'text', TIPO_ALLEGATI, index)} label='Descrizione allegato*' placeholder='Inserire descrizione' type='text'
                          isDisabled={this.props.statoIscrizione !== statoCompilazione && this.props.statoIscrizione !== statoRichiestaIntegrazioni} />
                      </FormGroup>
                    </div>
                    <div className={isCurrentMobileDevice() ? null : "col-5"}>
                      <FormGroup label={"uploadAllegato"}>
                        <GenericUploadFront
                          name={'Allegato'}
                          value={this.state.fields.uploadFiles[index]?.filename ?? undefined}
                          idAttachment={this.state.fields.uploadFiles[index]?.idAttachment}
                          datiAllegato={e => onChangeFile(e, TIPO_ALLEGATI, index)}
                          tipologiaAllegato={"allegato_iot"}
                          id={"uploadAllegato" + index}
                          acceptedExtensionFile={["pdf"]}
                          errorMessageAlert={message}
                          itemIdDeleted={"uploadAllegato" + idAllegatoDeleted}
                          currentStep={tabNumber}
                          descrizioneBreve={this.state.fields.uploadFiles[index]?.descrizioneBreve}
                          isRequired
                          hideResetFile={this.state.fields.uploadFiles[index]?.filename && this.props.statoIscrizione !== statoRichiestaIntegrazioni}
                          isDisabled={this.state.fields.uploadFiles[index]?.filename}
                        />
                      </FormGroup>
                    </div>
                    <div className={isCurrentMobileDevice() ? null : "col-1"}><GenericButton onClickEvent={() => rimuoviFile(this.state.fields, index)} size="xs" children={<Icon size="sm" className='mt-3' color='danger' icon="it-delete" /> } isHidden={statoIscrizione !== statoCompilazione && this.props.statoIscrizione !== statoRichiestaIntegrazioni} /></div>
                  </div>
                )
              } else return null
            })
            }
            <div className='row p-2'>
              <div className={isCurrentMobileDevice() ? null : "col-6"}> <FormGroup label={"aggiungiAllegati"}>
                <GenericButton size="xs" type="button" color="primary" label="Aggiungi allegato" onClickEvent={() => aggiungiFile(TIPO_ALLEGATI)} isDisabled={this.props.statoIscrizione !== statoCompilazione && this.props.statoIscrizione !== statoRichiestaIntegrazioni} />
              </FormGroup></div>
            </div>
            <div style={{ paddingTop: '20px' }}>
              <GenericCheckbox title={"Autocertificazione"} id="autocertificazione" key={'autocertificazione'} defaultChecked={getBigIntToConvertToBoolean(this.state.fields?.autocertificazione) ?? undefined}
                onChange={(e) => onChange(e, 'checkbox', 'autocertificazione', null)} label={"* Dichiaro sotto la mia responsabilità penale che i dati dichiarati corrispondono al vero."} isDisabled={this.props.statoIscrizione !== statoCompilazione && this.props.statoIscrizione !== statoRichiestaIntegrazioni} errorMessageAlert={message} isRequired currentStep={tabNumber} />

              <GenericCheckbox title={"Comprensione adempimenti"} id="comprensioneAdempimenti" key={'comprensioneAdempimenti'} defaultChecked={getBigIntToConvertToBoolean(this.state.fields?.comprensioneAdempimenti) ?? undefined}
                onChange={(e) => onChange(e, 'checkbox', 'comprensioneAdempimenti', null)} label={"* Dichiaro di aver letto e compreso gli adempimenti degli iscritti come riportati nel seguente link."} isDisabled={this.props.statoIscrizione !== statoCompilazione && this.props.statoIscrizione !== statoRichiestaIntegrazioni} errorMessageAlert={message} isRequired currentStep={tabNumber} />
            </div>
          </div>
        }></GenericCard>
      </Form>

    ];


    /********************************* Form Visualizza ************************************/

    const formVisualizza = [
      <Form key="formVisualizzaDocumenti" id="formVisualizzaDocumenti" className="row">

        <GenericCard key={'fileVisualizza'} cardHeader={"File"} cardBody={

          <div>
            <>
              <div hidden={this.state.listaFiles.length === 0}>
                {
                  //recupero la lista di file dell'iscrizione
                  this.state.listaFiles.map((file) => (
                    <>
                      <div className='row'>
                        <Label style={{ fontWeight: 'bold' }}>{file.tipologiaAllegato}</Label>
                        <div className='row'>
                          <div className="d-flex">
                            <Icon icon="it-download" size="sm" />
                            <a onClick={() => this.getFile(file.idAttachment, file.filename)}>{file.filename}</a>
                            <span className='ms-5' hidden={file.codiceTipoAllegato !== "allegato_iot" || !file.descrizioneBreve}><b>Descrizione: </b>{file.descrizioneBreve}</span>
                          </div>
                        </div>
                      </div>
                      <br></br>
                    </>
                  ))}
              </div>
              <div hidden={this.state.listaFiles.length > 0}>
                <p>Nessun file presente</p>
              </div>
            </>
          </div>
        }>
        </GenericCard >

      </Form >
    ]

    if (this.state.isLoaded)
      return (
        <>{(this.props.visualizzaFlag) ? formVisualizza : formIscrizione}
          <GenericModal
            title={this.state.modalTitle}
            text={this.state.modalText}
            modalOpen={this.state.modalOpen}
            closeButton={this.closeModal}
          />
        </>
      )
    else return (<><GenericSpinner /></>)
  }
}
export declare interface DocumentiProps {
  activeTab?: any;
  tabClick?: any;
  data?: any;
  prevData?: any;
  prevClicked?: any;
  processoIscrizione?: any;
  modificaDatiFlag?: any;
  reiscrizioneFlag?: any;
  visualizzaFlag?: any;
  message?: any;
  getSelectedValue?: any;
  tabNumber?: number;
  statoIscrizione?: number;
}
