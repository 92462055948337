import React, {useEffect, useMemo, useState} from 'react'
import {Form} from 'design-react-kit'
import {useAreeProfessionaliForUser, useCompetenze, useSdafForUser} from 'src/hooks/useReqOptions'
import createWrpInput from 'src/frontend/app/components/Form/WrpInput'

import GenericCard from 'src/frontend/app/components/GenericCard'
import GenericSelect from 'src/frontend/app/components/Form/Select'
import {attivitaDisponibili} from 'src/formazione'

const FormDatiAttivita: React.FunctionComponent<FormDatiAttivitaProps> = ({
  title,
  data,
  listaSDAFPof,
  handleInput,
  onInitInput,
  errors,
  viewOnly,
  disableOverride,
}) => {
  const objKey = ''
  const WrpInput = useMemo(() => createWrpInput({ objKey, onInit: onInitInput, onChange: handleInput, viewOnly }), [])

  const listaSDAF = useSdafForUser()
  const listaCompetenze = useCompetenze()
  const listaAreeProfessionali = useAreeProfessionaliForUser()
  const filteredListaSDAF = useMemo(() => {
    return listaSDAFPof ? listaSDAF.filter((sdaf) => listaSDAFPof.some((sdafPof) => sdaf.id === Number(sdafPof.settore))) : listaSDAF
  }, [listaSDAF, listaSDAFPof])

  const [areaPrincipaleOptions, setAreaPrincipaleOptions] = useState([])
  const [areaSecondariaOptions, setAreaSecondariaOptions] = useState([])
  const [listaSDAFPerAttivita, setListaSDAFPerAttivita] = useState([])

  let listaAreeProfessionaliMap = {}
  let listaAree = []
  let listaMacroAree = []

  for (let i = 0; i < listaAreeProfessionali.length; i++) {
    if (listaAreeProfessionali[i].nr === '0') {
      listaMacroAree.push(listaAreeProfessionali[i])
      if (listaAree.length !== 0) {
        listaAreeProfessionaliMap[listaAreeProfessionali[i - 1].area] = listaAree
        listaAree = []
      }
    } else {
      listaAree.push(listaAreeProfessionali[i])
    }
  }

  if (listaMacroAree.length) {
    listaAreeProfessionaliMap[listaMacroAree[listaMacroAree.length - 1].area] = listaAree
  }

  useEffect(() => {
    const listaFiltered = []
    for (const area of listaAreeProfessionali) {
      if (area.nr !== '0') {
        listaFiltered.push(area)
      }
    }
    setAreaPrincipaleOptions(listaFiltered)
  }, [listaAreeProfessionali])

  useEffect(() => {
    const listaAreeProfessionaliSecondarieMap = listaAreeProfessionali
      .map((areaProfessionale) => {
        if (!areaProfessionale || (areaProfessionale.nr !== '0' && (!data.areaProfessionale || areaProfessionale.id !== data.areaProfessionale.id)))
          return areaProfessionale
      })
      .filter((obj) => {
        return obj !== undefined
      })

    setAreaSecondariaOptions(listaAreeProfessionaliSecondarieMap)
  }, [listaAreeProfessionali, data.areaProfessionale])

  useEffect(() => {
    if (filteredListaSDAF && data.codAttivita) {
      const codToCheck = data.codAttivita.value
      const newlist = []
      for (const sdaf of filteredListaSDAF) {
        if (sdaf.cod_formaz_attivita.toLowerCase() === codToCheck.toLowerCase()) {
          newlist.push(sdaf)
        }
      }

      if (newlist.length !== listaSDAFPerAttivita.length) {
        setListaSDAFPerAttivita(newlist)
        if (!data.settore || codToCheck.toLowerCase() !== data.settore.cod_formaz_attivita.toLowerCase()) {
          handleInput('settore', newlist[0])
        }
      }
    }
  }, [listaSDAFPerAttivita, filteredListaSDAF, data.codAttivita, data.settore])

  return (
    <GenericCard cardHeader={title}>
      <Form>
        <div className="row">
          <div className="col-12">
            <WrpInput
              as={GenericSelect}
              id={'codAttivita'}
              placeholder="Selezione Attività'"
              label="Attività"
              suffix="*"
              defaultOptions={attivitaDisponibili}
              data={data}
              errors={errors}
              disableOverride={disableOverride}
            />
          </div>
          <div className="col-12">
            <WrpInput
              as={GenericSelect}
              id={'settore'}
              placeholder="Selezione SDAF'"
              label="SDAF"
              suffix="*"
              defaultOptions={listaSDAFPerAttivita}
              data={data}
              errors={errors}
              disableOverride={disableOverride}
            />
          </div>
          <div className="col-12">
            <WrpInput
              as={GenericSelect}
              id={'codCompetenza'}
              placeholder="Selezione Competenza'"
              label="Competenza"
              suffix="*"
              defaultOptions={listaCompetenze}
              data={data}
              errors={errors}
              disableOverride={disableOverride}
            />
          </div>
          <div className="col-12">
            <WrpInput
              as={GenericSelect}
              id={'areaProfessionale'}
              placeholder="Selezione Prestazione Professionale Principale'"
              label="Prestazione Professionale Principale"
              suffix="*"
              defaultOptions={areaPrincipaleOptions}
              data={data}
              errors={errors}
              disableOverride={disableOverride}
            />
          </div>
          <div className="col-12">
            <WrpInput
              as={GenericSelect}
              isMulti={true}
              id={'areaProfessionale2'}
              placeholder="Selezione Prestazioni Professionali Secondarie'"
              label="Prestazioni Professionali Secondarie"
              defaultOptions={areaSecondariaOptions}
              data={data}
              errors={errors}
              disableOverride={disableOverride}
            />
          </div>
        </div>
      </Form>
    </GenericCard>
  )
}

interface FormDatiAttivitaProps {
  data: any
  listaSDAFPof?: any
  errors: any
  title: string
  handleInput: any
  onInitInput?: any
  viewOnly?: boolean
  disableOverride?: boolean
}

export default FormDatiAttivita
