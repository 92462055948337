import React, {useCallback, useEffect, useState} from 'react'
import {Alert, Button, Icon, Select, Spinner, Table} from 'design-react-kit'
import GenericCard from 'src/frontend/app/components/GenericCard'
import GenericInput from 'src/frontend/app/components/Form/Input'
import {getDefaultAxiosConfig, getFormazioneResourceApi} from 'src/app/utils/ManagerRestGateway'
import {GenericSpinner} from 'src/backoffice/app/components'
import {StyledCCFPForm} from './styles'

const FormDatiComposizioneCFP: React.FunctionComponent<FormDatiComposizioneCFPProps> = ({
  title,
  data,
  handleInput,
  onInitInput,
  errors,
  showErrorMessage,
  isDisabled,
}) => {
  const [listSdaf, setListStaf] = useState<any>(null)
  const [sdaf, setSdaf] = useState<any>(null)

  // Load data
  useEffect(() => {
    ;(async () => {
      try {
        const { data: piani } = await getFormazioneResourceApi().msfoFormazioneSettoriDisciplinariForUserGet(getDefaultAxiosConfig())
        setListStaf(
          piani.reduce((map, settore: any) => {
            map[settore.id] = {
              value: settore.id,
              label: settore.cod + '-' + settore.titolo,
              is_meta: settore.cod_formaz_attivita === 'AFM',
            }
            return map
          }, {})
        )
      } catch (e) {
        showErrorMessage("C'è stato un errore nel caricamento dei settori. Contattare il servizio di assistenza.")
      }
    })()
  }, [])

  // EVENT ROWS
  const addSdaf = useCallback(() => {
    if (sdaf == null) {
      return
    }
    const ccfp = data.pFormativo2016List || []
    if (ccfp.find((v) => v.id_settore == sdaf.value)) {
      return
    }
    ccfp.push({
      id_settore: sdaf.value,
      tot_cfp: '',
    })
    handleInput('pFormativo2016List', [...ccfp])
  }, [sdaf, data])

  const removeSdaf = useCallback(
    (index) => {
      data.pFormativo2016List.splice(index, 1)
      handleInput('pFormativo2016List', [...data.pFormativo2016List])
    },
    [data]
  )

  const getCfpRow = (row) => {
    return isNaN(row.tot_cfp) || row.tot_cfp == '' ? 0 : parseFloat(row.tot_cfp)
  }

  const composizioniCfp = data.pFormativo2016List || []
  const listError = errors || {}

  return (
    <StyledCCFPForm>
      <GenericCard cardHeader={title} className={'no-padding form-dati-composizione'}>
        {!isDisabled && (
          <div className="d-flex justify-content-end pt-2 align-items-center">
            <div className={'px-2'}>{listSdaf == null && <Spinner label={''} small={true} active={true} />}</div>
            <div className={'px-2 wrap-select'}>
              {listSdaf !== null && (
                <Select
                  className={'select-sdaf'}
                  placeholder={'Seleziona il settore'}
                  options={Object.keys(listSdaf).map((k) => listSdaf[k])}
                  onChange={(v) => {
                    setSdaf(v)
                  }}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      e.preventDefault();
                    }
                  }}
                />
              )}
            </div>
            <Button color={'secondary'} disabled={sdaf == null} onClick={addSdaf}>
              Aggiungi settore
            </Button>
          </div>
        )}
        {listSdaf == null && <GenericSpinner />}
        {listSdaf != null && (
          <div className={'list-compositions pt-3'}>
            {composizioniCfp.length == 0 && (
              <div className="pt-4">
                <Alert color="info">Non hai ancora inserito un settore</Alert>
              </div>
            )}
            {composizioniCfp.length > 0 && (
              <>
                <Table className={'form-table'}>
                  <thead>
                    <tr>
                      <th scope="col" className={'col-title'}>
                        Settore
                      </th>
                      <th scope="col" className={'col-cfp-input col-input-small'}>
                        Numero CFP Previsti
                      </th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {composizioniCfp.map((cdaf, i) => {
                      return (
                        <tr key={cdaf.id_settore}>
                          <td>{listSdaf[cdaf.id_settore] ? listSdaf[cdaf.id_settore].label : ''}</td>
                          <td>
                            <GenericInput
                              id={`pFormativo2016List.${i}.tot_cfp`}
                              type={'number'}
                              value={cdaf.tot_cfp}
                              step={'any'}
                              onChange={handleInput}
                              onInit={onInitInput}
                              hideErrorMessage={true}
                              isDisabled={isDisabled}
                              errorMessage={listError[`pFormativo2016List.${i}.tot_cfp`]}
                              onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                  e.preventDefault();
                                }
                              }}
                            />
                          </td>
                          <td>
                            {!isDisabled && (
                              <Button onClick={() => removeSdaf(i)}>
                                <Icon icon={'it-delete'} size="sm" />
                              </Button>
                            )}
                          </td>
                        </tr>
                      )
                    })}
                  </tbody>
                </Table>
                <div className={'totals pt-2'}>
                  <div className={'total-row'}>
                    <label>Totale CFP Caratterizzanti</label>
                    <span className={'label'}>
                      {composizioniCfp.reduce((tot, cdaf) => {
                        const settore = listSdaf[cdaf.id_settore] || {}
                        let totale = parseFloat(tot) + (!settore.is_meta ? getCfpRow(cdaf) : 0)
                        if (totale){
                          return (totale).toFixed(3)
                        }
                        else{
                          return 0
                        }
                      }, 0)}
                    </span>
                  </div>
                  <div className={'total-row'}>
                    <label>Totale CFP Metaprofessionali</label>
                    <span className={'label'}>
                      {composizioniCfp.reduce((tot, cdaf) => {
                        const settore = listSdaf[cdaf.id_settore] || {}
                        let totale = parseFloat(tot) + (settore.is_meta ? getCfpRow(cdaf) : 0)
                        if (totale){
                          return (totale).toFixed(3)
                        }
                        else{
                          return 0
                        }
                      }, 0)}
                    </span>
                  </div>
                  <div className={'total-row'}>
                    <label>Totale CFP</label>
                    <span className={'label'}>
                      {composizioniCfp.reduce((tot, cdaf) => {
                        let totale = parseFloat(tot) + getCfpRow(cdaf)
                        if (totale){
                          return (totale).toFixed(3)
                        }
                        else{
                          return 0
                        }
                      }, 0)}
                    </span>
                  </div>
                </div>
              </>
            )}
          </div>
        )}
        <GenericInput
          type={'hidden'}
          id={'check_ccfp'}
          isDisabled={isDisabled}
          value={data.check_ccfp}
          onInit={onInitInput}
          errorMessage={listError[`check_ccfp`]}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              e.preventDefault();
            }
          }}
        />
      </GenericCard>
    </StyledCCFPForm>
  )
}

interface FormDatiComposizioneCFPProps {
  onInitInput?: any
  handleInput?: any
  data?: any
  errors?: any
  title?: string
  showErrorMessage?: any
  isDisabled?: boolean
}

export default FormDatiComposizioneCFP
