import React, {useCallback, useEffect, useRef, useState} from 'react'
import {Link, useHistory, useLocation} from 'react-router-dom'
import useFrontOffice from 'src/hooks/useFrontOffice'
import {Button, PageSection, Title} from '@patternfly/react-core'
import {Label} from 'design-react-kit'
import styled from 'styled-components'

import {
    PATH_TO_BO_DETTAGLIO_AGENZIA,
    PATH_TO_BO_ELENCO_AGENZIE,
    PATH_TO_FO_DETTAGLIO_AGENZIA,
    PATH_TO_FO_ELENCO_AGENZIE
} from 'src/app/utils/RoutesConstants'
import {createFormData, getTabActiveFormId, handleChangeFormData, validateFormData} from 'src/app/utils/form-utils'
import formValidationRules from 'src/frontend/app/pages/iscrizioneAlboEF/formIscrizioneEF/FormRules'
import {requestMapperAgenzia, responseMapperAgenzia} from 'src/frontend/app/pages/iscrizioneAlboEF/form-utils'
import {DETAILS_ACTIONS, STATI_AGENZIE, STATI_AGENZIE_VALUES} from 'src/formazione'
import GenericWizard from 'src/backoffice/app/components/GenericWizard'

// Form components
import FormDatiAgenzia from 'src/frontend/app/pages/iscrizioneAlboEF/formIscrizioneEF/FormDatiAgenzia'
import FormDatiSedeLegale from 'src/frontend/app/pages/iscrizioneAlboEF/formIscrizioneEF/FormDatiSedeLegale'
import FormDatiRappresentanteLegale
    from 'src/frontend/app/pages/iscrizioneAlboEF/formIscrizioneEF/FormDatiRappresentanteLegale'
import FormDatiResponsabileAF from 'src/frontend/app/pages/iscrizioneAlboEF/formIscrizioneEF/FormDatiResponsabileAF'
import FormDatiAccreditamento from 'src/frontend/app/pages/iscrizioneAlboEF/formIscrizioneEF/FormDatiAccreditamento'
import FormDatiDichiarazioni from 'src/frontend/app/pages/iscrizioneAlboEF/formIscrizioneEF/FormDatiDichiarazioni'
import FormSubmit, {STATUS} from 'src/frontend/app/components/Form/GenericSubmit'

// Form azioni
import FormIntegrazione from 'src/backoffice/app/pages/formazione/formIscrizioneEF/FormIntegrazione'
import FormApprovazioneFormale from 'src/backoffice/app/pages/formazione/formIscrizioneEF/FormApprovazioneFormale'
import FormApprovazioneMerito from 'src/backoffice/app/pages/formazione/formIscrizioneEF/FormApprovazioneMerito'
import FormApprovazioneMinisteriale
    from 'src/backoffice/app/pages/formazione/formIscrizioneEF/FormApprovazioneMinisteriale'
import FormRigetto from 'src/backoffice/app/pages/formazione/formIscrizioneEF/FormRigetto'

import {getDefaultAxiosConfig, getFormazioneResourceApi} from 'src/app/utils/ManagerRestGateway'
import GenericBreadCrumb from 'src/frontend/app/components/GenericBreadCrumb'
import FormDatiAllegati from 'src/frontend/app/pages/iscrizioneAlboEF/formIscrizioneEF/FormDatiAllegati'
import GenericCard from 'src/frontend/app/components/GenericCard'
import {ottieniAllegato} from './formEntiEsterni/formUtils'
import moment from 'moment'
import {FlexDiv} from './DettaglioRichiestaEsonero'
import FormDatiFormatori from 'src/frontend/app/pages/iscrizioneAlboEF/formIscrizioneEF/FormDatiFormatori'
import FormProroga from './formIscrizioneEF/FormProroga'

const LinkStyled = styled.div`
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: end;
`
const Spacer = () => (
  <>
    <br></br>
  </>
)

const MyDiv = FlexDiv

const DettaglioAgenzia: React.FunctionComponent = (props) => {
  const isFrontOffice = useFrontOffice()
  const pathToElenco = isFrontOffice ? PATH_TO_FO_ELENCO_AGENZIE : PATH_TO_BO_ELENCO_AGENZIE
  const pathToDettaglio = isFrontOffice ? PATH_TO_FO_DETTAGLIO_AGENZIA : PATH_TO_BO_DETTAGLIO_AGENZIA
  const basePath = { label: 'Elenco Agenzie', link: pathToElenco }
  const myPath = {
    label: 'Dettaglio Agenzia',
    link: pathToDettaglio,
  }

  const history = useHistory()
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const id = searchParams.get('id')
  const [stato, setStato] = useState<any>()
  const modalita = searchParams.get('modalita')
  const viewOnly = modalita !== DETAILS_ACTIONS.MODIFICA && modalita !== DETAILS_ACTIONS.APPROVAZIONE_MERITO

  // Submit state
  const [editStatus, setEditStatus] = useState<any>()
  const [editMessage, setEditMessage] = useState<any>()

  const [loadingInvio, setLoadingInvio] = useState<any>(true)
  const [loadingSalva, setLoadingSalva] = useState<any>(true)


  // Form State
  const refForm = useRef<any>([])
  const [rawData, setRawData] = useState<any>({})
  const [data, setData] = useState<any>({})
  const [errors, setErrors] = useState<any>({})
  const formData = createFormData(data, setData, errors, setErrors)
  const [isProrogata, setIsProrogata] = useState<boolean>()
  const [logoPresente, setLogoPresente] = useState<boolean>()


  useEffect(() => {
    const doRequest = async (id) => {
      const { data: response } = await getFormazioneResourceApi().msfoFormazioneDettaglioAccAgenziaFormativaGet(id, getDefaultAxiosConfig())
      setRawData(response)
      const obj = await responseMapperAgenzia(response, modalita === DETAILS_ACTIONS.MODIFICA)
      setData(obj)
      setStato(response?.datiAccreditamento?.stato)

      if (response.datiAccreditamento.dataFineProroga !== null) {
        setIsProrogata(true);
      } else {
        setIsProrogata(false);
      }

      if (obj.datiAccreditamento?.idNaz !== null && obj.datiAccreditamento?.idNaz > 0){
        if(obj.stato == STATI_AGENZIE_VALUES.ACCREDITATA){
          setLogoPresente(true)
        }else{
          if(response.datiAccreditamento.dataFineProroga !== null){
            setLogoPresente(true)
          }
          else{
            setLogoPresente(false)
          }
        }
      }else{
        setLogoPresente(false)
      }
    }
    doRequest(id)
  }, [id])

  const onInitInput = (tab) => (cmp) => {
    refForm.current.push({ tab, cmp })
  }

  const handleInput = (code, value) => {
    handleChangeFormData(formData, code, value, formValidationRules)
  }

  const onBack = () => {
    history.replace(pathToElenco)
  }
  const isValid = useCallback(
    (toIndex) => {
      for (let index = 0; index <= toIndex; index++) {
        const inputs = getTabActiveFormId(refForm, index)
        formData.data.__IS_EDIT_MODE = modalita === DETAILS_ACTIONS.MODIFICA
        const isValid = validateFormData(formData, formValidationRules, inputs)
        if (!isValid) return false
      }
      return true
    },
    [formData]
  )

  const onSubmit = useCallback(
    async (e,isInviaRichiesta) => {
      try {
        if (!isValid(7) && isInviaRichiesta) {
          setEditStatus(STATUS.FAILURE)
          setEditMessage('Controllare i dati inseriti')
          window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
          return null
        }
        setEditStatus(STATUS.LOADING)

        const _data = await requestMapperAgenzia(formData.data)
        _data.datiAccreditamento.id = id
        if(isInviaRichiesta){
          _data.datiAccreditamento.stato = STATI_AGENZIE_VALUES.ISTRUTTORIA
          setLoadingSalva(false)
        }else
        {setLoadingInvio(false)}

        if (modalita === DETAILS_ACTIONS.MODIFICA && isInviaRichiesta) {
          _data.datiAccreditamento.stato = STATI_AGENZIE_VALUES.ISTRUTTORIA
        } else if (modalita === DETAILS_ACTIONS.APPROVAZIONE_MERITO) {
          _data.datiAccreditamento.stato = STATI_AGENZIE_VALUES.MERITO
          _data.datiAccreditamento.dataAccettazione = new Date().toISOString()
        }

        if ( stato === STATI_AGENZIE_VALUES.SCADUTA )
        {  _data.datiAccreditamento.data_rinnovo = moment().format('YYYY-MM-DD')
        }

        await getFormazioneResourceApi().msfoFormazioneAggiornaAgenziaFormativaPost(_data, getDefaultAxiosConfig())

        setEditStatus(STATUS.SUCCESS)
        setEditMessage('Dati salvati con successo')
        setTimeout(() => {
          onBack()
        }, 500)
        return { status: STATUS.SUCCESS, message: 'Dati salvati con successo' }
      } catch (e) {
        setEditStatus(STATUS.FAILURE)
        setEditMessage('Impossibile salvare i dati')
        setLoadingInvio(true)
        setLoadingSalva(true)
        return { status: STATUS.FAILURE, message: 'Impossibile salvare i dati' }
      }
    },
    [formData, id]
  )

  const forms = [
    <FormDatiAgenzia title="Dati Anagrafici" data={data} errors={errors} onInitInput={onInitInput(0)} handleInput={handleInput} viewOnly={viewOnly} />,
    <FormDatiSedeLegale title="Sede Legale" data={data} errors={errors} onInitInput={onInitInput(1)} handleInput={handleInput} viewOnly={viewOnly} />,
    <FormDatiRappresentanteLegale
      title="Rappresentante Legale"
      data={data}
      errors={errors}
      onInitInput={onInitInput(2)}
      handleInput={handleInput}
      viewOnly={viewOnly}
    />,
    <FormDatiResponsabileAF
      title="Responsabile Attività Formativa"
      data={data}
      errors={errors}
      onInitInput={onInitInput(3)}
      handleInput={handleInput}
      viewOnly={viewOnly}
    />,
    <FormDatiAccreditamento
      title="Dati Accreditamento"
      data={data}
      errors={errors}
      onInitInput={onInitInput(4)}
      handleInput={handleInput}
      viewOnly={viewOnly}
    />,
    <FormDatiFormatori title="Dati sui formatori" dataRef={data} onInitInput={onInitInput(5)} handleInput={handleInput} viewOnly={viewOnly} />,
    <FormDatiDichiarazioni title="Dichiarazioni" data={data} errors={errors} onInitInput={onInitInput(5)} handleInput={handleInput} viewOnly={viewOnly} />,
    <FormDatiAllegati title="Allegati" data={data} errors={errors} onInitInput={onInitInput(6)} handleInput={handleInput} viewOnly={viewOnly} />,
    isProrogata && ( <FormProroga title="Dati Proroga Accreditamento" id={id} dataAF={data} viewOnly={viewOnly} isProrogata={isProrogata}/> ),
  ]
  let steps = [
    {
      id: 0,
      name: 'Dati Anagrafici',
      component: forms[0],
    },
    {
      id: 1,
      name: 'Sede Legale',
      component: forms[1],
    },
    {
      id: 2,
      name: 'Rappresentante Legale',
      component: forms[2],
    },
    {
      id: 3,
      name: 'Responsabile Attività Formativa',
      component: forms[3],
    },
    {
      id: 4,
      name: 'Dati Accreditamento',
      component: forms[4],
    },
    {
      id: 4,
      name: 'Dati Formatori',
      component: forms[5],
    },
    {
      id: 5,
      name: 'Dichiarazioni',
      component: forms[6],
    },
    {
      id: 6,
      name: 'Allegati',
      component: forms[7],
    },
  ]
  if (isProrogata) {
    steps = [
      ...steps,
      {
        id: 7,
        name: 'Dati Proroga',
        component: forms[8],
      }
    ]
  }

  if (Object.keys(data).length === 0) {
    return null
  }

  let statoText = STATI_AGENZIE[stato]

  if (  rawData.datiAccreditamento.tipo_ag_form === 1 && rawData?.datiAccreditamento.dataFineProroga && moment(rawData.datiAccreditamento.dataFineProroga).isSameOrAfter( moment(), 'day') )
  {
    statoText += ' (Prorogata)'
  }

  if (
    rawData.datiAccreditamento.data_fine &&
    rawData.datiAccreditamento.tipo_ag_form === 2 &&
    rawData.datiAccreditamento.stato === STATI_AGENZIE_VALUES.ACCREDITATA &&
    moment().diff(rawData.datiAccreditamento.data_fine) >= 0
  ) {
    statoText = 'Scaduta'
  } else if (
    rawData.datiAccreditamento.data_accettazione &&
    !rawData.datiAccreditamento.data_fine &&
    rawData.datiAccreditamento.tipo_ag_form === 2 &&
    rawData.datiAccreditamento.stato === STATI_AGENZIE_VALUES.ACCREDITATA &&
    moment().diff(rawData.datiAccreditamento.data_accettazione, 'years', true) >= 3
  ) {
    statoText = 'Scaduta'
  }

  if (
    rawData.datiAccreditamento.data_fine &&
    rawData.datiAccreditamento.tipo_ag_form === 3 &&
    rawData.datiAccreditamento.stato === STATI_AGENZIE_VALUES.ACCREDITATA &&
    moment().diff(rawData.datiAccreditamento.data_fine) >= 0
  ) {
    statoText = 'Scaduto'
  }
  if (rawData.datiAccreditamento.tipo_ag_form === 3 && rawData.datiAccreditamento.stato === STATI_AGENZIE_VALUES.REVOCATA) {
    statoText = 'Cancellato'
  }

  if (!statoText) {
    statoText = 'N/D'
  }

  return (
    <PageSection>
      <div className="container  register-container">
        <GenericBreadCrumb paths={[basePath, myPath]} />
        <Spacer />

        <Title headingLevel="h1" size="lg">
          {myPath.label}
        </Title>

        <MyDiv>
          {logoPresente && (
            <Button
              variant="primary"
              onClick={() => {
                let idAllegato: string | null = data.logoAllegato.idAllegato

                if (idAllegato) {
                  ottieniAllegato(idAllegato)
                } else {
                  console.error('IDALLEGATO MISSING')
                }
              }}
            >
              Scarica logo
            </Button>
          )}

          {stato >= 0 && <p>Stato : {statoText}</p>}

          {data && data.stato === STATI_AGENZIE_VALUES.ACCREDITATA && <p>ID : {id}</p>}
        </MyDiv>

        <Spacer />

        {modalita === DETAILS_ACTIONS.PROROGA && <FormProroga id={id} isProrogata={isProrogata} dataAF={data} />}
        {modalita === DETAILS_ACTIONS.RICHIESTA_INTEG && <FormIntegrazione id={id} />}
        {modalita === DETAILS_ACTIONS.APPROVAZIONE_FORMALE && <FormApprovazioneFormale id={id} />}
        {modalita === DETAILS_ACTIONS.APPROVAZIONE_MERITO && <FormApprovazioneMerito id={id} submitMerito={onSubmit} />}
        {modalita === DETAILS_ACTIONS.APPROVAZIONE_MINISTERIALE && <FormApprovazioneMinisteriale id={id} />}
        {modalita === DETAILS_ACTIONS.RIGETTA && <FormRigetto id={id} stato={stato} />}
        {modalita === DETAILS_ACTIONS.DETTAGLIO && stato === STATI_AGENZIE_VALUES.INTEGRAZIONE && (
              <GenericCard cardHeader={<Title headingLevel="h2">Sono richieste integrazioni. Motivo:</Title>}>
                <Title headingLevel="h3">{data.datiAccreditamento.integrazione}</Title>
              </GenericCard>
            )}
        {modalita === DETAILS_ACTIONS.MODIFICA ? (
          <>
            {stato === STATI_AGENZIE_VALUES.INTEGRAZIONE && (
              <GenericCard cardHeader={<Title headingLevel="h2">Sono richieste integrazioni. Motivo:</Title>}>
                <Title headingLevel="h3">{data.datiAccreditamento.integrazione}</Title>
              </GenericCard>
            )}

            {( stato === STATI_AGENZIE_VALUES.SCADUTA) ? (
              <FormSubmit title="Conferma rinnovo" buttonLabel="Rinnova" alertMessage={editMessage} status={editStatus} onSubmit={(e)=>{
                onSubmit(e,true)
             }}>
                <Label>Per rinnovare l'agenzia cliccare "Rinnova".</Label>
              </FormSubmit>
            ) : ( <>
            { loadingSalva && (
              <FormSubmit title="Conferma modifica" buttonLabel="Modifica" alertMessage={editMessage} status={editStatus} onSubmit={onSubmit} >
                <Label>Per modificare i dati in compilazione cliccare "Modifica".</Label>
              </FormSubmit>
            )}
            { loadingInvio && (
              <FormSubmit title="Invia richiesta" buttonLabel="Invia Richiesta" alertMessage={editMessage} status={editStatus}
                onSubmit={(e)=>{
                   onSubmit(e,true)
                }}>
                <Label>Per inviare la richiesta di iscrizione cliccare "Invia Richiesta".</Label>
              </FormSubmit>
            )}
           </> )}
            {forms}
            <LinkStyled>
              <Link to={pathToElenco}>Torna alla lista</Link>
            </LinkStyled>
          </>
        ) : (
          <GenericWizard
            steps={steps}
            backButtonText={'PRECEDENTE'}
            nextButtonText={'SUCCESSIVO'}
            cancelButtonText={'Torna alla lista'}
            onClickNext={null}
            onClickCancel={onBack}
            height="auto"
          />
        )}
      </div>
      <PageSection>
        <div className="container register-container">
          <Title headingLevel="h3">Legenda</Title>
          <p>* Campo obbligatorio</p>
          <p>** Campo non modificabile compilato automaticamente</p>
        </div>
      </PageSection>
    </PageSection>
  )
}

export default DettaglioAgenzia
