/* eslint-disable react/no-direct-mutation-state */
import * as React from 'react'
import GenericSpinner from 'src/backoffice/app/components/GenericSpinner'
import FormListaDisciplinari from './form/FormListaDisciplinari'
import {creaNuovaDisciplinare} from 'src/processes/disciplinari/Disciplinari'
import {
    PATH_TO_BO_ANAG_ELENCO_ISCRITTI,
    PATH_TO_BO_ATTI_DISCIPLINARI,
    PATH_TO_BO_ATTI_DISCIPLINARI_NUOVA
} from 'src/app/utils/RoutesConstants'
import GenericModal from '../../components/GenericModal'
import {convertUrlParamsToObject, inputRequiredValidation} from 'src/utilities/utility'
import GenericAlert from 'src/frontend/app/components/GenericAlert'
import GenericBreadCrumb from 'src/frontend/app/components/GenericBreadCrumb'
import {isCurrentMobileDevice} from 'src/utilities/deviceUtility'

const isMobileDivice = isCurrentMobileDevice();
var breadcrumbOptions = [
  { label: "Anagrafica", link: "#" },
  { label: "Elenco iscritti", link: PATH_TO_BO_ANAG_ELENCO_ISCRITTI},
  { label: "Atti disciplinari", link: PATH_TO_BO_ATTI_DISCIPLINARI_NUOVA },
  { label: "Nuova Sanzioni Disciplinari", link: PATH_TO_BO_ATTI_DISCIPLINARI_NUOVA },
]

class CreazioneSanzioneDisciplinari extends React.Component<any , any>{
    constructor(props) {
      super(props);
      this.state = {
          idIscrizione: undefined, //idIscrizione is passed with props and next will be a GET to take data from db
          message:"",
          isSubmitted:false,
          fields: {},
          disciplinari : {},
          errors: [],
          modalOpen:false,
          modalText:"testo finestra modale",
          modalTitle:"titolo finestra modale"
      };
  }
  goToDisciplinari = (redirectLink: any) => {
    this.props.history.push(PATH_TO_BO_ATTI_DISCIPLINARI + '?cf=' + this.state.cf + '&idIscrizione=' + this.state.idIscrizione + '&tipologiaIscrizione=' + this.state.tipologiaIscrizione);
  }
  async componentDidMount(): Promise<void> {
    this.setState({ isLoaded : true })
    var url = window.location.search?.split("?")[1]
    var urlParams = convertUrlParamsToObject(url)

    if (!urlParams?.id || !urlParams?.idIscrizione || !urlParams?.tipologiaIscrizione || !urlParams?.cf) {
      this.props.history.push(PATH_TO_BO_ANAG_ELENCO_ISCRITTI);
    }
    else {
      let idIscrizione = urlParams?.idIscrizione
      let tipologiaIscrizione = urlParams?.tipologiaIscrizione
      let cf = urlParams?.cf
      this.setState({ idIscrizione: idIscrizione, tipologiaIscrizione: tipologiaIscrizione, cf: cf})
    }
  }
  inviaDisciplinari = async (disciplinari, showSedeFlag, showDateProvvedimento, showDataProvvedimentoAl) => {
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
      this.setState({isLoaded: false})
      /*--------------------------------------------- Validazione campi obbligatori ------------------------------------------------*/
      var requiredFields = [
        { value: disciplinari.procedimentoNr, label: 'Procedimento nr' },
        { value: disciplinari.causale, label: 'Causale' },
        { value: disciplinari.organo ? disciplinari.organo : 'cassazione', label: 'Organo' },
        showSedeFlag ? { value: disciplinari.chiProcede, label: 'Sede' } : null,
        { value: disciplinari.dataApertura, label: 'Data apertura' },
        { value: disciplinari.dataChiusura, label: 'Data chiusura' },
        { value: disciplinari.determinazione, label: 'Determinazione' },
        { value: disciplinari.provvedimento ? disciplinari.provvedimento : 'avvertimento', label: 'Tipo provvedimento' },
        { value: disciplinari.dataProvvedimento, label: 'Data delibera' },
        { value: disciplinari.deliberaNr, label: 'Delibera Nr' },
        showDateProvvedimento ? { value: disciplinari.provvedimentoDal, label: 'Data inizio provvedimento' } : null,
        showDateProvvedimento && showDataProvvedimentoAl && disciplinari.provvedimento !==  'sospensione-morosita' ? { value: disciplinari.provvedimentoAl, label: 'Data fine provvedimento' } : null,
        { value: disciplinari.motivazione, label: 'Motivazione' },
      ]
      var errorRequired: any = inputRequiredValidation(requiredFields)

    if (errorRequired !== undefined && errorRequired !== "") {
      return this.setState({ message: errorRequired.messaggio, typeMessage: errorRequired.type, isLoaded: true })
    }
    /*------------------------------------------------------------------------------------------------------------------------------*/

    else {
        /************************** Chiamata per l'invio finale ******************************/
        var richiestaDisciplinari = {
          idIscrizione: this.state.idIscrizione,
          tipologiaIscrizione: this.state.tipologiaIscrizione,
        }
        creaNuovaDisciplinare(richiestaDisciplinari, this.state.disciplinari).then(response => {
            this.setState({ message: "Dati salvati con successo", typeMessage: "success", isLoaded: true })
            this.showModal(true, "Creazione avvenuta con successo", "Il tuo provvedimento - N. delibera " + response.deliberaNr + " - è stato creato con successo!");

        }).catch((err) => {
            this.setState({ message: "C'è stato un problema nell'invio dei dati: "+ err.message.replace("Error:", "").replace("Service", ""), typeMessage: "danger", isLoaded: true })
            this.setState({ richiestaDisciplinari: richiestaDisciplinari })
        })
    }
  }
  /** Funzione per aggiornare i campi del form */
  getSelectValues = async (fields: any) => {
      await this.setState({fields: fields, data: fields, disciplinari: fields})
      this.setState({isLoaded: true})
  }

  showModal = async (booleanValue, title, text) => {
        await this.setState({
            modalOpen: booleanValue,
            modalTitle: title,
            modalText:text,
            isLoaded: true,
        });
  }

  closeModal = () => {
        this.setState({modalOpen:false});
        this.props.history.push(PATH_TO_BO_ATTI_DISCIPLINARI + '?cf=' + this.state.cf + '&idIscrizione=' + this.state.idIscrizione + '&tipologiaIscrizione=' + this.state.tipologiaIscrizione);
  }
  render() {

    const { typeMessage, message} = this.state;

    return(
      <div key={"nuovaSanzioneDisciplinare"} id='formDisciplinari' className='container-fluid'>
          <div className="container-fluid">
            <div className='row'>
                <div className={!isMobileDivice ? 'col-10 offset-1' : 'col-12'}>
                    {
                    (this.state.isLoaded) ?
                      <div>
                          <div className="row">
                          <div className={!isMobileDivice ? 'col-10 offset-1 mt-5' : 'col-12'}>
                              <GenericBreadCrumb paths={breadcrumbOptions} /> <br></br><br></br>
                              <GenericAlert hidden={message === ''} label={message} color={typeMessage}></GenericAlert>
                            </div>
                          </div>
                          <FormListaDisciplinari inviaDisciplinari={this.inviaDisciplinari} data={this.state.disciplinari} prevData={this.state.disciplinari} getSelectedValue={this.getSelectValues} disciplinari={this.state.disciplinari} visualizzaFlag={false} modificaDatiFlag={false} goToDisciplinari={this.goToDisciplinari}/>
                          <GenericModal
                                title={this.state.modalTitle}
                                text={this.state.modalText}
                                modalOpen={this.state.modalOpen}
                                closeButton={this.closeModal}
                              />
                      </div>
                      : <GenericSpinner></GenericSpinner>
                    }
                </div>
            </div>
          </div>
      </div>
    )
  }
};
export declare interface InserisciProps {
  idIscrizione?: any;
  modificaDatiFlag?:any;
  visualizzaFlag?:any;
  disciplinari?:any;
}

export default CreazioneSanzioneDisciplinari  ;
