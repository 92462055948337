import React, {useMemo} from 'react'
import {Form} from 'design-react-kit'
import createWrpInput from 'src/frontend/app/components/Form/WrpInput'
import GenericCard from 'src/frontend/app/components/GenericCard'
import GenericInput from 'src/frontend/app/components/Form/Input'
import {isCurrentMobileDevice} from 'src/utilities/deviceUtility'

const FormDichiarazioni: React.FunctionComponent<FormDatiSedeLegaleProps> = ({ title, data, handleInput, onInitInput, errors, viewOnly }) => {
  const objKey = ''
  const WrpInput = useMemo(() => createWrpInput({ objKey, onInit: onInitInput, onChange: handleInput, viewOnly }), [])

  return (
    <GenericCard cardHeader={title}>
      <Form>
        <div className="row">
          <div className={isCurrentMobileDevice() ? null : 'col-4'}>
            <WrpInput as={GenericInput} id="titolo_accademico" type="text" label="Titolo Accademico" suffix="" data={data} errors={errors} />
          </div>
          <div className={isCurrentMobileDevice() ? null : 'col-4'}>
            <WrpInput as={GenericInput} id="titolo_professionale" type="text" label="Titolo Professionale" suffix="" data={data} errors={errors} />
          </div>
          <div className={isCurrentMobileDevice() ? null : 'col-4'}>
            <WrpInput as={GenericInput} id="altro_titolo" type="text" label="Altro" suffix="" data={data} errors={errors} />
          </div>
        </div>
        <div className="row">
          <div className={isCurrentMobileDevice() ? null : 'col-6'}>
            <WrpInput as={GenericInput} id="possesso_diploma" type="checkbox" label="In possesso di diploma" suffix="" data={data} errors={errors} />
          </div>
          <div className={isCurrentMobileDevice() ? null : 'col-6'}>
            {data?.possesso_diploma && <WrpInput as={GenericInput} id="tipo_diploma" type="text" label="Tipo diploma" suffix="*" data={data} errors={errors} />}
          </div>
        </div>
        <div className="row">
          <div className={isCurrentMobileDevice() ? null : 'col-6'}>
            <WrpInput as={GenericInput} id="possesso_laurea" type="checkbox" label="In possesso di laurea" suffix="" data={data} errors={errors} />
          </div>
          <div className={isCurrentMobileDevice() ? null : 'col-6'}>
            {data?.possesso_laurea && <WrpInput as={GenericInput} id="tipo_laurea" type="text" label="Tipo di laurea" suffix="*" data={data} errors={errors} />}
          </div>
        </div>
        <div className="row">
          <div className={isCurrentMobileDevice() ? null : 'col-6'}>
            <WrpInput
              as={GenericInput}
              id="possesso_laurea_magistrale"
              type="checkbox"
              label="In possesso di laurea magistrale"
              suffix=""
              data={data}
              errors={errors}
            />
          </div>
          <div className={isCurrentMobileDevice() ? null : 'col-6'}>
            {data?.possesso_laurea_magistrale && (
              <WrpInput as={GenericInput} id="tipo_laurea_magistrale" type="text" label="Tipo di laurea magistrale" suffix="*" data={data} errors={errors} />
            )}
          </div>
        </div>
      </Form>
    </GenericCard>
  )
}

interface FormDatiSedeLegaleProps {
  data: any
  errors: any
  title: string
  handleInput: any
  onInitInput?: any
  viewOnly?: boolean
}

export default FormDichiarazioni
