import React, {useEffect, useState} from 'react'
import styled from 'styled-components'
import {useHistory} from 'react-router-dom'
import {PageSection, Title} from '@patternfly/react-core'

import {PATH_TO_BO_DETTAGLIO_POF, PATH_TO_BO_ELENCO_POF} from 'src/app/utils/RoutesConstants'

import {getFormazioneResourceApi} from 'src/app/utils/ManagerRestGateway'
import GenericBreadCrumb from 'src/frontend/app/components/GenericBreadCrumb'
import {GenericAsyncTableData, RequestData} from 'src/backoffice/app/components/GenericAsyncTableData'
import {generateRequestFilter, generateRequestSort} from 'src/app/utils/ApiUtils'
import {getFiltersYear, LABEL_STATI, STATI} from 'src/model/PianiFormativi'
import {getAllEnti, LABEL_ENTI} from 'src/model/EnteFormazione'
import GenericSpinner from 'src/backoffice/app/components/GenericSpinner'
import {ACTION_APPROVA, ACTION_RICHIEDI_INFO, ACTION_RIGETTA, ACTION_SEGNALA} from './DettagliPOF'

const StyledList = styled.div`
  .filter-prov {
    min-width: 300px !important;
  }
`

const RichiestePOF: React.FunctionComponent = () => {
  const history = useHistory()
  const [enti, setListEnti] = useState<any>(null)

  useEffect(() => {
    ;(async () => {
      try {
        setListEnti(await getAllEnti())
      } catch (e) {}
    })()
  }, [])

  const loadData = async (request: RequestData) => {
    const { data } = await getFormazioneResourceApi().msfoFormazioneListaPformativiGet(
      generateRequestFilter(request.filters),
      request.pageSize,
      request.currentPage,
      generateRequestSort(request.sortColumn, request.sortDirection)
    )

    return {
      items: data.items || [],
      total: data.count || 0,
    }
  }

  const getRowActions = (row) => {
    const handleShowPof = (id, action) => {
      history.push(`${PATH_TO_BO_DETTAGLIO_POF}?id=${id}&action=${action}`)
    }
    const actions = [{ label: 'Dettagli', onClick: () => handleShowPof(row.id, 0) }]
    if (row.stato === STATI.PRESENTATA) {
      actions.push({ label: 'Approva', onClick: () => handleShowPof(row.id, ACTION_APPROVA) })
      actions.push({ label: 'Rigetta', onClick: () => handleShowPof(row.id, ACTION_RIGETTA) })
      actions.push({ label: 'Richiedi integrazioni', onClick: () => handleShowPof(row.id, ACTION_RICHIEDI_INFO) })
    }
    if (row.tipo_ente_ric && row.tipo_ente_ric !== 'af') {
      actions.push({ label: 'Segnala non conformità', onClick: () => handleShowPof(row.id, ACTION_SEGNALA) })
    }
    return actions
  }

  return (
    <PageSection>
      <div className="container register-container">
        <GenericBreadCrumb
          paths={[
            {
              label: 'Piani Formativi',
              link: PATH_TO_BO_ELENCO_POF,
            },
          ]}
        />
        <Title headingLevel="h1" size="lg">
          Richieste Piani Formativi
        </Title>
        <StyledList>
          {enti == null && <GenericSpinner label={''} />}
          {enti != null && (
            <GenericAsyncTableData
              tableKey={'lista.richieste.pof'}
              emptyMessage={'Nessun piano formativo trovato'}
              filters={[
                {
                  field: 'anno',
                  label: 'Anno',
                  type: 'select',
                  options: getFiltersYear(2011).map((y) => {
                    return { value: y, label: y }
                  }),
                },
                {
                  field: 'stato',
                  label: 'Stato',
                  type: 'select',
                  options: Object.keys(LABEL_STATI).map((s) => {
                    return { value: s, label: LABEL_STATI[s] }
                  }),
                },
                {
                  field: 'prov',
                  label: 'Ente',
                  type: 'select',
                  options: Object.keys(LABEL_ENTI).map((type) => {
                    return {
                      label: LABEL_ENTI[type],
                      options: Object.keys(enti)
                        .filter((code) => enti[code].tipo == type)
                        .map((code) => {
                          return {
                            label: enti[code].nome,
                            value: code,
                          }
                        }),
                    }
                  }),
                },
              ]}
              columns={[
                { label: 'Anno', field: 'anno', isSortable: true },
                {
                  label: 'Tipo Ente',
                  field: 'tipo_ente_ric',
                  isSortable: true,
                  render: (row) => {
                    return row.enteFormazione?.tipo_ag_form === 3 ? 'Ente Esterno' : LABEL_ENTI[row.tipo_ente_ric]
                  },
                },
                {
                  label: 'Ente',
                  field: 'prov',
                  isSortable: true,
                  render: (row) => {
                    return row.enteFormazione ? row.enteFormazione.name : row.prov
                  },
                },

                {
                  label: 'Stato',
                  field: 'stato',
                  options: LABEL_STATI,
                  isSortable: true,
                  render: (row) => {
                    return row?.stato && row.stato === 5 ? 'Segnalato' : LABEL_STATI[row.stato]
                  },
                },
                {
                  label: 'Totale CFP',
                  field: 'stato',
                  render: (row) => {
                    if (row.pFormativo2016List) {
                      return row.pFormativo2016List
                        .reduce((total, row) => {
                          const cfu = parseFloat(row.tot_cfp)
                          return total + (isNaN(cfu) ? 0 : cfu)
                        }, 0)
                        .toFixed(2)
                    }
                    return 0
                  },
                },
              ]}
              getRowActions={getRowActions}
              getRows={loadData}
            />
          )}
        </StyledList>
        <div style={{ height: 100 }}></div>
      </div>
    </PageSection>
  )
}

export default RichiestePOF
