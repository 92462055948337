import {Form, Label} from 'design-react-kit'
import * as React from 'react'
import GenericSpinner from 'src/backoffice/app/components/GenericSpinner'
import GenericAlert from 'src/frontend/app/components/GenericAlert'
import GenericSelect from 'src/frontend/app/components/GenericSelect'
import GenericTableCompact from 'src/frontend/app/components/GenericTableCompact'
import GenericUploadFront from 'src/frontend/app/components/GenericUpload'
import {getDatiQuotaIscrizioneByOrdine} from 'src/processes/ProcessoIscrizione'
import {isCurrentMobileDevice} from 'src/utilities/deviceUtility'
import {getBigIntToConvertToStringSiNo} from 'src/utilities/utility'

/* ----------------------------------- VARIABILI E COSTANTI ----------------------------------------- */
const optionsRichAssegnazionePec = [
  { label: 'Si', value: 1 },
  { label: 'No', value: 0 },
]
const statoCompilazione = 1
/* -------------------------------------------------------------------------------------------------- */

export class FormDocumentiIscrizioneStpFO extends React.Component<GeneralFormDocumentiIscrizioneStpFOProps, any> {
  constructor(props) {
    super(props)
    this.state = {
      dataLoaded: false,
    }
  }

  async componentDidMount() {
    var iscrizione = this.props.datiIscrizione?.iscrizioneOrdineTerritorialeDTO
    var richiestaPecSelected =
      iscrizione?.flagRichPec !== undefined && iscrizione?.flagRichPec !== null
        ? { label: getBigIntToConvertToStringSiNo(iscrizione?.flagRichPec), value: iscrizione?.flagRichPec }
        : undefined
    let fileNameBollo = this.props.listaFiles?.filter((e) => (e.codiceTipoAllegato ? e.codiceTipoAllegato : e.tipologiaAllegato) === 'bollo_stp')[0]?.filename
    let fileNameTassa = this.props.listaFiles?.filter((e) => (e.codiceTipoAllegato ? e.codiceTipoAllegato : e.tipologiaAllegato) === 'tassa_stp')[0]?.filename
    let fileNameQuotaIscrizione = this.props.listaFiles?.filter(
      (e) => (e.codiceTipoAllegato ? e.codiceTipoAllegato : e.tipologiaAllegato) === 'quota_iscrizione_stp'
    )[0]?.filename
    let idAttachmentBollo = this.props.listaFiles?.filter((e) => (e.codiceTipoAllegato ? e.codiceTipoAllegato : e.tipologiaAllegato) === 'bollo_stp')[0]
      ?.idAttachment
    let idAttachmentTassa = this.props.listaFiles?.filter((e) => (e.codiceTipoAllegato ? e.codiceTipoAllegato : e.tipologiaAllegato) === 'tassa_stp')[0]
      ?.idAttachment
    let idAttachmentIscrizione = this.props.listaFiles?.filter(
      (e) => (e.codiceTipoAllegato ? e.codiceTipoAllegato : e.tipologiaAllegato) === 'quota_iscrizione_stp'
    )[0]?.idAttachment

    var ordine = this.props.ordineTerritoriale.value ? this.props.ordineTerritoriale.value : this.props.ordineTerritoriale
    await getDatiQuotaIscrizioneByOrdine(ordine).then(async (response) => {
      if (response && response.data && response.data.httpStatus === 200) {
        var quote: any = []
        response.data.returnedObject.map((item, id) => {
          quote[id] = {
            anno: item.anno,
            categoria: item.categoria,
            quota: item.quotaAssociativa,
          }
        })
        this.setState({ datiQuotaIscrizione: quote })
      } else {
        this.setState({ messageQuotaMancante: response.data.error, datiQuotaIscrizione: [] })
      }
    })

    this.setState({
      dataLoaded: true,
      richiestaPecSelected: richiestaPecSelected,
      fileNameBollo: fileNameBollo,
      fileNameTassa: fileNameTassa,
      fileNameQuotaIscrizione: fileNameQuotaIscrizione,
      idAttachmentBollo: idAttachmentBollo,
      idAttachmentTassa: idAttachmentTassa,
      idAttachmentIscrizione: idAttachmentIscrizione,
    })
  }

  handleChange = (event, fieldId, type) => {
    if (type === 'input' && event.target.id === fieldId) this.props.formData({ value: event.target.value })
    else if (type === 'select' && fieldId === 'idFlagRichPec') {
      this.props.formData({ id: fieldId, value: event.value })
    }
  }

  render() {
    const { tabNumber } = this.props
    const {
      dataLoaded,
      richiestaPecSelected,
      fileNameBollo,
      fileNameTassa,
      fileNameQuotaIscrizione,
      idAttachmentBollo,
      idAttachmentTassa,
      idAttachmentIscrizione,
      datiQuotaIscrizione,
      messageQuotaMancante,
    } = this.state

    const getDatiBollo = (fileData) => {
      this.props.fileBollo(fileData)
    }

    const getDatiTassa = (fileData) => {
      this.props.fileTassa(fileData)
    }

    const getDatiQuotaIscrizione = (fileData) => {
      this.props.fileQuotaIscrizione(fileData)
    }

    return (
      <>
        {dataLoaded === true ? (
          <div>
            <Form>
              <div>
                <div className="row">
                  <div className={isCurrentMobileDevice() ? null : 'col-6'}>
                    <GenericSelect
                      defaultValue={richiestaPecSelected}
                      elementId={'idFlagRichPec'}
                      label={'Richiesta assegnazione PEC*'}
                      placeholder={'Si/No'}
                      defaultOptions={optionsRichAssegnazionePec}
                      errorMessageAlert={this.props.message}
                      onChange={(e) => this.handleChange(e, 'idFlagRichPec', 'select')}
                      currentStep={tabNumber}
                      isRequired
                      isDisabled={this.props.statoIscrizione !== statoCompilazione}
                    />
                  </div>
                </div>
                <p>È necessario allegare la copia del pagamento del bollo, della tassa di concessione governativa e della quota di iscrizione</p>
                <br></br>
                <b>
                  <p>Pagamenti</p>
                </b>
                <br></br>
                <div className="row">
                  <div className="col">
                    <div className="row">
                      <Label for="bolloID">
                        <strong>Bollo*</strong>
                      </Label>
                    </div>
                    <div className="row">
                      <div className={isCurrentMobileDevice() ? null : 'col-5'}>
                        <GenericUploadFront
                          name={'Bollo'}
                          value={fileNameBollo}
                          idAttachment={idAttachmentBollo}
                          datiAllegato={getDatiBollo}
                          tipologiaAllegato={'bollo_stp'}
                          acceptedExtensionFile={['pdf']}
                          id={'idBollo'}
                          errorMessageAlert={this.props.message}
                          currentStep={tabNumber}
                          isRequired
                          isDisabled={this.props.statoIscrizione !== statoCompilazione}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col">
                    <div className="row">
                      <Label for="tassaId">
                        <strong>Tassa di concessione governativa*</strong>
                      </Label>
                    </div>
                    <div className="row">
                      <div className={isCurrentMobileDevice() ? null : 'col-5'}>
                        <GenericUploadFront
                          name={'Tassa di concessione governativa'}
                          value={fileNameTassa}
                          idAttachment={idAttachmentTassa}
                          datiAllegato={getDatiTassa}
                          tipologiaAllegato={'tassa_stp'}
                          acceptedExtensionFile={['pdf']}
                          id={'idTassa'}
                          errorMessageAlert={this.props.message}
                          currentStep={tabNumber}
                          isRequired
                          isDisabled={this.props.statoIscrizione !== statoCompilazione}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <Label for="quotaIscrizioneID">
                      <strong>Quota di iscrizione*</strong>
                    </Label>
                  </div>
                </div>
                <div className="row">
                  <div className={isCurrentMobileDevice() ? null : 'col-5'}>
                    <GenericUploadFront
                      name={'Quota di iscrizione'}
                      value={fileNameQuotaIscrizione}
                      datiAllegato={getDatiQuotaIscrizione}
                      idAttachment={idAttachmentIscrizione}
                      tipologiaAllegato={'quota_iscrizione_stp'}
                      acceptedExtensionFile={['pdf']}
                      id={'idQuotaIscrizione'}
                      errorMessageAlert={this.props.message}
                      currentStep={tabNumber}
                      isRequired
                      isDisabled={this.props.statoIscrizione !== statoCompilazione}
                    />
                  </div>
                  {datiQuotaIscrizione && datiQuotaIscrizione?.length > 0 ? (
                    <>
                      <div className={isCurrentMobileDevice() ? null : 'col-5'}>
                        <GenericTableCompact
                          style={{ border: '1px solid grey' }}
                          columns={['Anno', 'Categoria', 'Quota']}
                          rows={datiQuotaIscrizione?.map((quota) => (
                            <tr>
                              <td>{quota?.anno ? quota.anno : '-'}</td>
                              <td>{quota?.categoria ? quota.categoria : '-'}</td>
                              <td>{quota?.quota ? quota.quota : '-'} €</td>
                            </tr>
                          ))}
                        />
                      </div>
                    </>
                  ) : (
                    <div className="col">
                      <GenericAlert label={messageQuotaMancante} color="info" />
                    </div>
                  )}
                </div>
              </div>
            </Form>
          </div>
        ) : (
          <GenericSpinner />
        )}
      </>
    )
  }
}

export declare interface GeneralFormDocumentiIscrizioneStpFOProps {
  message?: any
  tabNumber?: number
  formData?: any
  fileMandato?: any
  fileBollo?: any
  fileTassa?: any
  fileQuotaIscrizione?: any
  datiIscrizione?: any
  listaFiles?: any
  composizioneSocietaria?: any
  statoIscrizione?: number
  ordineTerritoriale?: any
}
