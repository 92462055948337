import * as React from 'react'
import {PageSection} from '@patternfly/react-core'
import GenericBreadCrumb from 'src/frontend/app/components/GenericBreadCrumb'
import GenericAlert from '../components/GenericAlert'
import {PATH_TO_FO_COOKIE} from 'src/app/utils/RoutesConstants'

/* ----------------------------------- VARIABILI E COSTANTI ----------------------------------------- */
const title = 'Informazioni cookie'

var breadcrumbOptions = [
    { label: "Informativa cookie", link: PATH_TO_FO_COOKIE }
]

class Cookie extends React.Component<any, any> {
    constructor(props) {
        super(props);
        this.state = {
            message: "",
            typeMessage: "default"
        };
    }

    componentDidMount() {}

    render() {
        const { typeMessage, message, idSoggetto } = this.state
        return (
            <div className='row'>
                <PageSection id='pageSectionId'>
                    <div className='container register-container'>
                        <GenericBreadCrumb paths={breadcrumbOptions}></GenericBreadCrumb>
                        <div><h1>{title}</h1></div>
                        <GenericAlert hidden={message === ''} label={message} color={typeMessage}></GenericAlert>
                        <div>
                        <p>
                            <h2>Informazioni sui cookie del sito web https://www.sidafonline.it</h2>
                            <p>Questo documento descrive l’utilizzo dei cookie all'interno del sito web costituisce parte integrante della “Privacy Policy” di [inserire ragione sociale del titolare del trattamento]</p>
                            <p>Per rendere il nostro sito più facile ed intuitivo utilizziamo i cookie. I cookie sono piccole porzioni di dati che il server web fornisce al dispositivo di navigazione dell'utente (computer, tablet, smartphone, ecc.) per consentire l'utilizzo dei nostri servizi. Inoltre le informazioni generate dall'uso dei cookie ci permettono di ottimizzare le pagine e le nostre infrastrutture tecniche, migliorando così la navigazione del sito. </p>
                            <p>Il nostro sito può contenere link, servizi, immagini o componenti multimediali di terze parti che possono generare a loro volta cookie sui quali non possiamo avere il controllo diretto.</p>
                        </p>
                        <br></br>

                        <p>
                            <h2>Cosa sono i cookie</h2>
                            <p>I cookie sono piccoli file di testo che vengono salvati nel computer dell'Utente quando questi visita un sito web. I cookie sono largamente utilizzati da moltissimi siti web per ottimizzare l'efficienza del sito, per migliorare la navigazione e per ottenere informazioni sul numero e sulla tipologia di visitatori.</p>
                            <p>I cookie inseriti dal proprietario del sito web vengono definiti "cookie di prime parti". I cookie inseriti da persone diverse dal proprietario del sito web vengono definiti "cookie di terze parti". I cookie di terze parti servono a rendere disponibili, nel sito web, determinate funzionalità. Chi imposta i cookie di terze parti sarà in grado di riconoscere il computer dell'utente quando accede al sito web e anche quando visita determinati altri siti.</p>
                        </p>
                        <br></br>

                        <p>
                            <h2>Perché utilizziamo i cookie</h2>
                            <p>I cookie servono a raccogliere informazioni sulle visite del sito web. Tali dati possono includere dettagli sugli indirizzi IP, sul tipo di browser e sulla risoluzione dello schermo, nonché una serie di informazioni sulle abitudini e le preferenze dell'utente, come ad esempio la lingua utilizzata e le pagine visitate con maggiore frequenza. </p>
                            <p>Tali informazioni permettono di comprendere gli interessi dei visitatori e di migliorare costantemente il servizio offerto. In ogni caso, le informazioni raccolte non consentono di determinare l'effettiva identità dei visitatori, a meno che essi non decidano volontariamente di fornire i propri dati personali tramite uno dei form di contatto presenti nel Sito.</p>

                            <br></br>
                            <p>
                                <h3>Cookie del gestore del Sito</h3>
                                <br></br>
                                <p>
                                    <li>cookies di sessione e tecnici (che non vengono memorizzati in modo persistente sul computer dell'utente e svaniscono con la chiusura del browser) è strettamente limitato alla trasmissione di identificativi di sessione (costituiti da numeri casuali generati dal server) necessari per consentire l'esplorazione sicura ed efficiente del sito. I c.d. cookies di sessione utilizzati in questo sito evitano il ricorso ad altre tecniche informatiche potenzialmente pregiudizievoli per la riservatezza della navigazione degli utenti e non consentono l'acquisizione di dati personali identificativi dell'utente.</li>
                                    Nell’elenco seguente sono riportati i cookie utilizzati nel Sito e la loro funzione:
                                    <table>
                                    <tr>
                                        <th>
                                            _utma<br></br>
                                            _utmb<br></br>
                                            _utmc<br></br>
                                            _utmz
                                        </th>
                                        <th>
                                        Cookie tecnici
                                        </th>
                                    </tr>
                                    </table>
                                </p>
                            </p>
                        </p>
                        <br></br>

                        <p>
                            <h2>Cookie di profilazione e/o analitici</h2>
                            Non sono presenti cookie di profilazione o analitici utilizzati dal gestore del Sito.
                        </p>
                        <br></br>

                        <p>
                            <h2>Cookie di parti terze</h2>
                                <li>
                                    <ul>Cookie per integrare prodotti e funzioni di software di terze parti - Questa tipologia di cookie integra funzionalità sviluppate da terzi all’interno delle pagine del Sito come le icone e le preferenze espresse nei social network al fine di condivisione dei contenuti del sito o per l’uso di servizi software di terze parti (come i software per generare le mappe e ulteriori software che offrono servizi aggiuntivi). Questi cookie sono inviati da domini di terze parti e da siti partner che offrono le loro funzionalità tra le pagine del Sito. Tra questi rientrano i cookie relativi a:</ul>
                                    <li>
                                        <ul>Video di Youtube: Questo sito e i siti tematici eventualmente connessi incorporano o potrebbero incorporare video di Youtube, proprietà di Google, utilizzati allo scopo di mostrare agli utenti video di Youtube all'interno delle nostre pagine. Per consultare l'informativa privacy della società Google Inc., titolare autonomo del trattamento dei dati relativi al servizio YouTube, è possibile visitare https://www.youtube.com/static?template=privacy_guidelines&gl=IT</ul>
                                        <ul>Google maps: Questo sito e i siti tematici eventualmente connessi incorporano o potrebbero incorporare mappe di Google, utilizzate allo scopo di mostrare agli utenti mappe di Google all'interno delle nostre pagine. Per consultare l'informativa privacy della società Google Inc., titolare autonomo del trattamento dei dati relativi al servizio Google maps, è possibile visitare http://www.google.com/maps/about/behind-the-scenes/streetview/privacy/.</ul>
                                        <ul>
                                            Social network: Si tratta di strumenti di terze parti che espongono modalità di interazione con i social network. Ad esempio i sistemi di condivisione di un contenuto su Facebook e Twitter: i cookie in questo caso sono necessari per il funzionamento del meccanismo di condivisione.
                                            Di seguito si elencano i collegamenti alle cookie policy dei Social Network che potrebbero essere integrati su questo sito:
                                            <ul>
                                                <li>Facebook</li>
                                                <li>Twitter</li>
                                            </ul>
                                        </ul>
                                    </li>
                                </li>
                            </p>
                        <br></br>

                        <p>
                            <h2>Modalità del trattamento</h2>
                            Il trattamento viene effettuato con strumenti automatizzati dal Titolare. Non viene effettuata alcuna diffusione o comunicazione.
                        </p>
                        <br></br>

                        <p>
                            <h2>Conferimento dei dati</h2>
                            Fatta eccezione per i cookie tecnici strettamente necessari alla normale navigazione, il conferimento dei dati è rimesso alla volontà dell'interessato che decida di navigare sul sito dopo aver preso visione dell'informativa breve contenuta nell'apposito banner e di usufruire dei servizi che comportano l'installazione di cookie (così per la condivisione dei contenuti sui Social Network). L'interessato può quindi evitare l'installazione dei cookie attraverso le apposite funzioni disponibili sul proprio browser.
                        </p>
                        <br></br>

                        <p>
                            <h2>Disabilitazione dei cookie</h2>
                            Fermo restando quanto sopra indicato in ordine ai cookie strettamente necessari alla navigazione, l'utente può eliminare gli altri cookie attraverso la funzionalità a tal fine messa a disposizione dal Titolare tramite la presente informativa oppure direttamente tramite il proprio browser.
                            I cookie possono essere disabilitati andando a modificare direttamente le impostazioni del proprio browser. Questa operazione può risultare diversa a seconda del browser che si sta utilizzando.
                            Questi sono alcuni link alle istruzioni specifiche per i browser più comuni:

                            <ul>
                                <li>Google Chrome</li>
                                <li>Mozilla Firefox</li>
                                <li>Microsoft Internet Explorer</li>
                                <li>Apple Safari</li>
                            </ul>

                            La disattivazione dei cookie di terze parti è inoltre possibile attraverso le modalità rese disponibili direttamente dalla società terza, come indicato ai link riportati nel paragrafo "Cosa sono i cookie - cookie di terze parti".

                            <p><b>Attenzione!</b> Disabilitare la memorizzazione di tutti i cookie (compresi quelli tecnici), potrebbe compromettere o limitare la navigabilità del sito.</p>
                        </p>
                        <br></br>

                        <br></br>
                        </div>
                    </div>
                </PageSection>
            </div>
        );
    }
};

export default Cookie;
