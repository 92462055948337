/* eslint-disable no-cond-assign */
/* eslint-disable react/no-direct-mutation-state */
import {FormGroup, Nav, NavItem, NavLink} from 'design-react-kit'
import * as React from 'react'
import GenericAccordionCard from '../../components/GenericAccordionCard'
import GenericBreadCrumb from 'src/frontend/app/components/GenericBreadCrumb'
import GenericChips from '../../components/GenericChips'
import GenericInput from '../../components/GenericInput'
import GenericTab from '../../components/GenericTab'
import GenericTitle from '../../components/GenericTitle'
import DatiInformativi from './form/DatiInformativi'
import Riepilogo from './form/Riepilogo'
import './style.scss'
import TabellaUno from './form/TabellaUno'
import GenericAlert from '../../components/GenericAlert'
import GenericSpinner from 'src/backoffice/app/components/GenericSpinner'
import PagamentoPolizza from './form/PagamentoPolizza'
import {getPolizzaById, getPolizzaStoricaById, getStorico} from 'src/processes/assicurazioni/Assicurazione'
import {
    recuperoTabellaUnoByPolizza,
    recuperoTabellaUnoDefinitivaAssociataAllaPolizza
} from 'src/processes/assicurazioni/TabellaUno'
import InserimentoDatiPolizza from './form/InserimentoDatiPolizza'
import {TokenStore} from 'src/keycloak/jwt/TokenStore'
import {getHome, isEmpty} from 'src/utilities/utility'
import {getSoggettoByIdIscrizioneFO} from 'src/processes/Soggetto'
import GenericModal from 'src/backoffice/app/components/GenericModal'
import GenericDebug from 'src/backoffice/app/components/GenericDebug'
import PolizzeData from 'src/backoffice/app/components/Assicurazioni/PolizzeData'
import {PATH_TO_FO_ASSICURAZIONI} from 'src/app/utils/RoutesConstants'
import {AppCostants} from 'src/app/utils/AppConstant'
import {
    linkTabCollettivaVisualizzazione,
    linkTabIndividualeVisualizzazione
} from 'src/processes/assicurazioni/CostantiPolizze'
import {AppUtils as U} from 'src/app/utils/FormUtils'
import {AssicurazioneUtils} from 'src/processes/assicurazioni/AssicurazioneUtils'
import {isCurrentMobileDevice} from 'src/utilities/deviceUtility'

const breadcrumbOptions = [
  { label: "Assicurazioni / Lista richieste", link: PATH_TO_FO_ASSICURAZIONI },
]

let listaStorico: any = [];

class VisualizzaPolizza extends React.Component<any, any>{
  constructor(props){
    super(props);
    this.state = {
      message: "",
      typeMessage: "default",
      idPolizza: "",
      codiceStudioAssociato: "",
      polizza: {},
      data: {},
      mySelection: [],
      tabellaUno: {},
      activeTab: 1,
      isLoaded: false,
      selectedPolizza: "",
      originalPolizza: "",
      storicoFlag: false,
      utenteSTP: false,
      gratuita: false

    }
  }

  async componentDidMount(): Promise<void> {
    let id;
    if(this.props.location.state?.polizza.id) id = this.props.location.state.polizza.id;
    if(this.state?.polizza.id) id = this.state.polizza.id;
    listaStorico = []
    if(id !== undefined) {
      await getPolizzaById(id).then(async (response: any)=> {

        let dataCreazioneRichiesta =  response.dataCreazioneRichiesta;
        if(dataCreazioneRichiesta === null || dataCreazioneRichiesta === undefined){
           dataCreazioneRichiesta = "0000-00-00T00:00:00";
        }

        let obj: any = {
          id: response.id,
          dataCreazioneRichiesta: dataCreazioneRichiesta,
          stato: response.stato
        }

        //casting da numerale a dropdown
        response.coperturaPostuma = U.dn(response.coperturaPostuma);
        response.danniPatrimoniali = U.dn(response.danniPatrimoniali);
        response.retroattivitaIllimitata  = U.dn(response.retroattivitaIllimitata);
        response.operaStudioAssociato  = U.dn(response.operaStudioAssociato);
        response.altrePolizze  = U.dn(response.altrePolizze);
        response.maternita  = U.dn(response.maternita);
        response.paternita = U.dn(response.paternita);

        response.idCategoriaDue = {label: (response.categoria === 2) ? 'Si' : 'No', value: (response.categoria === 2) ? 'Si' : 'No'};
        response.privacy = (response.privacy === 1) ? true : false;
        response.haLettoCircostanza = (response.haLettoCircostanza === 1) ? true : false;
        response.haLettoDichiarazioni = (response.haLettoDichiarazioni === 1) ? true : false;

        response.periodoValDaConaf = U.data_normalize(response.periodoValDaConaf);
        response.periodoValAConaf  = U.data_normalize(response.periodoValAConaf);
        response.periodoValDaAltri = U.data_normalize(response.periodoValDaAltri);
        response.periodoValAAltri = U.data_normalize(response.periodoValAAltri);

        if(response.associatoConConaf === 1) {
          if(response.premioConaf > 0){
              this.setState({gratuita : false})
          }
          else this.setState({gratuita : true})
          this.setState({tipologiaPolizza: "Collettiva"})
        }
        else this.setState({tipologiaPolizza: "Individuale"})
        this.setState({polizza: response});

        listaStorico.push(obj);
        this.setState({originalPolizza: response.id, selectedPolizza: {id: response.id, dataCreazioneRichiesta: response.dataCreazioneRichiesta}});
        if(TokenStore.getInstance().isCurrentAccountSTP() === true) {

          //chiamata al servizio 'getSoggettoByIdIscrizione' che restituisce i dati del soggetto a partire dall'idIscrizione
          await getSoggettoByIdIscrizioneFO(response.idIscrizione).then((response: any) => {
            const keyData = TokenStore.getInstance().getDecodedTokenJWT();

            this.setState({
              user: {
                ragioneSociale: response.data.ragioneSociale,
                partitaIva: response.data.partitaIva,
                nome: "",
                cognome: "",
                cf: keyData.fiscalNumber,
              },
              utenteSTP: true} )
          })
          this.setState({utenteSTP: true})

        }
        else {
          const keyData = TokenStore.getInstance().getDecodedTokenJWT();
          this.setState({
            user: {
              nome: keyData.given_name,
              cognome: keyData.family_name,
              ragioneSociale: "",
              partitaIva: "",
              cf: keyData.fiscalNumber,
            },
            utenteSTP: false
          })
        }


        await recuperoTabellaUnoDefinitivaAssociataAllaPolizza(id).then(async responseTabella => {

              this.setState({tabellaUno: responseTabella})
              if(response.associatoConConaf === 1) this.setState({polizzaIndividuale: false, polizzaCollettiva: true})
              if(response.associatoConAltri === 1) this.setState({polizzaIndividuale: true, polizzaCollettiva: false})
              await this.setState({polizza: response, isLoaded: true});

              await getStorico(this.props.location.state.polizza.id).then(async responseStorico=>{
                if(responseStorico.length > 0) {
                  await this.setState({isLoaded: false});
                  responseStorico.map((item) => {
                    let obj: any = {
                      id: item.id,
                      dataCreazioneRichiesta: item.dataCreazioneRichiesta,
                      stato: item.stato
                    };
                    listaStorico.push(obj);
                  })
                  await this.setState({polizza: response, isLoaded: true});
                }
              })
              .catch((err) => {
                this.showModal(true, "Errore.",err);
              })

        }).catch((err) => {
            this.showModal(true, "Errore.",err);
        })

      })
    } else {
      window.location.href = PATH_TO_FO_ASSICURAZIONI
    }
  }

  //Procedi allo step precedente
  previousStep = async () => {
    if(this.state.activeTab === 2){
      if(this.state.polizza.fileMaternitaPaternita !== undefined){
        this.setState({ activeTab: this.state.activeTab - 1})
        this.setState({prevFileMPUploaded : true})
      }
    }
      if(this.state.activeTab === 5){
      if(this.state.polizza.copiaPagamentoPolizza !== undefined){
        this.setState({ activeTab: this.state.activeTab - 1})
        this.setState({prevFileUploaded : true})
      }
    }


    if(this.state.tabellaUno && this.state.tabellaUno.idTabellaUno) this.state.tabellaUno.id = this.state.tabellaUno.idTabellaUno
    //breadcrumbOptions.pop()
    await this.setState({ activeTab: this.state.activeTab - 1, polizza:this.state.polizza})
  }

  //Procedi allo step successivo
  nextStep = async () => {
    if(this.state.activeTab === 1 ) this.setState({polizza: this.state.polizza})
    this.state.polizza.codiceFiscale = TokenStore.getInstance().getDecodedTokenJWT().fiscalNumber;

    if(this.state.activeTab+1<= linkTabCollettivaVisualizzazione.length) {

        this.setState({ activeTab: this.state.activeTab + 1, polizza: this.state.polizza})

    }
  }

  //Sincronizzazione di tutti i valori
  getSelectValues = async (fields: any, type: any) => {

      if(type === 'mySelection') {
        if(fields.length > 0)
        this.setState({checkEmpty : true})
        else this.setState({checkEmpty : false})

      }
      else if(type === 'tabellaUno') {
        this.setState({tabellaUno: fields})
      }
      else if(type === 'polizza') {
        this.setState({polizza: fields})
      }
      else {
        await this.setState({fields: fields, data: fields})
      }
      this.setState({isLoaded: true})
    }


  onSubmit = async (event) => {
    event.preventDefault();
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    this.nextStep()
    this.setState({ message: "" })
  }

  setSelectedPolizza = async (polizzaId) => {
    let polizza = this.state.polizza;

    await getPolizzaStoricaById(polizzaId).then(async (response: any)=> {
      polizza = response;

      if(response === "" || response === null) {
          await getPolizzaById(polizzaId).then(async (responsePolizza: any)=> {

              await this.setState({originalPolizza: responsePolizza.id, selectedPolizza: {id: responsePolizza.id, dataCreazioneRichiesta: responsePolizza.dataCreazioneRichiesta}, isLoaded: false, storicoFlag: false});
                      polizza = responsePolizza;
                      //casting da numerale a dropdown
                      polizza.coperturaPostuma = U.dn(polizza.coperturaPostuma);
                      polizza.danniPatrimoniali = U.dn(polizza.danniPatrimoniali);
                      polizza.retroattivitaIllimitata  = U.dn(polizza.retroattivitaIllimitata);
                      polizza.operaStudioAssociato  = U.dn(polizza.operaStudioAssociato);
                      polizza.altrePolizze  = U.dn(polizza.altrePolizze);
                      polizza.maternita  = U.dn(polizza.maternita);
                      polizza.paternita = U.dn(polizza.paternita);
                      polizza.sinistri = U.dn(polizza.sinistri);
                      polizza.sinistriRicevutoLiberatoriaCompagnia = U.dn(polizza.sinistriRicevutoLiberatoriaCompagnia);
                      polizza.idCategoriaDue = {label: (polizza.categoria === 2) ? 'Si' : 'No', value: (polizza.categoria === 2) ? 'Si' : 'No'};
                      polizza.privacy = (polizza.privacy === 1) ? true : false;
                      polizza.haLettoCircostanza = (polizza.haLettoCircostanza === 1) ? true : false;
                      polizza.haLettoDichiarazioni = (polizza.haLettoDichiarazioni === 1) ? true : false;

                      polizza.periodoValDaConaf = U.data_normalize(polizza.periodoValDaConaf);
                      polizza.periodoValAConaf  = U.data_normalize(polizza.periodoValAConaf);
                      polizza.periodoValDaAltri = U.data_normalize(polizza.periodoValDaAltri);
                      polizza.periodoValAAltri = U.data_normalize(polizza.periodoValAAltri);

                      await recuperoTabellaUnoDefinitivaAssociataAllaPolizza(polizzaId).then(async responseTabella=>{

                        this.setState({tabellaUno: responseTabella})
                          if(polizza.associatoConConaf === 1) this.setState({polizzaIndividuale: false, polizzaCollettiva: true})
                          if(polizza.associatoConAltri === 1) this.setState({polizzaIndividuale: true, polizzaCollettiva: false})
                          await this.setState({polizza: polizza, isLoaded: true});
                      }).catch((err) => {
                        this.showModal(true, "Errore durante il caricamento dei dati.",err);
                      })
          })

      }
      else {

          this.setState({
            selectedPolizza:
              {
                id: response.id,
                dataCreazioneRichiesta: response.dataCreazioneRichiesta
              },
              isLoaded: false,
              storicoFlag: true
            })
          await recuperoTabellaUnoByPolizza(polizzaId).then(async responseTabella=>{
              this.setState({tabellaUno: responseTabella})
              if(response.associatoConConaf === 1) this.setState({polizzaIndividuale: false, polizzaCollettiva: true})
              if(response.associatoConAltri === 1) this.setState({polizzaIndividuale: true, polizzaCollettiva: false})
              await this.setState({polizza: polizza, isLoaded: true});

          }).catch((err) => {
            this.showModal(true, "Errore durante il caricamento dei dati.",err);
          })
      }

  })
  }

  hasFormErrors = (alert) => {

  }
  closeModal = () => {
    this.setState({modalOpen:false});
  }

  showModal = async (booleanValue, title, text) => {
    await this.setState({
        modalOpen:booleanValue,
        modalTitle: title,
        modalText:text,
        isLoaded: true,
    });
  }
  render() {
    const {activeTab,isSubmitted, message, typeMessage} = this.state;
    var title = "Visualizza richiesta assicurativa"

    const listBodyTabColl =
    /** Lista del contenuto dei Tab per la compilazione della polizza */
    [
      {
        id:1,
        body: (this.state.activeTab === 1) ? <DatiInformativi message={this.hasFormErrors} key={'1'} modificaDatiFlag={false} visualizzaFlag={true} storicoFlag={this.state.storicoFlag} data={this.state.polizza}  polizza={this.state.polizza} getSelectedValue={this.getSelectValues} tabNumber={1} /> : null
      },
      {
        id:2,
        body: (this.state.activeTab === 2) ? <TabellaUno message={this.hasFormErrors} key={'2'} modificaDatiFlag={false} visualizzaFlag={true} storicoFlag={this.state.storicoFlag} data={this.state.polizza} polizza={this.state.polizza} tabellaUno={this.state.tabellaUno} getSelectedValue={this.getSelectValues} tabNumber={2} /> : null
      },
      {
        id:3,
        body: (this.state.activeTab === 3) ? <Riepilogo message={this.hasFormErrors} key={'3'} modificaDatiFlag={false} visualizzaFlag={true} storicoFlag={this.state.storicoFlag} data={this.state.polizza} polizza={this.state.polizza} tabellaUno={this.state.tabellaUno} getSelectedValue={this.getSelectValues} activeTab={activeTab} tabNumber={3} /> : null
      },
      {
        id:4,
        body: (this.state.activeTab === 4 ) ? <PagamentoPolizza message={this.hasFormErrors} key={'4'} prevData={this.state.polizza} polizza={this.state.polizza} prevFileUploaded={this.state.prevFileUploaded} idAllegato={this.state.idAllegato} tabellaUno={this.state.tabellaUno} modificaDatiFlag={false} visualizzaFlag={true} storicoFlag={this.state.storicoFlag} data={this.state.polizza} getSelectedValue={this.getSelectValues} activeTab={activeTab} tabNumber={4}  /> : null
      }

    ];

    const listBodyTabInd =
    /** Lista del contenuto dei Tab per la compilazione della polizza */
    [
      {
        id:1,
        body: (this.state.activeTab === 1) ? <DatiInformativi message={this.hasFormErrors} key={'1'} modificaDatiFlag={false} visualizzaFlag={true} storicoFlag={this.state.storicoFlag} data={this.state.polizza} polizza={this.state.polizza} getSelectedValue={this.getSelectValues} tabNumber={1} /> : null
      },
      {
        id:2,
        body: (this.state.activeTab === 2) ? <TabellaUno message={this.hasFormErrors} key={'2'} modificaDatiFlag={false} visualizzaFlag={true} storicoFlag={this.state.storicoFlag} polizza={this.state.polizza} data={this.state.polizza} tabellaUno={this.state.tabellaUno} getSelectedValue={this.getSelectValues} tabNumber={2} /> : null
      },
      {
        id:3,
        body: (this.state.activeTab === 3) ? <Riepilogo message={this.hasFormErrors} key={'3'} modificaDatiFlag={false} visualizzaFlag={true} storicoFlag={this.state.storicoFlag} data={this.state.polizza} polizza={this.state.polizza} tabellaUno={this.state.tabellaUno} getSelectedValue={this.getSelectValues} activeTab={activeTab} tabNumber={3}  /> : null
      },
      {
        id:4,
        body: (this.state.activeTab === 4) ? <InserimentoDatiPolizza message={this.hasFormErrors} key={'4'} modificaDatiFlag={false} visualizzaFlag={true} storicoFlag={this.state.storicoFlag} data={this.state.polizza} tabellaUno={this.state.tabellaUno} getSelectedValue={this.getSelectValues} activeTab={activeTab} tabNumber={4} /> : null
      }

    ];

    /************************ Dati Anagrafici non modificabili **************************************/
    const SpidData = (props) => {
      return (
        <>
            <div className="row" >
                <div className={isCurrentMobileDevice() ? null : "col-4"}>
                  <FormGroup label={"nome"} >
                    <GenericInput placeholder="nome" key="nome" id="nome" name="nome" label='Nome' isDisabled defaultValue={this.state.user.nome} />
                  </FormGroup>
                </div>
                <div className={isCurrentMobileDevice() ? null : "col-4"}>
                  <FormGroup label={"cognome"}>
                    <GenericInput placeholder="cognome" key="cognome"  id="cognome" name="cognome"  label='Cognome' isDisabled defaultValue={this.state.user.cognome} />
                  </FormGroup>
                </div>
                <div className={isCurrentMobileDevice() ? null : "col-4"}>
                  <FormGroup label={"cf"}>
                    <GenericInput placeholder="cf" key="cf" id="cf" name="cf" label="Codice fiscale" isDisabled defaultValue={this.state.user.cf} />
                  </FormGroup>
                </div>
          </div>
        </>
      )
    }

    /************************ Dati Societa STP non modificabili **************************************/
    const SocietaSTPData = () => {
          return (
            <>
                <div className="row" >
                    <div className={isCurrentMobileDevice() ? null : "col-4"}>
                      <FormGroup label={"nome"} >
                        <GenericInput placeholder="nome" key="nome" id="nome" name="nome" label='Ragione Sociale' isDisabled defaultValue={this.state.user.ragioneSociale} />
                      </FormGroup>
                    </div>

                    <div className={isCurrentMobileDevice() ? null : "col-4"}>
                      <FormGroup label={"nome"} >
                        <GenericInput placeholder="nome" key="nome" id="nome" name="nome" label='Partita IVA' isDisabled defaultValue={this.state.user.partitaIva} />
                      </FormGroup>
                    </div>
              </div>
            </>
          )
    }

    /************************ Avvisi provenienti da backoffice **************************************/
    const AvvisiBO = () => {
      return (
        <>
          <div className="row" >
                  <div className={isCurrentMobileDevice() ? null : "col-4"}>
                    <FormGroup label={"stato"} >
                      <GenericInput placeholder="stato" key="nome" id="stato" name="stato" label='Stato della polizza' isDisabled defaultValue={AssicurazioneUtils.decodificaStatiPolizza(this.state.polizza.stato)} />
                    </FormGroup>
                  </div>
                  <div className={isCurrentMobileDevice() ? null : "col-4"}>
                    <FormGroup label={"tipologia"} >
                      <GenericInput placeholder="tipologia" key="tipologia" id="tipologia" name="tipologia" label='Tipologia' isDisabled defaultValue={this.state.polizza.associatoConConaf === 1 ? "COLLETTIVA" : this.state.polizza.associatoConAltri === 1 ? "INDIVIDUALE" : "INVALIDA"} />
                    </FormGroup>
                  </div>
                  <div className={isCurrentMobileDevice() ? null : "col-4"}>
                    <FormGroup label={"tipoRichiesta"} >
                      <GenericInput placeholder="tipoRichiesta" key="tipoRichiesta" id="tipoRichiesta" name="tipoRichiesta" label='Tipo richiesta' isDisabled defaultValue={!isEmpty(this.state.polizza.tipoRichiesta) ? AssicurazioneUtils.decodificaTipoRichiesta(this.state.polizza.tipoRichiesta) : "-"} />
                    </FormGroup>
                  </div>

            </div>
            <br></br>
            <div className="row" hidden={!this.state.polizza.noteConferma || this.state.polizza.stato !== 2} >
                    Note conferma
                    <GenericAlert key="noteConferma" id="noteConferma" label={this.state.polizza.noteConferma} />
            </div>
            <div className="row" hidden={!this.state.polizza.noteRifiuto || (this.state.polizza.stato !== 3 && this.state.polizza.stato !==4 && this.state.polizza.stato !==1)} >
                    Note
                    <GenericAlert key="noteRifiuto" id="noteRifiuto" color={'danger'} label={this.state.polizza.noteRifiuto} />
            </div>
            <div className="row" hidden={!this.state.polizza.noteRichiedente} >
                    Note richiedente
                    <GenericAlert key="noteRichiedente" id="noteRichiedente" color={'secondary'} label={this.state.polizza.noteRichiedente} />
            </div>
        </>
      )
    }

    const tabClick = (tab) => {
      if(this.state.activeTab === 2){
        if(this.state.polizza.fileMaternitaPaternita !== undefined){
          this.setState({ activeTab: this.state.activeTab - 1})
          this.setState({prevFileMPUploaded : true})
        }
      }
        if(this.state.activeTab === 5){
        if(this.state.polizza.copiaPagamentoPolizza !== undefined){
          this.setState({ activeTab: this.state.activeTab - 1})
          this.setState({prevFileUploaded : true})
        }
      }

      if(this.state.tabellaUno && this.state.tabellaUno.idTabellaUno) this.state.tabellaUno.id = this.state.tabellaUno.idTabellaUno
      this.setState({activeTab: tab})
    }

   return(
     <div key={"compilaPolizza"} className={isCurrentMobileDevice() ? 'container register-container-zero-padding' : 'container-fluid register-container p-4'}>
        <div className={isCurrentMobileDevice() ? null : 'row'}>
            <div className='col-12'></div>
                {
                  (this.state.isLoaded) ?
                      <div className={isCurrentMobileDevice() ? 'container register-container-zero-padding' : 'container-fluid register-container p-4'}>
                          <GenericBreadCrumb key="genericTab" paths={breadcrumbOptions} />
                          <div className={isCurrentMobileDevice() ? 'container register-container-zero-padding' :'container-fluid register-container'}>

                                <Nav tabs className='mb-3'>
                                  {
                                      listaStorico?.sort( (p1, p2) => (p1.dataCreazioneRichiesta > p2.dataCreazioneRichiesta) ? -1 : (p1.dataCreazioneRichiesta < p2.dataCreazioneRichiesta ) ? 1 : 0)
                                      .map((item, index) => (
                                          <NavItem key={index}>
                                            <NavLink active={(this.state.selectedPolizza && this.state.selectedPolizza.id === item.id) ? true : false}
                                              onClick={()=>{this.setState({isLoaded:false});this.setSelectedPolizza(item.id)}}>
                                              {item.dataCreazioneRichiesta.split('T')[0] + " " + item.dataCreazioneRichiesta.split('.')[0].split('T')[1]}
                                            </NavLink>
                                          </NavItem>
                                      ))
                                  }
                                </Nav>


                          <div className={isCurrentMobileDevice() ? null : "d-flex justify-content-between ipi-title"} hidden={this.state.selectedPolizza === undefined}>
                              <GenericTitle Size="h1" title={title}></GenericTitle>
                              <GenericChips label={"Polizza "+ (this.state.polizza.associatoConConaf ? "Collettiva" : "Individuale" )+ " " + this.state.originalPolizza}></GenericChips>
                          </div>

                          { AppCostants.isDebug() === true &&
                            <>

                              {!this.state.utenteSTP &&
                                  <GenericDebug cardHeader={<h4>DEBUG</h4>} cardBody={
                                    <PolizzeData
                                        fields={this.state.fields}
                                        polizza={this.state.selectedPolizza}
                                        polizzaInCompilazione={undefined}
                                        intestatarioPolizza={this.state.utente}
                                        comunicantePolizza={undefined}
                                        decodeToken={false}
                                    />
                                  } />
                              }



                              {this.state.utenteSTP &&
                                  <GenericDebug cardHeader={<h4>DEBUG</h4>} cardBody={
                                    <PolizzeData
                                        fields={this.state.fields}
                                        polizza={this.state.selectedPolizza}
                                        polizzaInCompilazione={undefined}
                                        intestatarioPolizza={this.state.utente}
                                        comunicantePolizza={this.state.utente}
                                        decodeToken={false}
                                    />
                                  } />
                              }
                            </>
                          }

                          {!this.state.utenteSTP && <GenericAccordionCard cardHeader={<h4>Dati anagrafici</h4>} cardBody={<SpidData></SpidData>} />}
                          {this.state.utenteSTP && <GenericAccordionCard cardHeader={<h4>Dati società</h4>} cardBody={<SocietaSTPData></SocietaSTPData>} />}
                          <GenericAccordionCard cardHeader={<h4>Messaggi e Avvisi</h4>} cardBody={<AvvisiBO></AvvisiBO>} />
                          <div className={isCurrentMobileDevice() ? "justify-content-center" : "justify-content-center p-4"}>
                                {this.state.polizza.associatoConAltri === 1 ? <GenericTab
                                          isClickable={true}
                                          tabClick={tabClick}
                                          key={'genericTabIscrizione'}
                                          hiddenAlert={message === ""}
                                          alertTypeMessage={typeMessage}
                                          labelAlert={message}
                                          isSubmitted={isSubmitted}
                                          onSubmit={this.onSubmit}
                                          listBodyTab={listBodyTabInd}
                                          listNavLink={linkTabIndividualeVisualizzazione}
                                          activeTab={activeTab}
                                          previousStep={this.previousStep}
                                          homeLink={PATH_TO_FO_ASSICURAZIONI}
                                        ></GenericTab> :
                                        <GenericTab
                                          isClickable={true}
                                          tabClick={tabClick}
                                          key={'genericTabIscrizione'}
                                          hiddenAlert={message === ""}
                                          alertTypeMessage={typeMessage}
                                          labelAlert={message}
                                          isSubmitted={isSubmitted}
                                          onSubmit={this.onSubmit}
                                          listBodyTab={listBodyTabColl}
                                          listNavLink={linkTabCollettivaVisualizzazione}
                                          activeTab={activeTab}
                                          previousStep={this.previousStep}
                                          homeLink={PATH_TO_FO_ASSICURAZIONI}
                                        ></GenericTab>}

                                  <GenericModal
                                      title={this.state.modalTitle}
                                      text={this.state.modalText}
                                      modalOpen={this.state.modalOpen}
                                      closeButton={this.closeModal}
                                  />

                              </div>
                          </div>
                      </div>
                  : (this.state.message === "") ? <div className="container register-container"><GenericSpinner></GenericSpinner></div>
                    : <div className='container register-container p-5'>
                        <GenericAlert key="alertIscrizione" label={<>
                        {this.state.message+'. Torna alla '}<a href={getHome()} className='alert-link'>home page</a>
                        </>
                        } color={'danger'}></GenericAlert>
                    </div>
                }
        </div>
    </div>
   )
  }
};

export declare interface InserisciProps {
  activeTab?: any;
  visualizzaFlag?:any;
  getSelectedValue?:any;

}


export default VisualizzaPolizza;
