import moment from 'moment'
import {ALLEGATI_AGENZIA_FILTERED, isInvalidAllegati, TIPOLOGIE_ACCREDITAMENTO_VALUES} from 'src/formazione'

const rules = {
  regex: [
    {
      regex: /datiAnagrafici.*(?<!fax)$/,
      rules: {
        isRequired: true,
      },
    },
    {
      regex: /datiSedeLegale.*/,
      rules: {
        isRequired: true,
      },
    },
    {
      regex: /datiRappresentanteLegale.*/,
      rules: {
        isRequired: true,
      },
    },
    {
      regex: /datiResponsabileAF.*/,
      rules: {
        isRequired: true,
      },
    },
  ],
  'check-formatori': {
    _custom: {
      check: (code, rule, data) => {
        let check = true
        if(data?.formatori?.length) {
          data.formatori.forEach(formatore => {
            if(formatore.hasRespFormConflitti !== true) {
              check = false
            }
          });
        } else {
          check = false
        }
        return check
      }
    }
  },
  'datiAnagrafici.codiceFiscale': {
    isFiscalCode: true,
  },
  'datiAnagrafici.piva': {
    isPartitaIva: true,
  },
  'datiAnagrafici.email': {
    isEmail: true,
  },
  'datiAnagrafici.pec': {
    isEmail: true,
  },
  'datiAnagrafici.telefono': {
    isTelefono: true,
  },
  'datiAnagrafici.fax': {
    isFax: true,
  },
  'datiSedeLegale.cap': {
    isCap: true,
  },
  'datiRappresentanteLegale.cap': {
    isCap: true,
  },
  'datiResponsabileAF.codiceFiscale': {
    isFiscalCodePhysicalPerson: true,
  },
  'datiResponsabileAF.cap': {
    isCap: true,
  },
  'datiResponsabileAF.email': {
    isEmail: true,
  },
  'datiAccreditamento.tipoAccreditamento': {
    isRequired: true,
  },
  'datiAccreditamento.settoriDisciplinari': {
    inclusion: {
      field: 'datiAccreditamento.tipoAccreditamento',
      values: [TIPOLOGIE_ACCREDITAMENTO_VALUES.COMPLESSO, TIPOLOGIE_ACCREDITAMENTO_VALUES.SEMPLICE],
    },
  },
  'datiAccreditamento.areePrestazioni': {
    inclusion: {
      field: 'datiAccreditamento.tipoAccreditamento',
      values: [TIPOLOGIE_ACCREDITAMENTO_VALUES.COMPLESSO, TIPOLOGIE_ACCREDITAMENTO_VALUES.SEMPLICE],
    },
  },
  'datiAccreditamento.tipoAttivita': {
    inclusion: {
      field: 'datiAccreditamento.tipoAccreditamento',
      values: [TIPOLOGIE_ACCREDITAMENTO_VALUES.COMPLESSO, TIPOLOGIE_ACCREDITAMENTO_VALUES.SEMPLICE],
    },
  },
  'datiAccreditamento.modalita': {
    inclusion: {
      field: 'datiAccreditamento.tipoAccreditamento',
      values: [TIPOLOGIE_ACCREDITAMENTO_VALUES.COMPLESSO, TIPOLOGIE_ACCREDITAMENTO_VALUES.SEMPLICE],
    },
  },
  'datiAccreditamento.ambitoTerritoriale': {
    inclusion: {
      field: 'datiAccreditamento.tipoAccreditamento',
      values: [TIPOLOGIE_ACCREDITAMENTO_VALUES.COMPLESSO, TIPOLOGIE_ACCREDITAMENTO_VALUES.SEMPLICE],
    },
  },
  'datiDichiarazioni.assenzaSentenze': {
    isRequired: true,
  },
  'datiDichiarazioni.assenzaFallimento': {
    isRequired: true,
  },
  'datiDichiarazioni.rispettoNormative': {
    isRequired: true,
  },
  'datiDichiarazioni.formatore': {},
  'datiDichiarazioni.isRespFormFormatore': {
    isRequired: true,
  },
  'datiDichiarazioni.hasRespFormConflitti': {
    isRequired: true,
  },
  'datiDichiarazioni.massimalePolizza': {
    isRequired: true,
  },
  'datiDichiarazioni.tipoPolizza': {
    isRequired: true,
  },
  'datiDichiarazioni.polizzaValidaDa': {
    _custom: {
      check: (code, rule, data) => {
        return true
      }
    },
    isRequired: true
  },
  'datiDichiarazioni.polizzaValidaA': {
    _custom: {
      check: (code, rule, data) => {
        if(data.datiDichiarazioni.polizzaValidaDa) {
          return moment(data.datiDichiarazioni.polizzaValidaDa).diff(data.datiDichiarazioni.polizzaValidaA) <= 0
        } else {
          return moment().diff(data.datiDichiarazioni.polizzaValidaA) <= 0
        }
      }
    },
    isRequired: true
  },
  'datiDichiarazioni.nuovaCostituzione': {},
  'datiDichiarazioni.flagImpegnoProdDoc': {
    _custom: {
      check: (code, rule, data) => {
        return !data?.datiDichiarazioni?.nuovaCostituzione
      },
    },
  },
  'datiDichiarazioni.fatturatoFormazione': {
    _custom: {
      check: (code, rule, data) => {
        return !!data?.datiDichiarazioni?.nuovaCostituzione || !!data?.datiDichiarazioni?.fatturatoFormazione
      },
    },
  },
  check_uploads: {
    _custom: {
      check: (code, rule, data) => {
        const allegati = ALLEGATI_AGENZIA_FILTERED(data, 'allegati')
        return !isInvalidAllegati(allegati, data)
      },
    },
  },
  check_formatori: {
    _custom: {
      check: (code, rule, data) => {
        if(data.tipo_ag_form !== 1 || !data.formatori) {
          return true
        } else {
          for(const formatore of data.formatori) {
            if(!formatore.hasRespFormConflitti) {
              return false
            }
          }
        }
        return true
      },
    },
  },
}

export default rules
