import * as React from 'react'
import {Form, Tooltip} from '@patternfly/react-core'
import GenericTextInput from '../../../components/GenericTextInput'
import {checkAnno, checkValuta, inputRequiredValidation} from 'src/utilities/utility'
import InfoCircleIcon from '@patternfly/react-icons/dist/esm/icons/info-circle-icon'
import {TokenStore} from 'src/keycloak/jwt/TokenStore'
import {comunicaQuotaIscrizione} from 'src/processes/QuoteIscrizione'
import {isCurrentMobileDevice} from 'src/utilities/deviceUtility'

const isMobileDivice = isCurrentMobileDevice();

export class FormComunicazioneQuoteIscrizione extends React.Component<GeneralFormComunicazioneQuoteIscrizioneProps, any> {

    handleSubmit = async event => {
        /*--------------------------------------------- Validazione campi obbligatori ------------------------------------------------*/
        var requiredFields = [
            checkAnno(event.target.idAnnoRiferimento.value) ? { value: event.target.idAnnoRiferimento.value, label: 'Anno di riferimento' } : { value: "", label: 'Anno di riferimento' },
            { value: event.target.idCategoria.value, label: 'Categoria iscritto' },
            checkValuta(event.target.idQuotaAssociativa.value) ? { value: event.target.idQuotaAssociativa.value, label: 'Quota associativa' } : { value: "", label: 'Quota associativa' },
        ]

        var errorRequired: any = inputRequiredValidation(requiredFields)
        if (errorRequired !== undefined && errorRequired !== "") {
            event.preventDefault()
            return this.props.messaggio({ messaggio: errorRequired.messaggio, type: errorRequired.type })
        }
        /*------------------------------------------------------------------------------------------------------------------------------*/
        else {
            var codiceGruppo = Object.keys(TokenStore?.getInstance()?.getCurrentAccount()?.accounts)[0]
            var quoteOrdiniDTO = {
                anno: event.target.idAnnoRiferimento.value ? event.target.idAnnoRiferimento.value : "",
                categoria: event.target.idCategoria.value ? event.target.idCategoria.value : "",
                quotaAssociativa: event.target.idQuotaAssociativa.value ? event.target.idQuotaAssociativa.value : "",
                codiceGruppo: codiceGruppo ? codiceGruppo : ""
            }
            try {
                event.preventDefault()
                //chiamata al servizio comunicaQuotaIscrizione
                await comunicaQuotaIscrizione(quoteOrdiniDTO)
                return this.props.messaggio({ messaggio: "Dati salvati con successo", type: "success" })
            }
            catch (err) {
                return this.props.messaggio({ messaggio: "C'è stato un problema nell'invio dei dati", type: "danger" })
            }
        }
    }

    render() {
        return (
            <div>
                <Form id='idComunicazioneQuoteIscrizione' onSubmit={this.handleSubmit}>
                    <div className='row'>
                        <div className={!isMobileDivice? 'col-6' : 'col-12' }>
                            <GenericTextInput type={'anno'} className='w-50' id={'idAnnoRiferimento'} label={"Anno di riferimento"} placeholder={'Inserire anno'} isRequired />
                        </div>
                        <div className={!isMobileDivice? 'col-6' : 'col-12' }>
                            <GenericTextInput type={'text'} className='w-75' id={'idCategoria'} label={<>Categoria iscritto <Tooltip content={'Descrizione della categoria di iscritto al quale si riferisce la quota'}><InfoCircleIcon /></Tooltip></>} placeholder={'Inserire la categoria di iscritto'} isRequired />
                        </div>
                    </div>
                    <div className='row'>
                        <div className={!isMobileDivice? 'col-6' : 'col-12' }>
                            <GenericTextInput type={'valuta'} className='w-50' id={'idQuotaAssociativa'} label={<>Quota associativa <Tooltip content={'Importo della quota annuale per la categoria. Utilizzare " , " come separatore decimale'}><InfoCircleIcon /></Tooltip></>} placeholder={'Inserire importo'} isRequired icon={"€"} />
                        </div>
                    </div>
                </Form>
            </div>
        )
    }
}

export declare interface GeneralFormComunicazioneQuoteIscrizioneProps {
    messaggio?: any;
}
