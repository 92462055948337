import * as React from 'react'
import GenericInput from 'src/frontend/app/components/GenericInput'
import GenericSelect from 'src/frontend/app/components/GenericSelect'
import {Form, FormGroup} from '@patternfly/react-core'
import {getAllProvince} from 'src/processes/Province'
import {getComuniByProvinciaMsga} from 'src/processes/Comuni'
import GenericCard from 'src/frontend/app/components/GenericCard'
import GenericButton from 'src/frontend/app/components/GenericButton'
import {PATH_TO_FO_STUDI_ASSOCIATI_ELENCO} from 'src/app/utils/RoutesConstants'
import {getStudioAssociatoByPIva} from 'src/processes/StudioAssociato'
import {listLegendaCampiObbligatori} from 'src/processes/Costanti'
import {isCurrentMobileDevice} from 'src/utilities/deviceUtility'

export class FormStudiAssociati extends React.Component<GeneralFormStudiAssociatiProps, any> {
    constructor(props) {
        super(props);
        this.state = {
            province: [],
            comuni: [],
            isLoaded: false,
            provinciaSelected: false,
            fields: {},
            isDisabled: false,
        };
    }

    componentDidMount() {
        //chiamata al servizio che restituisce tutte le province, ordinate in ordine alfabetico
        this.setState({isLoaded: false})
        getAllProvince().then((response: any) => {
            var province = [{ label: '', value: '' }];

            // eslint-disable-next-line array-callback-return
            response.data.map((provincia, id) => {
              province[id] = {
                label: provincia['denominazioneUnitaTerritorialeSovracomunale'],
                value: provincia['siglaAutomobilistica']
              };
            })

            let sortedProvince = province.sort((p1, p2) => (p1.label > p2.label) ? 1 : (p1.label < p2.label) ? -1 : 0);

            this.setState({ province: sortedProvince })

        }).catch((err) => {
            setTimeout(() => {
                return this.props.message({ messaggio: "C'è stato un problema nel recupero dei dati delle province" + err.message, type: "danger" })
        }, 3000);
    });
    this.setState({isLoaded: true})
    }

    onChangeOrdineTerritoriale = (event) => {
        this.setState({ ordineTerritoriale: event.value })
    }

    handleSubmit = event => {
        /*--------------------------------------------- Validazione campi obbligatori ------------------------------------------------*/
        var error = { messaggio: "Compila tutti i campi obbligatori", type: "danger" }
        if (this.state.ordineTerritoriale === undefined) {
            event.preventDefault()
            return this.props.message(error);
        }
        else if (event.target.idEmailOperatoreOrdineTerritoriale.value === "") {
            event.preventDefault()
            return this.props.message(error);
        }
        /*------------------------------------------------------------------------------------------------------------------------*/
        else {
            try {
                event.preventDefault()
                return this.props.message({ messaggio: "Iscrizione avvenuta con successo", type: "success" })
            }
            catch (err) {
                return this.props.message({ messaggio: "C'è stato un problema nell'invio dei dati", type: "danger" })
            }
        }
    }


    setFields = (fields) => {
        this.setState({fields: fields})
        this.setState({ isLoaded: true});

    }
    render() {
        const { province, comuni, provinciaSelected, fields, isDisabled } = this.state;
        const setDataItem = async (e: any, type: any, field: any) => {
            let fields = this.state.fields;

            if (type === 'select') {
                fields[field] = e.label;
                if (field === "provincia") {
                    this.setState({ provinciaSelected: false})
                    this.state.fields['comune'] = undefined;
                    await getComuniByProvinciaMsga(e.label).then(async comuni => {
                        this.setState({ comuni: comuni, provinciaSelected: true });
                    });
                }
            }
            else if (type === 'input') {
                fields[field] = e.target.value;
                if (field === "cfPiva") {

                    await getStudioAssociatoByPIva(e.target.value).then(async responseStudioFound => {

                        this.setState({ provinciaSelected: true, isDisabled: true});
                        fields = responseStudioFound
                        this.setFields(responseStudioFound)

                    }).catch(async (err) => {

                        //Se non trovo lo studio associato non accade nulla
                        //In questo caso non si deve visualizzare un messaggio di errore
                        //Nè c'è bisogno di gestire l'errore
                        //fields = {}
                        this.setState({ isLoaded: false});

                        fields = {
                            "cap": "",
                            "comune":"",
                            "idStudioAssociato": "",
                            "indirizzo": "",
                            "provincia": "",
                            "ragioneSociale": "",
                            "cfPiva": e.target.value
                        }
                        this.setFields(fields)
                        this.setState({ provinciaSelected: false, isDisabled: false });



                    })

                }
            }
            else {
                fields[field] = e.target.value;
            }

            await this.setState({ isLoaded: true});


        }
        return (
            <div>
                    {this.state.isLoaded ?
                    <>
                        <GenericCard cardHeader={'Compila i seguenti campi'} cardBody={
                            <Form id={'idCollaborazioneConStudio'} onSubmit={this.handleSubmit}>
                                <div className='row'>
                                    <div className="col-6">
                                        <FormGroup label={"CF/PIVA"} isRequired>
                                            <GenericInput type={'text'} id={'cfPiva'} placeholder={"Inserire CF/PIVA"} isRequired onChange={(e) => setDataItem(e, 'input', 'cfPiva')} defaultValue={fields['cfPiva'] ? fields['cfPiva'] : undefined}/>
                                        </FormGroup>
                                    </div>
                                    <div className='col-6'>
                                        <FormGroup label={"Ragione sociale"} isRequired>
                                        <GenericInput type={'text'} id={'ragioneSociale'} placeholder={"Inserire ragione sociale"} isRequired onChange={(e) => setDataItem(e, 'input', 'ragioneSociale')} defaultValue={fields['ragioneSociale'] ? fields['ragioneSociale'] : undefined} value={fields['ragioneSociale']} isDisabled={isDisabled}/>
                                        </FormGroup>
                                    </div>
                                    <div className='col-6'>
                                        <FormGroup label={"Provincia"} isRequired>
                                            <GenericSelect elementId={'provincia'} placeholder={"Seleziona la provincia"} defaultOptions={province} defaultValue={fields['provincia'] ? [{ label: fields['provincia'], value: fields['provincia'] }] : undefined} value={isDisabled ? [{ label: fields['provincia'], value: fields['provincia'] }] : undefined} onChange={(e) => setDataItem(e, 'select', 'provincia')} isSearchable isRequired isDisabled={isDisabled}/>
                                        </FormGroup>
                                    </div>
                                    <div className='col-6'>
                                        <FormGroup label={"Comune"} isRequired>
                                            <GenericSelect elementId={'comune'} placeholder={"Seleziona il comune"} defaultOptions={comuni} defaultValue={fields['comune'] ? [{ label: fields['comune'], value: fields['comune'] }] : undefined} value={isDisabled ? [{ label: fields['comune'], value: fields['comune'] }] : undefined} onChange={(e) => setDataItem(e, 'select', 'comune')} isSearchable isRequired isDisabled={isDisabled || !provinciaSelected}/>
                                        </FormGroup>
                                    </div>
                                    <div className='col-6'>
                                        <FormGroup label={"Indirizzo"} isRequired>
                                            <GenericInput type={'text'} id={'indirizzo'} placeholder={"Inserire indirizzo"} isRequired onChange={(e) => setDataItem(e, 'input', 'indirizzo')} defaultValue={fields['indirizzo'] ? fields['indirizzo'] : undefined} value={fields['indirizzo']} isDisabled={isDisabled}/>
                                        </FormGroup>
                                    </div>
                                    <div className='col-6'>
                                        <FormGroup label={"CAP"} isRequired>
                                            <GenericInput type={'cap'} id={'idCap'} placeholder={"Inserire CAP"} isRequired onChange={(e) => setDataItem(e, 'input', 'cap')} defaultValue={fields['cap'] ? fields['cap'] : undefined} value={fields['cap']} isDisabled={isDisabled}/>
                                        </FormGroup>
                                    </div>
                                </div>

                            </Form>
                        }/>
                        <br></br>
                        <div className="row"  >
                            <div className={isCurrentMobileDevice() ? "col": "col-1"}>
                                <GenericButton key={"salva"}  label="Salva" onClickEvent={()=>{this.props.creaStudioAssociato(this.props.idSoggetto, fields)}} color={'primary'} />
                            </div>
                            <div className={isCurrentMobileDevice() ? "col": "col-1"}>
                                <GenericButton key={"close"} label="Chiudi" onClickEvent={()=>{this.props.goToElencoStudiAssociati(PATH_TO_FO_STUDI_ASSOCIATI_ELENCO)}} color={'secondary'}  />
                            </div>
                        </div>
                    </>
                    : null}
                    <br></br><br></br>
                    <div>{listLegendaCampiObbligatori}</div>

                    <br></br>
                </div>

        )
    }
}

export declare interface GeneralFormStudiAssociatiProps {
    message?: any;
    idSoggetto?: any;
    creaStudioAssociato?: any;
    goToElencoStudiAssociati?: any;
}
