import {getAnagrafeResourcesApi, getDefaultAxiosConfig} from 'src/app/utils/ManagerRestGateway'

/**
 * GET Elenco codifica stati
 * @returns
 *
 */
export async function getCodificaStati() {
  try {
    const response = await getAnagrafeResourcesApi().msgaAnagrafeBoListaCodificaStatiGet(getDefaultAxiosConfig());

    var stati = [{ idStato: '', descrizioneStato: '' }]
    if (response)
      response.data.map((stato:any, id:any) => {
        return (
          stati[id] = {
            idStato: stato.idCodificaStati,
            descrizioneStato: stato.decoDescrizione
          }
        )
      })

    //Manca il ResponseBuilder su Backend
    return stati;
  } catch (err: any) {
    //TODO : Gestire l'errore - al momento ritorna il messaggio di errore
    // let errorMessage: any = ErrorStore.getInstance().returnMessageError(err.message, undefined)
    console.error("Errore durante la chiamata per il recupero e la decodifica degli stati. " + err);
    throw new Error("Errore durante la chiamata per il recupero e la decodifica degli stati. " + err);
  }
}
