import {useEffect, useState} from 'react'
import {getAllProvince} from 'src/processes/Province'
import {getComuniByProvinciaMsga} from 'src/processes/Comuni'
import {getFormazioneResourceApi} from 'src/app/utils/ManagerRestGateway'

const useProvince = (provincia: string, comune: string, onReset: () => void): IResponse => {
  const [province, setProvince] = useState([])
  const [comuni, setComuni] = useState([])
  const [nazioni, setNazioni] = useState<any>([])

  useEffect(() => {
    getFormazioneResourceApi().msfoFormazioneNazioniGet().then((response) => {
      const nazioni = response.data.map((nazione) => ({
        label: nazione.denominazioneIt,
        value: nazione.denominazioneIt,
      }))
      setNazioni(nazioni)
    })

    getAllProvince().then((response: any) => {
      const province: any = []
      for (const index in response?.data || []) {
        const provincia = response.data[index]
        province[index] = {
          label: provincia['denominazioneUnitaTerritorialeSovracomunale'],
          value: provincia['denominazioneUnitaTerritorialeSovracomunale'],
        }
      }

      const sortedProvince = province.sort((p1, p2) => (p1.label > p2.label ? 1 : p1.label < p2.label ? -1 : 0))
      setProvince(sortedProvince)
    })
  }, [])

  useEffect(() => {
    if (!provincia) return
    if(provincia !== "EEP" && provincia !== "Stato Estero" && provincia !== '') {
      getComuniByProvinciaMsga(provincia).then((data) => {
        // if comune is not in the list, reset it
        if (!data?.find((c) => c.value === comune)) onReset()
        setComuni(data)
      })
    }

  }, [provincia])

  return {
    province,
    comuni,
    nazioni
  }
}

interface IResponse {
  province: any[]
  comuni: any[]
  nazioni: any[]
}
export default useProvince
