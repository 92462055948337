import {Form} from '@patternfly/react-core'
import React from 'react'
import GenericTextArea from 'src/backoffice/app/components/GenericTextArea'
import GenericTextInput from 'src/backoffice/app/components/GenericTextInput'
import {cancellaIscrittoSTP} from 'src/processes/ProcessoIscrizione'
import {isCurrentMobileDevice} from 'src/utilities/deviceUtility'
import {inputRequiredValidation} from 'src/utilities/utility'

export class FormCancellaSTP extends React.Component<GeneralFormCancellaSTPProps, any> {
  constructor(props) {
    super(props)
    this.state = {
      messaggio: '',
      type: '',
    }
  }

  handleSubmit = async (event) => {
    /*--------------------------------------------- Validazione campi obbligatori ------------------------------------------------*/
    var requiredFields = [
      { value: event.target.idDeliberaCancellazioneSTP.value, label: 'Delibera' },
      { value: event.target.idDataDeliberaCancellazioneSTP.value, label: 'Data delibera' },
      { value: event.target.idDataCancellazioneSTP.value, label: 'Data cancellazione' },
      { value: event.target.idMotivoCancellazioneSTP.value, label: 'Motivo cancellazione' },
    ]

    var errorRequired: any = inputRequiredValidation(requiredFields)
    if (errorRequired !== undefined && errorRequired !== '') {
      event.preventDefault()
      return this.props.messaggio({ messaggio: errorRequired.messaggio, type: errorRequired.type })
    } else {
    /*------------------------------------------------------------------------------------------------------------------------------*/
      //chiamata al servizio per cancellazione iscritto STP
      var stato = 'cancellato'
      var idIscrizione = this.props.idIscrizione
      var parametriCancellazioneSTP = {
        deliberaCancellazione: event.target.idDeliberaCancellazioneSTP.value ? event.target.idDeliberaCancellazioneSTP.value : '',
        dataDeliberaCancellazione: event.target.idDataDeliberaCancellazioneSTP.value ? event.target.idDataDeliberaCancellazioneSTP.value : null,
        dataCancellazione: event.target.idDataCancellazioneSTP.value ? event.target.idDataCancellazioneSTP.value : null,
        motivoCancellazione: event.target.idMotivoCancellazioneSTP.value ? event.target.idMotivoCancellazioneSTP.value : '',
      }
      try {
        event.preventDefault()
        await cancellaIscrittoSTP(idIscrizione, stato, parametriCancellazioneSTP)
        return this.props.messaggio({ messaggio: 'Dati salvati con successo', type: 'success' })
      } catch (err) {
        return this.props.messaggio({ messaggio: "C'è stato un problema nell'invio dei dati", type: 'danger' })
      }
    }
  }

  render() {
    return (
      <div>
        <Form id="idCancellaSTP" onSubmit={this.handleSubmit}>
          <div className="row">
            <div className={isCurrentMobileDevice() ? null : 'col-6'}>
              <GenericTextInput
                type={'text'}
                className="w-100"
                id={'idDeliberaCancellazioneSTP'}
                label={'Delibera'}
                placeholder={'Inserire delibera'}
                isRequired
              />
            </div>
            <div className={isCurrentMobileDevice() ? null : 'col-6'}>
              <GenericTextInput
                type={'date'}
                className="w-100"
                id={'idDataDeliberaCancellazioneSTP'}
                label={'Data delibera'}
                placeholder={'Selezionare data'}
                style={{ paddingRight: '25px' }}
                isRequired
              />
            </div>
          </div>
          <div className="row">
            <div className={isCurrentMobileDevice() ? null : 'col-6'}>
              <GenericTextInput
                type={'date'}
                className="w-100"
                id={'idDataCancellazioneSTP'}
                label={'Data cancellazione'}
                placeholder={'Selezionare data'}
                style={{ paddingRight: '25px' }}
                isRequired
              />
            </div>
            <div className={isCurrentMobileDevice() ? null : 'col-6'}>
              <GenericTextArea className="w-100" id={'idMotivoCancellazioneSTP'} label={'Motivo cancellazione'} placeholder={'Inserire motivo'} isRequired />
            </div>
          </div>
        </Form>
      </div>
    )
  }
}

export declare interface GeneralFormCancellaSTPProps {
  messaggio?: any
  idIscrizione?: any
}
