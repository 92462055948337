import * as React from 'react'
import {Form} from '@patternfly/react-core'
import GenericTextInput from '../../../components/GenericTextInput'
import GenericSelect from '../../../components/GenericSelect'
import {inputRequiredValidation, isOnlyNumbers} from 'src/utilities/utility'
import {valutaIscrizioneOrdine} from 'src/processes/ProcessoIscrizione'
import {isCurrentMobileDevice} from 'src/utilities/deviceUtility'

/* ----------------------------------- VARIABILI E COSTANTI ----------------------------------------- */
const age_senior = 75
const age_professional = 50

//opzioni select -> 0: 'No',  1: 'Si'
var flag_categoria_B = [
  { value: 1, label: 'Si', disabled: false },
  { value: 0, label: 'No', disabled: false },
]
var flag_categoria_C = [
  { value: 1, label: 'Si', disabled: false },
  { value: 0, label: 'No', disabled: false },
]
var flag_categoria_D = [
  { value: 1, label: 'Si', disabled: false },
  { value: 0, label: 'No', disabled: false },
]
var flag_esonero_quota_ordine = [
  { value: 1, label: 'Si', disabled: false },
  { value: 0, label: 'No', disabled: false },
]
var flag_esonero_quota_conaf = [
  { value: 1, label: 'Si', disabled: false },
  { value: 0, label: 'No', disabled: false },
]
var flag_iscritto_perito_tribunale_penale = [
  { value: 1, label: 'Si', disabled: false },
  { value: 0, label: 'No', disabled: false },
]
var flag_iscritto_ctu_tribunale_civile = [
  { value: 1, label: 'Si', disabled: false },
  { value: 0, label: 'No', disabled: false },
]

//flag di default select impostato a 'No'
const defaultValue = 0
/* ------------------------------------------------------------------------------------------------- */

export class FormConfermaIscritto extends React.Component<GeneralFormConfermaIscrittoProps, any> {
  constructor(props) {
    super(props)
    this.state = {
      isDateValiTimbroHidden: true,
      isDateValiMartelloHidden: true,
    }
  }

  //onChange input numero timbro e numero martello
  inputOnChange = (event, handleFunction) => {
    switch (handleFunction) {
      //case 1: onChange numero timbro
      case 1:
        if (event === '') {
          this.setState({ isDateValiTimbroHidden: true })
        } else {
          this.setState({ isDateValiTimbroHidden: false })
        }
        break
      //case 2: onChange numero martello
      case 2:
        if (event === '') {
          this.setState({ isDateValiMartelloHidden: true })
        } else {
          this.setState({ isDateValiMartelloHidden: false })
        }
        break
    }
  }

  handleSubmit = async (event) => {
    /*--------------------------------------------- Validazione campi obbligatori ------------------------------------------------*/
    var requiredFields = [
      isOnlyNumbers(event.target.idNumeroIscrizione.value)
        ? { value: event.target.idNumeroIscrizione.value, label: 'Numero iscrizione' }
        : { value: '', label: 'Numero iscrizione' },
      { value: event.target.idDataIscrizione.value, label: 'Data iscrizione' },
      { value: event.target.idDeliberaIscrizione.value, label: 'Delibera iscrizione' },
      !this.state.isDateValiTimbroHidden ? { value: event.target.idDataIniValiTimbro.value, label: 'Data inizio validità timbro' } : null,
      !this.state.isDateValiMartelloHidden ? { value: event.target.idDataIniValiMartello.value, label: 'Data inizio validità martello' } : null,
      { value: event.target.idEsoneroQuotaOrdine.value, label: 'Esonerato da pagamento della quota all’ordine' },
    ]

    var errorRequired: any = inputRequiredValidation(requiredFields)
    if (errorRequired !== undefined && errorRequired !== '') {
      event.preventDefault()
      return this.props.messaggio({ messaggio: errorRequired.messaggio, type: errorRequired.type })
    } else {
    /*------------------------------------------------------------------------------------------------------------------------------*/
      //chiamata al servizio valutaIscrizione per conferma iscrizione
      var esitoValutazione = 'iscritto'
      var idIscrizione = this.props.idIscrizione
      var idSoggetto = this.props.soggetto.idSoggetto
      var parametriConferma = {
        numeroIscrizione: event.target.idNumeroIscrizione.value ? event.target.idNumeroIscrizione.value : '',
        dataIscrizione: event.target.idDataIscrizione.value ? event.target.idDataIscrizione.value : null,
        deliberaIscrizione: event.target.idDeliberaIscrizione.value ? event.target.idDeliberaIscrizione.value : '',
        numeroTimbro: event.target.idNumeroTimbro.value ? event.target.idNumeroTimbro.value : '',
        dataInizioValiTimbro: !this.state.isDateValiTimbroHidden && event.target.idDataIniValiTimbro.value ? event.target.idDataIniValiTimbro.value : null,
        dataFineValiTimbro: !this.state.isDateValiTimbroHidden && event.target.idDataFineValiTimbro.value ? event.target.idDataFineValiTimbro.value : null,
        numeroMartello: event.target.idNumeroMartello.value ? event.target.idNumeroMartello.value : '',
        dataInizioValiMartello:
          !this.state.isDateValiMartelloHidden && event.target.idDataIniValiMartello.value ? event.target.idDataIniValiMartello.value : null,
        dataFineValiMartello:
          !this.state.isDateValiMartelloHidden && event.target.idDataFineValiMartello.value ? event.target.idDataFineValiMartello.value : null,
        categoriaB: event.target.idCategoriaB.value ? event.target.idCategoriaB.value : defaultValue,
        categoriaC: event.target.idCategoriaC.value ? event.target.idCategoriaC.value : defaultValue,
        categoriaD: event.target.idCategoriaD.value ? event.target.idCategoriaD.value : defaultValue,
        esoneroPagaQuotaOrdine: event.target.idEsoneroQuotaOrdine.value ? event.target.idEsoneroQuotaOrdine.value : null,
        esoneroPagaQuotaConaf: event.target.idEsoneroQuotaConaf.value ? event.target.idEsoneroQuotaConaf.value : defaultValue,
        iscrittoPeritoTribunalePenale: event.target.idIscrittoPeritoTribunalePenale.value ? event.target.idIscrittoPeritoTribunalePenale.value : defaultValue,
        iscrittoCTUTribunaleCivile: event.target.idIscrittoCTUTribunaleCivile.value ? event.target.idIscrittoCTUTribunaleCivile.value : defaultValue,
      }
      try {
        event.preventDefault()
        await valutaIscrizioneOrdine(esitoValutazione, idIscrizione, idSoggetto, parametriConferma, [])
        return this.props.messaggio({ messaggio: 'Dati salvati con successo', type: 'success' })
      } catch (err) {
        return this.props.messaggio({ messaggio: "C'è stato un problema nell'invio dei dati", type: 'danger' })
      }
    }
  }

  render() {
    const { isDateValiTimbroHidden, isDateValiMartelloHidden } = this.state
    return (
      <div>
        <Form id="idConfermaIscritto" onSubmit={this.handleSubmit}>
          <div className="row">
            <div className={isCurrentMobileDevice() ? null : 'col-6'}>
              <GenericTextInput
                type={'only-number'}
                className="w-75"
                id={'idNumeroIscrizione'}
                label={'Numero iscrizione'}
                placeholder={'Inserire numero iscrizione'}
                isRequired
              />
            </div>
            <div className={isCurrentMobileDevice() ? null : 'col-6'}>
              <GenericTextInput
                type={'date'}
                className="w-75"
                id={'idDataIscrizione'}
                label={'Data iscrizione'}
                placeholder={'Selezionare data'}
                style={{ paddingRight: '25px' }}
                isRequired
              />
            </div>
          </div>
          <div className="row">
            <div className={isCurrentMobileDevice() ? null : 'col-6'}>
              <GenericTextInput
                type={'text'}
                className="w-75"
                id={'idDeliberaIscrizione'}
                label={'Delibera iscrizione'}
                placeholder={'Inserire delibera iscrizione'}
                isRequired
              />
            </div>
          </div>
          <div className="row">
            <div className={isCurrentMobileDevice() ? null : 'col-6'}>
              <GenericTextInput
                type={'text'}
                className="w-75"
                id={'idNumeroTimbro'}
                label={'Numero timbro'}
                placeholder={'Inserire numero timbro'}
                onChange={(e) => this.inputOnChange(e, 1)}
              />
            </div>
          </div>
          {/*-------------------------------------------------------- Campi visibili solo se presente il numero timbro ----------------------------------------------------*/}
          <div className="row" hidden={isDateValiTimbroHidden}>
            <div className={isCurrentMobileDevice() ? null : 'col-6'}>
              {/*Campo obbligatorio solo se è inserito il numero Timbro*/}
              <GenericTextInput
                type={'date'}
                className="w-75"
                id={'idDataIniValiTimbro'}
                label={'Data inizio validità timbro'}
                placeholder={'Selezionare data'}
                style={{ paddingRight: '25px' }}
                isRequired={!isDateValiTimbroHidden}
              />
            </div>
            <div className={isCurrentMobileDevice() ? null : 'col-6'}>
              {/*Se c’è la data di inizio e non è compilato si intende privo di scadenza*/}
              <GenericTextInput
                type={'date'}
                className="w-75"
                id={'idDataFineValiTimbro'}
                label={'Data fine validità timbro'}
                placeholder={'Selezionare data'}
                style={{ paddingRight: '25px' }}
              />
            </div>
          </div>
          {/*------------------------------------------------------------------------------------------------------------------------------------------------------------- */}
          <div className="row">
            <div className={isCurrentMobileDevice() ? null : 'col-6'}>
              <GenericTextInput
                type={'text'}
                className="w-75"
                id={'idNumeroMartello'}
                label={'Numero martello'}
                placeholder={'Inserire numero martello'}
                onChange={(e) => this.inputOnChange(e, 2)}
              />
            </div>
          </div>
          {/*------------------------------------------------------ Campi visibili solo se presente il numero martello ----------------------------------------------------*/}
          <div className="row" hidden={isDateValiMartelloHidden}>
            <div className={isCurrentMobileDevice() ? null : 'col-6'}>
              {/*Campo obbligatorio solo se è inserito il numero Matello*/}
              <GenericTextInput
                type={'date'}
                className="w-75"
                id={'idDataIniValiMartello'}
                label={'Data inizio validità martello'}
                placeholder={'Selezionare data'}
                style={{ paddingRight: '25px' }}
                isRequired={!isDateValiMartelloHidden}
              />
            </div>
            <div className={isCurrentMobileDevice() ? null : 'col-6'}>
              {/* Se c’è la data di inizio e non è compilato si intende privo di scadenza*/}
              <GenericTextInput
                type={'date'}
                className="w-75"
                id={'idDataFineValiMartello'}
                label={'Data fine validità martello'}
                placeholder={'Selezionare data'}
                style={{ paddingRight: '25px' }}
              />
            </div>
          </div>
          {/*------------------------------------------------------------------------------------------------------------------------------------------------------------- */}
          <div className="row">
            {/*---------- Campo visibile solo per iscritti con anzianità superiore ai 50 anni. Se non selezionato è "No" di  default ----------*/}
            <div className={isCurrentMobileDevice() ? null : 'col-6'} hidden={!(this.props.soggetto.anniAnzianita > age_professional)}>
              <GenericSelect className="w-75" id={'idCategoriaB'} label={'Categoria B - Professional'} value={defaultValue} list={flag_categoria_B} />
            </div>
            {/*--------------------------------------------------------------------------------------------------------------------------------*/}
            <div className={isCurrentMobileDevice() ? null : 'col-6'}>
              {/*Se non selezionato è “No” */}
              <GenericSelect className="w-75" id={'idCategoriaC'} label={'Categoria C - Emeriti'} value={defaultValue} list={flag_categoria_C} />
            </div>
          </div>
          {/*---------- Campo visibile solo per iscritti con anzianità superiore ai 75 anni. Se non selezionato è "No" di  default ----------*/}
          <div className="row" hidden={!(this.props.soggetto.anniAnzianita > age_senior)}>
            <div className={isCurrentMobileDevice() ? null : 'col-6'}>
              <GenericSelect className="w-75" id={'idCategoriaD'} label={'Categoria D - Senior'} value={defaultValue} list={flag_categoria_D} />
            </div>
          </div>
          {/*--------------------------------------------------------------------------------------------------------------------------------*/}
          <div className="row">
            <div className={isCurrentMobileDevice() ? null : 'col-6'}>
              <GenericSelect
                className="w-75"
                id={'idEsoneroQuotaOrdine'}
                label={'Esonerato da pagamento della quota all’ordine'}
                value={defaultValue}
                list={flag_esonero_quota_ordine}
                isRequired
              />
            </div>
            <div className={isCurrentMobileDevice() ? null : 'col-6'}>
              {/*Se non selezionato è “No” */}
              <GenericSelect
                className="w-75"
                id={'idEsoneroQuotaConaf'}
                label={'Esonerato da pagamento della quota CONAF'}
                value={defaultValue}
                list={flag_esonero_quota_conaf}
              />
            </div>
          </div>
          <div className="row">
            <div className={isCurrentMobileDevice() ? null : 'col-6'}>
              {/*Se non selezionato è “No” */}
              <GenericSelect
                className="w-75"
                id={'idIscrittoPeritoTribunalePenale'}
                label={'Iscritto perito tribunale penale'}
                value={defaultValue}
                list={flag_iscritto_perito_tribunale_penale}
              />
            </div>
            <div className={isCurrentMobileDevice() ? null : 'col-6'}>
              {/*Se non selezionato è “No” */}
              <GenericSelect
                className="w-75"
                id={'idIscrittoCTUTribunaleCivile'}
                label={'Iscritto CTU tribunale civile'}
                value={defaultValue}
                list={flag_iscritto_ctu_tribunale_civile}
              />
            </div>
          </div>
        </Form>
      </div>
    )
  }
}

export declare interface GeneralFormConfermaIscrittoProps {
  messaggio?: any
  idIscrizione?: any
  soggetto?: any
}
