import * as React from 'react'
import {Form} from '@patternfly/react-core'
import {GenericTextArea} from '../../../components/GenericTextArea'
import {riapriPolizza} from 'src/processes/assicurazioni/Assicurazione'
import {PATH_TO_BO_POLIZZE_ELENCO} from 'src/app/utils/RoutesConstants'
import GenericModal from 'src/backoffice/app/components/GenericModal'
import {inputRequiredValidation} from 'src/utilities/utility'
import {isCurrentMobileDevice} from 'src/utilities/deviceUtility'

export class FormRiapriPolizza extends React.Component<GeneralFormRiapriPolizzaProps, any> {
    constructor(props) {
        super(props);
        this.state = {
            errorFlag: false,
            isLoaded: false,
        };
      }

    closeModal = () => {
        this.setState({modalOpen:false});
        if(this.state.errorFlag === false) window.location.href = PATH_TO_BO_POLIZZE_ELENCO;
    }

    showModal = async (booleanValue, title, text) => {
        await this.setState({
            modalOpen:booleanValue,
            modalTitle: title,
            modalText:text,
            isLoaded: true,
        });
    }

    handleSubmit = async event => {
        await this.setState({isLoaded:false})
        var requiredFields = [{ value: event.target.idMotivoRiapriPolizza.value, label: 'Motivo' }]
        var errorRequired: any = inputRequiredValidation(requiredFields)
        if (errorRequired !== undefined && errorRequired !== "") {
            event.preventDefault()
            return this.props.messaggio({ messaggio: errorRequired.messaggio, type: errorRequired.type })
        } else {
            var idPolizza = this.props.idPolizza
            var parametriRiapri = {
                idPolizza: idPolizza,
                note: event.target.idMotivoRiapriPolizza.value ? event.target.idMotivoRiapriPolizza.value : "",
            }
            event.preventDefault();
            try {
                event.preventDefault();
                await riapriPolizza(parametriRiapri)
                await this.setState({errorFlag: false})
                this.props.messaggio({ messaggio: "Dati salvati con successo", type: "success" })
                await this.showModal(true, "Messaggio","Polizza riaperta con successo");
                await this.setState({isLoaded:true})
                return false;
            }
            catch(err: any) {
                event.preventDefault();
                await this.setState({errorFlag: true})

                await this.showModal(true, "Errore",err.message);
                return false;
            }
        }
    }


    render() {
        return (
            <div>
                <Form id='idRiapriPolizza' onSubmit={this.handleSubmit}>
                    <GenericTextArea className={isCurrentMobileDevice() ? 'col-12' : 'w-50' } id={"idMotivoRiapriPolizza"} label={"Motivo"} name={"idMotivoRiapriPolizza"} placeholder={"Motivo della richiesta"} ariaLabel={'textarea-idMotivoRiapriPolizza'} isRequired />
                </Form>
                <GenericModal
                          title={this.state.modalTitle}
                          text={this.state.modalText}
                          modalOpen={this.state.modalOpen}
                          closeButton={this.closeModal}
                />
            </div>
        )
    }
}

export declare interface GeneralFormRiapriPolizzaProps {
    messaggio?: any;
    idPolizza?: any;
    idSoggetto?: any;
}
