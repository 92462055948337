import {cquery} from 'src/processes/CubeService'
import {Filter, Query} from '@cubejs-client/core'
import React from 'react'
import {Link} from 'react-router-dom'
import {getDefaultAxiosConfig, getMsgaReportResourceApi} from 'src/app/utils/ManagerRestGateway'
import {ReportByDate} from '@parsec/msga/dist/models'
import {isEmptyValue} from 'src/utilities/utility'

export namespace ST_SEC {
  export function unwrapSecurityContext(currentAccount) {
    let accountKey = Object.keys(currentAccount.accounts)[0]
    let account = currentAccount.accounts[accountKey]
    return account
  }

  export function isConaf(currentAccount) {
    let accountKey = Object.keys(currentAccount.accounts)[0]
    let account = currentAccount.accounts[accountKey]
    if (account.genericGroup === 'CONAF') {
      return true
    }
    return false
  }

  export function isOdaf(currentAccount) {
    let accountKey = Object.keys(currentAccount.accounts)[0]
    let account = currentAccount.accounts[accountKey]
    if (account.genericGroup === 'ODAF') {
      return true
    }
    return false
  }
  export function isFodaf(currentAccount) {
    let accountKey = Object.keys(currentAccount.accounts)[0]
    let account = currentAccount.accounts[accountKey]
    if (account.genericGroup === 'FODAF') {
      return true
    }
    return false
  }

  //funzione di sicurezza sulle query per impostarne il filtro
  export function query(query, currentAccount) {
    let account = unwrapSecurityContext(currentAccount)
    let filters: any[] = []
    if ('filters' in query) {
      filters = query.filters
    }

    let queryFilter = query
    if (account.genericGroup === 'ODAF') {
      const filtroCodiceGruppo = [account.specificGroup]
      if (filtroCodiceGruppo.length > 0) {
        filters.push({
          member: 'DimOrdine.codiceGruppo',
          operator: 'equals',
          values: [...filtroCodiceGruppo],
        })
      }
      queryFilter.filters = filters
    } else if (
      account.genericGroup === 'FODAF' &&
      !(filters.some((obj) => obj.member === 'DimOrdine.codiceGruppo') && filters.some((obj) => obj.member === 'DimForPeriodo.periodo'))
    ) {
      const filtroCodiceGruppo = [account.specificGroup]
      if (filtroCodiceGruppo.length > 0) {
        filters.push({
          member: 'DimFederazione.codiceGruppo',
          operator: 'equals',
          values: [...filtroCodiceGruppo],
        })
      }
      queryFilter.filters = filters
    }
    return queryFilter
  }
}

export namespace ST_ANAG {
  export const pivotOptionsIscritti = {
    rows: ['Ordine', 'Ente', 'Stato', 'Sezione', 'Titolo'],
    cols: ['Sesso'],
    aggregatorName: 'Integer Sum',
    vals: ['Valore'],
    rendererName: 'Table',
    totalCells: 'Totale',
    data: [],
  }

  export const pivotOptionsTotale = {
    rows: ['Ordine'],
    cols: ['Stato'],
    aggregatorName: 'Integer Sum',
    vals: ['Valore'],
    rendererName: 'Table',
    totalCells: 'Totale',
    data: [],
  }

  /* const query per la visualizzazione dei dati **/
  const query: Query = {
    measures: ['FactsIotAggregates.count'],
    order: [
      ['FactsIotAggregates.anno_iscrizione', 'asc'],
      ['DimOrdine.denominazioneGruppo', 'asc'],
    ],
    dimensions: [
      'DimOrdine.denominazioneGruppo',
      'FactsIotAggregates.anno_iscrizione',
      'DimCodificaStatiIscr.decoDescrizione',
      'DimTipoEntePrevidenza.descrizione',
      'DimTitoloIscritto.titoloEsteso',
      'DimGenere.sesso',
      'DimTitoloIscritto.sezione',
      'DimOrdine.codiceGruppo',
      'DimFederazione.codiceGruppo',
      'DimDisciplinareStato.descrizione',
    ],
  }

  const queryOrdini: Query = {
    dimensions: ['DimOrdine.denominazioneGruppo', 'DimOrdine.codiceGruppo'],
  }

  const queryOrdiniDTO = `
      *.{
          "Ordine": \`DimOrdine.denominazioneGruppo\`,
          "CodiceGruppo": \`DimOrdine.codiceGruppo\`
      }
  `

  const pivotRemappingIscrittiPerOrdine = `
    *.{
          "Ordine": \`DimOrdine.denominazioneGruppo\`,
          "Anno": \`FactsIotAggregates.anno_iscrizione\`,
          "Stato": \`DimCodificaStatiIscr.decoDescrizione\`,
          "Ente": \`DimTipoEntePrevidenza.descrizione\`,
          "Titolo": \`DimTitoloIscritto.titoloEsteso\`,
          "Valore": $number(\`FactsIotAggregates.count\`),
          "Sesso": \`DimGenere.sesso\`,
          "Sezione": \`DimTitoloIscritto.sezione\`,
          "Codice Gruppo": \`DimOrdine.codiceGruppo\`,
          "Codice Gruppo Fed.": \`DimFederazione.codiceGruppo\`,
          "Provvedimenti": \`DimDisciplinareStato.descrizione\`
    }
  `

  export async function statisticheIscritti(currentAccount, context, callback) {
    let squery = ST_SEC.query(query, currentAccount)

    //query per le statistiche sugli iscritti
    cquery(squery, pivotRemappingIscrittiPerOrdine, callback.bind(context))
  }

  export async function elencoOrdini(currentAccount, context, callback) {
    let squery = ST_SEC.query(queryOrdini, currentAccount)
    //query per le statistiche aggregate dell'ordine
    cquery(squery, queryOrdiniDTO, callback.bind(context))
  }

  export async function statisticheStatoIscrizione(currentAccount, context, callback) {
    let squery = ST_SEC.query(queryOrdini, currentAccount)
    //query per le statistiche aggregate dell'ordine
    cquery(squery, pivotOptionsTotale, callback.bind(context))
  }

  export async function searchPivotIscrittiByFilters(currentAccount, context) {
    let filters: any[] = []
    let queryFilter = query
    const filtroCodiceGruppo = [...context.state.filtroOrdiniTerritoriali]

    if (filtroCodiceGruppo.length > 0) {
      filters.push({
        member: 'DimOrdine.codiceGruppo',
        operator: 'equals',
        values: [...filtroCodiceGruppo],
      })
    }

    queryFilter.filters = filters
    cquery(query, pivotRemappingIscrittiPerOrdine, context.updateStateElencoIscrittiPivot)
  }
}

export namespace ST_POLI {
  const queryAnniCopertura: Query = {
    order: {
      'FactsPolizze.anno_fine_validita': 'asc',
    },
    dimensions: ['FactsPolizze.anno_fine_validita'],
  }

  const queryAnniCoperturaDTO = `
    *.{
        "AnnoCopertura": \`FactsPolizze.anno_fine_validita\`
    }
  `

  export const pivotPoliDatiGeneraliAnno = {
    rows: [
      'Cognome',
      'Nome',
      'PIva',
      'Titolo',
      'Anno copertura',
      'Numero Iscrizione',
      'Premio',
      'Massimale',
      'StatoPolizza',
      'Progressivo',
      'Validità da',
      'Validità a',
      'TipoAssicurato',
      'TipoRichiesta',
      'GiorniCopertura',
    ],
    cols: ['Ordine', 'Anno copertura'],
    aggregatorName: 'Count',
    vals: ['Massimale', 'Premio'],
    rendererName: 'Table',
    totalCells: 'Totale',
    data: [],
  }

  /* const query per la visualizzazione dei dati **/
  export const queryPoliDatiGeneraliAnno: Query = {
    order: {
      'DimSoggetto.cognome': 'asc',
      'FactsPolizze.anno_fine_validita': 'asc',
    },
    dimensions: [
      'DimPolTipoAssicurato.descrizione',
      'DimPolStato.descrizione',
      'FactsPolizze.anno_fine_validita',
      'FactsPolizze.massimale',
      'FactsPolizze.premio',
      'FactsPolizze.id_data_val_pol_da',
      'FactsPolizze.id_data_val_pol_a',
      'DimPolCategorie.descrizione',
      'DimOrdine.codiceGruppo',
      'DimOrdine.denominazioneGruppo',
      'DimSoggetto.nome',
      'DimSoggetto.cognome',
      'DimSoggetto.partita_iva',
      'DimTitoloIscritto.titoloEsteso',
      'FactsPolizze.it_formati_data_val_pol_da',
      'FactsPolizze.it_formati_data_val_pol_a',
      'FactsPolizze.progressivo_polizza',
      'FactsPolizze.giorni_di_copertura',
      'DimIot.numero_iscrizione_ordine',
      'DimIot.desc_stato_iscrizione',
      'DimPolTipoRichiesta.descrizione_richiesta',
    ],
  }

  const queryPoliDatiGeneraliAnnoDTO = `
      *.{
        "TipoAssicurato": \`DimPolTipoAssicurato.descrizione\`,
        "StatoPolizza": \`DimPolStato.descrizione\`,
        "Anno copertura": $number(\`FactsPolizze.anno_fine_validita\`),
        "Massimale": \`FactsPolizze.massimale\`,
        "CodiceGruppo": \`DimOrdine.codiceGruppo\`,
        "Ordine": \`DimOrdine.denominazioneGruppo\`,
        "Nome": \`DimSoggetto.nome\`,
        "PIva": \`DimSoggetto.partita_iva\`,
        "Cognome": \`DimSoggetto.cognome\`,
        "Premio": \`FactsPolizze.premio\`,
        "Categoria": \`DimPolCategorie.descrizione\`,
        "Validità da": \`FactsPolizze.it_formati_data_val_pol_da\`,
        "Validità a": \`FactsPolizze.it_formati_data_val_pol_a\`,
        "Progressivo": \`FactsPolizze.progressivo_polizza\`,
        "GiorniCopertura": \`FactsPolizze.giorni_di_copertura\`,
        "Numero Iscrizione": \`DimIot.numero_iscrizione_ordine\`,
        "Titolo": \`DimTitoloIscritto.titoloEsteso\`,
        "TipoRichiesta": \`DimPolTipoRichiesta.descrizione_richiesta\`
      }
  `

  /*
   * Questa funzione recupera l'elenco degli anni di copertura per le polizze
   */
  export async function elencoAnniCopertura(currentAccount, context, callback) {
    let squery = ST_SEC.query(queryAnniCopertura, currentAccount)
    //query per le statistiche aggregate dell'ordine
    cquery(squery, queryAnniCoperturaDTO, callback.bind(context))
  }
  //Questa funzione richiede un filtro sull'ordine territoriale anche in caso di ordine competente e di conaf nazionale,
  //a causa della grossa mole di dati
  export async function statisticheGeneraliPolizze(filtro, currentAccount, context, callback) {
    let filters: any[] = []
    //se filtro è presente
    if (filtro.length > 0) {
      let searchable = false
      let searchByOrdine = false
      let searchByAnnoCopertura = false

      for (let i = 0; i < filtro.length; i++) {
        let currentFiltro = filtro[i]
        if (currentFiltro?.selectKeyLabel && currentFiltro?.selectKeyLabel === 'ordini') {
          let ordini = currentFiltro?.state?.selected
          if (ordini.some((ordine) => ordine === 'ODAF_')) {
            //Ha selezionato tutti gli ordini
          } else {
            let objectOrdine = {
              member: 'DimOrdine.codiceGruppo',
              operator: 'in',
              values: ordini,
            }
            // Verifica se l'oggettoOrdine esiste già nell'array filters
            const existingObject = filters.find(
              (item) =>
                item.member === objectOrdine.member &&
                item.operator === objectOrdine.operator &&
                JSON.stringify(item.values) === JSON.stringify(objectOrdine.values)
            )

            // Se l'oggettoOrdine non esiste già, lo inserisce in filters
            if (!existingObject) {
              filters.push(objectOrdine)
            }
          }
          searchByOrdine = true
        }
        if (currentFiltro?.selectKeyLabel && currentFiltro?.selectKeyLabel === 'anniCopertura') {
          let anni = currentFiltro?.state?.selected

          let objectAnno = {
            member: 'FactsPolizze.anno_fine_validita',
            operator: 'in',
            values: anni,
          }
          // Verifica se l'oggettoOrdine esiste già nell'array filters
          const existingObject = filters.find(
            (item) =>
              item.member === objectAnno.member && item.operator === objectAnno.operator && JSON.stringify(item.values) === JSON.stringify(objectAnno.values)
          )

          // Se l'oggettoOrdine non esiste già, lo inserisce in filters
          if (!existingObject) {
            filters.push(objectAnno)
          }
          filters.push(objectAnno)

          searchByAnnoCopertura = true
        } else {
          if (ST_SEC.isOdaf(currentAccount) === true) {
            filters.push(currentFiltro[0])
            searchByOrdine = true
            searchByAnnoCopertura = true
          }
        }
      }

      if (searchByOrdine === true) {
        searchable = true
      }
    }

    if (filters.length > 0) {
      queryPoliDatiGeneraliAnno.filters = filters
      let squery = ST_SEC.query(queryPoliDatiGeneraliAnno, currentAccount)
      cquery(squery, queryPoliDatiGeneraliAnnoDTO, callback.bind(context))
    }
  }
}

export namespace ST_FOR {
  export const OBB_CFP_CARATT_LABEL = 'CFP CARATTERIZZANTI'
  export const CFP_META_RICHIESTI_LABEL = 'CFP Meta Richiesti'
  export const CFP_META_OTTENUTI = 'Totale CFP META Periodo'
  export const CFP_TOT_RICHIESTI = 'Totale CFP Richiesti senza esonerabilità'
  export const CFP_TOT_ESONERABILITA = "CFP rimossi dall'obbligo formativo per ogni anno di esonerabilità"
  export const CFP_TOT_OTTENUTI = 'TOT CFP PERIODO'
  export const CFP_TOT_CARA_OTTENUTI = 'TOT CFP CARA. Periodo'
  export const REGOLARITA_FORMATIVA = 'Regolarità Formativa'
  export const ESONERATO = 'Esonerato'
  export const ESONERATO_LABEL_SI = 'SI'
  export const ESONERATO_LABEL_NO = 'NO'
  export const GIORNI_ESONERO_PERIODO_LABEL = 'Giorni Esonero Periodo'
  export const DURATA_ESONERO = 'Durata Esonero'
  export const REGOLARITA_OTTENUTA_LABEL_SI = 'SI'
  export const REGOLARITA_OTTENUTA_LABEL_NO = 'NO'
  export const OBBLIGO_FORMATIVO = 'Obbligo Formativo'
  export const TOT_CFP_MANCANTI = 'Totali mancanti'
  export const TOT_CFP_META_MANCANTI = 'Meta mancanti'
  export const TOT_CFP_ANNO = 'TOT CFP ANNO'
  export const NUMERO_ISCRIZIONE = 'Iscrizione'
  export const DATA_ISCRIZIONE = 'Data iscrizione'
  export const CF = 'CF'
  export const NUMERO_GIORNI_PERIODO = 'Numero Giorni Periodo'
  export const NUMERO_ANNI_PERIODO = 'Numero Anni Periodo'

  export const calcoloEsonero = function (record) {
    let annoIscrizione
    if (!isEmptyValue(record[DATA_ISCRIZIONE])) {
      annoIscrizione = parseInt(record[DATA_ISCRIZIONE].split('/')[2], 10)
    }
    if (!isEmptyValue(record['Anno']) && !isEmptyValue(annoIscrizione) && record['Anno'] <= annoIscrizione) {
      return ESONERATO_LABEL_SI
    } else if (
      !isEmptyValue(record['Anno']) &&
      !isEmptyValue(record['Anno Fine Esonero']) &&
      !isEmptyValue(record['Anno Inizio Esonero']) &&
      record['Anno'] <= record['Anno Fine Esonero'] &&
      record['Anno'] >= record['Anno Inizio Esonero']
    ) {
      return ESONERATO_LABEL_SI
    } else if (
      !isEmptyValue(record['Anno']) &&
      !isEmptyValue(record['Anno Fine Esonero']) &&
      !isEmptyValue(record['Anno Inizio Esonero']) &&
      (record['Anno'] > record['Anno Fine Esonero'] || record['Anno'] < record['Anno Inizio Esonero'])
    ) {
      return ESONERATO_LABEL_NO
    } else {
      return ESONERATO_LABEL_NO
    }
  }

  //questa funzione calcola l'obbligo formativo totale al netto degli esoneri
  export const obbligoFormativo = function (record) {
    var cfpTotRichiesti = Number(record[CFP_TOT_RICHIESTI])
    var giorniEsonero = Number(record[GIORNI_ESONERO_PERIODO_LABEL])
    var numeroGiorniPeriodo = Number(record[NUMERO_GIORNI_PERIODO])
    var numeroAnniPeriodo = Number(record[NUMERO_ANNI_PERIODO])

    var giorniEsoneroMassimiTriennio = numeroGiorniPeriodo * numeroAnniPeriodo
    var obbligoFormativo = ((giorniEsoneroMassimiTriennio - giorniEsonero) / giorniEsoneroMassimiTriennio) * cfpTotRichiesti
    return obbligoFormativo.toFixed(3)
  }

  //questa funzione calcola l'obbligo formativo meta totale al netto degli esoneri
  export const obbligoFormativoMeta = function (record) {
    const annoPrimaIscrizione = parseInt(record[DATA_ISCRIZIONE].split('/')[2], 10)
    const periodoInizio = parseInt(record['Periodo'].split('-')[0])
    const periodoFine = periodoInizio + 1

    if (annoPrimaIscrizione >= periodoInizio && annoPrimaIscrizione <= periodoFine) {
      if (periodoFine >= 2023) {
        return 1
      } else {
        let cfpMetaTotRichiesti = 0.5
        const giorniEsonero = Number(record[GIORNI_ESONERO_PERIODO_LABEL])
        const numeroGiorniPeriodo = Number(record[NUMERO_GIORNI_PERIODO])
        const numeroAnniPeriodo = Number(record[NUMERO_ANNI_PERIODO])
        const giorniEsoneroMassimiTriennio = numeroGiorniPeriodo * numeroAnniPeriodo
        const obbligoFormativo = Math.max((giorniEsoneroMassimiTriennio - giorniEsonero) / giorniEsoneroMassimiTriennio, cfpMetaTotRichiesti)
        return obbligoFormativo.toFixed(3)
      }
    } else {
      const cfpMetaTotRichiesti = Number(record[CFP_META_RICHIESTI_LABEL])
      const giorniEsonero = Number(record[GIORNI_ESONERO_PERIODO_LABEL])
      const numeroGiorniPeriodo = Number(record[NUMERO_GIORNI_PERIODO])
      const numeroAnniPeriodo = Number(record[NUMERO_ANNI_PERIODO])
      const giorniEsoneroMassimiTriennio = numeroGiorniPeriodo * numeroAnniPeriodo
      const obbligoFormativo = ((giorniEsoneroMassimiTriennio - giorniEsonero) / giorniEsoneroMassimiTriennio) * cfpMetaTotRichiesti
      return obbligoFormativo.toFixed(3)
    }
  }

  export const linkAzioni = function (record) {
    return (props) => {
      const { value } = props
      return (
        <Link to={`/a`}>
          <button>a</button>
        </Link>
      )
    }
  }

  //questa funzione verifica se la regolarità formativa è rispettata
  var regolaritaFormativa = function (record) {
    var obbligo = ST_FOR.obbligoFormativo(record)
    if (Number(obbligo) == 0) {
      return REGOLARITA_OTTENUTA_LABEL_SI
    }

    var cfpMetaOttenuti = Number(record[CFP_META_OTTENUTI])
    var cfpMetaMancanti = Number(record['Meta mancanti'])
    var cfpTotRichiesti = Number(record[CFP_TOT_RICHIESTI])
    var cfpTotOttenuti = Number(record[CFP_TOT_OTTENUTI])
    var numeroGiorniPeriodo = Number(record[NUMERO_GIORNI_PERIODO])
    var numeroAnniPeriodo = Number(record[NUMERO_ANNI_PERIODO])
    var giorniEsonero = Number(record[GIORNI_ESONERO_PERIODO_LABEL])
    var cfpMetaTotRichiesti = Number(record[CFP_META_RICHIESTI_LABEL])
    var giorniEsoneroMassimiTriennio = numeroGiorniPeriodo * numeroAnniPeriodo

    //obbligo formativo totale
    var obbligoFormativo = ((giorniEsoneroMassimiTriennio - giorniEsonero) / giorniEsoneroMassimiTriennio) * cfpTotRichiesti

    //obbligo formativo meta professionale
    var obbligoFormativoMeta = ((giorniEsoneroMassimiTriennio - giorniEsonero) / giorniEsoneroMassimiTriennio) * cfpMetaTotRichiesti

    var rispettoObbligoTotCfp = 0
    if (obbligoFormativo <= cfpTotOttenuti) {
      rispettoObbligoTotCfp = 1
    }

    var rispettoObbligoTotCfpMeta = 0
    if (obbligoFormativoMeta <= cfpMetaOttenuti && cfpMetaMancanti >= 0) {
      rispettoObbligoTotCfpMeta = 1
    }
    if (rispettoObbligoTotCfp == 1 && rispettoObbligoTotCfpMeta == 1) {
      return REGOLARITA_OTTENUTA_LABEL_SI
    } else {
      return REGOLARITA_OTTENUTA_LABEL_NO
    }
  }

  /* questa funzione verifica che sul totale dei cfp l'obbligo sul triennio è rispettato */
  var regolaritaFormativaTotTriennio = function (record) {
    var cfpTotRichiesti = Number(record[CFP_TOT_RICHIESTI])
    var cfpTotOttenuti = Number(record[CFP_TOT_OTTENUTI])
    var numeroGiorniPeriodo = Number(record[NUMERO_GIORNI_PERIODO])
    var numeroAnniPeriodo = Number(record[NUMERO_ANNI_PERIODO])
    var giorniEsonero = Number(record[GIORNI_ESONERO_PERIODO_LABEL])
    var giorniEsoneroMassimiTriennio = numeroGiorniPeriodo * numeroAnniPeriodo
    var obbligoFormativo = ((giorniEsoneroMassimiTriennio - giorniEsonero) / giorniEsoneroMassimiTriennio) * cfpTotRichiesti

    var rispettoObbligoTotCfp = 0
    if (obbligoFormativo <= cfpTotOttenuti) {
      rispettoObbligoTotCfp = 1
    }

    if (rispettoObbligoTotCfp == 1) {
      return 0
    } else {
      return (cfpTotOttenuti - obbligoFormativo).toFixed(3)
    }
  }

  /* questa funzione verifica che sul totale dei cfp meta professionali l'obbligo sul triennio è rispettato */
  var regolaritaFormativaTotMetaTriennio = function (record) {
    var cfpMetaOttenuti = Number(record[CFP_META_OTTENUTI])

    //Se l'utente è esonerato calcolo della quota parte sull'obbligo, altrimenti calcola come normale
    var obbligoMeta = Number(ST_FOR.obbligoFormativoMeta(record))

    var rispettoObbligoTotCfpMeta = 0
    if (obbligoMeta <= cfpMetaOttenuti) {
      rispettoObbligoTotCfpMeta = 1
    }

    if (rispettoObbligoTotCfpMeta == 1) {
      return 0
    } else {
      var obbligo = ST_FOR.obbligoFormativo(record)
      if (Number(obbligo) == 0) {
        return 0
      } else {
        return (cfpMetaOttenuti - obbligoMeta).toFixed(3)
      }
    }
  }

  //Attenzione: Non si riescono ad interpolare le chiavi:
  //Attributi derivati per ragioni di semplicità di mix frontend
  //TODO: Migliorare questa gestione
  export const derivedAttributes = {
    Link: linkAzioni,
    Esonerato: calcoloEsonero,
    'Regolarità Formativa': regolaritaFormativa,
    'Obbligo Formativo': obbligoFormativo,
    'Totali mancanti': regolaritaFormativaTotTriennio,
    'Meta mancanti': regolaritaFormativaTotMetaTriennio,
  }

  export const pivotForDatiGeneraliOrdineAndPeriodo = {
    rows: [
      NUMERO_ISCRIZIONE,
      DATA_ISCRIZIONE,
      'Stato Iscrizione',
      CF,
      'Cognome',
      'Nome',
      'Titolo',
      'Periodo',
      'Esonerato',
      GIORNI_ESONERO_PERIODO_LABEL,
      OBBLIGO_FORMATIVO,
      REGOLARITA_FORMATIVA,
      CFP_TOT_OTTENUTI,
      TOT_CFP_MANCANTI,
      CFP_TOT_CARA_OTTENUTI,
      CFP_META_OTTENUTI,
      TOT_CFP_META_MANCANTI,
      'Anno',
      TOT_CFP_ANNO,
      'Tipologia Attivita',
      'Cfp',
    ],
    cols: ['Ordine', 'Periodo', CFP_TOT_RICHIESTI, CFP_TOT_ESONERABILITA, CFP_META_RICHIESTI_LABEL, 'Anno'],
    derivedAttributes: derivedAttributes,
    aggregatorName: 'Sum',
    vals: ['Cfp'],
    rendererName: 'Table',
    totalCells: 'Totale',
    data: [],
  }

  const numeroIscrizioneNotNullFilter: Filter = {
    member: 'DimIot.numero_iscrizione_ordine',
    operator: 'set',
  }

  const statoIscrizioneFilter: Filter = {
    member: 'DimIot.stato_iscrizione',
    operator: 'equals',
    values: ['4', '7'],
  }

  //filtro su stati iscrizione, iscritto e/o sospeso
  export const queryForDatiGeneraliAndPeriodoPredefinedFilter: Filter[] = [numeroIscrizioneNotNullFilter, statoIscrizioneFilter]

  export const queryForDatiGeneraliOrdineAndPeriodo: Query = {
    dimensions: [
      'DimForPeriodo.periodo',
      'DimForPeriodo.numero_giorni_periodo',
      'DimForPeriodo.numero_anni_periodo',
      'DimOrdine.denominazioneGruppo',
      'DimForTipoAttivita.tipo_attivita',
      'DimSoggetto.nome',
      'DimSoggetto.cognome',
      'DimSoggetto.cf',
      'DimTitoloIscritto.titoloEsteso',
      'DimIot.numero_iscrizione_ordine',
      'DimIot.stato_iscrizione',
      'DimIot.desc_stato_iscrizione',
      'DimIot.data_iscrizione_ordine',
      'DimIot.data_prima_iscrizione',
      'FactsForEsoneriAgg.anno_inizio_esonero',
      'FactsForEsoneriAgg.anno_fine_esonero',
      'FactsForEsoneriAgg.durata',
      'FactForCreditiAggAnno.giorni_esonerato',
      'DimForEsoneriAnno.giorni_esonero_anno',
      'DimForEsoneriPeriodo.giorni_esonero_periodo',
      'FactForCreditiAggAnno.cod_attivita',
      'FactForCreditiAggAnno.cfp',
      'FactForCreditiAggAnno.cfp_anno',
      'FactForCreditiAggAnno.cfp_periodo', //TODO: cambiare
      'FactForCreditiAggAnno.cfp_cara_periodo', //TODO: cambiare
      'FactForCreditiAggAnno.cfp_meta_periodo', //TODO: cambiare
      'FactForCreditiAggAnno.anno',
      'DimForPeriodoObblighi.min_cfp_caratt',
      'DimForPeriodoObblighi.min_cfp_meta',
      'DimForPeriodoObblighi.tot_cfp_richiesti',
    ],
    measures: [
      //"FactForCreditiAggAnno.sum_cfp_over_year_for_iscritto"
    ],
  }

  const queryForDatiGeneraliOrdineAndPeriodooDTO = `
    *.{
        "Periodo": \`DimForPeriodo.periodo\`,
        "${NUMERO_GIORNI_PERIODO}": \`DimForPeriodo.numero_giorni_periodo\`,
        "${NUMERO_ANNI_PERIODO}": \`DimForPeriodo.numero_anni_periodo\`,
        "Ordine": \`DimOrdine.denominazioneGruppo\`,
        "Tipologia Attivita": \`DimForTipoAttivita.tipo_attivita\`,
        "Stato Iscrizione": \`DimIot.desc_stato_iscrizione\`,
        "Nome": \`DimSoggetto.nome\`,
        "Cognome": \`DimSoggetto.cognome\`,
        "${CF}": \`DimSoggetto.cf\`,
        "Titolo": \`DimTitoloIscritto.titoloEsteso\`,
        "${NUMERO_ISCRIZIONE}": \`DimIot.numero_iscrizione_ordine\`,
        "${DATA_ISCRIZIONE}": \`DimIot.data_prima_iscrizione\`,
        "Anno Inizio Esonero": \`FactsForEsoneriAgg.anno_inizio_esonero\`,
        "Anno Fine Esonero": \`FactsForEsoneriAgg.anno_fine_esonero\`,
        "${GIORNI_ESONERO_PERIODO_LABEL}": \`FactForCreditiAggAnno.giorni_esonerato\`,
        "Tipo Attivita Iscritto":  \`FactForCreditiAggAnno.cod_attivita\`,
        "Cfp": \`FactForCreditiAggAnno.cfp\`,
        "Anno": \`FactForCreditiAggAnno.anno\`,
        "${TOT_CFP_ANNO}": \`FactForCreditiAggAnno.cfp_anno\`,
        "${CFP_TOT_OTTENUTI}": \`FactForCreditiAggAnno.cfp_periodo\`,
        "${CFP_TOT_CARA_OTTENUTI}": \`FactForCreditiAggAnno.cfp_cara_periodo\`,
        "${CFP_META_OTTENUTI}": \`FactForCreditiAggAnno.cfp_meta_periodo\`,
        "${CFP_META_RICHIESTI_LABEL}": \`DimForPeriodoObblighi.min_cfp_meta\`,
        "${CFP_TOT_RICHIESTI}": \`DimForPeriodoObblighi.tot_cfp_richiesti\`,
        "${CFP_TOT_ESONERABILITA}": 3
    }
  `

  //Questa funzione richiede un filtro sull'ordine territoriale anche in caso di ordine competente e di conaf nazionale,
  //per la grossa mole di dati
  export async function statisticheGeneraliFormazione(filtro, currentAccount, context, callback) {
    let filters: any[] = []
    let searchable = false
    //se filtro è presente
    if (filtro.length > 0) {
      let searchByPeriodo = false
      let searchByOrdine = false
      for (let i = 0; i < filtro.length; i++) {
        let currentFiltro = filtro[i]
        //verifico se è presente un elenco di trienni
        if (currentFiltro?.selectKeyLabel && currentFiltro?.selectKeyLabel === 'Periodo') {
          let periodo = currentFiltro?.state?.selected
          filters.push({
            member: 'DimForPeriodo.periodo',
            operator: 'in',
            values: periodo,
          })
          searchByPeriodo = true
        }

        if (currentFiltro?.selectKeyLabel && currentFiltro?.selectKeyLabel === 'ordini') {
          let ordini = currentFiltro?.state?.selected
          filters.push({
            member: 'DimOrdine.codiceGruppo',
            operator: 'in',
            values: ordini,
          })
          searchByOrdine = true
        } else {
          if (ST_SEC.isOdaf(currentAccount) === true) {
            filters.push(currentFiltro[0])
            searchByOrdine = true
          }
        }
      }

      if (searchByPeriodo === true && searchByOrdine === true) {
        searchable = true
      }
    }

    if (filters.length > 0 && searchable === true) {
      filters = filters.filter((val) => val !== undefined)
      let predefinedfilters = ST_FOR.queryForDatiGeneraliAndPeriodoPredefinedFilter
      for (const idx in predefinedfilters) {
        filters.push(predefinedfilters[idx])
      }
      queryForDatiGeneraliOrdineAndPeriodo.filters = filters
      let squery = ST_SEC.query(queryForDatiGeneraliOrdineAndPeriodo, currentAccount)
      cquery(squery, queryForDatiGeneraliOrdineAndPeriodooDTO, callback.bind(context))
    } else {
      callback.bind(context)
    }
  }

  export const elencoPeriodiQuery: Query = {
    dimensions: ['DimForPeriodo.periodo'],
    timeDimensions: [],
    order: {
      'DimForPeriodo.periodo': 'asc',
    },
    filters: [
      {
        member: 'DimForPeriodo.anno',
        operator: 'lte',
        values: ['' + new Date().getFullYear()],
      },
    ],
  }

  const elencoPeriodiFormativiDTO = `
    *.{
      "Periodo": \`DimForPeriodo.periodo\`
    }
  `

  //Ricerca l'elenco dei trienni formativi
  export async function elencoPeriodiFormativi(currentAccount, context, callback) {
    cquery(elencoPeriodiQuery, elencoPeriodiFormativiDTO, callback.bind(context))
  }
}

export namespace ST_INI_PEC {
  /*
   * Metodo per scaricare il report inipec
   */
  export async function generaReportInipec(parametriRicerca, currentAccount, context, callback) {
    let reportByDate: ReportByDate = {}
    reportByDate.date = parametriRicerca
    getMsgaReportResourceApi()
      .msgaRepInipecPost(reportByDate, getDefaultAxiosConfig())
      .then((response) => {
        callback(response)
      })
  }

  /*
   * Metodo per scaricare il report reginde
   */
  export async function generaReportRegInde(parametriRicerca, currentAccount, context, callback) {
    let reportByDate: ReportByDate = {}
    reportByDate.date = parametriRicerca
    getMsgaReportResourceApi()
      .msgaRepRegindePost(reportByDate, getDefaultAxiosConfig())
      .then((response) => {
        callback(response)
      })
  }

  /*
   * Metodo per recuperare la lista dei report in aggiornamento inipec/reginde
   */
  export async function getReportListByCategoria(categoria: string) {
    try {
      var param = {
        param: categoria,
      }
      const response = getMsgaReportResourceApi().msgaRepGetListReportInipecRegIndePost(param, getDefaultAxiosConfig())
      return response
    } catch (err: any) {
      console.error('Errore durante la chiamata msgagetListReportInipecRegIndePost - ' + err)
      throw new Error('Errore durante la chiamata msgagetListReportInipecRegIndePost - ' + err)
    }
  }

  /*
   * Metodo per recuperare il file del report mediante il filename
   */
  export function getReportByFilename(filename: string) {
    try {
      var param = {
        param: filename,
      }
      const response = getMsgaReportResourceApi().msgaRepGetReportFileByFilenamePost(param, getDefaultAxiosConfig())
      return response
    } catch (err: any) {
      console.error('Errore durante la chiamata msgaRepGetReportFileByFilenamePost - ' + err)
      throw new Error('Errore durante la chiamata msgaRepGetReportFileByFilenamePost - ' + err)
    }
  }
}
