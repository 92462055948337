import {
    getAssicurazioneBOResourceApi,
    getAssicurazioneResourceApi,
    getDefaultAxiosConfig
} from 'src/app/utils/ManagerRestGateway'
import {AppUtils as U} from 'src/app/utils/FormUtils'
import {MessaggiErroreUtente} from 'src/errorBoundary/ErrorConstants'
import {ErrorStore} from 'src/errorBoundary/ErrorStore'

/**
 * POST Elenco assicurazioni dell'utente loggato
*/
export async function getListaAssicurazioni(cf: string, tipologia: string, piva: string){
  try {
    var obj = {
      "tipoAnagrafe": tipologia,
      "codiceFiscale": cf,
      "partitaIva": piva
    }
    let response: any = await getAssicurazioneResourceApi().mspoRicercaPolizzePost(obj,getDefaultAxiosConfig());

    return ErrorStore.getInstance().checkResponse(response);

  } catch (err) {
    let errorMessage : any = ErrorStore.getInstance().returnMessageError(err, 500)
    throw await new Error(errorMessage.title + errorMessage.message);
  }

}


/**
 * POST Elenco assicurazioni dell'utente loggato
*/
export async function getSituazioneAssicurativa(cf: string, tipologia: string, piva: string){
  try {
    var obj = {
      "tipoAnagrafe": tipologia,
      "codiceFiscale": cf,
      "partitaIva": piva
    }

    let response: any = await getAssicurazioneResourceApi().mspoSituazioneAssicurativaPost(obj, getDefaultAxiosConfig())
    return ErrorStore.getInstance().checkResponse(response);

  } catch (err:any) {

    let errorMessage : any = ErrorStore.getInstance().returnMessageError(err, 500)
    throw await new Error(errorMessage.title /*+ "Errore durante il recupero dei dati della situazione assicurativa"*/ + errorMessage.message);

  }

}


/**
 * POST Recupero polizza by id
 * @returns Polizza
 */
export async function getPolizzaById(id: any){
  try {
    let param = {
      "param": id,
    }
    let response: any = await getAssicurazioneResourceApi().mspoGlobalGetByIdPost(param,getDefaultAxiosConfig());
    return ErrorStore.getInstance().checkResponse(response);

  } catch (err) {

    let errorMessage: any = await ErrorStore.getInstance().returnMessageError(err, 500)
    console.error( errorMessage.title + MessaggiErroreUtente.errore_chiamata_recupero_polizza + errorMessage.message);
    throw await new Error(errorMessage.title /*+ "Errore durante la chiamata per il recupero della polizza  "*/ + errorMessage.message);

  }
}

/**
 * POST Recupero polizza originaria aumento by id
 * @returns Polizza
 */
export async function getPolizzaOriginariaAumentoById(id: any){

  try {
      return getPolizzaById(id);
  } catch (err) {
      let errorMessage: any = await ErrorStore.getInstance().returnMessageError(err, 500)
      throw await new Error(errorMessage.title + errorMessage.message);
  }
}

/**
 * GET Recupero l'elenco delle campagne attive correnti
 * @returns Polizza
 */
export async function getCampagneAssicurative(){
  try {
    let response: any = await getAssicurazioneResourceApi().mspoRecuperaCampagneAssicurativeGet(getDefaultAxiosConfig());
    return ErrorStore.getInstance().checkResponse(response);

  } catch (err) {
    let errorMessage: any = await ErrorStore.getInstance().returnMessageError(err, 500)
    throw await new Error(errorMessage.title + errorMessage.message);
  }
}

/**
 * POST Recupero polizza by id
 * @returns Polizza
 */
export async function getPolizzaStoricaById(id: any){
  try {
    let param = {
      "param": id
    }
    let response: any = await getAssicurazioneResourceApi().mspoGetPolizzaStoricaByIdPost(param,getDefaultAxiosConfig());
    return response.data.returnedObject;

  } catch (err) {
    let errorMessage: any = await ErrorStore.getInstance().returnMessageError(err, 500)
    throw await new Error(errorMessage.title + errorMessage.message);
  }
}

/**
 * POST Crea una nuova polizza su frontend
 *
 */
export async function creaNuovaPolizza(richiestaPolizza: any){
  try {
      let response: any = await getAssicurazioneResourceApi().mspoCreazioneAssicurazionePost(richiestaPolizza,getDefaultAxiosConfig());
      return ErrorStore.getInstance().checkResponse(response);

  } catch (err:any) {
      let errorMessage: any = await ErrorStore.getInstance().returnMessageError(err, undefined)
      throw await new Error(errorMessage.message);
  }
}


/**
 * PUT Aggiorna una polizza collettiva frontend
 * @returns
 */
export async function aggiornaPolizza(cf: any, polizza: any){

  let endOfTheDayTime = "T23:59:59";
  let startOfTheDayTime = "T00:00:00";
    try {

      let operaStudioAssociato = U.nd(polizza.operaStudioAssociato);
      let idStudioAssociato = polizza.idStudioAssociato && typeof(polizza.idStudioAssociato) === 'object' ? polizza.idStudioAssociato.value : polizza.idStudioAssociato ? polizza.idStudioAssociato : null ;
      let altrePolizze = U.nd(polizza.altrePolizze);
      let associatoConAltri = polizza.associatoConAltri;
      let associatoConConaf = polizza.associatoConConaf;
      let datiPatrimoniali = U.nd(polizza.danniPatrimoniali);
      let retroattivita = U.nd(polizza.retroattivitaIllimitata)
      let coperturaPostuma = U.nd(polizza.coperturaPostuma);
      let sinistri = U.nd(polizza.sinistri);
      let liberatoria = U.nd(polizza.sinistriRicevutoLiberatoriaCompagnia);
      let circostanza = U.nd(polizza.haLettoCircostanza);
      let maternita = U.nd(polizza.maternita);
      let paternita = U.nd(polizza.paternita);
      let privacy = U.nd(polizza.privacy);
      let haLettoVademecum = U.nd(polizza.haLettoVademecum);
      let haLettoNormativa = U.nd(polizza.haLettoNormativa);
      //se polizza categoria 2 uguale a si restitituisco 2 altrimenti 1
      //serve a controllare che nel caso della dropdown stia passando il valore corretto
      //perchè il campo è stato calcolato male
      let categoriaPolizza = 1;
      if(polizza.idCategoriaDue !== undefined){
        categoriaPolizza = U.nd(polizza.idCategoriaDue) === 1 ? 2 : 1;
      }
      else{
        if(polizza.categoria === undefined){
          categoriaPolizza = 1;
        }else{
          categoriaPolizza = polizza.categoria === 2 ? 2 : 1;
        }
      }

      //CONTROLLI FORMALI SE BOOLEANI
      U.tbb(operaStudioAssociato);
      U.tbb(altrePolizze);
      U.tbb(associatoConAltri);
      U.tbb(associatoConConaf);
      U.tbb(datiPatrimoniali);
      U.tbb(retroattivita);
      U.tbb(coperturaPostuma);
      U.tbb(sinistri);
      U.tbb(liberatoria);
      U.tbb(circostanza);
      U.tbb(maternita);
      U.tbb(paternita);
      U.tbb(privacy);
      U.tnb(categoriaPolizza);
      U.tbb(associatoConAltri);
      U.tbb(associatoConConaf);
      U.tbb(haLettoVademecum);
      U.tbb(haLettoNormativa);

      const compilaPolizza  = {
        "id": polizza.id,
        "idIscrizione": polizza.idIscrizione,
        "tipoUtente": polizza.tipoUtente,
        "operaStudioAssociato": operaStudioAssociato,
        "idStudioAssociato": idStudioAssociato,
        "altrePolizze": altrePolizze,
        "stuAssRagSoc": (polizza.stuAssRagSoc) ? (polizza.stuAssRagSoc) : "",
        "stuAssProv": (polizza.stuAssProv) ? (polizza.stuAssProv.value) : "",
        "stuAssCitta": (polizza.stuAssCitta) ? (polizza.stuAssCitta.label) : "",
        "stuAssInd": (polizza.stuAssInd) ? (polizza.stuAssInd) : "",
        "stuAssCap": (polizza.stuAssCap) ? (polizza.stuAssCap) : "",
        "associatoConAltri": associatoConAltri,
        "danniPatrimoniali": datiPatrimoniali,
        "retroattivitaIllimitata": retroattivita,
        "coperturaPostuma": coperturaPostuma,
        "vrcOk": (polizza.vrcOk) ? (polizza.vrcOk) : 0,
        "vopOk": (polizza.vopOk) ? (polizza.vopOk) : 0,
        "articoloOtto": (polizza.articoloOtto) ? (polizza.articoloOtto) : 0,
        "articoloOttoDescrizione": (polizza.articoloOttoDescrizione) ? (polizza.articoloOttoDescrizione) : "",
        "nomeCompagniaAltri": (polizza.nomeCompagniaAltri) ? (polizza.nomeCompagniaAltri) : "",
        "valoreRischioAltri": (polizza.valoreRischioAltri) ? ((polizza.valoreRischioAltri).toString().replace(',','.')) : "",
        "massimaleAltri": (polizza.massimaleAltri) ? Number(parseFloat((polizza.massimaleAltri).toString().replace(',','.')).toFixed(2)) : 0,
        "premioAltri": (polizza.premioAltri) ? Number(parseFloat((polizza.premioAltri).toString().replace(',','.')).toFixed(2)) : 0,
        "periodoValDaAltri": (polizza.periodoValDaAltri) ? (polizza.periodoValDaAltri.split('T')[0] + startOfTheDayTime) : undefined,
        "periodoValAAltri": (polizza.periodoValAAltri) ? (polizza.periodoValAAltri.split('T')[0] + endOfTheDayTime) : undefined,
        "numeroPolizaAltri": (polizza.numeroPolizaAltri) ? (polizza.numeroPolizaAltri) : "",
        "associatoConConaf": associatoConConaf,
        "vrcConaf": (polizza.vrcConaf) ? (polizza.vrcConaf) : 0,
        "vopConaf": (polizza.vopConaf) ? (polizza.vopConaf) : 0,
        "categoria": categoriaPolizza,
        "categoriaCalculated": polizza.categoriaCalculated,
        "fasciaPremioConaf": (polizza.fasciaPremioConaf) ? (polizza.fasciaPremioConaf) : "",
        "valoreRischioConaf": (polizza.valoreRischioConaf) ? (polizza.valoreRischioConaf) : "",
        "massimaleConaf": (polizza.massimaleConaf) ? (polizza.massimaleConaf) : 0,
        "premioConaf": (polizza.premioConaf) ? (polizza.premioConaf) : 0,
        "periodoValDaConaf": (polizza.periodoValDaConaf) ? (polizza.periodoValDaConaf.split('T')[0] + startOfTheDayTime) : undefined,
        "periodoValAConaf": (polizza.periodoValAConaf) ? (polizza.periodoValAConaf.split('T')[0] + endOfTheDayTime) : undefined,
        "stato": (polizza.stato) ? (polizza.stato) : 0,
        "noteRifiuto": (polizza.noteRifiuto) ? (polizza.noteRifiuto) : "",
        "sinistri": sinistri,
        "sinistriRicevutoLiberatoriaCompagnia": liberatoria,
        "haLettoCircostanza": circostanza,
        "progressivoConaf": (polizza.progressivoConaf) ? (polizza.progressivoConaf) : 0,
        "noteConferma": (polizza.noteConferma) ? (polizza.noteConferma) : "",
        "dataCreazioneRichiesta": polizza.dataCreazioneRichiesta,
        "dataUM": (polizza.dataUM) ? (polizza.dataUM) : "2022-03-10T12:15:50",
        "dataRichiesta": polizza.dataRichiesta,
        "dataConferma": (polizza.dataConferma) ? (polizza.dataConferma) : undefined,
        "noteRichiedente": (polizza.noteRichiedente) ? (polizza.noteRichiedente) : "",
        "haLettoVademecum": (haLettoVademecum) ? (haLettoVademecum) : 0,
        "haLettoNormativa": (haLettoNormativa) ? (haLettoNormativa) : 0,
        "tipoRichiesta": (polizza.tipoRichiesta) ? (polizza.tipoRichiesta) : "",
        "threadStorico": (polizza.threadStorico) ? (polizza.threadStorico) : "",
        "dataInStorico": (polizza.dataInStorico) ? (polizza.dataInStorico) : undefined,
        "descrizioneStato": "COMPILAZIONE",
        "codiceFiscale": polizza.codiceFiscale,
        "partitaIva": (polizza.partitaIva) ? (polizza.partitaIva) : "",
        "maternita": maternita,
        "paternita": paternita,
        "fileMaternitaPaternita": (polizza.fileMaternitaPaternita),
        "copiaPagamentoPolizza": (polizza.copiaPagamentoPolizza) ? polizza.copiaPagamentoPolizza : "",
        "privacy": privacy,
        "assicurazioneAllegati": polizza.assicurazioneAllegati
      }

      let response: any = await getAssicurazioneResourceApi().mspoAggiornaCompilazionePut(compilaPolizza,getDefaultAxiosConfig());
      return ErrorStore.getInstance().checkResponse(response);

    } catch (err) {
      let errorMessage: any = await ErrorStore.getInstance().returnMessageError(err, 500)
      throw await new Error(errorMessage.message);
    }
}

/**
 * POST Richiedi riapertura polizza
 * @returns
 */
export async function richiestaRiaperturaPolizza(polizza: any){

  try {
    let response: any = await getAssicurazioneResourceApi().mspoInviaPolizzaPost(polizza,getDefaultAxiosConfig());
    return ErrorStore.getInstance().checkResponse(response);

  } catch (err) {
    let errorMessage: any = await ErrorStore.getInstance().returnMessageError(err, 500)
    throw await new Error(errorMessage.title + errorMessage.message);
  }

}
/**
 * POST Invia una polizza dopo la compilazione
 * @returns
 */
export async function inviaPolizza(polizza: any){

  try {
      let response: any = await getAssicurazioneResourceApi().mspoInviaPolizzaPost(polizza,getDefaultAxiosConfig());
      return ErrorStore.getInstance().checkResponse(response);

  } catch (err) {
    let errorMessage: any = await ErrorStore.getInstance().returnMessageError(err, 500)
      throw await new Error(errorMessage.title + errorMessage.message);
  }

}

/**
 * POST rifiuta polizza per backoffice BO
 * @returns
 */
export async function rifiutaPolizza(polizza: any){
  try {
    let response: any = await getAssicurazioneBOResourceApi().mspoBoRifiutaPolizzaPost(polizza,getDefaultAxiosConfig());
    return ErrorStore.getInstance().checkResponse(response);

  } catch (err) {
    let errorMessage: any = await ErrorStore.getInstance().returnMessageError(err, 500)
    throw await new Error(errorMessage.title + errorMessage.message);
  }

}

/**
 * POST conferma polizza su BO
 * @returns
 */
export async function confermaPolizza(polizza: any/*, timeFrom: any, timeTo: any*/){
  polizza.dateFrom = polizza.dateFrom.split('T')[0] + "T00:00:00.000";
  polizza.dateTo = polizza.dateTo.split('T')[0] + "T23:59:59.000";

  try {

    let response: any = await getAssicurazioneBOResourceApi().mspoBoConfermaPolizzaPost(polizza,getDefaultAxiosConfig());
    return ErrorStore.getInstance().checkResponse(response);
  } catch (err:any) {
    let errorMessage: any = await ErrorStore.getInstance().returnMessageError(err, 500)
    throw await new Error(errorMessage.message);
  }

}

/**
 * POST riapri polizza su BO
 * @returns
*/
export async function riapriPolizza(polizza: any){
  try {
    let response: any = await getAssicurazioneBOResourceApi().mspoBoRiapriPolizzaPost(polizza,getDefaultAxiosConfig());
    return ErrorStore.getInstance().checkResponse(response);

  } catch (err) {
    let errorMessage: any = await ErrorStore.getInstance().returnMessageError(err, 500)
    throw await new Error(errorMessage.message);
  }
}


/**
 * POST richiedi integrazione polizza su BO
 * @returns
 */
export async function richiestaIntegrazione(polizza: any){
  try {

    let response: any = await getAssicurazioneBOResourceApi().mspoBoRichiestaIntegrazionePost(polizza,getDefaultAxiosConfig());
    return ErrorStore.getInstance().checkResponse(response);

  } catch (err) {
    let errorMessage: any = await ErrorStore.getInstance().returnMessageError(err, 500)
    throw await new Error(errorMessage.title + errorMessage.message);
  }

}

/**
 * POST richiesta riapertura polizza su FO
 * @returns
 */

export async function richiediRiaperturaPolizza(idPolizza: any){
  const richiestaRiapertura = {
    "idPolizza": idPolizza,
    "note": "",
  }
  try {
    let response: any = await getAssicurazioneResourceApi().mspoRichiestaRiaperturaPost(richiestaRiapertura,getDefaultAxiosConfig());
    return ErrorStore.getInstance().checkResponse(response);

  } catch (err) {
    let errorMessage: any = await ErrorStore.getInstance().returnMessageError(err, 500)
    throw await new Error(errorMessage.title + errorMessage.message);
  }
}


/**
 * POST richiedi cancella polizza su BO
 * @returns
 */
export async function cancellaPolizzaBO(polizza: any){
  try {
    let response: any = await getAssicurazioneBOResourceApi().mspoBoCancellaPolizzaPost(polizza,getDefaultAxiosConfig());

    return ErrorStore.getInstance().checkResponse(response);

  } catch (err) {

    let errorMessage: any = await ErrorStore.getInstance().returnMessageError(err, 500)
    throw await new Error(errorMessage.title + errorMessage.message);
  }

}

/**
 * POST richiedi cancella polizza su BO
 * @returns
 */
export async function cancellaPolizzaFO(polizza: any){
  try {
    let response: any = await getAssicurazioneResourceApi().mspoCancellaPolizzaPost(polizza,getDefaultAxiosConfig());
    return ErrorStore.getInstance().checkResponse(response);

  } catch (err) {
    let errorMessage: any = await ErrorStore.getInstance().returnMessageError(err, 500)
    throw await new Error(errorMessage.title + errorMessage.message);
  }

}

/**
 * POST richiedi integrazione polizza su BO
 * @returns
 */
export async function modificaValiditaPolizza(polizza: any, timeFrom: any, timeTo: any){

  polizza.dateFrom = polizza.dateFrom.split('T')[0] + "T00:00:00.000";
  polizza.dateTo = polizza.dateTo.split('T')[0] + "T00:00:00.000";

  if(timeFrom !== "" && timeFrom !== undefined)
    polizza.dateFrom = polizza.dateFrom.split('T')[0] + "T" + timeFrom + ":00.000"
  if(timeTo !== "" && timeTo !== undefined)
    polizza.dateTo = polizza.dateTo.split('T')[0] + "T" + timeTo + ":00.000"

  try {
     let response: any = await getAssicurazioneBOResourceApi().mspoBoModificaValiditaPolizzaPost(polizza,getDefaultAxiosConfig());
    return ErrorStore.getInstance().checkResponse(response);

  } catch (err:any) {
    let errorMessage: any = await ErrorStore.getInstance().returnMessageError(err, 500)
    throw await new Error(errorMessage.message);
  }
}

/**
 * POST richiedi certificato
 * @returns
 */
export async function recuperaCertificato(polizza: any){

  let param = {
    "param": polizza
  }
  try {
    let response: any = await getAssicurazioneResourceApi().mspoGetCertificatoByPolizzaPost(param,getDefaultAxiosConfig());
    return response.data;
  } catch (err) {
    let errorMessage: any = await ErrorStore.getInstance().returnMessageError(err, 500)
    throw await new Error(errorMessage.title + errorMessage.message);
  }

}


/**
 * POST richiedi copia pagamento
 * @param String id polizza
 * @returns
 */
export async function recuperaCopiaPagamento(polizza: any){

  let param = {
    "param": polizza
  }
  try {
    let response: any = await getAssicurazioneResourceApi().mspoGetCopiaPagamentoByPolizzaPost(param,getDefaultAxiosConfig());

    return response.data;

  } catch (err) {

    let errorMessage: any = await ErrorStore.getInstance().returnMessageError(err, 500)
    throw await new Error(errorMessage.title + errorMessage.message);
  }

}

/**
 * POST recupera allegato
 * @returns
 */
export async function recuperaAllegato(idPolizza: any, idAttachment: any){

  let param = {
    "idPolizza": idPolizza,
    "idAttachment": idAttachment
  }
  try {
    let response: any = await getAssicurazioneResourceApi().mspoGetAllegatoPost(param,getDefaultAxiosConfig());
    return response.data;

  } catch (err) {
    let errorMessage: any = await ErrorStore.getInstance().returnMessageError(err, 500)
      throw await new Error(errorMessage.title + errorMessage.message);
  }

}

/**
 * POST recupera allegato
 * @returns
 */
export async function recuperaPagamentoPolizzaByIdPagamento(idPagamento: any){

  let param = {
    "integerParam": idPagamento
  }
  try {
    let response: any = await getAssicurazioneResourceApi().mspoGetPagamentoByIdPost(param,getDefaultAxiosConfig());
    return response.data;

  } catch (err) {
    let errorMessage: any = await ErrorStore.getInstance().returnMessageError(err, 500)
      throw await new Error(errorMessage.title + errorMessage.message);
  }

}

/**
 * POST recupero dello storico di una polizza
 * @returns
 */
export async function getStorico(polizza: any){

  let param = {
    "param": polizza
  }
  try {
    let response: any = await getAssicurazioneResourceApi().mspoRicercaStoricoPolizzaPost(param,getDefaultAxiosConfig());
    return ErrorStore.getInstance().checkResponse(response);
  } catch (err) {
    let errorMessage: any = await ErrorStore.getInstance().returnMessageError(err, 500)
    throw await new Error(errorMessage.title + errorMessage.message);
  }
}


/**
 * POST upload allegato Polizza
 * @returns
 */
export async function uploadAllegatoPolizza(idPolizza: any, allegato: any){
  if(allegato?.descrizioneBreve === undefined || allegato?.descrizioneBreve === null || allegato?.descrizioneBreve === "" ) {
    allegato.descrizioneBreve = "";
  }

  let param2 = {
    "idPolizza": idPolizza,
    "allegatoDTO": {
      "filename": allegato.filename,
      "contentBase64": allegato.contentBase64,
      "tipologiaAllegato": allegato.tipologiaAllegato,
      "contentType": allegato.contentType,
      "descrizioneBreve": allegato.descrizioneBreve
    },
    "tipologiaAllegato": allegato.tipologiaAllegato,
    "poPagamenti": allegato.poPagamenti
  }

  try {
    let response: any = await getAssicurazioneResourceApi().mspoCaricaAllegatoPolizzaPost(param2, getDefaultAxiosConfig());

    return ErrorStore.getInstance().checkResponse(response);
  } catch (err) {
    let errorMessage: any = await ErrorStore.getInstance().returnMessageError(err, 500)
    throw await new Error(errorMessage.title + errorMessage.message);
  }
}


/**
 * POST convert policy from individual to collective
 * @returns
 */
export async function convertPolizzaFromIndividualeToCollettiva(idPolizza: any){

  let param = {
    "param": idPolizza
  }

  try {
    let response: any = await getAssicurazioneResourceApi().mspoCambiaPolizzaIndividualeACollettivaPost(param, getDefaultAxiosConfig());
    return ErrorStore.getInstance().checkResponse(response);

  } catch (err) {
    let errorMessage: any = await ErrorStore.getInstance().returnMessageError(err, 500)
    throw await new Error(errorMessage.title + errorMessage.message);
  }
}
