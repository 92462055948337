import React, {useEffect, useState} from 'react'

import GenericInput from 'src/frontend/app/components/Form/Input'
import GenericSelect from 'src/frontend/app/components/Form/Select'
import {Spinner} from 'design-react-kit'

const AsyncSelectFilter: React.FunctionComponent<GenericAsyncTableDataFilterProps> = (props) => {
  const [options, setOptions] = useState<any>(null)
  useEffect(() => {
    ;(async () => {
      if (props.filter.loadOptions) {
        const options = await props.filter.loadOptions()
        setOptions(options)
      }
    })()
  }, [])

  return (
    <>
      {options == null && <Spinner active={true} small label={''} />}
      {options != null && options.length === 0 && <></>}
      {options != null && options.length > 0 && (
        <GenericSelect
          defaultOptions={[{ value: undefined, label: 'Tutti' }, ...options]}
          className={'async-select-container'}
          label={props.filter.label}
          value={props.value || ''}
          onChange={(c, v) => props.onChange(v.value === undefined ? undefined : v)}
          id={`filter.${props.id}`}
        />
      )}
    </>
  )
}

const InputFilter: React.FunctionComponent<GenericAsyncTableDataFilterProps> = (props) => {
  return (
    <GenericInput
      className={'input-container'}
      type={props?.filter.inputType || 'text'}
      label={props.filter.label}
      value={props.value}
      onKeyDown={(e) => props.onKeyDown(e)}
      onChange={(c, v) => props.onChange(v)}
      id={`filter.${props.id}`}
    />
  )
}

const SelectFilter: React.FunctionComponent<GenericAsyncTableDataFilterProps> = (props) => {
  return (
    <GenericSelect
      className={'select-container'}
      defaultOptions={[{ value: undefined, label: 'Tutti' }, ...(props?.filter?.options || [])]}
      label={props.filter.label}
      value={props.value || ''}
      onChange={(c, v) => props.onChange(v.value === undefined ? undefined : v)}
      id={`filter.${props.id}`}
    />
  )
}

export const GenericAsyncTableDataFilter: React.FunctionComponent<GenericAsyncTableDataFilterProps> = (props) => {
  switch (props.filter.type) {
    case 'input':
      return <InputFilter {...props} />
    case 'select':
      return <SelectFilter {...props} />
    case 'asyncSelect':
      return <AsyncSelectFilter {...props} />
  }
  return null
}

export declare interface GenericAsyncTableDataFilterProps {
  filter: Filter
  value: string
  onChange: any
  onKeyDown?: any
  id: string
}

export declare interface Filter {
  label: string
  type: string
  inputType?: string
  options?: any
  field: string
  loadOptions?: any
  operator?: string
}
