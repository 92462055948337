import React from 'react'
import styled from 'styled-components'
import {GenericSpinner} from 'src/backoffice/app/components'


const StyledDiv = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background: rgba(255,255,255,0.6);
`;

const SavingFormLoader: React.FunctionComponent = (props) => {
      return (
        <StyledDiv><GenericSpinner /></StyledDiv>
      )
}

export default SavingFormLoader;
