import {
    CancellaSingolaVoceDettaglioDTO,
    RicercaElencoTabellaUnoDTO,
    RicercaTabellaUnoByIdDTO,
    StringParam,
    TabellaUnoCreateDTO
} from '@parsec/mspo'
import {getDefaultAxiosConfig, getTabellaUnoResourceApi} from 'src/app/utils/ManagerRestGateway'
import {ErrorStore} from 'src/errorBoundary/ErrorStore'

/**
 * GET delle voci prestazioni per la tabella uno
 * @returns
 */
export async function creaTabellaUno(tabellaUnoCreateDTO: TabellaUnoCreateDTO) {
  try {
    let response = await getTabellaUnoResourceApi().mspoTabellaUnoCreaNuovaTabellaPost(tabellaUnoCreateDTO,getDefaultAxiosConfig());
    return ErrorStore.getInstance().checkResponse(response);
  } catch (err) {
    let errorMessage: any = await ErrorStore.getInstance().returnMessageError(err, 500)
    throw await new Error(errorMessage.title + errorMessage.message);
  }
}

/**
 * POST aggiorna e invia la tabella uno compilata
 * @returns
 */
export async function inviaTabellaUno(idTabella: any) {
  try {
    let response = await getTabellaUnoResourceApi().mspoTabellaUnoInviaTabellaDefinitivaPost(idTabella,getDefaultAxiosConfig());
    return ErrorStore.getInstance().checkResponse(response);
  } catch (err: any) {
    let errorMessage: any = await ErrorStore.getInstance().returnMessageError(err, 500)
    throw await new Error(errorMessage.message);
  }
}

/**
 * POST aggiorna voci tabella uno
 * @returns
 */
export async function aggiornaVociTabellaUno(aggiornaVociDTO: any) {
  try {
    let response = await getTabellaUnoResourceApi().mspoTabellaUnoSalvaVociTabellaUnoPost(aggiornaVociDTO,getDefaultAxiosConfig());
    return ErrorStore.getInstance().checkResponse(response);
  } catch (err) {
    let errorMessage: any = await ErrorStore.getInstance().returnMessageError(err, 500)
    throw await new Error(errorMessage.title + errorMessage.message);
  }
}

/**
 * POST tabella uno by id
 * @returns
 */
export async function getTabellaUnoById(dto: RicercaTabellaUnoByIdDTO) {
  try {
    let response = await getTabellaUnoResourceApi().mspoTabellaUnoGetCompilazioneTabellaUnoByIdPost(dto,getDefaultAxiosConfig());
    return ErrorStore.getInstance().checkResponse(response);
    //return response.data.returnedObject;

  } catch (err) {
    let errorMessage: any = await ErrorStore.getInstance().returnMessageError(err, 500)
    throw await new Error(errorMessage.message);
  }
}

/**
 * POST elenco tabelle uno dato un codice fiscale
 * @returns
 */
export async function getElencoTabelleUnoByCodiceFiscale(richiestaTabellaUnoDTO: RicercaElencoTabellaUnoDTO) {
  try {
    let response = await getTabellaUnoResourceApi().mspoTabellaUnoElencoTabelleUnoByCodiceFiscalePost(richiestaTabellaUnoDTO,getDefaultAxiosConfig());
    // return ErrorStore.getInstance().checkResponse(response);
    return response.data.returnedObject;
  } catch (err) {
    let errorMessage: any = await ErrorStore.getInstance().returnMessageError(err, 500)
    throw await new Error(errorMessage.message);
  }
}

/**
 * POST aggiunta singola voce ad una tabella uno
 * @returns
 */
export async function aggiungiVoceTabellaUno(singolaVoce: any) {
  try {
    let response = await getTabellaUnoResourceApi().mspoTabellaUnoCompilazioneAggiungiSingolaVoceTabellaDettaglioPut(singolaVoce,getDefaultAxiosConfig());
    return ErrorStore.getInstance().checkResponse(response);
  } catch (err) {
    let errorMessage: any = await ErrorStore.getInstance().returnMessageError(err, 500)
    throw await new Error(errorMessage.message);
  }
}

/**
 * POST aggiunta singola voce ad una tabella uno
 * @returns
 */
export async function modificaVoceTabellaUno(singolaVoce: any) {
  try {
    let response = await getTabellaUnoResourceApi().mspoTabellaUnoCompilazioneAggiornaSingolaVoceTabellaDettaglioPut(singolaVoce,getDefaultAxiosConfig());

    return ErrorStore.getInstance().checkResponse(response);
  } catch (err) {
    let errorMessage: any = await ErrorStore.getInstance().returnMessageError(err, 500)
    throw await new Error(errorMessage.message);
  }
}

/**
 * POST rimozione singola voce da una tabella uno
 * @returns
 */
export async function cancellaVoceTabellaUno(singolaVoce: CancellaSingolaVoceDettaglioDTO) {
  try {
    let response = await getTabellaUnoResourceApi().mspoTabellaUnoCompilazioneCancellaSingolaVoceTabellaDettaglioPut(singolaVoce,getDefaultAxiosConfig());
    return ErrorStore.getInstance().checkResponse(response);
  } catch (err) {
    let errorMessage: any = await ErrorStore.getInstance().returnMessageError(err, 500)
    throw await new Error(errorMessage.message);
  }
}

/**
 * POST Questo metodo recupera la tabella uno confermata associata ad una polizza confermata
 * @returns tabellaUno
 */
export async function recuperoTabellaUnoDefinitivaAssociataAllaPolizza(id: any) {
  try {
    let param: StringParam = {
      "param": id
    }
    let response = await getTabellaUnoResourceApi().mspoTabellaUnoRecuperoTabellaUnoDefinitivaDellaPolizzaPost(param,getDefaultAxiosConfig());
    return response.data.returnedObject;
    //ErrorStore.getInstance().checkResponse(response);
  } catch (err) {
    let errorMessage: any = await ErrorStore.getInstance().returnMessageError(err, 500)
    throw await new Error(errorMessage.title + errorMessage.message);

    // throw new Error("Errore durante recuperoTabellaUnoDefinitivaAssociataAllaPolizza")
  }
}

/* Questo metodo recupera la tabella uno più recente associata ad una polizza per qualsiasi stato
*/
export async function recuperoTabellaUnoByPolizza(id: any) {
  try {
    let param: StringParam = {
      "param": id//'5049502738041'
    }
    let response = await getTabellaUnoResourceApi().mspoTabellaUnoRecuperoTabellaUnoByPolizzaPost(param,getDefaultAxiosConfig());
    return ErrorStore.getInstance().checkResponse(response);
  } catch (err) {
    let errorMessage: any = await ErrorStore.getInstance().returnMessageError(err, 500)
    throw await new Error(errorMessage.title + errorMessage.message);
  }
}

