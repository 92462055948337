import {GenericRequestDTO, StringParam} from '@parsec/mswf'
import {getDefaultAxiosConfig, getTodoEventResourceApi} from 'src/app/utils/ManagerRestGateway'

/**
 * Avvia il processo di iscrizione all'ordine.
 * @param spidData
 * @returns
 */
export async function getTodoEventList(param: any) {
    try {
        var body: GenericRequestDTO;
        if (param.siglaOrdine !== undefined && param.siglaOrdine !== "") {
            body =
            {
                params: {
                    "ID_SOGGETTO": {
                        value: param.idSoggetto+""
                    },
                    "CF": {
                        value: param.cf+""
                    },
                    "TIPOLOGIA_ISCRIZIONE": {
                        value: param.tipologiaIscrizione+""
                    },
                    "SIGLA_ORDINE": {
                        value: param.siglaOrdine+""
                    }
                }
            }
        } else {
            body =
            {
                params: {
                    "ID_SOGGETTO": {
                        value: param.idSoggetto+""
                    },
                    "CF": {
                        value: param.cf+""
                    },
                    "TIPOLOGIA_ISCRIZIONE": {
                        value: param.tipologiaIscrizione+""
                    }
                }
            }

        }

        const response = await getTodoEventResourceApi().mswfTodoEventFetchTodoListPost(body, getDefaultAxiosConfig())
        return response
    } catch (err) {
        // var msg = "Errore durante il recupero delle todo list";
        // throw new Error(msg + " - " + err);
        //Viene restituito null perchè tale chiamata non deve mai ritornare errore al momento
        return null;
    }
}

    export async function setDisabledTodoEventByID(idTodo: any) {
        try {
             const body: StringParam = {
                 "param": idTodo
             }


            const response = await getTodoEventResourceApi().mswfTodoEventDisablePost(body, getDefaultAxiosConfig())
            return response
        } catch (err) {
            // var msg = "Errore durante il recupero delle todo list";
            // throw new Error(msg + " - " + err);
            //Viene restituito null perchè tale chiamata non deve mai ritornare errore al momento
            return null;
        }
    }
