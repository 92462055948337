import React, {Suspense, useState} from 'react'
import PivotTableUI from 'react-pivottable/PivotTableUI'
import 'react-pivottable/pivottable.css'
import './PivotTableUISmartWrapper.scss'
import TableRenderers from 'react-pivottable/TableRenderers'
import Plot from 'react-plotly.js'
import createPlotlyRenderers from 'react-pivottable/PlotlyRenderers'

import GenericSpinner from '../../GenericSpinner'

function PivotWrapper(props) {

    const [pivotState, setPivotState] = useState(props.state.pivotState);
    const changeEvent = props.state.onChangePivot;

    const handlePivotStateChange = (newPivotState) => {
        //;
        setPivotState(newPivotState);
        changeEvent(newPivotState);
    }

    return (
        <PivotTableUI
            renderers={Object.assign(
                {},
                TableRenderers,
                createPlotlyRenderers(Plot)
            )}
            {...props.state.pivotState}
            data={props.state.pivotData}
            onChange={(e)=>handlePivotStateChange(e)}
            unusedOrientationCutoff={Infinity}
    />
    )
}

class PivotTableUISmartWrapper extends React.Component<any,any> {

    constructor(props) {
        super(props);
        this.state = {
            pivotState: props.pivotState,//stato del pivot
            pivotData: props.pivotData,//dati su cui fare il pivot.
            isLoading: props.isLoading,//marca il componente come in caricamento
            pivotFilter: props.pivotFilter,
            onChangePivot: props.onChangePivot
        };
    }

    componentWillReceiveProps(nextProps) {
        //;
        this.setState({
            pivotState: nextProps.pivotState,
            pivotData: nextProps.pivotData,
            isLoading: nextProps.isLoading,
            pivotUiRef: nextProps.pivotUiRef,
            pivotFilter: nextProps.pivotFilter
        });
    }

    componentDidMount(){

    }

    componentDidUpdate(nextProps){
        //;
    }


    handlePivotChange(e){
        this.state.onChangePivot(e);
    }

    render() {
        return (
            <>
                <Suspense fallback={<GenericSpinner />}>
                    <div>

                            {/* sezione filtri */}
                            {this.state.pivotFilter}


                            <PivotWrapper
                                state={this.state}
                                onChange={(e)=>this.handlePivotChange(e)}
                            />

                    </div>
                </Suspense>
            </>
        );
    }
}

export default PivotTableUISmartWrapper;
