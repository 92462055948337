import React, {useCallback, useRef, useState} from 'react'
import {getDefaultAxiosConfig, getFormazioneResourceApi} from 'src/app/utils/ManagerRestGateway'
import GenericInput from 'src/frontend/app/components/Form/Input'
import FormSubmit, {STATUS} from 'src/frontend/app/components/Form/GenericSubmit'
import {createFormData, getTabActiveFormId, handleChangeFormData, validateFormData} from 'src/app/utils/form-utils'
import moment from 'moment'
import {STATI_ESONERO} from 'src/model/Esoneri'
import {useHistory} from 'react-router-dom'
import {isCurrentMobileDevice} from 'src/utilities/deviceUtility'

const FormApprovazione: React.FunctionComponent<FormApprovazioneProps> = ({ id }) => {
  const [status, setStatus] = useState<any>()
  const [alertMessage, setAlertMessage] = useState<any>()

  // Form State
  const refForm = useRef<any>([])
  const [data, setData] = useState<any>({})
  const [errors, setErrors] = useState<any>({})
  const history = useHistory()
  const formData = createFormData(data, setData, errors, setErrors)
  const rules = {
    protocollo_delibera: {
      isRequired: true,
    },
  }

  const onInitInput = (cmp) => {
    refForm.current.push({
      cmp: cmp,
      tab: 0,
    })
  }

  const handleInput = (code, value) => {
    handleChangeFormData(formData, code, value, rules)
  }

  const submit = useCallback(
    async (e) => {
      try {
        const inputs = getTabActiveFormId(refForm, 0)
        const valid = validateFormData(formData, rules, inputs)
        const data = formData.data
        data.id = id
        data.stato = STATI_ESONERO.APPROVATA
        data.dt_cambio_stato = moment().format('YYYY-MM-DD')
        if (!valid) {
          window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
          return
        }

        setStatus(STATUS.LOADING)
        // TODO change api
        await getFormazioneResourceApi().msfoFormazioneEsoneroPatch(data as any, getDefaultAxiosConfig())
        setStatus(STATUS.SUCCESS)
        setAlertMessage('Dati salvati con successo')
        setTimeout(() => {
          history.goBack()
        }, 500)
      } catch (e) {
        setStatus(STATUS.FAILURE)
        setAlertMessage('Impossibile salvare i dati')
      }
    },
    [id, formData]
  )

  return (
    <FormSubmit title="Approvazione Richiesta" buttonLabel="CONFERMA" alertMessage={alertMessage} status={status} onSubmit={submit}>
      <div className="row">
        <div className={isCurrentMobileDevice() ? null : 'col-3'}>
          <GenericInput
            id="protocollo_delibera"
            type={'text'}
            placeholder={'Protocollo Delibera'}
            label={'Protocollo Delibera*'}
            value={data?.protocollo_delibera}
            errorMessage={errors?.protocollo_delibera}
            onInit={onInitInput}
            onChange={handleInput}
          />
        </div>
      </div>
    </FormSubmit>
  )
}

interface FormApprovazioneProps {
  id: any
}

export default FormApprovazione
