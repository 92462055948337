import React, {useEffect, useState} from 'react'
import {Spinner, Table} from 'design-react-kit'

import GenericCard from 'src/frontend/app/components/GenericCard'
import GenericInput from 'src/frontend/app/components/Form/Input'
import {formatEur} from 'src/frontend/app/utils/utils'
import GenericFormLabel from 'src/frontend/app/components/GenericFormLabel'
import {calcolateTotalActivity, getPrevCostiAttività} from 'src/model/PianiFormativi'
import {StyleCFPdForm} from './styles'


const FormDatiCFP: React.FunctionComponent<FormDatiCFPProps> = ({
                                                                  title,
                                                                  data,
                                                                  handleInput,
                                                                  onInitInput,
                                                                  errors,
                                                                  showErrorMessage,
                                                                  isDisabled
                                                                }) => {
  const [loading, setLoading] = useState(true)
  useEffect(() => {
    (async () => {
      try {
        const attivita = await getPrevCostiAttività()
        const cfp = attivita.map(a => {
          const pofC = pofCosti.find(c => c.cod_attivita === a.cod) || {}
          return {
            cod_attivita:a.cod,
            titolo:a.titolo,
            costo_unit_standard:a['costo_unitario_standard'],
            ...pofC,
            tot_cfp: pofC.tot_cfp || '0',
            nr_pratecip_prev: pofC.nr_pratecip_prev || a['media_partecipanti'],
          }
        })
        handleInput("previCostiAttFormativaList",cfp,false)
        setLoading(false)
      } catch (e) {
        showErrorMessage('C\'è stato un errore nel caricamento dei settori. Contattare il servizio di assistenza.')
      }
    })()
  }, [])

  const listError = errors || {}
  const pofCosti = data.previCostiAttFormativaList || []

  return (
    <StyleCFPdForm>
      <GenericCard cardHeader={title} className={'no-padding form-cfp'}>
        <div className='row'>
          <div className='col-12'>
            {loading && (
              <div className={'wrap-loader'}>
                <Spinner active={true} label={''} />
              </div>
            )}
            {!loading && (
              <Table className={'form-table'}>
                <thead>
                <tr>
                  <th scope='col' className={'col-title'}>Tipologia attività</th>
                  <th scope='col' className={'col-cfp-input col-input-small'}>Numero CFP Previsti</th>
                  <th scope='col' className={'col-cost'}>Costo Unitario Standard (per CFP)</th>
                  <th scope='col' className={'col-np col-input-small'}>Numero Partecipanti previsti</th>
                  <th scope='col' className={'col-total'}>Costo Totale TA</th>
                </tr>
                </thead>
                <tbody>
                {pofCosti.map((row, i) => {
                  const prefixId = 'previCostiAttFormativaList.' + i + '.'
                  return (
                    <tr key={i}>
                      <td>{row.titolo}</td>
                      <td>
                        <GenericInput
                          id={`${prefixId}tot_cfp`}
                          type={'number'}
                          value={row.tot_cfp}
                          step={'any'}
                          onChange={handleInput}
                          onInit={onInitInput}
                          hideErrorMessage={true}
                          isDisabled={isDisabled}
                          errorMessage={listError[`${prefixId}tot_cfp`]}
                          onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                              e.preventDefault();
                            }
                          }}
                        />
                      </td>
                      <td className={'text-center'}>{formatEur(row.costo_unit_standard)}</td>
                      <td>
                        <GenericInput
                          id={`${prefixId}nr_pratecip_prev`}
                          type={'number'}
                          value={row.nr_pratecip_prev}
                          onChange={handleInput}
                          onInit={onInitInput}
                          hideErrorMessage={true}
                          isDisabled={isDisabled}
                          errorMessage={listError[`${prefixId}nr_pratecip_prev`]}
                          onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                              e.preventDefault();
                            }
                          }}
                        />
                      </td>
                      <td className={'text-right'}>
                        {formatEur(calcolateTotalActivity(row))}
                      </td>
                    </tr>
                  )
                })}
                </tbody>
              </Table>
            )}
          </div>
        </div>
        {!loading && (
          <div className={'totals'}>
            <div className='row'>
              <div className='col-6'>
                <GenericFormLabel
                  label={'Costo totale Standard attività formativa'}
                  value={
                    formatEur(pofCosti.reduce((total, k) => {
                      return total + calcolateTotalActivity(k)
                    }, 0))
                  }
                />
              </div>
              <div className='col-6'>
                <GenericInput
                  id={'budget_prev_bilancio'}
                  label={'Budget previsto nel bilancio del soggetto proponente'}
                  type={'number'}
                  value={data.budget_prev_bilancio}
                  step={'any'}
                  prefix={'€'}
                  isDisabled={isDisabled}
                  onChange={handleInput}
                  onInit={onInitInput}
                  errorMessage={listError.budget_prev_bilancio}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      e.preventDefault();
                    }
                  }}
                />
              </div>
            </div>
          </div>)
        }
      </GenericCard>
    </StyleCFPdForm>
  )
}

interface FormDatiCFPProps {
  onInitInput?: any;
  handleInput?: any;
  data?: any;
  errors?: any;
  title: string;
  showErrorMessage?: any;
  isDisabled?: boolean
}

export default FormDatiCFP
