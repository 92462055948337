import {convertFlagSiNo, getDescrizioneStatoIscrizione} from 'src/utilities/utility'
import {ClauseBuilder, GraphqlBuilder, GraphqlGroupBuilder} from 'src/utilities/graphqlUtilities'
import {getAnagrafeResourcesApi, getDefaultAxiosConfig} from 'src/app/utils/ManagerRestGateway'
import {TokenStore} from 'src/keycloak/jwt/TokenStore'
import {getCodificaStati} from 'src/processes/CodificaStati'
import {getOrdiniCompetentiList} from './ElencoIscrittiFilterQuery'
import {getDatiOrdineFederazioneByCodiceGruppo} from 'src/processes/Soggetto'


export async function elencoOperatoriList(codiceProvinciaResidenza, globalSearchParam, itemPageCount, listaStati, currentPage, filterParams, sortParam) {

    // Recupera la mappa degli stati con la relativa descrizione da utilizzare nei filtri.
    if (listaStati === undefined || listaStati === null || listaStati.length === 0) {
        var listaStati = [];
        await getCodificaStati().then(async (response) => {
            listaStati = response;
        })
    }

    // Rappresenta la lista degli ordini da filtrare (se CONAF non viene gestita nella WHERE)
    var ordiniCompetentiList = await getOrdiniCompetentiList();

    let index = currentPage;
    let limit = itemPageCount;
    var specificGroup = Object.keys(TokenStore?.getInstance()?.getCurrentAccount()?.accounts)[0]
    var genericGroup = TokenStore.getInstance().getCurrentAccount().accounts[specificGroup]?.genericGroup
    var tipologiaIscrizione
    var idFederazione

    //setto la tipologia iscrizione in base al genericGroup del currentAccount
    if (genericGroup === 'ODAF') {
        tipologiaIscrizione = 2
    }
    // else if (genericGroup === 'STP') {
    //     tipologiaIscrizione = 3
    // }
    else if (genericGroup === 'CONAF') {
        tipologiaIscrizione = 4
    }

    else if (genericGroup === 'FODAF') {

        tipologiaIscrizione = 11
        await getDatiOrdineFederazioneByCodiceGruppo(specificGroup).then((response) => {
            if (response.data.httpStatus === 200)
                idFederazione =  response?.data?.returnedObject?.id

            else
                throw new Error(response.data.error);

        }).catch(() => {
            setTimeout(() => {
                this.setState({ message: "C'è stato un errore durante il recupero dei dati", typeMessage: 'danger' })
            }, 1000);
        })

    }

    // Valorizzo i parametri a partire dai filtri di ricerca
    if (filterParams === undefined || filterParams.length === 0)
        filterParams = [{ filterParamValue: "" }, { filterParamValue: "" }]
    const codiceFiscale = filterParams[0].filterParamValue
    const cognomeNome = filterParams[1].filterParamValue

    // Compongo la query query jpa in graph join
    const queryGraphContent = `{
        cf,
        codiceFiscaleAzienda,
        cognome,
        nome,
        tipoAnagrafe,
        pec,
        formaGiuridica,
        ragioneSociale,
        idIscrizione,
        ordineTerritorialeCompetente,
        statoIscrizione,
        tipologiaIscrizione,
        numeroIscrizioneOrdine,
        flagIscrizioneEpap,
        idFederazioneCompetente,
        cancellato
    }`;

    // Compongo la query di select, con gli eventuali order by
    const selectQuery = `{
        cf${sortField(sortParam?.codiceFiscale)},
        codiceFiscaleAzienda,
        cognome${sortField(sortParam?.cognomeNomeRagioneSociale)},
        nome${sortField(sortParam?.cognomeNomeRagioneSociale)},
        tipoAnagrafe${sortField(sortParam?.tipologia)},
        pec,
        idFederazioneCompetente,
        formaGiuridica,
        ragioneSociale${sortField(sortParam?.cognomeNomeRagioneSociale)},
        idIscrizione${sortField(sortParam?.nIscrizione)},
        ordineTerritorialeCompetente,
        tipologiaIscrizione,
        statoIscrizione${sortField(sortParam?.stato)},
        numeroIscrizioneOrdine,
        flagIscrizioneEpap,
        cancellato
    }`;

    var globalSearchQueryGraph = new GraphqlBuilder()
        .group(
            new GraphqlGroupBuilder()
                .in("tipologiaIscrizione", [tipologiaIscrizione])
                .in("ordineTerritorialeCompetente", (ordiniCompetentiList.length === 0 || genericGroup === "FODAF" ) ? null : ["\"" + ordiniCompetentiList.toString().replaceAll(",", "\",\"") + "\""])
                .in("idFederazioneCompetente", (genericGroup !== "FODAF") ? null : idFederazione )
                .or([new ClauseBuilder().in("cancellato", 0), new ClauseBuilder().isNull("cancellato", true)])
                .buildAND()
        )
        .group(
            new GraphqlGroupBuilder()
                .like("nome", globalSearchParam)
                .like("cognome", globalSearchParam)
                .like("cf", globalSearchParam)
                .buildOR()
        )
        .build();

    var filterSearchQueryGraph = new GraphqlBuilder()
        .group(
            new GraphqlGroupBuilder()
                .or([
                    new ClauseBuilder().like("nome", cognomeNome),
                    new ClauseBuilder().like("cognome", cognomeNome),
                ])
                .like("cf", codiceFiscale)
                .in("tipologiaIscrizione", [tipologiaIscrizione])
                .in("ordineTerritorialeCompetente", (ordiniCompetentiList.length === 0 || genericGroup === "FODAF" ) ? null : ["\"" + ordiniCompetentiList.toString().replaceAll(",", "\",\"") + "\""])
                .in("idFederazioneCompetente", (genericGroup !== "FODAF") ? null : idFederazione )
                .or([
                    new ClauseBuilder().in("cancellato", 0),
                    new ClauseBuilder().isNull("cancellato", true)
                //    new ClauseBuilder().eq("ordineTerritorialeCompetente", ""),
                //    new ClauseBuilder().isNull("ordineTerritorialeCompetente", true)
                ])
                .buildAND()
        )
        .build();

    var isFilterParamNotEmpty = filterParams.map(a => a.filterParamValue !== "").includes(true)

    var checkProfileFodaf
    var query

    //ODAF
    if(genericGroup !== "FODAF") {
        //Query Odaf
        query =   `{
            ordineTerritorialeCompetente: {${inClauseFromArray(ordiniCompetentiList?.toString().replaceAll(",", "\",\""))}},
            idFederazioneCompetente: { IS_NULL: true },
            tipologiaIscrizione: {IN: [${tipologiaIscrizione}]},
            OR:[
                {cancellato: {NE: 1}}, {cancellato:{IS_NULL: true}}
            ]    
        }`
    }

    //FODAF
    else {
        checkProfileFodaf = "IN: [" + idFederazione + "]"
        //Query Fodaf
        query =   `{
            idFederazioneCompetente: { ${checkProfileFodaf} },
            tipologiaIscrizione: {IN: [${tipologiaIscrizione}]},
            AND:[
                {
                    OR:[
                        {cancellato: {NE: 1}}, {cancellato:{IS_NULL: true}},
                    ]  
                },
                {  
                    OR:[
                        {ordineTerritorialeCompetente: {EQ: ""}}, {ordineTerritorialeCompetente:{ IS_NULL: true } }
                    ]   
                }
            ]
           
        }`

    }
    let whereCondition = globalSearchParam !== "" && globalSearchParam !== undefined ?
        `${globalSearchQueryGraph}` :
        isFilterParamNotEmpty && filterParams !== undefined ?
            `${filterSearchQueryGraph}` :
            (ordiniCompetentiList.length === 0) ?
            `{
                tipologiaIscrizione: {IN: [${tipologiaIscrizione}]},
                OR:[
                    {cancellato: {EQ: 0}}, {cancellato:{IS_NULL: true}}
                ] 
            }`
            :
            `${query}`
        ;
    // Costruisco la query jpa da passare al server
    let jpaQuery = `{
        IscrittiEntityViews(
            page:{start: ${index}, limit: ${limit}}
            where: ${whereCondition}
        )
        {
            pages
            total
            select ${selectQuery}
        }
    }`;

    /*
    Questa è una query di esempio che restituisce un oggetto Paged<Soggetto>.
    L'oggetto Paged contiene nei campi:

    index: è la pagina corrente che parte da 1
    size: il numero di elementi in pagina
    totalCount: è il numero totale di elementi trovati dalla query
    pageCount: il numero totale di pagine da scorrere se il numero di elementi per pagina è size
    content: i campi richiesti in output dall'entità Soggetto eventualmente collegati in join sull'entità che serve, parametrizzati in {queryGraphContent}
    */

    //query da inviare all'endpoint graph specifico che accetta in input la query graphjpa da eseguire
    //e restituisce in ouput un oggetto paginato con la lista delle entità.
    //occorre ottimizzare il codice
    let queryGraph = `query allAnagrafeIscrizioniByFilter($pageRequest: PagedRequestDtoInput) {
        allAnagrafeIscrizioniByFilter(pageRequest: $pageRequest){
            index
            size
            totalCount
            pageCount
            content ${queryGraphContent}
        }
    }`;

    const json = {
        operationName: "allAnagrafeIscrizioniByFilter",
        query: queryGraph,
        variables: {
            "pageRequest": {
                "index": index,
                "size": limit,
                "jpsQuery": jpaQuery
            }
        }
    };

var iscrizioneEpap
    var rows = await getAnagrafeResourcesApi().msgaAnagrafeElencoIscrizioniPost(json.variables.pageRequest, getDefaultAxiosConfig()).then(response => {
        var descrizioneStatoIscrizione
        var rowsNames
        var responseData = response.data
        if (response?.data?.errors)
            throw new Error(response.data.errors[0].message)
        var responseData = response.data
        if (response.data.errors)
            throw new Error(response.data.errors[0].message)
        rowsNames = responseData.content.map((field) => (
            descrizioneStatoIscrizione = getDescrizioneStatoIscrizione(listaStati, field.statoIscrizione),
            iscrizioneEpap = convertFlagSiNo(field.flagIscrizioneEpap),
            {
                data: [
                    field.cf,
                    field.cognome + " " + field.nome,
                ]
            }))
        var elencoIscrittiArrayRemapped = {
            index: responseData.index,
            pageCount: responseData.pageCount,
            size: responseData.size,
            totalCount: responseData.totalCount,
            content: rowsNames
        }
        return elencoIscrittiArrayRemapped
    }).catch((err) => {
        throw new Error("Errore durante la get degli iscritti all'ordine " + err);
    })
    return JSON.stringify(rows)
}

/**
 * Se array = [LE] ==> [\"LE\"]
 * Se array = [LE,MI] ==> [\"LE\",\"MI\",]
 */
function inClauseFromArray(arrayContent) {
    var array = "IN: [";

    // array = array.substring(0, array.length - 1);
    array = array +  "\"" + arrayContent + "\"" + "]";

    return array;
}

function sortField(sortDirection) {
    if (sortDirection)
        return "(orderBy: " + sortDirection?.toUpperCase() + ")"
    else
        return ""
}
