import React from 'react'
import {Alert, Spinner} from 'design-react-kit'
import GenericCard from 'src/frontend/app/components/GenericCard'
import GenericButton from 'src/frontend/app/components/GenericButton'

export const STATUS = {
  LOADING: 'loading',
  SUCCESS: 'success',
  FAILURE: 'failure',
}
const GenericSubmit: React.FunctionComponent<GenericSubmitProps> = ({ title, buttonLabel, alertMessage, status, onSubmit, children }) => {
  return (
    <div className="container  register-container p-0">
      <GenericCard cardHeader={title}>
        {![STATUS.LOADING, STATUS.SUCCESS].includes(status) && (
          <>
            <div className="mb-4">{children}</div>

            <GenericButton
              label={buttonLabel}
              color={'primary'}
              onClickEvent={(e) => {
                e.preventDefault()
                onSubmit()
              }}
            />
          </>
        )}
        {status === STATUS.LOADING && (
          <div className={'wrap-loader'}>
            <Spinner active={true} label={''} />
          </div>
        )}
        {[STATUS.SUCCESS, STATUS.FAILURE].includes(status) && (
          <Alert className="mt-4" color={status === STATUS.SUCCESS ? 'success' : 'danger'}>
            {alertMessage}
          </Alert>
        )}
      </GenericCard>
    </div>
  )
}

interface GenericSubmitProps {
  title: string
  buttonLabel: string
  alertMessage: any
  status: any
  onSubmit: any
  children: any
}

export default GenericSubmit
