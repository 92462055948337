import React from 'react'
import {PageSection, Title} from '@patternfly/react-core'
import GenericBreadCrumb from 'src/frontend/app/components/GenericBreadCrumb'
import GenericCard from '../../components/GenericCard'
import GenericButton from '../../components/GenericButton'
import GenericSpinner from '../../components/GenericSpinner'
import {FormModificaPolizza} from './formPolizze/FormModificaPolizza'
import {PATH_TO_BO_POLIZZE_ELENCO, PATH_TO_BO_POLIZZE_ELENCO_MODIFICA} from 'src/app/utils/RoutesConstants'
import GenericAlert from 'src/frontend/app/components/GenericAlert'
import {isCurrentMobileDevice} from 'src/utilities/deviceUtility'

const isMobileDevice = isCurrentMobileDevice();


class ModificaPolizza extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.state = {
      message: "",
      typeMessage: "default",
      idPolizza: "",
    };
  }
  goToElencoPolizze = (redirectLink: any) => {
    this.props.history.push({
      pathname: redirectLink,
    });
  }
  componentDidMount() {

    if (this.props.history.location.state === undefined) {
    //   this.goToElencoPolizze('PATH_TO_BO_POLIZZE_ELENCO')
    }

    else {
      var idPolizza = this.props.history.location.state.idPolizza
      var periodoValDa = this.props.history.location.state.row.data[7].split('T')[0]
      var periodoValA = this.props.history.location.state.row.data[8].split('T')[0]
      this.setState({ idPolizza: idPolizza, periodoValDa:periodoValDa, periodoValA:periodoValA })

    }
    this.setState({isLoaded : true})
  }

  submitMessageResult = (alert: any) => {
    const element = document.getElementById("pageSectionId");
    if (element)
      element.scrollIntoView({ behavior: 'smooth' });
    this.setState({ message: alert.messaggio, typeMessage: alert.type })
  }

  render() {
    const { message, typeMessage, idPolizza, idSoggetto, periodoValDa, periodoValA } = this.state
    return (
      <div>
        { this.state.isLoaded ?
        <>

          <div className='container-fluid p-5'>

            <PageSection id='pageSectionId'>
              <GenericBreadCrumb paths={[{ label: "Polizze", link: PATH_TO_BO_POLIZZE_ELENCO }, { label: "Elenco polizze", link: PATH_TO_BO_POLIZZE_ELENCO }, { label: "Modifica Polizza", link: PATH_TO_BO_POLIZZE_ELENCO_MODIFICA }]} /><br></br>
              <Title headingLevel="h1" size="4xl">Modifica polizza</Title><br></br>
              <GenericAlert hidden={message === ''} label={message} color={typeMessage}></GenericAlert>
              <GenericCard className="cardStyle" isExpandibleCard={false} header={'Compila i seguenti campi'} body={<><FormModificaPolizza periodoValA={periodoValA} periodoValDa={periodoValDa} messaggio={this.submitMessageResult} idPolizza={idPolizza} idSoggetto={idSoggetto}/></>} /><br></br>
              <div className='row'>
                <div className={isMobileDevice ? 'col-6' : 'col-1' } >
                  <GenericButton label={"INDIETRO"} color={"tertiary"} onClick={() => this.goToElencoPolizze(PATH_TO_BO_POLIZZE_ELENCO)} />
                </div>
                <div className={isMobileDevice ? 'col-6' : 'col-1' } >
                  <GenericButton form={"idModificaPolizza"} type={"submit"} label={"CONFERMA"} color={"primary"}/>

                </div>
              </div>
            </PageSection>
            <PageSection className='pt-5'>
              <div>
                <Title headingLevel='h3'>Legenda</Title>
                <p>* Campo obbligatorio</p>
              </div>
            </PageSection>
          </div>
      </>
        : <GenericSpinner/>}
      </div>

    );
  }
};

export default ModificaPolizza;
