import {DropdownToggle, LinkList, LinkListItem, UncontrolledDropdown} from 'design-react-kit'
import React from 'react'
import {DropdownMenu} from 'reactstrap'
import GenericBreadCrumb from 'src/frontend/app/components/GenericBreadCrumb'
import GenericCard from '../../components/GenericCard'
import dots from '../../images/moreItems.png'
import './style.scss'
import GenericTitle from '../../components/GenericTitle'
import GenericTable from 'src/backoffice/app/components/GenericTable'
import GenericSpinner from 'src/backoffice/app/components/GenericSpinner'
import {
    creaNuovaPolizza,
    getListaAssicurazioni,
    getSituazioneAssicurativa,
    recuperaCertificato,
    recuperaCopiaPagamento,
    richiediRiaperturaPolizza
} from 'src/processes/assicurazioni/Assicurazione'
import {
    findSTPByPIva,
    getIscrizioneOrdineByCfFO,
    GetIscrizioneStpIscrittaByPartitaIvaPost
} from 'src/processes/Soggetto'
import {getIscrizioneByCodiceFiscaleResponsabileStp} from 'src/processes/ProcessoIscrizione'
import {creaAumentoMassimale} from 'src/processes/assicurazioni/AumentoMassimale'
import {recuperoTabellaUnoDefinitivaAssociataAllaPolizza} from 'src/processes/assicurazioni/TabellaUno'
import GenericModal from 'src/backoffice/app/components/GenericModal'
import {
    PATH_TO_FO_ASSICURAZIONI,
    PATH_TO_FO_ASSICURAZIONI_AUMENTA_MASSIMALE_POLIZZA,
    PATH_TO_FO_ASSICURAZIONI_CANCELLA_POLIZZA,
    PATH_TO_FO_ASSICURAZIONI_COMPILA_POLIZZA,
    PATH_TO_FO_ASSICURAZIONI_VISUALIZZA_POLIZZA
} from 'src/app/utils/RoutesConstants'
import {AppUtils} from 'src/app/utils/FormUtils'
import {MessaggiErroreUtente} from 'src/errorBoundary/ErrorConstants'
import {TokenStore} from 'src/keycloak/jwt/TokenStore'
import {isEmptyValue} from 'src/utilities/utility'
import {isCurrentMobileDevice} from 'src/utilities/deviceUtility'

const isMobileDevice = isCurrentMobileDevice();
const breadcrumbOptions = [
  { label: "Assicurazioni / Lista richieste", link: PATH_TO_FO_ASSICURAZIONI }
]

const cardHeader = "Lista richieste assicurative"

const listHeaders = [
          {id:1, name: "DATA RICHIESTA"},
          {id:2, name: "FASCIA ASSICURATIVA"},
          {id:3, name: "CONTRIBUTO ASSICURATIVO"},
          {id:4, name: "MASSIMALE"},
          {id:5, name: "CODICE"},
          {id:6, name: "NUMERO PROGRESSIVO"},
          {id:7, name: "INIZIO VALIDITÀ"},
          {id:8, name: "FINE VALIDITÀ"},
          {id:9, name: "STATO"},
          {id:10, name: "TIPO"},
          {id:11, name: "FILE"},
]

export default class Assicurazioni extends React.Component<any, any>{
  constructor(props){
    super(props);
    this.state = {
      activeTab: 1,
      isLoaded:false,
      dropdownList: [],
      selectedRow: {}
    }
  }

  async componentDidMount(): Promise<void> {
    //funzione per disabilitare i filtri
    this.setState({disableFilter:true,hideDropdown:true,hideFilters:true});
    let tipologia = "PF";
    let piva = ""
    if(await TokenStore.getInstance().isOperatoreResponsabilePropriaSTP() === true && TokenStore.getInstance().getCurrentAccountSpecificGroupName().includes("STP")) {
      tipologia = "PG";
      await getIscrizioneByCodiceFiscaleResponsabileStp(TokenStore.getInstance().getDecodedTokenJWT().fiscalNumber.toUpperCase()).then(async (responseSoggetto: any) => {
        piva = responseSoggetto.data.returnedObject.partitaIva
        //visualizzazione della lista delle assicurazioni per codice fiscale dell'utente

        }).catch((err: any) => {
            this.showModal(true, "Errore",err.message, false);
        })

    } else if(TokenStore.getInstance().isOperatoreSTP() === true && TokenStore.getInstance().getCurrentAccountSpecificGroupName().includes("STP")) {

      tipologia = "PG";
      await getIscrizioneOrdineByCfFO(TokenStore.getInstance().getDecodedTokenJWT().fiscalNumber.toUpperCase()).then(async (responseSoggetto: any) => {

        if(responseSoggetto?.data?.returnedObject === null || (responseSoggetto?.data?.returnedObject !== null && responseSoggetto?.data?.returnedObject?.partitaIva === null)) {
          var specificGroup = Object.keys(TokenStore?.getInstance()?.getCurrentAccount()?.accounts)[0]
          var codiceFiscaleRappresentanteSTP = TokenStore?.getInstance().getDecodedTokenJWT().fiscalNumber
          var pIva = specificGroup.split("_")[1]

          if (specificGroup.startsWith("STP_")){
              var soggettoSTP = await findSTPByPIva(pIva)
              if(soggettoSTP?.data) {
                  piva = soggettoSTP.data.partitaIva;
                  codiceFiscaleRappresentanteSTP = soggettoSTP.data.codiceFiscaleResponsabileStp
              }
          }
        }
        else piva = responseSoggetto.data.returnedObject.partitaIva

        }).catch((err: any) => {
            this.showModal(true, "Errore",err.message, false);
        })

    }

     /** SERVIZIO CHE RECUPERA LA SITUAZIONE ASSICURATIVA */
     await getSituazioneAssicurativa(TokenStore.getInstance().getDecodedTokenJWT().fiscalNumber.toUpperCase(), tipologia, piva).then(async (responseSituazioneAssicurativa: any) => {

      this.setState({situazioneAssicurativa: responseSituazioneAssicurativa.situazioneAssicurativa})
      this.setState({coperturaAssicurativa: responseSituazioneAssicurativa.valid })

      }).catch((err) => {
          this.setState({message: err.message, typeMessage: "danger"})
      })
    await getListaAssicurazioni(TokenStore.getInstance().getDecodedTokenJWT().fiscalNumber, tipologia, piva ).then(async (response: any)=>{
      //data: ['ODAF_44','06 lug 22', 'Fascia ASW', '1.000', '300.000', '01/07/22', '01/07/23', 'RICHIESTA INTEGRAZIONE', '-'],
      //let assicurazioniAttive ;
        let listaAssicurazioni: any = [];
        response.elencoAssicurazioniAttive.map(async polizzaAttiva=> {
            let descrizioneStato = "";
            /** Check sulla descrizione stato */
            if(polizzaAttiva.stato === 0) descrizioneStato = "COMPILAZIONE";
            if(polizzaAttiva.stato === 1) descrizioneStato = "IN VALUTAZIONE";
            if(polizzaAttiva.stato === 2) descrizioneStato = "CONFERMATO";
            if(polizzaAttiva.stato === 3) descrizioneStato = "RIFIUTATA";
            if(polizzaAttiva.stato === 4) descrizioneStato = "RICHIESTA INTEGRAZIONE";
            if(polizzaAttiva.stato === 5 && (polizzaAttiva.noteRichiedente === undefined || polizzaAttiva.noteRichiedente === null || polizzaAttiva.noteRichiedente === undefined || polizzaAttiva.noteRichiedente === "")) descrizioneStato = "ARCHIVIATA";
            if(polizzaAttiva.stato === 5 && (polizzaAttiva.noteRichiedente !== undefined && polizzaAttiva.noteRichiedente !== null && polizzaAttiva.noteRichiedente !== undefined && polizzaAttiva.noteRichiedente !== "")) descrizioneStato = "RICHIESTA RIAPERTURA";
            if(polizzaAttiva.stato === 6) descrizioneStato = "CANCELLATA";

            //Controllo se la polizza è scaduta
            let today = new Date();
            if(polizzaAttiva.stato === 2) {
                let fineValidita;
                if(polizzaAttiva.associatoConConaf === 1 ) {
                  fineValidita = new Date(polizzaAttiva.periodoValAConaf)
                } else if(polizzaAttiva.associatoConAltri === 1 ) {
                  fineValidita = new Date(polizzaAttiva.periodoValAAltri)
                }

                if(fineValidita < today) {
                    descrizioneStato = "SCADUTO E CONFERMATO";
                }

            }
            if(polizzaAttiva.tipoRichiesta === 'A') descrizioneStato = descrizioneStato + " (Aumento Massimale)";

            let premioConaf = "-";
            let massimaleConaf = "-";

            //formattazione e localizzazione delle date
            let periodoDiValiditaDa = "-";
            let periodoDiValiditaA = "-";

            if(polizzaAttiva.associatoConConaf === 1 && polizzaAttiva.periodoValDaConaf) {
              let date = polizzaAttiva.periodoValDaConaf.split("T").join(" ").split(".")[0];
              date = AppUtils.itDate(date);
              periodoDiValiditaDa = date;
            }
            else if(polizzaAttiva.associatoConAltri === 1 && polizzaAttiva.periodoValDaAltri) {
                let date = polizzaAttiva.periodoValDaAltri.split("T").join(" ").split(".")[0];
                date = AppUtils.itDate(date);
                periodoDiValiditaDa = date;
            }

            if(polizzaAttiva.associatoConConaf === 1 && polizzaAttiva.periodoValAConaf) {
                let date = polizzaAttiva.periodoValAConaf.split("T").join(" ").split(".")[0];
                date = AppUtils.itDate(date);
                periodoDiValiditaA = date;
            }
            else if(polizzaAttiva.associatoConAltri === 1 && polizzaAttiva.periodoValAAltri) {
              let date = polizzaAttiva.periodoValAAltri.split("T").join(" ").split(".")[0];
              date = AppUtils.itDate(date);
              periodoDiValiditaA = date;
            }

            //Setting premio e massimale in base alla tipologia (collettiva o individuale)
            if(polizzaAttiva.associatoConConaf === 1) {
                if(!isEmptyValue(polizzaAttiva.premioConaf)){
                    premioConaf = AppUtils.currency(polizzaAttiva.premioConaf);
                }
                if(!isEmptyValue(polizzaAttiva.massimaleConaf)){
                    massimaleConaf = AppUtils.currency(polizzaAttiva.massimaleConaf);
                }
            }
            else if(polizzaAttiva.associatoConAltri === 1) {
              if(!isEmptyValue(polizzaAttiva.premioAltri)){
                  premioConaf = AppUtils.currency(polizzaAttiva.premioAltri);
              }
              if(!isEmptyValue(polizzaAttiva.massimaleAltri)) {
                  massimaleConaf = AppUtils.currency(polizzaAttiva.massimaleAltri);
              }
            }

            let dataCreazioneRischiesta = "-";

            if(!isEmptyValue(polizzaAttiva.dataCreazioneRichiesta)) {
                let date = polizzaAttiva.dataCreazioneRichiesta.split("T")[0];
                date = AppUtils.itDate(date);
                dataCreazioneRischiesta = date;
            }

            let certificatoIsEmpty = false;
            let pagamentoIsEmpty = false;
            //Verifico se esiste il pagamento della polizza
            if(polizzaAttiva.assicurazioneAllegati.filter(
              allegato =>
                  allegato.cancellato === 0 &&
                  ((allegato.tipologiaAllegato === "polizze_copia_pagamento") || (allegato.tipologiaAllegato === "AssicurazioniCopiaPagamento"))
              ).length === 0) {
                pagamentoIsEmpty = true
            }
            //Verifico se esiste il certificato della polizza
            if(polizzaAttiva.stato === 2 && polizzaAttiva.associatoConConaf === 1 && polizzaAttiva.associatoConAltri === 0) {
              certificatoIsEmpty = false;
            } else {
              certificatoIsEmpty = true;
            }
            let obj = {
              id: polizzaAttiva.id,
              certificatoIsEmpty: certificatoIsEmpty,
              pagamentoIsEmpty: pagamentoIsEmpty,
              data: [
                    (!isEmptyValue(dataCreazioneRischiesta)) ? dataCreazioneRischiesta : '-',
                    (!isEmptyValue(polizzaAttiva.fasciaPremioConaf)) ? polizzaAttiva.fasciaPremioConaf : '-',
                    (!isEmptyValue(premioConaf)) ? premioConaf : '-',
                    (!isEmptyValue(massimaleConaf)) ? massimaleConaf : '-',
                    (!isEmptyValue(polizzaAttiva.id)) ? polizzaAttiva.id : '-',
                    (polizzaAttiva.progressivoConaf) ? polizzaAttiva.progressivoConaf : '-',
                    (!isEmptyValue(periodoDiValiditaDa)) ? periodoDiValiditaDa : '',
                    (!isEmptyValue(periodoDiValiditaA)) ? periodoDiValiditaA : '',
                    (!isEmptyValue(descrizioneStato)) ? descrizioneStato : '-',
                    (polizzaAttiva.associatoConConaf === 1) ? "Collettiva" : (polizzaAttiva.associatoConAltri === 1 ) ? "Individuale" : '-'
                  ]
            }

            await listaAssicurazioni.push(obj)
        })

      await this.setState({rowsNames: listaAssicurazioni })


      await this.setState({isLoaded : true})
    }).catch((err: any) => {
        this.showModal(true, "Errore",err.message, false);
    })

  }


  downloadAllegato = async (type: any, row?:any) => {
      await this.setState({ selectedRow: row })
      try
      {
          if(type === 'certificato') {
              await recuperaCertificato(row.id).then(response => {
                  if(response["content"] === undefined || response["content"] === null || response["content"] === ""){
                    this.showModal(true, "Attenzione","File non presente per la polizza selezionata", false);

                  }
                  else {
                      let linkSource = "data:application/pdf;base64,"+response["content"];
                      const downloadLink = document.createElement("a");
                      const fileName = "certificato_"+row.id+".pdf";
                      downloadLink.href = linkSource;
                      downloadLink.download = fileName;
                      downloadLink.click();
                  }
              })
          } else if(type === 'copia_pagamento') {
            //*   Recupero della copia del pagamento    */
            await recuperaCopiaPagamento(row.id).then(response => {
                if(response["content"] === undefined || response["content"] === null || response["content"] === ""){
                    this.showModal(true, "Attenzione","File non presente per la polizza selezionata", false);

                }
                else {
                    let linkSource = "data:application/pdf;base64,"+response["content"];
                    const downloadLink = document.createElement("a");
                    //const fileName = this.state.selectedRow.data[0]+".pdf";
                    const fileName = "copia_pagamento_"+response["name"]+".pdf";
                    downloadLink.href = linkSource;
                    downloadLink.download = fileName;
                    downloadLink.click();
                }
              let pdfAsDataUri :any = "data:application/pdf;base64,"+response["content"];
              window.open(pdfAsDataUri,"_blank");
              })
        }

      } catch(err){
        this.showModal(true, "Errore", MessaggiErroreUtente.errore_downlaod_file, false);
      }
  }

  //azione all'on select
  onSelectAction = (redirectLink: any) => {

        this.props.history.push({
            pathname: redirectLink,
            state: {action: "polizze", polizza: this.state.selectedRow}
        });

  }

  aumentaMassimale = (row ?: any) => {
        creaAumentoMassimale(row.id).then(async (responseMassimale: any)=> {
          this.setState({polizza: responseMassimale})
          recuperoTabellaUnoDefinitivaAssociataAllaPolizza(responseMassimale.id).then(async (responseTabella: any)=> {
            this.props.history.push({
                pathname: PATH_TO_FO_ASSICURAZIONI_AUMENTA_MASSIMALE_POLIZZA,
                state: {action: "polizze", polizza: responseMassimale}
            });
            this.setState({ tabellaUno: responseTabella, isLoaded: true })
          })
          .catch((err: any) => {
            this.showModal(true, "Errore",err.message, false);
          })

          await this.setState({fields: responseMassimale, isLoaded: true});
    })
    .catch((err: any) => {
      if(err.message.includes("data di revisione delle quote di adesione")) {
        this.showModal(true, "Attenzione",err.message, true);
      } else if(err.message.includes("periodo di compilazione")) {
        this.showModal(true, "Attenzione",err.message.replace("Service Exception: ", ""), false);
      }
      else this.showModal(true, "Errore",err.message, false);

    })
  }

  /*creazione della polizza
  * il campo checkDataInizio viene settato di default a zero
  * se settato a 1 serve a creare una polizza nonostante si sia vicini alla data di revisione delle quote di adesione
  */
   creaPolizza = async (tipologia: any, redirectLink: any, checkDataInizio: any) => {
      //se tipologia collettiva eseguo questo

      if(tipologia === 'polizza_collettiva')
      {
          /** Chiamata al servizio per creare la polizza collettiva
           * in caso di successo fa il redirect
           * se ci sono errori visualizzare l'alert
          */

         //Check per capire se la persona è fisica o giuridica
         if(TokenStore.getInstance().isCurrentAccountSTP() === true) {
            var specificGroup = Object.keys(TokenStore?.getInstance()?.getCurrentAccount()?.accounts)[0]
            var pIva = specificGroup.split("_")[1]
            await GetIscrizioneStpIscrittaByPartitaIvaPost(pIva).then(async (responseIscrizioneSTP: any) => {

              if(responseIscrizioneSTP.data.returnedObject === null) {
                this.showModal(true, "Errore",responseIscrizioneSTP.data.error, false);
              }
              else {
                let richiestaPolizza = {
                  tipologiaPolizza: tipologia,
                  codiceFiscale: TokenStore.getInstance().getDecodedTokenJWT().fiscalNumber,
                  partitaIva:  (responseIscrizioneSTP.data) ? responseIscrizioneSTP.data.returnedObject.partitaIva : "",
                  idIscrizione: (responseIscrizioneSTP.data) ? responseIscrizioneSTP.data.returnedObject.idIscrizione : "",
                  tipoUtente: "PG",
                  tipoRichiesta: null,
                  checkDataInizio: checkDataInizio

                }
                creaNuovaPolizza(richiestaPolizza).then(response=>{

                      this.props.history.push({
                          pathname: redirectLink,
                          state: {action: "polizze", polizza: response}
                      });
                }).catch((err) => {
                  if(err.message.includes("data di revisione delle quote di adesione")) {
                      this.showModal(true, "Attenzione",err.message, true);
                      this.setState({richiestaPolizza: richiestaPolizza})

                  } else if(err.message.includes("periodo di compilazione")) {
                    this.showModal(true, "Attenzione",err.message.replace("Service Exception: ", ""), false);
                  }
                  else this.showModal(true, "Errore",err.message, false);

                })
              }
            }).catch((err: any) => {
                  this.showModal(true, "Errore",err.message, false);
            })

         }
         else {
            await getIscrizioneOrdineByCfFO(TokenStore.getInstance().getDecodedTokenJWT().fiscalNumber.toUpperCase())
            .then(async (responseSoggetto: any) => {

                if(responseSoggetto?.data?.error){
                  throw responseSoggetto?.data?.error;
                }

                let codiceFiscaleDelRichiedente = undefined;
                let idIscrizione = undefined;
                if(responseSoggetto?.data?.returnedObject){
                  codiceFiscaleDelRichiedente = responseSoggetto?.data?.returnedObject.cf;
                  idIscrizione = responseSoggetto?.data?.returnedObject.idIscrizione;
                }

                if(codiceFiscaleDelRichiedente===undefined||idIscrizione===undefined){
                  throw new Error("Errore nella creazione della polizza. Impossibile recuperare i dati dell'iscrizione all'ordine");
                }

                if(responseSoggetto.data.returnedObject === null) {
                  this.showModal(true, "Errore",responseSoggetto.data.error, false);
                }
                else {
                  let richiestaPolizza = {
                        tipologiaPolizza: tipologia,
                        codiceFiscale: codiceFiscaleDelRichiedente,
                        partitaIva: null,
                        idIscrizione: idIscrizione,
                        tipoUtente: "PF",
                        tipoRichiesta: null,
                        checkDataInizio: checkDataInizio
                    }

                    creaNuovaPolizza(richiestaPolizza).then(response=>{
                          this.props.history.push({
                              pathname: redirectLink,
                              state: {action: "polizze", polizza: response}
                          });
                    })
                    .catch((err) => {
                        if(err.message.includes("data di revisione delle quote di adesione")) {
                          this.showModal(true, "Errore",err.message, true);
                          this.setState({richiestaPolizza: richiestaPolizza})
                        } else
                        if(err.message.includes("periodo di compilazione")) {
                          this.showModal(true, "Attenzione",err.message.replace("Service Exception: ", "").replace("Errore. ", ""), false);
                        }
                        else this.showModal(true, "Errore",err.message, false);

                    })
                }
            }).catch((err: any) => {
              this.showModal(true, "Errore",err, false);
            })

         }
      }

      //polizza individuale
      else {
          /**Chiamata al servizio per creare la polizza individuale
           * in caso di successo fa il redirect
           * se ci sono errori visualizzare l'alert
          */
          if(TokenStore.getInstance().isCurrentAccountSTP() === true) {
            var specificGroup = Object.keys(TokenStore?.getInstance()?.getCurrentAccount()?.accounts)[0]
            var pIva = specificGroup.split("_")[1]
            await GetIscrizioneStpIscrittaByPartitaIvaPost(pIva).then(async (responseIscrizioneSTP: any) => {
                if(responseIscrizioneSTP.data.returnedObject === null) {
                  this.showModal(true, "Errore",responseIscrizioneSTP.data.error, false);
                }
                else {

                  let richiestaPolizza = {
                    tipologiaPolizza: tipologia,
                    codiceFiscale: TokenStore.getInstance().getDecodedTokenJWT().fiscalNumber,
                    partitaIva:  (responseIscrizioneSTP.data) ? responseIscrizioneSTP.data.returnedObject.partitaIva : "",
                    idIscrizione: (responseIscrizioneSTP.data) ? responseIscrizioneSTP.data.returnedObject.idIscrizione : "",
                    tipoUtente: "PG",
                    tipoRichiesta: null,
                    checkDataInizio: checkDataInizio
                  }
                  creaNuovaPolizza(richiestaPolizza).then(response=>{
                        this.props.history.push({
                            pathname: redirectLink,
                            state: {action: "polizze", polizza: response}
                        });
                  }).catch((err) => {
                    this.showModal(true, "Errore",err.message, false);
                  })
                }
            }).catch((err: any) => {
                  this.showModal(true, "Errore",err.message, false);
            })

          }
          else {
            await getIscrizioneOrdineByCfFO(TokenStore.getInstance().getDecodedTokenJWT().fiscalNumber.toUpperCase())
              .then(async (responseSoggetto: any) => {

                  if(responseSoggetto?.data?.error){
                    throw responseSoggetto?.data?.error;
                  }

                  let codiceFiscaleDelRichiedente = undefined;
                  let idIscrizione = undefined;
                  if(responseSoggetto?.data?.returnedObject){
                    codiceFiscaleDelRichiedente = responseSoggetto?.data?.returnedObject.cf;
                    idIscrizione = responseSoggetto?.data?.returnedObject.idIscrizione;
                  }

                  if(codiceFiscaleDelRichiedente===undefined||idIscrizione===undefined){
                    throw new Error("Errore nella creazione della polizza. Impossibile recuperare i dati dell'iscrizione all'ordine");
                  }

                  let richiestaPolizza = {
                        tipologiaPolizza: tipologia,
                        codiceFiscale: codiceFiscaleDelRichiedente,
                        partitaIva: null,
                        idIscrizione: idIscrizione,
                        tipoUtente: "PF",
                        tipoRichiesta: null,
                        checkDataInizio: checkDataInizio
                    }

                    creaNuovaPolizza(richiestaPolizza).then(response=>{
                          this.props.history.push({
                              pathname: redirectLink,
                              state: {action: "polizze", polizza: response}
                          });
                    }).catch((err) => {
                      this.showModal(true, "Errore",err.message, false);
                    })
            }).catch((err) => {
              this.showModal(true, "Attenzione",err, false);
            })
          }
      }
  }

  //Richiesta di riapertura di una polizza archiviata
  riapriPolizza = () => {

     richiediRiaperturaPolizza(this.state.selectedRow.id).then(async (response: any)=> {
      this.showModal(true, "Messaggio","Richiesta di riapertura inviata! A breve un operatore valuterà la tua richiesta.", false);
      await this.setState({polizza: response, isLoaded: true});
    })
    .catch((err: any) => {
      this.showModal(true, "Errore", err.message, false);
    })
  }
  closeModal = () => {
    this.setState({modalOpen:false});
  }

  showModal = async (booleanValue, title, text, makeChoice) => {

    //Il campo makeChoice consente di inserire nel modal i pulsanti Si/No se è settato a true
    //se è false viene visualizzato solo il pulsante OK
    if(makeChoice === true) {
        text = text + " Sicuro di voler procedere?"
    }

    await this.setState({
        modalOpen:booleanValue,
        modalTitle: title,
        modalText:text,
        isLoaded: true,
        makeChoice: makeChoice,

    });
  }

  render() {
    var title = "Assicurazioni"
    const { situazioneAssicurativa, coperturaAssicurativa } = this.state;
    //se ho selezionato la riga va su svlocco tabella 1
    const onSelectRow = (row: any) => {

              this.state.dropdownList.splice(0,this.state.dropdownList.length);
              let tipologia = row.data[9];
              let stato = row.data[row.data.length-2].split(' (')[0];

              //Filtro per la visualizzazione delle actions
              if (stato === "COMPILAZIONE") {
                this.setState({dropdownList : [
                  { title: 'Visualizza', onClick: () => this.onSelectAction(PATH_TO_FO_ASSICURAZIONI_VISUALIZZA_POLIZZA), itemKey:PATH_TO_FO_ASSICURAZIONI_VISUALIZZA_POLIZZA},
                  { title: 'Modifica', onClick: () => this.onSelectAction(row.data[row.data.length-2].includes("Massimale") ? PATH_TO_FO_ASSICURAZIONI_AUMENTA_MASSIMALE_POLIZZA : PATH_TO_FO_ASSICURAZIONI_COMPILA_POLIZZA), itemKey:PATH_TO_FO_ASSICURAZIONI_COMPILA_POLIZZA},
                  { title: 'Cancella', onClick: () => this.onSelectAction(PATH_TO_FO_ASSICURAZIONI_CANCELLA_POLIZZA), itemKey:PATH_TO_FO_ASSICURAZIONI_CANCELLA_POLIZZA}]})
              }
              else if(stato === "ARCHIVIATA") {
                this.setState({dropdownList : [
                  { title: 'Visualizza', onClick: () => this.onSelectAction(PATH_TO_FO_ASSICURAZIONI_VISUALIZZA_POLIZZA), itemKey:PATH_TO_FO_ASSICURAZIONI_VISUALIZZA_POLIZZA},
                  {title: 'Chiedi riapertura', onClick: () => this.riapriPolizza() },
                  { title: 'Cancella', onClick: () => this.onSelectAction(PATH_TO_FO_ASSICURAZIONI_CANCELLA_POLIZZA), itemKey:PATH_TO_FO_ASSICURAZIONI_CANCELLA_POLIZZA}]})
              }
              else if(stato === "RICHIESTA RIAPERTURA") {
                this.setState({dropdownList : [
                  { title: 'Visualizza', onClick: () => this.onSelectAction(PATH_TO_FO_ASSICURAZIONI_VISUALIZZA_POLIZZA), itemKey:PATH_TO_FO_ASSICURAZIONI_VISUALIZZA_POLIZZA}]})
              }
              else if (stato === "RICHIESTA INTEGRAZIONE") {
                this.setState({dropdownList : [
                  { title: 'Visualizza', onClick: () => this.onSelectAction(PATH_TO_FO_ASSICURAZIONI_VISUALIZZA_POLIZZA), itemKey:PATH_TO_FO_ASSICURAZIONI_VISUALIZZA_POLIZZA},
                  { title: 'Integra', onClick: () => this.onSelectAction(PATH_TO_FO_ASSICURAZIONI_COMPILA_POLIZZA) , itemKey:PATH_TO_FO_ASSICURAZIONI_COMPILA_POLIZZA }]})
              }
              else if(stato === "CONFERMATO" && tipologia === "Collettiva") {
                 ;

                this.setState({dropdownList : [
                  { title: 'Visualizza', onClick: () => this.onSelectAction(PATH_TO_FO_ASSICURAZIONI_VISUALIZZA_POLIZZA), itemKey:PATH_TO_FO_ASSICURAZIONI_VISUALIZZA_POLIZZA},
                  { title: 'Aumenta massimale', onClick: () => {this.aumentaMassimale(this.state.selectedRow);}, itemKey:PATH_TO_FO_ASSICURAZIONI_AUMENTA_MASSIMALE_POLIZZA}
                ]})
              }
              else if(stato === "CONFERMATO" && tipologia === "Individuale" ) {
                this.setState({dropdownList : [
                  { title: 'Visualizza', onClick: () => this.onSelectAction(PATH_TO_FO_ASSICURAZIONI_VISUALIZZA_POLIZZA), itemKey:PATH_TO_FO_ASSICURAZIONI_VISUALIZZA_POLIZZA},
                ]})

              }
              else if (stato === "SCADUTO E CONFERMATO" ) {

                this.setState({dropdownList : [
                  { title: 'Visualizza', onClick: () => this.onSelectAction(PATH_TO_FO_ASSICURAZIONI_VISUALIZZA_POLIZZA), itemKey:PATH_TO_FO_ASSICURAZIONI_VISUALIZZA_POLIZZA},
                ]})
              }
              else if(stato === "IN VALUTAZIONE") {
                this.setState({dropdownList : [
                  { title: 'Visualizza', onClick: () => this.onSelectAction(PATH_TO_FO_ASSICURAZIONI_VISUALIZZA_POLIZZA), itemKey:PATH_TO_FO_ASSICURAZIONI_VISUALIZZA_POLIZZA}
                ]})

              }
              else if(stato === "RIFIUTATA") {
                this.setState({dropdownList : [
                  { title: 'Visualizza', onClick: () => this.onSelectAction(PATH_TO_FO_ASSICURAZIONI_VISUALIZZA_POLIZZA), itemKey:PATH_TO_FO_ASSICURAZIONI_VISUALIZZA_POLIZZA},
                ]})

              }
              else if(stato === "CANCELLATA") {
                this.setState({dropdownList : [
                  { title: 'Visualizza', onClick: () => this.onSelectAction(PATH_TO_FO_ASSICURAZIONI_VISUALIZZA_POLIZZA), itemKey:PATH_TO_FO_ASSICURAZIONI_VISUALIZZA_POLIZZA}
                ]})

              }

        this.setState({selectedRow: row})
    }


   return(
    <div className="container-fluid p-4">

      <div className='row'>
          <div className='col-1'></div>
          <div className='col-10'>
            <GenericBreadCrumb key="genericTab" paths={breadcrumbOptions} />
            <h4 /** hidden TOFIX */ className='status-text'>Situazione assicurativa: <b className={coperturaAssicurativa === 0 ? 'text-danger' : ''} >{situazioneAssicurativa}</b></h4>
            <GenericTitle Size="h1" title={title}></GenericTitle>
            <div className="Card">
                <GenericCard className=''
                    cardHeader={
                      <div className='justify-content-between d-flex flex-wrap'>
                        <h5 className='card-title'>{cardHeader}</h5>
                        <UncontrolledDropdown nav tag="div">
                            <DropdownToggle caret nav>
                              <button className='dots-btn'><img className='dropDownButton' src={dots} alt="scegli" /></button>
                            </DropdownToggle>
                            <div className='docs-show-dropdown-open'>

                              <DropdownMenu style={{width: '280px'}} className='full-width'>
                                <LinkList>
                                  <LinkListItem onClick={()=>this.creaPolizza('polizza_collettiva', PATH_TO_FO_ASSICURAZIONI_COMPILA_POLIZZA, 0)} size='large'>
                                      <span>Aderisci alla polizza collettiva</span>
                                  </LinkListItem>
                                  <LinkListItem onClick={()=>this.creaPolizza('polizza_individuale', PATH_TO_FO_ASSICURAZIONI_COMPILA_POLIZZA, 0)} size='large'>
                                      <span>Comunica polizza individuale</span>
                                  </LinkListItem>
                                </LinkList>

                              </DropdownMenu>
                            </div>
                        </UncontrolledDropdown>

                      </div>
                    }
                    cardBody={
                      <div>
                        {
                          (this.state.isLoaded) ?

                                <GenericTable
                                    hideCsv={true}
                                    hideSearch={this.state.disableFilter} hideDropdown={this.state.hideDropdown} hideFilters={this.state.hideFilters}
                                    isEditableTable={false}
                                    columns={listHeaders?.map((column) => column.name)}
                                    onSelect={onSelectRow}
                                    rows={this.state.rowsNames ? this.state.rowsNames : null}
                                    tableHeadFontSize="0.8em"
                                    tableContentFontSize="0.8em"
                                    hidePagination={true}
                                    hideActionColumn={false}
                                    downloadFunction={(type, e) => this.downloadAllegato(type, e)}
                                    actions={[{ label: "Aggiorna", function: '' }]}
                                    dropdownList={this.state.dropdownList}
                                    fitContentType="initial"
                                    autoWidthColumns={true}
                                    variant={!isMobileDevice ? false : 'compact'}
                                  />

                          :
                            <div><GenericSpinner></GenericSpinner></div>
                        }
                      </div>
                    }
                />
                <GenericModal
                      title={this.state.modalTitle}
                      text={this.state.modalText}
                      modalOpen={this.state.modalOpen}
                      closeButton={this.closeModal}
                      makeChoice={this.state.makeChoice}
                      choiceAction={()=>this.creaPolizza('polizza_collettiva', PATH_TO_FO_ASSICURAZIONI_COMPILA_POLIZZA, 1)}
                />
            </div>
          </div>
          <div className='col-1'></div>

      </div>
    </div>
   )
  }
};
export declare interface AssicurazioniProps {
  activeTab?: any;
}

