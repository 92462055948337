import * as React from 'react'
import {PageSection} from '@patternfly/react-core'
import GenericBreadCrumb from 'src/frontend/app/components/GenericBreadCrumb'
import GenericAlert from '../components/GenericAlert'
import {PATH_TO_FO_PRIVACY} from 'src/app/utils/RoutesConstants'

/* ----------------------------------- VARIABILI E COSTANTI ----------------------------------------- */
const title = 'Informativa privacy'

var breadcrumbOptions = [
    { label: "Informativa privacy", link: PATH_TO_FO_PRIVACY }
]

class Privacy extends React.Component<any, any> {
    constructor(props) {
        super(props);
        this.state = {
            message: "",
            typeMessage: "default"
        };
    }

    componentDidMount() {}

    render() {
        const { typeMessage, message } = this.state
        return (
            <>
                <PageSection id='pageSectionId'>
                    <div className='container register-container'>
                        <GenericBreadCrumb paths={breadcrumbOptions}></GenericBreadCrumb>
                        <div><h1>{title}</h1></div>
                        <GenericAlert hidden={message === ''} label={message} color={typeMessage}></GenericAlert>
                        <div>
                        <p>Il Consiglio dell'Ordine Nazionale dei Dottori Agronomi e dei Dottori Forestali - Cod. Fiscale 80247570585 - Via Po 22 - 00198 ROMA - Tel. 06-8540174 - Email: protocollo@conafpec.it / serviziosegreteria@conaf.it - (in seguito, “Titolare”), in qualità di titolare del trattamento dei dati, comunica che, ai sensi dell’art. 13 D. Lgs. 30.6.2003 n.196 come successivamente novellato dal D. Lgs 101/2018 n seguito, “Nuovo Codice Privacy”) e dell’art. 13 Regolamento UE n. 2016/679 (in seguito, “GDPR”) che i dati personali saranno trattati anche attraverso il sito istituzionale dell’ente con le modalità e per le finalità seguenti.</p>
                        <br></br>
                        <p><h2>1. Oggetto del trattamento e finalità</h2>
                        Il Titolare tratta i dati personali ed identificativi; nello specifico, indirizzo, nome utente e nome, cognome, email, numero telefonico, indirizzo, nonché  dati biometrici e sanitari di cui all’art. 9 GDPR – in seguito, “dati personali” o anche “dati” comunicati dall’interessato:
                        - in occasione dell’accesso al sito istituzionale;
                        - in occasione di procedure di manifestazione di interesse e attività connesse;
                        - in occasione della conclusione di contratti e/o richieste di servizi del Titolare e allo scopo necessario di utilizzo di servizi anche esterni;
                        Il trattamento dei Suoi dati potrà avvenire per eseguire un obbligo di legge, per finalità istituzionali, per esigenze di tipo operativo e gestionale, per dare esecuzione a prestazioni contrattualmente convenute.</p>
                        <br></br>
                        <p><h2>2. Modalità del trattamento</h2>
                        Il trattamento dei dati personali e sensibili è realizzato per mezzo delle operazioni indicate all’art. 4 Codice Privacy e all’art. 4 n. 2) GDPR e precisamente: raccolta, registrazione, organizzazione, conservazione, consultazione, elaborazione, modificazione, selezione, estrazione, raffronto, utilizzo, interconnessione, blocco, comunicazione, cancellazione e distruzione dei dati. I Suoi dati sono sottoposti a trattamento sia cartaceo che elettronico e/o automatizzato.
                        Il conferimento dei dati è obbligatorio per dar corso alla procedura di Suo interesse.</p>
                        <br></br>
                        <p><h2>3. Accesso ai dati</h2>
                        I dati potranno essere resi accessibili per le finalità indicate:
                        – a dipendenti e collaboratori del Titolare, nella loro qualità di incaricati e/o responsabili del trattamento e/o amministratori di sistema e/o titolari autonomi di trattamento;
                        – a società terze od altri soggetti, anche pubblici, (ad esempio, provider per la gestione e manutenzione del sito web, fornitori, istituti di credito, studi professionali, etc) che svolgono attività in outsourcing per conto del Titolare, nella loro qualità di responsabili del trattamento e/o incaricati.</p>
                        <br></br>
                        <p><h2>4. Comunicazione dei dati</h2>
                        Senza la necessità di un espresso consenso da parte dell’interessato (ex art. 24 lett. a), b), d) Codice Privacy e art. 6 lett. b) e c) GDPR), il Titolare potrà comunicare i dati a Organismi di vigilanza, Autorità giudiziarie, pubbliche amministrazioni nonché a tutti gli altri soggetti ai quali la comunicazione sia obbligatoria per legge per l’espletamento delle finalità istituzionali e di gestione del sito.
                        Detti soggetti tratteranno i dati nella loro qualità di autonomi titolari del trattamento. I dati non saranno diffusi e saranno trattati con riservatezza.</p>
                        <br></br>
                        <p><h2>5. Diritti dell’interessato</h2>
                        Ogni interessato ha i diritti di cui all’art. 7 Codice Privacy e art. 15 GDPR e precisamente i diritti di:
                        I. ottenere la conferma dell’esistenza o meno di dati personali e sensibili che La riguardano, anche se non ancora registrati e la loro comunicazione in forma intelligibile;
                        II. ottenere l’indicazione: a) dell’origine dei dati trattati; b) delle finalità e modalità del trattamento; c) della logica applicata in caso di trattamento effettuato l’ ausilio di strumenti elettronici; d) degli estremi identificativi del titolare, dei responsabili e del rappresentante qualora designato ai sensi dell’art. 5, comma 2 Codice Privacy e art. 3, comma 1, GDPR; e) dei soggetti o delle categorie di soggetti ai quali i dati personali possono essere comunicati o che possono venirne a conoscenza in qualità di rappresentante designato nel territorio dello Stato, di responsabili o incaricati;
                        III. ottenere: a) l’aggiornamento, la rettificazione ovvero, quando vi hai interesse, l’integrazione dei dati; b) la cancellazione, la trasformazione in forma anonima o il blocco dei dati trattati in violazione di legge, compresi quelli di cui non è necessaria la conservazione in relazione agli scopi per i quali i dati sono stati raccolti o successivamente trattati; c) l’attestazione che le operazioni di cui alle lettere a) e b) sono state portate a conoscenza, anche per quanto riguarda il loro contenuto, di coloro ai quali i dati sono stati comunicati o diffusi, eccettuato il caso in cui tale adempimento si rivela impossibile o comporta un impiego di mezzi manifestamente sproporzionato rispetto al diritto tutelato;
                        IV. opporsi, in tutto o in parte: a) per motivi legittimi al trattamento dei dati personali e sensibili che La riguardano, ancorché pertinenti allo scopo della raccolta; b) al trattamento di dati personali che La riguardano a fini di invio di materiale pubblicitario o di vendita diretta o per il compimento di ricerche di mercato o di comunicazione commerciale, mediante l’uso di sistemi automatizzati di chiamata senza l’intervento di un operatore mediante email e/o mediante modalità di marketing tradizionali mediante telefono e/o posta cartacea.</p>
                        L’Interessato, ove applicabili, ha altresì i diritti di cui agli artt. 16-21 GDPR (Diritto di rettifica, diritto all’oblio, diritto di limitazione di trattamento, diritto alla portabilità dei dati, diritto di opposizione), nonché il diritto di reclamo all’Autorità Garante.
                        <br></br>
                        <p><h2>6. I dati saranno trattati per il tempo stabilito dalla legge e non oltre il tempo necessario all’esecuzione del servizio richiesto e secondo la finalità istituzionale perseguita.</h2></p>
                        <br></br>
                        <p><h2>7. Modalità di esercizio dei diritti</h2>
                        Potrà in qualsiasi momento esercitare i diritti di cui al punto 6 inviando:
                        – una raccomandata a/r. a:
                        Consiglio dell'Ordine Nazionale dei Dottori Agronomi e dei Dottori Forestali - Via Po 22 - 00198 ROMA;</p>
                        <br></br>
                        <p><h2>8. Titolare, responsabile e incaricati</h2>
                        Il Titolare del trattamento è il Consiglio dell'Ordine Nazionale dei Dottori Agronomi e dei Dottori Forestali.
                        Il Responsabile per la protezione dei dati (c.d. RPD) è l’avv. Maria Vittoria Colonna, mail: mvittoria.colonna@gmail.com, PEC: mariavittoriacolonna@ordineavvocatiroma.org.
                        L’elenco aggiornato dei responsabili e degli incaricati al trattamento è custodito presso la sede del Titolare del trattamento, all’interno del Registro di Privacy.</p>
                        <br></br>
                        </div>
                    </div>
                </PageSection>
            </>
        );
    }
};

export default Privacy;
