import * as React from 'react'
import {PageSection, Title} from '@patternfly/react-core'
import GenericBreadCrumb from 'src/frontend/app/components/GenericBreadCrumb'
import {GenericSpinner, GenericTable} from 'src/backoffice/app/components'
import {ricercaAlboUnicoPF, ricercaAlboUnicoPG} from 'src/processes/Soggetto'
import {getBigIntToConvertToStringSiNo, isEmptyValue} from 'src/utilities/utility'
import {PATH_TO_RICERCA_ALBO_UNICO, PATH_TO_RISULTATI_RICERCA_ALBO_UNICO} from 'src/app/utils/RoutesConstants'

var columnsPF = ['Nome/Cognome', 'Indirizzo', 'Data di nascita', 'Città di nascita', 'PEC']
var columnsSocieta = ['Ragione sociale', 'Forma giuridica', 'P.IVA', 'Codice fiscale azienda']

class RisultatiRicercaAlboUnico extends React.Component<GeneralRisultatiRicercaAlboUnicoProps, any> {
  constructor(props) {
    super(props);
    this.state = {
      soggettiAlboPF: [],
      soggettiAlboPG: [],
      isLoaded: false,
    };
  }

  datiInputRicerca = this.props.location.state?.datiInputRicerca
  flagIsSocieta = this.datiInputRicerca?.flagIsSocieta
  cognome = this.datiInputRicerca?.cognome?.value ?? ""
  ragioneSociale = this.datiInputRicerca?.ragioneSociale?.value ?? ""
  nome = this.datiInputRicerca?.nome?.value ?? ""
  partitaIVA = this.datiInputRicerca?.partitaIVA?.value ?? ""
  codiceFiscale = this.datiInputRicerca?.codiceFiscale?.value ?? ""
  ordineIscrizione = this.datiInputRicerca?.ordineIscrizione?.value ?? ""
  numeroIscrizione = this.datiInputRicerca?.numeroIscrizione?.value ?? ""

  goToRicercaAlbo = (redirectLink: any) => {
    this.props.history.push({
        pathname: redirectLink,
    });
  }

  async componentDidMount() {

    if (this.props.history.location.state === undefined) {
      this.goToRicercaAlbo(PATH_TO_RICERCA_ALBO_UNICO)
    }
    this.setState({isLoaded: false})

    if(this.flagIsSocieta === true) {
      await ricercaAlboUnicoPG(this.ragioneSociale, this.partitaIVA, this.codiceFiscale, this.ordineIscrizione, this.numeroIscrizione)
      .then(async (response: any) => {
        var soggettiPG: any = []
        if (response.data.httpStatus !== 200) {
          throw new Error(response.data.error)
        }
        else {

          await response.data.returnedObject.forEach((soggetto) => {
                soggettiPG.push({
                  id: soggetto.idSoggetto,
                  data: [
                    soggetto?.ragioneSociale ? soggetto?.ragioneSociale : '-',
                    soggetto?.formaGiuridica ? soggetto?.formaGiuridica : '-',
                    soggetto?.partitaIva ? soggetto?.partitaIva : '-',
                    soggetto?.codiceFiscaleAzienda ? soggetto?.codiceFiscaleAzienda : '-',
                ],
                nestedComponent: this.nestedComponent("PG", soggetto)

                })

            })
          await this.setListaSoggetti("PG", soggettiPG)


          await this.setState({isLoaded: true})
        }
      }).catch((err) => {
        console.error("Errore --> ", err)
      })
    } else {
        await ricercaAlboUnicoPF(this.nome, this.cognome, this.codiceFiscale, this.ordineIscrizione, this.numeroIscrizione)
        .then(async (response: any) => {
          var soggettiPF: any = []
          if (response.data.httpStatus !== 200) {
            throw new Error(response.data.error)

          }
          else {

            await response.data.returnedObject.forEach((soggetto) => {

                  soggettiPF.push({
                    id: soggetto.idSoggetto,
                    data: [
                      soggetto?.nome + " " + soggetto?.cognome,
                      (soggetto.indirizzoResidenza ? soggetto.indirizzoResidenza : "") + (soggetto.numeroCivico ? ", " + soggetto.numeroCivico : ""),
                      soggetto?.dataNascita,
                      soggetto?.cittaNascita,
                      soggetto?.pec
                  ],
                  nestedComponent: this.nestedComponent("PF", soggetto)
                  })
              })
            await this.setListaSoggetti("PF", soggettiPF)
            await this.setState({isLoaded: true})
          }
        }).catch((err) => {
          console.error("Errore --> ", err)
        })
    }
  }

  setListaSoggetti = (tipologia: any, soggetti: any) => {
    if(tipologia === "PF")
      this.setState({soggettiAlboPF: soggetti})
    else
      this.setState({soggettiAlboPG: soggetti})
  }

  /**
     * Restituisce il nestedComponent in funzione del tipo anagrafe (PF o PG)
     * @param {*} soggetto
     * @returns
  */
  nestedComponent = (tipoAnagrafe, soggetto) => {

      if (tipoAnagrafe === 'PF') {
          return [
              {
                  label: 'Sezione',
                  value: !isEmptyValue(soggetto.codiceSezioneRichiesta) ? soggetto?.codiceSezioneRichiesta : '-'
              },
              {
                  label: 'Titolo',
                  value: !isEmptyValue(soggetto.codiceTitoloRichiesto) ? soggetto?.codiceTitoloRichiesto : '-'
              },
              /* Serve a creare una colonna vuota */
              {
                  label: '',
                  value: ''
              },
              {
                  label: 'Ordine iscrizione',
                  value: !isEmptyValue(soggetto.ordineTerritorialeCompetente) ? soggetto?.ordineTerritorialeCompetente : '-'
              },
              {
                  label: 'N. Iscrizione',
                  value: !isEmptyValue(soggetto.numeroIscrizioneOrdine) ? soggetto?.numeroIscrizioneOrdine : '-'
              },
              {
                  label: 'Data Iscrizione',
                  value: !isEmptyValue(soggetto.dataIscrizioneOrdine) ? soggetto?.dataIscrizioneOrdine : '-'
              },
              {
                  label: 'Dipendente pubblico',
                  value: !isEmptyValue(soggetto.flagDipendentePubblicaAmministrazione) ? getBigIntToConvertToStringSiNo(soggetto?.flagDipendentePubblicaAmministrazione) : 'No'
              },
              {
                  label: 'Sospeso',
                  value: !isEmptyValue(soggetto.sospeso) ? getBigIntToConvertToStringSiNo(soggetto?.sospeso) : 'No'
              },
              {
                label: 'Radiato',
                value: !isEmptyValue(soggetto.radiato) ? getBigIntToConvertToStringSiNo(soggetto?.radiato) : 'No'
              },
          ]
      } else if (tipoAnagrafe === 'PG') {

          return [
              {
                  label: 'Ordine iscrizione',
                  value: !isEmptyValue(soggetto.ordineTerritorialeCompetente) ? soggetto?.ordineTerritorialeCompetente : '-'
              },
              {
                  label: 'N. Iscrizione',
                  value: !isEmptyValue(soggetto.numeroIscrizioneOrdine) ? soggetto?.numeroIscrizioneOrdine : '-'
              },
              {
                  label: 'Data Iscrizione',
                  value: !isEmptyValue(soggetto.dataIscrizioneOrdine) ? soggetto?.dataIscrizioneOrdine : '-'
              },
              {
                  label: 'Indirizzo sede legale',
                  value: !isEmptyValue(soggetto.indirizzoSedeLegale) ? soggetto?.indirizzoSedeLegale : '-'
              },
              {
                  label: 'Telefono sede legale',
                  value: !isEmptyValue(soggetto.telefonoSedeLegale) ? soggetto?.telefonoSedeLegale : '-'
              },
              {
                  label: 'Fax sede legale',
                  value: !isEmptyValue(soggetto.faxSedeLegale) ? soggetto?.faxSedeLegale : '-'
              },
              {
                  label: 'Email sede legale',
                  value: !isEmptyValue(soggetto?.emailSedeLegale) ? soggetto?.emailSedeLegale : '-'
              },
              {
                  label: 'PEC sede legale',
                  value: !isEmptyValue(soggetto.emailPecSedeLegale) ? soggetto?.emailPecSedeLegale : '-'
              },
              {
                  label: 'Sito sede legale',
                  value: !isEmptyValue(soggetto.indirizzoWebSedeLegale) ? soggetto?.indirizzoWebSedeLegale : '-'
              },
              {
                  label: 'Tipo',
                  value: "Responsabile legale"
              },
              {
                  label: 'Titolo',
                  value: !isEmptyValue(soggetto.titoloComposizione )  ? soggetto?.titoloComposizione : '-'
              },
              {
                  label: 'Nome/Cognome',
                  value: soggetto?.nome + " " + soggetto?.cognome,
              },
              {
                  label: 'Iscrizione Albo',
                  value: !isEmptyValue(soggetto.iscrittoAlbo ) ? soggetto?.iscrittoAlbo : '-'
              },
              {
                  label: 'R. Disciplinari',
                  value: !isEmptyValue(soggetto.rDisciplinari ) ? soggetto?.rDisciplinari : 'No'
              },
              {
                  label: 'Provvedimenti disciplinari alla STP in corso ',
                  value: !isEmptyValue(soggetto.provvedimentiDisciplinariInCorso ) ? getBigIntToConvertToStringSiNo(soggetto?.provvedimentiDisciplinariInCorso) : 'No'
              },
              ,
              {
                  label: 'Sospesa',
                  value: !isEmptyValue(soggetto.sospeso) ? getBigIntToConvertToStringSiNo(soggetto?.sospeso) : 'No'
              },
              {
                label: 'Radiata',
                value: !isEmptyValue(soggetto.radiato) ? getBigIntToConvertToStringSiNo(soggetto?.radiato) : 'No'
              },
        ]
      } else {
          throw new Error("Tipo anagrafe non riconosciuto: " + tipoAnagrafe);
      }
  }

  getColumns = (isSocieta) => {
    if (isSocieta === true)
      return columnsSocieta

    else
      return columnsPF
  }

  getRows = (isSocieta) => {
    if (isSocieta === true)
      return this.state.soggettiAlboPG

    else
      return this.state.soggettiAlboPF
  }

  getResultsNumber = (isSocieta) => {
    if (isSocieta === true)
      return this.state.soggettiAlboPG.length

    else
      return this.state.soggettiAlboPF.length
  }

  render() {
    return (

        <PageSection>
          <div className='container register-container'>
            <GenericBreadCrumb paths={[{ label: "Ricerca albo unico", link: PATH_TO_RICERCA_ALBO_UNICO }, { label: "Risultati ricerca", link: PATH_TO_RISULTATI_RICERCA_ALBO_UNICO }]} /><br></br>
            <Title headingLevel="h1">Cerca nell'Albo Unico</Title>
            { this.state.isLoaded ?
              <div>
                  <div className="row">
                    <div className='col'>
                      {this.getResultsNumber(this.flagIsSocieta) === 1 ?
                        <Title headingLevel="h4"> Trovata <strong>{this.getResultsNumber(this.flagIsSocieta)}</strong> corrispondenza </Title>
                        :
                        <Title headingLevel="h4"> Trovate <strong>{this.getResultsNumber(this.flagIsSocieta)}</strong> corrispondenze </Title>
                      }
                    </div><br></br><br></br>
                    <div className='col' style={{ display: 'flex', justifyContent: 'flex-end' }}>
                      <a href={PATH_TO_RICERCA_ALBO_UNICO}> Nuova ricerca</a>
                    </div>
                  </div>
                  <GenericTable
                    key={'tabellaRisultati'}
                    columns={this.getColumns(this.flagIsSocieta)}
                    rows={this.getRows(this.flagIsSocieta)}
                    hideCsv
                    hideFilters
                    hidePagination
                    autoWidthColumns
                    hideActionColumn
                  />
                  <br></br>

              </div>
          : <GenericSpinner></GenericSpinner> }
          </div>
        </PageSection>
    );
  }
}
export default RisultatiRicercaAlboUnico;

export declare interface GeneralRisultatiRicercaAlboUnicoProps {
  flagIsSocieta?: boolean;
  location?: any;
  history?: any;
}
