import React, {useEffect, useMemo, useState} from 'react'
import {Alert, Form} from 'design-react-kit'
import GenericButton from 'src/frontend/app/components/GenericButton'
import GenericCard from 'src/frontend/app/components/GenericCard'
import {getDefaultAxiosConfig, getFormazioneResourceApi} from 'src/app/utils/ManagerRestGateway'
import {generateRequestSort} from 'src/app/utils/ApiUtils'
import {useSdaf} from 'src/hooks/useReqOptions'
import FormEsperienzaScientifica from 'src/backoffice/app/pages/anagrafica/formFormatori/FormEsperienzaScientifica'
import FormEsperienzaDidattica from 'src/backoffice/app/pages/anagrafica/formFormatori/FormEsperienzaDidattica'
import FormEsperienzaProfessionale from 'src/backoffice/app/pages/anagrafica/formFormatori/FormEsperienzaProfessionale'
import FormAttivitaFormative from 'src/backoffice/app/pages/anagrafica/formFormatori/FormAttivitaFormative'
import FormAltriTitoli from 'src/backoffice/app/pages/anagrafica/formFormatori/FormAltriTitoli'
import {responseMapperFormatore} from '../form-utils'
import GenericInput from 'src/frontend/app/components/Form/Input'
import createWrpInput from 'src/frontend/app/components/Form/WrpInput'

const FormDatiFormatori: React.FunctionComponent<FormDatiFormatoriProps> = ({ dataRef, tabNumber, title, handleInput, onInitInput, viewOnly }) => {
  const objKey = '' as string
  const WrpInput = useMemo(() => createWrpInput({ objKey, onInit: onInitInput, onChange: handleInput, viewOnly }), [])
  const WrpInputFormatore = useMemo(() => createWrpInput({ objKey, onInit: onInitInput, onChange: handleInput, viewOnly, realId: 'hasRespFormConflitti' }), [])
  const [errors, setErrors] = useState<any>([])
  const [ricercaCF, setRicercaCF] = useState('')
  const [formatori, setFormatori] = useState<any>([])
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string>('')

  useEffect(() => {
    if (dataRef.formatori) {
      setFormatori(dataRef.formatori)
    }
  }, [dataRef.formatori])

  const sdaf = useSdaf()

  const ricercaFormatore = async () => {
    setErrorMessage('')
    try {
      setIsLoading(true)
      const { data } = await getFormazioneResourceApi().msfoFormazioneDettaglioFormatoriGet(
        ['stato::EQUAL::2', 'cod_fisc::LIKE::' + ricercaCF],
        -1,
        -1,
        generateRequestSort('', ''),
        getDefaultAxiosConfig()
      )


      if (data.items[0]) {
        const form = data.items[0]

        const obj = await responseMapperFormatore(form)

        const { id, nome, cognome, titoli, att_formative, esp_prof, esp_didattica, esp_scientifica, cod_fisc } = obj

        const formatore = {
          id: id,
          nome: nome,
          cognome: cognome,
          cod_fisc,
          titoli: titoli,
          att_formative: att_formative,
          esp_prof: esp_prof,
          esp_didattica: esp_didattica,
          esp_scientifica: esp_scientifica,
          hasRespFormConflitti: false,
        }

        setFormatori([...formatori, formatore])
        handleInput('formatori', [...formatori, formatore])
      } else {
        setErrorMessage('Formatore non trovato, riprova')
      }

      setIsLoading(false)
    } catch {
      setErrorMessage('Formatore non trovato, riprova')
      setIsLoading(false)
    }
  }

  return (
    <GenericCard cardHeader={title}>
      <Form>
        {!isLoading && !viewOnly && (
          <>
            <div className="row">
              <div className="col-6">
                <GenericInput
                  id="ricercaCF"
                  type={'text'}
                  placeholder={'CF Formatore'}
                  value={ricercaCF}
                  onChange={(cod, val) => {
                    setRicercaCF(val)
                  }}
                />
              </div>
              <div className="col-6">
                <GenericButton
                  label={'Aggiungi'}
                  onClickEvent={(e) => {
                    ricercaFormatore()
                  }}
                  color={'primary'}
                />
              </div>
            </div>
            {errorMessage.length !== 0 && (
              <div className="row">
                <Alert className="mt-4" color={'danger'}>
                  {errorMessage}
                </Alert>
              </div>
            )}
          </>
        )}

        {formatori.map((formatore, index) => {
          return (
            <GenericCard
              key={index}
              cardHeader={formatore.cf}
              cardBody={
                <>
                  <div className="row">
                    <div className="col-6">
                      <GenericInput isDisabled={true} id={`cognome-${index}`} type={'text'} value={formatore.cognome} />
                    </div>
                    <div className="col-6">
                      <GenericInput isDisabled={true} id={`nome-${index}`} type={'text'} value={formatore.nome} />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-24">
                      <WrpInputFormatore
                        as={GenericInput}
                        id={`hasRespFormConflitti-${index}`}
                        realId="hasRespFormConflitti"
                        type="checkbox"
                        label={'Confermo di aver ricevuto dal formatore la dichiarazione di mancanza di conflitto di interessi**'}
                        suffix="**"
                        data={formatore}
                        errors={errors}
                        objKey={null}
                        onChange={() => {
                          const _formatori = [...formatori]
                          const _formatore = _formatori[index]
                          _formatore.hasRespFormConflitti = !_formatore.hasRespFormConflitti
                          setFormatori(_formatori)
                        }}
                      />
                    </div>
                  </div>
                  <WrpInput as={GenericInput} id="check-formatori" type="hidden" label="" data={dataRef} suffix="**" errors={errors} />

                  <div className="row">
                    <div className="col-12">
                      <FormAltriTitoli title="Altri titoli" data={formatore} errors={errors} viewOnly={true} />
                      <FormAttivitaFormative title="Attività formative svolte" data={formatore} errors={errors} viewOnly={true} />
                      <FormEsperienzaProfessionale title="Esperienza professionale" data={formatore} errors={errors} viewOnly={true} />
                      <FormEsperienzaDidattica title="Esperienza didattica" data={formatore} errors={errors} viewOnly={true} />
                      <FormEsperienzaScientifica title="Esperienza scientifica" data={formatore} errors={errors} viewOnly={true} />
                    </div>
                  </div>
                </>
              }
              cardFooter={
                !viewOnly && <GenericButton
                  label={'Elimina'}
                  color={'danger'}
                  onClickEvent={(e) => {
                    e.preventDefault()
                    const _formatori = formatori.filter((f, i) => i !== index)
                    setFormatori([..._formatori])
                    handleInput('formatori', [..._formatori])
                  }}
                />
              }
            />
          )
        })}
      </Form>
    </GenericCard>
  )
}

interface FormDatiFormatoriProps {
  tabNumber?: number
  dataRef?: any
  title?: string
  handleInput: any
  onInitInput: any
  viewOnly: boolean
}

export default FormDatiFormatori
