/* eslint-disable react/no-direct-mutation-state */
import * as React from 'react'
import {PATH_TO_BO_ANAG_ELENCO_ISCRITTI, PATH_TO_BO_ATTI_DISCIPLINARI_VISUALIZZA} from 'src/app/utils/RoutesConstants'
import GenericSpinner from 'src/backoffice/app/components/GenericSpinner'
import {getDisciplinareById} from 'src/processes/disciplinari/Disciplinari'
import FormListaDisciplinari from './form/FormListaDisciplinari'
import GenericAlert from 'src/frontend/app/components/GenericAlert'
import {convertUrlParamsToObject} from 'src/utilities/utility'
import GenericBreadCrumb from 'src/frontend/app/components/GenericBreadCrumb'

var breadcrumbOptions = [
  { label: "Anagrafica", link: "#" },
  { label: "Elenco iscritti", link: PATH_TO_BO_ANAG_ELENCO_ISCRITTI},
  { label: "Atti disciplinari", link: PATH_TO_BO_ATTI_DISCIPLINARI_VISUALIZZA },
  { label: "Visualizza", link: PATH_TO_BO_ATTI_DISCIPLINARI_VISUALIZZA }
]
class VisualizzaSanzioneDisciplinari extends React.Component<any , any>{
    constructor(props) {
      super(props);
      this.state = {
          prevTab: "",
          idIscrizione: 1, //idIscrizione is passed with props and next will be a GET to take data from db
          message:"",
          isSubmitted:false,
          disciplinari : {},
          errors: [],
      };
  }

  async componentDidMount(): Promise<void> {
    var url = window.location.search?.split("?")[1]
    var urlParams = convertUrlParamsToObject(url)

    if (!urlParams?.id || !urlParams?.idIscrizione || !urlParams?.tipologiaIscrizione || !urlParams?.cf) {
      this.props.history.push(PATH_TO_BO_ANAG_ELENCO_ISCRITTI);
    }
    else {
      let id = urlParams?.id;
      let idIscrizione = urlParams?.idIscrizione
      let tipologiaIscrizione = urlParams?.tipologiaIscrizione
      let cf = urlParams?.cf
      this.setState({ idIscrizione: idIscrizione, tipologiaIscrizione: tipologiaIscrizione, cf: cf, id: urlParams?.id })

      getDisciplinareById(id).then(async (responseDisciplinare: any)=> {
        await this.setState({disciplinari: responseDisciplinare, isLoaded: true});
          let fields = this.state.fields;
          fields = responseDisciplinare;
          await this.setState({ fields: fields, disciplinari:fields,  isLoaded: true });

        }).catch(async () => {
                  setTimeout(() => {
                      this.setState({ message: "C'è stato un errore durante il recupero dei dati", typeMessage: 'danger' })
                  }, 3000);
                  await this.setState({isLoaded: false})
        })
      }
  }

  goToDisciplinari = (redirectLink: any) => {
      this.props.history.push(
          redirectLink + '?cf=' + this.state.cf + '&idIscrizione=' + this.state.idIscrizione + '&tipologiaIscrizione=' + this.state.tipologiaIscrizione
      );
  }

  submitMessageResult = (alert: any) => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    this.setState({ message: alert.messaggio, typeMessage: alert.type })
  }

  render() {

    const { typeMessage, message} = this.state;

    return(
      <div key={"modificaSanzioneDisciplinare"} className='container-fluid'>
          <div className="container-fluid">
            <div className='row'>
              <div className='col-10 offset-1'>
                  {
                  (this.state.isLoaded) ?
                    <div>
                         <div className="row">
                            <div className="col-10 offset-1 mt-5">
                              <GenericBreadCrumb paths={breadcrumbOptions} />
                              <GenericAlert hidden={message === ''} label={message} color={typeMessage}/>
                            </div>
                          </div>
                       <FormListaDisciplinari disciplinari={this.state.disciplinari} prevData={this.state.disciplinari} messaggio={this.submitMessageResult} visualizzaFlag={true} modificaDatiFlag={false} goToDisciplinari={this.goToDisciplinari}/>
                    </div>
                    : <GenericSpinner></GenericSpinner>
                  }
              </div>
            </div>
          </div>
      </div>
    )
  }
};
export declare interface InserisciProps {
  idIscrizione?: any;
  modificaDatiFlag?:any;
  visualizzaFlag?:any;
  disciplinari?:any;
}

export default VisualizzaSanzioneDisciplinari  ;
