import {TextArea} from 'design-react-kit'
import styled from 'styled-components'
import React from 'react'
import InputGroup, {InputGroupProps} from './InputGroup'
import {isEmptyValue} from 'src/utilities/utility'

const TextAreaStyled = styled.div`
  .row-5 {
    label {
      ${(props) => (props.labelBottom ? 'position:relative; top:15px' : '')}
    }
  }

  .form-group {
    width: 100%;
  }
  textarea.is-invalid {
    border-color: #a31f33 !important;
  }
  .textarea-group .form-group {
    margin: 0px;
  }
`

class GenericTextArea extends React.Component<GenericTextAreaProps, any> {
  isMount = false

  onChange = (e) => {
    if (this.props.onChange) {
      this.props.onChange(this.props.id, e.target.value)
    }
  }

  componentDidMount() {
    this.isMount = true
    if (this.props.onInit) {
      this.props.onInit(this)
    }
  }

  componentWillUnmount() {
    this.isMount = false
  }

  render() {
    const rows = this.props.rows ? this.props.rows : 5
    const isInvalid = this.props.invalid || !isEmptyValue(this.props.errorMessage)
    return (
      <TextAreaStyled className={this.props.className} labelBottom={this.props.labelBottom}>
        <InputGroup {...this.props} rows={rows} type={'textarea'} label={''}>
          <TextArea
            hidden={this.props.isHidden}
            defaultValue={this.props.defaultValue}
            placeholder={this.props.placeholder}
            id={this.props.id}
            name={this.props.name}
            className={this.props.className}
            disabled={this.props.isDisabled}
            label={this.props.label}
            onChange={this.onChange}
            normalized={this.props.normalized}
            readOnly={this.props.isReadOnly}
            invalid={isInvalid}
            value={this.props.value || ''}
            rows={rows}
          />
        </InputGroup>
      </TextAreaStyled>
    )
  }
}

export declare interface GenericTextAreaProps extends InputGroupProps {
  value?: any
  defaultValue?: any
  onChange?: any
  normalized?: any
  isReadOnly?: boolean
  invalid?: boolean
  onInit?: any
  isDisabled?: boolean
  labelBottom?: boolean
}

export default GenericTextArea
