import React from 'react'
import {Alert, Label, Spinner} from 'design-react-kit'
import GenericCard from 'src/frontend/app/components/GenericCard'
import GenericButton from 'src/frontend/app/components/GenericButton'

const FormDatiConferma: React.FunctionComponent<FormDatiConfermaProps> = ({ title, onConfirm, loading, resultData }) => {
  return (
    <GenericCard cardHeader={title}>
      {resultData?.success !== 'success' && !loading && (
        <>
          <div className={'text'}>
            <Label>Per confermare e inviare la domanda cliccare "Invia Richiesta".</Label>
          </div>
          {resultData === null && <div className="col-lg-8 offset-lg-4 p-4">
            <GenericButton label="Invia Richiesta" color={'primary'} onClickEvent={onConfirm}></GenericButton>
          </div>}
        </>
      )}
      {loading && (
        <div className={'wrap-loader'}>
          <Spinner active={true} label={''} />
        </div>
      )}
      {resultData !== null && (
        <Alert color={resultData.success ? 'success' : 'danger'}>{resultData.success ? 'La richiesta è stata inviata' : resultData.error}</Alert>
      )}
    </GenericCard>
  )
}

interface FormDatiConfermaProps {
  title: string
  onConfirm: any

  loading: boolean

  resultData: any
}

export default FormDatiConferma
