import {getAnagrafeResourcesApi, getDefaultAxiosConfig} from 'src/app/utils/ManagerRestGateway'

/**
 * GET Elenco tipologie società
 * @returns
 *
 */
export async function getTipologieSocieta() {
  try {
    const response = await getAnagrafeResourcesApi().msgaAnagrafeFoListaTipologieSocietaGet(getDefaultAxiosConfig());
    return response;
  } catch (err) {
    console.error("Errore durante la chiamata msgaAnagrafeListaTipologieSocietaGet - " + err);
    throw new Error("Errore durante la chiamata msgaAnagrafeListaTipologieSocietaGet - " + err);
  }
}
