import React, {useEffect, useMemo} from 'react'
import {Form} from 'design-react-kit'
import useProvince from 'src/hooks/useProvince'
import createWrpInput from 'src/frontend/app/components/Form/WrpInput'
import {getValueAtPath} from 'src/utilities/utility'

import GenericCard from 'src/frontend/app/components/GenericCard'
import GenericInput from 'src/frontend/app/components/Form/Input'
import GenericSelect from 'src/frontend/app/components/Form/Select'

const FormDatiResponsabileAF: React.FunctionComponent<FormDatiResponsabileAFProps> = ({ title, data, handleInput, onInitInput, errors, viewOnly }) => {
  const objKey = 'datiResponsabileAF'
  const WrpInput = useMemo(() => createWrpInput({ objKey, onInit: onInitInput, onChange: handleInput, viewOnly }), [])

  const provincia = getValueAtPath(data, `${objKey}.provincia`)?.value
  const comune = getValueAtPath(data, `${objKey}.comune`)?.value
  const nazione = getValueAtPath(data, `${objKey}.nazione`)?.value
  const { province, comuni, nazioni } = useProvince(provincia, comune, () => {
    if (data[objKey]?.provincia?.value && data[objKey]?.provincia?.value !== 'EEP') {
      handleInput(`${objKey}.comune`, '')
    } else {
      handleInput(`${objKey}.comune`, { label: 'Stato Estero', value: 'EEP' })
    }
  })

  useEffect(() => {
    if (nazione !== 'Italia') {
      handleInput(`${objKey}.provincia`, { label: 'Stato Estero', value: 'EEP' })
      handleInput(`${objKey}.comune`, { label: 'Stato Estero', value: 'EEP' })
    } else if (provincia && nazione === 'Italia' && provincia === 'EEP') {
      handleInput(`${objKey}.provincia`, '')
      handleInput(`${objKey}.comune`, '')
    }
  }, [nazione])

  return (
    <GenericCard cardHeader={title}>
      <Form>
        <div className="row">
          <div className="col-6">
            <WrpInput as={GenericInput} id="nome" type="text" label="Nome" suffix="*" data={data} errors={errors} />
          </div>
          <div className="col-6">
            <WrpInput as={GenericInput} id="cognome" type="text" label="Cognome" suffix="*" data={data} errors={errors} />
          </div>
          <div className="col-6">
            <WrpInput as={GenericInput} id="codiceFiscale" type="text" label="Codice Fiscale" suffix="*" data={data} errors={errors} />
          </div>
          <div className="col-6">
            <WrpInput as={GenericInput} id="luogoNascita" type="text" label="Luogo di nascita" suffix="*" data={data} errors={errors} />
          </div>
          <div className="col-6">
            <WrpInput as={GenericInput} id="dataNascita" type="date" label="Data di nascita" suffix="*" data={data} errors={errors} />
          </div>
          <div className="col-6">
            <WrpInput
              as={GenericSelect}
              id={'nazione'}
              placeholder="Seleziona Nazione"
              label="Nazione"
              suffix="*"
              defaultOptions={nazioni}
              data={data}
              errors={errors}
            />
          </div>
          <div className="col-6">
            <WrpInput
              as={GenericSelect}
              id={'provincia'}
              placeholder="Seleziona Provincia"
              label="Provincia"
              suffix="*"
              defaultOptions={province}
              data={data}
              errors={errors}
              isDisabled={data[objKey]?.nazione?.label !== 'Italia' || viewOnly}
            />
          </div>
          <div className="col-6">
            <WrpInput
              as={GenericSelect}
              id={'comune'}
              placeholder="Seleziona Comune"
              label="Comune"
              suffix="*"
              defaultOptions={comuni}
              data={data}
              errors={errors}
              isDisabled={data[objKey]?.nazione?.label !== 'Italia' || viewOnly}
            />
          </div>
          <div className="col-4">
            <WrpInput as={GenericInput} id="indirizzo" type="text" label="Indirizzo" suffix="*" data={data} errors={errors} />
          </div>
          <div className="col-4">
            <WrpInput as={GenericInput} id="cap" type="text" label="Cap" suffix="*" data={data} errors={errors} />
          </div>
          <div className="col-4">
            <WrpInput as={GenericInput} id="email" type="text" label="Email" suffix="*" data={data} errors={errors} />
          </div>
        </div>
      </Form>
    </GenericCard>
  )
}

interface FormDatiResponsabileAFProps {
  data: any
  errors: any
  title: string
  handleInput: any
  onInitInput?: any
  viewOnly?: boolean
}

export default FormDatiResponsabileAF
