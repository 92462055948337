import {Card, CardBody, CardExpandableContent, CardFooter, CardHeader, CardTitle} from '@patternfly/react-core'
import React from 'react'

class GenericCard extends React.Component<GeneralCardProps, any>{
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      isChecked: false,
      isExpanded: props.isExpanded,
      isExpandibleCard: props.isExpandibleCard,
      body: props.body,
      header: props.header,
      footer: props.footer,
      isRounded: props.isRounded,
      isHidden: props.isHidden,
      modificaDatiFlag: props.modificaDatiFlag,
      visualizzaFlag: props.visualizzaFlag,
      data: {},
      getSelectedValue:props.getSelectedValue,
      polizza:props.polizza,
      className:props.className
    };
  }

  onToggle = (isOpen: boolean) => {
    this.setState({ isOpen: isOpen });
  };

  onSelect = () => {
    this.setState({ isOpen: false });
  };

  onClick = (checked: boolean) => {
    this.setState({ isChecked: checked });
  };

  onExpand = (event: React.MouseEvent, id: string) => {
    // eslint-disable-next-line no-console
    this.setState({ isExpanded: !this.state.isExpanded });
  };

  render() {
    const { isExpandibleCard, isRounded } = this.state;
    return (
      (isExpandibleCard) ? (
        <>
          <Card id={this.props.id} isRounded isExpanded={this.state.isExpanded} hidden={this.props.isHidden} className={this.props.className}>
            <CardHeader
              onExpand={this.onExpand}
              toggleButtonProps={{
                id: 'toggle-button1',
                'aria-label': 'Details',
                'aria-labelledby': 'expandable-card-title toggle-button1',
                'aria-expanded': this.state.isExpanded
              }}
            >
              <CardTitle id="expandable-card-title">{this.props.header}</CardTitle>
            </CardHeader>
            <CardExpandableContent>
              <CardBody>{this.props.body}</CardBody>
              <CardFooter>{this.props.footer}</CardFooter>
            </CardExpandableContent>
          </Card>
        </>
      ) :
      (<Card isRounded={isRounded} hidden={this.props.isHidden} className={this.props.className}>
        <CardTitle>{this.props.header}</CardTitle>
        <CardBody>{this.props.body}</CardBody>
        <CardFooter>{this.props.footer}</CardFooter>
      </Card>)
    );
  }
}

export declare interface GeneralCardProps {
  isExpandibleCard?: any;
  body?: any;
  header?: any;
  footer?: any;
  isRounded?: boolean;
  isHidden?: boolean;
  isExpanded?: boolean;
  modificaDatiFlag?:any;
  visualizzaFlag?:any;
  data?:any;
  getSelectedValue?:any;
  polizza?:any;
  className?:any;
  id?: any;
}

export default GenericCard;
