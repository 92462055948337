import {convertFlagSiNo} from 'src/utilities/utility'
import {ClauseBuilder, GraphqlBuilder, GraphqlGroupBuilder} from 'src/utilities/graphqlUtilities'
import {getAnagrafeResourcesApi, getDefaultAxiosConfig} from 'src/app/utils/ManagerRestGateway'
import {TokenStore} from 'src/keycloak/jwt/TokenStore'
import {findSTPByPIva} from 'src/processes/Soggetto'

export async function elencoOperatoriSTPList(codiceProvinciaResidenza, globalSearchParam, itemPageCount, listaStati, currentPage, filterParams, sortParam) {

    let index = currentPage;
    let limit = itemPageCount;
    var specificGroup = Object.keys(TokenStore?.getInstance()?.getCurrentAccount()?.accounts)[0]
    var genericGroup = TokenStore.getInstance().getCurrentAccount().accounts[specificGroup]?.genericGroup
    var tipologiaIscrizione
    var pIva = specificGroup.split("_")[1]
    var codiceFiscaleAzienda
    var codiceFiscaleResponsabileStp

    var soggettoSTP = await findSTPByPIva(pIva)
    if(soggettoSTP?.data){
        codiceFiscaleAzienda = soggettoSTP.data.codiceFiscaleAzienda
        codiceFiscaleResponsabileStp = soggettoSTP.data.codiceFiscaleResponsabileStp
    }

    //setto la tipologia iscrizione in base al genericGroup del currentAccount
    if (genericGroup === 'STP') {
        tipologiaIscrizione = 3
    }

    // Valorizzo i parametri a partire dai filtri di ricerca
    if (filterParams === undefined || filterParams.length === 0)
        filterParams = [{ filterParamValue: "" }, { filterParamValue: "" }]
    const codiceFiscale = filterParams[0]?.filterParamValue
    const cognomeNome = filterParams[1]?.filterParamValue

    // Compongo la query query jpa in graph join
    const queryGraphContent = `{
        cf,
        codiceFiscaleAzienda,
        cognome,
        nome,
        tipoAnagrafe,
        pec,
        formaGiuridica,
        ragioneSociale,
        idIscrizione,
        ordineTerritorialeCompetente,
        statoIscrizione,
        tipologiaIscrizione,
        numeroIscrizioneOrdine,
        flagIscrizioneEpap
    }`;

    // Compongo la query di select, con gli eventuali order by
    const selectQuery = `{
        cf${sortField(sortParam?.codiceFiscale)},
        codiceFiscaleAzienda,
        cognome${sortField(sortParam?.cognomeNomeRagioneSociale)},
        nome${sortField(sortParam?.cognomeNomeRagioneSociale)},
        tipoAnagrafe${sortField(sortParam?.tipologia)},
        pec,
        formaGiuridica,
        ragioneSociale${sortField(sortParam?.cognomeNomeRagioneSociale)},
        idIscrizione${sortField(sortParam?.nIscrizione)},
        ordineTerritorialeCompetente,
        tipologiaIscrizione,
        statoIscrizione${sortField(sortParam?.stato)},
        numeroIscrizioneOrdine,
        flagIscrizioneEpap
    }`;

    var globalSearchQueryGraph = new GraphqlBuilder()
        .group(
            new GraphqlGroupBuilder()
                .or([
                    new ClauseBuilder().eq("codiceFiscaleStpOperatore", codiceFiscaleAzienda),
                    new ClauseBuilder().eq("codiceFiscaleResponsabileStp", codiceFiscaleResponsabileStp),
                    new ClauseBuilder().eq("cf", codiceFiscaleResponsabileStp),
                ])
                .in("tipologiaIscrizione", tipologiaIscrizione)
                .buildAND()
        )
        .group(
            new GraphqlGroupBuilder()
                .like("nome", globalSearchParam)
                .like("cognome", globalSearchParam)
                .like("cf", globalSearchParam)
                .buildOR()
        )
        .build();

    var filterSearchQueryGraph = new GraphqlBuilder()
        .group(
            new GraphqlGroupBuilder()
                .or([
                    new ClauseBuilder().like("nome", cognomeNome),
                    new ClauseBuilder().like("cognome", cognomeNome),
                    new ClauseBuilder().like("cognome", cognomeNome),
                ])
                .or([
                    new ClauseBuilder().eq("codiceFiscaleStpOperatore", codiceFiscaleAzienda),
                    new ClauseBuilder().eq("codiceFiscaleResponsabileStp", codiceFiscaleResponsabileStp),
                    new ClauseBuilder().eq("cf", codiceFiscaleResponsabileStp),
                ])
                .like("cf", codiceFiscale)
                .in("tipologiaIscrizione", tipologiaIscrizione)
                .buildAND()
        )
        .build();

    var isFilterParamNotEmpty = filterParams.map(a => a.filterParamValue !== "").includes(true)

    let whereCondition = globalSearchParam !== "" && globalSearchParam !== undefined ?
        `${globalSearchQueryGraph}` :
        isFilterParamNotEmpty && filterParams !== undefined ?
            `${filterSearchQueryGraph}` :
               `{ OR: [
                    { codiceFiscaleStpOperatore: {EQ: "${codiceFiscaleAzienda}"} }, 
                    { cf: {EQ: "${codiceFiscaleResponsabileStp}"} },
                    { codiceFiscaleResponsabileStp: {EQ: "${codiceFiscaleResponsabileStp}"} }],
                    tipologiaIscrizione: {IN: [${tipologiaIscrizione}] }   
            }`
        ;

    // Costruisco la query jpa da passare al server
    let jpaQuery = `{
        IscrittiEntityViews(
            page:{start: ${index}, limit: ${limit}}
            where: ${whereCondition}
        )
        {
            pages
            total
            select ${selectQuery}
        }
    }`;

    /*
    Questa è una query di esempio che restituisce un oggetto Paged<Soggetto>.
    L'oggetto Paged contiene nei campi:

    index: è la pagina corrente che parte da 1
    size: il numero di elementi in pagina
    totalCount: è il numero totale di elementi trovati dalla query
    pageCount: il numero totale di pagine da scorrere se il numero di elementi per pagina è size
    content: i campi richiesti in output dall'entità Soggetto eventualmente collegati in join sull'entità che serve, parametrizzati in {queryGraphContent}
    */

    //query da inviare all'endpoint graph specifico che accetta in input la query graphjpa da eseguire
    //e restituisce in ouput un oggetto paginato con la lista delle entità.
    //occorre ottimizzare il codice
    let queryGraph = `query allAnagrafeIscrizioniByFilter($pageRequest: PagedRequestDtoInput) {
        allAnagrafeIscrizioniByFilter(pageRequest: $pageRequest){
            index
            size
            totalCount
            pageCount
            content ${queryGraphContent}
        }
    }`;

    const json = {
        operationName: "allAnagrafeIscrizioniByFilter",
        query: queryGraph,
        variables: {
            "pageRequest": {
                "index": index,
                "size": limit,
                "jpsQuery": jpaQuery
            }
        }
    };

    var iscrizioneEpap

    var rows = await getAnagrafeResourcesApi().msgaAnagrafeElencoIscrizioniPost(json.variables.pageRequest, getDefaultAxiosConfig()).then(response => {
        var rowsNames
        var responseData = response.data
        if (response?.data?.errors)
            throw new Error(response.data.errors[0].message)
        var responseData = response.data
        if (response.data.errors)
            throw new Error(response.data.errors[0].message)
        rowsNames = responseData.content.map((field) => (
            iscrizioneEpap = convertFlagSiNo(field.flagIscrizioneEpap),
            {
                data: [
                    field.cf,
                    field.cognome + " " + field.nome,
                ]
            }))
        var elencoIscrittiArrayRemapped = {
            index: responseData.index,
            pageCount: responseData.pageCount,
            size: responseData.size,
            totalCount: responseData.totalCount,
            content: rowsNames
        }
        return elencoIscrittiArrayRemapped
    }).catch((err) => {
        throw new Error("Errore durante la get degli iscritti all'ordine " + err);
    })
    return JSON.stringify(rows)
}

/**
 * Se array = [LE] ==> [\"LE\"]
 * Se array = [LE,MI] ==> [\"LE\",\"MI\",]
 */
function inClauseFromArray(arrayContent) {
    var array = "IN: [";
    arrayContent.map((e) => {
        array = array + "\"" + e + "\",";
    });
    array = array.substring(0, array.length - 1);
    array = array + "]";
    return array;
}

function sortField(sortDirection) {
    if (sortDirection)
        return "(orderBy: " + sortDirection?.toUpperCase() + ")"
    else
        return ""
}
