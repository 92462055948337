import * as React from 'react'
import {PageSection} from '@patternfly/react-core'
import GenericInput from 'src/frontend/app/components/GenericInput'
import GenericSelect from 'src/frontend/app/components/GenericSelect'
import GenericButton from '../components/GenericButton'
import GenericHorizontalTab from '../components/GenericHorizontalTab'
import GenericCard from '../components/GenericCard'
import GenericBreadCrumb from 'src/frontend/app/components/GenericBreadCrumb'
import {Form} from 'design-react-kit'
import {
    PATH_TO_HOME,
    PATH_TO_RICERCA_ALBO_UNICO,
    PATH_TO_RISULTATI_RICERCA_ALBO_UNICO
} from 'src/app/utils/RoutesConstants'
import {
    convertArrayToObject,
    inputRequiredValidation,
    replaceStartWordAndLowerCaseFirstLetter
} from 'src/utilities/utility'
import GenericAlert from '../components/GenericAlert'
import {listLegendaCampiObbligatori} from 'src/processes/Costanti'
import {getOrdiniPermitAll} from 'src/processes/Ordini'

var breadcrumbOptions: any = [
    { label: 'Home', link: PATH_TO_HOME },
    { label: 'Ricerca iscritti all\'albo', link: PATH_TO_RICERCA_ALBO_UNICO }
]

class RicercaIscrittiAlboUnico extends React.Component<any, any>{

    constructor(props) {
        super(props);
        this.state = {
            currentTab: 0,
            isLoaded: false,
            message: "",
            typeMessage: "default"
        };
    }

    async componentDidMount(): Promise<void> {

        //chiamata al servizio che restituisce tutti gli ordini
        await getOrdiniPermitAll().then((response: any) => {
            var ordiniTerritoriali = [{ label: '', value: '' }];
            if (response) response.data.map((ordine, id) => {

                ordiniTerritoriali[id] = {
                    label: ordine['denominazioneGruppo'],
                    value: ordine['codiceProvinciaResidenza'],
                };
            })
            this.setState({ listaOrdini: ordiniTerritoriali, isLoaded: true })
        })
    }

    /**
     * Ottiene l'indice del tab corrente
     * @param index
     */
    getCurrentTab = index => {
        this.setState({ message: "", typeMessage : "default", currentTab: index })
    }

    /**
     * Al change dei campi del form, setta il valore selezionato nello stato
     * @param event
     */
    handleChange = event => {
        const { name, value } = event.target ? event.target : event;
        if (event?.target === undefined) {
            this.setState((prevState) => ({
                formValues: {
                    ...prevState.formValues,
                    ["ordineIscrizione"]: value,
                },
            }));
        } else {
            this.setState((prevState) => ({
                formValues: {
                    ...prevState.formValues,
                    [name]: value,
                },
            }));
        }
    }

    checkForm(param: any): boolean {

        if (this.state?.currentTab === 0) {
            if (param.ordineIscrizione?.value !== "" || param.cognome?.value !== "")
                return true
            else
                return false
        }

        else
            return true
    }

    /**
     * Al submit valida i campi e fa la redirect con i dati
     * @param event
     */
    handleSubmit = event => {
        /*--------------------------------------------- Validazione campi obbligatori per Persona Fisica ------------------------------------------------*/
        if (this.state?.currentTab === 0){
            var requiredFields = [
                { value: event.target.idordineIscrizione.value, label: "Ordine iscrizione" },
                { value: event.target.cognome.value, label: "Cognome" },
            ]

            var errorRequired: any = inputRequiredValidation(requiredFields)
            if (errorRequired !== undefined && errorRequired !== "") {
                event.preventDefault()
                this.setState({ message: errorRequired.messaggio.replace(',', ' o '), typeMessage: errorRequired.typeMessage })
            }
        }
        /*-----------------------------------------------------------------------------------------------------------------------------------------------*/

        event.preventDefault()
        var formData: any = []

        if (this.state?.currentTab === 0) {
            if (this.state.formValues?.ordineIscrizione !== undefined) {
                event.target.idordineIscrizione.value = this.state.formValues.ordineIscrizione
                event.target.cognome.value = ""
            }
            else {
                event.target.idordineIscrizione.value = ""
            }

            if (this.state.formValues?.cognome !== undefined) {
                event.target.cognome.value = this.state.formValues.cognome
                event.target.idordineIscrizione.value = ""
            }
            else {
                event.target.cognome.value = ""
            }
        }
        else {
            if (this.state.formValues?.ordineIscrizione !== undefined) {
                event.target.idordineIscrizione.value = this.state.formValues.ordineIscrizione
            }
            else {
                event.target.idordineIscrizione.value = ""
            }
        }

        const inputElements = document.querySelectorAll('input[type="text"]');

        /**
         *   Recupero tutti gli id degli elementi nel form
         */
        let inputIds = Array.from(inputElements).map(element => element.id);

        /**
         * Se il valore è vuoto lo aggiungo all'array
         */
        inputIds.forEach((elementID) => {
            formData.push({
                id: replaceStartWordAndLowerCaseFirstLetter("id", elementID),
                value: event.target[elementID]?.value
            })
        })

        /**
         * COnverto l'array in dto e faccio il redirect dei dati
         */
        var formDataDTO = convertArrayToObject(formData)

        if (!this.checkForm(formDataDTO)) {
            this.setState({typeMessage: 'danger'})
        }
        else {
            if (this.state.currentTab === 1)
                formDataDTO.flagIsSocieta = true
            else
                formDataDTO.flagIsSocieta = false

            this.props.history.push({
                pathname: PATH_TO_RISULTATI_RICERCA_ALBO_UNICO,
                state: { datiInputRicerca: formDataDTO }
            });
        }

    }

    render() {
        const { formValues, listaOrdini, isLoaded, message, typeMessage } = this.state
        const buttonLabel = this.state?.currentTab === 0 ? "Cerca iscritto" : "Cerca società"

        /**
         * Form che contiene sia i campi per persona fisica che per la società
         */
        const formRicercaIscritto = (
            <>
                <Form onSubmit={e => this.handleSubmit(e)}>
                    <div className='container'>
                        <div className='row mt-5'>
                            <div className='col-12'>
                                {this.state?.currentTab === 0 ?
                                    <GenericInput
                                        id={'idCognome'}
                                        type={'text'}
                                        label={'Cognome *'}
                                        name={"cognome"}
                                        defaultValue={formValues?.cognome}
                                        onChange={e => this.handleChange(e)}
                                        placeholder={'Inserisci il cognome...'}
                                        isRequired />
                                    :
                                    <GenericInput
                                        id={'idRagioneSociale'}
                                        type={'text'}
                                        label={'Ragione sociale'}
                                        name={"ragioneSociale"}
                                        defaultValue={formValues?.ragioneSociale}
                                        onChange={e => this.handleChange(e)}
                                        placeholder={'Inserisci la ragione Sociale...'} />
                                }
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-12'>
                                {this.state?.currentTab === 0 ?
                                    <GenericInput
                                        id={'idNome'}
                                        type={'text'}
                                        label={'Nome'}
                                        name={"nome"}
                                        defaultValue={formValues?.nome}
                                        onChange={e => this.handleChange(e)}
                                        placeholder={'Inserisci il nome...'} />
                                    :
                                    <GenericInput
                                        id={'idPartitaIVA'}
                                        type={'text'}
                                        label={'Partita IVA'}
                                        name={"partitaIVA"}
                                        defaultValue={formValues?.partitaIVA}
                                        onChange={e => this.handleChange(e)}
                                        placeholder={'Inserisci la partita Iva...'} />
                                }
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-12'>
                                <GenericInput
                                    id={'idCodiceFiscale'}
                                    type={'text'}
                                    label={'Codice fiscale'}
                                    name={"codiceFiscale"}
                                    defaultValue={formValues?.codiceFiscale}
                                    onChange={e => this.handleChange(e)}
                                    placeholder={'Inserisci il codice fiscale...'} />
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-12'>
                                {this.state?.currentTab === 0 ?
                                    <GenericSelect
                                        defaultInputValue={formValues?.ordineIscrizione}
                                        elementId={'idordineIscrizione'}
                                        name={"ordineIscrizione"}
                                        onChange={e => this.handleChange(e)}
                                        defaultOptions={listaOrdini}
                                        label={'Ordine iscrizione *'}
                                        placeholder={'Inserisci ordine iscrizione'}
                                        isRequired
                                    />
                                    :
                                    <GenericSelect
                                        defaultInputValue={formValues?.ordineIscrizione}
                                        elementId={'idordineIscrizione'}
                                        name={"ordineIscrizione"}
                                        onChange={e => this.handleChange(e)}
                                        defaultOptions={listaOrdini}
                                        label={'Ordine iscrizione'}
                                        placeholder={'Inserisci ordine iscrizione'}
                                    />
                                }
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-12'>
                                <GenericInput
                                    id={'idNumeroIscrizione'}
                                    type={'text'}
                                    label={'Numero iscrizione'}
                                    name={"numeroIscrizione"}
                                    defaultValue={formValues?.numeroIscrizione}
                                    onChange={e => this.handleChange(e)}
                                    placeholder={'Inserisci il numero iscrizione...'} />
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-12 d-flex justify-content-end'>
                                <GenericButton
                                    type={"submit"}
                                    color={'primary'}
                                    label={buttonLabel} />
                            </div>
                        </div>
                    </div>
                </Form>
            </>
        )

        const tabElements = [
            { id: 0, title: 'Persona fisica', body: formRicercaIscritto },
            { id: 1, title: 'Società', body: formRicercaIscritto }
        ]

        return (
            <PageSection>
                <div className='container p-5'>
                    <GenericBreadCrumb paths={breadcrumbOptions} />
                    <div className='row justify-content-center'>
                        <div className='d-flex justify-content-center mb-4'>
                            <h1 className='fw-bold'>Ricerca albo unico</h1>
                        </div>

                        <div className='col-12 col-md-5 mb-4'>

                            <GenericAlert label={message} hidden={message === ""} color={typeMessage}></GenericAlert>

                            <GenericCard
                                hiddenHeader
                                className='mb-3'
                                cardBody={
                                    <GenericHorizontalTab isLoaded={isLoaded} contents={tabElements} currentTab={e => this.getCurrentTab(e)} />
                                } />

                            <div>{listLegendaCampiObbligatori}</div>

                        </div>
                    </div>

                </div>


            </PageSection>
        );
    }
};

export default RicercaIscrittiAlboUnico;
