import React from 'react'
import {Label, Spinner} from 'design-react-kit'
import GenericCard from 'src/frontend/app/components/GenericCard'
import GenericButton from 'src/frontend/app/components/GenericButton'

const FormDatiConferma: React.FunctionComponent<FormDatiConfermaProps> = ({
                                                                            title,
                                                                            onConfirm,
                                                                            loading
                                                                          }) => {

  return (
    <GenericCard cardHeader={title}>
      {!loading && (
        <>
          <div className={'text'}>
            <Label>Per confermare i dati e inviare il piano formativo clicca su  "Invia Piano Formativo".</Label>
          </div>
          <div className='col-lg-8 offset-lg-4 p-4'>
            <GenericButton label='Invia Piano Formativo' color={'primary'} onClickEvent={onConfirm}></GenericButton>
          </div>
        </>
      )}
      {loading && (
        <div className={'wrap-loader'}>
          <Spinner active={true} label={''} />
        </div>
      )}


    </GenericCard>
  )
}

interface FormDatiConfermaProps {
  title: string;
  onConfirm: any

  loading?: boolean,
}

export default FormDatiConferma
