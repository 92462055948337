import * as React from 'react'
import {Form} from '@patternfly/react-core'
import {GenericTextArea} from '../../../components/GenericTextArea'
import {cancellaPolizzaBO} from 'src/processes/assicurazioni/Assicurazione'
import {PATH_TO_BO_POLIZZE_ELENCO} from 'src/app/utils/RoutesConstants'
import GenericModal from 'src/backoffice/app/components/GenericModal'
import GenericSpinner from 'src/backoffice/app/components/GenericSpinner'
import {inputRequiredValidation} from 'src/utilities/utility'
import {isCurrentMobileDevice} from 'src/utilities/deviceUtility'

export class FormCancellaPolizza extends React.Component<GeneralFormCancellaPolizzaProps, any> {

    constructor(props) {
        super(props);
        this.state = {
            isLoaded: true

        };
      }

    handleSubmit = async event => {
        var requiredFields = [{ value: event.target.idMotivoCancellaPolizza.value, label: 'Motivo' }]
        var errorRequired: any = inputRequiredValidation(requiredFields)
        if (errorRequired !== undefined && errorRequired !== "") {
            event.preventDefault()
            return this.props.messaggio({ messaggio: errorRequired.messaggio, type: errorRequired.type })
        } else {
            await this.setState({isLoaded:false});

            var idPolizza = this.props.idPolizza
            var parametriCancella = {
                idPolizza: idPolizza,
                note: event.target.idMotivoCancellaPolizza.value ? event.target.idMotivoCancellaPolizza.value : "",
            }
            event.preventDefault();
            try {
                event.preventDefault();
                await cancellaPolizzaBO(parametriCancella)
                event.preventDefault();
                this.props.messaggio({ messaggio: "Dati salvati con successo", type: "success" })
                await this.showModal(true, "Messaggio","Polizza cancellata");
                await this.setState({isLoaded:true})

                return false;
            }
            catch(err: any) {
                event.preventDefault();
                await this.setState({isLoaded:true})
                this.showModal(true, "Errore",err.message);
                return false;
            }
        }
    }

    closeModal = () => {
        this.setState({modalOpen:false});
        window.location.href = PATH_TO_BO_POLIZZE_ELENCO;
    }

    showModal = async (booleanValue, title,text) => {
        await this.setState({
            modalOpen:booleanValue,
            modalTitle: title,
            modalText:text,
            isLoaded: true,
        });
    }

    render() {

        return (
            this.state.isLoaded ? <div>
                <Form id='idCancellaPolizza' onSubmit={this.handleSubmit}>
                    <GenericTextArea className={isCurrentMobileDevice() ? 'col-12' : 'w-50' } id={"idMotivoCancellaPolizza"} label={"Motivo"} name={"idMotivoCancella"} placeholder={"Inserire motivo"} ariaLabel={'textarea-idMotivoCancella'} isRequired />
                </Form>
                <GenericModal
                          title={this.state.modalTitle}
                          text={this.state.modalText}
                          modalOpen={this.state.modalOpen}
                          closeButton={this.closeModal}
                  />
            </div>
            : <GenericSpinner/>
        )
    }
}

export declare interface GeneralFormCancellaPolizzaProps {
    messaggio?: any;
    idPolizza?: any;
    idSoggetto?: any;
}
