import * as React from 'react'
import {PageSection, Title} from '@patternfly/react-core'

const FormazioneProfessionale: React.FunctionComponent = () => (
  <PageSection>
    <div className='container register-container'>
      <Title headingLevel="h1" size="lg">
      Formazione Professionale Page Title
      </Title>
    </div>
  </PageSection>
);

export default FormazioneProfessionale;
