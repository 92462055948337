import {useEffect, useState} from 'react'
import {generateRequestFilter, generateRequestSort} from 'src/app/utils/ApiUtils'
import {getDefaultAxiosConfig, getFormazioneResourceApi} from 'src/app/utils/ManagerRestGateway'
import {TokenStore} from 'src/keycloak/jwt/TokenStore'

export const getSdaf = async () => {
  const { data: sdaf } = await getFormazioneResourceApi().msfoFormazioneSettoriDisciplinariGet(getDefaultAxiosConfig())
  const _listaSDAF = sdaf.map((item: any) => {
    const label = `${item.cod || ''} - ${item.titolo || ''}`
    return { ...item, label, value: item.cod }
  })
  return _listaSDAF
}
export const getSdafForUser = async () => {
  const { data: sdaf } = await getFormazioneResourceApi().msfoFormazioneSettoriDisciplinariForUserGet(getDefaultAxiosConfig())
  const _listaSDAF = sdaf.map((item: any) => {
    const label = `${item.cod || ''} - ${item.titolo || ''}`
    return { ...item, label, value: item.cod }
  })
  return _listaSDAF
}
export const useSdaf = () => {
  const [listaSDAF, setListaSDAF] = useState<any>([])

  useEffect(() => {
    getSdaf().then(setListaSDAF).catch(console.error)
  }, [])

  return listaSDAF
}

export const useSdafConCod = () => {
  const [listaSDAF, setListaSDAF] = useState<any>([])

  useEffect(() => {
    getSdaf()
      .then((sdaf) => {
        const lista = []
        for (const sd of sdaf) {
          sd.descrizione = sd.cod + ' - ' + sd.descrizione
          lista.push(sd)
        }
        setListaSDAF(lista)
      })
      .catch(console.error)
  }, [])

  return listaSDAF
}

export const useSdafForUser = () => {
  const [listaSDAF, setListaSDAF] = useState<any>([])

  useEffect(() => {
    getSdafForUser().then(setListaSDAF).catch(console.error)
  }, [])

  return listaSDAF
}

export const useCompetenze = () => {
  const [listaCompetenze, setListaCompetenze] = useState<any>([])
  useEffect(() => {
    const doRequest = async () => {
      const { data: competenze } = await getFormazioneResourceApi().msfoFormazioneListaCompetenzeGet(getDefaultAxiosConfig())
      const _listaCompetenze = competenze.map((item: any) => {
        const label = `${item.cod || ''} - ${item.descrizione || ''} - ${item.norma_rif || ''}`
        return { ...item, label, value: item.cod }
      })
      setListaCompetenze(_listaCompetenze)
    }
    doRequest().catch(console.error)
  }, [])

  return listaCompetenze
}

export const useCompetenzeForUser = () => {
  const [listaCompetenze, setListaCompetenze] = useState<any>([])
  useEffect(() => {
    const doRequest = async () => {
      const { data: competenze } = await getFormazioneResourceApi().msfoFormazioneListaCompetenzeGet(getDefaultAxiosConfig())
      const _listaCompetenze = competenze.map((item: any) => {
        const label = `${item.cod || ''} - ${item.descrizione || ''} - ${item.norma_rif || ''}`
        return { ...item, label, value: item.cod }
      })
      setListaCompetenze(_listaCompetenze)
    }
    doRequest().catch(console.error)
  }, [])

  return listaCompetenze
}

export const getAreeProfessionali = async () => {
  const { data: areeProfessionali } = await getFormazioneResourceApi().msfoFormazioneAreeProfessionaliGet(getDefaultAxiosConfig())
  const _listaAreeProfessionali = areeProfessionali.map((item: any) => {
    return { ...item, label: item.titolo, value: item.cod }
  })
  return _listaAreeProfessionali
}
export const getAreeProfessionaliForUser = async () => {
  const { data: areeProfessionali } = await getFormazioneResourceApi().msfoFormazioneAreeProfessionaliForUserGet(getDefaultAxiosConfig())
  const _listaAreeProfessionali = areeProfessionali.map((item: any) => {
    return { ...item, label: item.titolo, value: item.cod }
  })
  return _listaAreeProfessionali
}
export const useAreeProfessionali = () => {
  const [listaAreeProfessionali, setListaAreeProfessionali] = useState<any>([])
  useEffect(() => {
    getAreeProfessionali().then(setListaAreeProfessionali).catch(console.error)
  }, [])
  return listaAreeProfessionali
}
export const useAreeProfessionaliForUser = () => {
  const [listaAreeProfessionali, setListaAreeProfessionali] = useState<any>([])
  useEffect(() => {
    getAreeProfessionaliForUser().then(setListaAreeProfessionali).catch(console.error)
  }, [])
  return listaAreeProfessionali
}

export const getTipologieAttivita = async () => {
  const { data: tipologieAttivita } = await getFormazioneResourceApi().msfoFormazioneAttivitaFormativeGet(getDefaultAxiosConfig())
  const _listaTipologieAttivita = tipologieAttivita.map((item: any) => {
    return { ...item, label: item.titolo, value: item.cod }
  })
  return _listaTipologieAttivita
}
export const useTipologieAttivita = () => {
  const [listaTipologieAttivita, setListaTipologieAttivita] = useState<any>([])
  useEffect(() => {
    getTipologieAttivita().then(setListaTipologieAttivita).catch(console.error)
  }, [])

  return listaTipologieAttivita
}

export const useTipologieAttivitaEvCatalogo = () => {
  const [listaTipologieAttivita, setListaTipologieAttivita] = useState<any>([])
  useEffect(() => {
    getTipologieAttivita()
      .then((data) => {
        const tips = []
        for (const value of data) {
          if (value.ta_1 === 1 || value.cod === 'CU') {
            tips.push(value)
          }
        }
        setListaTipologieAttivita(tips)
      })
      .catch(console.error)
  }, [])

  return listaTipologieAttivita
}

export const useTipologieAttivitaTa1 = () => {
  const [listaTipologieAttivita, setListaTipologieAttivita] = useState<any>([])
  useEffect(() => {
    getTipologieAttivita()
      .then((data) => {
        const tips = []
        for (const value of data) {
          if (value.ta_1 === 1) {
            tips.push(value)
          }
        }
        setListaTipologieAttivita(tips)
      })
      .catch(console.error)
  }, [])

  return listaTipologieAttivita
}

export const getUserEnte = async () => {
  const { data: userEnte } = await getFormazioneResourceApi().msfoFormazioneEnteForUserGet(getDefaultAxiosConfig())
  return userEnte
}
export const useUserEnte = () => {
  const [userEnte, setUserEnte] = useState<any>([])
  useEffect(() => {
    getUserEnte()
      .then(setUserEnte)
      .catch(() => {
      })
  }, [])

  return userEnte
}

export const getAgenziaFormativa = async () => {
  if (TokenStore.getInstance().isCurrentAccountCONAF()) {
    return null
  } else {
    const { data: userEnte } = await getFormazioneResourceApi().msfoFormazioneAgenziaForUserGet(getDefaultAxiosConfig())
    return userEnte
  }
}

export const useAgenziaFormativa = () => {
  const [agenzia, setAgenzia] = useState<any>(null)
  useEffect(() => {
    getAgenziaFormativa()
      .then((data) => {
        if (data) {
          setAgenzia(data)
        }
      })
      .catch(() => {})
  }, [])

  return agenzia
}

export const flagPivaAgeForm = async (piva) => {
  const filtersToUse = [ { field: 'piva', operator: 'EQUAL', value: piva } ]
    const { data } = await getFormazioneResourceApi().msfoFormazioneAccAgenzieFormativeGet(generateRequestFilter(filtersToUse),1,1,generateRequestSort('',''), getDefaultAxiosConfig())
    if(data?.count > 0) return false // piva già presente
    return true // non esiste Agenzie Formative con quella P iva
}
