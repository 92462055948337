import * as React from 'react'
import {PageSection, Title} from '@patternfly/react-core'
import GenericBreadCrumb from 'src/frontend/app/components/GenericBreadCrumb'
import GenericCard from '../../components/GenericCard'
import GenericButton from '../../components/GenericButton'
import {getPolizzaById} from 'src/processes/assicurazioni/Assicurazione'
import {FormCancellaPolizza} from './formPolizze/FormCancellaPolizza'
import {PATH_TO_BO_POLIZZE_ELENCO, PATH_TO_BO_POLIZZE_ELENCO_CANCELLA} from 'src/app/utils/RoutesConstants'
import GenericPanelAvvisi from '../../components/GenericPanelAvvisi'
import {Icon} from 'design-react-kit'
import {AvvisiUtenteBo} from 'src/processes/assicurazioni/CostantiPolizze'
import GenericAlert from 'src/frontend/app/components/GenericAlert'
import {isCurrentMobileDevice} from 'src/utilities/deviceUtility'


class CancellaPolizza extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.state = {
      message: "",
      typeMessage: "default",
      idPolizza: ""
    };
  }
  goToElencoPolizze = (redirectLink: any) => {
    this.props.history.push({
      pathname: redirectLink,
    });
  }
  componentDidMount() {

    if (this.props.history.location.state === undefined) {
    //   this.goToElencoPolizze('PATH')
    }

    else {
      var idPolizza = this.props.history.location.state.idPolizza

      getPolizzaById(idPolizza).then(async (responsePolizza: any)=> {
           if(responsePolizza === ""){
               alert("Polizza non trovata");
           }
           this.setState({polizza:responsePolizza})
       });
      this.setState({ idPolizza: idPolizza })
    }
    this.setState({isLoaded : true})
  }

  submitMessageResult = (alert: any) => {
    const element = document.getElementById("pageSectionId");
    if (element)
      element.scrollIntoView({ behavior: 'smooth' });
    this.setState({ message: alert.messaggio, typeMessage: alert.type })
  }

  render() {
    const { message, typeMessage, idPolizza, idSoggetto } = this.state



    return (
      <>
        <div className='container-fluid p-5'>
          <PageSection id='pageSectionId'>
            <GenericBreadCrumb paths={[{ label: "Polizze", link: PATH_TO_BO_POLIZZE_ELENCO }, { label: "Elenco polizze", link: PATH_TO_BO_POLIZZE_ELENCO }, { label: "Cancella Polizza", link: PATH_TO_BO_POLIZZE_ELENCO_CANCELLA}]} /><br></br>
            <Title headingLevel="h1" size="4xl">Cancella polizza</Title><br></br>
            <GenericAlert hidden={message === ''} label={message} color={typeMessage}></GenericAlert>

            {   this.state.polizza?.stato === 2 ?
                <>
                <GenericPanelAvvisi open={true} cardHeader={<h4>AVVISO</h4>} cardBody={
                  <div>
                     <Icon
                      className="bg-gray"
                      color=""
                      icon="it-warning-circle"
                      size="lg"
                    />

                        {
                          (this.state.polizza?.tipoRichiesta === 'P' || this.state.polizza?.tipoRichiesta === 'R') &&
                          <>
                            <span className="pf-c-title pf-m-4md pf-u-color-300"> &nbsp; {AvvisiUtenteBo.avviso_cancellazione_p_collettiva}</span>
                          </>
                        }

                        {
                        this.state.polizza?.tipoRichiesta === 'C' &&
                          <>
                            <span className="pf-c-title pf-m-4md pf-u-color-300"> &nbsp;  {AvvisiUtenteBo.avviso_cancellazione_p_individuale} </span>
                          </>
                        }
                        {
                        this.state.polizza?.tipoRichiesta === 'A' &&
                          <>
                            <span className="pf-c-title pf-m-4md pf-u-color-300"> &nbsp;  {AvvisiUtenteBo.avviso_cancellazione_p_collettiva_massimale} </span>
                          </>
                        }
                  </div>
                }
                />
             </>

            : <></>}

            <GenericCard className="cardStyle" isExpandibleCard={false} header={'Compila i seguenti campi'} body={<><FormCancellaPolizza messaggio={this.submitMessageResult} idPolizza={idPolizza} idSoggetto={idSoggetto}/></>} /><br></br>
            <div className='row'>
              <div className={isCurrentMobileDevice() ? 'col-6' : 'col-1' }>
                <GenericButton label={"INDIETRO"} color={"tertiary"} onClick={() => this.goToElencoPolizze(PATH_TO_BO_POLIZZE_ELENCO)} />
              </div>
              <div className={isCurrentMobileDevice() ? 'col-6' : 'col-1' }>
                <GenericButton form={"idCancellaPolizza"} type={"submit"} label={"CONFERMA"} color={"primary"}/>

              </div>
            </div>
          </PageSection>
          <PageSection className='pt-5'>
            <div>
              <Title headingLevel='h3'>Legenda</Title>
              <p>* Campo obbligatorio</p>
            </div>
          </PageSection>
        </div>
      </>
    );
  }
};

export default CancellaPolizza;
