import React, {useCallback, useRef, useState} from 'react'
import {Form, Label} from 'design-react-kit'
import FormSubmit, {STATUS} from 'src/frontend/app/components/Form/GenericSubmit'
import {getDefaultAxiosConfig, getFormazioneResourceApi} from 'src/app/utils/ManagerRestGateway'
import TextArea from 'src/frontend/app/components/Form/TextArea'
import {createFormData, handleChangeFormData, validateFormData} from 'src/app/utils/form-utils'
import {useHistory} from 'react-router-dom'
import GenericInput from 'src/frontend/app/components/Form/Input'
import GenericUpload from 'src/frontend/app/components/Form/Upload'
import {AppUtils as U} from 'src/app/utils/FormUtils'
import GenericCard from 'src/frontend/app/components/GenericCard'
import {TokenStore} from 'src/keycloak/jwt/TokenStore'
import {RO_GEST_FORM_CONAF} from 'src/app/utils/RoutesConstants'
import {isCurrentMobileDevice} from 'src/utilities/deviceUtility'

const FormProroga: React.FunctionComponent<FormProrogaProps> = ({ title, id, isProrogata, dataAF, viewOnly }) => {
  const [status, setStatus] = useState<any>()
  const [alertMessage, setAlertMessage] = useState<any>()
  const isConafGest = TokenStore.getInstance().currentAccountHasRolesOR([RO_GEST_FORM_CONAF])
  // Form State
  const refForm = useRef<any>([])
  const [data, setData] = useState<any>({})
  const [errors, setErrors] = useState<any>({})
  const history = useHistory()
  const formData = createFormData(data, setData, errors, setErrors)

  const today = new Date()
  today.setHours(0, 0, 0, 0)

  const rules = {
    dataFineProroga: {
      isRequired: true,
    },
  }

  const onInitInput = (cmp) => {
    refForm.current.push({
      cmp: cmp,
    })
  }

  const handleInput = (code, value) => {
    handleChangeFormData(formData, code, value, rules)
  }

  const submit = useCallback(
    async (e) => {
      try {
        const inputs = ['proroga']
        const valid = validateFormData(formData, rules, inputs)
        const data = formData?.data
        if (!valid) {
          window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
          return
        }

        const selectedDate = new Date(data.dataFineProroga)
        if (selectedDate < today) {
          setErrors({ ...errors, dataFineProroga: 'La data deve essere successiva o uguale alla data odierna' })
          return
        }

        setStatus(STATUS.LOADING)
        await getFormazioneResourceApi().msfoFormazioneAccAgenziaFormativaProrogaPatch(
          {
            datiAccreditamento: {
              id: id,
              dataFineProroga: data.dataFineProroga + 'T23:59:00',
              noteProroga: data?.noteProroga,
            } as any,
            allegato: data?.allegatoProroga?.allegatoDTO,
          },
          getDefaultAxiosConfig()
        )
        setStatus(STATUS.SUCCESS)
        setAlertMessage('Dati salvati con successo')
        setTimeout(() => {
          history.goBack()
        }, 500)
      } catch (e) {
        setStatus(STATUS.FAILURE)
        setAlertMessage('Impossibile salvare i dati')
      }
    },
    [id, data]
  )

  if (!viewOnly && isConafGest) {
    return (
      <FormSubmit
        title={isProrogata ? 'Modifica Proroga Accreditamento Agenzia' : 'Proroga Accreditamento Agenzia'}
        buttonLabel="CONFERMA"
        alertMessage={alertMessage}
        status={status}
        onSubmit={submit}
      >
        <Form>
          <div className="row">
            <div className={isCurrentMobileDevice() ? null : 'col-4'}>
              <GenericInput
                id="dataFineProroga"
                type="date"
                label="Data fine proroga*"
                value={
                  isProrogata && data.dataFineProroga
                    ? data.dataFineProroga
                    : isProrogata && !data.dataFineProroga
                    ? U.data_normalize(dataAF.datiAccreditamento.dataFineProroga)
                    : data.dataFineProroga
                }
                onChange={handleInput}
                onInit={onInitInput}
                errorMessage={errors?.dataFineProroga}
              />
            </div>
            <div className={isCurrentMobileDevice() ? null : 'col-2'}></div>
            <div className={isCurrentMobileDevice() ? null : 'col-6'}>
              <div className="uploader-label">
                <Label for={'datiAccreditamento.allegatoProroga'}>
                  <strong>Documentazione Autorizzativa Proroga</strong>
                </Label>
              </div>
              <div className="uploader-files">
                <GenericUpload
                  id={'allegatoProroga'}
                  tipologiaAllegato={'documentazione_proroga_agenzia'}
                  extensions={['pdf']}
                  accept={['application/pdf']}
                  data={isProrogata ? dataAF.datiAccreditamento?.allegatoProroga : data}
                  onChange={handleInput}
                  isDisabled={isProrogata}
                />
              </div>
              {isProrogata && dataAF.datiAccreditamento?.allegatoProroga?.allegatoProroga.length < 1 ? 'Nessun allegato' : null}
            </div>
          </div>

          <TextArea
            labelBottom
            id="noteProroga"
            placeholder={isProrogata ? dataAF.datiAccreditamento?.noteProroga || '-- nessuna nota --' : 'Note'}
            label={'Note proroga accreditamento'}
            value={data?.noteProroga}
            onInit={onInitInput(0)}
            onChange={handleInput}
            isReadOnly={isProrogata}
          />
        </Form>
      </FormSubmit>
    )
  } else {
    // visualizza dettagli proroga, carica i dati in sola lettura : viewOnly == true
    return (
      <GenericCard cardHeader={title}>
        <Form>
          <div className="row">
            <div className={isCurrentMobileDevice() ? null : 'col-4'}>
              <GenericInput
                id="dataFineProroga"
                type="date"
                label="Data fine proroga"
                value={U.data_normalize(dataAF.datiAccreditamento.dataFineProroga)}
                onInit={onInitInput}
                isDisabled={viewOnly}
              />
            </div>
            <div className={isCurrentMobileDevice() ? null : 'col-2'}></div>
            <div className={isCurrentMobileDevice() ? null : 'col-6'}>
              <div className="uploader-label">
                <Label for={'datiAccreditamento.allegatoProroga'}>
                  <strong>Documentazione Autorizzativa Proroga</strong>
                </Label>
              </div>
              <div className="uploader-files">
                <GenericUpload
                  id={'allegatoProroga'}
                  tipologiaAllegato={'documentazione_proroga_agenzia'}
                  extensions={['pdf']}
                  accept={['application/pdf']}
                  data={dataAF.datiAccreditamento?.allegatoProroga}
                  onChange={handleInput}
                  isDisabled={viewOnly}
                />
              </div>
              {isProrogata && dataAF.datiAccreditamento?.allegatoProroga?.allegatoProroga.length < 1 ? 'Nessun allegato' : null}
            </div>
          </div>

          <TextArea
            labelBottom
            id="noteProroga"
            placeholder={dataAF.datiAccreditamento?.noteProroga || '-- nessuna nota --'}
            label={'Note proroga accreditamento'}
            isDisabled={viewOnly}
          />
        </Form>
      </GenericCard>
    )
  }
}

interface FormProrogaProps {
  id: any
  isProrogata?: boolean
  dataAF: any
  viewOnly?: boolean
  title?: string
}

export default FormProroga
