import * as React from 'react'
import {Form} from '@patternfly/react-core'
import GenericTextInput from 'src/backoffice/app/components/GenericTextInput'
import {isCurrentMobileDevice} from 'src/utilities/deviceUtility'

export class FormElencoSociIscrittiAltriOrdiniStpBO extends React.Component<GeneralFormElencoSociIscrittiAltriOrdiniStpBOProps, any> {
  render() {
    return (
      <div>
        <Form>
          {/*Cicla la lista composizioneSocietaria e visualizza i soci non iscritti (isSocio === 1 e idSoggettoPF === null) */}
          {this.props.composizioneSocietaria?.length > 0 ? (
            this.props.composizioneSocietaria.map((socio, id) => (
              <div className="row" hidden={!(socio.isSocio === 1 && socio.idSoggettoPF === null)}>
                <div className={isCurrentMobileDevice() ? null : 'col-4'}>
                  <GenericTextInput
                    type={'text'}
                    className="w-100"
                    id={'idNomeSocio' + id}
                    label={'Nome del socio non iscritto'}
                    value={socio.nome}
                    isReadOnly
                  />
                </div>
                <div className={isCurrentMobileDevice() ? null : 'col-4'}>
                  <GenericTextInput
                    type={'text'}
                    className="w-100"
                    id={'idCognomeSocio' + id}
                    label={'Cognome del socio non iscritto'}
                    value={socio.cognome}
                    isReadOnly
                  />
                </div>
                <div className={isCurrentMobileDevice() ? null : 'col-4'}>
                  <GenericTextInput
                    type={'text'}
                    className="w-100"
                    id={'idTitoloSocio' + id}
                    label={'Titolo del socio non iscritto'}
                    value={socio.titProf ? socio.titProf : '-'}
                    isReadOnly
                  />
                </div>
              </div>
            ))
          ) : (
            <p className="text-center">Nessun socio presente</p>
          )}
        </Form>
      </div>
    )
  }
}

export declare interface GeneralFormElencoSociIscrittiAltriOrdiniStpBOProps {
  messaggio?: any
  composizioneSocietaria?: any
}
