import React from 'react'
import {PageSection, Title} from '@patternfly/react-core'
import GenericBreadCrumb from 'src/frontend/app/components/GenericBreadCrumb'
import GenericCard from '../../components/GenericCard'
import GenericButton from '../../components/GenericButton'
import {FormConfermaPolizza} from './formPolizze/FormConfermaPolizza'
import GenericSpinner from '../../components/GenericSpinner'
import {PATH_TO_BO_POLIZZE_ELENCO, PATH_TO_BO_POLIZZE_ELENCO_CONFERMA} from 'src/app/utils/RoutesConstants'
import GenericAlert from 'src/frontend/app/components/GenericAlert'
import {isCurrentMobileDevice} from 'src/utilities/deviceUtility'

class ConfermaPolizza extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.state = {
      message: "",
      typeMessage: "default",
      idPolizza: "",
    };
  }
  goToElencoPolizze = (redirectLink: any) => {
    this.props.history.push({
      pathname: redirectLink,
    });
  }
  componentDidMount() {
    // Controllo temporaneo per gestire il refresh della pagina.
    // In tal caso i dati passati dalla riga si perderanno e l'utente verrà reindirizzato all'elenco iscritti
    if (this.props.history.location.state === undefined) {
    //   this.goToElencoPolizze('PATH')
    }

    else {
      //recupero l'indice della colonna in cui è riportato l'idIscrizione

      var idPolizza = this.props.history.location.state.idPolizza

      var periodoValDa = this.props.history.location.state.row.data[7].split('T')[0]
      var periodoValA = this.props.history.location.state.row.data[8].split('T')[0]


      this.setState({ idPolizza: idPolizza, periodoValDa:periodoValDa, periodoValA:periodoValA })

    }
    this.setState({isLoaded : true})
  }

  submitMessageResult = (alert: any) => {
    const element = document.getElementById("pageSectionId");
    if (element)
      element.scrollIntoView({ behavior: 'smooth' });
    this.setState({ message: alert.messaggio, typeMessage: alert.type })
  }

  render() {
    const { message, typeMessage, idPolizza, periodoValDa, periodoValA } = this.state
    return (
      <div >
        { this.state.isLoaded ?
          <div className='container-fluid p-5'>
              <PageSection id='pageSectionId'>
                <GenericBreadCrumb paths={[{ label: "Polizze", link: PATH_TO_BO_POLIZZE_ELENCO }, { label: "Elenco polizze", link: PATH_TO_BO_POLIZZE_ELENCO }, { label: "Accetta Polizza", link: PATH_TO_BO_POLIZZE_ELENCO_CONFERMA }]} /><br></br>
                <Title headingLevel="h1" size="4xl">Accetta polizza</Title><br></br>
                <GenericAlert hidden={message === ''} label={message} color={typeMessage}></GenericAlert>
                <GenericCard className="cardStyle" isExpandibleCard={false} header={'Compila i seguenti campi'} body={<><FormConfermaPolizza periodoValA={periodoValA} periodoValDa={periodoValDa} messaggio={this.submitMessageResult} idPolizza={idPolizza} tabellaUno={this.state.tabellaUno}/></>} /><br></br>
                <div className='row'>
                  <div className={isCurrentMobileDevice() ? 'col-6' : 'col-1' }>
                    <GenericButton label={"INDIETRO"} color={"tertiary"} onClick={() => this.goToElencoPolizze(PATH_TO_BO_POLIZZE_ELENCO)} />
                  </div>
                  <div className={isCurrentMobileDevice() ? 'col-6' : 'col-1' }>
                    <GenericButton form={"idConfermaPolizza"} type={"submit"} label={"CONFERMA"} color={"primary"}/>

                  </div>
                </div>
              </PageSection><br></br>
              <PageSection>
                <div>
                  <Title headingLevel='h3'>Legenda</Title>
                  <p>* Campo obbligatorio</p>
                </div>
              </PageSection>
          </div>
          : <GenericSpinner/>
        }
      </div>

    );
  }
};

export default ConfermaPolizza;
