import React from 'react'
import {Select, SelectGroup, SelectOption, SelectVariant} from '@patternfly/react-core'

export declare interface IFilteringCheckboxSelectInputWithBadgingProps {
  data?: any[]
  dataKey?: string
  dataValue?: string
  selectLabel: string
  selectKeyLabel: string
  onChangeObserver: any
  placeholder: string
  style?: any
}

class FilteringCheckboxSelectInputWithBadging extends React.Component<IFilteringCheckboxSelectInputWithBadgingProps, any> {
  data: any[]
  selectLabel: string
  selectKeyLabel: string
  dataKey: string
  dataValue: string
  label: string
  options: JSX.Element[]
  onChangeObserver: any
  placeholder: string
  style: any
  onToggle: (isOpen: any) => void
  clearSelection: () => void
  onSelect: (event: any, selection: any) => void
  onFilter: (_: any, textInput: any) => JSX.Element[]
  onclick: () => void

  constructor(props) {
    super(props)
    this.data = props?.data
    this.dataKey = props?.dataKey
    this.dataValue = props?.dataValue
    this.selectLabel = props?.selectLabel
    this.selectKeyLabel = props?.selectKeyLabel
    this.onChangeObserver = props?.onChangeObserver
    this.placeholder = props?.placeholder
    this.style = props?.style

    this.state = {
      isOpen: false,
      selected: [],
      customBadgeText: 0,
    }

    this.options = []

    //alert("lenght: "+this.data.length);

    if (this.data.length > 0) {
      this.options = [
        <SelectGroup label={this.selectLabel} key={this.selectKeyLabel} style={this.style}>
          {this.data.map((item, key) => {
            return (
              <SelectOption key={item[this.dataKey]} value={item[this.dataKey]}>
                {' '}
                {item[this.dataValue]}{' '}
              </SelectOption>
            )
          })}
        </SelectGroup>,
      ]
    }

    this.onToggle = (isOpen) => {
      this.setState({
        isOpen,
      })
    }


    this.onSelect = (event, selection) => {

      const { selected } = this.state
      //alert("notifico observer")
      //notifico l'observer

      if (selected.includes(selection)) {
        this.setState(
          (prevState) => ({
            selected: prevState.selected.filter((item) => item !== selection),
            customBadgeText: this.setBadgeText(prevState.selected.length - 1),
          }),
          () => {
            this.onChangeObserver(this)
          }
        )
      } else {
        this.setState(
          (prevState) => ({
            selected: [...prevState.selected, selection],
            customBadgeText: this.setBadgeText(prevState.selected.length + 1),
          }),
          () => {
            this.onChangeObserver(this)
          }
        )
      }
    }

    this.onFilter = (_, textInput) => {

      // var label = this.state.label
      if (this.props.selectLabel === 'Triennio') {
        let filteredGroups = this.data.filter((element) => element?.Triennio.includes(textInput))
        var group = filteredGroups.map((element, id) => (
          <SelectOption key={id} value={element.Triennio}>
            {' '}
            {element.Triennio}{' '}
          </SelectOption>
        ))
        return group
      } else if (textInput === '' || textInput === undefined) {
        return this.options
      } else if (this.props.selectLabel === 'Ordini') {
        let filteredGroups = this.data.filter((element) => element?.Ordine.toLowerCase().includes(textInput.toLowerCase()))
        var group = filteredGroups.map((element, id) => (
          <SelectOption key={id} value={element.CodiceGruppo}>
            {' '}
            {element.Ordine}{' '}
          </SelectOption>
        ))
        return group
      }
      return this.options
    }

    this.clearSelection = () => {
      this.setState({
        selected: [],
        customBadgeText: this.setBadgeText(0),
      })

      this.onChangeObserver([])
    }

    this.setBadgeText = (selected) => {
      if (selected === 7) {
        return 'All'
      }
      if (selected === 0) {
        return 0
      }
      return null
    }
  }

  setBadgeText(arg0: number) {
    throw new Error('Method not implemented.')
  }

  render() {
    const { isOpen, selected, filteredOptions, customBadgeText } = this.state
    const titleId = 'checkbox-filtering-custom-badging-select-id'
    return (
      <div>
        <span id={titleId} hidden>
          Checkbox Title
        </span>
        <Select
          variant={SelectVariant.checkbox}
          onToggle={this.onToggle}
          onSelect={this.onSelect}
          selections={selected}
          isOpen={isOpen}
          placeholderText={this.placeholder}
          aria-labelledby={titleId}
          onFilter={this.onFilter}
          onClear={this.clearSelection}
          isGrouped
          hasInlineFilter
          customBadgeText={customBadgeText}
          width={250}
        >
          {this.options}
        </Select>
      </div>
    )
  }
}

export default FilteringCheckboxSelectInputWithBadging
