import {Alert} from 'design-react-kit'
import React from 'react'
import {getHome} from 'src/utilities/utility'

class AccessDenied extends React.Component {
  render() {
    return (
      <Alert color='danger'>
        Accesso non consentito
        <br></br>
        <a href={getHome()} className='alert-link'>
         Torna nella home page
        </a>{' '}
      </Alert>
    )
  }
}

export default AccessDenied;
