import React, {useCallback, useEffect, useState} from 'react'
import {useHistory} from 'react-router-dom'
import {Button, debounce, PageSection, Title} from '@patternfly/react-core'
import {TableComposable, Tbody, Td, Th, Thead, Tr} from '@patternfly/react-table'
import {Modal, ModalBody, ModalFooter, ModalHeader, Row} from 'design-react-kit'
import GenericSelect from 'src/frontend/app/components/Form/Select'
import styled from 'styled-components'

import {generateRequestFilter, generateRequestSort} from 'src/app/utils/ApiUtils'
import {
    PATH_TO_BO_CREDITI_FORMATIVI,
    PATH_TO_BO_DETTAGLIO_RICHIESTA_ESONERO,
    RO_GEST_FORM_ODAF
} from 'src/app/utils/RoutesConstants'
import {getDefaultAxiosConfig, getFormazioneResourceApi} from 'src/app/utils/ManagerRestGateway'

import GenericBreadCrumb from 'src/frontend/app/components/GenericBreadCrumb'
import {TokenStore} from 'src/keycloak/jwt/TokenStore'
import useFrontOffice from 'src/hooks/useFrontOffice'
import GenericInput from 'src/frontend/app/components/Form/Input'
import {getSdaf, getTipologieAttivita} from 'src/hooks/useReqOptions'
import moment from 'moment'
import {exportPDF, ottieniAllegato} from 'src/frontend/app/pages/iscrizioneAlboEF/form-utils'
import {TRIENNI_OPTIONS} from './ConcessioneDeroga'
import {GenericSpinner} from '../../components'
import {getSoggettoByCf} from 'src/processes/Soggetto'

const myPath = {
  label: 'Elenco Crediti Formativi',
  link: PATH_TO_BO_CREDITI_FORMATIVI,
}

export const annoToTriennio = (anno: number) => {
  if (anno === 2013) {
    return 671
  } else if (anno < 2013) {
    return Math.floor(anno / 3.0)
  } else {
    return Math.floor((anno - 1.0) / 3.0)
  }
}

export const getAnnoAperturaByTriennio = (triennio: number) => {
  if (triennio <= 671) {
    return triennio * 3
  } else {
    return triennio * 3 + 1
  }
}

export const getAnnoChiusuraByTriennio = (triennio: number) => {
  if (triennio < 671) {
    return triennio * 3 + 2
  } else {
    return triennio * 3 + 1 + 2
  }
}

const triennioToString = (triennio: number) => {
  const apertura = getAnnoAperturaByTriennio(triennio)
  const chiusura = getAnnoChiusuraByTriennio(triennio)
  return apertura + ' - ' + chiusura
}

const Wrapper = styled.div`
  width: 100%;
  table {
    tbody {
      tr > *:empty {
        min-width: 200px;
      }
      td {
        min-width: 200px;
      }
    }
    thead {
      tr {
        width: 100%;
        justify-content: space-between;
        th:first-child {
          max-width: 200px;
        }
      }
    }
  }
`

const Spacer = () => (
  <>
    <br></br>
  </>
)
const basePath = { label: 'Formazione', link: '/bo/formazione' }
var timeout: any

const CreditiFormativi: React.FunctionComponent = () => {
  const searchParams = new URLSearchParams(window.location.search)
  const cf = searchParams.get('cf')
  const flagApprovazione = searchParams.get('flagApprovazione')
  const isFrontOffice = useFrontOffice()
  const history = useHistory()

  const [codFisc, setCodFisc] = useState<string>(!isFrontOffice && cf ? cf : '')

  const [dataIscrizione, setDataIscrizione] = useState<any>(null)
  const [approvazioni, setApprovazioni] = useState<any>({})
  const [esoneri, setEsoneri] = useState<any>([])
  const [datiPerTriennio, setDatiPerTriennio] = useState<any>({})
  const [infoPerTriennio, setInfoPerTriennio] = useState<any>({})
  const [triennioOptions, setTriennioOptions] = useState<Option[]>([{ label: triennioToString(674), value: 674 }])
  const [triennioSelezionato, setTriennioSelezionato] = useState<Option>({ label: triennioToString(674), value: 674 })
  const [approvaCliccato, setApprovaCliccato] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [metaprofPerAnno, setMetaprofPerAnno] = useState<{ [key: number]: number }>({})

  const [rows, setRows] = useState<any>([])
  const [isFoComponentMounted, setIsFoComponentMounted] = useState<boolean>(false)

  const [showModal, setShowModal] = useState(false)

  const handleInput = (id_dato: number, dato: string) => {
    setDatiPerTriennio({})
    setInfoPerTriennio({})
    setTriennioOptions([{ label: triennioToString(674), value: 674 }])
    setTriennioSelezionato({ label: triennioToString(674), value: 674 })
    setRows([])
    setApprovazioni({})
    setCodFisc(dato)
  }

  useEffect(() => {
    if (flagApprovazione) {
      setTriennioSelezionato((prevTriennio) => ({
        label: triennioToString(prevTriennio.value - 1),
        value: prevTriennio.value - 1,
      }))
    }
  }, [flagApprovazione])

  const initData = useCallback(
    debounce(async () => {
      setLoading(true)
      try {
        const listaSDAF = await getSdaf()

        let dataIscrizione = null

        let data: any = null
        let result: any = null

        let approvazioniresult: any = []
        let approvazioni: any = []

        if (isFrontOffice) {
          // const {dataIscrizione, richiestaEsoneroList } = (await getFormazioneResourceApi().msfoFormazioneDataIscrizioneByCfForUserGet(codFisc, getDefaultAxiosConfig())) as any;
          approvazioniresult = await getFormazioneResourceApi().msfoFormazioneListaTriennioFormativoForUserGet(
            generateRequestFilter([]),
            -1,
            -1,
            generateRequestSort('triennio', 'desc'),
            getDefaultAxiosConfig()
          )
          result = await getFormazioneResourceApi().msfoFormazioneRiepilogoCreditiFormativiForUserGet(getDefaultAxiosConfig())
        } else {
          if (codFisc) {
            try {
              const data = (await getFormazioneResourceApi().msfoFormazioneDataIscrizioneByCfGet(codFisc, getDefaultAxiosConfig())) as any
              const richiestaEsoneroList = data.data.richiestaEsoneroList
              dataIscrizione = data.data.dataIscrizione

              setEsoneri(richiestaEsoneroList)
              setDataIscrizione(dataIscrizione)
            } catch (e) {
              console.error('Error getting dati iscrizione ', e)
            }
          }
          approvazioniresult = await getFormazioneResourceApi().msfoFormazioneListaTriennioFormativoGet(
            codFisc,
            generateRequestFilter([]),
            -1,
            -1,
            generateRequestSort('triennio', 'desc'),
            getDefaultAxiosConfig()
          )
          result = await getFormazioneResourceApi().msfoFormazioneRiepilogoCreditiFormativiGet(codFisc, getDefaultAxiosConfig())
        }

        data = result.data
        approvazioni = approvazioniresult.data.items

        const tipologieAttivita = await getTipologieAttivita()

        const tipologieMap = {}
        const settoriMap = {}

        for (const tipologia of tipologieAttivita) {
          tipologieMap[tipologia.id] = tipologia
        }

        for (const SDAF of listaSDAF) {
          settoriMap[SDAF.id] = SDAF
        }

        const cfu_by_tipologia = {}

        const dati = []

        const anno = moment().get('year')

        const annoNelTriennio = (anno - 1) % 3

        const triennioRiferimento = annoToTriennio(anno)

        const baseDelTriennio = anno - annoNelTriennio

        const fineDelTriennio = baseDelTriennio + 2

        const eventiPerTriennio = {}

        const creditiIndividualiPerAnnoPerTipologia = {}

        const creditiNonACatalogoTriennio = {}

        const metaprofPerAnno = {}

        // controllo per i casi di eventi non a catalogo riconciliati (vecchi) che si sommano a quelli calcolati che hanno già raggiunto il limite per "validi per gli obblighi formativi"
        const MAX_CFP_NON_A_CATALOGO = {}

        const creditiACatalogoPerAnnoPerTipologia = {}
        let count = 0
        try {
          for (const ogg of data) {
            count++
            if (ogg.movimento) {
              const annoMovimento = ogg.movimento.anno_calc_cfp
              const crediti = ogg.movimento.crediti
              const isRiconosciuto = ogg.movimento.stato === 2
              const tipologia = tipologieMap[ogg.evento.tipo_corso && ogg.evento.tipo_corso !== '' ? parseInt(ogg.evento.tipo_corso) : 1]
              const maxCFP = tipologia.max_cfp_evt
              const max_CFP_anno = tipologia.max_cfp_anno
              const annoNelTriennio = (annoMovimento - 1) % 3
              const triennioRifMovimento = annoToTriennio(annoMovimento)

              if (!cfu_by_tipologia[triennioRifMovimento]) {
                cfu_by_tipologia[triennioRifMovimento] = {}
              }

              if (!cfu_by_tipologia[triennioRifMovimento][tipologia.id]) {
                cfu_by_tipologia[triennioRifMovimento][tipologia.id] = 0
              }

              if (!creditiACatalogoPerAnnoPerTipologia[annoMovimento]) {
                creditiACatalogoPerAnnoPerTipologia[annoMovimento] = {}
              }

              if (!creditiACatalogoPerAnnoPerTipologia[annoMovimento][tipologia.id]) {
                creditiACatalogoPerAnnoPerTipologia[annoMovimento][tipologia.id] = 0
              }

              const cfp_riconosciuti = isRiconosciuto ? crediti : 0

              let cfp_validi = ogg.movimento.crediti_validi

              cfu_by_tipologia[triennioRifMovimento][tipologia.id] += cfp_riconosciuti
              creditiACatalogoPerAnnoPerTipologia[annoMovimento][tipologia.id] += cfp_riconosciuti

              const oggettoStampato: Riga = {
                settore: !isNaN(parseInt(ogg.evento.settore)) && settoriMap[parseInt(ogg.evento.settore)] ? settoriMap[parseInt(ogg.evento.settore)].cod : '',
                tipo_corso: tipologia ? tipologia.cod : '',
                anno_calc_cfp: ogg.movimento.anno_calc_cfp,
                nome_corso: ogg.evento.nome_corso,
                cod_attivita: ogg.evento.cod_attivita,
                cfp_riconosciuti: cfp_riconosciuti,
                cfp_validi: cfp_validi,
                evento: ogg.evento,
                attestatoPartecipazione: ogg.attestatoPartecipazione,
                settoreNum: ogg.evento ? ogg.evento.settore : ogg.attivitaFormativaExt?.settore,
                dataInizio: ogg.evento.data_inizio,
                dataFine: ogg.evento.data_fine,
              }

              if (!eventiPerTriennio[triennioRifMovimento]) {
                eventiPerTriennio[triennioRifMovimento] = []
              }

              if (cfp_validi > 0) {
                eventiPerTriennio[triennioRifMovimento].push(oggettoStampato)
              } else {
                if (ogg.isRiconciliato !== 1) {
                  eventiPerTriennio[triennioRifMovimento].push(oggettoStampato)
                }
              }
            } else if (ogg.attivitaFormativaExt && ogg.attivitaFormativaExt.anno_calc_cfp) {
              const tipologia =
                tipologieMap[
                  ogg.attivitaFormativaExt.tipo_corso && ogg.attivitaFormativaExt.tipo_corso !== '' ? parseInt(ogg.attivitaFormativaExt.tipo_corso) : 1
                ]
              const triennioAttivita = annoToTriennio(ogg.attivitaFormativaExt.anno_calc_cfp)
              if (ogg.attivitaFormativaExt.tipologia_evento == 0) {
                //NON A CATALOGO

                if (!creditiNonACatalogoTriennio[triennioAttivita]) {
                  creditiNonACatalogoTriennio[triennioAttivita] = 0
                }

                if (MAX_CFP_NON_A_CATALOGO[triennioAttivita] === undefined || MAX_CFP_NON_A_CATALOGO[triennioAttivita] === null) {
                  if (triennioAttivita === 672 || triennioAttivita === 673) {
                    MAX_CFP_NON_A_CATALOGO[triennioAttivita] = 2
                  } else {
                    MAX_CFP_NON_A_CATALOGO[triennioAttivita] = 3
                  }
                }

                let cfp_validi = ogg.attivitaFormativaExt.cfp_validi

                creditiNonACatalogoTriennio[triennioAttivita] += parseFloat(ogg.attivitaFormativaExt.cpf_riconosciuti)

                const oggettoStampato: Riga = {
                  settore:
                    !isNaN(parseInt(ogg.attivitaFormativaExt.settore)) && settoriMap[parseInt(ogg.attivitaFormativaExt.settore)]
                      ? settoriMap[parseInt(ogg.attivitaFormativaExt.settore)].cod
                      : '',
                  tipo_corso: tipologia ? tipologia.cod : '',
                  anno_calc_cfp: ogg.attivitaFormativaExt.anno_calc_cfp,
                  nome_corso: ogg.attivitaFormativaExt.nome_corso,
                  cod_attivita: ogg.attivitaFormativaExt.cod_attivita,
                  cfp_riconosciuti: parseFloat(ogg.attivitaFormativaExt.cpf_riconosciuti),
                  cfp_validi: cfp_validi,
                  attivitaFormativaExt: ogg.attivitaFormativaExt,
                  settoreNum: ogg.evento ? ogg.evento.settore : ogg.attivitaFormativaExt?.settore,
                  tipologia_evento: ogg.attivitaFormativaExt.tipologia_evento,
                  dataInizio: ogg.attivitaFormativaExt.data_inizio,
                  dataFine: ogg.attivitaFormativaExt.data_fine,
                  id_corso: ogg.attivitaFormativaExt.id,
                }

                if (!eventiPerTriennio[triennioAttivita]) {
                  eventiPerTriennio[triennioAttivita] = []
                }

                if (cfp_validi > 0) {
                  if (MAX_CFP_NON_A_CATALOGO[triennioAttivita] >= cfp_validi) {
                    MAX_CFP_NON_A_CATALOGO[triennioAttivita] -= cfp_validi
                  } else {
                    oggettoStampato.cfp_validi = MAX_CFP_NON_A_CATALOGO[triennioAttivita]
                    MAX_CFP_NON_A_CATALOGO[triennioAttivita] = 0
                  }
                  eventiPerTriennio[triennioAttivita].push(oggettoStampato)
                } else {
                  if (ogg.isRiconciliato !== 1) {
                    eventiPerTriennio[triennioAttivita].push(oggettoStampato)
                  }
                  // nel caso di un evento NON a catalogo riconciliato nello stesso triennio (errori da vecchio db), faccio visualizzare una sola volta l'evento con i cfp complessivi
                  if (ogg.isRiconciliato === 1) {
                    for (let i = 0; i < eventiPerTriennio[triennioAttivita].length; i++) {
                      const eventoCorrente = eventiPerTriennio[triennioAttivita][i]
                      // controllo che l'evento sia NON a catalogo
                      if (eventoCorrente.attivitaFormativaExt) {
                        if (oggettoStampato.id_corso === eventoCorrente.attivitaFormativaExt.id) {
                          // faccio la somma
                          oggettoStampato.cfp_riconosciuti += eventoCorrente.cfp_riconosciuti
                          oggettoStampato.cfp_validi += eventoCorrente.cfp_validi
                          // Rimuovo l'evento corrente dal triennio e pusho quello aggiornato
                          eventiPerTriennio[triennioAttivita].splice(i, 1)
                          eventiPerTriennio[triennioAttivita].push(oggettoStampato)
                        }
                      }
                    }
                  }
                }
              } else {
                //INDIVIDUALE
                const annoMovimento = ogg.attivitaFormativaExt.anno_calc_cfp

                if (!creditiIndividualiPerAnnoPerTipologia[annoMovimento]) {
                  creditiIndividualiPerAnnoPerTipologia[annoMovimento] = {}
                }

                if (!creditiIndividualiPerAnnoPerTipologia[annoMovimento][tipologia.id]) {
                  creditiIndividualiPerAnnoPerTipologia[annoMovimento][tipologia.id] = 0
                }

                const cfp_riconosciuti = parseFloat(ogg.attivitaFormativaExt.cpf_riconosciuti)

                let cfp_validi = ogg.attivitaFormativaExt.cfp_validi

                creditiIndividualiPerAnnoPerTipologia[annoMovimento][tipologia.id] += cfp_riconosciuti

                const oggettoStampato: Riga = {
                  settore:
                    !isNaN(parseInt(ogg.attivitaFormativaExt.settore)) && settoriMap[parseInt(ogg.attivitaFormativaExt.settore)]
                      ? settoriMap[parseInt(ogg.attivitaFormativaExt.settore)].cod
                      : '',
                  tipo_corso: tipologia ? tipologia.cod : '',
                  anno_calc_cfp: ogg.attivitaFormativaExt.anno_calc_cfp,
                  nome_corso: ogg.attivitaFormativaExt.nome_corso,
                  cod_attivita: ogg.attivitaFormativaExt.cod_attivita,
                  cfp_riconosciuti: parseFloat(ogg.attivitaFormativaExt.cpf_riconosciuti),
                  cfp_validi: cfp_validi,
                  settoreNum: ogg.evento ? ogg.evento.settore : ogg.attivitaFormativaExt?.settore,
                  tipologia_evento: ogg.attivitaFormativaExt.tipologia_evento,
                  dataInizio: ogg.attivitaFormativaExt.data_inizio,
                  dataFine: ogg.attivitaFormativaExt.data_fine,
                  id_corso: ogg.attivitaFormativaExt.id,
                }

                if (!eventiPerTriennio[triennioAttivita]) {
                  eventiPerTriennio[triennioAttivita] = []
                }

                if (cfp_validi > 0) {
                  eventiPerTriennio[triennioAttivita].push(oggettoStampato)
                } else {
                  if (ogg.isRiconciliato !== 1) {
                    eventiPerTriennio[triennioAttivita].push(oggettoStampato)
                  }
                  // nel caso di un evento individuale riconciliato nello stesso triennio (errori da vecchio db), faccio visualizzare una sola volta l'evento con i cfp complessivi
                  if (ogg.isRiconciliato === 1) {
                    for (let i = 0; i < eventiPerTriennio[triennioAttivita].length; i++) {
                      const eventoCorrente = eventiPerTriennio[triennioAttivita][i]
                      // controllo che l'evento sia NON a catalogo
                      if (eventoCorrente.attivitaFormativaExt) {
                        if (oggettoStampato.id_corso === eventoCorrente.attivitaFormativaExt.id) {
                          // faccio la somma
                          oggettoStampato.cfp_riconosciuti += eventoCorrente.cfp_riconosciuti
                          oggettoStampato.cfp_validi += eventoCorrente.cfp_validi
                          // Rimuovo l'evento corrente dal triennio e pusho quello aggiornato
                          eventiPerTriennio[triennioAttivita].splice(i, 1)
                          eventiPerTriennio[triennioAttivita].push(oggettoStampato)
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        } catch (e) {
          console.error(e)
        }

        const info_cfp_triennio = {}

        const options: Option[] = []

        const approvazioniByTriennio = {}

        for (const approvazione of approvazioni) {
          approvazioniByTriennio[approvazione.triennio] = approvazione
        }

        for (const triennio in eventiPerTriennio) {
          const result = {
            cfp_validi: 0,
            cfp_metaprofessionali: 0,
          }

          options.push({
            label: triennioToString(parseInt(triennio)),
            value: parseInt(triennio),
          })

          for (const evento of eventiPerTriennio[triennio]) {
            result.cfp_validi += evento.cfp_validi
            if (evento.cod_attivita === 'AFM') {
              result.cfp_metaprofessionali += evento.cfp_validi

              if (!metaprofPerAnno[evento.anno_calc_cfp]) {
                metaprofPerAnno[evento.anno_calc_cfp] = 0
              }
              metaprofPerAnno[evento.anno_calc_cfp] += evento.cfp_validi
            }
          }

          info_cfp_triennio[triennio] = result

          // ordinamento eventi per SDAF
          // eventiPerTriennio[triennio].sort((a, b) => a.settoreNum - b.settoreNum)

          // ordinamento eventi per data fine corso
          eventiPerTriennio[triennio].sort((a, b) => {
            const dateA = a.dataFine ? parseInt(a.dataFine.replace(/-/g, '')) : 99999999
            const dateB = b.dataFine ? parseInt(b.dataFine.replace(/-/g, '')) : 99999999
            return dateB - dateA
          })
        }

        setIsFoComponentMounted(true)

        setRows(eventiPerTriennio[triennioSelezionato.value])

        setDatiPerTriennio(eventiPerTriennio)

        setTriennioOptions(options)

        setInfoPerTriennio(info_cfp_triennio)

        setApprovazioni(approvazioniByTriennio)

        setApprovaCliccato(false)

        setMetaprofPerAnno(metaprofPerAnno)
        setLoading(false)

        setLoading(false)
        return eventiPerTriennio
      } catch (e) {
        setLoading(false)
        return {}
      }
    }, 1000),
    [codFisc, triennioSelezionato]
  )

  const approvaTriennio = useCallback(async () => {
    const triennio = triennioSelezionato.value
    const eventiFormativi = datiPerTriennio[triennio]
    const info = infoPerTriennio[triennio]
    const caratterizzanti = info.cfp_validi - info.cfp_metaprofessionali
    let htmlCrediti = `
      <p class="italicheader center">Regolare</p>
      <p class="italicheader center">Per il triennio formativo ${triennioToString(triennio)}</p>
      <p class="spaced">Crediti Maturati</p>
      <p class="spaced">CFP Caratterizzanti n° ${Math.round(caratterizzanti * 1000) / 1000}</p>
      <p class="spaced">CFP Metaprofessionali n° ${Math.round(info.cfp_metaprofessionali * 1000) / 1000}</p>
    `
    let htmlEventi = `
      <p class="spaced">Elenco attività formative <span class="italicheader">triennio formativo ${triennioToString(triennio)}</span></p>
    `
    for (const evento of eventiFormativi) {
      htmlEventi += `
        <p class="spaced">${evento.nome_corso}</p>
      `
    }

    const object = {
      cf_ricevente: codFisc,
      triennio: triennioSelezionato.value,
      approvato: 1,
      testo_crediti: htmlCrediti,
      testo_eventi: htmlEventi,
    }

    setLoading(true)
    await getFormazioneResourceApi().msfoFormazioneApprovaTriennioFormativoPost(object, getDefaultAxiosConfig())
    setLoading(false)
    initData()
  }, [codFisc, triennioSelezionato, datiPerTriennio, infoPerTriennio, initData])

  const confermaApprovaTriennio = () => {
    setApprovaCliccato(true)
    setShowModal(false)
    approvaTriennio()
  }

  const closeModal = () => {
    setShowModal(false)
  }

  useEffect(() => {
    initData()
  }, [])

  useEffect(() => {
    initData()
  }, [codFisc])

  const onDetails = (row, modalita) => (e) => {
    history.push({
      pathname: PATH_TO_BO_DETTAGLIO_RICHIESTA_ESONERO,
      search: `?id=${row.id}${modalita ? `&modalita=${modalita}` : ''}`,
    })
  }
  const getColumnValue = (column: any, row: any) => {
    if (column.field && row[column.field] !== undefined) {
      const value = row[column.field]
      if (column.options && column.options[value]) {
        return column.options[value]
      }
      return row[column.field]
    }
    return ''
  }

  const columns: Column[] = [
    { label: 'SDAF', field: 'settore', isSortable: false, width: 100 },
    { label: 'Tipologie Attività', field: 'tipo_corso', isSortable: false, width: 80 },
    { label: 'Data inizio', field: 'dataInizio', isSortable: false, width: 200 },
    { label: 'Data fine', field: 'dataFine', isSortable: false, width: 200 },
    { label: 'Titolo Evento', field: 'nome_corso', isSortable: false, width: 800 },
    {
      label: 'Anno',
      field: 'anno_calc_cfp',
      isSortable: false,
    },
    { label: 'Tipo CFP', field: 'cod_attivita', isSortable: false, width: 80 },
    {
      label: 'CFP Riconosciuti',
      field: 'cfp_riconosciuti',
      isSortable: false,
      width: 80,
      render: (row) => {
        return Math.round(row.cfp_riconosciuti * 1000) / 1000
      },
    },
    {
      label: 'CFP validi per gli obblighi formativi',
      field: 'cfp_validi',
      isSortable: false,
      width: 150,
      render: (row) => {
        return Math.round(row.cfp_validi * 1000) / 1000
      },
    },
    {
      label: 'Organizzatore',
      field: 'organizzatore',
      isSortable: false,
      render: (row) => {
        return row.evento
          ? row?.evento?.enteFormazione?.name
            ? row?.evento?.enteFormazione?.name
            : row.organizzatoda
          : row.tipologia_evento && row.tipologia_evento == 0
          ? 'Non a catalogo'
          : 'Individuale'
      },
    },
    {
      label: 'Attestato',
      field: 'attestato',
      isSortable: false,
      render: (row) => {
        if (!row.attestatoPartecipazione && row?.evento) {
          return (
            <Button
              variant="primary"
              onClick={async () => {
                setLoading(true)
                const res = await getFormazioneResourceApi().msfoFormazioneGeneraAttestatoPartecipazioneEventoPost(
                  { user_name: codFisc, ref: row?.evento?.idcorso } as any,
                  getDefaultAxiosConfig()
                )

                ottieniAllegato(res.data, 'Attestato_partecipazione_evento')
                setLoading(false)
              }}
            >
              Scarica attestato
            </Button>
          )
        } else {
          return (
            <Button
              variant="primary"
              onClick={async () => {
                if (row.attivitaFormativaExt) {
                  const res = await getFormazioneResourceApi().msfoFormazioneEventoNonACatalogoGet(row.attivitaFormativaExt.id as any, getDefaultAxiosConfig())
                  ottieniAllegato(res.data.attestatoDTO?.idAllegato)
                } else {
                  ottieniAllegato(row.attestatoPartecipazione.idAllegato)
                }
              }}
            >
              Scarica attestato
            </Button>
          )
        }
      },
    },
  ]

  let minToApprove = 9
  let minMetaToApprove = 1
  let apertura = getAnnoAperturaByTriennio(triennioSelezionato.value)
  const chiusura = getAnnoChiusuraByTriennio(triennioSelezionato.value)

  let validoInAnniIscrizione = true

  if (triennioSelezionato && dataIscrizione) {
    const annoIscr = moment(dataIscrizione, 'YYYY-MM-DD').get('year')
    var creditiMetaPrimoAnno = 1.0

    if (annoIscr >= apertura && annoIscr <= chiusura) {
      if (chiusura <= 2023) {
        creditiMetaPrimoAnno = 0.5
      }
      apertura = annoIscr
      let anniPeriodo = chiusura - apertura

      if (chiusura == 2016 && anniPeriodo > 0) {
        // il 2016 è l'anno chiusura dell'unico quadriennio presente
        anniPeriodo = anniPeriodo - 1
      }
      minToApprove = parseFloat((3 * anniPeriodo).toFixed(3))
      minMetaToApprove = Math.max(parseFloat(((1 / 3) * anniPeriodo).toFixed(3)), creditiMetaPrimoAnno)

      let cfpValidiInAnniIscrizione = 0
      if (metaprofPerAnno[annoIscr]) {
        cfpValidiInAnniIscrizione += metaprofPerAnno[annoIscr]
      }
      if (metaprofPerAnno[annoIscr + 1]) {
        cfpValidiInAnniIscrizione += metaprofPerAnno[annoIscr + 1]
      }
      if (cfpValidiInAnniIscrizione >= creditiMetaPrimoAnno) {
        validoInAnniIscrizione = true
      } else {
        validoInAnniIscrizione = false
      }
    }

    const primoGiornoTriennio = moment(apertura + '-01-01', 'YYYY-MM-DD')
    const ultimoGiornoTriennio = moment(chiusura + '-12-31', 'YYYY-MM-DD')
    let giorniDiEsonero = 0
    let giorniInTriennio = ultimoGiornoTriennio.diff(primoGiornoTriennio, 'days')

    if (esoneri) {
      for (const esonero of esoneri) {
        let dataInizio = moment(esonero.data_inizio, 'YYYY-MM-DD')
        let dataFine = moment(esonero.data_fine, 'YYYY-MM-DD')

        if (dataFine.isBefore(primoGiornoTriennio) || dataInizio.isAfter(ultimoGiornoTriennio)) {
          continue
        }

        if (dataInizio.isBefore(primoGiornoTriennio)) {
          dataInizio = primoGiornoTriennio
        }

        if (dataFine.isAfter(ultimoGiornoTriennio)) {
          dataFine = ultimoGiornoTriennio
        }

        giorniDiEsonero += dataFine.diff(dataInizio, 'days')
      }

      let percEsonero = 0
      if (giorniInTriennio > 0) {
        percEsonero = giorniDiEsonero / giorniInTriennio
      }

      let percCrediti = 1 - percEsonero

      minToApprove *= percCrediti
      minMetaToApprove *= percCrediti
    }
  }

  useEffect(() => {
    if (
      !approvaCliccato &&
      validoInAnniIscrizione &&
      infoPerTriennio[triennioSelezionato.value] &&
      Math.round(infoPerTriennio[triennioSelezionato.value].cfp_validi * 1000) / 1000 >= minToApprove &&
      Math.round(infoPerTriennio[triennioSelezionato.value].cfp_metaprofessionali * 1000) / 1000 >= minMetaToApprove &&
      !approvazioni[triennioSelezionato.value] &&
      !isFrontOffice &&
      TokenStore.getInstance().isCurrentAccountODAF() &&
      TokenStore.getInstance().currentAccountHasRolesOR([RO_GEST_FORM_ODAF]) &&
      flagApprovazione
    ) {
      setShowModal(true)
    }
  }, [
    approvaCliccato,
    validoInAnniIscrizione,
    infoPerTriennio,
    triennioSelezionato,
    minToApprove,
    minMetaToApprove,
    approvazioni,
    isFrontOffice,
    flagApprovazione,
  ])

  return (
    <PageSection>
      <div className="container register-container">
        <GenericBreadCrumb paths={[basePath, myPath]} />
        <Spacer />

        <Title headingLevel="h1" size="lg">
          {myPath.label}
        </Title>
        {!isFrontOffice && (
          <GenericInput
            id="codice_fiscale"
            type={'text'}
            placeholder={'CODICE FISCALE'}
            label={'Codice Fiscale*'}
            value={codFisc}
            onChange={handleInput}
            onKeyDown={() => {}}
          />
        )}

        {loading ? (
          <GenericSpinner />
        ) : (
          <>
            <GenericSelect
              id="selettoreTriennio"
              label="Seleziona il triennio scelto:"
              value={triennioSelezionato}
              defaultValue={674}
              defaultOptions={TRIENNI_OPTIONS}
              onChange={(code, value) => {
                setTriennioSelezionato(value)
                setApprovaCliccato(false)
              }}
            />
            <Spacer />
            {!approvaCliccato &&
              validoInAnniIscrizione &&
              infoPerTriennio[triennioSelezionato.value] &&
              Math.round(infoPerTriennio[triennioSelezionato.value].cfp_validi * 1000) / 1000 >= minToApprove &&
              Math.round(infoPerTriennio[triennioSelezionato.value].cfp_metaprofessionali * 1000) / 1000 >= minMetaToApprove &&
              !approvazioni[triennioSelezionato.value] &&
              !isFrontOffice &&
              TokenStore.getInstance().isCurrentAccountODAF() &&
              TokenStore.getInstance().currentAccountHasRolesOR([RO_GEST_FORM_ODAF]) && (
                // tolto ruolo per CONAF Nazionale vedi requisito pag. 153 solo ODAF può validare il triennio
                <>
                  <Button variant="primary" onClick={() => setShowModal(true)} disabled={approvaCliccato}>
                    Approva Triennio
                  </Button>

                  <Modal isOpen={showModal} toggle={closeModal} centered>
                    <ModalHeader>Approvazione Triennio Formativo {triennioSelezionato.label}</ModalHeader>
                    <ModalBody>
                      <p>Sei sicuro di voler approvare il triennio per:</p>
                      <p>Iscritto: {codFisc}</p>
                      <p>CFP Totali Validi: {Math.round(infoPerTriennio[triennioSelezionato.value].cfp_validi * 1000) / 1000}</p>
                      <p>
                        CFP Caratterizzanti Validi:{' '}
                        {Math.round(
                          (infoPerTriennio[triennioSelezionato.value].cfp_validi - infoPerTriennio[triennioSelezionato.value].cfp_metaprofessionali) * 1000
                        ) / 1000}
                      </p>
                      <p>CFP Metaprofessionali Validi: {Math.round(infoPerTriennio[triennioSelezionato.value].cfp_metaprofessionali * 1000) / 1000}</p>
                    </ModalBody>
                    <ModalFooter>
                      <Button color="secondary" onClick={closeModal} style={{ marginRight: '10px' }}>
                        Annulla
                      </Button>
                      <Button color="primary" onClick={confermaApprovaTriennio}>
                        Conferma
                      </Button>
                    </ModalFooter>
                  </Modal>
                  <Spacer />
                </>
              )}
            {approvazioni[triennioSelezionato.value] && (
              <>
                <Button
                  variant="primary"
                  onClick={() => {
                    let idAllegato: string | null = approvazioni[triennioSelezionato.value].id_attestato

                    if (idAllegato) {
                      ottieniAllegato(idAllegato)
                    } else {
                      console.error('IDALLEGATO MISSING')
                    }
                  }}
                >
                  Scarica attestato regolarità formativa
                </Button>
                <Spacer />
              </>
            )}
            {infoPerTriennio[triennioSelezionato.value] ? (
              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Title headingLevel="h2" size="lg">
                  Totale CFP validi: {Math.round(infoPerTriennio[triennioSelezionato.value].cfp_validi * 1000) / 1000}. Di cui{' '}
                  {Math.round(infoPerTriennio[triennioSelezionato.value].cfp_metaprofessionali * 1000) / 1000} metaprofessionali
                </Title>
                <Button
                  variant="primary"
                  onClick={() => {
                    if (!codFisc) {
                      const { codiceFiscale, nome, cognome } = TokenStore.getInstance().getSpidData()

                      exportPDF(
                        datiPerTriennio[triennioSelezionato.value],
                        nome,
                        cognome,
                        codiceFiscale,
                        triennioSelezionato.label,
                        Math.round(infoPerTriennio[triennioSelezionato.value].cfp_validi * 1000) / 1000,
                        Math.round(infoPerTriennio[triennioSelezionato.value].cfp_metaprofessionali * 1000) / 1000
                      )
                    } else {
                      getSoggettoByCf(codFisc).then((response: any) => {
                        const { nome, cognome } = response.data[0]

                        exportPDF(
                          datiPerTriennio[triennioSelezionato.value],
                          nome,
                          cognome,
                          codFisc,
                          triennioSelezionato.label,
                          Math.round(infoPerTriennio[triennioSelezionato.value].cfp_validi * 1000) / 1000,
                          Math.round(infoPerTriennio[triennioSelezionato.value].cfp_metaprofessionali * 1000) / 1000
                        )
                      })
                    }
                  }}
                >
                  Scarica riepilogo formativo
                </Button>
              </div>
            ) : (
              <Title headingLevel="h2" size="lg">
                Totale CFP validi: 0. Di cui 0 metaprofessionali
              </Title>
            )}
            <PageSection>
              <TableComposable id="riepilogoCreditiFormativi" aria-label="Sort table" hidden={!infoPerTriennio[triennioSelezionato.value] && !loading}>
                <Thead>
                  <Tr>
                    {columns.map((column, index) => {
                      return (
                        <Th
                          key={`head${index}`}
                          id={column.field}
                          style={column.width ? { width: column.width, maxWidth: column.width, position: 'sticky' } : {}}
                        >
                          {column.label}
                        </Th>
                      )
                    })}
                  </Tr>
                </Thead>
                <Tbody>
                  {datiPerTriennio[triennioSelezionato.value] && (
                    <>
                      {datiPerTriennio[triennioSelezionato.value].map((row, index) => {
                        return (
                          <Tr key={`row${index}`}>
                            {columns.map((column, index) => {
                              return (
                                <Td key={`col${index}`} style={column.style || {}}>
                                  {column.render && column.render(row)}
                                  {!column.render && getColumnValue(column, row)}
                                </Td>
                              )
                            })}
                          </Tr>
                        )
                      })}
                    </>
                  )}
                </Tbody>
              </TableComposable>
              {!infoPerTriennio[triennioSelezionato.value] && codFisc && loading && <Row>Nessuna corrispondenza trovata</Row>}
            </PageSection>
          </>
        )}
      </div>
    </PageSection>
  )
}

export declare interface Column {
  label: string
  width?: any
  style?: any
  hidden?: boolean
  isSortable?: boolean
  field: string
  render?: any
  options?: any
}
export declare interface Riga {
  settore?: string
  tipo_corso?: string
  anno_calc_cfp?: number
  nome_corso?: string
  cod_attivita?: string
  cfp_riconosciuti?: number
  cfp_validi?: number
  organizzatore?: string
  attestato?: any
  id_corso?: string
  evento?: any
  attestatoPartecipazione?: any
  attivitaFormativaExt?: any
  settoreNum?: number
  tipologia_evento?: number
  dataInizio?: string
  dataFine?: string
}
export declare interface Option {
  value: number
  label: string
}

export default CreditiFormativi
