import React, {useMemo} from 'react'
import {Alert, Form} from 'design-react-kit'
import {useAreeProfessionali, useSdaf, useTipologieAttivitaTa1} from 'src/hooks/useReqOptions'
import createWrpInput from 'src/frontend/app/components/Form/WrpInput'
import GenericCard from 'src/frontend/app/components/GenericCard'
import GenericSelect from 'src/frontend/app/components/Form/Select'
import {
    AMBITI_TERRITORIALI_OPTIONS,
    MODALITA_DISPONIBILI_OPTIONS,
    TIPOLOGIE_ACCREDITAMENTO_OPTIONS,
    TIPOLOGIE_ACCREDITAMENTO_VALUES
} from 'src/formazione'

const FormDatiAccreditamento: React.FunctionComponent<FormDatiAccreditamentoProps> = ({ title, data, handleInput, onInitInput, errors, viewOnly }) => {
  const objKey = 'datiAccreditamento'
  const WrpInput = useMemo(() => createWrpInput({ objKey, onInit: onInitInput, onChange: handleInput, viewOnly }), [])

  const sdaf = useSdaf()
  const tipiProf = useAreeProfessionali()
  const tipiAttivita = useTipologieAttivitaTa1()

  const showFields = [TIPOLOGIE_ACCREDITAMENTO_VALUES.COMPLESSO, TIPOLOGIE_ACCREDITAMENTO_VALUES.SEMPLICE].includes(data?.[objKey]?.tipoAccreditamento?.value)
  const isTotal = data?.[objKey]?.tipoAccreditamento?.value === TIPOLOGIE_ACCREDITAMENTO_VALUES.TOTALE
  const isMulti = data?.[objKey]?.tipoAccreditamento?.value === TIPOLOGIE_ACCREDITAMENTO_VALUES.COMPLESSO

  return (
    <GenericCard cardHeader={title}>
      <Form>
        <div className="row">
          <div className="col-12">
            <WrpInput
              as={GenericSelect}
              id={'tipoAccreditamento'}
              placeholder="Selezione tipo accreditamento"
              label="Tipo Accreditamento"
              suffix="*"
              defaultOptions={TIPOLOGIE_ACCREDITAMENTO_OPTIONS}
              data={data}
              errors={errors}
            />
          </div>
        </div>
        {showFields && (
          <>
            <div className="row">
              <div className="col-12">
                <WrpInput
                  isMulti={isMulti}
                  as={GenericSelect}
                  id={'settoriDisciplinari'}
                  placeholder="Selezione settore disciplinare"
                  label="Settore Disciplinare"
                  suffix="*"
                  defaultOptions={sdaf}
                  data={data}
                  errors={errors}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <WrpInput
                  isMulti={isMulti}
                  as={GenericSelect}
                  id={'areePrestazioni'}
                  placeholder="Selezione area di prestazione"
                  label="Area di prestazione"
                  suffix="*"
                  defaultOptions={tipiProf}
                  data={data}
                  errors={errors}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <WrpInput
                  isMulti={isMulti}
                  as={GenericSelect}
                  id={'tipoAttivita'}
                  placeholder="Selezione tipo attività"
                  label="Tipo di attività"
                  suffix="*"
                  defaultOptions={tipiAttivita}
                  data={data}
                  errors={errors}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <WrpInput
                  isMulti={isMulti}
                  as={GenericSelect}
                  id={'modalita'}
                  placeholder="Selezione modalità"
                  label="Modalità"
                  suffix="*"
                  defaultOptions={MODALITA_DISPONIBILI_OPTIONS}
                  data={data}
                  errors={errors}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <WrpInput
                  as={GenericSelect}
                  id={'ambitoTerritoriale'}
                  placeholder="Selezione ambito territoriale"
                  label="Ambito territoriale"
                  suffix="*"
                  defaultOptions={AMBITI_TERRITORIALI_OPTIONS}
                  data={data}
                  errors={errors}
                />
              </div>
            </div>
          </>
        )}
        {isTotal && (
          <Alert color="warning">
            L'accreditamento Totale comprende tutti i settori disciplinari, le prestazioni, le tipologie di attività, le modalita e l'ambito Nazionale.
          </Alert>
        )}
      </Form>
    </GenericCard>
  )
}

interface FormDatiAccreditamentoProps {
  data: any
  errors: any
  title: string
  handleInput: any
  onInitInput?: any
  viewOnly?: boolean
}

export default FormDatiAccreditamento
