import * as React from 'react'
import {Form} from 'design-react-kit'
import {inputRequiredValidation} from 'src/utilities/utility'
import GenericSelect from 'src/frontend/app/components/GenericSelect'
import {GenericSpinner} from 'src/backoffice/app/components'
import GenericInput from 'src/frontend/app/components/GenericInput'
import GenericCheckbox from 'src/frontend/app/components/GenericCheckbox'
import {getComuniByProvinciaMsga} from 'src/processes/Comuni'
import {getAllProvince, getProvinciaBySiglaFO} from 'src/processes/Province'
import {getOrdiniBySigla} from 'src/processes/Ordini'
import {getDefaultAxiosConfig, getTrasferimentoOrdineResourceApi} from 'src/app/utils/ManagerRestGateway'
import {isCurrentMobileDevice} from 'src/utilities/deviceUtility'

export class FormRichiestaTrasfOrdine extends React.Component<GeneralFormRichiestaTrasfOrdineProps, any> {
    constructor(props) {
        super(props);
        this.state = {
            dataLoaded: false,
            provinciaResidenzaSelected: true,
            provinciaStudioSelected: true,
            ordineSelected: true,
            listaProvince: {}
        }
    }

    async componentDidMount(): Promise<void> {
        this.setState({ dataLoaded: false })
        this.setState({
            idSoggetto: this.props.idSoggetto,
            idIscrizione: this.props.idIscrizione,
            //dati residenziali
            provinciaResidenza: { label: this.props.datiResidenza?.provinciaEstesa, value: this.props.datiResidenza?.codiceProvinciaResidenza },
            comuneResidenza: { label: this.props.datiResidenza?.comuneResidenza, value: this.props.datiResidenza?.comuneResidenza },
            indirizzoResidenza: this.props.datiResidenza?.indirizzoResidenza,
            civicoResidenza: this.props.datiResidenza?.numeroCivico,
            capResidenza: this.props.datiResidenza?.capResidenza,

            //dati sede legale
            flagSedeLegaleDiversaDaResidenza: this.props.flagSedeLegaleDiversaDaResidenza,
            indirizzoStudio: this.props.datiSedeLegale?.slIndirizzoStudio,
            provinciaStudio: { label: this.props.datiSedeLegale?.provinciaEstesa, value: this.props.datiSedeLegale?.slProvinciaStudio },
            cittaStudio: { label: this.props.datiSedeLegale?.slCittaStudio, value: this.props.datiSedeLegale?.slCittaStudio },
            civicoStudio: this.props.datiSedeLegale?.slNumeroCivicoStudio,
            capStudio: this.props.datiSedeLegale?.slCapStudio
        })

        //chiamata al servizio che restituisce tutte le Province, ordinate in ordine alfabetico
        await getAllProvince().then(async (response: any) => {
            var listaProvince = [{ label: '', value: '' }];

            response.data.map((provincia, id) => {
                return (
                    listaProvince[id] = {
                        label: provincia.denominazioneUnitaTerritorialeSovracomunale,
                        value: provincia.siglaAutomobilistica
                    });
            })

            let sortedProvince = listaProvince.sort(
                (p1, p2) => (p1.label > p2.label) ? 1 : (p1.label < p2.label) ? -1 : 0);

            this.setState({ listaProvince: sortedProvince })
        }).catch((e) => {
            throw new Error(e)
        })

        //chiamata alla funzione che recupera la lista degli ordini della select in base alla/e provincia/e inserita/e
        this.getOrdine();

        this.setState({ dataLoaded: true })
    }

    componentDidUpdate(prevProps: Readonly<GeneralFormRichiestaTrasfOrdineProps>, prevState: Readonly<any>, snapshot?: any): void {
        // se cambia una tra provincia di residenza e provincia sede legale viene aggiornata la lista degli ordini
        if (prevState.provinciaResidenza !== this.state.provinciaResidenza || prevState.provinciaStudio !== this.state.provinciaStudio || prevState.flagSedeLegaleDiversaDaResidenza !== this.state.flagSedeLegaleDiversaDaResidenza) {
            this.getOrdine();
        }
    }

    getOrdine = () => {
        this.setState({ordineSelected: false})
        //chiamata al servizio che restituisce la lista degli ordini relativi alle province indicate
        getOrdiniBySigla(this.state.flagSedeLegaleDiversaDaResidenza === true ? 1 : 0, this.state.provinciaStudio?.value, this.state.provinciaResidenza?.value, 'PF').then((response: any) => {
            var ordiniTerritoriali = [{ label: '', value: '' }];
            if (response) response.data.map((ordine, id) => {
                ordiniTerritoriali[id] = {
                    label: ordine['denominazioneGruppo'],
                    value: ordine['codiceProvinciaResidenza'],
                };
            })
            this.setState({ listaOrdini: ordiniTerritoriali, ordineSelected: true, ordine: undefined })
        }).catch((e) => {
            throw new Error(e)
        })
    }

    handleInput = async (event, field, type) => {

        //Caso SELECT:
        if (type === 'select') {
            if (field === 'provinciaResidenza') {

                this.setState({ provinciaResidenzaSelected: false })

                //Chiamata al servizio getProvinciaBySiglaFO che recupera la provincia di residenza by Sigla
                getProvinciaBySiglaFO(event.value).then(async (response) => {
                    this.setState({ provinciaResidenza: { label: response.data.denominazioneUnitaTerritorialeSovracomunale, value: event.value } })

                    //Chiamata al servizio getComuniByProvinciaMsga che recupera la lista dei comuni in base alla provincia
                    getComuniByProvinciaMsga(response.data.denominazioneUnitaTerritorialeSovracomunale).then(async comuni => {
                        this.setState({ listaComuniResidenza: comuni, provinciaResidenzaSelected: true, comuneResidenza: undefined});
                    });
                })
            }

            else if (field === 'comuneResidenza')
                this.setState({ comuneResidenza: { label: event.value, value: event.value } })

            else if (field === 'provinciaStudio') {

                this.setState({ provinciaStudioSelected: false })

                //Chiamata al servizio getProvinciaBySiglaFO che recupera la provincia dello studio by Sigla
                getProvinciaBySiglaFO(event.value).then(async (response) => {
                    this.setState({ provinciaStudio: { label: response.data.denominazioneUnitaTerritorialeSovracomunale, value: event.value } })

                    //Chiamata al servizio getComuniByProvinciaMsga che recupera la lista dei comuni in base alla provincia
                    getComuniByProvinciaMsga(response.data.denominazioneUnitaTerritorialeSovracomunale).then(async comuni => {
                        this.setState({ listaComuniStudio: comuni, provinciaStudioSelected: true, cittaStudio: undefined });
                    });
                })
            }

            else if (field === 'cittaStudio')
                this.setState({ cittaStudio: { label: event.value, value: event.value } })

            else if (field === 'ordine')
                this.setState({ ordine: event.value })
        }

        //Caso INPUT:
        else if (type === 'input') {
            if (field === 'indirizzoResidenza')
                this.setState({ indirizzoResidenza: event.target.value })

            else if (field === 'civicoResidenza')
                this.setState({ civicoResidenza: event.target.value })

            else if (field === 'capResidenza')
                this.setState({ capResidenza: event.target.value })

            else if (field === 'indirizzoStudio')
                this.setState({ indirizzoStudio: event.target.value })

            else if (field === 'civicoStudio')
                this.setState({ civicoStudio: event.target.value })

            else if (field === 'capStudio')
                this.setState({ capStudio: event.target.value })

            else if (field === 'motivo')
                this.setState({ motivo: event.target.value })
        }

        //Caso CHECKBOX:
        else if (type === 'checkbox') {
            if (field === 'flagSedeLegaleDiversaDaResidenza')
                this.setState({ flagSedeLegaleDiversaDaResidenza: event.target.checked })
        }
    }

    handleSubmit = async event => {
        const { idSoggetto, idIscrizione, provinciaResidenza, comuneResidenza, indirizzoResidenza, civicoResidenza, capResidenza, flagSedeLegaleDiversaDaResidenza, indirizzoStudio, provinciaStudio, cittaStudio, civicoStudio, capStudio, ordine, motivo } = this.state
        /*--------------------------------------------- Validazione campi obbligatori ------------------------------------------------*/
        var requiredFields = [
            { value: provinciaResidenza?.value, label: 'Provincia di residenza' },
            { value: comuneResidenza?.value, label: 'Città di residenza' },
            { value: indirizzoResidenza, label: 'Indirizzo di residenza' },
            { value: civicoResidenza, label: 'Numero civico residenza' },
            { value: capResidenza, label: 'Cap residenza' },
            flagSedeLegaleDiversaDaResidenza ? { value: provinciaStudio?.label, label: 'Provincia sede legale studio' } : null,
            flagSedeLegaleDiversaDaResidenza ? { value: cittaStudio?.label, label: 'Città sede legale studio' } : null,
            flagSedeLegaleDiversaDaResidenza ? { value: indirizzoStudio, label: 'Indirizzo sede legale studio' } : null,
            flagSedeLegaleDiversaDaResidenza ? { value: civicoStudio, label: 'Numero civico sede legale studio' } : null,
            flagSedeLegaleDiversaDaResidenza ? { value: capStudio, label: 'Cap sede legale studio' } : null,
            { value: ordine, label: 'Ordine territoriale richiesto' },
            { value: motivo, label: 'Motivo della richiesta' }
        ]

        var errorRequired: any = inputRequiredValidation(requiredFields)
        if (errorRequired) {
            event.preventDefault()
            return this.props.messaggio({ messaggio: errorRequired.messaggio, type: errorRequired.type })
        }
        /*------------------------------------------------------------------------------------------------------------------------------*/
        else {
            event.preventDefault()
            var params = {
                idSoggetto: idSoggetto,
                idIscrizioneTerritoriale: idIscrizione,
                residenzaTrasferimentoOrdineDTO: {
                    "codiceProvinciaResidenza": provinciaResidenza?.value ? provinciaResidenza?.value : '',
                    "comuneResidenza": comuneResidenza?.value ? comuneResidenza?.value : '',
                    "indirizzoResidenza": indirizzoResidenza ? indirizzoResidenza : '',
                    "numeroCivico": civicoResidenza ? civicoResidenza : '',
                    "capResidenza": capResidenza ? capResidenza : ''
                },
                sedeLegaleTrasferimentoOrdineDTO: {
                    "slIndirizzoStudio": flagSedeLegaleDiversaDaResidenza ? (indirizzoStudio ? indirizzoStudio : '') : null,
                    "slNumeroCivicoStudio": flagSedeLegaleDiversaDaResidenza ? (civicoStudio ? civicoStudio : '') : null,
                    "slProvinciaStudio": flagSedeLegaleDiversaDaResidenza ? (provinciaStudio?.value ? provinciaStudio?.value : '') : null,
                    "slCittaStudio": flagSedeLegaleDiversaDaResidenza ? (cittaStudio?.value ? cittaStudio?.value : '') : null,
                    "slCapStudio": flagSedeLegaleDiversaDaResidenza ? (capStudio ? capStudio : '') : null
                },
                siglaOrdineTerritorialeSelected: ordine ? ordine : '',
                motivoRichiesta: motivo ? motivo : '',
                flagSedeLegaleDiversoDaProvinciaResidenza: flagSedeLegaleDiversaDaResidenza === true ? 1 : 0
            }

            getTrasferimentoOrdineResourceApi().mswfTrasferimentoOrdineSalvaPost(params, getDefaultAxiosConfig()).then((response) => {
                if (response.status === 200)
                    return this.props.messaggio({ messaggio: "Richiesta inviata con successo", type: "success" })
            }).catch(() => {
                return this.props.messaggio({ messaggio: "C'è stato un problema nell'invio dei dati", type: "danger" })
            })
        }
    }

    render() {
        const { ordineSelected, provinciaResidenzaSelected, provinciaStudioSelected, listaProvince, listaOrdini, listaComuniResidenza, ordine, listaComuniStudio, dataLoaded, provinciaResidenza, comuneResidenza, indirizzoResidenza, civicoResidenza, capResidenza, flagSedeLegaleDiversaDaResidenza, provinciaStudio, cittaStudio, indirizzoStudio, civicoStudio, capStudio } = this.state
        return (
            (!dataLoaded) ?
                (
                    <GenericSpinner />
                ) :
                <div>
                    <Form id={'idRichTrasfOrdine'} onSubmit={this.handleSubmit}>
                        <div className='row'>
                            <div className={isCurrentMobileDevice() ? null : 'col-6'}>
                                <GenericSelect elementId="provinciaResidenza" name={'provinciaResidenza'} defaultValue={provinciaResidenza?.label ? provinciaResidenza : null} label='Provincia di residenza *' placeholder={"Selezionare la provincia"} onChange={(e) => this.handleInput(e, 'provinciaResidenza', 'select')} defaultOptions={listaProvince} isRequired />
                            </div>
                            {provinciaResidenzaSelected ?
                                <div className={isCurrentMobileDevice() ? null : 'col-6'}>
                                    <GenericSelect elementId="comuneResidenza" name={'comuneResidenza'} defaultValue={comuneResidenza?.label ? comuneResidenza : null} label='Città di residenza *' onChange={(e) => this.handleInput(e, 'comuneResidenza', 'select')} placeholder={"Selezionare la città"} defaultOptions={listaComuniResidenza} isRequired />
                                </div>
                                : null
                            }
                        </div>
                        <div className='row'>
                            <div className={isCurrentMobileDevice() ? null : 'col-6'}>
                                <GenericInput type={'text'} id='indirizzoResidenza' name={'indirizzoResidenza'} label='Indirizzo di residenza *' defaultValue={indirizzoResidenza ? indirizzoResidenza : null} placeholder={"Inserire indirizzo"} onChange={(e) => this.handleInput(e, 'indirizzoResidenza', 'input')} />
                            </div>
                            <div className={isCurrentMobileDevice() ? null : 'col-3'}>
                                <GenericInput type={'text'} id='civicoResidenza' name={'civico'} label={'Numero civico residenza *'} defaultValue={civicoResidenza ? civicoResidenza : null} placeholder={"Inserire civico"} onChange={(e) => this.handleInput(e, 'civicoResidenza', 'input')} />
                            </div>
                            <div className={isCurrentMobileDevice() ? null : 'col-3'}>
                                <GenericInput type='cap' id='capResidenza' name={'cap'} defaultValue={capResidenza ? capResidenza : null} label='Cap residenza *' placeholder={"Inserire cap"} onChange={(e) => this.handleInput(e, 'capResidenza', 'input')} />
                            </div>
                        </div>
                        <div className='row'>
                            <div className={isCurrentMobileDevice() ? null : 'col-6'}>
                                <GenericCheckbox label={'Sede legale studio diversa da residenza?'} id='sede_diversa' isChecked={flagSedeLegaleDiversaDaResidenza} onChange={(e) => this.handleInput(e, 'flagSedeLegaleDiversaDaResidenza', 'checkbox')} />
                            </div>
                        </div>
                        <br />
                        <div className='row' hidden={!flagSedeLegaleDiversaDaResidenza}>
                            <div className='row'>
                                <div className={isCurrentMobileDevice() ? null : 'col-6'}>
                                    <GenericSelect elementId='provinciaStudio' name={'provinciaStudio'} defaultValue={provinciaStudio?.label ? provinciaStudio : null} label='Provincia sede legale studio *' placeholder={"Selezionare la provincia"} defaultOptions={listaProvince} isRequired={flagSedeLegaleDiversaDaResidenza} onChange={(e) => this.handleInput(e, 'provinciaStudio', 'select')} />
                                </div>
                                {provinciaStudioSelected ?
                                    <div className={isCurrentMobileDevice() ? null : 'col-6'}>
                                        <GenericSelect elementId="comuneStudio" placeholder={"Selezionare la città"} name={'comuneStudio'} label='Città sede legale studio  *' defaultValue={cittaStudio?.label ? cittaStudio : null} defaultOptions={listaComuniStudio} isRequired={flagSedeLegaleDiversaDaResidenza} onChange={(e) => this.handleInput(e, 'cittaStudio', 'select')} />
                                    </div>
                                    : null
                                }
                            </div>
                            <div className='row'>
                                <div className={isCurrentMobileDevice() ? null : 'col-6'}>
                                    <GenericInput type='text' id='indirizzoStudio' name={'indirizzoStudio'} label={'Indirizzo sede legale studio *\n'} placeholder={"Inserire indirizzo"} defaultValue={indirizzoStudio ? indirizzoStudio : null} onChange={(e) => this.handleInput(e, 'indirizzoStudio', 'input')} />
                                </div>
                                <div className={isCurrentMobileDevice() ? null : 'col-3'}>
                                    <GenericInput type='text' id='civicoStudio' name={'civicoStudio'} label='Numero civico sede legale studio *' defaultValue={civicoStudio ? civicoStudio : null} placeholder={"Inserire civico"} onChange={(e) => this.handleInput(e, 'civicoStudio', 'input')} />
                                </div>
                                <div className={isCurrentMobileDevice() ? null : 'col-3'}>
                                    <GenericInput type='cap' id='capStudio' name={'capStudio'} defaultValue={capStudio ? capStudio : null} placeholder={"Inserire cap"} label='Cap sede legale studio *' onChange={(e) => this.handleInput(e, 'capStudio', 'input')} />
                                </div>
                            </div>
                            <br />
                        </div>
                        <div className='row'>
                            {(ordineSelected) ?
                                <div className={isCurrentMobileDevice() ? null : 'col-6'}>
                                    <GenericSelect elementId='nuovoOrdine' name='nuovoOrdine' label='Ordine territoriale richiesto *' defaultValue={ordine} placeholder={"Selezionare l'ordine"} defaultOptions={listaOrdini} isRequired onChange={(e) => this.handleInput(e, 'ordine', 'select')} />
                                </div>
                                : null
                            }
                            <div className={isCurrentMobileDevice() ? null : 'col-6'}>
                                <GenericInput type={"text"} id='motivo' placeholder={"Inserire motivo"} name='motivo' label='Motivo della richiesta *' onChange={(e) => this.handleInput(e, 'motivo', 'input')} />
                            </div>
                        </div>
                    </Form>
                </div>
        )
    }
}

export declare interface GeneralFormRichiestaTrasfOrdineProps {
    messaggio?: any;
    idSoggetto?: any;
    idIscrizione?: any;
    datiResidenza?: any;
    datiSedeLegale?: any;
    flagSedeLegaleDiversaDaResidenza?: any;
}
