import {BindUserGroupRolesDeliberaDTO, BindUserGroupRolesDTO, GenericRequestDTO} from '@parsec/msga'
import {getAnagrafeResourcesApi, getDefaultAxiosConfig} from 'src/app/utils/ManagerRestGateway'

/**
 * Recupera gli ordini territoriali relativi all'operatore corrente
 * @param genericRequestDTO
 * @returns
 */
export async function getSpecificGroupIdsByIdSoggettoAndIdSoggettoOperatorePost(genericRequestDTO: any) {
    try {
        const body: GenericRequestDTO = {
            params: {
                SPECIFIC_GROUP_ID: {
                    value: genericRequestDTO.specificGroupID
                },
                CF: {
                    value: genericRequestDTO.cf
                },
                ID_USER_LOGGED: {
                    value: genericRequestDTO.userLoggedID
                }
            }
        }
      //  ;
        const response = await getAnagrafeResourcesApi().msgaAnagrafeFindSpecificGroupIdsByIdSoggettoAndIdSoggettoOperatorePost(body, getDefaultAxiosConfig());
        return response;
    } catch (err) {
        console.error("Errore durante la chiamata msgaAnagrafeFindSpecificGroupIdsByIdSoggettoAndIdSoggettoOperatorePost - " + err)
        throw new Error("Errore durante la chiamata msgaAnagrafeFindSpecificGroupIdsByIdSoggettoAndIdSoggettoOperatorePost - " + err);
    }
}

/**
 * Recupera i ruoli assegnati dall'utente corrente a quello selezionato
 * @param genericRequestDTO
 * @returns
 */
export async function getKcRolesAssignedBySoggettoAndSpecificGroup(genericRequestDTO: any) {
    try {
        const body: GenericRequestDTO = {
            params: {
                TIPO: {
                    value: genericRequestDTO.tipo
                },
                ID_SOGGETTO: {
                    value: genericRequestDTO.idSoggetto
                }
            }
        }
        const response = await getAnagrafeResourcesApi().msgaAnagrafeFindKcRolesAssignedBySoggettoAndSpecificGroupPost(body, getDefaultAxiosConfig());
        return response;
    } catch (err) {
        console.error("Errore durante la chiamata msgaAnagrafeFindKcRolesAssignedBySoggettoAndSpecificGroupPost - " + err)
        throw new Error("Errore durante la chiamata msgaAnagrafeFindKcRolesAssignedBySoggettoAndSpecificGroupPost - " + err);
    }
}

/**
 * Recupera i ruoli rimossi dall'utente corrente a quello selezionato
 * @param genericRequestDTO
 * @returns
 */
export async function getKcRolesNotAssignedBySoggettoAndSpecificGroup(genericRequestDTO: any) {
    try {
        const body: GenericRequestDTO = {
            params: {
                TIPO: {
                    value: genericRequestDTO.tipo
                },
                ID_SOGGETTO: {
                    value: genericRequestDTO.idSoggetto
                }
            }
        }
        const response = await getAnagrafeResourcesApi().msgaAnagrafeFindKcRolesNotAssignedBySoggettoAndSpecificGroupPost(body, getDefaultAxiosConfig());
        return response;
    } catch (err) {
        console.error("Errore durante la chiamata msgaAnagrafeFindKcRolesNotAssignedBySoggettoAndSpecificGroupPost - " + err)
        throw new Error("Errore durante la chiamata msgaAnagrafeFindKcRolesNotAssignedBySoggettoAndSpecificGroupPost - " + err);
    }
}

/**
 * Salva i ruoli sul database: rimuove o assegna i ruoli in base al flag delete
 * @param bindUserGroupRolesDTO
 * @returns
 */
export async function bindUserGroupRoles(bindUserGroupRolesDTO: any) {
    try {
        const body: BindUserGroupRolesDTO = {
            cfToAssignee: bindUserGroupRolesDTO.cfToAssignee,
            cfOperatore: bindUserGroupRolesDTO.cfOperatore,
            genericGroupName: bindUserGroupRolesDTO.genericGroupName,
            tipo: bindUserGroupRolesDTO.tipo,
            roleNamesDaCancellare: bindUserGroupRolesDTO.roleNamesDaCancellare,
            roleNamesDaAssegnare: bindUserGroupRolesDTO.roleNamesDaAssegnare,
            deleted: bindUserGroupRolesDTO.deleted,
            deleteProfile: bindUserGroupRolesDTO.deleteProfile
        } as any
        const response = await getAnagrafeResourcesApi().msgaAnagrafeBindUserGroupRolesPost(body, getDefaultAxiosConfig());
        return response;
    } catch (err) {
        console.error("Errore durante la chiamata msgaAnagrafeBindUserGroupRolesPost - " + err)
        throw new Error("Errore durante la chiamata msgaAnagrafeBindUserGroupRolesPost - " + err);
    }
}

/**
 * Salva i ruoli sul database: rimuove o assegna i ruoli in base al flag delete
 * @param bindUserGroupRolesDTO
 * @returns
 */
export async function bindUserGroupRolesDelibera(bindUserGroupRolesDTO: any, dataAtto: String, estremiAtto: String, allegatoDTO: any) {
    try {
        const body: BindUserGroupRolesDeliberaDTO = {
            bindUserGroupRolesDTO: {
                cfToAssignee: bindUserGroupRolesDTO.cfToAssignee,
                cfOperatore: bindUserGroupRolesDTO.cfOperatore,
                genericGroupName: bindUserGroupRolesDTO.genericGroupName,
                tipo: bindUserGroupRolesDTO.tipo,
                roleNamesDaCancellare: bindUserGroupRolesDTO.roleNamesDaCancellare,
                roleNamesDaAssegnare: bindUserGroupRolesDTO.roleNamesDaAssegnare,
                deleted: bindUserGroupRolesDTO.deleted
            },
            dataAtto: dataAtto,
            estremiAtto: estremiAtto,
            allegatoDTO: allegatoDTO
        } as any
        const response = await getAnagrafeResourcesApi().msgaAnagrafeBindUserGroupRolesDeliberaPost(body, getDefaultAxiosConfig());
        return response;
    } catch (err) {
        console.error("Errore durante la chiamata msgaAnagrafeBindUserGroupRolesDeliberaPost - " + err)
        throw new Error("Errore durante la chiamata msgaAnagrafeBindUserGroupRolesDeliberaPost - " + err);
    }
}
