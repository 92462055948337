import React, {useState} from 'react'
import TextArea from 'src/frontend/app/components/Form/TextArea'
import moment from 'moment'
import FormSubmit, {STATUS} from 'src/frontend/app/components/Form/GenericSubmit'
import {Label} from 'design-react-kit'
import {getDefaultAxiosConfig, getFormazioneResourceApi} from 'src/app/utils/ManagerRestGateway'
import {aggiornaDatiHeadPiano} from 'src/model/PianiFormativi'

const PofStatusIntegrazioni: React.FunctionComponent<any> = ({ pof, isSegnala }) => {
  const [dataSegnalazione, setDataSegnalazione] = useState('')

  const [editStatus, setEditStatus] = useState<any>()
  const [editMessage, setEditMessage] = useState<any>()

  const handleInput = (cod, val) => {
    setDataSegnalazione(val)
  }

  const onSubmit = async () => {
    try {
      // TODO change to include allegati
      if (dataSegnalazione === '') {
        setEditStatus(STATUS.FAILURE)
        setEditMessage('Controllare i dati inseriti')
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
        return null
      }
      setEditStatus(STATUS.LOADING)

      const _data = {
        stato: isSegnala ? 5 : 4,
        integr_descr_gen_pform: dataSegnalazione,
      }

      await aggiornaDatiHeadPiano(pof.id, _data)

      await getFormazioneResourceApi().msfoFormazioneSegnalaNonconformitaPformativoPost(pof.id, getDefaultAxiosConfig())

      setEditStatus(STATUS.SUCCESS)
      setEditMessage('Dati salvati con successo')
    } catch (e) {
      setEditStatus(STATUS.FAILURE)
      setEditMessage('Impossibile salvare i dati')
    }
    return null
  }

  return (
    <div>
      <div className={'container'}>
        <div className={'row'}>
          <div className={'col-3'}>
            <span className={'text-warning'}>
              {isSegnala ? `Segnala non conformità` : `Richieste integrazioni il ${moment(pof.data_modifica).format('DD/MM/YYYY')}`}
            </span>
          </div>
          <div className={'col-9 '}>
            <TextArea
              className={'fill'}
              isDisabled={!isSegnala}
              label={'Integrazioni'}
              value={isSegnala ? dataSegnalazione : pof.suggerimenti}
              onChange={handleInput}
              rows={8}
            />
            {isSegnala && (
              <FormSubmit title="Conferma modifica" buttonLabel="Modifica" alertMessage={editMessage} status={editStatus} onSubmit={onSubmit}>
                <Label>Per modificare il piano cliccare "Modifica".</Label>
              </FormSubmit>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default PofStatusIntegrazioni
