/* eslint-disable react/no-direct-mutation-state */
import * as React from 'react'
import {
    PATH_TO_BO_ANAG_ELENCO_ISCRITTI,
    PATH_TO_BO_ATTI_DISCIPLINARI,
    PATH_TO_BO_ATTI_DISCIPLINARI_CANCELLA
} from 'src/app/utils/RoutesConstants'
import GenericSpinner from 'src/backoffice/app/components/GenericSpinner'
import {cancellaDisciplinari, getDisciplinareById} from 'src/processes/disciplinari/Disciplinari'
import GenericModal from '../../components/GenericModal'
import FormCancellaDisciplinari from './form/FormCancellaDisciplinare'
import {convertUrlParamsToObject} from 'src/utilities/utility'
import GenericAlert from 'src/frontend/app/components/GenericAlert'
import GenericBreadCrumb from 'src/frontend/app/components/GenericBreadCrumb'

var breadcrumbOptions = [
  { label: "Anagrafica", link: "#" },
  { label: "Elenco iscritti", link: PATH_TO_BO_ANAG_ELENCO_ISCRITTI},
  { label: "Atti disciplinari", link: PATH_TO_BO_ATTI_DISCIPLINARI_CANCELLA },
  { label: "Cancella Sanzioni Disciplinari", link: PATH_TO_BO_ATTI_DISCIPLINARI_CANCELLA },
]

class CreazioneSanzioneDisciplinari extends React.Component<any , any>{
    constructor(props) {
      super(props);
      this.state = {
          prevTab: "",
          idIscrizione: 1, //idIscrizione is passed with props and next will be a GET to take data from db
          message:"",
          isSubmitted:false,
          fields: {},
          disciplinari : {},
          errors: [],
          modalOpen:false,
          modalText:"testo finestra modale",
          modalTitle:"titolo finestra modale"
      };
  }

  async componentDidMount(): Promise<void> {
    var url = window.location.search?.split("?")[1]
    var urlParams = convertUrlParamsToObject(url)

    if (!urlParams?.id || !urlParams?.idIscrizione || !urlParams?.tipologiaIscrizione || !urlParams?.cf) {
      this.props.history.push(PATH_TO_BO_ANAG_ELENCO_ISCRITTI);
    }
    else {
      let id = urlParams?.id;
      let idIscrizione = urlParams?.idIscrizione
      let tipologiaIscrizione = urlParams?.tipologiaIscrizione
      let cf = urlParams?.cf
      this.setState({ idIscrizione: idIscrizione, tipologiaIscrizione: tipologiaIscrizione, cf: cf, id: urlParams?.id })

      getDisciplinareById(id).then(async (responseDisciplinare: any)=> {

        await this.setState({disciplinari: responseDisciplinare, isLoaded: true});
          let fields = this.state.fields;
          fields = responseDisciplinare;
          await this.setState({ fields: fields, disciplinari:fields,  isLoaded: true });
      }).catch(async () => {
                  setTimeout(() => {
                      this.setState({ message: "C'è stato un errore durante il recupero dei dati", typeMessage: 'danger' })
                  }, 3000);
                  await this.setState({isLoaded: false})
        })
    }

  }

  submitMessageResult = (alert: any) => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    this.setState({ message: alert.messaggio, typeMessage: alert.type })
  }

  inviaDisciplinari = async (disciplinari: any) => {
    await cancellaDisciplinari(disciplinari.id)
    .then((response: any) => {

      this.showModal(true, "Messaggio", "Disciplinare - N. delibera " + disciplinari.deliberaNr + " - cancellato con successo!");
      })
      .catch((err) => {
        this.showModal(true, "Errore.", err.message.replace("Service", ""));
      })
  }

  showModal = async (booleanValue, title, text) => {
    await this.setState({
        modalOpen: booleanValue,
        modalTitle: title,
        modalText:text,
        isLoaded: true,
    });


  }

  closeModal = () => {
    this.setState({modalOpen:false});
    this.props.history.push(PATH_TO_BO_ATTI_DISCIPLINARI + '?cf=' + this.state.cf + '&idIscrizione=' + this.state.idIscrizione + '&tipologiaIscrizione=' + this.state.tipologiaIscrizione);
  }

  goToDisciplinari = (redirectLink: any) => {
    this.props.history.push(
        redirectLink + '?cf=' + this.state.cf + '&idIscrizione=' + this.state.idIscrizione + '&tipologiaIscrizione=' + this.state.tipologiaIscrizione
    );
}

  render() {

    const { typeMessage, message} = this.state;

    return(
      <div key={"creazioneSanzioneDisciplinare"} className='container-fluid'>
          <div className="container-fluid">
            <div className='row'>
              <div className='col-10 offset-1'>
                  {
                  (this.state.isLoaded) ?
                    <div>
                         <div className="row">
                            <div className="col-10 offset-1 mt-5">
                              <GenericAlert hidden={message === ''} label={message} color={typeMessage}/>
                              <GenericBreadCrumb paths={breadcrumbOptions} />
                            </div>
                          </div>
                       <FormCancellaDisciplinari inviaDisciplinari={this.inviaDisciplinari} data={this.state.disciplinari} disciplinari={this.state.disciplinari} prevData={this.state.disciplinari} messaggio={this.submitMessageResult} goToDisciplinari={this.goToDisciplinari}/>
                       <GenericModal
                                title={this.state.modalTitle}
                                text={this.state.modalText}
                                modalOpen={this.state.modalOpen}
                                closeButton={this.closeModal}
                        />
                    </div>
                    : <GenericSpinner></GenericSpinner>
                  }
              </div>
            </div>
          </div>
      </div>
    )
  }
};
export declare interface InserisciProps {
  disciplinari?:any;
}

export default CreazioneSanzioneDisciplinari  ;
