import * as React from 'react'
import {GenericTextArea} from '../../../components/GenericTextArea'
import GenericUploadFront from 'src/frontend/app/components/GenericUpload'
import {Label} from 'design-react-kit'
import {Form} from '@patternfly/react-core'
import {inputRequiredValidation} from 'src/utilities/utility'
import {GenericTextInput} from 'src/backoffice/app/components'
import {getCambioSezioneResourceApi, getDefaultAxiosConfig} from 'src/app/utils/ManagerRestGateway'
import {isCurrentMobileDevice} from 'src/utilities/deviceUtility'

export class FormRifiutaCambioSezione extends React.Component<GeneralFormRifiutaCambioSezioneProps, any> {
  handleSubmit = async (event) => {
    /*--------------------------------------------- Validazione campi obbligatori ------------------------------------------------*/
    var requiredFields = [
      { value: event.target.motivoRigetto.value, label: 'Motivo del rigetto' },
      { value: event.target.deliberaRigetto.value, label: 'Delibera rigetto' },
      { value: event.target.dataDelibera.value, label: 'Data delibera' },
    ]

    var errorRequired: any = inputRequiredValidation(requiredFields)
    if (errorRequired !== undefined && errorRequired !== '') {
      event.preventDefault()
      return this.props.messaggio({ messaggio: errorRequired.messaggio, type: errorRequired.type })
    } else {
    /*----------------------------------------------------------------------------------------------------------------------------*/
      event.preventDefault()
      //chiamata al servizio CambioSezioneValuta
      var idIscrizione = this.props.idIscrizione
      var idCambioSezione = this.props.idCambioSezione

      const param = {
        esitoValutazione: 'respinta',
        idIscrizione: this.props.idIscrizione,
        valutazioneDTO: {
          motivoRigetto: event.target.motivoRigetto.value ? event.target.motivoRigetto.value : '',
          deliberaIscrizione: event.target.deliberaRigetto.value ? event.target.deliberaRigetto.value : '',
          dataDeliberaRifiuto: event.target.dataDelibera.value ? event.target.dataDelibera.value : '',
        },
        cambioSezioneDTO: {
          idCambioSezione: idCambioSezione,
          idIscrizione: idIscrizione,
          listaAllegatoDTO: this.state?.fileDeliberaRigetto ? [this.state.fileDeliberaRigetto] : [],
        },
      }

      await getCambioSezioneResourceApi()
        .mswfCambioSezioneValutaPost(param, getDefaultAxiosConfig())
        .then(() => {
          return this.props.messaggio({ messaggio: 'Dati salvati con successo', type: 'success' })
        })
        .catch((err) => {
          return this.props.messaggio({ messaggio: "C'è stato un problema nell'invio dei dati", type: 'danger' })
        })
    }
  }

  render() {
    const getDatiDeliberaRigetto = (fileData) => {
      this.setState({ fileDeliberaRigetto: fileData })
    }

    return (
      <div>
        <Form id="rifiuta" onSubmit={this.handleSubmit}>
          <div>
            <GenericTextArea
              className="w-50"
              label={'Motivo del rigetto'}
              id={'motivoRigetto'}
              ariaLabel={'textarea-motivoRigetto'}
              placeholder={'Inserire motivo'}
              isRequired
            />
          </div>
          <div className="row">
            <div className={isCurrentMobileDevice() ? null : 'col-6'}>
              <GenericTextInput className="w-75" type={'text'} label={'Delibera rigetto'} id={'deliberaRigetto'} placeholder={'Inserire delibera'} isRequired />
            </div>
            <div className={isCurrentMobileDevice() ? null : 'col-6'}>
              <GenericTextInput className="w-75" type={'date'} id={'dataDelibera'} label={'Data delibera'} placeholder={'Selezionare data'} isRequired />
            </div>
          </div>
          <div>
            <div className="row">
              <Label>
                <strong>File delibera</strong>
              </Label>
            </div>
            <div className="row">
              <div className={isCurrentMobileDevice() ? null : 'col-6'}>
                <GenericUploadFront
                  id={'idFileDeliberaRigetto'}
                  datiAllegato={getDatiDeliberaRigetto}
                  tipologiaAllegato={'delibera_rigetto_iot'}
                  acceptedExtensionFile={['png', 'pdf']}
                />
              </div>
            </div>
          </div>
        </Form>
      </div>
    )
  }
}

export declare interface GeneralFormRifiutaCambioSezioneProps {
  messaggio?: any
  idIscrizione?: any
  idCambioSezione?: any
}
