import React from 'react'
import {DualListSelector} from '@patternfly/react-core'

class GenericDualList extends React.Component<GeneralDualList, any> {
    constructor(props) {
        super(props);
        this.state = {
            options: props.options,
            chosenOptions: props.chosenOptions,
            isLoading: false,
            isRejected: false,
            label: props.label,
            typeFileAccept: props.typeFileAccept,
            maxSizeByte: props.maxSizeByte,
            placeholder: props.placeholder,
            isSearchable: props.isSearchable,
            labelSX: props.labelSX,
            labelDX: props.labelDX,
            isDisabled: props.isDisabled
        };
    }

    componentDidMount(): void {
        if (this.props.chosenOptions !== undefined && this.props.options !== undefined) {
            this.setState({ chosenOptions: this.props.chosenOptions })
            this.setState({ options: this.props.options })
        }
    }

    componentDidUpdate(prevProps): void {
        if (prevProps.chosenOptions !== this.props.chosenOptions || prevProps.options !== this.props.options)
            if (this.props.chosenOptions !== undefined && this.props.options !== undefined) {
                this.setState({ chosenOptions: this.props.chosenOptions })
                this.setState({ options: this.props.options })
            }
    }

    onListChange = (newAvailableOptions: React.ReactNode[], newChosenOptions: React.ReactNode[]) => {
        this.setState({ options: newAvailableOptions.sort() });
        this.setState({ chosenOptions: newChosenOptions.sort() });
    };

    render() {
        const { options, isSearchable, chosenOptions, labelSX, labelDX } = this.state;
        const { isDisabled } = this.props;

        return (
            <DualListSelector
                availableOptionsTitle={labelSX}
                chosenOptionsTitle={labelDX}
                isSearchable={isSearchable}
                availableOptions={options}
                chosenOptions={chosenOptions}
                onListChange={this.onListChange}
                id="dual-list-selector-basic-search"
                isDisabled={isDisabled}
                addSelected={this.props.addSelected}
                removeSelected={this.props.removeSelected}
                addAll={this.props.addAll}
                removeAll={this.props.removeAll}
            />
        );
    }
};

export declare interface GeneralDualList {
    options?: any;
    isSearchable?: boolean;
    chosenOptions?: any;
    labelSX?: any;
    labelDX?: any;
    isDisabled?: boolean;
    getOptionAndChosenOption?: any;
    addSelected?: any;
    removeSelected?: any;
    addAll?: any;
    removeAll?: any;
}

export default GenericDualList;


