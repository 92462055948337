import React from 'react'
import {DatePicker, FormGroup} from '@patternfly/react-core'

class GenericDatePicker extends React.Component<GeneralDatePickerProps, any>{
  constructor(props) {
    super(props);
    this.state = {
      value: props.value,
      invalidText: '',
      validated: 'default',
      invalidFormatText: props.invalidFormatText,
      placeholder: props.placeholder,
      id: props.id,
      isRequired: props.isRequired,
      isDisabled: props.isDisabled,
      isHidden: props.isHidden,
      label: props.label,
      className: props.className,
    };
  }

  handleDataPickerChange = value => {
    this.setState({ value: value, validated: 'default', invalidText: '' })

    if ((value === '') && this.props.isRequired) {
      this.setState({ validated: 'error', invalidText: 'Campo obbligatorio' });
    }

    if (this.props.onChange !== undefined)
      this.props.onChange(value)
  };

  render() {
    const { value, invalidFormatText, placeholder, id, invalidText, validated, label, className, isHidden } = this.state;
    const dateFormat = (date: Date) =>
      date.toLocaleDateString('en-US', { year: 'numeric', month: '2-digit', day: '2-digit' }).replace(/\//g, '-');
    const dateParse = (date: string) => {
      const split = date.split('-');
      if (split.length !== 3) {
        return new Date();
      }
      const month = split[0];
      const day = split[1];
      const year = split[2];
      return new Date(`${year.padStart(4, '0')}-${month.padStart(2, '0')}-${day.padStart(2, '0')}T00:00:00`);
    };

    return (
      <FormGroup helperTextInvalid={invalidText} validated={validated} label={label} isRequired={this.props.isRequired}>
        <DatePicker hidden={isHidden}
        className={className}
        //dateFormat={dateFormat} dateParse={dateParse}
        inputProps={id} value={value} invalidFormatText={invalidFormatText} placeholder={placeholder} onChange={(e) => { this.handleDataPickerChange(e) }} required={this.props.isRequired} isDisabled={this.props.isDisabled} />
      </FormGroup>
    );
  }
}

export declare interface GeneralDatePickerProps {
  value?: any;
  invalidFormatText?: any;
  placeholder?: string;
  id?: any;
  isRequired?: boolean;
  isDisabled?: boolean;
  isHidden?: boolean;
  onChange?: any;
  label?: string;
  className?: string;
}

export default GenericDatePicker;
