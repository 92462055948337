import {Input} from 'design-react-kit'
import React from 'react'
import InputGroup, {InputGroupProps} from './InputGroup'
import {isEmptyValue, isTimeCFP} from 'src/utilities/utility'

class GenericInput extends React.Component<GeneralInputProps, any> {

  isMount = false;

  constructor(props) {
    super(props);
    this.state={isInvalidTime : false}
  }

  onChange = (e)=>{
      const v = this.props.type==="checkbox"?e.target.checked:e.target.value;
      if(this.props.onChange) {
        const code = this.props.name?this.props.name:this.props.id;
        this.props.onChange(code,v);
      }
      if(this.props.id === "totOreCFU")
      {
        if(isTimeCFP(e.target.value))
        {
          this.setState({isInvalidTime : false })
        }
        else
        {
          this.setState({isInvalidTime : true })
        }
      }
      if(this.props.id === "totOre")
      {
        if(isTimeCFP(e.target.value))
        {
          this.setState({isInvalidTime : false })
        }
        else
        {
          this.setState({isInvalidTime : true })
        }
      }
  }

  onKeyDown = (e) => {
    this.props.onKeyDown(e);
  }

  componentDidMount() {
    this.isMount = true;
    if(this.props.onInit) {
      this.props.onInit(this);
    }
    if(this.props.id === "totOreCFU")
    {
      if(this.props.value !== undefined)
      {
        if(isTimeCFP(this.props.value))
        {
          this.setState({isInvalidTime : false })
        }
        else
        {
          this.setState({isInvalidTime : true })
        }
      }
    }
    if(this.props.id === "totOre")
    {
      if(this.props.value !== undefined)
      {
        if(isTimeCFP(this.props.value))
        {
          this.setState({isInvalidTime : false })
        }
        else
        {
          this.setState({isInvalidTime : true })
        }
      }
    }


  }

  componentWillUnmount() {
    this.isMount = false;
  }

  render() {

    const isInvalid = this.props.invalid || !isEmptyValue(this.props.errorMessage) || this.state.isInvalidTime;
    const classLabel = this.props.type === "checkbox"?"form-check-label":"active";
    const classGroup = this.props.type === "checkbox"?"form-check":"input-group";
    return (
      <InputGroup
        {...this.props}
        classLabel={classLabel}
        classGroup={classGroup}
        >
        <Input
          ref={this.props.ref}
          checked={this.props.checked}
          hidden={this.props.isHidden}
          type={this.props.type}
          defaultValue={this.props.defaultValue}
          defaultChecked={this.props.defaultChecked}
          placeholder={this.props.placeholder}
          id={this.props.id}
          name={this.props.name}
          className={this.props.className}
          disabled={this.props.isDisabled}
          label={this.props.label}
          onChange={this.onChange}
          onKeyDown={this.onKeyDown}
          normalized={this.props.normalized}
          min='0'
          max={this.props.max}
          readOnly={this.props.isReadOnly}
          invalid={isInvalid}
          value={this.props.value !== undefined && this.props.value !== null ? this.props.value : ""}
          step={this.props.step}
          plaintext={this.props.plaintext}
          noWrapper={true}
        />
      </InputGroup>
    )
  }
}

export declare interface GeneralInputProps extends InputGroupProps {
  /**
   * @description Type accettati: classici type html | "cf" | "pIva" | "cap"
   */
  type?: any;
  placeholder?: string;
  id?: string;
  name?: string;
  className?: string;
  label?: any;
  isDisabled?: boolean;
  isHidden?: boolean;
  value?: any;
  defaultValue?: any;
  defaultChecked?: any;
  onChange?: (code:any,value:any)=>void;
  onKeyDown?:(value:any)=>void;
  normalized?: any;
  checked?: boolean;

  min?: any;
  max?: any;
  isReadOnly?: boolean;

  step?: string;

  plaintext?: boolean

  invalid?:boolean

  ref?:any;

  onInit?:any;
}

export default GenericInput
