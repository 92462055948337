import * as React from 'react'
import {EmptyState, EmptyStateBody, EmptyStateIcon, Form} from '@patternfly/react-core'
import {ExclamationTriangleIcon} from '@patternfly/react-icons'
import GenericTextInput from 'src/backoffice/app/components/GenericTextInput'
import GenericCheckbox from 'src/backoffice/app/components/GenericCheckbox'
import GenericSpinner from 'src/backoffice/app/components/GenericSpinner'
import {getBigIntToConvertToBoolean} from 'src/utilities/utility'
import {Label} from 'design-react-kit'
import {isCurrentMobileDevice} from 'src/utilities/deviceUtility'

export class FormDatiAnagrafici extends React.Component<GeneralFormDatiAnagraficiProps, any> {
  render() {
    const { messaggio, iscrizione, soggetto, documentoRiconoscimento } = this.props
    return (
      <>
        {(soggetto === '' || documentoRiconoscimento === '' || iscrizione === '') && messaggio === '' ? (
          <GenericSpinner />
        ) : messaggio !== '' ? (
          <EmptyState variant="full">
            <EmptyStateIcon icon={ExclamationTriangleIcon} />
            <EmptyStateBody>Non è stato possibile recuperare i dati. Contattare l'assistenza.</EmptyStateBody>
          </EmptyState>
        ) : (
          <div>
            <Form>
              <div className="row">
                <div className={isCurrentMobileDevice() ? null : 'col-6'}>
                  <GenericTextInput type={'text'} className="w-75" id={'idNome'} label={'Nome'} value={soggetto.nome} isReadOnly />
                </div>
                <div className={isCurrentMobileDevice() ? null : 'col-6'}>
                  <GenericTextInput type={'text'} className="w-75" id={'idCognome'} label={'Cognome'} value={soggetto.cognome} isReadOnly />
                </div>
              </div>
              <div className="row">
                <div className={isCurrentMobileDevice() ? null : 'col-6'}>
                  <GenericTextInput type={'text'} className="w-75" id={'idCodiceFiscale'} label={'Codice fiscale'} value={soggetto.cf} isReadOnly />
                </div>
                <div className={isCurrentMobileDevice() ? null : 'col-6'}>
                  <GenericTextInput type={'text'} className="w-75" id={'idSesso'} label={'Sesso'} value={soggetto.sesso} isReadOnly />
                </div>
              </div>
              <div className="row">
                <div className={isCurrentMobileDevice() ? null : 'col-6'}>
                  <GenericTextInput
                    type={'date'}
                    className="w-75"
                    id={'idDataNascita'}
                    label={'Data di nascita'}
                    value={soggetto.dataNascita}
                    style={{ paddingRight: '25px' }}
                    isReadOnly
                  />
                </div>
                <div className={isCurrentMobileDevice() ? null : 'col-6'}>
                  <GenericTextInput
                    type={'text'}
                    className="w-75"
                    id={'idProvinciaNascita'}
                    label={'Provincia di nascita'}
                    value={this.props.provinciaNascita}
                    isReadOnly
                    isHidden={this.props.provinciaNascita === 'Stato estero'}
                  />
                </div>
              </div>
              <div className="row">
                <div className={isCurrentMobileDevice() ? null : 'col-6'}>
                  <GenericTextInput type={'text'} className="w-75" id={'idCittaNascita'} label={'Città di nascita'} value={soggetto.cittaNascita} isReadOnly />
                </div>
                <div className={isCurrentMobileDevice() ? null : 'col-6'}>
                  <GenericTextInput type={'text'} className="w-75" id={'idCittadinanza'} label={'Cittadinanza'} value={soggetto.cittadinanza} isReadOnly />
                </div>
              </div>
              {/*-------------------------------------------------------- CAMPO VISIBILE SOLO SE CITTADINANZA NON ITALIANA --------------------------------------------------- */}
              <div
                className="row"
                hidden={soggetto.cittadinanza && (soggetto.cittadinanza.toUpperCase() === 'ITALIA' || soggetto.cittadinanza.toUpperCase() === 'ITALIANA')}
              >
                <div className={isCurrentMobileDevice() ? null : 'col-6'}>
                  <GenericTextInput
                    type={'date'}
                    className="w-75"
                    id={'idDataScadenzaPermesso'}
                    label={'Scadenza del permesso di soggiorno'}
                    value={soggetto.scadenzaPermessoSoggiorno}
                    style={{ paddingRight: '25px' }}
                    isReadOnly
                  />
                </div>
              </div>
              {/*--------------------------------------------------------------------------------------------------------------------------------------------------------------*/}
              <br></br>
              <div className="row">
                <div className="col-6">
                  <Label>
                    <b>Dati documento di riconoscimento</b>
                  </Label>
                </div>
              </div>
              <div className="row">
                <div className={isCurrentMobileDevice() ? null : 'col-6'}>
                  <GenericTextInput
                    type={'text'}
                    className="w-75"
                    id={'idTipoDocumento'}
                    label={'Tipo di documento'}
                    value={documentoRiconoscimento?.tipoDocumento}
                    isReadOnly
                  />
                </div>
                <div className={isCurrentMobileDevice() ? null : 'col-6'}>
                  <GenericTextInput
                    type={'text'}
                    className="w-75"
                    id={'idNumeroDocumento'}
                    label={'Numero documento'}
                    value={documentoRiconoscimento?.numeroDocumento}
                    isReadOnly
                  />
                </div>
              </div>
              <div className="row">
                <div className={isCurrentMobileDevice() ? null : 'col-6'}>
                  <GenericTextInput type={'text'} className="w-75" id={'idEmessoDa'} label={'Emesso da'} value={documentoRiconoscimento?.emessoDa} isReadOnly />
                </div>
                <div className={isCurrentMobileDevice() ? null : 'col-6'}>
                  <GenericTextInput
                    type={'date'}
                    className="w-75"
                    id={'idDataEmissione'}
                    label={'Data emissione'}
                    value={documentoRiconoscimento?.emessoIl}
                    isReadOnly
                  />
                </div>
              </div>
              <div className="row">
                <div className={isCurrentMobileDevice() ? null : 'col-6'}>
                  <GenericTextInput
                    type={'date'}
                    className="w-75"
                    id={'idDataScadenza'}
                    label={'Data scadenza'}
                    value={documentoRiconoscimento?.scadenzaIl}
                    isReadOnly
                  />
                </div>
              </div>
              <div>
                <GenericCheckbox
                  id={'idDiritti_civili'}
                  label={'Dichiaro di godere dei diritti civili'}
                  isChecked={getBigIntToConvertToBoolean(iscrizione.dirittiCivili)}
                  isDisabled
                />
              </div>
              <div>
                <GenericCheckbox
                  id={'idCondotta'}
                  label={'Dichiaro di essere di specchiata condotta morale'}
                  isChecked={getBigIntToConvertToBoolean(iscrizione.condotta)}
                  isDisabled
                />
              </div>
              <div>
                <GenericCheckbox
                  id={'idCondanne'}
                  label={"Dichiaro di non aver riportato condanne che, a norma del presente ordinamento, comportino la radiazione dall'Albo"}
                  isChecked={getBigIntToConvertToBoolean(iscrizione.condanne)}
                  isDisabled
                />
              </div>
              <div>
                <GenericCheckbox
                  id={'idPrivacy'}
                  label={
                    'Dichiara di essere informato, ai sensi e per gli effetti dell’art. 13 del D.Lgs. 196/2003 e all’art. 13 GDPR 679/16, che i dati personali raccolti saranno trattati, anche con strumenti informatici, esclusivamente nell’ambito del procedimento per il quale la presente dichiarazione viene resa.'
                  }
                  isChecked={getBigIntToConvertToBoolean(soggetto.consensoPrivacy)}
                  isDisabled
                />
              </div>
            </Form>
          </div>
        )}
      </>
    )
  }
}

export declare interface GeneralFormDatiAnagraficiProps {
  soggetto?: any
  documentoRiconoscimento?: any
  iscrizione?: any
  messaggio?: any
  provinciaNascita?: any
}
