import React, {useCallback, useRef, useState} from 'react'
import {Buffer} from 'buffer'
import {usePapaParse} from 'react-papaparse'
import {Link, useLocation} from 'react-router-dom'
import {PageSection, Title} from '@patternfly/react-core'
import {Button, Icon, Label, Modal, ModalBody, ModalFooter, ModalHeader} from 'design-react-kit'
import styled from 'styled-components'

import {generateRequestFilter, generateRequestSort} from 'src/app/utils/ApiUtils'
import {useUserEnte} from 'src/hooks/useReqOptions'
import useFrontOffice from 'src/hooks/useFrontOffice'
import {GenericAsyncTableData, RequestData} from 'src/backoffice/app/components/GenericAsyncTableData'
import {
    PATH_TO_BO_ELENCO_EVENTI_CATALOGO,
    PATH_TO_BO_ISCRITTI_EVENTO_CATALOGO,
    PATH_TO_FO_ELENCO_EVENTI_CATALOGO,
    PATH_TO_FO_ISCRITTI_EVENTO_CATALOGO
} from 'src/app/utils/RoutesConstants'

import {getDefaultAxiosConfig, getFormazioneResourceApi} from 'src/app/utils/ManagerRestGateway'
import GenericBreadCrumb from 'src/frontend/app/components/GenericBreadCrumb'
import GenericUpload from 'src/frontend/app/components/Form/Upload'
import AssegnaCFP from './formEventiCatalogo/AssegnaCFP'
import moment from 'moment'
import {TokenStore} from 'src/keycloak/jwt/TokenStore'
import {responseMapperEvento} from './formEntiEsterni/formUtils'
import {getIscrizioneOrdineByCfBO} from 'src/processes/Soggetto'
import {goTo, isEmptyValue} from 'src/utilities/utility'
import {getEventoACatalogoById} from 'src/processes/formazione/EventoService'

const LinkStyled = styled.div`
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: end;
`
const Spacer = () => (
  <>
    <br></br>
  </>
)


const IscrittiEventoCatalogo: React.FunctionComponent = () => {
  const isFrontOffice = useFrontOffice()
  const pathToElenco = isFrontOffice ? PATH_TO_FO_ELENCO_EVENTI_CATALOGO : PATH_TO_BO_ELENCO_EVENTI_CATALOGO
  const pathToIscritti = isFrontOffice ? PATH_TO_FO_ISCRITTI_EVENTO_CATALOGO : PATH_TO_BO_ISCRITTI_EVENTO_CATALOGO
  const basePath = { label: 'Elenco Eventi a Catalogo', link: pathToElenco }
  const [popoverOpenInfo, setPopoverInfo] = useState(false);
  const targetRefInfo = useRef(null);
  const myPath = {
    label: 'Iscritti Evento a Catalogo',
    link: pathToIscritti,
  }

  const [manualRefreshCount, setManualRefreshCount] = useState(0)
  const { readString, jsonToCSV } = usePapaParse()

  const [evento, setEvento] = useState<any>(null)
  const userEnte = useUserEnte()
  const [enti, setListEnti] = useState<any>([])

  const isOrganizzatore = userEnte?.codiceEnte === evento?.organizzatoda
  const [data, setData] = useState<any>({})

  const location = useLocation<{ data: any }>()

  const searchParams = new URLSearchParams(location.search)
  const id = searchParams.get('id')

  const [modaleCFP, setModaleCFP] = useState<any>(null)

  const loadData = useCallback(
    async (request: RequestData) => {
      try {

        const { data } = await getEventoACatalogoById(id);
        const _data = await responseMapperEvento(data)
        //Ulteriore controllo per capire se l'evento è approvato
        if(isEmptyValue(_data.evento) || isEmptyValue(_data.evento.accreditato) || _data.evento.accreditato !== 1) {
          alert("Questo evento non è approvato!")
          goTo(pathToElenco);
        }

        let max = null
        if (_data.evento && _data.evento.data_inizio && _data.evento.data_fine) {
          const diffInDays = moment(_data.evento.data_fine).diff(moment(_data.evento.data_inizio), 'days')
          max = Math.floor(diffInDays)
        }

        if (!_data.tipologiaAttivita) {
          _data.crediti_riconosciuti = 0
        } else {
          if (_data.tipologiaAttivita.um === 'H') {
            const totOre = _data.totOreCFU
            if (totOre && totOre.indexOf(':') > 0) {
              const ore = parseInt(totOre.split(':')[0])
              const minuti = parseInt(totOre.split(':')[1])
              _data.crediti_riconosciuti = ((ore + minuti / 60) / _data.tipologiaAttivita.ore_evt_battute) * _data.tipologiaAttivita.max_cfp_evt
            } else {
              _data.crediti_riconosciuti = _data.tipologiaAttivita.max_cfp_evt
            }

            if (max) {
              _data.crediti_riconosciuti = _data.crediti_riconosciuti > max ? max : _data.crediti_riconosciuti
            }
          } else {
            if (_data.tipologiaAttivita.cod === 'AO' || _data.tipologiaAttivita.cod === 'AF') {
              _data.crediti_riconosciuti = 0.25
            } else {
              _data.crediti_riconosciuti = _data.evento.crediti ? _data.evento.crediti : _data.tipologiaAttivita.max_cfp_evt
            }
          }
        }
        _data.crediti_riconosciuti = _data.evento && _data.evento.crediti ? _data.evento.crediti : _data.crediti_riconosciuti
        setData(_data)
      } catch (e) {
        alert("Si è verificato un problema durante l'apertura della pagina!")
        goTo(pathToElenco);
      }

      const corso = { field: 'idcorso', value: id }
      const { data: evento } = await getFormazioneResourceApi().msfoFormazioneListaEventiACatalogoGet(
        generateRequestFilter([corso]),
        1,
        0,
        undefined,
        getDefaultAxiosConfig()
      )
      setEvento(evento?.items?.[0])

      request.filters.push(corso)
      const { data } = await getFormazioneResourceApi().msfoFormazioneListaIscrittiGet(
        generateRequestFilter(request.filters),
        request.pageSize,
        request.currentPage,
        generateRequestSort(request.sortColumn, request.sortDirection),
        getDefaultAxiosConfig()
      )

      return {
        items: data.items || [],
        total: data.count || 0,
      }
    },
    [id]
  )

  const loadAllData = async (id) => {
    const corso = { field: 'idcorso', value: id }
    const { data } = await getFormazioneResourceApi().msfoFormazioneListaIscrittiGet(
      generateRequestFilter([corso]),
      10000,
      0,
      undefined,
      getDefaultAxiosConfig()
    )
    return data.items || []
  }

  const generateSignatures = useCallback(async () => {
    let data = await loadAllData(id)
    data = data.map((i: any) => {
      const obj = {}
      const map = {
        nome: 'NOME',
        cognome: 'COGNOME',
        user_name: 'CODICE FISCALE',
        ordine: 'ORDINE',
        barcode: 'N.ISCRIZIONE',
      }
      for (let key in i) {
        const mappedKey = map[key]
        if (!mappedKey) continue
        obj[mappedKey] = i[key]
      }
      obj['ORA ENTRATA'] = ''
      obj['ORA USCITA'] = ''
      obj['FIRMA USCITA'] = ''
      obj['FIRMA PRIVACY'] = ''
      return obj
    })

    const exported = jsonToCSV(data || [])
    const blob = new Blob([exported], { type: 'text/csv;charset=utf-8;' })
    const url = window.URL.createObjectURL(blob)
    const a = document.createElement('a')
    a.href = url
    a.download = 'foglio_firma.csv'
    a.click()
    window.URL.revokeObjectURL(url)
  }, [id])

  const assignCFU = useCallback(
    async (_, { allegatoDTO }) => {
      const { data: eventoData } = await getEventoACatalogoById(id);
      let evento = eventoData;
      const data = await loadAllData(id)
      const decodedString = Buffer.from(allegatoDTO?.contentBase64, 'base64').toString('utf-8')
      readString(decodedString, {
        worker: true,
        skipEmptyLines: true,
        complete: async (results: any) => {
          const validResults: any = []
          const invalidResults: any = []
          const isCFPMassiva = true

          for (let i in results?.data || []) {
            let errorFlag = false;

            const r = results?.data[i]
            const _idNaz = r[0]?.trim()
            const anno = r[1]?.trim()
            const user_name = r[2]?.trim()
            const cfpDecimali = r[3]?.trim();
             // Converte la stringa in un numero
            const numero = parseFloat(cfpDecimali);
            const cfp = isNaN(numero) ? null : parseFloat(numero.toFixed(3));

            let item : any = data.find((i: any) => (i.user_name ? i.user_name.toLowerCase() : i.user_name) === (user_name ? user_name.toLowerCase() : user_name))
            let updatedItem = { ...item, cfp }
            let riga = `(R. ${parseInt(i) + 1})`
            if (!_idNaz || !anno || !user_name || !cfp) {
              invalidResults.push({
                ...updatedItem,
                message: `${riga} In ogni riga del csv devono essere presenti: id corso, anno, codice fiscale e cfp`,
              })
              continue
            }
            //Controlla se quel codice fiscale è presente all'interno della lista degli iscritti
            //dovrebbe però controllare che quell'iscritto sia censito come iscritto all'albo

            if (_idNaz !== evento.evento.id_naz.toString()) {
              invalidResults.push({ ...updatedItem, message: `${riga} Il codice evento ${_idNaz} non corrisponde a quello dell'evento selezionato ${evento.evento.id_naz}` })
              continue
            }
            if (!item) {

              //Se l'utente non lo trova tra la lista degli iscritti lo cerca tra i censiti all'albo
              // let tipoAnagrafe = "PF";
              // let tipologiaIscrizione = 1;
              //chiamata al servizio che restituisce i dati del soggetto
              await getIscrizioneOrdineByCfBO(user_name.toUpperCase()).then(async (response: any) => {
                      item = {
                        user_name: user_name,
                        cognome: response.data.returnedObject.cognome,
                        nome: response.data.returnedObject.nome,
                        idcorso: id
                      }
                      updatedItem = { ...item, cfp }

              }).catch(error =>{
                invalidResults.push({ ...updatedItem, message: `${riga} Nessuna corrispondenza trovata all'interno dell'albo per il codice fiscale ${user_name} ` })
                errorFlag = true
              })

            }
            if(errorFlag === false)
              validResults.push(updatedItem)
          }
          setModaleCFP({ validResults, invalidResults, isCFPMassiva })
        },
      })
    },
    [id]
  )

  const filters: any = [
    { label: 'Cognome', field: 'cognome', type: 'input', operator: 'LIKE' },
    { label: 'Nome', field: 'nome', type: 'input', operator: 'LIKE' },
    { label: 'Codice Fiscale', field: 'cf', type: 'input', operator: 'LIKE' },
    { label: 'Ordine di appartenenza', field: 'ordine_territoriale_competente', type :'input', operator: 'LIKE'},
    { label: 'CFP assegnati', field: 'cfpassegnati', type: 'input', operator: 'LIKE' },
    { label: 'N. iscrizione', field: 'numero_iscrizione_ordine', type: 'input', operator: 'LIKE' },
  ]


  return (
    <PageSection>
      <div className="container  register-container">
        <GenericBreadCrumb paths={[basePath, myPath]} />
        <Spacer />

        <Title headingLevel="h1" size="lg">
          {myPath.label}
        </Title>
        <Spacer />
        {(TokenStore.getInstance().isOperatoreCONAF() || isOrganizzatore) && (
          <div className="d-flex justify-content-end mb-4">
            {!TokenStore.getInstance().isCurrentAccountFormatore() && (
              <div className="me-4">
                <Label className="d-flex justify-content-center" for="massive_cfp">
                  <strong className="me-2">Assegnazione massiva CFP</strong>
                  <a>
                    <Icon  onClick={() => {
                      setPopoverInfo(!popoverOpenInfo);
                    }} color="primary" icon="it-info-circle" size="sm" /></a>
                </Label>

                <GenericUpload id="massive_cfp" extensions={['csv']} accept={['text/csv', 'csv']} onChange={assignCFU} />

              </div>
            )}
            <div className="me-2">
              <Button
                color="secondary"
                onClick={(e) => {
                  e.preventDefault()
                  generateSignatures()
                }}
              >
                Generazione foglio firma
              </Button>
            </div>
            <div>
                  <Modal
                      placement={'bottom'}
                      isOpen={popoverOpenInfo}
                      target={targetRefInfo}
                      toggle={() => {
                        setPopoverInfo(!popoverOpenInfo);
                      }}
                      size='lg'
                  >
                      <ModalHeader icon='it-info-circle' id="assegnaCFUMassivi">Assegnazione massiva CFP</ModalHeader>
                      <ModalBody >
                        <Label>
                          Tramite tale funzione l’organizzatore dell’evento (e solo lui), ha la possibilità di assegnare i crediti formativi
                          ai partecipanti all’evento in modo massivo tramite caricamento di un file di import (.csv)
                        </Label>
                        <Label>
                          Per ciascuna riga del file di import devono essere riportati i seguenti dati separati da “,”:
                          <li>
                            Id evento
                          </li>
                          <li>
                            Anno di svolgimento
                          </li>
                          <li>
                            Codice fiscale iscritto (in maiuscolo)
                          </li>
                          <li>
                            CFP assegnati (utilizzare il punto come separatore dei decimali: 3.85, 1.075, etc)
                          </li>
                        </Label>
                        <Label>
                          Nel file csv caricato non deve essere presente alcuna riga di intestazione.
                        </Label>

                      </ModalBody>
                      <ModalFooter>
                        <Button color='primary' onClick={() => setPopoverInfo(!popoverOpenInfo)}>
                          Ok
                        </Button>
                      </ModalFooter>
                  </Modal>
            </div>
          </div>
        )}
        <GenericAsyncTableData
          manualRefreshCount={manualRefreshCount}
          emptyMessage={'Nessun iscritto trovato'}
          filters={filters}
          columns={[
            { label: 'Cognome', field: 'cognome', isSortable: true },
            { label: 'Nome', field: 'nome', isSortable: true },
            { label: 'Codice Fiscale', field: 'cf', isSortable: true },
            { label: 'Ordine di appartenenza', field: 'ordine_territoriale_competente', isSortable: true },
            { label: 'CFP assegnati',  field: 'cfpassegnati', isSortable: true, toFixed: 3 },
            { label: 'N. iscrizione', field: 'numero_iscrizione_ordine', isSortable: true },
          ]}
          getRows={loadData}
          getRowActions={(row) => {
            const actions: any = TokenStore.getInstance().isCurrentAccountFormatore()
              ? []
              : [{ label: 'Assegna crediti', onClick: () => setModaleCFP({ validResults: [{ ...row, user_name: row.cf }]}) }]
            return actions.filter((a) => a)
          }}
        />
        {modaleCFP && (
          <AssegnaCFP
            iscritti={modaleCFP?.validResults}
            errori={modaleCFP?.invalidResults}
            evento={evento}
            onClose={() => {
              setModaleCFP(null)
              setManualRefreshCount((c) => c + 1)
            }}
            maxCFPCorso={data.crediti_riconosciuti}
            cfpMassiva={modaleCFP?.isCFPMassiva}
          />
        )}
        <LinkStyled>
          <Link to={pathToElenco}>Torna alla lista</Link>
        </LinkStyled>
      </div>
    </PageSection>
  )
}

export default IscrittiEventoCatalogo
