import {getDefaultAxiosConfig, getFormazioneResourceApi} from 'src/app/utils/ManagerRestGateway'
import {getAreeProfessionali, getSdaf, getTipologieAttivita} from 'src/hooks/useReqOptions'

import {getAllProvince} from 'src/processes/Province'
import {getComuniByProvinciaMsga} from 'src/processes/Comuni'
import {getValueAtPath} from 'src/utilities/utility'
import {
    ALLEGATI_AGENZIA_FILTERED,
    AMBITI_TERRITORIALI_OPTION_FROM_VALUES,
    attivitaDisponibili,
    MODALITA_DISPONIBILI_OPTION_FROM_VALUES,
    TIPOLOGIA_EVENTO_OPTION_FROM_VALUES,
    TIPOLOGIE_ACCREDITAMENTO_OPTION_FROM_VALUES
} from 'src/formazione'
import moment from 'moment'

const toDateFormat = (date) => {
  if (!date) return null
  return moment(date).format('YYYY-MM-DD')
}

// TODO do it better
const provinciaMap: { [key: string]: any } = {}
const fillProvince = async () => {
  try {
    const { data } = await getAllProvince()
    for (const provincia of data || []) {
      const prov: any = provincia
      provinciaMap[prov['denominazioneUnitaTerritorialeSovracomunale']] = prov['siglaAutomobilistica']
    }
  } catch (err) {
    console.error(err)
  }
}

export const requestMapperAgenzia = async (data: any) => {
  await fillProvince()
  const { datiAnagrafici, datiSedeLegale, datiRappresentanteLegale, datiResponsabileAF, datiAccreditamento, datiDichiarazioni } = data || {}

  const obj: any = {
    datiAccreditamento: {
      tipo_ag_form: 1,
      rag_soc: datiAnagrafici?.ragioneSociale,
      cod_fisc: datiAnagrafici?.codiceFiscale,
      piva: datiAnagrafici?.piva,
      email: datiAnagrafici?.email,
      pec: datiAnagrafici?.pec,
      telefono: datiAnagrafici?.telefono,
      fax: datiAnagrafici?.fax,
      cod_issn: '',

      sl_provincia: provinciaMap[datiSedeLegale?.provincia?.value],
      sl_comune: datiSedeLegale?.comune?.value,
      sl_indirizzo: datiSedeLegale?.indirizzo,
      sl_cap: datiSedeLegale?.cap,

      lr_nome: datiRappresentanteLegale?.nome,
      lr_cognome: datiRappresentanteLegale?.cognome,
      lr_codice_fiscale: datiRappresentanteLegale?.codiceFiscale,
      lr_luogo_nas: datiRappresentanteLegale?.luogoNascita,
      lr_comu_nas: datiRappresentanteLegale?.comune?.value,
      lr_prov_nas: provinciaMap[datiRappresentanteLegale?.provincia?.value],
      lr_data_nas: datiRappresentanteLegale?.dataNascita || null,
      lr_nazione: datiRappresentanteLegale?.nazione,
      lr_sesso: '',
      lr_comu_res: datiRappresentanteLegale?.comune?.value,
      lr_prov_res: provinciaMap[datiRappresentanteLegale?.provincia?.value],
      lr_ind_res: datiRappresentanteLegale?.indirizzo,
      lr_cap_res: datiRappresentanteLegale?.cap,

      // TODO manca Responsabile AF
      tipo_accr: datiAccreditamento?.tipoAccreditamento?.value || 0,
      ambito_territoriale: datiAccreditamento?.ambitoTerritoriale?.value || 0,
      // TODO FEDE check (sul pdf deve essere una select tre fad in situ e mista)
      // f_att_situ: [MODALITA_DISPONIBILI_VALUES.IN_SITU, MODALITA_DISPONIBILI_VALUES.MISTA].includes(data?.acc_modalita?.value) ? 1 : 0,
      // f_att_fad: [MODALITA_DISPONIBILI_VALUES.FAD_SINCRONO, MODALITA_DISPONIBILI_VALUES.FAD_ASINCRONO, MODALITA_DISPONIBILI_VALUES.MISTA].includes(
      //   data?.acc_modalita?.value
      // )
      //   ? 1
      //   : 0,

      assenza_sentenze: datiDichiarazioni?.assenzaSentenze ? 1 : 0,
      assenza_proc_fallimentari: datiDichiarazioni?.assenzaFallimento ? 1 : 0,
      risp_norm_lavoro: datiDichiarazioni?.rispettoNormative ? 1 : 0,
      is_formatore: datiDichiarazioni?.formatore ? 1 : 0,
      is_resp_form_formatore: datiDichiarazioni?.isRespFormFormatore ? 1 : 0,
      has_resp_form_conflitti: datiDichiarazioni?.hasRespFormConflitti ? 1 : 0,

      massimale_polizza: datiDichiarazioni?.massimalePolizza,
      tipo_rischio_polizza: datiDichiarazioni?.tipoPolizza,
      polizza_val_da: datiDichiarazioni?.polizzaValidaDa || null,
      polizza_val_a: datiDichiarazioni?.polizzaValidaA || null,
      is_nuova_costituzione: datiDichiarazioni?.nuovaCostituzione ? 1 : 0,
      flag_impegno_prod_doc: datiDichiarazioni?.flagImpegnoProdDoc ? 1 : 0,
      fatturato_formazione: datiDichiarazioni?.fatturatoFormazione,

      doc_copia_vers_tariffa: '',
      doc_all_mod_a_g: '',
      doc_bilanci_appr: '',
      doc_richiesta_uff: '',
      doc_all_rivista: '',
      doc_all_mod_f_shf1_f2: '',
      note_acc_rifiuto: '',
      note_acc_rifiuto_min: '',
    },
    datiTipologiaAccreditamento: {
      settori: [datiAccreditamento?.settoriDisciplinari].flat(),
      aree: [datiAccreditamento?.areePrestazioni].flat(),
      attivita: [datiAccreditamento?.tipoAttivita].flat(),
      modalita: [datiAccreditamento?.modalita].flat(),
    },
    datiReponsabileAttivitaFormativa: {
      nome: datiResponsabileAF?.nome,
      cognome: datiResponsabileAF?.cognome,
      cf: datiResponsabileAF?.codiceFiscale,
      data_nascita: datiResponsabileAF?.dataNascita || moment().format('YYYY-MM-DD'),
      luogo_nascita: datiResponsabileAF?.luogoNascita,
      nazione: datiResponsabileAF?.nazione,
      comune: datiResponsabileAF?.comune?.value,
      provincia: provinciaMap[datiResponsabileAF?.provincia?.value] || 'EEP',
      cap: datiResponsabileAF?.cap,
      indirizzo: datiResponsabileAF?.indirizzo,
      email: datiResponsabileAF?.email,
      pec: datiResponsabileAF?.pec,
      cf_formatore_allegato: datiResponsabileAF?.cfFormatoreAllegato,
    },
    allegati: ALLEGATI_AGENZIA_FILTERED(data, 'allegati')?.flatMap((a) => {
      const value = getValueAtPath(data, a?.id)
      let file = value
      // TODO remove, for now send only the first file if isMulti
      // if (Array.isArray(value)) file = value?.[0]
      // delete file.key
      return file
    }),
  }
  return obj
}

export const responseMapperAgenzia = async (data: any) => {
  const {
    datiAccreditamento: accreditamento,
    datiReponsabileAttivitaFormativa: aformativa,
    datiTipologiaAccreditamento: taccreditamento,
    allegatiRecuperati,
  } = data || {}

  const { data: province } = await getAllProvince()

  const datiAnagrafici: any = {
    ragioneSociale: accreditamento?.rag_soc,
    codiceFiscale: accreditamento?.cod_fisc,
    piva: accreditamento?.piva,
    email: accreditamento?.email,
    pec: accreditamento?.pec,
    telefono: accreditamento?.telefono,
    fax: accreditamento?.fax,
  }
  const datiSedeLegale: any = {
    indirizzo: accreditamento?.sl_indirizzo,
    cap: accreditamento?.sl_cap,
  }

  const datiRappresentanteLegale: any = {
    nome: accreditamento?.lr_nome,
    cognome: accreditamento?.lr_cognome,
    codiceFiscale: accreditamento?.lr_codice_fiscale,
    luogoNascita: accreditamento?.lr_luogo_nas,
    dataNascita: accreditamento?.lr_data_nas,
    nazione: { label: accreditamento?.lr_nazione, value: accreditamento?.lr_nazione },
    indirizzo: accreditamento?.lr_ind_res,
    cap: accreditamento?.lr_cap_res,
  }
  const datiResponsabileAF: any = {
    nome: aformativa?.nome,
    cognome: aformativa?.cognome,
    codiceFiscale: aformativa?.cf,
    dataNascita: aformativa?.data_nascita,
    luogoNascita: aformativa?.luogo_nascita,
    nazione: { label: aformativa?.nazione, value: aformativa?.nazione },
    cap: aformativa?.cap,
    indirizzo: aformativa?.indirizzo,
    email: aformativa?.email,
    pec: aformativa?.pec,
  }
  const datiAccreditamento: any = {
    tipoAccreditamento: TIPOLOGIE_ACCREDITAMENTO_OPTION_FROM_VALUES[accreditamento?.tipo_accr],
    ambitoTerritoriale: AMBITI_TERRITORIALI_OPTION_FROM_VALUES[accreditamento?.ambito_territoriale],
  }
  const datiDichiarazioni: any = {
    assenzaSentenze: !!accreditamento?.assenza_sentenze,
    assenzaFallimento: !!accreditamento?.assenza_proc_fallimentari,
    rispettoNormative: !!accreditamento?.risp_norm_lavoro,
    formatore: accreditamento?.is_formatore,
    isRespFormFormatore: !!accreditamento?.is_resp_form_formatore,
    hasRespFormConflitti: !!accreditamento?.has_resp_form_conflitti,
    massimalePolizza: accreditamento?.massimale_polizza,
    tipoPolizza: accreditamento?.tipo_rischio_polizza,
    polizzaValidaDa: toDateFormat(accreditamento?.polizza_val_da),
    polizzaValidaA: toDateFormat(accreditamento?.polizza_val_a),
    nuovaCostituzione: !!accreditamento?.is_nuova_costituzione,
    flagImpegnoProdDoc: !!accreditamento?.flag_impegno_prod_doc,
    fatturatoFormazione: accreditamento?.fatturato_formazione,
  }
  const allegati = Object.assign(
    {},
    ...ALLEGATI_AGENZIA_FILTERED(data, 'allegati')?.map((a) => {
      const key = a?.id?.replace('allegati.', '')
      const files = allegatiRecuperati?.filter((item) => item?.tipologiaAllegato === a?.type)
      return {
        [key]: files?.map((item) => ({
          ...item,
          allegatoDTO: {
            filename: item?.titoloDocumento,
          },
        })),
      }
    })
  )

  // if (accreditamento?.f_att_situ && accreditamento?.f_att_fad) {
  //   datiAccreditamento.modalita = MODALITA_DISPONIBILI_OPTION_FROM_VALUES[MODALITA_DISPONIBILI_VALUES.MISTA]
  // } else if (datiAccreditamento?.f_att_situ) {
  //   datiAccreditamento.modalita = MODALITA_DISPONIBILI_OPTION_FROM_VALUES[MODALITA_DISPONIBILI_VALUES.IN_SITU]
  // } else if (datiAccreditamento?.f_att_fad) {
  //   datiAccreditamento.modalita = MODALITA_DISPONIBILI_OPTION_FROM_VALUES[MODALITA_DISPONIBILI_VALUES.FAD_SINCRONO]
  // }

  datiAccreditamento.modalita = taccreditamento?.modalita
  if (taccreditamento?.settori?.length > 1) {
    datiAccreditamento.settoriDisciplinari = taccreditamento?.settori?.flatMap((item) => {
      if (!item) return null
      item.label = item?.titolo
      item.value = item?.cod
      return item
    })
  } else {
    const item = taccreditamento?.settori?.[0] || {}
    datiAccreditamento.settoriDisciplinari = {
      ...item,
      label: item?.titolo,
      value: item?.cod,
    }
  }

  if (taccreditamento?.attivita?.length > 1) {
    datiAccreditamento.tipoAttivita = taccreditamento?.attivita?.flatMap((item) => {
      if (!item) return null
      item.label = item?.titolo
      item.value = item?.cod
      return item
    })
  } else {
    const item = taccreditamento?.attivita?.[0] || {}
    datiAccreditamento.tipoAttivita = {
      ...item,
      label: item?.titolo,
      value: item?.cod,
    }
  }

  if (taccreditamento?.aree?.length > 1) {
    datiAccreditamento.areePrestazioni = taccreditamento?.aree?.flatMap((item) => {
      if (!item) return null
      item.label = item?.titolo
      item.value = item?.cod
      return item
    })
  } else {
    const item = taccreditamento?.aree?.[0] || {}
    datiAccreditamento.areePrestazioni = {
      ...item,
      label: item?.titolo,
      value: item?.cod,
    }
  }


  try {
    const sl_provincia = province?.find((item) => item?.siglaAutomobilistica === accreditamento?.sl_provincia)
    if(sl_provincia?.denominazioneUnitaTerritorialeSovracomunale) {
      const sl_comuni = await getComuniByProvinciaMsga(sl_provincia?.denominazioneUnitaTerritorialeSovracomunale)
      datiSedeLegale.provincia = {
        label: sl_provincia?.denominazioneUnitaTerritorialeSovracomunale,
        value: sl_provincia?.denominazioneUnitaTerritorialeSovracomunale,
      }
      datiSedeLegale.comune = sl_comuni?.find((item) => item.value.toLowerCase() === accreditamento?.sl_comune.toLowerCase())
    }

    const rapp_provincia = province?.find((item) => item?.siglaAutomobilistica === accreditamento?.lr_prov_res)
    if(rapp_provincia?.denominazioneUnitaTerritorialeSovracomunale) {
      const rapp_comuni = await getComuniByProvinciaMsga(rapp_provincia?.denominazioneUnitaTerritorialeSovracomunale)
      datiRappresentanteLegale.provincia = {
        label: rapp_provincia?.denominazioneUnitaTerritorialeSovracomunale,
        value: rapp_provincia?.denominazioneUnitaTerritorialeSovracomunale,
      }
      datiRappresentanteLegale.comune = rapp_comuni?.find((item) => item.value === accreditamento?.lr_comu_res)
    }

    const res_provincia = province?.find((item) => item?.siglaAutomobilistica === aformativa?.provincia)
    if(res_provincia?.denominazioneUnitaTerritorialeSovracomunale) {
      const res_comuni = await getComuniByProvinciaMsga(res_provincia?.denominazioneUnitaTerritorialeSovracomunale)
      datiResponsabileAF.provincia = {
        label: res_provincia?.denominazioneUnitaTerritorialeSovracomunale,
        value: res_provincia?.denominazioneUnitaTerritorialeSovracomunale,
      }
      datiResponsabileAF.comune = res_comuni?.find((item) => item.value === aformativa?.comune)
    }
  } catch (e) {
    console.error(e)
  }

  const obj: any = {
    datiAnagrafici,
    datiSedeLegale,
    datiRappresentanteLegale,
    datiResponsabileAF,
    datiAccreditamento,
    datiDichiarazioni,
    allegati,
  }
  return obj
}

export const requestMapperEvento = () => {}
export const responseMapperEvento = async (data) => {
  const areeProfessionali = await getAreeProfessionali()
  const listaSDAF = await getSdaf()

  const obj = { ...data }
  if (obj?.evento?.nome_corso) {
    obj.nomeCorso = obj.evento.nome_corso
  }

  obj.modalita = MODALITA_DISPONIBILI_OPTION_FROM_VALUES[obj?.evento?.modalita]
  obj.totOreCFU = obj.evento?.tot_ore_cfu
  obj.piattaformaFAD = obj.evento?.piattaforma_fad
  obj.partecipazioneConAbbonamento = obj?.evento?.part_con_abb
  obj.partecipazioneSenzaAbbonamento = obj?.evento?.part_senza_abb

  if (obj?.tipologiaAttivita) {
    obj.tipologiaAttivita = { ...obj?.tipologiaAttivita, label: obj?.tipologiaAttivita?.titolo, value: obj?.tipologiaAttivita?.cod }
  }

  obj.codAttivita = attivitaDisponibili?.find((item) => item?.value === obj?.evento?.cod_attivita)
  for(const sdaf of listaSDAF) {
    if(sdaf.id === obj?.evento?.settore) {
      obj.settore = sdaf;
    }
  }
  if (obj?.codCompetenza) {
    obj.codCompetenza = { label: `${obj.codCompetenza.cod} - ${obj.codCompetenza.descrizione} - ${obj.codCompetenza.norma_rif}`, value: obj.codCompetenza.cod }
  }
  if (obj?.areaProfessionale) {
    obj.areaProfessionale = areeProfessionali?.find((item) => item?.id === obj?.areaProfessionale?.id)
  }
  if (obj?.evento?.id_prestazione_professionale) {
    obj.areaProfessionale2 = areeProfessionali?.filter((item) => obj?.evento?.id_prestazione_professionale.includes(item?.id))
  }

  obj.locandina = {
    ...(obj?.formazioneAllegatoDTO || {}),
    allegatoDTO: {
      filename: obj?.formazioneAllegatoDTO?.titoloDocumento,
    },
  }
  obj.interventi = data?.interventiRecuperati?.map((x) => ({
    titTema: x?.tit_tema,
    dalle: x?.dalleTime,
    alle: x?.alle,
    titRelatore: x?.tit_relatore,
    relatore: {
      cf: '',
      nome: x?.nome,
      cognome: x?.cognome,
      email: x?.email,
    },
  }))

  return obj
}

export const requestMapperEventoInd = () => {}
export const responseMapperEventoInd = async (data) => {
  const sdaf = await getSdaf()
  const tipologieAttivita = await getTipologieAttivita()

  const obj = { ...data }
  obj.nomeCorso = obj?.attivitaFormativaExt?.nome_corso
  obj.modalita = MODALITA_DISPONIBILI_OPTION_FROM_VALUES[obj?.attivitaFormativaExt?.modalita]
  const settoreDisciplinare = sdaf?.find((s) => s.id === obj?.attivitaFormativaExt?.settore)
  if (settoreDisciplinare) {
    obj.settore = { ...settoreDisciplinare, label: `${settoreDisciplinare?.cod || ''} - ${settoreDisciplinare?.titolo || ''}`, value: settoreDisciplinare?.cod }
  }
  obj.soggettoErogante = obj?.attivitaFormativaExt?.sogg_erogante
  obj.totOre = obj?.attivitaFormativaExt?.durata
  obj.crediti = obj?.attivitaFormativaExt?.cfu
  obj.regione = obj?.attivitaFormativaExt?.regione
  obj.sede = obj?.attivitaFormativaExt?.presso
  obj.descrizione = obj?.attivitaFormativaExt?.descr_corso
  obj.tipologiaEvento = TIPOLOGIA_EVENTO_OPTION_FROM_VALUES[obj?.attivitaFormativaExt?.tipologia_evento]

  const tipologiaAttivita = tipologieAttivita?.find((t) => t.id == obj?.attivitaFormativaExt?.tipo_corso)
  if (tipologiaAttivita) {
    obj.tipologiaAttivita = { ...tipologiaAttivita, label: tipologiaAttivita?.titolo, value: tipologiaAttivita?.cod }
  }
  obj.note = obj?.attivitaFormativaExt?.note_richiedente

  obj.locandina = {
    ...(obj?.formazioneAllegatoDTO || {}),
    allegatoDTO: {
      filename: obj?.formazioneAllegatoDTO?.titoloDocumento,
    },
  }
  obj.attestato = {
    ...(obj?.attestatoDTO || {}),
    allegatoDTO: {
      filename: obj?.attestatoDTO?.titoloDocumento,
    },
  }
  return obj
}

const toSnakeCase = (data) => {
  const obj = {}
  for (let key in data) {
    const newKey = key.replace(/([A-Z])/g, (g) => `_${g[0].toLowerCase()}`)
    obj[newKey] = data[key]
  }
  return obj
}

const toCamelCase = (data) => {
  const obj = {}
  for (let key in data) {
    const newKey = key.replace(/_([a-z])/g, (g) => g[1].toUpperCase())
    obj[newKey] = data[key]
  }
  return obj
}

export const ottieniAllegato = async (idAllegato?: any, name?: string) => {
  try {
    const param = { idAttachment: idAllegato }
    let { data } = await getFormazioneResourceApi().msfoFormazioneGetAllegatoPost(param, getDefaultAxiosConfig())
    if (!data['content']) {
      throw new Error('File non presente')
    }

    let linkSource = 'data:application/pdf;base64,' + data['content']
    const downloadLink = document.createElement('a')
    const fileName = data.name + '.pdf'
    downloadLink.href = linkSource
    downloadLink.download = name ? name : fileName
    downloadLink.click()

    let pdfAsDataUri: any = 'data:application/pdf;base64,' + data['data']['content']
    window.open(pdfAsDataUri, '_blank')
  } catch (err) {
    console.error("Errore durante la chiamata per il recupero dell'allegato " + err)
  }
}
