import {FormGroup, Select} from 'design-react-kit'
import React from 'react'
import {breakLineIfLabelTooLong} from 'src/utilities/utility'

class GenericSelect extends React.Component<GeneralSelectProps, any> {
  constructor(props) {
    super(props)
    this.state = {}
  }

  /**
   * Modifica il css delle label troppo lunghe --> permette di andare a capo
   */
  labelTooLength: any = {
    select: {
      whiteSpace: 'pre-line',
      marginTop: '-3%',
      lineHeight: '1.5',
    },
  }

  messageAlertContentError = {
    hasError: true,
    id: this.props.elementId,
    isReadonly: false,
    isDisabled: this.props.isDisabled,
    stepTab: this.props.currentStep,
    value: this.props.defaultValue,
    label: this.props.label,
  }
  messageAlertContentSuccess = {
    hasError: false,
    id: this.props.elementId,
    isReadonly: false,
    isDisabled: this.props.isDisabled,
    stepTab: this.props.currentStep,
    value: this.props.defaultValue,
    label: this.props.label,
  }

  componentDidMount(): void {
    if (this.props.errorMessageAlert !== undefined) {
      if (this.props.isRequired && this.state.selectValue === undefined) {
        this.props.errorMessageAlert(this.messageAlertContentError)
      } else this.props.errorMessageAlert(this.messageAlertContentSuccess)
      if (this.props.defaultValue) this.props.errorMessageAlert(this.messageAlertContentSuccess)
    }
  }

  componentDidUpdate(prevProps): void {
    if (this.props.itemIdDeleted !== prevProps.itemIdDeleted)
      this.props.errorMessageAlert({
        hasError: false,
        id: this.props.itemIdDeleted,
        isReadonly: false,
        isDisabled: false,
        stepTab: this.props.currentStep,
        value: this.props.defaultValue,
        label: this.props.label,
      })

    if (this.props.isRequired !== prevProps.isRequired) this.componentDidMount()
  }

  componentWillUnmount(): void {
    this.componentDidMount()
  }

  validationForm = (event): void => {
    this.messageAlertContentError['value'] = event.value
    this.messageAlertContentSuccess['value'] = event.value
    if (this.props.errorMessageAlert !== undefined) {
      if (event?.label !== '') this.props.errorMessageAlert(this.messageAlertContentSuccess)
      else this.props.errorMessageAlert(this.messageAlertContentError)
    }
    this.setState({ selectValue: event.value })
    if (this.props.onChange !== undefined) this.props.onChange(event)
  }

  render() {
    return (
      <FormGroup hidden={this.props.isHidden}>
        <div>
          <div className="bootstrap-select-wrapper form-group flex">
            <Select
              defaultInputValue={this.props.defaultInputValue}
              isOptionSelected={this.props.isOptionSelected}
              inputId={this.props.elementId}
              id={this.props.id}
              onInputChange={this.props.onInputChange}
              name={this.props.name}
              onChange={this.validationForm}
              defaultValue={this.props.defaultValue}
              placeholder={this.props.placeholder}
              isDisabled={this.props.isDisabled}
              isClearable={this.props.isClearable}
              isSearchable={this.props.isSearchable}
              isMulti={this.props.isMulti}
              value={this.props.value}
              inputValue={this.props.inputValue}
              options={this.props.defaultOptions}
              controlShouldRenderValue
            />
          </div>
          <div>
            <label htmlFor={this.props.elementId} className={this.props.className} style={this.labelTooLength.select}>
              {breakLineIfLabelTooLong(this.props.label, this.props.maxLabelLength)}
            </label>
          </div>
        </div>
      </FormGroup>
    )
  }
}

export declare interface GeneralSelectProps {
  elementId?: any
  name?: any
  placeholder?: any
  value?: any
  inputValue?: any
  defaultValue?: any
  ariaLabel?: any
  label?: any
  defaultOptions?: any
  isDisabled?: any
  isClearable?: any
  isSearchable?: any
  isMulti?: any
  className?: any
  onChange?: any
  id?: string
  isOptionSelected?: any
  isRequired?: boolean
  errorMessageAlert?: any
  isHidden?: boolean
  modificaDatiFlag?: boolean
  currentStep?: any
  /**
   * Contiene l'id dell'elemento da rendere non obbligatorio in fase di validazione una volta eliminato
   * Inserire l'id dell'elemento seguito dall'id dell'elemento selezionato
   * Es. se l'id elemento è uguale a {pippo + id} --> scrivere itemIdDeleted={"pippo" + idElementoEliminato}
   */
  itemIdDeleted?: any
  maxLabelLength?: number
  defaultInputValue?: any
  onInputChange?: any
}

export default GenericSelect
