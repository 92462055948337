import React, {useCallback, useEffect, useState} from 'react'
import {Link, useLocation} from 'react-router-dom'
import {PageSection, Title} from '@patternfly/react-core'
import styled from 'styled-components'

import {generateRequestFilter, generateRequestSort} from 'src/app/utils/ApiUtils'
import useFrontOffice from 'src/hooks/useFrontOffice'
import {GenericAsyncTableData, RequestData} from 'src/backoffice/app/components/GenericAsyncTableData'
import {
    PATH_TO_BO_CFP_EVENTO_CATALOGO,
    PATH_TO_BO_ELENCO_EVENTI_CATALOGO,
    PATH_TO_FO_ELENCO_EVENTI_CATALOGO
} from 'src/app/utils/RoutesConstants'

import {getDefaultAxiosConfig, getFormazioneResourceApi} from 'src/app/utils/ManagerRestGateway'
import GenericBreadCrumb from 'src/frontend/app/components/GenericBreadCrumb'
import AssegnaCFP from './formEventiCatalogo/AssegnaCFP'
import moment from 'moment'

import {responseMapperEvento} from './formEntiEsterni/formUtils'
import {getAllEnti, TIPO_ENTE} from 'src/model/EnteFormazione'

const LinkStyled = styled.div`
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: end;
`
const Spacer = () => (
  <>
    <br></br>
  </>
)
const IscrittiOrdineCFP: React.FunctionComponent = () => {
  const isFrontOffice = useFrontOffice()
  const pathToElenco = isFrontOffice ? PATH_TO_FO_ELENCO_EVENTI_CATALOGO : PATH_TO_BO_ELENCO_EVENTI_CATALOGO
  const basePath = { label: 'Elenco Eventi a Catalogo', link: pathToElenco }
  const myPath = {
    label: 'Assegnazione CFP',
    link: PATH_TO_BO_CFP_EVENTO_CATALOGO,
  }

  const [manualRefreshCount, setManualRefreshCount] = useState(0)
  const [evento, setEvento] = useState<any>(null)
  const [enti, setListEnti] = useState<any>([])
  const [data, setData] = useState<any>({})

  const location = useLocation<{ data: any }>()
  const searchParams = new URLSearchParams(location.search)
  const id = searchParams.get('id')

  const [modaleCFP, setModaleCFP] = useState<any>(null)

  useEffect(() => {
    getAllEnti()
      .then((res) => {
        setListEnti(res)
      })
      .catch(console.error)
  }, [])

  const loadData = useCallback(
    async (request: RequestData) => {
      try {
        const { data } = await getFormazioneResourceApi().msfoFormazioneEventoACatalogoGet(id, getDefaultAxiosConfig())
        const _data = await responseMapperEvento(data)

        let max = null
        if (_data.evento && _data.evento.data_inizio && _data.evento.data_fine) {
          const diffInDays = moment(_data.evento.data_fine).diff(moment(_data.evento.data_inizio), 'days')
          max = Math.floor(diffInDays)
        }

        if (!_data.tipologiaAttivita) {
          _data.crediti_riconosciuti = 0
        } else {
          if (_data.tipologiaAttivita.um === 'H') {
            const totOre = _data.totOreCFU
            if (totOre && totOre.indexOf(':') > 0) {
              const ore = parseInt(totOre.split(':')[0])
              const minuti = parseInt(totOre.split(':')[1])
              _data.crediti_riconosciuti = ((ore + minuti / 60) / _data.tipologiaAttivita.ore_evt_battute) * _data.tipologiaAttivita.max_cfp_evt
            } else {
              _data.crediti_riconosciuti = _data.tipologiaAttivita.max_cfp_evt
            }

            if (max) {
              _data.crediti_riconosciuti = _data.crediti_riconosciuti > max ? max : _data.crediti_riconosciuti
            }
          } else {
            if (_data.tipologiaAttivita.cod === 'AO' || _data.tipologiaAttivita.cod === 'AF') {
              _data.crediti_riconosciuti = 0.25
            } else {
              _data.crediti_riconosciuti = _data.evento.crediti ? _data.evento.crediti : _data.tipologiaAttivita.max_cfp_evt
            }
          }
        }
        _data.crediti_riconosciuti = _data.evento && _data.evento.crediti ? _data.evento.crediti : _data.crediti_riconosciuti
        setData(_data)
      } catch (e) {}

      const corso = { field: 'idcorso', value: id }
      const { data: evento } = await getFormazioneResourceApi().msfoFormazioneListaEventiACatalogoGet(
        generateRequestFilter([corso]),
        1,
        0,
        undefined,
        getDefaultAxiosConfig()
      )
      setEvento(evento?.items?.[0])


      request.filters.push(corso)
      const { data } = await getFormazioneResourceApi().msfoFormazioneListaIscrittiOrdineGet(
        generateRequestFilter(request.filters),
        request.pageSize,
        request.currentPage,
        generateRequestSort(request.sortColumn, request.sortDirection),
        getDefaultAxiosConfig()
      )

      return {
        items: data.items || [],
        total: data.count || 0,
      }
    },
    [id]
  )

  const filters: any = [
    { label: 'Cognome', field: 'cognome', type: 'input', operator: 'LIKE' },
    { label: 'Nome', field: 'nome', type: 'input', operator: 'LIKE' },
    { label: 'Codice Fiscale', field: 'cf', type: 'input', operator: 'LIKE' },
    { label: 'CFP assegnati', field: 'cfpassegnati', type: 'input', operator: 'LIKE' },
    { label: 'N. iscrizione', field: 'numero_iscrizione_ordine', type: 'input', operator: 'LIKE' },
  ]

    filters.push({
      label: 'Ordine di appartenenza',
      field: 'ordine_territoriale_competente',
      type: 'select',
      options: Object.keys(enti)
        .filter((code) => enti[code].tipo === TIPO_ENTE.ORDINE)
        .map((code) => {
          return {
            label: enti[code].nome,
            value: code,
          }
        }),
    })



  return (
    <PageSection>
      <div className="container  register-container">
        <GenericBreadCrumb paths={[basePath, myPath]} />
        <Spacer />

        <Title headingLevel="h1" size="lg">
          {myPath.label}
        </Title>
        <Spacer />


        <GenericAsyncTableData
          manualRefreshCount={manualRefreshCount}
          tableKey={'lista.assegnazione.cfp'}
          emptyMessage={'Nessun iscritto trovato'}

          filters={filters}
          columns={[
            { label: 'Cognome', field: 'cognome', isSortable: true },
            { label: 'Nome', field: 'nome', isSortable: true },
            { label: 'Codice Fiscale', field: 'cf', isSortable: true },
            { label: 'Ordine di appartenenza', field: 'ordine_territoriale_competente', isSortable: true },
            { label: 'CFP assegnati', field: 'cfpassegnati', isSortable: true, toFixed: 3 },
            { label: 'N. iscrizione', field: 'numero_iscrizione_ordine', isSortable: true },
          ]}
          getRows={loadData}
          getRowActions={(row) => {
            const actions: any = [{ label: 'Assegna crediti', onClick: () => setModaleCFP({ validResults: [{ ...row, user_name: row.cf }] }) }]
            return actions.filter((a) => a)
          }}
        />
        {modaleCFP && (
          <AssegnaCFP
            iscritti={modaleCFP?.validResults}
            errori={modaleCFP?.invalidResults}
            evento={evento}
            onClose={() => {
              setModaleCFP(null)
              setManualRefreshCount((c) => c + 1)
            }}
            maxCFPCorso={data.crediti_riconosciuti}
          />
        )}
        <LinkStyled>
          <Link to={pathToElenco}>Torna alla lista</Link>
        </LinkStyled>
      </div>
    </PageSection>
  )
}

export default IscrittiOrdineCFP
