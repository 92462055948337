import {RegistrazionePagamentoQuotaAnnualeDTO} from '@parsec/msga'
import {getDefaultAxiosConfig, getIscrizioneOrdineResourceApi} from 'src/app/utils/ManagerRestGateway'

/**
 * Registrazione pagamento quota annuale Massivo
 * @returns
 */
export async function registraPagamentoQuotaAnnualeMassivo(file) {
    try {
        const response = await getIscrizioneOrdineResourceApi().msgaIotBoRegistrazionePagamentoQuotaAnnualeMassivoPostForm(file, '', '', getDefaultAxiosConfig());
        return response;
    } catch (err) {
        console.error("Errore durante la chiamata msgaIotBoRegistrazionePagamentoQuotaAnnualeMassivoPostForm - " + err);
        throw new Error("Errore durante la chiamata msgaIotBoRegistrazionePagamentoQuotaAnnualeMassivoPostForm - " + err);
    }
}

/**
 * Registrazione pagamento quota annuale singolo
 * @returns
 */
export async function registraPagamentoQuotaAnnualeSingolo(dtoQuotaAnnuale) {
    try {
        var params: RegistrazionePagamentoQuotaAnnualeDTO = {
            idSoggetto: dtoQuotaAnnuale.idSoggetto,
            idIscrizione: dtoQuotaAnnuale.idIscrizione,
            cf: dtoQuotaAnnuale.cf,
            annoRiferimento: dtoQuotaAnnuale.annoRiferimento,
            dataPagamento: dtoQuotaAnnuale.dataPagamento,
            dataFineValiditaPagamento: dtoQuotaAnnuale.dataFineValiditaPagamento
        }
        const response = await getIscrizioneOrdineResourceApi().msgaIotBoRegistrazionePagamentoQuotaAnnualePost(params, getDefaultAxiosConfig());
        return response;
    } catch (err) {
        console.error("Errore durante la chiamata msgaIotBoRegistrazionePagamentoQuotaAnnualePost - " + err);
        throw new Error("Errore durante la chiamata msgaIotBoRegistrazionePagamentoQuotaAnnualePost - " + err);
    }
}
