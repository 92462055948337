import {Button} from 'design-react-kit'
import React from 'react'

class GenericButton extends React.Component<GeneralButtonProps, any>{
  render() {
    const { form, onSubmitCapture, onSubmit, size, color, type, onClickEvent, isDisabled, isHidden, value, src, label, children, className } = this.props
    return (
      <Button
        form={form}
        onSubmitCapture={onSubmitCapture}
        onSubmit={onSubmit}
        size={size}
        color={color}
        type={type}
        onClick={onClickEvent}
        disabled={isDisabled}
        hidden={isHidden}
        value={value}
        className={className} >
        <img hidden={!src} src={src} alt=" "/>
        {label}
        {children}
      </Button>
    );
  }
}

export declare interface GeneralButtonProps {
  label?: any;
  value?: any,
  onClickEvent?: any;
  type?: any;
  color?: any;
  size?: any;
  isDisabled?: boolean;
  isHidden?: boolean;
  activeTab?: any;
  src?: any;
  id?: any;
  onSubmit?: any;
  children?: any;
  /**
   * @description Richiama il form con l'id indicato
   */
  form?: string;
  onSubmitCapture?: any;
  className?:any;
}

export default GenericButton;
