/* eslint-disable array-callback-return */
import {FormGroup} from 'design-react-kit'
import React from 'react'
import {getComuniByProvinciaMsga} from 'src/processes/Comuni'
import GenericCard from '../../../components/GenericCard'
import GenericInput from '../../../components/GenericInput'
import GenericSelect from '../../../components/GenericSelect'
import {getAllProvince} from 'src/processes/Province'
import GenericSpinner from 'src/backoffice/app/components/GenericSpinner'
import {isCurrentMobileDevice} from 'src/utilities/deviceUtility'

export default class SedeLegale extends React.Component<SedeLegaleProps, any>{
  constructor(props) {
    super(props);
    this.state = {
      prevData: props.prevData,
      prevClicked: props.prevClicked,
      listaComuni: [],
      province: [],
      fields: (props.prevData && props.prevClicked) ? props.prevData[0] : props?.data,
      isLoaded: false,
      isSedeLegaleDiversa: false,
      message: '',
      provinciaSelected: true,
      yes_no_options: [
        { label: "Si", value: "Si" },
        { label: "No", value: "No" }
      ]
    };
  }

  async componentDidMount(): Promise<void> {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });

    if (!this.props.visualizzaFlag) {
      if (this.props.data) {
        if(this.props.data.sedeLegaleStudio && (this.props.data.sedeLegaleStudio.label !== 'No')) this.setState({isSedeLegaleDiversa: true})
        let data = this.props.data;
        this.setState({ fields: data })
      }
    }

    else if ((this.props.visualizzaFlag) && this.props.data) {
      if(this.props.data.sedeLegaleStudio && (this.props.data.sedeLegaleStudio.label !== 'No')) this.setState({isSedeLegaleDiversa: true})
      this.setState({ fields: this.props.data })
    }

    if (!this.state.isLoaded && !this.props.visualizzaFlag) {


      await getAllProvince().then((response: any) => {
        var province = [{ label: '', value: '' }];

        response.data.map((provincia, id) => {
          province[id] = {
            label: provincia['denominazioneUnitaTerritorialeSovracomunale'],
            value: provincia['siglaAutomobilistica']
          };
        })

        let sortedProvince = province.sort(
          (p1, p2) => (p1.label > p2.label) ? 1 : (p1.label < p2.label) ? -1 : 0);
          this.setState({ province: sortedProvince, provinceCopy: sortedProvince})
        })
      }

      var prov = this.state.fields['provinciaStudio']?.label
      getComuniByProvinciaMsga(prov).then(async comuni => {
        this.setState({ listaComuni: comuni, isLoaded: true, provinciaSelected: true });
      });

      var labelProvinciaResidenza = this.state.fields?.provinciaResidenza?.label
      var isProvinciaResidenzaEstera = labelProvinciaResidenza?.toLowerCase() === "stato estero" || labelProvinciaResidenza?.toLowerCase() === "stato estero";
      const { yes_no_options } = this.state

      if(isProvinciaResidenzaEstera){
        // this.state.fields.sedeLegaleStudio = { label: 'Si', value: 'Si' }
        this.setState({
          yes_no_options: yes_no_options.filter(sede => sede.label.toLowerCase() === "si")
        })
      }

      this.setState({ isLoaded: true })
    }

  /**
   * recupero la cittadinanza inserita nel primo step e controllo se è estera o meno.
   * Se estera imposto la select sede legale diversa dalla residenza a si e rimuovo lo stato estero dal menu a tendina delle province
   * altrimenti funziona tutto come prima
   */
  componentWillReceiveProps(): void {
    const { yes_no_options, fields, province, provinceCopy } = this.state

    var labelProvinciaResidenza = this.state.fields?.provinciaResidenza?.label
    var isProvinciaResidenzaEstera = labelProvinciaResidenza?.toLowerCase() === "stato estero" || labelProvinciaResidenza?.toLowerCase() === "stato estero";

    if (isProvinciaResidenzaEstera) {

      var provincia = province?.filter(provincia => provincia.label.toLowerCase() !== "stato estero")


      this.setState({
        yes_no_options: yes_no_options.filter(sede => sede.label.toLowerCase() === "si")
      })

      this.setState({ province: provincia })
    } else {
      fields.sedeLegaleStudio = this.props.data.sedeLegaleStudio

      this.setState({
        yes_no_options: [{ label: 'Si', value: 'Si' }, { label: 'No', value: 'No' }],
        province: provinceCopy
      })
    }
  }

  render() {
    const { province, yes_no_options, fields } = this.state;
    const { tabNumber } = this.props;

    /********************************* Compilazione Form ***********************************/
    const setDataItem = async (e: any, type: any, field: any) => {
      let fields = this.state.fields;

      if (type === 'select') {
        fields[field] = { label: e.label, value: e.value };
      }
      else {
        fields[field] = e.target.value;
      }

      this.setState({ fields });
      let prov = "";
      if ((this.state.fields['sedeLegaleStudio'] && this.state.fields['sedeLegaleStudio'].label !== 'No'))
      {
        this.setState({isSedeLegaleDiversa: true})
        this.props.getFlagSvuotaOrdine(true)
      }
      else
      {
        this.setState({isSedeLegaleDiversa: false})
        this.props.getFlagSvuotaOrdine(true)
      }

      if (this.state.fields['provinciaStudio'])
        prov = this.state.fields['provinciaStudio']?.label;

      if (field === "provinciaStudio") {
        this.props.getFlagSvuotaOrdine(true)
        this.state.fields['cittaSedeLegaleStudio'] = undefined
        this.setState({provinciaSelected: false})
          getComuniByProvinciaMsga(prov).then(async comuni => {
            this.setState({ listaComuni: comuni, isLoaded: true, provinciaSelected: true });
          });
      }

      if (field === "cittaSedeLegaleStudio") {
        if (type === 'select') {
          fields[field] = {label: fields[field]?.label, value: fields[field]?.value};
        }
        else {
          fields[field] = e.target.value;
        }
      }

      this.setState({isLoaded: true})
      this.props.getSelectedValue(fields)
    }

    /*************************** Form Iscrizione *******************/
    const formIscrizione = [

      <GenericCard key={'formIscrizioneSedeLegale'} cardHeader="Sede legale" cardBody={
        <>
          <div id="formIscrizioneSedeLegale" className="row">
            <div>
              <div className="row">
                  <div hidden={this.props.visualizzaFlag} className={isCurrentMobileDevice() ? null : "col-6"}>
                    <FormGroup label={"sedeLegaleStudio"} >
                      <GenericSelect
                        elementId={"idSedeLegaleStudioDiversa"}
                        label='Sede legale diversa dalla residenza? *'
                        placeholder='Si / No'
                        defaultValue={fields.sedeLegaleStudio?.label ? fields.sedeLegaleStudio : null}
                        isDisabled={this.props.visualizzaFlag}
                        onChange={(e) => setDataItem(e, 'select', 'sedeLegaleStudio')}
                        defaultOptions={yes_no_options}
                        errorMessageAlert={this.props.message}
                        isRequired
                        isHidden={fields.sedeLegaleStudio === undefined}
                        currentStep={tabNumber} />
                    </FormGroup>
                  </div>
                  <div hidden={!this.props.visualizzaFlag} className={isCurrentMobileDevice() ? null : "col-6"}>
                    <GenericInput isDisabled id="sedeLegaleStudio" name={'sedeLegaleStudio'} defaultValue={(this.state.fields?.sedeLegaleStudio && this.state.fields?.sedeLegaleStudio?.label !== "" && this.state.fields?.sedeLegaleStudio?.label) ? this.state.fields?.sedeLegaleStudio?.label : null}
                      onChange={(e) => setDataItem(e, 'select', 'sedeLegaleStudio')} label='Sede legale diversa dalla residenza? *'
                      placeholder='Si / No' />
                  </div>
              </div>
                  <div hidden={!this.state.isSedeLegaleDiversa}>
                    <div className="row">
                      <div hidden={this.props.visualizzaFlag} className={isCurrentMobileDevice() ? null : "col-6"}>
                        <FormGroup label={"provinciaStudio"}>
                          <GenericSelect
                            label='Provincia *'
                            placeholder='Selezionare provincia'
                            elementId={"idProvinciaStudio"}
                            defaultValue={(this.state.fields?.provinciaStudio && this.state.fields?.provinciaStudio?.label) ? this.state.fields['provinciaStudio'] : undefined}
                            onChange={(e) => setDataItem(e, 'select', 'provinciaStudio')}
                            defaultOptions={province}
                            errorMessageAlert={this.props.message}
                            isRequired={this.state.isSedeLegaleDiversa}
                            isHidden={!this.state.isSedeLegaleDiversa}
                            currentStep={tabNumber} />
                        </FormGroup>
                      </div>
                      <div hidden={!this.props.visualizzaFlag} className={isCurrentMobileDevice() ? null : "col-6"}>
                        <GenericInput
                          isDisabled
                          id="provinciaStudio"
                          name={'provinciaStudio'}
                          defaultValue={(this.state.fields['provinciaStudio']) ? this.state.fields['provinciaStudio']?.label : '-'}
                          onChange={(e) => setDataItem(e, 'select', 'provinciaStudio')}
                          label='Provincia sede *'
                          placeholder='Selezionare provincia di residenza' />
                      </div>
                      <div className={isCurrentMobileDevice() ? null : "col-6"}>
                        <FormGroup label={"cittaSedeLegaleStudio"} hidden={!this.state.provinciaSelected}>
                          {this.state.provinciaSelected ?
                            <>
                              <GenericInput
                                isDisabled={this.props.visualizzaFlag}
                                name={'cittaSedeLegaleStudio'}
                                defaultValue={(this.state.fields['cittaSedeLegaleStudio']?.label) ? this.state.fields['cittaSedeLegaleStudio'].label : null}
                                onChange={(e) => setDataItem(e, 'text', 'cittaSedeLegaleStudio')}
                                label='Città *'
                                id="cittaSedeLegaleStudioEstero"
                                placeholder='Selezionare la città della sede legale'
                                errorMessageAlert={this.props.message}
                                isRequired={this.state.isSedeLegaleDiversa && this.state.fields['provinciaStudio']?.label === 'Stato Estero'}
                                isHidden={this.state.fields['provinciaStudio']?.label !== 'Stato Estero'}
                                type={this.props.visualizzaFlag ? null : 'text'}
                                currentStep={tabNumber} />
                              <GenericSelect
                                isDisabled={this.props.visualizzaFlag}
                                elementId="cittaSedeLegaleStudio"
                                name={'cittaSedeLegaleStudio'}
                                defaultValue={(fields['cittaSedeLegaleStudio'] && fields['cittaSedeLegaleStudio']?.label) ? this.state.fields['cittaSedeLegaleStudio'] : null}
                                onChange={(e) => setDataItem(e, 'select', 'cittaSedeLegaleStudio')}
                                label='Città *'
                                placeholder='Selezionare la città della sede legale'
                                defaultOptions={this.state.listaComuni}
                                errorMessageAlert={this.props.message}
                                isRequired={this.state.isSedeLegaleDiversa && this.state.fields['provinciaStudio']?.label !== 'Stato Estero'}
                                isHidden={this.state.fields['provinciaStudio']?.label === 'Stato Estero'}
                                currentStep={tabNumber} />
                            </>
                          : null}
                        </FormGroup>
                      </div>
                      </div>
                      <div className="row">
                          <div className={isCurrentMobileDevice() ? null : "col-6"}>
                            <FormGroup label={"indirizzoSedeLegaleStudio"} >
                              <GenericInput isDisabled={this.props.visualizzaFlag} id="indirizzoSedeLegaleStudio" name={'indirizzoSedeLegaleStudio'} defaultValue={(this.state.fields['indirizzoSedeLegaleStudio']) ? this.state.fields['indirizzoSedeLegaleStudio'] : null}
                                onChange={(e) => setDataItem(e, 'text', 'indirizzoSedeLegaleStudio')} label='Indirizzo *' placeholder='Inserire indirizzo' type={this.props.visualizzaFlag ? null : 'text'} errorMessageAlert={this.props.message} isRequired={this.state.isSedeLegaleDiversa} currentStep={tabNumber} />
                            </FormGroup>
                          </div>
                          <div className={isCurrentMobileDevice() ? null : "col-3"}>
                            <FormGroup label={"numeroCivicoSedeLegaleStudio"} >
                              <GenericInput isDisabled={this.props.visualizzaFlag} id="numeroCivicoSedeLegaleStudio" name={'numeroCivicoSedeLegaleStudio'} defaultValue={(this.state.fields['numeroCivicoSedeLegaleStudio']) ? this.state.fields['numeroCivicoSedeLegaleStudio'] : null}
                                onChange={(e) => setDataItem(e, 'text', 'numeroCivicoSedeLegaleStudio')} label='Numero *' placeholder='Numero' type={this.props.visualizzaFlag ? null : 'text'} errorMessageAlert={this.props.message} isRequired={this.state.isSedeLegaleDiversa} currentStep={tabNumber} />
                            </FormGroup>
                          </div>
                          <div className={isCurrentMobileDevice() ? null : "col-3"}>
                            <FormGroup label={"capSedeLegaleStudio"} >
                              <GenericInput isDisabled={this.props.visualizzaFlag} id="capSedeLegaleStudio" name={'capSedeLegaleStudio'} defaultValue={(this.state.fields['capSedeLegaleStudio']) ? this.state.fields['capSedeLegaleStudio'] : null}
                                onChange={(e) => setDataItem(e, 'text', 'capSedeLegaleStudio')} label='CAP *' placeholder='CAP' type={"cap"} errorMessageAlert={this.props.message} isRequired={this.state.isSedeLegaleDiversa} currentStep={tabNumber} />
                            </FormGroup>
                          </div>
                      </div>
                  </div>


              <div className="row">
                  <div className={isCurrentMobileDevice() ? null : "col-6"}>
                    <FormGroup label={"telefonoSedeLegaleStudio"} >
                      <GenericInput isDisabled={this.props.visualizzaFlag} id="telefonoSedeLegaleStudio" name={'telefonoSedeLegaleStudio'} defaultValue={(this.state.fields['telefonoSedeLegaleStudio']) ? this.state.fields['telefonoSedeLegaleStudio'] : null}
                        onChange={(e) => setDataItem(e, 'text', 'telefonoSedeLegaleStudio')} label='Telefono Studio' placeholder='Inserire numero di telefono' type={"tel"} />
                    </FormGroup>
                  </div>
                  <div className={isCurrentMobileDevice() ? null : "col-6"}>
                    <FormGroup label={"faxSedeLegaleStudio"} >
                      <GenericInput isDisabled={this.props.visualizzaFlag} id="faxSedeLegaleStudio" name={'faxSedeLegaleStudio'} defaultValue={(this.state.fields['faxSedeLegaleStudio']) ? this.state.fields['faxSedeLegaleStudio'] : null}
                        onChange={(e) => setDataItem(e, 'text', 'faxSedeLegaleStudio')} label='Fax Studio' placeholder='Inserire numero Fax' type={"fax"} />
                    </FormGroup>
                  </div>
                  <div className={isCurrentMobileDevice() ? null : "col-6"}>
                    <FormGroup label={"mailSedeLegaleStudio"} >
                      <GenericInput isDisabled={this.props.visualizzaFlag} id="mailSedeLegaleStudio" name={'mailSedeLegaleStudio'} defaultValue={(this.state.fields['mailSedeLegaleStudio']) ? this.state.fields['mailSedeLegaleStudio'] : null}
                        onChange={(e) => setDataItem(e, 'text', 'mailSedeLegaleStudio')} label='Email Studio*' placeholder='Inserire indirizzo email' type={this.props.visualizzaFlag ? null : 'email'} errorMessageAlert={this.props.message} isRequired currentStep={tabNumber} />
                    </FormGroup>
                  </div>
                  <div className={isCurrentMobileDevice() ? null : "col-6"}>
                    <FormGroup label={"pecSedeLegaleStudio"} >
                      <GenericInput isDisabled={this.props.visualizzaFlag} id="pecSedeLegaleStudio" name={'pecSedeLegaleStudio'} defaultValue={(this.state.fields['pecSedeLegaleStudio']) ? this.state.fields['pecSedeLegaleStudio'] : null}
                        onChange={(e) => setDataItem(e, 'text', 'pecSedeLegaleStudio')} label='PEC Studio*' placeholder='Inserire PEC' type={this.props.visualizzaFlag ? null : 'email'} errorMessageAlert={this.props.message} isRequired currentStep={tabNumber} />
                    </FormGroup>
                  </div>
                  <div className={isCurrentMobileDevice() ? null : "col-6"}>
                    <FormGroup label={"sitoInternet"} >
                      <GenericInput isDisabled={this.props.visualizzaFlag} id="sitoInternet" name={'sitoInternet'} defaultValue={(this.state.fields['sitoInternet']) ? this.state.fields['sitoInternet'] : null}
                        onChange={(e) => setDataItem(e, 'text', 'sitoInternet')} label='Sito Internet' placeholder='Inserire sito ' type={this.props.visualizzaFlag ? null : 'text'} />
                    </FormGroup>
                  </div>
                  <div className={isCurrentMobileDevice() ? null : "col-6"}>
                    <FormGroup label={"facebook"} >
                      <GenericInput isDisabled={this.props.visualizzaFlag} id="facebook" name={'facebook'} defaultValue={(this.state.fields['facebook']) ? this.state.fields['facebook'] : null}
                        onChange={(e) => setDataItem(e, 'text', 'facebook')} label='Facebook' placeholder='Inserire profilo Facebook' type={this.props.visualizzaFlag ? null : 'text'} />
                    </FormGroup>
                  </div>
                  <div className={isCurrentMobileDevice() ? null : "col-6"}>
                    <FormGroup label={"twitter"} >
                      <GenericInput isDisabled={this.props.visualizzaFlag} id="twitter" name={'twitter'} defaultValue={(this.state.fields['twitter']) ? this.state.fields['twitter'] : null}
                        onChange={(e) => setDataItem(e, 'text', 'twitter')} label='Twitter' placeholder='Inserire profilo Twitter' type={this.props.visualizzaFlag ? null : 'text'} />
                    </FormGroup>
                  </div>
                </div>
              </div>

          </div>
        </>
      }></GenericCard>
    ];

    /*************************** Form Visualizza *******************/
    const formVisualizza = [

      <GenericCard key={'sedeLegale'} cardHeader="Sede legale" cardBody={
        <>
          <div id="formIscrizione" className="row">
            <div>
              <div className="row">

                <div className={isCurrentMobileDevice() ? null : "col-6"}>
                  <GenericInput type={'text'} isDisabled label='Sede legale diversa dalla residenza?' placeholder=' '
                    defaultValue={(this.state.fields['sedeLegaleStudio']) ? this.state.fields['sedeLegaleStudio'].label : '-'}
                  />
                </div>
                <div className="row" hidden={(this.state.fields['sedeLegaleStudio'] && this.state.fields['sedeLegaleStudio'].label === 'No')}>
                  <div className={isCurrentMobileDevice() ? null : "col-6"}>
                    <GenericInput type={'text'} isDisabled label='Provincia' placeholder=' ' defaultValue={(this.state.fields['provinciaStudio'] && this.state.fields['provinciaStudio'].label) ? this.state.fields['provinciaStudio'].label : '-'} />
                  </div>
                  <div className={isCurrentMobileDevice() ? null : "col-6"}>
                    <GenericInput type={'text'} isDisabled id="cittaSedeLegaleStudio" name={'cittaSedeLegaleStudio'} defaultValue={(this.state.fields['cittaSedeLegaleStudio'] && this.state.fields['cittaSedeLegaleStudio'].label) ? this.state.fields['cittaSedeLegaleStudio'].label : '-'} label='Città' placeholder=' ' />
                  </div>
                  <div className={isCurrentMobileDevice() ? null : "col-6"}>
                    <GenericInput type={'text'} isDisabled id="indirizzoSedeLegaleStudio" name={'indirizzoSedeLegaleStudio'} defaultValue={(this.state.fields['indirizzoSedeLegaleStudio']) ? this.state.fields['indirizzoSedeLegaleStudio'] : '-'}
                      label='Indirizzo' placeholder=' ' />
                  </div>
                  <div className={isCurrentMobileDevice() ? null : "col-3"}>
                    <GenericInput type={'text'} isDisabled id="numeroCivicoSedeLegaleStudio" name={'numeroCivicoSedeLegaleStudio'} defaultValue={(this.state.fields['numeroCivicoSedeLegaleStudio']) ? this.state.fields['numeroCivicoSedeLegaleStudio'] : '-'}
                      label='Numero' placeholder=' ' />
                  </div>
                  <div className={isCurrentMobileDevice() ? null : "col-3"}>
                    <FormGroup label={"capSedeLegaleStudio"} >
                      <GenericInput type={'text'} isDisabled={this.props.visualizzaFlag} id="capSedeLegaleStudio" name={'capSedeLegaleStudio'} defaultValue={(this.state.fields['capSedeLegaleStudio']) ? this.state.fields['capSedeLegaleStudio'] : '-'}
                        onChange={(e) => setDataItem(e, 'text', 'capSedeLegaleStudio')} label='CAP' placeholder=' ' />
                    </FormGroup>
                  </div>
                </div>
                <div className="row">

                  <div className={isCurrentMobileDevice() ? null : "col-6"}>
                    <FormGroup label={"telefonoSedeLegaleStudio"} >
                      <GenericInput type={'text'} isDisabled={this.props.visualizzaFlag} id="telefonoSedeLegaleStudio" name={'telefonoSedeLegaleStudio'} defaultValue={(this.state.fields['telefonoSedeLegaleStudio']) ? this.state.fields['telefonoSedeLegaleStudio'] : '-'}
                        onChange={(e) => setDataItem(e, 'text', 'telefonoSedeLegaleStudio')} label='Telefono' placeholder=' ' />
                    </FormGroup>
                  </div>
                  <div className={isCurrentMobileDevice() ? null : "col-6"}>
                    <FormGroup label={"faxSedeLegaleStudio"} >
                      <GenericInput type={'text'} isDisabled={this.props.visualizzaFlag} id="faxSedeLegaleStudio" name={'faxSedeLegaleStudio'} defaultValue={(this.state.fields['faxSedeLegaleStudio']) ? this.state.fields['faxSedeLegaleStudio'] : '-'}
                        onChange={(e) => setDataItem(e, 'text', 'faxSedeLegaleStudio')} label='Fax' placeholder=' '  />
                    </FormGroup>
                  </div>
                  <div className={isCurrentMobileDevice() ? null : "col-6"}>
                    <FormGroup label={"mailSedeLegaleStudio"} >
                      <GenericInput type={'text'} isDisabled={this.props.visualizzaFlag} id="mailSedeLegaleStudio" name={'mailSedeLegaleStudio'} defaultValue={(this.state.fields['mailSedeLegaleStudio']) ? this.state.fields['mailSedeLegaleStudio'] : '-'}
                        onChange={(e) => setDataItem(e, 'text', 'mailSedeLegaleStudio')} label='Email' placeholder=' ' />
                    </FormGroup>
                  </div>
                  <div className={isCurrentMobileDevice() ? null : "col-6"}>
                    <FormGroup label={"pecSedeLegaleStudio"} >
                      <GenericInput type={'text'} isDisabled={this.props.visualizzaFlag} id="pecSedeLegaleStudio" name={'pecSedeLegaleStudio'} defaultValue={(this.state.fields['pecSedeLegaleStudio']) ? this.state.fields['pecSedeLegaleStudio'] : '-'}
                        onChange={(e) => setDataItem(e, 'text', 'pecSedeLegaleStudio')} label='PEC' placeholder=' '  />
                    </FormGroup>
                  </div>
                  <div className={isCurrentMobileDevice() ? null : "col-6"}>
                    <FormGroup label={"sitoInternet"} >
                      <GenericInput type={'text'} isDisabled={this.props.visualizzaFlag} id="sitoInternet" name={'sitoInternet'} defaultValue={(this.state.fields['sitoInternet']) ? this.state.fields['sitoInternet'] : '-'}
                        onChange={(e) => setDataItem(e, 'text', 'sitoInternet')} label='Sito Internet' placeholder=' ' />
                    </FormGroup>
                  </div>
                  <div className={isCurrentMobileDevice() ? null : "col-6"}>
                    <FormGroup label={"facebook"} >
                      <GenericInput type={'text'} isDisabled={this.props.visualizzaFlag} id="facebook" name={'facebook'} defaultValue={(this.state.fields['facebook']) ? this.state.fields['facebook'] : '-'}
                        onChange={(e) => setDataItem(e, 'text', 'facebook')} label='Facebook' placeholder=' '  />
                    </FormGroup>
                  </div>
                  <div className={isCurrentMobileDevice() ? null : "col-6"}>
                    <FormGroup label={"twitter"} >
                      <GenericInput type={'text'} isDisabled={this.props.visualizzaFlag} id="twitter" name={'twitter'} defaultValue={(this.state.fields['twitter']) ? this.state.fields['twitter'] : '-'}
                        onChange={(e) => setDataItem(e, 'text', 'twitter')} label='Twitter' placeholder=' '  />
                    </FormGroup>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </>
      }></GenericCard>
    ];

    return (
      <>{(this.state.isLoaded) ? (this.props.visualizzaFlag) ? formVisualizza : formIscrizione : <GenericSpinner />} </>
    )

  }
}

export declare interface SedeLegaleProps {
  activeTab?: any;
  tabClick?: any;
  data?: any;
  prevData?: any;
  prevClicked?: any
  processoIscrizione?: any;
  modificaDatiFlag?: any;
  reiscrizioneFlag?: any;
  visualizzaFlag?: any;
  message?: any;
  getSelectedValue?: any;
  tabNumber?: number;
  getFlagSvuotaOrdine?: any;
}
