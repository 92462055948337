import {Alert} from 'design-react-kit'
import React from 'react'
import {getHome} from 'src/utilities/utility'

class NotFound extends React.Component {
  render() {
    return (
      <Alert color='danger'>
        Sei sicuro che l'URL sia corretto?
        <br></br>
        <a href={getHome()} className='alert-link'>
         Torna nella home page
        </a>{' '}
      </Alert>
    )
  }
}

export default NotFound;
