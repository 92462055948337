/* eslint-disable react/no-direct-mutation-state */
import * as React from 'react'
import {
    PATH_TO_BO_ANAG_ELENCO_ISCRITTI,
    PATH_TO_BO_ATTI_DISCIPLINARI,
    PATH_TO_BO_ATTI_DISCIPLINARI_MODIFICA
} from 'src/app/utils/RoutesConstants'
import GenericSpinner from 'src/backoffice/app/components/GenericSpinner'
import {aggiornaDisciplinari, getDisciplinareById} from 'src/processes/disciplinari/Disciplinari'
import FormListaDisciplinari from './form/FormListaDisciplinari'
import GenericModal from 'src/backoffice/app/components/GenericModal'
import {convertUrlParamsToObject, goTo, inputRequiredValidation, isEmptyValue} from 'src/utilities/utility'
import GenericAlert from 'src/frontend/app/components/GenericAlert'
import GenericBreadCrumb from 'src/frontend/app/components/GenericBreadCrumb'

var breadcrumbOptions = [
  { label: "Anagrafica", link: "#" },
  { label: "Elenco iscritti", link: PATH_TO_BO_ANAG_ELENCO_ISCRITTI},
  { label: "Atti disciplinari", link: PATH_TO_BO_ATTI_DISCIPLINARI_MODIFICA },
  { label: "Modifica", link: PATH_TO_BO_ATTI_DISCIPLINARI_MODIFICA },
]

class ModificaSanzioneDisciplinari extends React.Component<any , any>{
    constructor(props) {
      super(props);
      this.state = {
          prevTab: "",
          idIscrizione: 1,
          message:"",
          isSubmitted:false,
          fields: {},
          disciplinari : {},
          errors: [],
          modalOpen:false,
          modalText:"testo finestra modale",
          modalTitle:"titolo finestra modale"
      };
  }

  async componentDidMount(): Promise<void> {
    var url = window.location.search?.split("?")[1]
    var urlParams = convertUrlParamsToObject(url)

    if (!urlParams?.id || !urlParams?.idIscrizione || !urlParams?.tipologiaIscrizione || !urlParams?.cf) {
      this.props.history.push(PATH_TO_BO_ANAG_ELENCO_ISCRITTI);
    }
    else {
      let id = urlParams?.id;
      let idIscrizione = urlParams?.idIscrizione
      let tipologiaIscrizione = urlParams?.tipologiaIscrizione
      let cf = urlParams?.cf
      this.setState({ idIscrizione: idIscrizione, tipologiaIscrizione: tipologiaIscrizione, cf: cf, id: urlParams?.id })

      getDisciplinareById(id).then(async (responseDisciplinare: any)=> {
        await this.setState({disciplinari: responseDisciplinare, isLoaded: true});
          let fields = this.state.fields;
          fields = responseDisciplinare;
          await this.setState({ fields: fields, disciplinari:fields,  isLoaded: true });

        }).catch(async () => {
                  setTimeout(() => {
                      this.setState({ message: "C'è stato un errore durante il recupero dei dati", typeMessage: 'danger' })
                  }, 3000);
                  await this.setState({isLoaded: false})
        })
      }
    }
    /** Funzione per aggiornare i campi del form */
    getSelectValues = async (fields: any, type: any) => {
      await this.setState({fields: fields, data: fields})
      this.setState({isLoaded: true})
    }

  hasFormErrors = (alert) => {
    var findItem = this.state.errors.find(a => a.id === alert.id)
    var errorID = this.state.errors.indexOf(findItem)
    if (findItem) {
      if (alert.isHidden)
        this.state.errors[errorID] = { id: alert.id, isDisabled: alert.isDisabled, isHidden: alert.isHidden, stepTab: alert.stepTab }
      else
        this.state.errors[errorID] = { error: alert.hasError, id: alert.id, isDisabled: alert.isDisabled, isHidden: alert.isHidden, stepTab: alert.stepTab }
    } else {
      this.state.errors.push({ error: alert.hasError, id: alert.id, isDisabled: alert.isDisabled, isHidden: alert.isHidden, stepTab: alert.stepTab })
    }
  }

  inviaDisciplinari = async (disciplinari, showSedeFlag, showDateProvvedimento, showDataProvvedimentoAl) => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    if (isEmptyValue(disciplinari.provvedimento)) {
      disciplinari.provvedimento = {
        value: "avvertimento",
        label: "Avvertimento"
      }
    }
    /*--------------------------------------------- Validazione campi obbligatori ------------------------------------------------*/
    var requiredFields = [
      { value: disciplinari.procedimentoNr, label: 'Procedimento nr' },
      { value: disciplinari.causale, label: 'Causale' },
      { value: disciplinari.organo.value ? disciplinari.organo.value : disciplinari.organo ? disciplinari.organo : 'cassazione', label: 'Organo' },
      showSedeFlag ? { value: disciplinari.chiProcede, label: 'Sede' } : null,
      { value: disciplinari.dataApertura, label: 'Data apertura' },
      { value: disciplinari.dataChiusura, label: 'Data chiusura' },
      { value: disciplinari.determinazione, label: 'Determinazione' },
      { value: disciplinari.provvedimento && disciplinari.provvedimento.value ? disciplinari.provvedimento.value : disciplinari.provvedimento ? disciplinari.provvedimento : 'avvertimento', label: 'Tipo provvedimento' },
      { value: disciplinari.dataProvvedimento, label: 'Data delibera' },
      { value: disciplinari.deliberaNr, label: 'Delibera Nr' },
      showDateProvvedimento ? { value: disciplinari.provvedimentoDal, label: 'Data inizio provvedimento' } : null,
      showDateProvvedimento && showDataProvvedimentoAl && (disciplinari.provvedimento !==  'sospensione-morosita' && disciplinari.provvedimento.value !==  'sospensione-morosita' && !disciplinari.provvedimentoAl.value)  ? { value: disciplinari.provvedimentoAl, label: 'Data fine provvedimento' } : null,
      { value: disciplinari.motivazione, label: 'Motivazione' },
    ]
    var errorRequired: any = inputRequiredValidation(requiredFields)

    if (errorRequired !== undefined && errorRequired !== "") {
      return this.setState({ message: errorRequired.messaggio, typeMessage: errorRequired.type, isLoaded: true })
    }
    /*------------------------------------------------------------------------------------------------------------------------------*/

    else {
      /************************** Chiamata per l'invio finale ******************************/
      await aggiornaDisciplinari(disciplinari).then((response: any) => {
        this.setState({ message: "Dati salvati con successo", typeMessage: "success", isLoaded: true })
        this.showModal(true, "Modifica avvenuta con successo", "La tua modifica per il provvedimento - N. delibera " + disciplinari.deliberaNr + " - è stata completata con successo!");
      }).catch((err) => {

        this.setState({ message: "C'è stato un problema nell'invio dei dati: " + err.message.replace("Error:", ""), typeMessage: "danger", isLoaded: true })
      })
    }
  }

  showModal = async (booleanValue, title, text) => {

    await this.setState({
        modalOpen: booleanValue,
        modalTitle: title,
        modalText:text,
        isLoaded: true,
    });


  }

  closeModal = () => {
    this.setState({modalOpen:false});
    this.props.history.push(PATH_TO_BO_ATTI_DISCIPLINARI + '?cf=' + this.state.cf + '&idIscrizione=' + this.state.idIscrizione + '&tipologiaIscrizione=' + this.state.tipologiaIscrizione
    ).setTimeout(3000);
  }

  goToDisciplinari = (redirectLink: any) => {
      if(this.state.disciplinari.idIscrizione) {
        this.props.history.push(redirectLink + '?cf=' + this.state.cf + '&idIscrizione=' + this.state.idIscrizione + '&tipologiaIscrizione=' + this.state.tipologiaIscrizione);
      } else {
        goTo(PATH_TO_BO_ANAG_ELENCO_ISCRITTI)
      }
  }

  render() {

    const { typeMessage, message} = this.state;
    return(
      <div key={"modificaSanzioneDisciplinare"} className='container-fluid'>
          <div className="container-fluid">
            <div className='row'>
              <div className='col-10 offset-1'>
                  {
                  (this.state.isLoaded) ?
                    <div>
                         <div className="row">
                            <div className="col-10 offset-1 mt-5">
                              <GenericBreadCrumb paths={breadcrumbOptions} /> <br></br><br></br>
                              <GenericAlert hidden={message === ''} label={message} color={typeMessage}/>
                            </div>
                          </div>
                       <FormListaDisciplinari inviaDisciplinari={this.inviaDisciplinari} data={this.state.disciplinari} disciplinari={this.state.disciplinari} prevData={this.state.disciplinari} message={this.hasFormErrors} getSelectedValue={this.getSelectValues} visualizzaFlag={false} modificaDatiFlag={true} goToDisciplinari={this.goToDisciplinari}/>
                       <GenericModal
                                title={this.state.modalTitle}
                                text={this.state.modalText}
                                modalOpen={this.state.modalOpen}
                                closeButton={this.closeModal}
                        />
                    </div>
                    : <GenericSpinner></GenericSpinner>
                  }
              </div>
            </div>
          </div>
      </div>
    )
  }
};
export declare interface InserisciProps {
  idIscrizione?: any;
  modificaDatiFlag?:any;
  visualizzaFlag?:any;
  disciplinari?:any;
}

export default ModificaSanzioneDisciplinari  ;
