import {getDefaultAxiosConfig, getFormazioneResourceApi} from 'src/app/utils/ManagerRestGateway'
import {ErrorStore} from 'src/errorBoundary/ErrorStore'

/**
 * GET Evento a catalogo by id
 * @returns
 *
*/
export async function getEventoACatalogoById(id: any){
  try {
    let response: any = await getFormazioneResourceApi().msfoFormazioneEventoACatalogoGet(id, getDefaultAxiosConfig())
    return response;

  } catch (err) {
    let errorMessage: any = await ErrorStore.getInstance().returnMessageError(err, 500)
    console.error( errorMessage.message);
    throw await new Error(errorMessage.title + errorMessage.message);

  }
}
