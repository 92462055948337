import {Button, FormGroup, Icon} from 'design-react-kit'
import {acceptedFileExtension, getFileNameFromPath, isEmpty, maxStringLength} from 'src/utilities/utility'
import attach from '../images/Iconsattache.png'
import close from '../images/x.png'
import GenericProgressBar from './GenericProgressBar'
import React from 'react'
import {getFileByIdAttachmentFO} from 'src/processes/ProcessoIscrizione'
import {GenericSpinner} from 'src/backoffice/app/components'
import {isCurrentMobileDevice} from 'src/utilities/deviceUtility'

export default class GenericUploadFront extends React.Component<GeneralUploadFrontProps, any> {

  constructor(props) {
    super(props);
    this.state = {
      selectedFile: '',
      label: props.label,
      isHidden: props.isHidden,
      responseArray: [],
      fileName: "",
      clicked: false,
      onClick: props.onClick,
      name: props.name,
      isFileUploaded: false,
      isFileAccepted: true,
      fileUploadId: '',
      isErrorMessageHidden: true,
      hideDownloadFile: false,
      isDownloadLoaded: true,
      hideResetFile: false
    };
  }

  messageAlertContentError = { hasError: true, id: this.props.id, isReadonly: false, isDisabled: this.props.isDisabled, stepTab: this.props.currentStep, value: this.props.value, label: this.props.name }
  messageAlertContentSuccess = { hasError: false, id: this.props.id, isReadonly: false, isDisabled: this.props.isDisabled, stepTab: this.props.currentStep, value: this.props.value, label: this.props.name }

  componentDidMount(): void {
    const { value } = this.props

    if (this.props.value !== undefined && this.props.value !== "") {
      this.setState({ fileName: this.props.value, clicked: true })
      this.setState({ selectedFile: { name: this.props.value } })
      if (this.props.errorMessageAlert !== undefined)
        this.props.errorMessageAlert(this.messageAlertContentSuccess)
    }

    if (this.props.errorMessageAlert !== undefined && this.props.isRequired) {
      this.props.errorMessageAlert(this.messageAlertContentError)
    }

    if(!value){
      this.setState({hideDownloadFile: true})
    }
  }

  componentDidUpdate(prevProps: Readonly<GeneralUploadFrontProps>): void {
    if (this.props.itemIdDeleted !== prevProps.itemIdDeleted)
      this.props.errorMessageAlert({ hasError: false, id: this.props.itemIdDeleted, isReadonly: false, isDisabled: this.props.isDisabled, stepTab: this.props.currentStep, value: this.props.value, label: this.props.name })

    if (prevProps.tipologiaAllegato !== this.props.tipologiaAllegato) {
      if (this.props.datiAllegato)
        this.props.datiAllegato({
          filename: getFileNameFromPath(this.state.event?.target?.value, "\\"),
          contentBase64: this.state.fileBase64,
          tipologiaAllegato: this.props.tipologiaAllegato,
          contentType: this.state.event?.target.files[0].type,
          descrizioneBreve: this.props.descrizioneBreve
        })
    }

    if (this.props.value && prevProps.value !== this.props.value) {
      this.setState({ fileName: this.props.value, clicked: true })
      this.setState({ selectedFile: { name: this.props.value } })
    }
  }

  componentWillUnmount(): void {
    if (this.props.errorMessageAlert)
      this.props.errorMessageAlert({ hasError: false, id: this.props.id, isReadonly: false, isDisabled: this.props.isDisabled, stepTab: this.props.currentStep, value: this.props.value, label: this.props.name })
  }

  getBase64(file, cb) {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      cb(reader.result)
    };
    reader.onerror = function (error) {
    };
  }

  render() {
    const { label, isHidden, isFileUploaded, isFileAccepted, hideDownloadFile, isDownloadLoaded } = this.state;
    const { isRequired, errorMessageAlert, hideUploadedFile } = this.props;

    const handleInputChange = async (event) => {
      this.setState({ fileUploadId: event.target.id, event: event, isFileUploaded: true })

      let fileToBeUploaded = event.target.files[0];

      var isAcceptedFileExtension = acceptedFileExtension(event.target.value, this.props.acceptedExtensionFile)
      if (isAcceptedFileExtension) {
        // Converte il file in base64
        this.getBase64(fileToBeUploaded, (response) => {
          var fileBase64 = response.split("base64,").pop()
          this.setState({ fileBase64: fileBase64, hideDownloadFile: false })

          if (this.props.datiAllegato)
            this.props.datiAllegato({
              filename: getFileNameFromPath(event.target.value, "\\"),
              contentBase64: fileBase64,
              tipologiaAllegato: this.props.tipologiaAllegato,
              contentType: event.target.files[0].type,
              descrizioneBreve: this.props.descrizioneBreve,
            })
        });

        var cuttedFilePath = getFileNameFromPath(event.target.value, "\\")
        await this.setState({ selectedFile: event.target.files[0], responseArray: [], fileName: cuttedFilePath, contentType: event.target.files[0].type });
        setTimeout(async () => {
          await this.setState({ isFileUploaded: this.state.selectedFile === "" })
          this.setState({ isFileAccepted: true })
        }, 1000);
        this.setState({ clicked: true });
      } else {
        this.setState({ isFileUploaded: true })
        setTimeout(() => {
          this.setState({ isFileUploaded: false })
          this.setState({ isFileAccepted: false })
        }, 1000);
      }
      validationForm(event)
    }

    const validationForm = (event) => {
      this.messageAlertContentError['value'] = event.target.value
      this.messageAlertContentSuccess['value'] = event.target.value
      if (isEmpty(event.target) && this.props.isRequired) {
        if (this.props.errorMessageAlert !== undefined)
          this.props.errorMessageAlert(this.messageAlertContentError)
        this.setState({ isErrorMessageHidden: false })
      } else {
        if (this.props.errorMessageAlert !== undefined)
          this.props.errorMessageAlert(this.messageAlertContentSuccess)
        this.setState({ isErrorMessageHidden: true })
      }
    }

    const resetFile = async () => {
      await this.setState({ selectedFile: [], clicked: false, filename: "", isFileAccepted: true, isErrorMessageHidden: false, hideDownloadFile: true })
      this.messageAlertContentError['value'] = ""
      this.messageAlertContentSuccess['value'] = ""
      this.props.errorMessageAlert(this.messageAlertContentError)
    }

    if (!isRequired) {
      if (errorMessageAlert !== undefined)
        errorMessageAlert(this.messageAlertContentSuccess)
    } else {
      if (errorMessageAlert !== undefined) {
        if (this.state.selectedFile.name === "" || this.state.selectedFile.name === undefined)
          errorMessageAlert(this.messageAlertContentError)
        else {
          errorMessageAlert(this.messageAlertContentSuccess)
        }
      }
    }

    /**
     * Scarica il file appena caricato.
     */
    const downloadPreSubmit = async () => {
      const { idAttachment, } = this.props
      const { fileBase64 } = this.state

      this.setState({isDownloadLoaded: false})

      if (fileBase64) {
        var a = document.createElement("a"); //Create <a>
        a.href = "data:" + this.state.contentType + ";base64," + this.state.fileBase64; //Image Base64 Goes here
        a.download = this.state.fileName ?? this.props.value; //File name Here
        a.click(); //Downloaded file
        this.setState({isDownloadLoaded: true})
      } else {
        let dataUri: any = '';
        await getFileByIdAttachmentFO(idAttachment).then(response => {
          if (response === undefined) {
            alert("File non presente");
          }
          dataUri = "data:" + response.data.contentType + ";base64," + response.data.content;
          const link = document.createElement("a");
          link.download = this.state.fileName ?? this.props.value;
          link.href = dataUri;
          link.click();
          this.setState({isDownloadLoaded: true})
        })
      }
    }

    return (
      <div>
        {this.props.isRequired ?
          <small hidden={this.state.isErrorMessageHidden} className={"text-danger"}>Compilare il campo obbligatorio</small> : null
        }
        <FormGroup onChange={handleInputChange}>
          <div hidden={isHidden} key={label}>
            <div className="row" style={{ marginTop: '-50px', marginBottom: '40px' }}>
              <div className="form-row">
                <div className="form-group col-12">
                  <label>{label}</label>
                  <div className="input-upload">
                    <input
                      type="file"
                      multiple
                      name={this.props.name}
                      disabled={this.props.isDisabled}
                      onChange={this.props.onChange}
                      id={this.props.id}
                      onClick={(event) => { event.currentTarget.value = "" }}
                      />
                    <div className={this.state.clicked && !hideUploadedFile? "custom-file d-flex clicked" : "custom-file d-flex"}>
                      <img src={attach} alt="attach" className="h-100" />
                      <div className={!isFileUploaded ? "bordered" : ""} style={{ maxWidth: '300px' }}>
                        <p hidden={isFileUploaded || isCurrentMobileDevice()}>{this.state.clicked && !hideUploadedFile ? maxStringLength(this.state.fileName, 20) : "Clicca qui per eseguire l'upload"}</p>
                        <Button hidden={this.props.hideResetFile} disabled={this.props.isDisabled} onClick={resetFile} className={this.state.clicked && !isFileUploaded && !hideUploadedFile? 'd-block' : 'd-none'} style={{ zIndex: 0 }}><img hidden={this.props.hideResetFile} src={close} alt="" /></Button>
                      </div>
                      <div className="mt-3 ms-2" hidden={hideDownloadFile || this.props.hideDownloadFile}>
                        <a hidden={!isDownloadLoaded} onClick={() => downloadPreSubmit()}><Icon icon="it-download" size="sm" /></a>
                        <GenericSpinner hidden={isDownloadLoaded} size={"lg"}/>
                      </div>
                    </div>
                  <GenericProgressBar isHidden={!isFileUploaded} style={{ width: "80%", marginLeft: "7%" }} />
                  <div hidden={!isFileAccepted} className={"text-secondary"} style={{ marginLeft: "5%", fontSize: "15px" }}>Formati accettati: {this.props.acceptedExtensionFile?.toString().replace(",", ", ")}</div>
                  <div hidden={isFileAccepted} className={"text-danger"} style={{ marginLeft: "5%",  marginTop: "8%", fontSize: "15px" }}>Il formato inserito non è accettato, formati accettati: {this.props.acceptedExtensionFile?.toString().replace(",", ", ")}</div>
                  </div>
                </div>
              </div>
              <br />
            </div>
          </div>
        </FormGroup>
      </div>
    );
  }
}


export declare interface GeneralUploadFrontProps {
  label?: any;
  isHidden?: any;
  onClick?: any;
  name?: any;
  id?: any;
  isRequired?: boolean;
  isDisabled?: boolean;
  onChange?: any;
  errorMessageAlert?: any;
  currentStep?: number;
  /**
   * Contiene l'id dell'elemento da rendere non obbligatorio in fase di validazione una volta eliminato
   * Inserire l'id dell'elemento seguito dall'id dell'elemento selezionato
   * Es. se l'id elemento è uguale a {pippo + id} --> scrivere itemIdDeleted={"pippo" + idElementoEliminato}
   */
  itemIdDeleted?: any;
  /**
   * @description Inserire array di stringhe contenente le estensioni senza i punti
   */
  acceptedExtensionFile?: any[];
  tipologiaAllegato?: string;
  descrizioneBreve?: string;
  /**
   * @description Restituisce i dati del file
   * @returns file(base 64), fileName, tipologiaAllegato
   */
  datiAllegato?: any;
  value?: string;
  className?: any;
  idAttachment?: any;
  hideUploadedFile?: any;
  hideResetFile?: any;
  hideDownloadFile ?: any;
}
