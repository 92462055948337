import {CookieBar, CookieBarButton, CookieBarButtons} from 'design-react-kit'
import React from 'react'
import {PATH_TO_FO_COOKIE} from './RoutesConstants'

class SidafCookieBar extends React.Component<any, any> {

  constructor(props) {
    super(props)
    this.state = {
      hideCookieBar: false
    }
  }

  componentDidMount(): void {
    if (document.cookie) {
      this.setState({ hideCookieBar: true })
    } else {
      this.setState({ hideCookieBar: false })
    }
  }

  setCookie() {
    var cookiesTecnici = ["_utma", "_utmb", "_utmc", "_utmz"]

    cookiesTecnici.map(cookie => {
      return document.cookie = `${cookie}=${cookie}`
    })
    this.setState({ hideCookieBar: true })
  }

  render() {
    const { hideCookieBar } = this.state
    return (
      <>
        <CookieBar className="cookie-bar" hidden={hideCookieBar}>
          <p>
            Questo sito utilizza cookie tecnici, analytics e di terze parti.{' '}
            <br />
            Proseguendo nella navigazione accetti l’utilizzo dei cookie.
          </p>
          <CookieBarButtons>
            <CookieBarButton onClick={ () => {window.location.href = PATH_TO_FO_COOKIE}}>
              Leggi l'informativa
            </CookieBarButton>
            <CookieBarButton onClick={() => this.setCookie()}>
              Accetto i cookies
            </CookieBarButton>
          </CookieBarButtons>
        </CookieBar>
      </>
    );
  }
}

export default SidafCookieBar;
