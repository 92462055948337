import React, {useCallback, useRef, useState} from 'react'
import GenericBreadCrumb from 'src/frontend/app/components/GenericBreadCrumb'
import GenericTab from 'src/frontend/app/components/GenericTab'
import {PATH_TO_FO_RICHIESTA_ESONERO} from 'src/app/utils/RoutesConstants'
// Form components
import formValidationRules from './formRichiestaEsonero/FormRules'

import {createFormData, getTabActiveFormId, handleChangeFormData, validateFormData} from 'src/app/utils/form-utils'

import {getDefaultAxiosConfig, getFormazioneResourceApi} from 'src/app/utils/ManagerRestGateway'
import FormRichiestaEsonero from './formRichiestaEsonero/FormRichiestaEsonero'
import FormDatiConferma from '../iscrizioneAlboEF/formCreazioneEvento/FormDatiConferma'
import {useHistory} from 'react-router-dom'
import {listLegendaCampiObbligatori} from 'src/processes/Costanti'

const title = 'Richiesta Esonero'
const listNavLink = [
  { id: 1, label: 'Dati Richiesta Esonero' },
  { id: 2, label: 'Conferma' },
]

const RichiestaEsonero: React.FunctionComponent = () => {
  const [breadcrumbOptions, setBreadcrumbOptions] = useState([
    { label: 'Home', link: '/' },
    { label: 'Richiesta esonero', link: PATH_TO_FO_RICHIESTA_ESONERO },
  ])

  const history = useHistory()

  // Tab component state
  const [activeTab, setActiveTab] = useState(1)
  const [message, setMessage] = useState('')
  const [alertTypeMessage, setAlertTypeMessage] = useState('')

  // Form State
  const refForm = useRef<any>([])
  const [data, setData] = useState<any>({})
  const [errors, setErrors] = useState<any>({})
  const formData = createFormData(data, setData, errors, setErrors)

  // Submit state
  const [saving, setSaving] = useState(false)
  const [saveResult, setSaveResult] = useState<any>(null)

  const setErrorMessage = (message) => {
    setAlertTypeMessage('danger')
    setMessage(message)
    setTimeout(() => {
      setMessage('')
    }, 2000)
  }

  const onInitInput = (cmp, tab) => {
    refForm.current.push({
      tab: tab,
      cmp: cmp,
    })
  }
  const handleInput = (code, value) => {
    handleChangeFormData(formData, code, value, formValidationRules)
  }

  const onNextStep = useCallback(
    (e) => {
      e.preventDefault()
      const inputs = getTabActiveFormId(refForm, activeTab - 1)
      const isValid = validateFormData(formData, formValidationRules, inputs)
      if (!isValid) {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
        return
      }

      // TODO call api with current step data
      // setBreadcrumbOptions(options => {
      //     const newOptions = [...options];
      //     newOptions.push({ label: listNavLink[activeTab].label, link: "/frontend/IscrizionePOF" })
      //     return newOptions;
      // });
      setMessage('')
      setActiveTab((tab) => tab + 1)
    },
    [activeTab, formData]
  )

  const previousStep = () => {
    if (saving) {
      return
    }
    // setBreadcrumbOptions((options) => {
    //   const newOptions = [...options]
    //   newOptions.pop()
    //   return newOptions
    // })
    setSaveResult(null)
    setActiveTab((tab) => tab - 1)
  }

  const onSubmit = useCallback(
    async (e) => {
      e.preventDefault()
      try {
        setSaving(true)

        await getFormazioneResourceApi().msfoFormazioneInserisciEsoneroPut(formData.data, getDefaultAxiosConfig())

        setSaving(false)
        setSaveResult({ success: true })
        setTimeout(() => {
          history.goBack()
        }, 500)
      } catch (e) {
        setSaving(false)
        setSaveResult({ success: false, error: 'Impossibile salvare i dati' })
      }
    },
    [formData]
  )

  return (
    <div className="container register-container">
        <GenericBreadCrumb paths={breadcrumbOptions}></GenericBreadCrumb>
        <div>
          <h1>{title}</h1>
        </div>
        <GenericTab
          hiddenAlert={message === ''}
          alertTypeMessage={alertTypeMessage}
          labelAlert={message}
          isSubmitted={false}
          onSubmit={onNextStep}
          previousStep={previousStep}
          activeTab={activeTab}
          listNavLink={listNavLink}
          listBodyTab={[
            {
              id: 1,
              body: (
                <FormRichiestaEsonero
                  title={'Dati Richiesta esonero'}
                  data={data}
                  errors={errors}
                  onInitInput={(id) => {
                    onInitInput(id, 0)
                  }}
                  handleInput={handleInput}
                  currentStep={1}
                />
              ),
            },
            {
              id: 2,
              body: <FormDatiConferma title={'Invio Richiesta'} loading={saving} resultData={saveResult} onConfirm={onSubmit} />,
            },
          ]}
        />
        <div>{listLegendaCampiObbligatori}</div>
        <br></br>
    </div>
  )
}

export default RichiestaEsonero
