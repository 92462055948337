import React from 'react'
import {PATH_TO_BO_ANAG_ELENCO_ISCRITTI, PATH_TO_BO_ATTI_DISCIPLINARI} from 'src/app/utils/RoutesConstants'
import GenericTitle from 'src/frontend/app/components/GenericTitle'

export const listaOrganiDisciplinari = [
  { label: "Cassazione", value: "cassazione"},
  { label: "Consiglio dell'Ordine di ...", value: "consiglio_provinciale"},
  { label: "Consiglio Disciplina Territoriale", value: "consiglio_disciplina_territoriale"},
  { label: "Consiglio Disciplina Nazionale", value: "consiglio_disciplina_nazionale"},
  { label: "Tribunale di ...", value: "tribunale"},
  { label: "Corte di Appello di ...", value: "corte_di_appello"},
  // { label: "Altro", value: "altro"}
]

export const listaTipoProvvedimentiDisciplinari = [
  { label: "Avvertimento", value: "avvertimento"},
  { label: "Censura", value: "censura"},
  // { label: "Consiglio Disciplina Nazionale", value: "consiglio_disciplina_nazionale"},
  { label: "Sospensione", value: "sospensione"},
  { label: "Sospensione per morosità", value: "sospensione-morosita"},
  { label: "Radiazione", value: "radiazione"},
  { label: "Altro", value: "-"},
  // { label: "Altro", value: "altro"}
]


export const legenda =([
    {title: 'Legenda', Size:'h2'},
    {title: '* Campo obbligatorio', Size:'p'},
    {title: '** Campo non modificabile compilato automaticamente', Size:'p'},
])

export const listLegenda =
[
  <div key={'legenda'} className="Card">
    {legenda.map((title, i) => {
    return (<GenericTitle key={i} title={legenda[i].title} Size={legenda[i].Size}/>)
      })}
  </div>
]

 export const columnsDisciplinari = [
    {id:1, name: "Tipo provvedimento"},
    {id:2, name: "Data"},
    {id:3, name: "Delibera nr"},
    {id:4, name: "Data inizio"},
    {id:5, name: "Data fine"}
]

export const breadcrumbOptionsDisciplinari = [
  { label: "Anagrafica", link: "#" },
  { label: "Elenco iscritti", link: PATH_TO_BO_ANAG_ELENCO_ISCRITTI},
  { label: "Atti disciplinari", link: PATH_TO_BO_ATTI_DISCIPLINARI }
]

export enum MessaggiErroreDisciplinari {
    campi_obbligatori = "Compila tutti i campi obbligatori",
    errore_recupero_dati = "C'è stato un errore durante il recupero dei dati",

}

