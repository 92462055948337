import {isEmptyValue} from 'src/utilities/utility'
import {CodiciErrore, FORMATO_NON_GESTITO, MessaggiErroreUtente, NETWORK_ERROR} from './ErrorConstants'

/**
 * Singleton class
 */
export class ErrorStore {

    /* Rappresenta l'istanza del ErrorStore. */
    private static instance: ErrorStore;

    /* Rappresenta l'indirizzo precendente alla pagina di errore. */
    private backUrl: any = "";

    /* Rappresenta il messaggio dell'errore */
    private messageError: any = "";

    /* Rappresenta il nome della chiamata che ha generato l'errore */
    private restCall: any = "";

    /* Rappresenta il titolo dell'errore */
    private titleError: any = "";

    /* Lista degli errori evidenziati all'interno di una sessione */
    private errorList: any = [];

    /**
        * The Singleton's constructor should always be private to prevent direct
        * construction calls with the `new` operator.
    */
    private constructor() { }

    public static getInstance(): ErrorStore {

         if (!ErrorStore.instance) {
            ErrorStore.instance = new ErrorStore();
         }
         return ErrorStore.instance;
    }

    /**
    * Imposta il token, l'istanza di keycloak, l'istanza di adminClient e il currentAccount del profilo personale.
    * @param token
    * @param keycloak
    * @param adminClient
    */
    public static initialize(title, message, back_url, call) {

        // Al primo accesso non è stato ancora impostato l'errore, quindi lo imposto.
        this.getInstance().setMessageError(message);
        this.getInstance().setTitleError(title);
        this.getInstance().setBackUrlError(back_url);
        this.getInstance().setRestCall(call);
        this.getInstance().removeSessionElements();
    }

    /**
        * Funzione che controlla la response del servizio
        * -> Se è presente un errore genera l'errore
        * -> altrimenti ritorna il response.data.returnedObject
        * @param response
        *
    **/
    public checkResponse(response) {
        if(response.data && response.data.returnedObject !== null &&
            response.data.httpStatus && response.data.httpStatus === 200) {
            return response.data.returnedObject;
        }
        else if(response.data && response.data.error) {
            let errorMessage = ErrorStore.getInstance().returnMessageError(response.data.error, response.data.httpStatus)
            throw new Error(errorMessage.title + errorMessage.message);
        }
        else {
            let errorMessage = ErrorStore.getInstance().returnMessageError("Formato della risposta non gestito", undefined)
            throw new Error(errorMessage.title + errorMessage.message);
        }
    }

    /**
        * Funzione analoga a checkResponse ma ritorna response e non response.data.returnedObject
        * @param response
        *
    **/
    public checkResponseReturnResponse(response) {

        if(response.data &&
            response.data.httpStatus && response.data.httpStatus === 200) {
            return response;
        }
        else if(response.data && response.data.error) {
            let errorMessage = ErrorStore.getInstance().returnMessageError(response.data.error, response.data.httpStatus)
            throw new Error(errorMessage.title + errorMessage.message);
        }
        else {
            let errorMessage = ErrorStore.getInstance().returnMessageError("Formato della risposta non gestito", undefined)
            throw new Error(errorMessage.title + errorMessage.message);
        }
    }

    /**
        * Funzione che genera l'errore automaticamente in base al response status code della chiamata
        * -> Se il response code è tra quelli censiti effettua il redirect alla pagina di errore
        * -> altrimenti restituisce il messaggio di errore
        * @param redirectLink - indirizzo di redirect
        * @param error - errore proveniente dalla clausola catch della chiamata
        *
    **/
    public generateError(redirectLink, error, http_code, call) {
        // let http_code = error?.response?.status;

        if((!http_code || http_code === 401 || http_code === 403 || http_code === 404 || http_code === 410 || http_code === 502 || http_code === 503)) {
            ErrorStore.getInstance().goToErrorPage(redirectLink, error, call )
            return null
        }
        else return ErrorStore.getInstance().returnMessageError(error, http_code)
    }

    /**
        * Funzione che permette di effettuare il redirect alla pagina di errore passando i seguenti parametri:
        * I dati sono salvati nel sessionStorage, appena letti vengono rimossi
        * @param redirectLink - indirizzo di redirect
        * @param error        - errore proveniente dalla clausola catch della chiamata
    **/
    public goToErrorPage(redirectLink, error, call) {
        let http_code = error?.response?.status;
        sessionStorage.setItem("title_error", ErrorStore.getInstance().decodificaStatusCode(http_code));     //titolo del messaggio di errore
        sessionStorage.setItem("message_error", ErrorStore.getInstance().decodificaMessaggioErrore(error));  //corpo del messaggio di errore
        sessionStorage.setItem("back_url", window.location.href);  //corpo del messaggio di errore
        sessionStorage.setItem("rest_call", call);  //corpo del messaggio di errore

        window.location.href = redirectLink;
    }

    /**
        * Funzione che ritorna il messaggio di errore della chiamata
        * @param error - errore proveniente dalla clausola catch della chiamata
        *
        * @returns un oggetto contenente title e message.
    **/
    public returnMessageError(error, http_code) {
        // let http_code = error?.response?.status;
        var errorMessage = {
            title: ErrorStore.getInstance().decodificaStatusCode(http_code),
            message: ErrorStore.getInstance().decodificaMessaggioErrore(error)
        }

        return errorMessage;
    }

    /**
        * Funzione che ritorna un alert base
        * @param message
    **/
        public showAlert(message) {

            alert(message)
        }

    /**
        * Imposta il message error
        * @param message
    */
    public setMessageError(message) {
        ErrorStore.getInstance().messageError = message;
    }

    /**
        * Imposta il title error
        * @param title
    */
    public setTitleError(title) {
        ErrorStore.getInstance().titleError = title;
    }

    /**
        * Imposta l'url precendente
        * @param backUrl
    */
    public setBackUrlError(backUrl) {
        ErrorStore.getInstance().backUrl = backUrl;
    }

    /**
        * Imposta il nome della chiamata che ha generato l'errore
        * @param restCall
    */
     public setRestCall(restCall) {
        ErrorStore.getInstance().restCall = restCall;
    }

    /**
        * @returns messageError
    */
    public getMessageError() {
        return ErrorStore.getInstance().messageError;
    }

    /**
        * @returns il messaggio di errore salvato temporaneamente nel session storage
    */
    public getSessionMessageError() {
        return window.sessionStorage.getItem("message_error");
    }

    public getErrorTitle() {
        return ErrorStore.getInstance().titleError;
    }

    /**
        * @returns il titolo di errore salvato temporaneamente nel session storage
    */
    public getSessionTitleError() {
        return window.sessionStorage.getItem("title_error");
    }

    /**
     * @returns indirizzo precendente al redirect alla pagina di errore
     */
    public getBackUrl() {
        return ErrorStore.getInstance().backUrl;
    }

    /**
        * @returns url precedente salvato temporaneamente nel session storage
    */
    public getSessionBackUrlError() {
        return window.sessionStorage.getItem("back_url");
    }

    /**
     * @returns il nome della chiamata che ha generato l'errore (utile per il debug)
    */
     public getRestCall() {
        return ErrorStore.getInstance().restCall;
    }

    /**
        * @returns url precedente salvato temporaneamente nel session storage
    */
    public getSessionRestCall() {
        return window.sessionStorage.getItem("rest_call");
    }

    /**
     * Rimuove gli elementi di errore in sessione
    */
    public removeSessionElements() {
        sessionStorage.removeItem("message_error")
        sessionStorage.removeItem("title_error")
        sessionStorage.removeItem("back_url")
        sessionStorage.removeItem("rest_call")
    }

    /**
        * Aggiunta di un errore alla lista errori
        * @param error
    */
    public addError(error) {
        ErrorStore.getInstance().errorList.push(error);
    }


    /**
     * @returns lista degli errori evidenziati in una sessione
     */
    public getErrorList() {
        return ErrorStore.getInstance().errorList;
    }

    /**
        * Decodifica del codice di errore della chiamata.
        * Sono censiti i codici di errore più comuni (vedi Enum CodiciErrore in ErrorConstants).
        * @param status - status code
        * @returns Stringa decodificata dello status code
    **/
    public decodificaStatusCode (status){
        if(status === 100){
            return CodiciErrore._100;
        }
        if(status === 200){
            return CodiciErrore._200;
        }
        if(status === 204){
            return CodiciErrore._204;
        }
        if(status === 300){
            return CodiciErrore._300;
        }

        if(status === 400){
            return CodiciErrore._400;
        }

        if(status === 401){
            return CodiciErrore._401;
        }

        if(status === 403){
            return CodiciErrore._404;
        }

        if(status === 404){
            return CodiciErrore._404;
        }

        if(status === 500){
            return CodiciErrore._500;
        }
        else{
            return "Errore. ";//CodiciErrore._;
        }
    }

    /**
        * Decodifica del messaggio di errore.
        * Sono censiti i messaggi di errore più frequenti (vedi Enum MessaggiErroreUtente).
        * @param errore  - messaggio di errore
        * @returns Stringa decodificata del messaggio di errore
    */
    public decodificaMessaggioErrore(errore){

        if(errore?.message && errore?.message.includes(NETWORK_ERROR)){
            return MessaggiErroreUtente.errore_di_rete_message
        }
        else if(!isEmptyValue(errore) && errore === FORMATO_NON_GESTITO){
            return MessaggiErroreUtente.errore_di_rete_message
        }
        else if(errore?.response && errore?.response?.data !== undefined && !isEmptyValue(errore?.response?.data?.title) && errore?.response?.data?.title.includes("Constraint") ){
            return MessaggiErroreUtente.constraint_violation_message
        }
        else if(errore?.response && errore?.response?.data !== undefined && !isEmptyValue(errore?.response?.data?.message)){
            return errore?.response.data.message
        }
        else if (typeof errore === 'string') {
            errore = errore?.replace("Error:", " ").replace("Exception: ", " ").replace("Client", "").replace("ClientException:", " ").replace("ServiceException:", " ").replace("  ", " ")
            return errore;
        }
        else if(errore?.message) {
            return errore?.message
        }
        else return MessaggiErroreUtente.default_error_message

    }


}
