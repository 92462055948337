import React, {useMemo} from 'react'
import {Form} from 'design-react-kit'
import {useTipologieAttivitaEvCatalogo} from 'src/hooks/useReqOptions'
import createWrpInput from 'src/frontend/app/components/Form/WrpInput'
import GenericCard from 'src/frontend/app/components/GenericCard'
import GenericInput from 'src/frontend/app/components/Form/Input'
import GenericSelect from 'src/frontend/app/components/Form/Select'

import {MODALITA_DISPONIBILI_OPTIONS, MODALITA_DISPONIBILI_VALUES} from 'src/formazione'
import {TokenStore} from 'src/keycloak/jwt/TokenStore'

const FormDatiEvento: React.FunctionComponent<FormDatiEventoProps> = ({ title, data, handleInput, onInitInput, errors, viewOnly, disableOverride, clona }) => {
  const objKey = ''
  const WrpInput = useMemo(() => createWrpInput({ objKey, onInit: onInitInput, onChange: handleInput, viewOnly }), [])
  const tipologieAttivita = useTipologieAttivitaEvCatalogo()
  const mod = data?.modalita?.value
  const att = data?.tipologiaAttivita?.value
  const showDate = [MODALITA_DISPONIBILI_VALUES.IN_SITU, MODALITA_DISPONIBILI_VALUES.FAD_SINCRONO, MODALITA_DISPONIBILI_VALUES.MISTA].includes(mod)
  const showDurataFad = [MODALITA_DISPONIBILI_VALUES.FAD_ASINCRONO, MODALITA_DISPONIBILI_VALUES.MISTA].includes(mod)
  const showTotOre = !['AO', 'AF', 'CU', 'CUCO'].includes(att)
  const showCrediti = 'CU' === att || 'CUCO' === att
  const showPiattaformaFAD = [MODALITA_DISPONIBILI_VALUES.MISTA, MODALITA_DISPONIBILI_VALUES.FAD_ASINCRONO, MODALITA_DISPONIBILI_VALUES.FAD_SINCRONO].includes(mod)
  const showLuogo = [MODALITA_DISPONIBILI_VALUES.IN_SITU, MODALITA_DISPONIBILI_VALUES.MISTA].includes(mod)
  const showMaxPSitu = [MODALITA_DISPONIBILI_VALUES.IN_SITU, MODALITA_DISPONIBILI_VALUES.MISTA].includes(mod)
  const showMaxPRemoto = [MODALITA_DISPONIBILI_VALUES.FAD_ASINCRONO, MODALITA_DISPONIBILI_VALUES.FAD_SINCRONO, MODALITA_DISPONIBILI_VALUES.MISTA].includes(mod)

  let emptyTotOreCfp = TokenStore.getInstance().getCurrentAccount()
  let emptyPartecipazioneA = TokenStore.getInstance().getCurrentAccount()
  let emptyPartecipazioneS = TokenStore.getInstance().getCurrentAccount()

  if (emptyTotOreCfp !== "" && (TokenStore.getInstance().isCurrentAccountODAF() && data?.totOreCFU == null && data?.evento))
    { emptyTotOreCfp = true }
  else
    { emptyTotOreCfp = false }

  if (emptyPartecipazioneA !== "" && (TokenStore.getInstance().isCurrentAccountODAF() && data?.partecipazioneConAbbonamento == null && data?.evento))
    { emptyPartecipazioneA = true }
  else
    { emptyPartecipazioneA = false }

  if (emptyPartecipazioneS !== "" && (TokenStore.getInstance().isCurrentAccountODAF() && data?.partecipazioneSenzaAbbonamento == null && data?.evento))
    { emptyPartecipazioneS = true }
  else
    { emptyPartecipazioneS = false }

  const isHttpLink = data?.piattaformaFAD?.startsWith("http")

  return (
    <GenericCard cardHeader={title}>
      <Form>
        <div className="row">
          <div className="col-6">
            <WrpInput
              as={GenericInput}
              id="nomeCorso"
              type="text"
              label="Nome del corso"
              suffix="*"
              data={data}
              errors={errors}
              disableOverride={disableOverride}
            />
          </div>
          <div className="col-6">
            <WrpInput
              as={GenericSelect}
              id={'modalita'}
              placeholder="Selezione Modalità"
              label="Modalità"
              suffix="*"
              defaultOptions={MODALITA_DISPONIBILI_OPTIONS}
              data={data}
              errors={errors}
            />
          </div>
          {showDate && (
            <div className="col-6">
              <WrpInput as={GenericInput} id="dataInizio" type="date" label="Data Inizio" suffix="*" data={data} errors={errors} />
            </div>
          )}
          {showDate && (
            <div className="col-6">
              <WrpInput as={GenericInput} id="dataFine" type="date" label="Data Fine" suffix="*" data={data} errors={errors} />
            </div>
          )}
          {showDurataFad &&  (
            <div className="col-6">
              <WrpInput
                as={GenericInput}
                id="totOre"
                type="text"
                label="Durata evento FAD"
                suffix="* ( es. 43:53 )"
                data={data}
                errors={errors}
                disableOverride={disableOverride}
                placeholder = "Ore : Minuti"
              />
            </div>
          )}
          <div className="col-6">
            <WrpInput
              as={GenericSelect}
              id={'tipologiaAttivita'}
              placeholder="Selezione Tipologia Attività"
              label="Tipologia Attività"
              suffix="*"
              defaultOptions={tipologieAttivita}
              data={data}
              errors={errors}
              disableOverride={disableOverride}
            />
          </div>

          {(!viewOnly || (data && data.evento && data.evento.accreditato !== 1)) && (!emptyTotOreCfp || clona ) && showTotOre && (
            <div className="col-6">
              <WrpInput
                as={GenericInput}
                id="totOreCFU"
                type="text"
                label="Totale ore valide per i CFP"
                suffix="* ( es. 43:53 )"
                data={data}
                errors={errors}
                disableOverride={disableOverride}
                placeholder = "Ore : Minuti"
              />
            </div>
          )}
          {(!viewOnly || (data && data.evento && data.evento.accreditato !== 1)) && showCrediti && (
            <div className="col-6">
              <WrpInput as={GenericInput} id="crediti" type="number" label="CFU" suffix="*" data={data} errors={errors} disableOverride={disableOverride} />
            </div>
          )}
          {data?.crediti_riconosciuti >= 0 && viewOnly && (
            <div className="col-6">
              <WrpInput as={GenericInput} id="crediti_riconosciuti" type="number" label="CFP riconosciuti" suffix="*" data={data} errors={errors} />
            </div>
          )}
          {showPiattaformaFAD && (
            <div className="col-6">
              {isHttpLink && viewOnly ? (
                <a href={data.piattaformaFAD} target="_blank" rel="noopener noreferrer" style={{ display: 'inline-block', width: '100%' }}>
                  <WrpInput as={GenericInput} id="piattaformaFAD" type="text" label="Piattaforma FAD" suffix="*" data={data} errors={errors} disabled />
                </a>
              ) : (
                <WrpInput as={GenericInput} id="piattaformaFAD" type="text" label="Piattaforma FAD" suffix="*" data={data} errors={errors} />
              )}
            </div>
          )}
          {showLuogo && (
            <div className="col-6">
              <WrpInput as={GenericInput} id="luogo" type="text" label="Luogo" suffix="*" data={data} errors={errors} />
            </div>
          )}
          {showMaxPSitu && (
            <div className="col-6">
              <WrpInput as={GenericInput} id="maxPSitu" type="number" label="Max partecipanti" suffix="*" data={data} errors={errors} />
            </div>
          )}
          {showMaxPRemoto && (
            <div className="col-6">
              <WrpInput as={GenericInput} id="maxPRemoto" type="number" label="Max partecipanti Remoto" suffix="*" data={data} errors={errors} />
            </div>
          )}
        </div>

        <div className="row">
          {(!emptyPartecipazioneA || clona) && (
            <div className="col-6">
              <WrpInput
                as={GenericInput}
                id="partecipazioneConAbbonamento"
                type="checkbox"
                label="Partecipazione con abbonamento"
                suffix="*"
                data={data}
                errors={errors}
                disableOverride={disableOverride}
              />
            </div>
          )}
          {(!emptyPartecipazioneA || clona) && (
            <div className="col-6">
              <WrpInput
                as={GenericInput}
                id="quotaAbbonamento"
                type="number"
                prefix={'€'}
                label="Quota abbonamento"
                suffix="*"
                data={data}
                errors={errors}
                disableOverride={disableOverride}
              />
            </div>
          )}
        </div>
        <div className="row">
          {(!emptyPartecipazioneS || clona) && (
            <div className="col-6">
              <WrpInput
                as={GenericInput}
                id="partecipazioneSenzaAbbonamento"
                type="checkbox"
                label="Partecipazione senza abbonamento"
                suffix="*"
                data={data}
                errors={errors}
                disableOverride={disableOverride}
              />
            </div>
          )}
          {(!emptyPartecipazioneS || clona) && (
            <div className="col-6">
              <WrpInput
                as={GenericInput}
                id="quotaNonAbbonati"
                type="number"
                prefix={'€'}
                label="Quota non abbonati"
                suffix="*"
                data={data}
                errors={errors}
                disableOverride={disableOverride}
              />
            </div>
          )}
        </div>

        <div className="row">
          <div className="col-6">
            <WrpInput as={GenericInput} id="dataChiusuraIscrizione" type="date" label="Data chiusura iscrizioni" suffix="*" data={data} errors={errors} />
          </div>
        </div>
      </Form>
    </GenericCard>
  )
}

interface FormDatiEventoProps {
  data: any
  errors: any
  title: string
  handleInput: any
  onInitInput?: any
  viewOnly?: boolean
  disableOverride?: boolean
  clona?: boolean
}

export default FormDatiEvento
