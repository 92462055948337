import {
    convertFlagSiNo,
    convertSiNoIscrizioneEpapToInt,
    convertStatoToCodiceStato,
    convertUrlParamsToObject,
    getDescrizioneStatoIscrizione,
    isRichiestaCambioSezioneInCorso,
    isRichiestaTrasferimentoOrdineInCorso,
    statoCambioSezioneBySiNo,
    statoRichiestaTrasferimentoOrdineBySiNo,
    tipoAnagrafeEsteso
} from 'src/utilities/utility'
import {ClauseBuilder, GraphqlBuilder, GraphqlGroupBuilder} from 'src/utilities/graphqlUtilities'
import {getAnagrafeResourcesApi, getDefaultAxiosConfig} from 'src/app/utils/ManagerRestGateway'
import {getOrdineCompetenteOperatorePOST} from 'src/processes/Ordini'
import {TokenStore} from 'src/keycloak/jwt/TokenStore'
import {getCodificaStati} from 'src/processes/CodificaStati'
import {saveAs} from 'file-saver'
import moment from 'moment'
import {getElencoIscrittiCSV} from 'src/processes/Soggetto'

export async function elencoIscrittiListCsv(codiceProvinciaResidenza, globalSearchParam, listaStati, filterParams, sortParam) {

  //Se 1 elemento ["LE"]
  //Se 2 elementi ["RM", "LE"]
  var ordiniCompetentiList = await getOrdiniCompetentiList()

  const tipoAnagrafe = filterParams[0].filterParamValue
  if (filterParams[1].filterParamValue !== "") {
    ordiniCompetentiList = [filterParams[1].filterParamValue]; // Se è stato scelto un ordine di appartenenza dal filtro, sostituisco il contenuto dell'array con il valore scelto
  }
  const numeroIscrizioneOrdine = filterParams[2].filterParamValue
  const codiceFiscale = filterParams[3].filterParamValue
  const nomeCognomeRagioneSociale = filterParams[4].filterParamValue
  const statoIscrizione = filterParams[5].filterParamValue

  var codiceStato = undefined;
  if (statoIscrizione !== "") codiceStato = convertStatoToCodiceStato(statoIscrizione, listaStati)

  const filterCsv = {
    "tipoAnagrafe": tipoAnagrafe ? tipoAnagrafeEsteso(tipoAnagrafe) : undefined,
    "ordineTerritorialeCompetente": ordiniCompetentiList.length === 0 ? undefined : ordiniCompetentiList.join(","),
    "numeroIscrizioneOrdine": numeroIscrizioneOrdine ? numeroIscrizioneOrdine : undefined,
    "codiceFiscale": codiceFiscale ? codiceFiscale : undefined,
    "nomeCognomeRagioneSociale": nomeCognomeRagioneSociale ? nomeCognomeRagioneSociale : undefined,
    "statoIscrizione": codiceStato ? codiceStato : undefined
  };

  //inserire la chiamata per l'estrazione csv
  let csvResponse = await getElencoIscrittiCSV(filterCsv);
  const blob = window.URL.createObjectURL(
    new Blob([csvResponse.data], { type: "text/csv" })
  );

  const fileNameCSV = "estrazione_iscritti_" + moment(Date.now()).format("YYYYMMDDHHmmss") + ".csv";
  saveAs(blob, fileNameCSV);
  csvResponse.downloaded = true;
  return csvResponse




}

export async function elencoIscrittiList(codiceProvinciaResidenza, globalSearchParam, itemPageCount, listaStati, currentPage, filterParams, sortParam) {

  /**
   * recupero i parametri dall'url
   */
  const urlParams = window.location.search?.split("?")[1]
  var filterParamsFromTodo = convertUrlParamsToObject(urlParams)

  /**
   * Gestione del parametro per la query todo per i cancellati reiscritti.
   */
  let cancellatiReiscritti = filterParamsFromTodo?.cancellatiReiscritti;

  // Recupera la mappa degli stati con la relativa descrizione da utilizzare nei filtri.
  if (listaStati === undefined || listaStati === null || listaStati.length === 0) {
    listaStati = []
    await getCodificaStati()
      .then(async (response) => {
        listaStati = response
      })
      .catch((err) => {
        throw new Error('Errore durante il recupero della lista degli stati iscrizione: ' + err)
      })
  }

  // Rappresenta la lista degli ordini da filtrare (se CONAF non viene gestita nella WHERE)
  var ordiniCompetentiList = await getOrdiniCompetentiList()
  let index = currentPage
  let limit = itemPageCount

  // Valorizzo i parametri a partire dai filtri di ricerca
  if (filterParams === undefined || filterParams.length === 0)
    filterParams = [
      { filterParamValue: '' },
      { filterParamValue: '' },
      { filterParamValue: '' },
      { filterParamValue: '' },
      { filterParamValue: '' },
      { filterParamValue: '' },
      { filterParamValue: '' },
      { filterParamValue: '' },
      { filterParamValue: '' },
      { filterParamValue: '' },
      { filterParamValue: '' },
      { filterParamValue: '' },
      { filterParamValue: '' },
    ]

  let tipoAnagrafe
  let numeroIscrizioneOrdine
  let codiceFiscale
  let nomeCognomeRagoneSociale
  let statoIscrizione
  let pec
  let titolo
  let statoSmartCard
  let statoLettoreSmartCard
  let iscrizioneEpap
  let statoToken
  let richiestaCambioSezioneInCorso
  let richiestaTrasferimentoOrdineInCorso
  var isFilterParamNotEmpty = filterParams.map((a) => a.filterParamValue !== '').includes(true)

  if (isFilterParamNotEmpty && filterParams !== undefined) {
    tipoAnagrafe = filterParams[0].filterParamValue
    if (filterParams[1].filterParamValue !== "") {
      ordiniCompetentiList = [filterParams[1].filterParamValue]; // Se è stato scelto un ordine di appartenenza dal filtro, sostituisco il contenuto dell'array con il valore scelto
    }
    numeroIscrizioneOrdine = filterParams[2].filterParamValue
    codiceFiscale = filterParams[3].filterParamValue
    nomeCognomeRagoneSociale = filterParams[4].filterParamValue
    statoIscrizione = filterParams[5].filterParamValue
    titolo = filterParams[6].filterParamValue
    pec = filterParams[7]?.filterParamValue
    iscrizioneEpap = filterParams[8]?.filterParamValue
    statoSmartCard = filterParams[9]?.filterParamValue
    statoLettoreSmartCard = filterParams[10]?.filterParamValue
    statoToken = filterParams[11]?.filterParamValue
    richiestaTrasferimentoOrdineInCorso = filterParams[12]?.filterParamValue
    richiestaCambioSezioneInCorso = filterParams[13]?.filterParamValue
    //idIscrizione e un campo nascosto
  } else if (filterParamsFromTodo || filterParamsFromTodo !== null) {
    numeroIscrizioneOrdine = filterParamsFromTodo.numeroIscrizione
    tipoAnagrafe = filterParamsFromTodo.tipologia
    codiceFiscale = filterParamsFromTodo.codiceFiscale
    nomeCognomeRagoneSociale = filterParamsFromTodo.nomeCognomeRagoneSociale
    statoIscrizione = filterParamsFromTodo.statoIscrizione
    pec = filterParamsFromTodo.pec
  }

  // Compongo la query query jpa in graph join
  const queryGraphContent = `{
        cf,
        codiceFiscaleAzienda,
        cognome,
        nome,
        tipoAnagrafe,
        pec,
        formaGiuridica,
        ragioneSociale,
        idIscrizione,
        ordineTerritorialeCompetente,
        statoIscrizione,
        tipologiaIscrizione,
        numeroIscrizioneOrdine,
        flagIscrizioneEpap,
        titoloEsteso,
        statoTrasferimentoOrdine,
        statoCambioSezione
    }`

  // Compongo la query di select, con gli eventuali order by
  const selectQuery = `{
        cf${sortField(sortParam?.codiceFiscale)},
        codiceFiscaleAzienda,
        cognome${sortField(sortParam?.nomeCognomeRagioneSociale)},
        nome${sortField(sortParam?.nomeCognomeRagioneSociale)},
        tipoAnagrafe${sortField(sortParam?.tipologia)},
        pec,
        formaGiuridica,
        ragioneSociale${sortField(sortParam?.nomeCognomeRagioneSociale)},
        idIscrizione,
        ordineTerritorialeCompetente${sortField(sortParam?.ordineDiAppartenenza)},
        tipologiaIscrizione,
        statoIscrizione${sortField(sortParam?.stato)},
        numeroIscrizioneOrdine${sortField(sortParam?.nIscrizione)},
        flagIscrizioneEpap,
        titoloEsteso,
        statoTrasferimentoOrdine,
        statoCambioSezione
    }`

  const selectQueryForReiscritti = `{
      cf${sortField(sortParam?.codiceFiscale)},
      codiceFiscaleAzienda,
      cognome${sortField(sortParam?.nomeCognomeRagioneSociale)},
      nome${sortField(sortParam?.nomeCognomeRagioneSociale)},
      tipoAnagrafe${sortField(sortParam?.tipologia)},
      pec,
      formaGiuridica,
      ragioneSociale${sortField(sortParam?.nomeCognomeRagioneSociale)},
      codiceFiscaleResponsabileStp,
      idIscrizione,
      ordineTerritorialeCompetente${sortField(sortParam?.ordineDiAppartenenza)},
      statoIscrizione${sortField(sortParam?.stato)},
      tipologiaIscrizione,
      titoloEsteso,
      numeroIscrizioneOrdine${sortField(sortParam?.nIscrizione)},
      cancellato,
      flagIscrizioneEpap,
      codiceFiscaleStpOperatore
  }`

  var globalSearchQueryGraph = new GraphqlBuilder()
    .group(
      new GraphqlGroupBuilder()
        .in('tipologiaIscrizione', [1, 10])
        .in(
          'ordineTerritorialeCompetente',
          ordiniCompetentiList.length === 0
            ? null
            : ordiniCompetentiList.map((o) => {
              return '"' + o + '"'
            })
        )
        .in('statoIscrizione', convertStatoToCodiceStato(globalSearchParam, listaStati))
        .buildAND()
    )
    .group(
      new GraphqlGroupBuilder()
        .like('nome', globalSearchParam)
        .like('cognome', globalSearchParam)
        .like('tipoAnagrafe', tipoAnagrafeEsteso(globalSearchParam))
        .like('pec', globalSearchParam)
        .like('cf', globalSearchParam)
        .like('codiceFiscaleAzienda', globalSearchParam)
        .like('ragioneSociale', globalSearchParam)
        .in('numeroIscrizioneOrdine', globalSearchParam)
        .like('titoloEsteso', globalSearchParam)
        .like('flagIscrizioneEpap', globalSearchParam)
        .like('statoTrasferimentoOrdine', globalSearchParam)
        .like('statoCambioSezione', globalSearchParam)
        .buildOR()
    )
    .build()

  var filterSearchQueryGraph = new GraphqlBuilder()
    .group(
      new GraphqlGroupBuilder()
        .or([
          new ClauseBuilder().like('nome', nomeCognomeRagoneSociale),
          new ClauseBuilder().like('cognome', nomeCognomeRagoneSociale),
          new ClauseBuilder().like('ragioneSociale', nomeCognomeRagoneSociale),
          new ClauseBuilder().like('codiceFiscaleAzienda', codiceFiscale),
          new ClauseBuilder().like('cf', codiceFiscale),
        ])
        .like('tipoAnagrafe', tipoAnagrafeEsteso(tipoAnagrafe))
        .like('pec', pec)
        .like('titoloEsteso', titolo)
        .in('numeroIscrizioneOrdine', numeroIscrizioneOrdine)
        .eq('flagIscrizioneEpap', convertSiNoIscrizioneEpapToInt(iscrizioneEpap))
        .in('statoTrasferimentoOrdine', statoRichiestaTrasferimentoOrdineBySiNo(richiestaTrasferimentoOrdineInCorso))
        .in('statoCambioSezione', statoCambioSezioneBySiNo(richiestaCambioSezioneInCorso))
        .in('statoIscrizione', convertStatoToCodiceStato(statoIscrizione, listaStati))
        .in('tipologiaIscrizione', [1, 10])
        .in(
          'ordineTerritorialeCompetente',
          ordiniCompetentiList.length === 0
            ? null
            : '"' + ordiniCompetentiList?.toString().replaceAll(",", "\",\"") + '"'
        )
        .buildAND()
    )
    .build()
  const whereConditions = () => {
    if (globalSearchParam !== '' && globalSearchParam !== undefined) {
      return `${globalSearchQueryGraph}`
    } else if (isFilterParamNotEmpty && filterParams !== undefined || filterParamsFromTodo !== null) {
      return `${filterSearchQueryGraph}`
    } else if (ordiniCompetentiList.length === 0) {
      return `{ tipologiaIscrizione: {IN: [1,10]}}`
    } else {
      return `{ ordineTerritorialeCompetente: {${inClauseFromArray(ordiniCompetentiList?.toString().replaceAll(",", "\",\""))}}, tipologiaIscrizione: {IN: [1,10]}}`
    }
  }

  // Costruisco la query jpa da passare al server

  let jpaQuery = ``;
  if (cancellatiReiscritti === undefined || cancellatiReiscritti === false) {
    jpaQuery = `{
        IscrittiEntityViews(
            page:{start: ${index}, limit: ${limit}}
            where: ${whereConditions()}
        )
        {
            pages
            total
            select ${selectQuery}
        }
    }`
  } else {
    jpaQuery = `{
        CancellatiReiscrittiEntityViews(
            page:{start: ${index}, limit: ${limit}}
            where: ${whereConditions()}
        )
        {
            pages
            total
            select ${selectQueryForReiscritti}
        }
    }`
  }

  /*
    Questa è una query di esempio che restituisce un oggetto Paged<IscrittiEntityViews>.
    L'oggetto Paged contiene nei campi:

    index: è la pagina corrente che parte da 1
    size: il numero di elementi in pagina
    totalCount: è il numero totale di elementi trovati dalla query
    pageCount: il numero totale di pagine da scorrere se il numero di elementi per pagina è size
    content: i campi richiesti in output dall'entità IscrittiEntityViews eventualmente collegati in join sull'entità che serve, parametrizzati in {queryGraphContent}
    */

  //query da inviare all'endpoint graph specifico che accetta in input la query graphjpa da eseguire
  //e restituisce in ouput un oggetto paginato con la lista delle entità.
  //occorre ottimizzare il codice
  let queryGraph = `query allAnagrafeIscrizioniByFilter($pageRequest: PagedRequestDtoInput) {
        allAnagrafeIscrizioniByFilter(pageRequest: $pageRequest){
            index
            size
            totalCount
            pageCount
            content ${queryGraphContent}
        }
    }`

  const json = {
    operationName: 'allAnagrafeIscrizioniByFilter',
    query: queryGraph,
    variables: {
      pageRequest: {
        index: index,
        size: limit,
        jpsQuery: jpaQuery,
      },
    },
  }

  /**
   * Restituisce il nestedComponent in funzione del tipo anagrafe (PF o PG)
   * @param {*} field
   * @returns
   */
  const nestedComponent = (field) => {
    if (field?.tipoAnagrafe === 'PF') {
      return [
        {
          label: 'Titolo',
          value: field.titoloEsteso,
        },
        {
          label: 'PEC',
          value: field.pec,
        },
        {
          label: 'Iscrizione EPAP',
          value: iscrizioneEpap,
        },
        {
          label: 'Stato smart card',
          value: 'Richiesta fornitura in corso', //inserito momentanemanete statico perché non è disponibile il dato a backend
        },
        {
          label: 'Stato lettore smart card',
          value: '-', // TODO: gestire quando il dato sarà disponibile a backend
        },
        {
          label: 'Stato token',
          value: '-', // TODO: gestire quando il dato sarà disponibile a backend
        },
        {
          label: 'Richiesta trasferimento ordine in corso',
          value: isRichiestaTrasferimentoOrdineInCorso(field.statoTrasferimentoOrdine),
        },
        {
          label: 'Richiesta cambio sezione in corso',
          value: isRichiestaCambioSezioneInCorso(field.statoCambioSezione),
        },
      ]
    } else if (field?.tipoAnagrafe === 'PG') {
      return (
        [{
          label: 'Forma giuridica',
          value: field.formaGiuridica,
        },
        {
          label: 'PEC',
          value: field.pec ? field.pec : "-",
        }]
      )
    } else {
      throw new Error('Tipo anagrafe non riconosciuto: ' + field?.tipoAnagrafe)
    }
  }

  var rows
  // Invoca il servizio default di ricerca degli iscritti
  if (cancellatiReiscritti === undefined || cancellatiReiscritti === false) {
    rows = await getAnagrafeResourcesApi()
      .msgaAnagrafeElencoIscrizioniPost(json.variables.pageRequest, getDefaultAxiosConfig())
      .then((response) => {
        var descrizioneStatoIscrizione
        var rowsNames
        var responseData = response.data
        if (response?.data?.errors) throw new Error(response?.data?.errors[0]?.message)
        rowsNames = responseData.content.map(
          (field) => (
            (descrizioneStatoIscrizione = getDescrizioneStatoIscrizione(listaStati, field.statoIscrizione)),
            (iscrizioneEpap = convertFlagSiNo(field.flagIscrizioneEpap)),
            {
              id: field.idIscrizione,
              data: [
                field.tipoAnagrafe === 'PF' ? 'persona fisica'.toUpperCase() : 'persona giuridica'.toUpperCase(),
                field.ordineTerritorialeCompetente,
                field.numeroIscrizioneOrdine === 0 ? '' : field.numeroIscrizioneOrdine ? field.numeroIscrizioneOrdine : '',
                field.tipoAnagrafe === 'PF' ? field?.cf : field?.codiceFiscaleAzienda,
                field.tipoAnagrafe === 'PF' ? field?.cognome + ' ' + field?.nome : field?.ragioneSociale,
                descrizioneStatoIscrizione,
                field.idIscrizione,
              ],
              nestedComponent: nestedComponent(field),
            }
          )
        )
        var elencoIscrittiArrayRemapped = {
          index: responseData.index,
          pageCount: responseData.pageCount,
          size: responseData.size,
          totalCount: responseData.totalCount,
          content: rowsNames,
        }
        return elencoIscrittiArrayRemapped
      })
      .catch((err) => {
        throw new Error("Errore durante la get degli iscritti all'ordine " + err)
      })
  }

  // Invoca il servizio per i cancellati reiscritti
  else {
    rows = await getAnagrafeResourcesApi()
      .msgaAnagrafeGetCancellatiReiscrittiPost(json.variables.pageRequest, getDefaultAxiosConfig())
      .then((response) => {
        var descrizioneStatoIscrizione
        var rowsNames
        var responseData = response.data
        if (response?.data?.errors) throw new Error(response?.data?.errors[0]?.message)
        rowsNames = responseData.content.map(
          (field) => (
            (descrizioneStatoIscrizione = getDescrizioneStatoIscrizione(listaStati, field.statoIscrizione)),
            (iscrizioneEpap = convertFlagSiNo(field.flagIscrizioneEpap)),
            {
              id: field.idIscrizione,
              data: [
                field.tipoAnagrafe === 'PF' ? 'persona fisica'.toUpperCase() : 'persona giuridica'.toUpperCase(),
                field.tipoAnagrafe === 'PF' ? field?.cf : field?.codiceFiscaleAzienda,
                field.tipoAnagrafe === 'PF' ? field?.cognome + ' ' + field?.nome : field?.ragioneSociale,
                field.numeroIscrizioneOrdine === 0 ? '' : field.numeroIscrizioneOrdine ? field.numeroIscrizioneOrdine : '',
                descrizioneStatoIscrizione,
                field.idIscrizione,
              ],
              nestedComponent: nestedComponent(field),
            }
          )
        )
        var elencoIscrittiArrayRemapped = {
          index: responseData.index,
          pageCount: responseData.pageCount,
          size: responseData.size,
          totalCount: responseData.totalCount,
          content: rowsNames,
        }
        return elencoIscrittiArrayRemapped
      })
      .catch((err) => {
        throw new Error("Errore durante la get degli iscritti all'ordine " + err)
      })
  }
  return JSON.stringify(rows)
}

/**
 * Restituisce la lista degli ordini territoriali competenti
 * per il profilo corrente.
 * Lista vuota => CONAF
 * Lista piena => size: 1 ODAF size: N FODAF
 * @returns
 */
export async function getOrdiniCompetentiList() {
  var ordiniCompetentiList = [] // default CONAF

  // Mi dice che tipo di filtro applicare per l'ordine (vedi dettaglio in getOrdineFilterType)
  var ordineTerritorialeCompetenteEnableFilter = TokenStore.getInstance().getOrdineFilterType()

  /**
   * Se sono operatore di federazione cerco tutti gli ordini territoriali
   * competenti da filtrare.
   * Se sono operatore di un ordine territoriale filtro
   * cerco quell'ordine e applico il filtro.
   */
  if (ordineTerritorialeCompetenteEnableFilter === 1 || ordineTerritorialeCompetenteEnableFilter === 2) {
    var specificGroup = TokenStore.getInstance().getSpecificGroupName(TokenStore.getInstance().getCurrentAccountId())
    await getOrdineCompetenteOperatorePOST(specificGroup)
      .then(async (response) => {

        ordiniCompetentiList.push(response.data)
      })
      .catch((err) => {
        throw new Error("Errore durante il recupero dell'ordine territoriale competente: " + err)
      })
  }

  return ordiniCompetentiList
}

/**
 * Se array = [LE] ==> [\"LE\"]
 * Se array = [LE,MI] ==> [\"LE\",\"MI\",] - [\
 */
function inClauseFromArray(arrayContent) {
  var array = "IN: [";

  // array = array.substring(0, array.length - 1);
  array = array + "\"" + arrayContent + "\"]";
  return array;
}
function sortField(sortDirection) {
  if (sortDirection) return '(orderBy: ' + sortDirection?.toUpperCase() + ')'
  else return ''
}
