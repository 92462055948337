/* eslint-disable array-callback-return */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/no-direct-mutation-state */
/* eslint-disable react/jsx-no-target-blank */
import {FormGroup, Label} from 'design-react-kit'
import React from 'react'
import GenericCard from '../../../components/GenericCard'
import GenericUploadFront from '../../../components/GenericUpload'
import GenericSpinner from 'src/backoffice/app/components/GenericSpinner'
import {getPolizzaById} from 'src/processes/assicurazioni/Assicurazione'
import GenericModal from 'src/backoffice/app/components/GenericModal'
import ListaAllegatiAssicurazioni from 'src/backoffice/app/components/Assicurazioni/ListaAllegatiAssicurazioni'
import {isCurrentMobileDevice} from 'src/utilities/deviceUtility'


export default class PagamentoPolizza extends React.Component<PagamentoPolizzaProps, any>{
  constructor(props) {
    super(props);
    this.state = {
      activeTab: props.activeTab,
      tabClick: props.tabClick,
      prevData: props.prevData,
      polizza: props.polizza,
      visualizzaFlag: props.visualizzaFlag,
      polizzaInsColl: props.polizzaInsColl,
      modificaDatiFlag: props.modificaDatiFlag,
      idAllegato: props.idAllegato,
      prevFileUploaded: props.prevFileUploaded,
      getSelectValues:props.getSelectValues,
      errors: {},
      isLoaded : false,
      filePagamentoExists : false,
      pagamento: {},
      campiPagamentoAzzerati: false,
    };
  }

  async componentDidMount(): Promise<void> {
    let polizza = this.state.polizza;
    let idPolizza;


    if(this.props.data.id) idPolizza = this.props.data.id;
    if(this.props.data.idPolizza) idPolizza = this.props.data.idPolizza;
    if(this.props.data) this.setState({polizza : this.props.data})

    if(this.props.data) {
      let id;
      if(this.props.data) id = this.props.data.id;
      if(this.props.prevData) id = this.props.prevData.id;
      await getPolizzaById(id).then(async (response: any)=> {

        await this.setState({polizza: response});

          if(response.associatoConConaf === 1 && response.premioConaf > 0) {
              this.setState({gratuita: false})
          } else {
            if(response.associatoConConaf === 1 && response.premioConaf === 0)
              this.setState({gratuita: true})
          }

          let allegati = response.assicurazioneAllegati.sort( (p1, p2) => (p1.dataInserimento > p2.dataInserimento && p1.tipologiaAllegato === "polizze_copia_pagamento") ? -1 : (p1.dataInserimento < p2.dataInserimento && p1.tipologiaAllegato === "polizze_copia_pagamento") ? 1 : 0);

            if(allegati !== undefined && allegati.length > 0 && allegati[0].tipologiaAllegato === "polizze_copia_pagamento"){
                    this.setState({filePagamentoExists : true})
            } else this.setState({filePagamentoExists : false})
            this.props.getSelectedValue(this.state.polizza, "polizza");

          }).catch(() => {
                    setTimeout(() => {
                        this.setState({ message: "C'è stato un errore durante il recupero dei dati", typeMessage: 'danger' })
                    }, 3000);
          })
    }


    if(this.props.data) {
      let obj = this.props.data;
      if(typeof(obj.notePagamento)) obj.notePagamento = this.state.polizza.notePagamento;
      if (obj.assicurazioneAllegati){
          let allegati = obj.assicurazioneAllegati.sort( (p1, p2) => (p1.dataInserimento > p2.dataInserimento && p1.tipologiaAllegato === "polizze_copia_pagamento") ? -1 : (p1.dataInserimento < p2.dataInserimento && p1.tipologiaAllegato === "polizze_copia_pagamento") ? 1 : 0);
          allegati.map((fileAllegato) =>{
            if(allegati !== undefined && allegati.length > 0 && fileAllegato.tipologiaAllegato === "polizze_copia_pagamento"){
              this.setState({filePagamentoExists : true})
          }
        })

      }
    }

    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    this.setState({isLoaded: true, polizza: polizza})

  }

  //setta l'allegato logicamente cancellato
  //finchè non confermo al next successivo
  //l'allegato non viene effettivamente rimosso dal db
  rimuoviAllegato = async(idAllegato?:any) => {
      let polizza = this.state.polizza;
      polizza.assicurazioneAllegati.filter(a => a.idAllegato === idAllegato)
      .map(a => a.cancellato = 1);

      this.setState({polizza: polizza})
      this.props.getSelectedValue(polizza, 'polizza')
  }


  closeModal = () => {
    this.setState({modalOpen:false});
  }

  showModal = async (booleanValue, title, text) => {
    await this.setState({
        modalOpen:booleanValue,
        modalTitle: title,
        modalText:text,
        isLoaded: true,
    });
  }

  resetFilePagamento = (polizza: any) => {

      polizza.copiaPagamentoPolizza = [];
      this.setState({polizza: polizza, campiPagamentoAzzerati: true});
      this.setState({prevFileUploaded : false});
  }

  controlloUnicoFile = (polizza: any) => {
      if((polizza?.assicurazioneAllegati && polizza?.assicurazioneAllegati.filter(
        allegato =>
            allegato.cancellato === 0 &&
            allegato.tipologiaAllegato.includes("polizze_copia_pagamento")
        ).length > 0) || (this.state.polizza["copiaPagamentoPolizza"] && this.state.polizza["copiaPagamentoPolizza"].length > 0)
      ) {
        this.setState({disabledUpload: true})
        this.showModal(true, "Errore.","Attenzione! File già caricato! E' possibile allegare solo un unico file.");
        return false
      } else {
        this.setState({disabledUpload: false, campiPagamentoAzzerati: false})
        return true
      }

  }

  render() {

    const {tabNumber} = this.props;

    const getDatiUploadFile = (fileData) => {
      if(this.controlloUnicoFile(this.state.polizza)) {
        this.state.polizza["copiaPagamentoPolizza"] = [fileData]
      }
      this.props.getSelectedValue(this.state.polizza, 'polizza')
      this.setState({polizza: this.state.polizza, isLoaded: true })
    }


    /********************************* Form Pagamento ************************************/
    const formPagamento = [
        <div key={"formPagamento"}>
            <div><h6>Gratuità di adesione non applicabile, è necessario caricare l'allegato del pagamento. (Numero max. allegati: 1) </h6>
            </div>
            <br></br>

            <div id="formPagamento" hidden={(!this.state.polizza)}>
                <GenericCard key={'pagamentoPolizza'} cardHeader="Pagamento"
                    cardBody={
                        <>

                          <div className="row">
                              <div className={isCurrentMobileDevice() ? null : "col-4"}>
                                  <Label color={'text-danger'}>Copia del pagamento *</Label>
                              </div>
                              <div className={isCurrentMobileDevice() ? null : "col-8"}>
                                    <FormGroup label={"copiaPagamentoPolizza"} onClick={()=>this.controlloUnicoFile(this.state.polizza)}>
                                            <GenericUploadFront

                                            isDisabled={(this.state.polizza?.assicurazioneAllegati && this.state.polizza?.assicurazioneAllegati?.filter(
                                              allegato =>
                                                  allegato.cancellato === 0 &&
                                                  allegato.tipologiaAllegato.includes("polizze_copia_pagamento")
                                              ).length > 0) ||
                                              (this.state.polizza["copiaPagamentoPolizza"] && this.state.polizza["copiaPagamentoPolizza"].length) > 0}

                                            datiAllegato={getDatiUploadFile}
                                            id={"copiaPagamentoPolizza"}
                                            isRequired={this.state.prevFileUploaded === true ? false : true}
                                            acceptedExtensionFile={["pdf"]}
                                            tipologiaAllegato={"polizze_copia_pagamento"}
                                            hideUploadedFile={true}
                                            hideDownloadFile={true}
                                            key="copiaPagamentoPolizza"
                                            />
                                    </FormGroup>
                              </div>
                              {/* <div className="row">
                                  <div className="col-6"><FormGroup label={"notePagamento"} >
                                      <GenericInput key={'notePagamento'} id="notePagamento" name={'notePagamento'} onChange={(e)=>setDataItem(e, 'text','notePagamento')} label="Note" placeholder='Inserisci Note (facoltativo)'
                                        defaultValue={(this.state.polizza.notePagamento) ? this.state.polizza.notePagamento : null} errorMessageAlert={this.props.message} currentStep={tabNumber}
                                        />
                                      </FormGroup>
                                  </div>
                              </div>  */}
                              <div className="row">
                                    <ListaAllegatiAssicurazioni
                                        polizza={this.state.polizza}
                                        nomeAllegato={"copiaPagamentoPolizza"}
                                        tipologiaAllegato={"polizze_copia_pagamento"}
                                        visualizzaFlag={this.props.visualizzaFlag}
                                        datiPagamento={true && !this.state.campiPagamentoAzzerati}
                                        resetFile={this.resetFilePagamento}
                                        note={true}
                                        getSelectedValue={this.props.getSelectedValue}
                                    />

                              </div>

                          </div>
                        </>
                    }>

                </GenericCard>
            </div>
        </div>
    ];

    const formVisualizzaPagamento = [
        <div key={"formVisualizzaPagamento"}>
            <div id="formPagamento" className="row" hidden={(!this.state.polizza)}>
                <GenericCard key={'pagamentoPolizza'} cardHeader="Pagamento"
                    cardBody={
                      <div key={'pagamentoPolizzaDivVis'}>
                        <div className="row" hidden>
                            <div className={isCurrentMobileDevice() ? null : "col-6"}>
                              <Label color={'text-danger'}>Copia del pagamento *</Label>
                            </div>
                        </div>
                        <div className="row">
                              <ListaAllegatiAssicurazioni
                                    polizza={this.state.polizza}
                                    nomeAllegato={"copiaPagamentoPolizza"}
                                    tipologiaAllegato={["polizze_copia_pagamento","AssicurazioniCopiaPagamento"]}
                                    visualizzaFlag={this.props.visualizzaFlag}
                                    resetFile={this.resetFilePagamento}
                                    datiPagamento={true}
                                    note={true}
                                  />
                        </div>
                      </div>
                    }>
                </GenericCard>
            </div>
        </div>
    ];

    if(this.state.isLoaded)
        return(
            <div key={"pagamentoForms"}>
                { this.state.gratuita === true ?
                  <div style={{minHeight:'200px'}}><h6>Gratuità di adesione applicata, l'importo da pagare è zero. </h6></div> :
                    (this.props.modificaDatiFlag) ? formPagamento :
                    (this.props.visualizzaFlag) ?  formVisualizzaPagamento :
                    formPagamento
                }
                <GenericModal
                    title={this.state.modalTitle}
                    text={this.state.modalText}
                    modalOpen={this.state.modalOpen}
                    closeButton={this.closeModal}
                />
            </div>
        )
    else return (<div key={"spinner"}><GenericSpinner /></div>)
  }
}

export declare interface PagamentoPolizzaProps {
  data?: any;
  polizza?: any;
  activeTab?: any;
  tabClick?: any;
  modificaDatiFlag?: any;
  storicoFlag?: any;
  visualizzaFlag?: any;
  aumentoMassimaleFlag?: any;
  polizzaInsColl?:any;
  prevData?:any;
  getSelectedValue?:any;
  tabNumber?: any;
  message?: any;
  tabellaUno?: any;
  selectedFile?:any;
  datiAllegato?:any;
  fileData?:any;
  getDatiUploadFile?:any;
  prevFileUploaded?:any;
  idAllegato?:any;
  filePagamentoExists?:any;
  gratuita?:any;
}

