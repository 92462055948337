import * as React from 'react'
import {Form} from '@patternfly/react-core'
import GenericTextInput from '../../../components/GenericTextInput'
import {inputRequiredValidation, isOnlyNumbers} from 'src/utilities/utility'
import {getCambioSezioneResourceApi, getDefaultAxiosConfig} from 'src/app/utils/ManagerRestGateway'
import {isCurrentMobileDevice} from 'src/utilities/deviceUtility'

export class FormAccettaCambioSezione extends React.Component<GeneralFormAccettaCambioSezioneProps, any> {
  constructor(props) {
    super(props)
    this.state = {
      dataIniValiTimbroRequired: false,
      dataIniValiMartelloRequired: false,
    }
  }

  //onChange input numero timbro e numero martello
  inputOnChange = (event, handleFunction) => {
    switch (handleFunction) {
      //case 1: onChange numero timbro
      case 1:
        if (event === '') {
          this.setState({ dataIniValiTimbroRequired: false })
        } else {
          this.setState({ dataIniValiTimbroRequired: true })
        }
        break
      //case 2: onChange numero martello
      case 2:
        if (event === '') {
          this.setState({ dataIniValiMartelloRequired: false })
        } else {
          this.setState({ dataIniValiMartelloRequired: true })
        }
        break
    }
  }

  handleSubmit = async (event) => {
    /*--------------------------------------------- Validazione campi obbligatori ------------------------------------------------*/
    var requiredFields = [
      isOnlyNumbers(event.target.idNumeroIscrizione.value)
        ? { value: event.target.idNumeroIscrizione.value, label: 'Numero iscrizione' }
        : { value: '', label: 'Numero iscrizione' },
      { value: event.target.idDataIscrizioneSezA.value, label: 'Data iscrizione sezione A' },
      { value: event.target.idDeliberaIscrizione.value, label: 'Delibera iscrizione' },
      this.state.dataIniValiTimbroRequired ? { value: event.target.idDataIniValiTimbro.value, label: 'Data inizio validità timbro' } : null,
      this.state.dataIniValiMartelloRequired ? { value: event.target.idDataIniValiMartello.value, label: 'Data inizio validità martello' } : null,
    ]

    var errorRequired: any = inputRequiredValidation(requiredFields)
    if (errorRequired !== undefined && errorRequired !== '') {
      event.preventDefault()
      return this.props.messaggio({ messaggio: errorRequired.messaggio, type: errorRequired.type })
    } else {
    /*------------------------------------------------------------------------------------------------------------------------------*/
      event.preventDefault()

      const param = {
        esitoValutazione: 'chiusa',
        idIscrizione: this.props.idIscrizione,
        valutazioneDTO: {
          numeroIscrizioneOrdine: event.target.idNumeroIscrizione.value ? event.target.idNumeroIscrizione.value : '',
          dataIscrizioneOrdine: event.target.idDataIscrizioneSezA.value ? event.target.idDataIscrizioneSezA.value : null,
          deliberaIscrizione: event.target.idDeliberaIscrizione.value ? event.target.idDeliberaIscrizione.value : '',
          numeroTimbro: event.target.idNumeroTimbro.value ? event.target.idNumeroTimbro.value : '',
          dataInizioValiTimbro: this.state.dataIniValiTimbroRequired && event.target.idDataIniValiTimbro.value ? event.target.idDataIniValiTimbro.value : null,
          dataFineValiTimbro: event.target.idDataFineValiTimbro.value ? event.target.idDataFineValiTimbro.value : null,
          numeroMartello: event.target.idNumeroMartello.value ? event.target.idNumeroMartello.value : '',
          dataInizioValiMartello:
            this.state.dataIniValiTimbroRequired && event.target.idDataIniValiMartello.value ? event.target.idDataIniValiMartello.value : null,
          dataFineValiMartello: event.target.idDataFineValiMartello.value ? event.target.idDataFineValiMartello.value : null,
        },
        cambioSezioneDTO: {
          idCambioSezione: this.props.idCambioSezione,
          idIscrizione: this.props.idIscrizione,
        },
      }

      await getCambioSezioneResourceApi()
        .mswfCambioSezioneValutaPost(param, getDefaultAxiosConfig())
        .then(() => {
          return this.props.messaggio({ messaggio: 'Dati salvati con successo', type: 'success' })
        })
        .catch((err) => {
          return this.props.messaggio({ messaggio: "C'è stato un problema nell'invio dei dati", type: 'danger' })
        })
    }
  }

  render() {
    const { dataIniValiTimbroRequired, dataIniValiMartelloRequired } = this.state
    return (
      <div>
        <Form id="idAccettaCambioSezione" onSubmit={this.handleSubmit}>
          <div className="row">
            <div className={isCurrentMobileDevice() ? null : 'col-6'}>
              <GenericTextInput
                type={'only-number'}
                className="w-75"
                id={'idNumeroIscrizione'}
                label={'Numero iscrizione'}
                placeholder={'Inserire numero iscrizione'}
                isRequired
              />
            </div>
            <div className={isCurrentMobileDevice() ? null : 'col-6'}>
              <GenericTextInput
                type={'date'}
                className="w-75"
                id={'idDataIscrizioneSezA'}
                label={'Data iscrizione sezione A'}
                placeholder={'Selezionare data'}
                style={{ paddingRight: '25px' }}
                isRequired
              />
            </div>
          </div>
          <div className="row">
            <div className={isCurrentMobileDevice() ? null : 'col-6'}>
              <GenericTextInput
                type={'text'}
                className="w-75"
                id={'idDeliberaIscrizione'}
                label={'Delibera iscrizione'}
                placeholder={'Inserire delibera iscrizione'}
                isRequired
              />
            </div>
          </div>
          <div className="row">
            <div className={isCurrentMobileDevice() ? null : 'col-6'}>
              <GenericTextInput
                type={'text'}
                className="w-75"
                id={'idNumeroTimbro'}
                label={'Numero timbro'}
                placeholder={'Inserire numero timbro'}
                onChange={(e) => this.inputOnChange(e, 1)}
              />
            </div>
          </div>
          <div className="row">
            <div className={isCurrentMobileDevice() ? null : 'col-6'}>
              {/*Campo obbligatorio solo se è inserito il numero Timbro*/}
              <GenericTextInput
                type={'date'}
                className="w-75"
                id={'idDataIniValiTimbro'}
                label={'Data inizio validità timbro'}
                placeholder={'Selezionare data'}
                style={{ paddingRight: '25px' }}
                isRequired={dataIniValiTimbroRequired}
              />
            </div>
            <div className={isCurrentMobileDevice() ? null : 'col-6'}>
              {/*Se c’è la data di inizio e non è compilato si intende privo di scadenza*/}
              <GenericTextInput
                type={'date'}
                className="w-75"
                id={'idDataFineValiTimbro'}
                label={'Data fine validità timbro'}
                placeholder={'Selezionare data'}
                style={{ paddingRight: '25px' }}
              />
            </div>
          </div>
          <div className="row">
            <div className={isCurrentMobileDevice() ? null : 'col-6'}>
              <GenericTextInput
                type={'text'}
                className="w-75"
                id={'idNumeroMartello'}
                label={'Numero martello'}
                placeholder={'Inserire numero martello'}
                onChange={(e) => this.inputOnChange(e, 2)}
              />
            </div>
          </div>
          <div className="row">
            <div className={isCurrentMobileDevice() ? null : 'col-6'}>
              {/*Campo obbligatorio solo se è inserito il numero Matello*/}
              <GenericTextInput
                type={'date'}
                className="w-75"
                id={'idDataIniValiMartello'}
                label={'Data inizio validità martello'}
                placeholder={'Selezionare data'}
                style={{ paddingRight: '25px' }}
                isRequired={dataIniValiMartelloRequired}
              />
            </div>
            <div className={isCurrentMobileDevice() ? null : 'col-6'}>
              {/* Se c’è la data di inizio e non è compilato si intende privo di scadenza*/}
              <GenericTextInput
                type={'date'}
                className="w-75"
                id={'idDataFineValiMartello'}
                label={'Data fine validità martello'}
                placeholder={'Selezionare data'}
                style={{ paddingRight: '25px' }}
              />
            </div>
          </div>
        </Form>
      </div>
    )
  }
}

export declare interface GeneralFormAccettaCambioSezioneProps {
  messaggio?: any
  idIscrizione?: any
  idCambioSezione?: any
}
