import moment from 'moment'
import {MODALITA_DISPONIBILI_VALUES} from 'src/formazione'
import {isTimeCFP} from 'src/utilities/utility'

const rules = {
  nomeCorso: {
    isRequired: true,
  },
  modalita: {
    isRequired: true,
  },
  dataInizio: {
    inclusion: {
      field: 'modalita',
      values: [MODALITA_DISPONIBILI_VALUES.IN_SITU, MODALITA_DISPONIBILI_VALUES.FAD_ASINCRONO, MODALITA_DISPONIBILI_VALUES.MISTA],
    },
    _custom: {
      check: (code, rule, data) => {
        if(moment(data.dataInizio).get('year') > 3000 || moment(data.dataInizio).get('year') < 1000) {
          return false
        }
        if (data.__IS_EDIT_MODE) {
          return true
        }
        return true // moment().diff(data.dataInizio, 'days') <= 0
      },
    },
    isRequired: true,
  },
  dataFine: {
    inclusion: {
      field: 'modalita',
      values: [MODALITA_DISPONIBILI_VALUES.IN_SITU, MODALITA_DISPONIBILI_VALUES.FAD_SINCRONO, MODALITA_DISPONIBILI_VALUES.MISTA],
    },
    _custom: {
      check: (code, rule, data) => {
        if(moment(data.dataFine).get('year') > 3000 || moment(data.dataFine).get('year') < 1000) {
          return false
        }
        if (data.__IS_EDIT_MODE) {
          return true
        }
        if (data.dataInizio) {
          return moment(data.dataInizio).diff(data.dataFine, 'days') <= 0
        } else {
          return true //moment().diff(data.dataFine, 'days') <= 0
        }
      },
    },
    isRequired: true,
  },
  totOre: {
    _custom: {
      check: (code, rule, data)=> {

      if(data.modalita.value !== MODALITA_DISPONIBILI_VALUES.FAD_ASINCRONO) {
        if (isTimeCFP(data.totOre)) {
          if ( (data.dataInizio !== "") && (data.dataFine !== "") && data.totOre) {
            const startDate = moment(data.dataInizio);
            const endDate = moment(data.dataFine);
            const durationInDays = endDate.diff(startDate, 'days') + 1;
            const maxHours = durationInDays * 24;
            const oreEMinuti = code.split(':');

            if (oreEMinuti.length === 2) {
              const ore = parseInt(oreEMinuti[0]);
              const minuti = parseInt(oreEMinuti[1]);

              if (!isNaN(ore) && !isNaN(minuti)) {
                const totOreAsNumber = ore + minuti / 60;

                if (totOreAsNumber <= maxHours) {
                  return true;
                } else {
                  return false;
                }
              } else {
                return false;
              }
            } else {
              return false;
            }
          } else {
            if ((data?.dataInizio === null || data?.dataInizio === undefined || data.dataInizio === "" ) || (data?.dataFine === null || data?.dataFine === undefined || data.dataFine === ""))
             { return true;}
            else
            {return false;}
          }
        } else {
          return false;
         }
      }else
            { if (isTimeCFP(data.totOre))
               { return true; }
               else {return false;}     }
      },
      error: 'Inserisci una durata valida nel formato ore:minuti',
    inclusion: {
      field: 'modalita',
      values: [MODALITA_DISPONIBILI_VALUES.FAD_ASINCRONO, MODALITA_DISPONIBILI_VALUES.MISTA],
      },
    },
  },

  tipologiaAttivita: {
    isRequired: true,
  },

  totOreCFU: {
    _custom: {
      check: (code, rule, data) => {

      if(data.modalita.value !== MODALITA_DISPONIBILI_VALUES.FAD_ASINCRONO) {
        if (isTimeCFP(data.totOreCFU)) {
          if ( (data.dataInizio !== "") && (data.dataFine !== "") && data.totOreCFU) {
            const startDate = moment(data.dataInizio);
            const endDate = moment(data.dataFine);
            const durationInDays = endDate.diff(startDate, 'days') + 1;
            const maxHours = durationInDays * 24;
            const oreEMinuti = code.split(':');

            if (oreEMinuti.length === 2) {
              const ore = parseInt(oreEMinuti[0]);
              const minuti = parseInt(oreEMinuti[1]);

              if (!isNaN(ore) && !isNaN(minuti)) {
                const totOreAsNumber = ore + minuti / 60;

                if (totOreAsNumber <= maxHours) {
                  return true;
                } else {
                  return false;
                }
              } else {
                return false;
              }
            } else {
              return false;
            }
          } else {
            if ((data?.dataInizio === null || data?.dataInizio === undefined || data.dataInizio === "" ) || (data?.dataFine === null || data?.dataFine === undefined || data.dataFine === ""))
               {return true;}
            else
            {return false;}
          }
        } else {
          return false;
        }
      }else
          { if (isTimeCFP(data.totOre))
             { return true; }
            else {return false;}     }
      },
      error: 'Inserisci una durata valida nel formato ore:minuti',
      exclusion: {
        field: 'tipologiaAttivita',
        values: ['assemblea', 'corsi_universitari_co_organizzati'],
      },
    },
  },
  crediti: {
    min:0,
    _custom: {
      check: (code, rule, data) => {
        if(data.modalita.value !== MODALITA_DISPONIBILI_VALUES.FAD_ASINCRONO) {
          if ( (data.dataInizio !== "") && (data.dataFine !== "") && data?.crediti) {
          const startDate = moment(data.dataInizio);
          const endDate = moment(data.dataFine);
          const durationInDays = endDate.diff(startDate, 'days') + 1;

          if(data.crediti <= durationInDays)
          { return true;}
          else
          {return false;}
        } else {
          if ((data?.dataInizio === null || data?.dataInizio === undefined || data.dataInizio === "" ) || (data?.dataFine === null || data?.dataFine === undefined || data.dataFine === ""))
             {return true;}
          else
          {return false;}
        }
      } else { return true; }
      }
    },
    inclusion: {
      field: 'tipologiaAttivita',
      values: ['corso_universitario','corsi_universitari_co_organizzati'],
    },

  },
  piattaformaFAD: {
    inclusion: {
      field: 'modalita',
      values: [MODALITA_DISPONIBILI_VALUES.FAD_SINCRONO, MODALITA_DISPONIBILI_VALUES.FAD_ASINCRONO, MODALITA_DISPONIBILI_VALUES.MISTA],
    },
  },
  luogo: {
    inclusion: {
      field: 'modalita',
      values: [MODALITA_DISPONIBILI_VALUES.IN_SITU, MODALITA_DISPONIBILI_VALUES.MISTA],
    },
  },
  maxPSitu: {
    min:0,
    inclusion: {
      field: 'modalita',
      values: [MODALITA_DISPONIBILI_VALUES.IN_SITU, MODALITA_DISPONIBILI_VALUES.MISTA],
    },
  },
  maxPRemoto: {
    min:0,
    inclusion: {
      field: 'modalita',
      values: [MODALITA_DISPONIBILI_VALUES.FAD_SINCRONO, MODALITA_DISPONIBILI_VALUES.FAD_ASINCRONO, MODALITA_DISPONIBILI_VALUES.MISTA],
    },
  },
  partecipazioneConAbbonamento: {
    _custom: {
      check: (code, rule, data) => {
        return (data?.partecipazioneConAbbonamento && data?.quotaAbbonamento >= 0) || (data?.partecipazioneSenzaAbbonamento && data?.quotaNonAbbonati >= 0)
      },
      error: 'Devi selezionare almeno una opzione e valorizzare la quota',
    },
  },
  partecipazioneSenzaAbbonamento: {
    _custom: {
      check: (code, rule, data) => {
        return (data?.partecipazioneConAbbonamento && data?.quotaAbbonamento >= 0) || (data?.partecipazioneSenzaAbbonamento && data?.quotaNonAbbonati >= 0)
      },
      error: 'Devi selezionare almeno una opzione e valorizzare la quota',
    },
  },
  dataChiusuraIscrizione: {
    inclusion: {
      field: 'modalita',
      values: [MODALITA_DISPONIBILI_VALUES.FAD_ASINCRONO],
    },
    _custom: {
      check: (code, rule, data) => {
        if (data.__IS_EDIT_MODE) {
          return true
        }
        if (data.modalita && data.modalita.value !== MODALITA_DISPONIBILI_VALUES.FAD_ASINCRONO) {
          if (data.dataInizio) {
            return moment(data.dataInizio).diff(data.dataChiusuraIscrizione, 'days') >= 0
          } else {
            return false
          }
        } else {
          return moment().diff(data.dataChiusuraIscrizione, 'days') <= 0
        }
      },
      error: 'Inserisci delle date coerenti e riprova',
    },
  },
  codAttivita: {
    isRequired: true,
  },
  settore: {
    isRequired: true,
  },
  codCompetenza: {
    isRequired: true,
  },
  areaProfessionale: {
    isRequired: true,
  },
  check_interventi: {
    _custom: {
      check: (code, rule, data) => {
        let check = true
        if (data.__IS_EDIT_MODE) {
          return true
        }

        if (data?.interventi?.length) {
          if (data.modalita.value !== MODALITA_DISPONIBILI_VALUES.FAD_ASINCRONO) {
            data.interventi.forEach((intervento) => {
              if (!intervento.alle || !intervento.dalle) {
                check = true
              } else if (isNaN(moment(intervento.alle).get('year')) || isNaN(moment(intervento.dalle).get('year'))) {
                check = false
              } else if (moment(intervento.alle).get('year') > 3000 || moment(intervento.dalle).get('year') > 3000) {
                check = false
              } else if (moment(intervento.alle).get('year') < 1000 || moment(intervento.dalle).get('year') < 1000) {
                check = false
              } else if (moment(intervento.alle).diff(intervento.dalle) <= 0) {
                check = false
              } else if (moment(data.dataInizio).diff(intervento.dalle) >= 0 || moment(data.dataFine).add(1, 'day').diff(intervento.alle) <= 0) {
                check = false
              }
            })
          }
        } else {
          check = false
        }
        return check
      },
      error: 'Le date inserite non sono coerenti o mancanti',
    },
  },
  regex: [
    {
      regex: /interventi\.[0-9]+\.titTema/,
      rules: {
        isRequired: true,
      },
    },
    {
      regex: /interventi\.[0-9]+\.relatore\.nome/,
      rules: {
        isRequired: true,
      },
    },
    {
      regex: /interventi\.[0-9]+\.relatore\.cognome/,
      rules: {
        isRequired: true,
      },
    },
    {
      regex: /interventi\.[0-9]+\.relatore\.email/,
      rules: {
        isRequired: true,
        isEmail: {
          error: 'Email non valida',
        },
      },
    },
  ],
}

export default rules
