import * as React from 'react'
import {EmptyState, EmptyStateBody, EmptyStateIcon, Form, FormGroup} from '@patternfly/react-core'
import {ExclamationTriangleIcon} from '@patternfly/react-icons'
import GenericSpinner from 'src/backoffice/app/components/GenericSpinner'
import {getFileByIdAttachmentBO} from 'src/processes/ProcessoIscrizione'
import {Icon} from 'design-react-kit'

export class FormFile extends React.Component<GeneralFormFileProps, any> {

    getFile = (idAttachment, filename) => {

        try {
            let dataUri: any = '';
            getFileByIdAttachmentBO(idAttachment).then(response => {
                if (response === undefined) {
                    alert("File non presente");
                }

                dataUri = "data:" + response.data.contentType + ";base64," + response.data.content;
                const link = document.createElement("a");
                link.download = filename;
                link.href = dataUri;
                link.click();
            })

        } catch (err) {
            alert("errore nel caricamento del file");
        }
    }

    render() {
        const { messaggio, iscrizione, listaFiles } = this.props
        return (
            <>
                {(iscrizione === '' && messaggio === '') ?
                    (
                        <GenericSpinner />
                    )
                    : (messaggio !== '' ? (
                        <EmptyState variant="full">
                            <EmptyStateIcon icon={ExclamationTriangleIcon} />
                            <EmptyStateBody>
                                Non è stato possibile recuperare i dati. Contattare l'assistenza.
                            </EmptyStateBody>
                        </EmptyState>
                    ) : (
                        <>
                            <div hidden={listaFiles.length === 0}>
                                <Form>
                                    {
                                        //recupero la lista di file dell'iscrizione
                                        listaFiles.map((file) => (
                                            <FormGroup label={file.tipologiaAllegato}>
                                                <Icon icon={'it-download'} size='sm' />
                                                <a onClick={() => this.getFile(file.idAttachment, file.filename)}>{file.filename}</a>
                                                <span className='ms-5' hidden={file.codiceTipoAllegato !== "allegato_iot" || !file.descrizioneBreve}><b>Descrizione: </b>{file.descrizioneBreve}</span>
                                            </FormGroup>
                                        ))
                                    }
                                </Form>
                            </div>
                            <div hidden={listaFiles.length > 0}>
                                <p>Nessun file presente</p>
                            </div>
                        </>
                    )
                    )}
            </>
        )
    }
}


export declare interface GeneralFormFileProps {
    listaFiles?: any;
    messaggio?: any;
    iscrizione?: any;
}
