import React from 'react'
import {useHistory} from 'react-router-dom'
import {PageSection, Title} from '@patternfly/react-core'
import useFrontOffice from 'src/hooks/useFrontOffice'

import {generateRequestFilter, generateRequestSort} from 'src/app/utils/ApiUtils'
import {GenericAsyncTableData, RequestData} from 'src/backoffice/app/components/GenericAsyncTableData'
import {
    PATH_TO_BO_DETTAGLIO_FORMATORE,
    PATH_TO_BO_ELENCO_RICHIESTE_ESONERO,
    PATH_TO_FO_DETTAGLIO_FORMATORE,
    PATH_TO_FO_ELENCO_RICHIESTE_ESONERO,
    RO_CONS_FORM_CONAF,
    RO_FO_AGE_RESPONSABILE,
    RO_FO_ENTE_EXT_RESPONSABILE,
    RO_GEST_FORM_CONAF,
    RO_GEST_FORM_FODAF,
    RO_GEST_FORM_ODAF,
    RO_RESP_FO
} from 'src/app/utils/RoutesConstants'
import {DETAILS_ACTIONS} from 'src/formazione'
import {getDefaultAxiosConfig, getFormazioneResourceApi} from 'src/app/utils/ManagerRestGateway'

import GenericBreadCrumb from 'src/frontend/app/components/GenericBreadCrumb'
import {LABEL_STATI, STATI_ESONERO} from 'src/model/Esoneri'
import {TokenStore} from 'src/keycloak/jwt/TokenStore'
import {getAgenziaFormativa} from 'src/hooks/useReqOptions'
import moment from 'moment'

const Spacer = () => (
  <>
    <br></br>
  </>
)
const basePath = { label: 'Home', link: '/' }
const ElencoFormatori: React.FunctionComponent = () => {
  const history = useHistory()
  const isFrontOffice = useFrontOffice()

  const myPath = {
    label: 'Elenco Formatori',
    link: isFrontOffice ? PATH_TO_FO_ELENCO_RICHIESTE_ESONERO : PATH_TO_BO_ELENCO_RICHIESTE_ESONERO,
  }

  const [sdaf, setSdaf] = React.useState<any>([])
  const [attivita, setAttivita] = React.useState<any>([])

  const loadData = async (request: RequestData) => {
    let customFunc = 'msfoFormazioneFormatoriForUserGet'
    if (
      TokenStore.getInstance().currentAccountHasRolesOR([
        RO_GEST_FORM_ODAF,
        RO_GEST_FORM_CONAF,
        RO_CONS_FORM_CONAF,
        RO_RESP_FO,
        RO_FO_AGE_RESPONSABILE,
        RO_GEST_FORM_FODAF,
        RO_FO_ENTE_EXT_RESPONSABILE,
      ])
    )
      customFunc = 'msfoFormazioneFormatoriGet'

    const { data } = await getFormazioneResourceApi()[customFunc](
      generateRequestFilter(request.filters),
      request.pageSize,
      request.currentPage,
      generateRequestSort(request.sortColumn, request.sortDirection),
      getDefaultAxiosConfig()
    )
    setSdaf(sdaf)
    setAttivita(attivita)
    return {
      items: data.items || [],
      total: data.count || 0,
    }
  }

  const onDetails = (row, modalita) => () => {
    history.push({
      pathname: PATH_TO_BO_DETTAGLIO_FORMATORE,
      search: `?id=${row.id}${modalita ? `&modalita=${modalita}` : ''}`,
    })
  }

  const onDetailsFo = (row, modalita) => () => {
    history.push({
      pathname: PATH_TO_FO_DETTAGLIO_FORMATORE,
      search: `?id=${row.id}${modalita ? `&modalita=${modalita}` : ''}`,
    })
  }

  /* TokenStore.getInstance().getCurrentAccount().accounts.CONAF */

  let ageFormAcc: any
  getAgenziaFormativa().then((agenzia) => {
    if (agenzia) {
          if (  agenzia?.dataFineProroga && moment(agenzia?.dataFineProroga).isSameOrAfter(moment()) )
              { ageFormAcc = true }
          else
              { if (agenzia?.stato === 6)
                { ageFormAcc = true }
                else
              { ageFormAcc = false }
              }
              }
        else
      { ageFormAcc = false }  }).catch(() => {})

  return (
    <PageSection>
      <div className="container register-container">
        <GenericBreadCrumb paths={[basePath, myPath]} />
        <Spacer />

        <Title headingLevel="h1" size="lg">
          {myPath.label}
        </Title>
        <Spacer />
        <GenericAsyncTableData
          emptyMessage={'Nessun formatore trovato'}
          filters={[
            {
              label: 'Stato',
              field: 'stato',
              type: 'select',
              options: Object.keys(LABEL_STATI).map((ogg) => {
                return { label: LABEL_STATI[ogg], value: ogg }
              }),
            },
            { label: 'Numero Iscrizione', field: 'id_iscr', type: 'input', inputType: 'text' },
            { label: 'Cognome', field: 'cognome', type: 'input', inputType: 'text', operator: 'LIKE' },
            { label: 'Codice Fiscale', field: 'cod_fisc', type: 'input', inputType: 'text', operator: 'LIKE' },
          ]}
          pageSizeOptions={[20, 30, 50]}
          columns={[
            { label: 'Nome', field: 'nome', isSortable: false },
            { label: 'Cognome', field: 'cognome', isSortable: false },
            { label: 'Tipo iscrizione', field: 'tipologia_iscrizione', isSortable: false },
            { label: 'Stato', field: 'stato', isSortable: false, render: (row) => LABEL_STATI[row.stato] },
          ]}
          tableKey={'elenco-formatori'}
          getRows={loadData}
          getRowActions={(row) => {


            if (isFrontOffice) {
              const showModifica = (ageFormAcc &&
                row.stato === STATI_ESONERO.INTEGRAZIONE) ||
                (row.cod_fisc === TokenStore.getInstance().decodeToken().fiscalNumber &&
                  (row.stato === STATI_ESONERO.INTEGRAZIONE || row.stato === STATI_ESONERO.IN_COMPILAZIONE))

              const actions = [
                {
                  label: 'Dettaglio richiesta',
                  onClick: () => {
                    history.push({
                      pathname: PATH_TO_FO_DETTAGLIO_FORMATORE,
                      search: `?id=${row.id}`,
                    })
                  },
                },
              ]
              if (showModifica) {
                actions.push({ label: 'Modifica', onClick: onDetailsFo(row, DETAILS_ACTIONS.MODIFICA) })
              }
              return actions
            }

            const showModifica =
              row.cod_fisc === TokenStore.getInstance().decodeToken().fiscalNumber &&
              (row.stato === STATI_ESONERO.INTEGRAZIONE || row.stato === STATI_ESONERO.IN_COMPILAZIONE)


            const showAllActions = row.stato === STATI_ESONERO.INVIATA && TokenStore.getInstance().currentAccountHasRolesOR([RO_GEST_FORM_CONAF])
            const showIntegrazione = row.stato === STATI_ESONERO.INVIATA && TokenStore.getInstance().currentAccountHasRolesOR([RO_GEST_FORM_CONAF])

            const actions = [
              {
                label: 'Dettaglio richiesta',
                onClick: () => {
                  history.push({
                    pathname: PATH_TO_BO_DETTAGLIO_FORMATORE,
                    search: `?id=${row.id}`,
                  })
                },
              },
            ]

            if (showIntegrazione) {
              actions.push({ label: 'Richiesta di integrazione', onClick: onDetails(row, DETAILS_ACTIONS.RICHIESTA_INTEG) })
            }

            if (showAllActions) {
              actions.push({ label: 'Approva', onClick: onDetails(row, DETAILS_ACTIONS.APPROVA) })
              actions.push({ label: 'Rigetta', onClick: onDetails(row, DETAILS_ACTIONS.RIGETTA) })
            }

            if (showModifica) {
              actions.push({ label: 'Modifica', onClick: onDetailsFo(row, DETAILS_ACTIONS.MODIFICA) })
            }
            return actions
          }}
        />
      </div>
    </PageSection>
  )
}

export default ElencoFormatori
