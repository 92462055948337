import * as React from 'react'
import {Form, Title} from '@patternfly/react-core'
import GenericSpinner from 'src/backoffice/app/components/GenericSpinner'
import GenericEditableTable from 'src/frontend/app/components/GenericEditableTable'
import {VocePrestazione} from 'src/model/VocePrestazione'
import {getVociPrestazioni} from 'src/processes/assicurazioni/VociPrestazioni'
import {Prestazione} from 'src/model/Prestazione'
import {RicercaTabellaUnoByIdDTO} from '@parsec/mspo'
import {getTabellaUnoById} from 'src/processes/assicurazioni/TabellaUno'
import {AvatarContainer, AvatarIcon, Callout, CalloutText, CalloutTitle} from 'design-react-kit'
import GenericInput from 'src/frontend/app/components/GenericInput'
import close from '../../../../../frontend/app/images/x.png'
import {TokenStore} from 'src/keycloak/jwt/TokenStore'
import GenericCard from 'src/backoffice/app/components/GenericCard'
import {columnsTabellaUno, listaAnniRiferimentoTabellaUno} from 'src/processes/assicurazioni/CostantiPolizze'
import {AppUtils} from 'src/app/utils/FormUtils'
import GenericAlert from 'src/frontend/app/components/GenericAlert'
import {getIscrizioneByIdBO} from 'src/processes/ProcessoIscrizione'
import {isCurrentMobileDevice} from 'src/utilities/deviceUtility'

export class FormTabellaUno extends React.Component<any, any> {
    constructor(props) {
      super(props);
      this.state = {
        activeTab: 1,
        tabClick: props.tabClick,
        prevData: props.prevData,
        prevClicked: props.prevClicked,
        polizza: props.polizza,
        visualizzaFlag : props.visualizzaFlag,
        modificaDatiFlag:props.modificaDatiFlag,
        fields: this.props.data,
        errors: {},
        isLoaded : false,
        rowsNames: [],
        mySelection : [],
        checkEmpty: false,
        selectionLength:0,
        total:{},
        totalCompenso:[],
        totalPrestazioni:[],
        totalValoreRischio:[],
        totalValoreOpera:[],
        vrcConaf: 0,
        vopConaf: 0,
        noTabellaUnoFlag: false,
      };
    }


    async componentDidMount(): Promise<void> {

      let name = this.props.name
      let lastname = this.props.lastname
      let initials = this.props.name.charAt(0).toUpperCase()+this.props.lastname.charAt(0).toUpperCase()
      this.setState({name:name, lastname:lastname, initials:initials})
      //Check if (this.props.tabellaUno) is empty

      if(!(Object.keys(this.props.tabellaUno).length === 0 && this.props.tabellaUno.constructor === Object)) {
          let fields = this.state.fields;
          fields["codiceFiscale"] = TokenStore.getInstance().getDecodedTokenJWT().fiscalNumber;
          fields["idPolizza"] = this.props.tabellaUno.idRichiestaCollegata;
          fields["annoRiferimento"] = {label: this.props.tabellaUno.annoRiferimento, value: this.props.tabellaUno.annoRiferimento} ;

          //Conversione base calcolo - Viene passato un numero: 0->anno precedente, 1->anno corrente, 2->anno successivo
          let stringaBaseCalcolo = "In base all'attività dell'ultimo esercizio " + (new Date().getFullYear() - 1);
          if(this.props.tabellaUno.baseCalcolo === 1) stringaBaseCalcolo = "In base alla previsione dell'attività dell'anno in corso "+ new Date().getFullYear();
          if(this.props.tabellaUno.baseCalcolo === 2) stringaBaseCalcolo = "In base alla previsione dell'anno successivo " + (new Date().getFullYear() + 1);
          fields["baseCalcolo"] = {label: stringaBaseCalcolo, value: this.props.tabellaUno.baseCalcolo};

          this.recuperaTabellaPreviousStep(this.props.tabellaUno.id)
          this.setState({fields});

      }
      else {
        let fields = this.state.fields;
        fields["idPolizza"] = this.state.fields.id;
        this.setState({noTabellaUnoFlag: true, vopConaf: this.props.data.vopConaf, vrcConaf: this.props.data.vrcConaf})
      }
      await this.setState({checkEmpty : false, listaAnniRiferimento: listaAnniRiferimentoTabellaUno, mySelection: []})
      if(this.props.data.idIscrizione) {

        await getIscrizioneByIdBO(this.props.data.idIscrizione, null).then((response: any) => {
          if(response.data.iotDTO.numeroIscrizioneOrdine) {
            this.setState({numeroIscrizione: response.data.iotDTO.numeroIscrizioneOrdine})
          }
        })
      }
      window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
      if(!this.state.isLoaded) {
            const keyData = TokenStore.getInstance().getDecodedTokenJWT();
            this.state.fields.nome = keyData.given_name;
            this.state.fields.cognome = keyData.family_name;
            this.state.fields.codiceFiscale = keyData.fiscalNumber;
            this.state.fields.genere = keyData.gender;
            this.state.fields.iniziali = keyData.given_name.charAt(0)+keyData.family_name.charAt(0);
            this.state.fields.dataNascita = "01-01-1990";
      }



        let aree: any[] = [];
        await getVociPrestazioni().then(async (response: any)=> {
                /** Estrarre solo le aree e poi creare un oggetto gerarchico per le prestazioni di ciascuna area */
                await response.map(async (item, index)=> {
                  let area: Prestazione = {
                    id: parseInt(item.codicePrestazione.toString().split(".")[0]),
                    title: item.area,
                    isExpanded: true,
                    data: [
                      {
                        "id": "codicePrestazione",
                        "value": parseInt(item.codicePrestazione.toString().split(".")[0]),
                        "type": "label",
                        "disabled": true,
                        "width": 1
                      },
                      {
                        "id": "areaPrestazione",
                        "value": item.area,
                        "type": "label",
                        "disabled": true,
                        "width": 100
                      },
                      {
                        "id": "codiceParametri",
                        "value": " ",
                        "type": "label",
                        "disabled": true,
                        "width": 30
                      },
                      {
                        "id": "codiceCompetenza",
                        "value": " ",
                        "type": "label",
                        "disabled": true,
                        "width": 30
                      },
                      {
                        "id": "attivitaProf",
                        "value": " ",
                        "type": "label",
                        "disabled": true,
                        "width": 30
                      },
                      {
                        "id": "compenso",
                        "value": " ",
                        "type": "input",
                        "disabled": true,
                        "width": 30
                      },
                      {
                        "id": "nPrestazioni",
                        "value": " ",
                        "type": "input-integer",
                        "disabled": true,
                        "width": 30
                      },
                      {
                        "id": "classeRischio",
                        "value": " ",
                        "type": "input",
                        "disabled": true,
                        "width": 30
                      },
                      {
                        "id": "valoreRischio",
                        "value": " ",
                        "type": "input",
                        "disabled": true,
                        "width": 30
                      },
                      {
                        "id": "valoreOpera",
                        "value": " ",
                        "type": "label",
                        "disabled": true,
                        "width": 30
                      },
                      {
                        "id": "valoreOperaTotale",
                        "value": " ",
                        "type": "input",
                        "disabled": true,
                        "width": 30
                      }
                    ],
                    children: []
                  }
                  await aree.push(area)
                })

                await aree.sort((p1, p2) => (p1.id > p2.id) ? 1 : (p1.id < p2.id) ? -1 : 0);
                const tmp = new Map()
                /**  Delete duplicates*/
                aree = await aree.reduce((acc, e) => {
                  if(tmp.has(e.title)) {
                    if (!tmp.get(e.title).includes(e.jobid)) {
                      acc.push(e)
                      tmp.set(e.title, [...tmp.get(e.title), e.title])
                    }
                  } else {
                    acc.push(e)
                    tmp.set(e.title, [e.jobid])
                  }
                  return acc
                }, [])
                /*
                areilter((item,
                  index) => aree.indexOf(item).title === index)
                */
                await aree.map(async (area, index)=> {
                      let childItems: any = [];
                      await response.map(async (responseItem, index)=> {
                            if(responseItem.area === area.title) {
                              let child: VocePrestazione = {
                                id: responseItem.idVocePrestazione,
                                ordinamento: responseItem.ordinamento,
                                data: [
                                  {
                                    "id": "codicePrestazione",
                                    "value": responseItem.codicePrestazione,
                                    "type": "label",
                                    "disabled": true,
                                    "width": 0.5
                                  },
                                  {
                                    "id": "areaPrestazione",
                                    "value": responseItem.prestazione,
                                    "type": "label",
                                    "disabled": true,
                                    "width": 300
                                  },
                                  {
                                    "id": "codiceParametri",
                                    "value": responseItem.codiceParametri,
                                    "type": "label",
                                    "disabled": true,
                                    "width": 30
                                  },
                                  {
                                    "id": "codiceCompetenza",
                                    "value": responseItem.codiceCompetenza,
                                    "type": "label",
                                    "disabled": true,
                                    "width": 30
                                  },
                                  {
                                    "id": "attivitaProf",
                                    "value": "True",
                                    "type": "label",
                                    "disabled": false,
                                    "width": 30
                                  },
                                  {
                                    "id": "compenso",
                                    "value": 0,
                                    "type": "input",
                                    "disabled": false,
                                    "width": 30,
                                    "valid": true,

                                  },
                                  {
                                    "id": "nPrestazioni",
                                    "value": 0,
                                    "type": "input-integer",
                                    "disabled": false,
                                    "width": 30,
                                    "valid": true,

                                  },
                                  {
                                    "id": "classeRischio",
                                    "value": responseItem.classeRischio,
                                    "type": "label",
                                    "disabled": true,
                                    "width": 30
                                  },
                                  {
                                    "id": "valoreRischio",
                                    "value": 0,
                                    "type": "label",
                                    "disabled": true,
                                    "width": 30
                                  },
                                  {
                                    "id": "valoreOpera",
                                    "value": 0,
                                    "type": "input",
                                    "disabled": false,
                                    "width": 80,
                                    "valid": true,

                                  },
                                  {
                                    "id": "valoreOperaTotale",
                                    "value": 0,
                                    "type": "label",
                                    "disabled": true,
                                    "width": 80
                                  }
                                ],
                              }
                              await childItems.push(child)
                            }
                      })
                      await childItems.sort((p1, p2) => (p1.ordinamento > p2.ordinamento) ? 1 : (p1.ordinamento < p2.ordinamento) ? -1 : 0);
                      area.children = childItems;

                })
                await this.setState({rowsNames: aree})

         }).catch((err) => {
              this.setState({ message: err.message, typeMessage: 'danger' })
         })
        await this.setState({isLoaded:true})

    }
    recuperaTabellaPreviousStep = async (tabellaId) => {
      let aree: any[] = [];

      /** Richiamare servizio per recuperare una tabella uno by ID*/
      let tabellaUnoByID: RicercaTabellaUnoByIdDTO = {
        idCompilazioneTabellaRischio: tabellaId
      }
      await getTabellaUnoById(tabellaUnoByID)
      .then(async (response: any)=> {


              if(response !== null) {

                this.state.mySelection.splice(0,this.state.mySelection.length);

                this.setVopVrc(response)
                await this.setState({isLoaded: false})
                await this.setState({tabellaUnoSelezionata: response})
                let fields = this.state.fields;
                fields['idTabellaUno'] = tabellaId;

                await this.setState({ fields });
                //this.props.getSelectedValue(this.state.fields, null)

                await response.elencoVociPrestazione.map(async (item, index)=> {
                        //Creazione area delle prestazioni
                        let area: Prestazione = {
                          id: parseInt(item.vocePrestazione.codicePrestazione.toString().split(".")[0]),
                          title: item.vocePrestazione.area,
                          isExpanded: true,
                          data: [
                            {
                              "id": "codicePrestazione",
                              "value": parseInt(item.vocePrestazione.codicePrestazione.toString().split(".")[0]),
                              "type": "label",
                              "disabled": true,
                              "width": 1
                            },
                            {
                              "id": "areaPrestazione",
                              "value": item.vocePrestazione.area,
                              "type": "label",
                              "disabled": true,
                              "width": 100
                            },
                            {
                              "id": "codiceParametri",
                              "value": " ",
                              "type": "label",
                              "disabled": true,
                              "width": 30
                            },
                            {
                              "id": "codiceCompetenza",
                              "value": " ",
                              "type": "label",
                              "disabled": true,
                              "width": 30
                            },
                            {
                              "id": "attivitaProf",
                              "value": " ",
                              "type": "label",
                              "disabled": true,
                              "width": 30
                            },
                            {
                              "id": "compenso",
                              "value": " ",
                              "type": "input",
                              "disabled": true,
                              "width": 30
                            },
                            {
                              "id": "nPrestazioni",
                              "value": " ",
                              "type": "input-integer",
                              "disabled": true,
                              "width": 30
                            },
                            {
                              "id": "classeRischio",
                              "value": " ",
                              "type": "input",
                              "disabled": true,
                              "width": 30
                            },
                            {
                              "id": "valoreRischio",
                              "value": " ",
                              "type": "input",
                              "disabled": true,
                              "width": 30
                            },
                            {
                              "id": "valoreOpera",
                              "value": " ",
                              "type": "label",
                              "disabled": true,
                              "width": 30
                            },
                            {
                              "id": "valoreOperaTotale",
                              "value": " ",
                              "type": "input",
                              "disabled": true,
                              "width": 30
                            }
                          ],
                          children: []
                        }
                        await aree.push(area)
                      })
                      await aree.sort((p1, p2) => (p1.id > p2.id) ? 1 : (p1.id < p2.id) ? -1 : 0);
                      const tmp = new Map()
                      aree = await aree.reduce((acc, e) => {
                        if(tmp.has(e.id)) {
                          if (!tmp.get(e.id).includes(e.jobid)) {
                            acc.push(e)
                            tmp.set(e.id, [...tmp.get(e.id), e.jobid])
                          }
                        } else {
                          acc.push(e)
                          tmp.set(e.id, [e.jobid])
                        }
                        return acc
                      }, [])

                      aree.map(async (area, index)=> {
                            let childItems: any = [];
                            await response.elencoVociPrestazione.map(async (vocePrestazioneItem, index)=> {
                                if(vocePrestazioneItem.vocePrestazione.area === area.title) {
                                    let child: VocePrestazione = {
                                      id: vocePrestazioneItem.idCompilazioneTabellaRischioDettaglio,
                                      ordinamento: vocePrestazioneItem.vocePrestazione.ordinamento,
                                      data: [
                                        {
                                          "id": "codicePrestazione",
                                          "value": vocePrestazioneItem.vocePrestazione.codicePrestazione,
                                          "type": "label",
                                          "disabled": true,
                                          "width": 1
                                        },
                                        {
                                          "id": "areaPrestazione",
                                          "value": vocePrestazioneItem.vocePrestazione.prestazione,
                                          "type": "label",
                                          "disabled": true,
                                          "width": 100
                                        },
                                        {
                                          "id": "codiceParametri",
                                          "value": vocePrestazioneItem.vocePrestazione.codiceParametri,
                                          "type": "label",
                                          "disabled": true,
                                          "width": 30
                                        },
                                        {
                                          "id": "codiceCompetenza",
                                          "value": vocePrestazioneItem.vocePrestazione.codiceCompetenza,
                                          "type": "label",
                                          "disabled": true,
                                          "width": 30
                                        },
                                        {
                                          "id": "attivitaProf",
                                          "value": vocePrestazioneItem.attivitaFormativa === 1 ? "SI" : "NO",
                                          "type": "label",
                                          "disabled": true,
                                          "width": 30
                                        },
                                        {
                                          "id": "compenso",
                                          "value": vocePrestazioneItem.compensoPrestazione,
                                          "type": "label-currency",
                                          "disabled": true,
                                          "width": 30,
                                          "valid": true,
                                        },
                                        {
                                          "id": "nPrestazioni",
                                          "value": vocePrestazioneItem.numeroPrestazioni,
                                          "type": "label",
                                          "disabled": true,
                                          "width": 30,
                                          "valid": true,
                                        },
                                        {
                                          "id": "classeRischio",
                                          "value": vocePrestazioneItem.vocePrestazione.classeRischio,
                                          "type": "label",
                                          "disabled": true,
                                          "width": 30
                                        },
                                        {
                                          "id": "valoreRischio",
                                          "value": Math.round((vocePrestazioneItem.numeroPrestazioni * vocePrestazioneItem.compensoPrestazione * vocePrestazioneItem.vocePrestazione.classeRischio) * 100) / 100,
                                          "type": "label-it-number",
                                          "disabled": true,
                                          "width": 30
                                        },
                                        {
                                          "id": "valoreOpera",
                                          "value": vocePrestazioneItem.valoreOpera,
                                          "type": "label-currency",
                                          "disabled": true,
                                          "width": 80,
                                          "valid": true,
                                        },
                                        {
                                          "id": "valoreOperaTotale",
                                          "value": (vocePrestazioneItem.valoreOpera * vocePrestazioneItem.numeroPrestazioni),
                                          "type": "label-currency",
                                          "disabled": true,
                                          "width": 80
                                        }
                                      ],
                                    }
                                    await childItems.push(child)
                                  }
                            })
                            area.children = childItems;
                            this.setVociDaTabella(area);


                      });
              }
              else {

                this.setState({ noTabellaUnoFlag: true, vrcConaf: this.state.polizza.vrcConaf, vopConaf: this.state.polizza.vopConaf })

              }
              await this.setState({isLoaded: true})

          }).catch((err) => {
            this.setState({ message: err.message, typeMessage: 'danger', noTabellaUnoFlag: true })
      })

    }


    setVopVrc = async (tabella: any) => {
      this.setState({vopConaf: tabella.vopCalcolato.toFixed(2), vrcConaf: tabella.vrcCalcolato.toFixed(2)})
  }


  setVociDaTabella = async (area: any) => {

      let currentSelection = this.state.mySelection;
      currentSelection.push(area);
      this.setState({mySelection: currentSelection});
  }

  render() {


    const { mySelection,  } = this.state;
    const { tabNumber } = this.props;


    /****************** Form VRC, VOP, Anno riferimento e base del calcolo  ********************/
    const formTabellaUno = [
        <Form id="formTabellaUno" key={"formTabellaUno"} className="row" hidden={(!this.state.fields)}>
            <GenericCard className="cardStyle" key={'tabellaUno'}  header={
                              <div className='justify-content-between d-flex'>
                              <p>{'Tabella 1'}</p>
                              </div>
                            }
            body={
              <>
                  <div className="row mb-5">
                        <div className={isCurrentMobileDevice() ? "p-2" : "col-2"}>
                              <AvatarContainer>
                                  <AvatarIcon color='orange' size='xl'>
                                    <p aria-hidden='true'>{this.state.initials}</p>
                                  </AvatarIcon>
                              </AvatarContainer>
                        </div>
                        <div className={isCurrentMobileDevice() ? "p-2" : "col-4"}>

                              <div><h2>{this.state.name+" "+this.state.lastname}</h2></div>
                              <div><h6>N. Polizza {(this.state.fields.id) ? this.state.fields.id : " Non disponibile"}</h6></div>
                              <div><h6>Iscrizione {(this.state.numeroIscrizione) ? this.state.numeroIscrizione : "-"}</h6></div>
                              <div><h6>Data richiesta: {(this.props.data.dataRichiesta) ? AppUtils.itDate(this.props.data.dataRichiesta.split('T')[0]) : "Oggi"}</h6></div>
                        </div>
                        <div className={isCurrentMobileDevice() ? null : "col-3"} style={{marginTop:'-20px'}}>
                            <Callout color="note">
                              <CalloutTitle><Title headingLevel={"h4"}>VRC</Title></CalloutTitle>
                              <CalloutText bigText>{(this.state.vrcConaf) ? AppUtils.itNumber(this.state.vrcConaf) : AppUtils.itNumber(0)}</CalloutText>
                            </Callout>
                        </div>
                        <div className={isCurrentMobileDevice() ? null : "col-3"} style={{marginTop:'-20px'}}>
                            <Callout color="danger">
                              <CalloutTitle><Title headingLevel={"h4"}>VOP</Title></CalloutTitle>
                              <CalloutText bigText>{(this.state.vopConaf) ? AppUtils.currency(this.state.vopConaf) : AppUtils.currency(0)}</CalloutText>
                            </Callout>
                        </div>
                  </div>
                  <div className="row p-2" hidden={this.state.noTabellaUnoFlag}>
                        <div className={isCurrentMobileDevice() ? null : "col-7"}>
                          <GenericInput isDisabled key={'baseCalcolo'} id="baseCalcolo" label='Base del calcolo *' placeholder='Selezionare'
                              currentStep={tabNumber} defaultValue={(this.state.fields['baseCalcolo']) ? this.state.fields['baseCalcolo'].label : '-'}
                              />
                        </div>
                        <div className={isCurrentMobileDevice() ? null : "col-5"} >
                          <GenericInput isDisabled key={'annoRiferimento'} id="annoRiferimento" label="*" placeholder='Selezionare'
                             currentStep={tabNumber} defaultValue={(this.state.fields['annoRiferimento']) ? this.state.fields['annoRiferimento'].label : '-'}
                          />
                        </div>
                  </div>
                  <div className="row p-2" hidden={!this.state.noTabellaUnoFlag}>
                    <GenericAlert label={"Non è possibile reperire la tabella uno associata a questa polizza."} color={"info"}/>
                  </div>
              </>

            }></GenericCard>
        </Form>
    ];

    const toggle = () =>{
      this.setState({isLoaded: true})

    }

    return(
        <>
        {(this.state.isLoaded) ?
          <div>

              {formTabellaUno}
              <Form id="formTabellaUno" key={"formTabellaUno"} className="row">
                  <div style={{marginLeft: '3px', marginTop: '20px', width: '100%', padding:'0px'}} className="row" hidden={!this.state.fields['annoRiferimento'] || !this.state.fields['baseCalcolo']}>

                      {(this.state.mySelection && !this.state.noTabellaUnoFlag)
                        ?

                            <GenericCard className="cardStyle" key={'tabellaMySelection'} header={
                              <div className='justify-content-between d-flex'>
                              <p>{'Selezionati'}</p>
                              </div>
                            }
                            body={<GenericEditableTable
                              hideActionColumn={true}
                              isHidden={!this.state.mySelection}
                              isBorderless={false}
                              editable={false}
                              className="policy-table"
                              columns={columnsTabellaUno?.map((column) => column.name)}
                              rows={mySelection}
                              iconButton={close}
                              onToggle={toggle}
                              striped
                              ></GenericEditableTable>}>
                            </GenericCard>

                        : null
                      }
                  </div>
              </Form>
          </div>

          : <GenericSpinner></GenericSpinner>
        }
        </>
    )

    }
}

export declare interface GeneralFormTabellaUnoProps {
  data?: any;
  polizza?: any;
  activeTab?: any;
  tabClick?: any;
  visualizzaFlag?: any;
  modificaDatiFlag?: any;
  prevClicked?:any;
  prevData?: any;
  getSelectedValue?: any;
  tabNumber?: any;
  checkEmpty?: any;
  message?: any;
  getValues?: any;
  vopConaf?: any;
  vrcConaf?: any;
  tabellaUno?: any;
}
