import React, {useEffect, useState} from 'react'
import {useHistory} from 'react-router-dom'
import {PageSection, Title} from '@patternfly/react-core'
import {useAgenziaFormativa, useSdaf, useTipologieAttivita, useUserEnte} from 'src/hooks/useReqOptions'
import useFrontOffice from 'src/hooks/useFrontOffice'
import {generateRequestFilter, generateRequestSort} from 'src/app/utils/ApiUtils'
import {GenericAsyncTableData, RequestData} from 'src/backoffice/app/components/GenericAsyncTableData'
import {
    PATH_TO_BO_CFP_EVENTO_CATALOGO,
    PATH_TO_BO_DETTAGLIO_EVENTO_CATALOGO,
    PATH_TO_BO_ELENCO_EVENTI_CATALOGO,
    PATH_TO_BO_ISCRITTI_EVENTO_CATALOGO,
    PATH_TO_FO_DETTAGLIO_EVENTO_CATALOGO,
    PATH_TO_FO_ELENCO_EVENTI_CATALOGO,
    RO_GEST_FORM_CONAF
} from 'src/app/utils/RoutesConstants'
import {
    DETAILS_ACTIONS,
    MODALITA_DISPONIBILI_OPTIONS,
    STATI_EVENTO_CATALOGO,
    STATI_EVENTO_CATALOGO_OPTIONS,
    STATI_EVENTO_CATALOGO_VALUES
} from 'src/formazione'
import {getDefaultAxiosConfig, getFormazioneResourceApi} from 'src/app/utils/ManagerRestGateway'

import GenericBreadCrumb from 'src/frontend/app/components/GenericBreadCrumb'
import {TokenStore} from 'src/keycloak/jwt/TokenStore'
import {getAllEnti, LABEL_ENTI} from 'src/model/EnteFormazione'
import moment from 'moment'

const Spacer = () => (
  <>
    <br></br>
  </>
)

const basePath = { label: 'Home', link: '/' }
const ElencoEventiCatalogo: React.FunctionComponent = () => {
  const history = useHistory()
  const [enti, setListEnti] = useState<any>([])

  useEffect(() => {
    ;(async () => {
      try {
        const entilist = await getAllEnti()
        entilist['CONAF'] = {
          nome: 'CONAF',
          codice: 'CONAF',
          tipo: 'CONAF',
          tipoAgForm: 0,
        }
        setListEnti(entilist)
      } catch (e) {}
    })()
  }, [])

  const isFrontOffice = useFrontOffice()
  const pathToElenco = isFrontOffice ? PATH_TO_FO_ELENCO_EVENTI_CATALOGO : PATH_TO_BO_ELENCO_EVENTI_CATALOGO
  const pathToDettaglio = isFrontOffice ? PATH_TO_FO_DETTAGLIO_EVENTO_CATALOGO : PATH_TO_BO_DETTAGLIO_EVENTO_CATALOGO
  const myPath = {
    label: 'Elenco Eventi a Catalogo',
    link: pathToElenco,
  }

  const sdaf = useSdaf()
  const attivita = useTipologieAttivita()
  const userEnte = useUserEnte()
  const agenzia = useAgenziaFormativa()
  const isProrogata = agenzia?.dataFineProroga && moment(agenzia.dataFineProroga).isSameOrAfter(moment())

  const loadData = async (request: RequestData) => {
    let customFunc = 'msfoFormazioneListaEventiACatalogoForUserGet'
    if (!isFrontOffice) customFunc = 'msfoFormazioneListaEventiACatalogoGet'

    const { data } = await getFormazioneResourceApi()[customFunc](
      generateRequestFilter(request.filters),
      request.pageSize,
      request.currentPage,
      generateRequestSort(request.sortColumn, request.sortDirection, true),
      getDefaultAxiosConfig()
    )

    return {
      items: data.items || [],
      total: data.count || 0,
    }
  }

  const onDetails = (row, modalita) => (e) => {
    history.push({
      pathname: pathToDettaglio,
      search: `?id=${row.idcorso}${modalita ? `&modalita=${modalita}` : ''}`,
    })
  }

  let filters = [
    {
      field: 'organizzatoda',
      label: 'Organizzatore',
      type: 'select',
      options: Object.keys(LABEL_ENTI).map((type) => {
        return {
          label: LABEL_ENTI[type],
          options: Object.keys(enti)
            .filter((code) => enti[code].tipo == type)
            .map((code) => {
              return {
                label: enti[code].nome,
                value: code,
              }
            }),
        }
      }),
    },
    { label: 'Da', field: 'data_inizio_PLACEHOLDER_1', type: 'input', inputType: 'date', operator: 'GTE' },
    { label: 'A', field: 'data_inizio_PLACEHOLDER_2', type: 'input', inputType: 'date', operator: 'LTE' },
    { label: 'Tipologia', field: 'tipo_corso', type: 'select', options: attivita.map((a) => ({ label: a.titolo, value: a.id })) },
    { label: 'SDAF', field: 'settore', type: 'select', options: sdaf.map((s) => ({ label: s.titolo, value: s.id })) },
    {
      label: 'Stato',
      field: 'accreditato',
      type: 'select',
      options: STATI_EVENTO_CATALOGO_OPTIONS,
    },
    {
      label: 'Modalità',
      field: 'modalita',
      type: 'select',
      options: MODALITA_DISPONIBILI_OPTIONS,
    },
  ]

  const currentAccount = TokenStore.getInstance().getCurrentAccount()
  if (!currentAccount || !currentAccount.accounts) {
    filters = [
      {
        field: 'organizzatoda',
        label: 'Organizzatore',
        type: 'select',
        options: Object.keys(LABEL_ENTI).map((type) => {
          return {
            label: LABEL_ENTI[type],
            options: Object.keys(enti)
              .filter((code) => enti[code].tipo == type)
              .map((code) => {
                return {
                  label: enti[code].nome,
                  value: code,
                }
              }),
          }
        }),
      },
      { label: 'Da', field: 'data_inizio_PLACEHOLDER_1', type: 'input', inputType: 'date', operator: 'GTE' },
      { label: 'A', field: 'data_inizio_PLACEHOLDER_2', type: 'input', inputType: 'date', operator: 'LTE' },
      { label: 'Tipologia', field: 'tipo_corso', type: 'select', options: attivita.map((a) => ({ label: a.titolo, value: a.id })) },
      { label: 'SDAF', field: 'settore', type: 'select', options: sdaf.map((s) => ({ label: s.titolo, value: s.id })) },
      {
        label: 'Modalità',
        field: 'modalita',
        type: 'select',
        options: MODALITA_DISPONIBILI_OPTIONS,
      },
    ]
  }

  return (
    <PageSection>
      <div className="container register-container">
        <GenericBreadCrumb paths={[basePath, myPath]} />
        <Spacer />

        <Title headingLevel="h1" size="lg">
          {myPath.label}
        </Title>
        <Spacer />
        <GenericAsyncTableData
          tableKey={'lista.catalogo'}
          emptyMessage={'Nessun evento a catalogo trovato'}
          filters={filters}
          columns={[
            { label: 'Data inizio', field: 'data_inizio', isSortable: true },
            { label: 'Data fine', field: 'data_fine', isSortable: true },
            { label: 'Titolo', field: 'nome_corso', isSortable: true },
            { label: 'Modalità', field: 'modalita', isSortable: true },
            {
              label: 'Organizzatore',
              field: 'organizzatoda',
              isSortable: true,
              render: (row) => (row?.enteFormazione?.name ? row?.enteFormazione?.name : row.organizzatoda),
            },
            {
              label: 'SDAF',
              field: 'settore',
              isSortable: true,
              render: (row) => sdaf.find((s) => s.id == row.settore)?.value,
            },
            {
              label: 'Tipologia',
              field: 'tipo_corso',
              isSortable: true,
              render: (row) => attivita.find((a) => a.id == row.tipo_corso)?.titolo,
            },
            { label: 'Crediti', field: 'crediti', isSortable: true },
            {
              label: 'Stato',
              field: 'accreditato',
              isSortable: true,
              render: (row) => STATI_EVENTO_CATALOGO[row.accreditato],
            },
            {
              label: 'Quota',
              field: 'quota_abbonamento',
              isSortable: true,
              render: (row) => (
                <>
                  <div>{row?.part_con_abb ? `${row?.quota_abbonamento ? `Abb.: ${row?.quota_abbonamento}` : 0} €` : ''}</div>
                  <div>{row?.part_senza_abb ? `${row?.quota_non_abbonati ? `No abb.: ${row?.quota_non_abbonati}` : 0} €` : ''}</div>
                </>
              ),
            },
          ]}
          getRows={loadData}
          getRowActions={(row) => {
            let azioni: any = [{ label: 'Dettaglio', onClick: onDetails(row, DETAILS_ACTIONS.DETTAGLIO) }]

            if (agenzia && agenzia.stato !== 6 && !isProrogata) {
              return [...azioni]
            }

            const stato = row?.accreditato
            const isConafGest = TokenStore.getInstance().currentAccountHasRolesOR([RO_GEST_FORM_CONAF])
            const isOrganizzatore = userEnte?.codiceEnte === row?.organizzatoda || (isConafGest && row?.organizzatoda === 'CONAF')
            const isIscritto = !!row?.iscritto

            //condizione per action "iscriviti"
            const dataFineIscr = row?.data_chiusura_iscrizione ?? row?.data_inizio
            const today = new Date()
            const formattedToday = today.toISOString().split('T')[0]
            const isDataFineIscrValid = dataFineIscr >= formattedToday

            const showApprova = isConafGest && stato === 0
            const showRigetta = isConafGest && stato === 0
            const showIntegrazione = isConafGest && stato === 0
            const showModifica =
              (isOrganizzatore &&
                ![STATI_EVENTO_CATALOGO_VALUES.RIFIUTATO, STATI_EVENTO_CATALOGO_VALUES.ANNULLATO, STATI_EVENTO_CATALOGO_VALUES.RICHIESTA].includes(stato)) ||
              (isConafGest && (stato === STATI_EVENTO_CATALOGO_VALUES.ACCREDITATO || stato === 0))

            const showAnnulla = isConafGest && stato === 1

            const showClonazione = isOrganizzatore

            if (isFrontOffice) {
              azioni = [...azioni]

              const currentAccount = TokenStore.getInstance().getCurrentAccount()
              if (currentAccount && currentAccount.accounts && isDataFineIscrValid) {
                if (!isIscritto) {
                  azioni.push({ label: 'Iscriviti', onClick: onDetails(row, DETAILS_ACTIONS.ISCRIVI) })
                } else {
                  azioni.push({ label: 'Cancella Iscrizione', onClick: onDetails(row, DETAILS_ACTIONS.CANCELLA_ISCRIZIONE) })
                }
              }
            } else {
              azioni = [
                ...azioni,
                stato === 1 && {
                  label: 'Lista Iscritti',
                  onClick: () => {
                    history.push({
                      pathname: PATH_TO_BO_ISCRITTI_EVENTO_CATALOGO,
                      search: `?id=${row.idcorso}`,
                    })
                  },
                },
                showApprova && { label: 'Approva', onClick: onDetails(row, DETAILS_ACTIONS.APPROVA) },
                showRigetta && { label: 'Rigetta', onClick: onDetails(row, DETAILS_ACTIONS.RIGETTA) },
                showIntegrazione && { label: 'Richiedi integrazioni', onClick: onDetails(row, DETAILS_ACTIONS.RICHIESTA_INTEG) },
                showModifica && { label: 'Modifica', onClick: onDetails(row, DETAILS_ACTIONS.MODIFICA) },
                showAnnulla && { label: 'Annulla', onClick: onDetails(row, DETAILS_ACTIONS.ANNULLA) },
                showClonazione && { label: 'Clona', onClick: onDetails(row, DETAILS_ACTIONS.CLONA) },
                stato === 1 &&
                  (isOrganizzatore || isConafGest) && {
                    label: 'Assegnazione CFP',
                    onClick: () => history.push({ pathname: PATH_TO_BO_CFP_EVENTO_CATALOGO, search: `?id=${row.idcorso}` }),
                  },
              ]
            }
            return azioni?.filter((a) => a)
          }}
        />
      </div>
    </PageSection>
  )
}

export default ElencoEventiCatalogo
