import * as React from 'react'
import {EmptyState, EmptyStateBody, EmptyStateIcon, Form} from '@patternfly/react-core'
import {ExclamationTriangleIcon} from '@patternfly/react-icons'
import GenericTextInput from 'src/backoffice/app/components/GenericTextInput'
import GenericSpinner from 'src/backoffice/app/components/GenericSpinner'
import {isCurrentMobileDevice} from 'src/utilities/deviceUtility'

const isMobileDivice = isCurrentMobileDevice();

export class FormRiferimentiExport extends React.Component<GeneralFormRiferimentiExportProps, any> {
    constructor(props) {
        super(props)
        this.state = {
            returnedObject: props.returnedObject,
            messaggio: props.messaggio
        }
    }
    render() {
        const { returnedObject, messaggio } = this.props
        return (
            <>
                {(returnedObject === '' && messaggio === '') ?
                    (
                        <GenericSpinner />
                    )
                    : (messaggio !== '' ? (
                        <EmptyState variant="full">
                            <EmptyStateIcon icon={ExclamationTriangleIcon} />
                            <EmptyStateBody>
                                Non è stato possibile recuperare i dati. Contattare l'assistenza.
                            </EmptyStateBody>
                        </EmptyState>
                    ) : (
                        <div>
                            <Form>
                                <div className='row'>
                                    <div className={!isMobileDivice? 'col-6' : 'col-12' }>
                                        <GenericTextInput type={'text'} className='w-75' id={'idOrdineINIPEC'} label={"ID ordine per INIPEC"} value={'-'} isReadOnly />
                                    </div>
                                </div>
                            </Form>
                        </div>
                    )
                    )}
            </>
        )
    }
}

export declare interface GeneralFormRiferimentiExportProps {
    returnedObject?: any;
    messaggio?: string;
}

