import {
    AnagrafeResourcesApi,
    Configuration,
    CubeResourcesApi,
    IscrizioniOrdineResourceApi,
    PublicResourceApi,
    ReportResourcesApi,
    SocietaResourcesApi,
    StudioAssociatoResourcesApi
} from '@parsec/msga'
import {
    AssicurazioneBOResourceApi,
    AssicurazioneResourceApi,
    AumentoMassimaleResourceApi,
    TabellaUnoResourceApi
} from '@parsec/mspo'
import {
    AllegatoResourcesApi,
    CambioSezioneResourceApi,
    IscrizioneCommonResourceApi,
    IscrizioneIscrittoResourceApi,
    IscrizioneOConafResourceApi,
    IscrizioneOOTResourceApi,
    IscrizioneOSTPResourceApi,
    IscrizioneSTPResourceApi,
    ProvvedimentiDisciplinariResourceApi,
    TipologiaIscrizioneResourcesApi,
    TodoEventResourceApi,
    TrasferimentoOrdineResourceApi
} from '@parsec/mswf'
import {
    FormazioneResourceApi,
    IscrizioneOEEResourceApi,
    IscrizioneOEFResourceApi,
    IscrizioneOERResourceApi
} from '@parsec/msfo'
import {TokenStore} from '../../keycloak/jwt/TokenStore'

export function getDefaultAxiosConfig() {
  return {
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Methods": "POST",
      "Access-Control-Allow-Credentials": true,
      "Content-Type": "application/json",
      "Authorization": 'Bearer ' + TokenStore.getInstance().getTokenJWT(),
      "currentAccount": JSON.stringify(TokenStore.getInstance().getCurrentAccount())
    }
  }
}

function getRestGatewayConfiguration(msname) {
  var restGatewayConfiguration = new Configuration();

  if (msname === "mswf" && process.env.REACT_APP_DEBUG_MSWF !== undefined && process.env.REACT_APP_DEBUG_MSWF !== null && process.env.REACT_APP_DEBUG_MSWF !== "")
    restGatewayConfiguration.basePath = process.env.REACT_APP_DEBUG_MSWF;

  else if (msname === "msga" && process.env.REACT_APP_DEBUG_MSGA !== undefined && process.env.REACT_APP_DEBUG_MSGA !== null && process.env.REACT_APP_DEBUG_MSGA !== "")
    restGatewayConfiguration.basePath = process.env.REACT_APP_DEBUG_MSGA;

  else if (msname === "mspo" && process.env.REACT_APP_DEBUG_MSPO !== undefined && process.env.REACT_APP_DEBUG_MSPO !== null && process.env.REACT_APP_DEBUG_MSPO !== "")
    restGatewayConfiguration.basePath = process.env.REACT_APP_DEBUG_MSPO;

  else if (msname === "msfo" && process.env.REACT_APP_DEBUG_MSFO !== undefined && process.env.REACT_APP_DEBUG_MSFO !== null && process.env.REACT_APP_DEBUG_MSFO !== "")
    restGatewayConfiguration.basePath = process.env.REACT_APP_DEBUG_MSFO;

  else
    restGatewayConfiguration.basePath = process.env.REACT_APP_API_URL

    return restGatewayConfiguration;
}

// FUNZIONI ESPORTATE PER LE RESOURCE API
export function getProcessoIscrizioneResourceApi() { return new IscrizioneIscrittoResourceApi(getRestGatewayConfiguration("mswf")); }
export function getProvvedimentiResourceApi() { return new ProvvedimentiDisciplinariResourceApi(getRestGatewayConfiguration("mswf")); }
export function getAllegatoResourcesApi() { return new AllegatoResourcesApi(getRestGatewayConfiguration("mswf")); }
export function getAnagrafeResourcesApi() { return new AnagrafeResourcesApi(getRestGatewayConfiguration("msga")); }
export function getPublicResourcesApi() { return new PublicResourceApi(getRestGatewayConfiguration("msga")); }
export function getIscrizioneOrdineResourceApi() { return new IscrizioniOrdineResourceApi(getRestGatewayConfiguration("msga")); }
export function getTipologiaIscrizioneResourcesApi() { return new TipologiaIscrizioneResourcesApi(getRestGatewayConfiguration("mswf")); }
export function getIscrizioneCommonResourceApi() { return new IscrizioneCommonResourceApi(getRestGatewayConfiguration("mswf")); }
export function getIscrizioneStpResourceApi() { return new IscrizioneSTPResourceApi(getRestGatewayConfiguration("mswf")); }
export function getIscrizioneOOTResourceApi() { return new IscrizioneOOTResourceApi(getRestGatewayConfiguration("mswf")); }
export function getIscrizioneOConafResourceApi() { return new IscrizioneOConafResourceApi(getRestGatewayConfiguration("mswf")); }
export function getIscrizioneOSTPResourceApi() { return new IscrizioneOSTPResourceApi(getRestGatewayConfiguration("mswf")); }
export function getTrasferimentoOrdineResourceApi() { return new TrasferimentoOrdineResourceApi(getRestGatewayConfiguration("mswf")); }
export function getCambioSezioneResourceApi() { return new CambioSezioneResourceApi(getRestGatewayConfiguration("mswf")); }
export function getSocietaResourceApi() { return new SocietaResourcesApi(getRestGatewayConfiguration("msga")); }
export function getStudioAssociatoResourceApi() { return new StudioAssociatoResourcesApi(getRestGatewayConfiguration("msga")); }
export function getAssicurazioneResourceApi() { return new AssicurazioneResourceApi(getRestGatewayConfiguration("mspo")); }
export function getAssicurazioneBOResourceApi() { return new AssicurazioneBOResourceApi(getRestGatewayConfiguration("mspo")); }
export function getAumentoMassimaleResourceApi() { return new AumentoMassimaleResourceApi(getRestGatewayConfiguration("mspo")); }
export function getTabellaUnoResourceApi() { return new TabellaUnoResourceApi(getRestGatewayConfiguration("mspo")); }
export function getFormazioneResourceApi() { return new FormazioneResourceApi(getRestGatewayConfiguration("msfo")); }
export function getIscrizioneOEFResourceApi() { return new IscrizioneOEFResourceApi(getRestGatewayConfiguration("msfo")); }
export function getIscrizioneOEEResourceApi() { return new IscrizioneOEEResourceApi(getRestGatewayConfiguration("msfo")); }
export function getIscrizioneOERResourceApi() { return new IscrizioneOERResourceApi(getRestGatewayConfiguration("msfo")); }
export function getCubesResourceApi() { return new CubeResourcesApi(getRestGatewayConfiguration("msga")); }
export function getMsgaReportResourceApi() {return new ReportResourcesApi(getRestGatewayConfiguration("msga")); }
export function getTodoEventResourceApi() { return new TodoEventResourceApi(getRestGatewayConfiguration("mswf")); }
