import {Button} from 'design-react-kit'
import * as React from 'react'
import {useHistory} from 'react-router-dom'
import GenericButton from '../../components/GenericButton'
import GenericBreadCrumb from 'src/frontend/app/components/GenericBreadCrumb'
import GenericChips from '../../components/GenericChips'
import GenericTitle from '../../components/GenericTitle'
import './style.scss'
import {TokenStore} from 'src/keycloak/jwt/TokenStore'
import {cancellaPolizzaFO} from 'src/processes/assicurazioni/Assicurazione'
import {PATH_TO_FO_ASSICURAZIONI, PATH_TO_FO_ASSICURAZIONI_CANCELLA_POLIZZA} from 'src/app/utils/RoutesConstants'
import GenericModal from 'src/backoffice/app/components/GenericModal'
import {isCurrentMobileDevice} from 'src/utilities/deviceUtility'

const breadcrumbOptions = [
  { label: "Assicurazioni / Lista richieste", link: PATH_TO_FO_ASSICURAZIONI },
  { label: "Cancella", link: PATH_TO_FO_ASSICURAZIONI_CANCELLA_POLIZZA }
]

export default class CancellaPolizza extends React.Component<any, any>{
  constructor(props){
    super(props);
    this.state = {
      activeTab: 1,
      fields:{}
    }
  }
  componentDidMount(): void {
    if(this.props.location.state?.polizza !== undefined && this.props.location.state.polizza.id) {

      const keyData = TokenStore.getInstance().getDecodedTokenJWT();
      let fiscalNumber = keyData.fiscalNumber;
      //let fiscalNumber = "ISDANFIADASFO";
      let given_name = keyData.given_name;
      //let given_name = "Mario";
      let family_name = keyData.family_name;
      //let family_name = "Rossi";

      this.setState({fields: {
        nome: given_name,
        cognome: family_name,
        codiceFiscale: fiscalNumber,
      }})
    }  else {
      window.location.href = PATH_TO_FO_ASSICURAZIONI
    }

  }
  cancellaPolizza = () => {
    var parametriCancella = {
      idPolizza: this.props.location.state.polizza.id,
      note: "Polizza cancellata dal titolare",
  }
  cancellaPolizzaFO(parametriCancella).then(responseCancella => {
    window.location.href = PATH_TO_FO_ASSICURAZIONI

  }).catch((err) => {
    this.showModal(true, "Errore",err.message);
    //alert("Si è verificato un errore.");
  })
  }

  closeModal = () => {
      this.setState({modalOpen:false});
      window.location.href = PATH_TO_FO_ASSICURAZIONI;
  }

  showModal = async (booleanValue, title,text) => {
      await this.setState({
          modalOpen:booleanValue,
          modalTitle: title,
          modalText:text,
          isLoaded: true,
      });
  }
  render() {
    var title = "Eliminazione richiesta assicurativa"
    const message = "Si sta cancellando una polizza. Sicuro di voler procedere?"

    function GoHome() {
      const history = useHistory();
      const handleClick = () => {
        history.push(PATH_TO_FO_ASSICURAZIONI);
      };
      return (
        <Button onClick={handleClick}>Indietro</Button>
      );
    }



   return(

      <div key="eliminaPolizzaFO" className={isCurrentMobileDevice() ? 'container register-container-zero-padding' : 'container-fluid register-container'}>
          <div className={isCurrentMobileDevice() ? "'container register-container-zero-padding'" : 'row container-fluid register-container p-4'}>
            <div className='col-1'>&nbsp;</div>
                <GenericBreadCrumb key="genericTab" paths={breadcrumbOptions} />
                <div className={isCurrentMobileDevice() ? null : "d-flex justify-content-between ipi-title"} hidden={this.state.selectedPolizza === undefined}>
                                <GenericTitle Size="h1" title={title}></GenericTitle>
                                <GenericChips label={"Polizza "+this.props.location.state.polizza.id}></GenericChips>
                </div>
                <div className={isCurrentMobileDevice() ? null : "row"}>
                    <div className={isCurrentMobileDevice() ? null : "col warning-message"}>
                        <GenericTitle title={message} Size="h4"/>
                    </div>
                </div>
                <GenericModal
                            title={this.state.modalTitle}
                            text={this.state.modalText}
                            modalOpen={this.state.modalOpen}
                            closeButton={this.closeModal}
                    />
                <div className='row'>
                    <div className={isCurrentMobileDevice() ? null :'col-1'}>
                        <GoHome></GoHome>
                    </div>
                    <div className={isCurrentMobileDevice() ? null :'col-1'}>
                        <GenericButton form={"cancellaPolizzaItem"} type={"button"} onClickEvent={this.cancellaPolizza} label={"Conferma"} color={"primary"} />
                    </div>
                </div><br></br>
          </div>
      </div>
   )
  }
};

export declare interface CancellaProps {
  activeTab?: any;
}

