import {Button} from '@patternfly/react-core'
import React from 'react'

class GenericButton extends React.Component<GeneralButtonProps, any>{
    constructor(props) {
        super(props);
        this.state = {
          label: props.label,
          onClick: props.onClick,
          type: props.type,
          color: props.color,
          isDisabled: props.isDisabled,
          className: props.className,
          id: props.id,
          form: props.form,
          href: props.href,
          hidden: props.hidden,

        };
    }

    render() {
        const { label, onClick, type, color, className, id, form, href } = this.state;
        return (
          <Button href={href} form={form} id={id} variant={color} type={type} onClick={onClick} isDisabled={this.props.isDisabled} hidden={this.props.hidden} className={className}>{label}</Button>
        );
      }
    }

    export declare interface GeneralButtonProps {
        label?: any;
        onClick?: any;
        type?: any;
        color?: any;
        isDisabled?: boolean;
        className?: any;
        id?: any;
        form?: any;
        href?: any;
        hidden?: any;
    }

    export default GenericButton;
