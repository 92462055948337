import React from 'react'
import {goTo} from 'src/utilities/utility'
import {PATH_TO_HOME} from 'src/app/utils/RoutesConstants'
import GenericButton from 'src/frontend/app/components/GenericButton'
import {ErrorStore} from './ErrorStore'
import GenericAlert from 'src/frontend/app/components/GenericAlert'

class GenericError extends React.Component<any,any> {

    constructor(props) {
        super(props);
        this.state = {
            nota: '',
            message: ErrorStore.getInstance().getMessageError(),
            title: ErrorStore.getInstance().getErrorTitle(),
            backUrl: ErrorStore.getInstance().getBackUrl(),
            restCall: ErrorStore.getInstance().getRestCall(),
            errorMessage: '',
        };
    }

    componentDidMount(): void {
    }

    render() {

        return (
              <div  className='container register-container p-4'>
                <h2 className="text-danger">{this.state.title}</h2>
                  <GenericAlert /*size={'inherit'}*/ label={this.state.message} color='danger'/>
                  <br></br>
                  <div className='row p-2'>
                    <div className='col-1'>
                          <GenericButton label={"Indietro"} color={"secondary"} onClickEvent={() => goTo(this.state.backUrl)} />
                    </div>
                    <div className='col-3'>
                      <GenericButton label={"Torna nella home page"} color={"tertiary"} onClickEvent={() => goTo(PATH_TO_HOME)} />

                    </div>

                  </div>

              </div>
        )
    }
}

export default GenericError;
