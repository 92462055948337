import * as React from 'react'
import {PageSection, Title} from '@patternfly/react-core'
import GenericBreadCrumb from 'src/frontend/app/components/GenericBreadCrumb'
import GenericCard from '../../components/GenericCard'
import GenericButton from '../../components/GenericButton'
import {FormCancellaIscritto} from './formIscrizione/FormCancellaIscritto'
import {getMotiviCancellazioneIscrittoBO} from 'src/processes/MotiviCancellazione'
import {
    PATH_TO_BO_ANAG_ELENCO_ISCRITTI,
    PATH_TO_BO_ANAG_ELENCO_ISCRITTI_CANCELLA_ISCRITTO
} from 'src/app/utils/RoutesConstants'
import GenericAlert from 'src/frontend/app/components/GenericAlert'
import {convertUrlParamsToObject} from 'src/utilities/utility'

class CancellaIscritto extends React.Component<any, any> {
    constructor(props) {
        super(props);
        this.state = {
            message: "",
            typeMessage: "default",
            listaMotiviCancellazione: []
        };
    }

    goToElencoIscritti = (redirectLink: any) => {
        this.props.history.push({
            pathname: redirectLink,
        });
    }

    componentDidMount() {
        //recupero gli eventuali parametri dall'url
        var url = window.location.search?.split("?")[1]
        var urlParams = convertUrlParamsToObject(url)

        //recupero l'idIscrizione dall'url
        var idIscrizione = urlParams?.idIscrizione

        this.setState({ idIscrizione: idIscrizione })

        //chiamata al servizio 'getMotiviCancellazioneIscritto' che recupera la lista dei motivi di cancellazione dell'iscritto
        getMotiviCancellazioneIscrittoBO().then((response: any) => {
            var motiviCancellazione = [{ value: '', label: '' }];
            motiviCancellazione[0] = { value: '', label: "Selezionare un'opzione" }
            if (response)
                response.data.map((motivo, id) => {
                    return (
                        motiviCancellazione[id + 1] = {
                            label: motivo.descrizione,
                            value: motivo.descrizione
                        });
                })
            this.setState({ listaMotiviCancellazione: motiviCancellazione })
        })
    }

    submitMessageResult = (alert: any) => {
        const element = document.getElementById("pageSectionId");
        if (element)
            element.scrollIntoView({ behavior: 'smooth' });
        this.setState({ message: alert.messaggio, typeMessage: alert.type })
        if (alert.type === 'success') {
            setTimeout(async () => {
                this.goToElencoIscritti(PATH_TO_BO_ANAG_ELENCO_ISCRITTI)
            }, 1000);
        }
    }

    render() {
        const { message, typeMessage, idIscrizione, listaMotiviCancellazione } = this.state
        return (
            <>
                <PageSection id='pageSectionId'>
                    <div className='container register-container'>
                        <GenericBreadCrumb paths={[{ label: "Anagrafica", link: "#" }, { label: "Elenco iscritti", link: PATH_TO_BO_ANAG_ELENCO_ISCRITTI }, { label: "Cancella iscritto", link: PATH_TO_BO_ANAG_ELENCO_ISCRITTI_CANCELLA_ISCRITTO }]} /><br></br>
                        <Title headingLevel="h1" size="4xl">Cancellazione iscritto</Title><br></br>
                        <GenericAlert hidden={message === ''} label={message} color={typeMessage}></GenericAlert>
                        <GenericCard isExpandibleCard={false} header={'Compila i seguenti campi'} body={<><FormCancellaIscritto messaggio={this.submitMessageResult} idIscrizione={idIscrizione} motiviCancellazione={listaMotiviCancellazione} /></>} /><br></br>
                        <div className='row'>
                            <div className='col-1'>
                                <GenericButton label={"INDIETRO"} color={"tertiary"} onClick={() => this.goToElencoIscritti(PATH_TO_BO_ANAG_ELENCO_ISCRITTI)} />
                            </div>
                            <div className='col-1'>
                                <GenericButton label={"CONFERMA"} type={"submit"} form={"idCancellaIscritto"} color={"primary"} isDisabled={this.state.typeMessage === 'success'} />
                            </div>
                        </div>
                    </div>
                </PageSection>
                <br></br>
                <PageSection>
                    <div className='container register-container'>
                        <Title headingLevel='h3'>Legenda</Title>
                        <p>* Campo obbligatorio</p>
                    </div>
                </PageSection>
            </>
        )
    }
}

export default CancellaIscritto;
