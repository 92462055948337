import {getDefaultAxiosConfig, getSocietaResourceApi} from 'src/app/utils/ManagerRestGateway'
import {ErrorStore} from 'src/errorBoundary/ErrorStore'

/**
 * GET soci by idSocieta
 * @returns
 * @param idSocieta
 */
export async function getComposizioneSocietariaByIdSocietaFO(idSocieta: number) {
    try {
      var param = {
        idSocietaPG: idSocieta,
      }
      const response = await getSocietaResourceApi().msgaSocietaFoFindSociByIdSocietaPost(param, getDefaultAxiosConfig());
      //Manca il ResponseBuilder su Backend

      return response;
    } catch (err:any) {
      console.error("Errore durante la chiamata per il recupero della società. " + err);
      throw new Error("Errore durante la chiamata per il recupero della società. " + err)
    }
}

/**
   * GET soci by idSocieta
   * @returns
   * @param idSocieta
*/
export async function getSociByIdSocietaBO(idSocieta: number) {
      try {
        var param = {
          idSocietaPG: idSocieta,
        }
        const response = await  getSocietaResourceApi().msgaSocietaBoFindSociByIdSocietaPost(param, getDefaultAxiosConfig());
        //Manca il ResponseBuilder su Backend
        return response;
      } catch (err: any) {

        console.error("Errore durante la chiamata per il recupero della società. " + err);
        throw new Error("Errore durante la chiamata per il recupero della società. " + err)
      }
}

/**
 * GET Rappresentante legale by id societa
 * @returns
 * @param idSocieta
*/
export async function getRappresentanteLegaleByIdSocietaFO(idSocieta: number) {
    try {
      var param = {
        idSocietaPG: idSocieta,
      }
      const response =  await getSocietaResourceApi().msgaSocietaFoFindLegaleRappresentanteByIdSocietaPost(param, getDefaultAxiosConfig());
      //Manca il ResponseBuilder su Backend
      return response;

    } catch (err:any) {
      console.error("Errore durante la chiamata per il recupero del rappresentante legale della società. " + err);
      throw new Error("Errore durante la chiamata per il recupero del rappresentante legale della società. " + err);

    }
}

/**
* GET Rappresentante legale by id societa
* @returns
* @param idSocieta
*/
export async function getRappresentanteLegaleByIdSocietaBO(idSocieta: number) {
    try {
      var param = {
        idSocietaPG: idSocieta,
      }
      const response =  await getSocietaResourceApi().msgaSocietaBoFindLegaleRappresentanteByIdSocietaPost(param, getDefaultAxiosConfig());
      //Manca il ResponseBuilder su Backend
      return response;

    } catch (err:any) {
      let errorMessage: any = ErrorStore.getInstance().showAlert("Errore durante la chiamata per il recupero del rappresentante legale della società. ")
      console.error(errorMessage.title + ". Errore durante la chiamata per il recupero del rappresentante legale della società. " + errorMessage.message);
      throw new Error(errorMessage.title + ". Errore durante la chiamata per il recupero del rappresentante legale della società. " + errorMessage.message);
    }
}
