import React, {useCallback, useState} from 'react'
import {Label} from 'design-react-kit'
import FormSubmit, {STATUS} from '../../../../../frontend/app/components/Form/GenericSubmit'
import {getDefaultAxiosConfig, getFormazioneResourceApi} from 'src/app/utils/ManagerRestGateway'
import {STATI_EVENTO_CATALOGO_VALUES} from 'src/formazione'
import {useHistory} from 'react-router-dom'

const FormAnnullamento: React.FunctionComponent<FormAnnullamentoProps> = ({ id }) => {
  const [status, setStatus] = useState<any>()
  const [alertMessage, setAlertMessage] = useState<any>()
  const history = useHistory()

  const submit = useCallback(
    async (e) => {
      try {
        // TODO EVENTO CATALOGO API check
        setStatus(STATUS.LOADING)
        await getFormazioneResourceApi().msfoFormazioneAggiornaEventoACatalogoPatch(
          {
            idcorso: id,
            accreditato: STATI_EVENTO_CATALOGO_VALUES.ANNULLATO,
          },
          getDefaultAxiosConfig()
        )
        setStatus(STATUS.SUCCESS)
        setAlertMessage('Dati salvati con successo')
        setTimeout(() => {
          history.goBack()
        }, 500)
      } catch (e) {
        setStatus(STATUS.FAILURE)
        setAlertMessage('Impossibile salvare i dati')
      }
    },
    [id]
  )

  return (
    <FormSubmit title="Annullamento evento" buttonLabel="CONFERMA" alertMessage={alertMessage} status={status} onSubmit={submit}>
      <Label>Per annullare l'evento cliccare "Conferma".</Label>
    </FormSubmit>
  )
}

interface FormAnnullamentoProps {
  id: any
}

export default FormAnnullamento
