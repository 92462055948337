import * as React from 'react'
import {Form} from '@patternfly/react-core'
import {getPolizzaById, modificaValiditaPolizza} from 'src/processes/assicurazioni/Assicurazione'
import GenericTextInput from 'src/backoffice/app/components/GenericTextInput'
import GenericSpinner from 'src/backoffice/app/components/GenericSpinner'
import {PATH_TO_BO_POLIZZE_ELENCO} from 'src/app/utils/RoutesConstants'
import GenericModal from 'src/backoffice/app/components/GenericModal'
import {Label} from 'design-react-kit'
import {inputRequiredValidation} from 'src/utilities/utility'
import {isCurrentMobileDevice} from 'src/utilities/deviceUtility'

const isMobileDevice = isCurrentMobileDevice();
export class FormModificaPolizza  extends React.Component<any, any> {
    constructor(props) {
        super(props);
        this.state = {
            dateFrom: "",
            dateTo: "",
            polizzaIndividuale: false,
            isLoaded: false,

        };
      }
      async componentDidMount(): Promise<void> {

        var parametriModifica = {
            "idPolizza": this.props.idPolizza.toString(),
        }

        await getPolizzaById(parametriModifica.idPolizza).then(async (response: any)=> {

            let periodoValDaDb = "";
            let periodoValADb = "";
            if(response.associatoConConaf === 1) {
                periodoValDaDb = response.periodoValDaConaf;
                periodoValADb = response.periodoValAConaf;
            }
              else {
                periodoValDaDb = response.periodoValDaAltri;
                periodoValADb = response.periodoValAAltri;
            }

            periodoValDaDb = periodoValDaDb.split("T")[0];
            periodoValADb = periodoValADb.split("T")[0];

            await this.setState({dateFrom : periodoValDaDb, dateTo : periodoValADb})

            if(response.associatoConAltri === 1) this.setState({polizzaIndividuale: true})
          }).catch(() => {
                    setTimeout(() => {
                        this.setState({ message: "C'è stato un errore durante il recupero dei dati", typeMessage: 'danger' })
                    }, 3000);
                })
                this.setState({ message: "C'è stato un errore durante il recupero dei dati", typeMessage: 'danger', isLoaded : true })

    }

    handleSubmit = async event => {
        var requiredFields = [
            { value: event.target.idDataModificaInizioPolizza.value, label: 'Data inizio validità' },
            { value: event.target.idDataModificaFinePolizza.value, label: 'Data fine validità' }
        ]

        var errorRequired: any = inputRequiredValidation(requiredFields)

        if (errorRequired !== undefined && errorRequired !== "") {
            event.preventDefault()
            return this.props.messaggio({ messaggio: errorRequired.messaggio, type: errorRequired.type })
        } else {
            await this.setState({isLoaded:false});

            var parametriModifica = {
                "idPolizza": this.props.idPolizza.toString(),
                "dateFrom": event.target.idDataModificaInizioPolizza.value,
                "dateTo": event.target.idDataModificaFinePolizza.value
            }
            event.preventDefault();

            try {
                event.preventDefault();
                this.setState({ dateFrom: parametriModifica.dateFrom, dateTo: parametriModifica.dateTo })

                await modificaValiditaPolizza(parametriModifica, this.state.timeFrom, this.state.timeTo).then(response => {
                })
                await this.setState({ errorFlag: false })
                this.props.messaggio({ messaggio: "Dati salvati con successo", type: "success" })
                await this.showModal(true, "Messaggio", "Le date di validità sono state aggiornate.");
                await this.setState({ isLoaded: true })
                return false;
            }
            catch (err: any) {
                event.preventDefault();
                await this.setState({ errorFlag: true })

                await this.showModal(true, "Errore", err.message);
                return false;
            }
        }
    }
    closeModal = () => {
        this.setState({modalOpen:false});
        if(this.state.errorFlag === false) window.location.href = PATH_TO_BO_POLIZZE_ELENCO;
    }

    showModal = async (booleanValue, title,text) => {
        await this.setState({
            modalOpen:booleanValue,
            modalTitle: title,
            modalText:text,
            isLoaded: true,
        });
    }
    render() {
        return (
            <div>
                {this.state.isLoaded ? <Form id='idModificaPolizza' onSubmit={this.handleSubmit}>
                <div className='row'>
                        <div className='col-11'>
                            In caso di modifica ad una adesione alla polizza collettiva per la quale è già stato emesso il certificato lo
                            stesso dovrà essere rigenerato.
                            La modifica dovrà essere comunicata tramite PEC all’iscritto con il nuovo certificato in allegato se
                            applicabile.
                            La modifica delle date deve essere storicizzata al pari del certificato.

                        </div>
                        <br></br>
                    </div>
                    <div className="row p-3">
                        <div className={!isMobileDevice ? 'col-4' : 'col-12' } >
                           <Label>Data inizio validità</Label>
                            <div className={'d-flex'} >
                            <GenericTextInput isDisabled={this.state.polizzaIndividuale} type={'date'} id={'idDataModificaInizioPolizza'} onChange={(event) => this.setState({dateFrom: event.target.value})} placeholder={'Selezionare data'} isRequired defaultValue={this.state.dateFrom} />
                            <GenericTextInput isDisabled type={'time'} id={'idTimeInizio'} value={"00:00"} />
                            </div>
                        </div>
                        <div className={!isMobileDevice ?  'col-4 offset-1' : 'col-12'}>
                        <Label>Data fine validità</Label>
                        <div className={'d-flex'} >
                           <GenericTextInput isDisabled={this.state.polizzaIndividuale} type={'date'} id={'idDataModificaFinePolizza'} onChange={(event) => this.setState({dateTo: event.target.value})} placeholder={'Selezionare data'} isRequired defaultValue={this.state.dateTo} />
                           <GenericTextInput isDisabled type={'time'} id={'idTimeInizio'} value={"23:59"} />

                           </div>
                        </div>
                    </div>
                    <GenericModal
                          title={this.state.modalTitle}
                          text={this.state.modalText}
                          modalOpen={this.state.modalOpen}
                          closeButton={this.closeModal}
                    />

                </Form> : <GenericSpinner/>}
            </div>
        )
    }
}


export declare interface GeneralFormModificaPolizzaProps {
    messaggio?: any;
    idPolizza?: any;
    idSoggetto?: any;
    polizza?:any;
    periodoValDa?:any;
    periodoValA?:any;
}
