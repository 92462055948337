import {Form, Icon} from 'design-react-kit'
import * as React from 'react'
import GenericButton from 'src/frontend/app/components/GenericButton'
import GenericInput from 'src/frontend/app/components/GenericInput'
import {getSoggettoIscrittoByCfTipoAnagrafeTipoIscrizione} from 'src/processes/Soggetto'
import {isCurrentMobileDevice} from 'src/utilities/deviceUtility'

export class FormElencoSociIscrittiAlboStpFO extends React.Component<GeneralFormElencoSociIscrittiAlboStpFOProps, any> {
  emptySocio = [
    { idSoggettoPF: null, idSocietaPG: null, isSocio: 1, isLegaleRappresentante: null, isDipendenteIscritto: 0, nome: '', cognome: '', cf: '', titProf: '' },
  ]

  constructor(props) {
    super(props)
    this.state = {
      soci: props.composizioneSocietaria,
    }
  }

  componentDidMount(): void {
    this.props.soci(this.state.soci)
  }

  //metodo che dato il cf di un socio iscritto all'albo restituisce il rispettivo nome, cognome e titolo
  handleChangeCodiceFiscale = (event, id) => {
    var codiceFiscaleDigitato = event.target.value
    var tipoAnagrafe = 'PF'
    var tipologiaIscrizione = 1 //iscritto all'ordine

    this.state.soci[id] = {
      idSoggettoPF: null,
      isSocio: 1,
      idSocietaPG: null,
      isLegaleRappresentante: null,
      isDipendenteIscritto: 0,
      nome: '',
      cognome: '',
      cf: '',
      titProf: '',
    }
    this.setState({ soci: this.state.soci })

    // Verifico che il socio non sia già stato inserito
    var socioFound = false
    this.state.soci.map((socio) => {
      if (!socioFound && socio.cf.toUpperCase() === codiceFiscaleDigitato.toUpperCase() && (socio.cancellato === undefined || socio.cancellato === false))
        socioFound = true
    })

    // Se il socio non è già stato inserito lo cerca e se lo trova lo inserisce
    if (!socioFound) {
      //chiamata al servizio 'getSoggettoIscrittoByCfTipoAnagrafeTipoIscrizione' che restituisce i dati del soggetto a partire dal codice fiscale, tipo anagrafe e tipologia di iscrizione
      getSoggettoIscrittoByCfTipoAnagrafeTipoIscrizione(codiceFiscaleDigitato.toUpperCase(), tipoAnagrafe, tipologiaIscrizione)
        .then(async (responseSoggetto: any) => {
          var idSocio
          if (responseSoggetto.data && responseSoggetto.data.error) throw new Error(responseSoggetto.data.error)
          // Verifico se ho già il codice fiscale nella mappa dei soci
          this.state.soci.map((socio, i) => {
            if (codiceFiscaleDigitato.toUpperCase() === socio.cf) idSocio = i

            if (idSocio !== i) {
              idSocio = id
            }
          })

          this.state.soci[idSocio] = {
            idSoggettoPF: responseSoggetto.data.returnedObject?.idSoggetto,
            idSocietaPG: this.props.idSocieta,
            isSocio: 1,
            isLegaleRappresentante: 0,
            isDipendenteIscritto: 0,
            nome: responseSoggetto.data.returnedObject?.nome,
            cognome: responseSoggetto.data.returnedObject?.cognome,
            cf: codiceFiscaleDigitato,
            titProf: responseSoggetto.data.returnedObject?.titoloRichiesto,
          }
          this.props.soci(this.state.soci)
        })
        .catch((err) => {
          console.error('Errore durante la getSoggettoByCfTipoAnagrafeAndTipoIscrizione: Socio non trovato ', err)
        })
    }
  }

  //metodo che aggiunge un elemento nell'array soci
  aggiungiSocio = (soci: any) => {
    soci?.push({
      idSoggettoPF: null,
      idSocietaPG: null,
      isSocio: 1,
      isLegaleRappresentante: null,
      isDipendenteIscritto: 0,
      nome: '',
      cognome: '',
      cf: '',
      titProf: '',
    })
    this.setState({ soci: soci })
  }

  //metodo che elimina l'elemento dell'array soci
  eliminaSocio = async (soci: any, id: any) => {
    soci[id] = { ...soci[id], isSocio: 1, isDipendenteIscritto: 0, cancellato: true }
    await this.setState({ soci, selectedId: id })
    this.props.soci(this.state.soci)
  }

  render() {
    const { tabNumber, message } = this.props
    const { soci, selectedId } = this.state
    return (
      <div>
        {/*--------------------------------------------------------------------------- FORM PER ISCRIZIONE STP ----------------------------------------------------------------------------*/}
        <Form>
          {soci.map((socio, id) => {
            return socio.cancellato !== true ? (
              <div className="row" key={id}>
                <div className={isCurrentMobileDevice() ? null : 'col-4'}>
                  <GenericInput
                    type={'cf'}
                    id={'idCodiceFiscaleSocio' + id}
                    placeholder={'Inserire codice fiscale'}
                    label={'Codice fiscale del socio iscritto*'}
                    value={socio.cf ? socio.cf : null}
                    currentStep={tabNumber}
                    errorMessageAlert={message}
                    itemIdDeleted={'idCodiceFiscaleSocio' + selectedId}
                    onChange={(e) => this.handleChangeCodiceFiscale(e, id)}
                    isRequired
                    isReadOnly={socio.cf}
                  />
                </div>
                <div className={isCurrentMobileDevice() ? null : 'col-4'} hidden={!socio.cf}>
                  <GenericInput
                    type={'text'}
                    id={'idNomeCognomeSocio' + id}
                    label={'Nome e cognome del socio iscritto'}
                    value={socio.nome + ' ' + socio.cognome}
                    isReadOnly
                  />
                </div>
                <div className={isCurrentMobileDevice() ? null : 'col-3'} hidden={!socio.cf}>
                  <GenericInput
                    type={'text'}
                    id={'idTitolo' + id}
                    label={'Titolo del socio iscritto'}
                    placeholder={' '}
                    value={socio.titProf ? socio.titProf : '-'}
                    isReadOnly
                  />
                </div>
                <div className={isCurrentMobileDevice() ? null : 'col-1'} /*hidden={soci.filter(e => e.isSocio !== 0).length === 1}*/>
                  <GenericButton
                    size="xs"
                    type="button"
                    color="tertiary"
                    onClickEvent={() => this.eliminaSocio(soci, id)}
                    children={<Icon size="sm" className="mt-3" color="danger" icon="it-delete" />}
                  />
                </div>
              </div>
            ) : null
          })}
          <div className="col-6">
            <GenericButton size="xs" type="button" color="primary" label="Aggiungi socio" onClickEvent={() => this.aggiungiSocio(soci)} />
          </div>
        </Form>
        {/*-------------------------------------------------------------------------------------------------------------------------------------------------------------------------------- */}
      </div>
    )
  }
}

export declare interface GeneralFormElencoSociIscrittiAlboStpFOProps {
  message?: any
  tabNumber?: any
  soci?: any
  idSocieta?: any
  isVisualizza?: boolean
  isModifica?: boolean
  datiIscrizione?: any
  composizioneSocietaria?: any
}
