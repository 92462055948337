import * as React from 'react'
import {EmptyState, EmptyStateBody, EmptyStateIcon, Form, Title} from '@patternfly/react-core'
import {ExclamationTriangleIcon} from '@patternfly/react-icons'
import GenericTextInput from 'src/backoffice/app/components/GenericTextInput'
import GenericSpinner from 'src/backoffice/app/components/GenericSpinner'
import {isCurrentMobileDevice} from 'src/utilities/deviceUtility'

export class FormConsiglioDisciplinaInCarica extends React.Component<GeneralFormConsiglioDisciplinaInCaricaProps, any> {
  constructor(props) {
    super(props)
    this.state = {
      presidenteDisciplina: props.presidenteDisciplina,
      consigliereDisciplina: props.consigliereDisciplina,
      segretarioDisciplina: props.segretarioDisciplina,
      messaggio: props.messaggio,
      isLoaded: false,
    }
  }

  componentDidMount(): void {
    this.setState({ isLoaded: true })
  }

  render() {
    const { returnedObject, messaggio } = this.props
    const { presidenteDisciplina, consigliereDisciplina, segretarioDisciplina, isLoaded } = this.state

    if (isLoaded) {
      return (
        <>
          {returnedObject === '' && messaggio === '' && presidenteDisciplina && consigliereDisciplina && segretarioDisciplina ? (
            <GenericSpinner />
          ) : messaggio !== '' ? (
            <EmptyState variant="full">
              <EmptyStateIcon icon={ExclamationTriangleIcon} />
              <EmptyStateBody>Non è stato possibile recuperare i dati. Contattare l'assistenza.</EmptyStateBody>
            </EmptyState>
          ) : (
            <div>
              <Form>
                {/*---------------------------------------- Campi visibili solo in caso di ordine territoriale ----------------------------------------*/}
                <Title headingLevel="h3">Presidente</Title>
                {presidenteDisciplina?.map((presidenteDisciplina, index) => {
                  return (
                    <div className="row">
                      <div className={isCurrentMobileDevice() ? null : 'col-6'}>
                        <GenericTextInput
                          type={'text'}
                          className="w-75"
                          key={'nomePresidenteDisciplina' + index}
                          id={'nomePresidenteDisciplina' + index}
                          label={'Nominativo'}
                          value={
                            presidenteDisciplina?.nome && presidenteDisciplina?.cognome ? presidenteDisciplina?.nome + ' ' + presidenteDisciplina?.cognome : '-'
                          }
                          isReadOnly
                        />
                      </div>
                      <div className={isCurrentMobileDevice() ? null : 'col-6'}>
                        <GenericTextInput
                          type={'text'}
                          className="w-75"
                          id={'email'}
                          label={'Email'}
                          value={presidenteDisciplina?.email ? presidenteDisciplina?.email : '-'}
                          isReadOnly
                        />
                      </div>
                    </div>
                  )
                })}
                <Title headingLevel="h3">Segretario</Title>
                {segretarioDisciplina?.map((segretarioDisciplina, index) => {
                  return (
                    <div className="row">
                      <div className={isCurrentMobileDevice() ? null : 'col-6'}>
                        <GenericTextInput
                          type={'text'}
                          className="w-75"
                          key={'nomeSegretarioDisciplina' + index}
                          id={'nomeSegretarioDisciplina' + index}
                          label={'Nominativo'}
                          value={
                            segretarioDisciplina?.nome && segretarioDisciplina?.cognome ? segretarioDisciplina?.nome + ' ' + segretarioDisciplina?.cognome : '-'
                          }
                          isReadOnly
                        />
                      </div>
                      <div className={isCurrentMobileDevice() ? null : 'col-6'}>
                        <GenericTextInput
                          type={'text'}
                          className="w-75"
                          id={'email'}
                          label={'Email'}
                          value={segretarioDisciplina?.email ? segretarioDisciplina?.email : '-'}
                          isReadOnly
                        />
                      </div>
                    </div>
                  )
                })}
                <Title headingLevel="h3">Consiglieri</Title>
                {consigliereDisciplina?.map((consigliereDisciplina, index) => {
                  return (
                    <div className="row">
                      <div className={isCurrentMobileDevice() ? null : 'col-6'}>
                        <GenericTextInput
                          type={'text'}
                          className="w-75"
                          key={'nomeConsigliereDisciplina' + index}
                          id={'nomeConsigliereDisciplina' + index}
                          label={'Nominativo'}
                          value={
                            consigliereDisciplina?.nome && consigliereDisciplina?.cognome
                              ? consigliereDisciplina?.nome + ' ' + consigliereDisciplina?.cognome
                              : '-'
                          }
                          isReadOnly
                        />
                      </div>
                      <div className={isCurrentMobileDevice() ? null : 'col-6'}>
                        <GenericTextInput
                          type={'text'}
                          className="w-75"
                          id={'email'}
                          label={'Email'}
                          value={consigliereDisciplina?.email ? consigliereDisciplina?.email : '-'}
                          isReadOnly
                        />
                      </div>
                    </div>
                  )
                })}
                {/*------------------------------------------------------------------------------------------------------------------------------------*/}
              </Form>
            </div>
          )}
        </>
      )
    } else {
      return <GenericSpinner />
    }
  }
}

export declare interface GeneralFormConsiglioDisciplinaInCaricaProps {
  returnedObject?: any
  messaggio?: string
  presidenteDisciplina?: any
  consigliereDisciplina?: any
  segretarioDisciplina?: any
}
