import {BigIntParam} from '@parsec/msga'
import {getDefaultAxiosConfig, getIscrizioneOrdineResourceApi} from 'src/app/utils/ManagerRestGateway'

/**
 * GET Titoli di Laurea
 * @returns
 */
export async function getTitoli() {
  try {
    const response = await getIscrizioneOrdineResourceApi().msgaIotElencoTitoliGet(getDefaultAxiosConfig());
    return response;
  } catch (err) {
    console.error("Errore durante la chiamata msgaIotElencoTitoliGet - " + err);
    throw new Error("Errore durante la chiamata msgaIotElencoTitoliGet - " + err);
  }
}

/**
 * GET Classi di Laurea
 * @returns
 */
export async function getClassiDiLaurea() {
  try {
    const response = await getIscrizioneOrdineResourceApi().msgaIotElencoClassiDiLaureaGet(getDefaultAxiosConfig());
    return response;
  } catch (err) {
    console.error("Errore durante la chiamata msgaIotElencoClassiDiLaureaGet - " + err);
    throw new Error("Errore durante la chiamata msgaIotElencoClassiDiLaureaGet - " + err);
  }
}

/**
 * GET Tipi di Laurea
 * @returns
 */
export async function getTipiDiLaurea() {
  try {
    const response = await getIscrizioneOrdineResourceApi().msgaIotElencoTipoLaureaGet(getDefaultAxiosConfig());
    return response;
  } catch (err) {
    console.error("Errore durante la chiamata msgaIotElencoTipoLaureaGet - " + err);
    throw new Error("Errore durante la chiamata msgaIotElencoTipoLaureaGet - " + err);
  }
}

/**
 * GET titolo richiesto by codice titolo
 * @returns
 * @param codiceTitoloRichiesto
 */
export async function getTitoloByCodiceBO(codiceTitoloRichiesto: number) {
  try {
    var titoloRichiesto: BigIntParam = {
      "integerParam": codiceTitoloRichiesto
    }
    const response = await getIscrizioneOrdineResourceApi().msgaIotBoGetTitoloByCodicePost(titoloRichiesto, getDefaultAxiosConfig())
    //Manca il ResponseBuilder su Backend
    return response;
  } catch (err: any) {
    var msg = "Errore durante il recupero del titolo. ";
    //let errorMessage: any = await ErrorStore.getInstance().showAlert(err.message)
    throw new Error(msg);
  }
}

/**
 * GET titolo richiesto by codice titolo
 * @returns
 * @param codiceTitoloRichiesto
 */
export async function getTitoloByCodiceFO(codiceTitoloRichiesto: number) {
  try {
    var titoloRichiesto: BigIntParam = {
      "integerParam": codiceTitoloRichiesto
    }
    const response = await getIscrizioneOrdineResourceApi().msgaIotFoGetTitoloByCodicePost(titoloRichiesto, getDefaultAxiosConfig())
    //Manca il ResponseBuilder su Backend
    return response;
  } catch (err: any) {
    var msg = "Errore durante il recupero del titolo. ";
    //let errorMessage: any = await ErrorStore.getInstance().showAlert(err.message)
    //throw await new Error(errorMessage.title + msg + errorMessage.message);
    throw new Error(msg);
  }
}

/**
 * GET tipo laurea by codice
 * @returns
 * @param codiceTipoLaurea
 */
export async function getTipoLaureaByCodice(codiceTipoLaurea: number) {
  try {
    var tipoLaurea: BigIntParam = {
      "integerParam": codiceTipoLaurea
    }
    const response = await getIscrizioneOrdineResourceApi().msgaIotGetTipoLaureaByCodicePost(tipoLaurea, getDefaultAxiosConfig())

    //Manca il ResponseBuilder su Backend
    return response;
  } catch (err: any) {
    var msg = "Errore durante il recupero del tipo di laurea. ";
    //let errorMessage: any = await ErrorStore.getInstance().showAlert(err.message)
    //throw await new Error(errorMessage.title + msg + errorMessage.message);
    throw new Error(msg);
  }
}

/**
 * GET classe di laurea by codice
 * @returns
 * @param codiceClasseLaurea
 */
export async function getClasseLaureaByCodice(codiceClasseLaurea: number) {
  try {
    var classeLaurea: BigIntParam = {
      "integerParam": codiceClasseLaurea
    }
    const response = await getIscrizioneOrdineResourceApi().msgaIotBoGetLaureaByCodicePost(classeLaurea, getDefaultAxiosConfig())
    return response;
  } catch (err: any) {
    var msg = "Errore durante il recupero della classe di laurea. ";
    //let errorMessage: any = await ErrorStore.getInstance().showAlert(err.message)
    //throw await new Error(errorMessage.title + msg + errorMessage.message);
    throw new Error(msg);
  }
}

/**
 * GET classe di laurea by codice
 * @returns
 * @param codiceClasseLaurea
 */
export async function getClasseLaureaByCodiceFO(codiceClasseLaurea: number) {
  try {
    var classeLaurea: BigIntParam = {
      "integerParam": codiceClasseLaurea
    }
    const response = await getIscrizioneOrdineResourceApi().msgaIotFoGetLaureaByCodicePost(classeLaurea, getDefaultAxiosConfig())
    return response;
  } catch (err: any) {
    var msg = "Errore durante il recupero della classe di laurea. ";
    //let errorMessage: any = await ErrorStore.getInstance().showAlert(err.message)
    //throw await new Error(errorMessage.title + msg + errorMessage.message);
    throw new Error(msg);
  }
}
