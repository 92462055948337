import React, {useCallback, useEffect, useState} from 'react'
import {Alert, Form, Icon, Label} from 'design-react-kit'
import GenericCard from 'src/frontend/app/components/GenericCard'
import GenericUpload from 'src/frontend/app/components/Form/Upload'
import GenericInput from 'src/frontend/app/components/Form/Input'
import {
    agenziaToModuloMapper,
    ottieniAllegato,
    requestMapperAgenzia
} from 'src/frontend/app/pages/iscrizioneAlboEF/form-utils'
import {ALLEGATI_AGENZIA_FILTERED, isInvalidAllegati, TIPOLOGIE_ACCREDITAMENTO_VALUES} from 'src/formazione'
import styled from 'styled-components'
import {AppCostants} from 'src/app/utils/AppConstant'
import {getFormazioneResourceApi} from 'src/app/utils/ManagerRestGateway'

const TIPOCHIAMATA = {
  ONLOAD: 0,
  ONCLICKSEMPLICE: 1,
  ONCLICKTOT: 2,
}

const UploadRowStyled = styled.div`
  display: flex;
  margin: 50px 0;
  .uploader-label {
    flex: 3;
  }
  .uploader-files {
    flex: 5;
  }
`
const FormDatiAllegati: React.FunctionComponent<FormDatiAllegatiProps> = ({ title, data, handleInput, onInitInput, errors, viewOnly }) => {
  const allegati = ALLEGATI_AGENZIA_FILTERED(data, 'allegati')
  const [guidModuloAgenziaPrecompilato, setGuidModuloAgenziaPrecompilato] = useState('')

  const objtipoaccr = data?.['datiAccreditamento']?.tipo_accr || data?.['datiAccreditamento']?.tipoAccreditamento

  const isTotal = objtipoaccr?.value === TIPOLOGIE_ACCREDITAMENTO_VALUES.TOTALE
  const isMulti = objtipoaccr?.value === TIPOLOGIE_ACCREDITAMENTO_VALUES.COMPLESSO
  const isSimple = objtipoaccr?.value === TIPOLOGIE_ACCREDITAMENTO_VALUES.SEMPLICE

  const func = useCallback(async (tipoChiamata) => {
    const _data = await requestMapperAgenzia(data)
    getFormazioneResourceApi()
      .msfoFormazioneGeneraModuloAgenziaPost(_data)
      .then((res) => {
        tipoChiamata === 1 && ottieniAllegato(res.data, 'Richiesta adesione (Complessa o Semplice)')
        tipoChiamata === 2 && ottieniAllegato(res.data, 'Richiesta adesione (Totale)')
        setGuidModuloAgenziaPrecompilato(res.data)
      })
  }, [data])

  useEffect(() => {
    func(TIPOCHIAMATA.ONLOAD)
  }, [])

  const generaModuloPrecompilato = async (nomefile, url, tipologia) => {
    const _data: any = {
      fileUrl: url,
      type: tipologia,
      keyValuePairs: agenziaToModuloMapper(data, nomefile),
    }

    getFormazioneResourceApi()
      .msfoFormazioneGeneraModuloAgenziaPrecompilatoPost(_data)
      .then((res) => {
        ottieniAllegato(res.data, nomefile)
        setGuidModuloAgenziaPrecompilato(res.data)
      })
  }

  return (
    <GenericCard cardHeader={title}>
      {/* check if there is an invalid item */}
      {isInvalidAllegati(allegati, data) && (
        <div className="pb-4">
          <Alert color="info">Inserire tutti gli allegati prima di procedere</Alert>
        </div>
      )}
      <Form>
        {allegati.map((allegato, index) => {
          const { id, label, type, extensions, accept, isMulti } = allegato || {}

          return (
            <UploadRowStyled key={index}>
              <div className="uploader-label">
                <Label for={id}>
                  <strong>{label}</strong>
                </Label>
              </div>
              <div className="uploader-files">
                <GenericUpload
                  isDisabled={viewOnly}
                  isMulti={isMulti}
                  id={id}
                  tipologiaAllegato={type}
                  extensions={extensions}
                  accept={accept}
                  data={data}
                  onChange={handleInput}
                />
              </div>
            </UploadRowStyled>
          )
        })}

        <div className="row p-2 ms-4" id="lista-moduli">
          <Label>Moduli da compilare</Label>
          <div className="col-4 mt-3">
            <a href={`#lista-moduli`} onClick={() => generaModuloPrecompilato('MOD_C.pdf', `${AppCostants.PUBLIC_SHARE}/formazione/MOD_C.pdf`, 'moduli_cdg')}>
              <Icon icon="it-file" /> Modulo C
            </a>
          </div>
          {isMulti && <div className="col-4 mt-3">
            <a href={`#lista-moduli`} onClick={() => generaModuloPrecompilato('MOD_D_Complesso.pdf', `${AppCostants.PUBLIC_SHARE}/formazione/MOD_D_Complesso.pdf`, 'moduli_cdg')}>
              <Icon icon="it-file" /> Modulo D (Complesso)
            </a>
          </div>}
          {isSimple && <div className="col-4 mt-3">
            <a href={`#lista-moduli`} onClick={() => generaModuloPrecompilato('MOD_D_Semplice.pdf', `${AppCostants.PUBLIC_SHARE}/formazione/MOD_D_Semplice.pdf`, 'moduli_cdg')}>
              <Icon icon="it-file" /> Modulo D (Semplice)
            </a>
          </div>}
          {isTotal && <div className="col-4 mt-3">
            <a href={`#lista-moduli`} onClick={() => generaModuloPrecompilato('MOD_D_Totale.pdf', `${AppCostants.PUBLIC_SHARE}/formazione/MOD_D_Totale.pdf`, 'moduli_cdg')}>
              <Icon icon="it-file" /> Modulo D (Totale)
            </a>
          </div>}
          {isMulti && <div className="col-4 mt-3">
            <a href={`#lista-moduli`} onClick={() => generaModuloPrecompilato('MOD_E_Complesso.pdf', `${AppCostants.PUBLIC_SHARE}/formazione/MOD_E_Complesso.pdf`, 'moduli_e')}>
              <Icon icon="it-file" /> Modulo E (Complesso)
            </a>
          </div>}
          {isSimple && <div className="col-4 mt-3">
            <a href={`#lista-moduli`} onClick={() => generaModuloPrecompilato('MOD_E_Semplice.pdf', `${AppCostants.PUBLIC_SHARE}/formazione/MOD_E_Semplice.pdf`, 'moduli_e')}>
              <Icon icon="it-file" /> Modulo E (Semplice)
            </a>
          </div>}
          {isTotal && <div className="col-4 mt-3">
            <a href={`#lista-moduli`} onClick={() => generaModuloPrecompilato('MOD_E_Totale.pdf', `${AppCostants.PUBLIC_SHARE}/formazione/MOD_E_Totale.pdf`, 'moduli_e')}>
              <Icon icon="it-file" /> Modulo E (Totale)
            </a>
          </div>}
          <div className="col-4 mt-3">
            <a href={`#lista-moduli`} onClick={() => generaModuloPrecompilato('MOD_F.pdf', `${AppCostants.PUBLIC_SHARE}/formazione/MOD_F.pdf`, 'moduli_f')}>
              <Icon icon="it-file" /> Modulo F
            </a>
          </div>
          <div className="col-4 mt-3">
            <a href={`#lista-moduli`} onClick={() => generaModuloPrecompilato('MOD_G.pdf', `${AppCostants.PUBLIC_SHARE}/formazione/MOD_G.pdf`, 'moduli_cdg')}>
              <Icon icon="it-file" /> Modulo G
            </a>
          </div>
          {(isSimple || isMulti) && (
            <div id="richiesta-adesione-complessa-semplice" className="col-4 mt-3">
              <a href={`#richiesta-adesione-complessa-semplice`} onClick={() => func(TIPOCHIAMATA.ONCLICKSEMPLICE)}>
                <Icon icon="it-file" /> Richiesta adesione (Complessa o Semplice)
              </a>
            </div>
          )}
          {isTotal && (
            <div id="richiesta-adesione-totale-doc" className="col-4 mt-3">
              <a href={`#richiesta-adesione-totale-doc`} onClick={() => func(TIPOCHIAMATA.ONCLICKTOT)}>
                <Icon icon="it-file" /> Richiesta adesione (Totale)
              </a>
            </div>
          )}
          <div className="col-4 mt-3">
            <a href={`#lista-moduli`} onClick={() => generaModuloPrecompilato('schema_F1.pdf', `${AppCostants.PUBLIC_SHARE}/formazione/schema_F1.pdf`, 'moduli_f')}>
              <Icon icon="it-file" /> Schema F1
            </a>
          </div>
          <div className="col-4 mt-3">
            <a href={`#lista-moduli`} onClick={() => generaModuloPrecompilato('schema_F2.pdf', `${AppCostants.PUBLIC_SHARE}/formazione/schema_F2.pdf`, 'moduli_f')}>
              <Icon icon="it-file" /> Schema F2
            </a>
          </div>
        </div>
        <GenericInput type={'hidden'} id={'check_uploads'} value={data.check_uploads} onInit={onInitInput} />
      </Form>
    </GenericCard>
  )
}

interface FormDatiAllegatiProps {
  data: any
  errors: any
  title: string
  handleInput: any
  onInitInput?: any
  viewOnly?: boolean
}

export default FormDatiAllegati
