import {getDefaultAxiosConfig, getProvvedimentiResourceApi} from 'src/app/utils/ManagerRestGateway'
import {ErrorStore} from 'src/errorBoundary/ErrorStore'
import {TokenStore} from 'src/keycloak/jwt/TokenStore'
import {isEmptyValue} from 'src/utilities/utility'

/**
 * POST Get elenco Disciplinari by codiceFiscale
 * @returns lista disciplinari
 *
 */
export async function getDisciplinariByCf(cf: any){
  try {
    let param = {
      "param": cf
    }
    let response = await getProvvedimentiResourceApi().mswfDisciplinareProvvedimentiRecuperaProvvedimentiDisciplinariByCfPost(param,getDefaultAxiosConfig());
    return ErrorStore.getInstance().checkResponse(response);

  } catch (err: any) {
    throw await new Error(err.message);

    // throw new Error("Errore durante la get dei disciplinari " + err?.response.data.message);
  }
}

/**
 * POST
 * @returns
 *
 */
export async function getDisciplinareById(disciplinari:any){
    try {
        var param = {
            "param": disciplinari
          }
          let response =   await getProvvedimentiResourceApi().mswfDisciplinareBoProvvedimentiGetByIdPost(param,getDefaultAxiosConfig());
      // return response.data;
      return ErrorStore.getInstance().checkResponse(response);

    } catch (err: any) {
      throw await new Error(err.message);
    }
  }

  /**
 * POST Crea una nuova disciplinare su BO
 *
 */
  export async function creaNuovaDisciplinare(richiestaDisciplinari:any, disciplinari: any){
    try {

      let createdByOrdine = 1;
      if(TokenStore.getInstance().isCurrentAccountCONAF() === true) {
        createdByOrdine = 0;
      }
      if((disciplinari.provvedimento?.value === 'sospensione-morosita' || disciplinari.provvedimento === 'sospensione-morosita') && isEmptyValue(disciplinari.provvedimentoAl)) {
        disciplinari.provvedimentoAl = "9999-12-31"
      }
      if(richiestaDisciplinari.tipologiaIscrizione === "PG") richiestaDisciplinari.tipologiaIscrizione = "SC"
      const compilaDisciplinari  = {
        "idIscrizione":richiestaDisciplinari.idIscrizione,
        "tipoIscrizione": richiestaDisciplinari.tipologiaIscrizione,
        "causale": disciplinari.causale ? disciplinari.causale : "",
        "chiProcede": disciplinari.chiProcede ? disciplinari.chiProcede : "",
        "dataApertura": disciplinari.dataApertura ? disciplinari.dataApertura.split('T')[0]  : undefined,
        "dataChiusura": disciplinari.dataChiusura ? disciplinari.dataChiusura.split('T')[0]  : undefined,
        "dataNotificaConsiglioNazionale": disciplinari.dataNotificaConsiglioNazionale ? disciplinari.dataNotificaConsiglioNazionale.split('T')[0]  : undefined,
        "dataNotificaIscrizione": disciplinari.dataNotificaIscrizione ? disciplinari.dataNotificaIscrizione.split('T')[0] : undefined,
        "dataNotificaMinisteroGiustizia": disciplinari.dataNotificaMinisteroGiustizia ? disciplinari.dataNotificaMinisteroGiustizia.split('T')[0]  : undefined,
        "dataNotificaProcedimento": disciplinari.dataNotificaProcedimento ? disciplinari.dataNotificaProcedimento.split('T')[0]  : undefined,
        "dataNotificaProcuraGenerale": disciplinari.dataNotificaProcuraGenerale ? disciplinari.dataNotificaProcuraGenerale.split('T')[0] : undefined,
        "dataProvvedimento": disciplinari.dataProvvedimento ? disciplinari.dataProvvedimento.split('T')[0] : undefined,
        "deliberaNr": disciplinari.deliberaNr ? disciplinari.deliberaNr : "",
        "determinazione": disciplinari.determinazione ? disciplinari.determinazione : "",
        "motivazione": disciplinari.motivazione ? disciplinari.motivazione : "",
        "organo": disciplinari.organo ? disciplinari.organo : "cassazione",
        "procedimentoNr": disciplinari.procedimentoNr ? disciplinari.procedimentoNr : "",
        "provvedimento": disciplinari.provvedimento ? disciplinari.provvedimento : "avvertimento",
        "provvedimentoAl": disciplinari.provvedimentoAl ? disciplinari.provvedimentoAl.split('T')[0]  : undefined,
        "provvedimentoDal": disciplinari.provvedimentoDal ? disciplinari.provvedimentoDal.split('T')[0]  : undefined,
        "createdByOrdine": createdByOrdine,
      }

      let response = await getProvvedimentiResourceApi().mswfDisciplinareBoProvvedimentiNuovoPost(compilaDisciplinari, getDefaultAxiosConfig());
      // return response.data;

      return ErrorStore.getInstance().checkResponse(response);

    } catch (err: any) {

      if(err?.response?.data?.title === 'Constraint Violation') {
        console.error(err?.response.data.title);
        throw new Error(err?.response.data.title);
      } else {
        throw await new Error(err.message);
      }
    }
  }

/**
 * POST richiedi cancella disciplinari su BO
 * @returns
 */
export async function cancellaDisciplinari(id: any){

  try {
    const param = {
      param: id
    }
    let response = await getProvvedimentiResourceApi().mswfDisciplinareBoProvvedimentiCancellaPost(param,getDefaultAxiosConfig());
    // return response.data;

    return ErrorStore.getInstance().checkResponse(response);

  } catch (err: any) {

    throw await new Error(err.message);

    //console.error("Errore durante la chiamata per la cancellazione del disciplinare" + err);
    // throw new Error("Errore durante la chiamata per la cancellazione del disciplinare " + err);
  }
  }

/**
 * PUT Aggiorna una disciplinare su BO
 * @returns
 */
export async function aggiornaDisciplinari(disciplinari: any){
  try {

        let createdByOrdine = 1;
        if(TokenStore.getInstance().isCurrentAccountCONAF() === true) {
          createdByOrdine = 0;
        }
        if((disciplinari.provvedimento?.value === 'sospensione-morosita' || disciplinari.provvedimento === 'sospensione-morosita') && isEmptyValue(disciplinari.provvedimentoAl)) {
          disciplinari.provvedimentoAl = "9999-12-31"
        }
        if(typeof(disciplinari.organo) === 'object') disciplinari.organo = disciplinari.organo.value;
        if(typeof(disciplinari.provvedimento) === 'object') disciplinari.provvedimento = disciplinari.provvedimento.value;

        let compilaDisciplinari  = {
          "id": disciplinari.id,
          "idIscrizione": disciplinari.idIscrizione,
          "causale": (disciplinari.causale) ? (disciplinari.causale) : "",
          "chiProcede": (disciplinari.chiProcede) ? (disciplinari.chiProcede) : "",
          "dataApertura": (disciplinari.dataApertura) ? (disciplinari.dataApertura.split('T')[0]) : undefined,
          "dataChiusura": (disciplinari.dataChiusura) ? (disciplinari.dataChiusura.split('T')[0]) : undefined,
          "dataNotificaConsiglioNazionale": (disciplinari.dataNotificaConsiglioNazionale) ? (disciplinari.dataNotificaConsiglioNazionale.split('T')[0]) : undefined,
          "dataNotificaIscrizione": (disciplinari.dataNotificaIscrizione) ? (disciplinari.dataNotificaIscrizione.split('T')[0]) : undefined,
          "dataNotificaMinisteroGiustizia": (disciplinari.dataNotificaMinisteroGiustizia) ? (disciplinari.dataNotificaMinisteroGiustizia.split('T')[0]) : undefined,
          "dataNotificaProcedimento": (disciplinari.dataNotificaProcedimento) ? (disciplinari.dataNotificaProcedimento.split('T')[0] ) : undefined,
          "dataNotificaProcuraGenerale": (disciplinari.dataNotificaProcuraGenerale) ? (disciplinari.dataNotificaProcuraGenerale.split('T')[0]) : undefined,
          "dataProvvedimento": (disciplinari.dataProvvedimento) ? (disciplinari.dataProvvedimento.split('T')[0] ) : undefined,
          "deliberaNr": (disciplinari.deliberaNr) ? (disciplinari.deliberaNr) : "",
          "determinazione": (disciplinari.determinazione) ? (disciplinari.determinazione) : "",
          "motivazione": (disciplinari.motivazione) ? (disciplinari.motivazione) : "",
          "organo": (disciplinari.organo) ? (disciplinari.organo) : "",
          "procedimentoNr": (disciplinari.procedimentoNr) ? (disciplinari.procedimentoNr) : "",
          "provvedimento": (disciplinari.provvedimento) ? (disciplinari.provvedimento) : "",
          "provvedimentoAl": (disciplinari.provvedimentoAl) ? (disciplinari.provvedimentoAl.split('T')[0]) : undefined,
          "provvedimentoDal": (disciplinari.provvedimentoDal) ? (disciplinari.provvedimentoDal.split('T')[0]) : undefined,"tipoIscrizione": (disciplinari.tipoIscrizione) ? (disciplinari.tipoIscrizione) : "",
          "createdByOrdine": createdByOrdine,

        }
        let response = await getProvvedimentiResourceApi().mswfDisciplinareBoProvvedimentiAggiornaPut(compilaDisciplinari,getDefaultAxiosConfig());
        // return response.data;

        return ErrorStore.getInstance().checkResponse(response);


      } catch (err: any) {
        if(err?.response?.data?.title === 'Constraint Violation') {
          console.error(err?.response.data.title);
          throw new Error(err?.response.data.title);
        } else {
          throw await new Error(err.message.replace("Service", ""));
        }
      }
  }


