import React, {useMemo} from 'react'
import {Form} from 'design-react-kit'
import createWrpInput from 'src/frontend/app/components/Form/WrpInput'
import GenericCard from 'src/frontend/app/components/GenericCard'
import GenericInput from 'src/frontend/app/components/Form/Input'
import {isCurrentMobileDevice} from 'src/utilities/deviceUtility'

const FormDatiDichiarazioni: React.FunctionComponent<FormDatiDichiarazioniProps> = ({ title, data, handleInput, onInitInput, errors, viewOnly }) => {
  const objKey = 'datiDichiarazioni'
  const WrpInput = useMemo(() => createWrpInput({ objKey, onInit: onInitInput, onChange: handleInput, viewOnly }), [])

  const pianoEditoriale = 'La rivista possiede un piano editoriale con regolarità di pubblicazione'
  const sostenibilita = 'La rivista rispetta i criteri di sostenibilità ambientale nel processo di produzione della rivista'
  const spazioRivista =
    'La rivista rende disponibile uno spazio per la pubblicazione dei dottori agronomi e dottori forestali in almeno la metà dei numeri programmati'

  return (
    <GenericCard cardHeader={title}>
      <Form>
        <div className="row">
          <div className={isCurrentMobileDevice() ? null : 'col-12'}>
            <WrpInput as={GenericInput} id="pianoEditoriale" type="checkbox" label={pianoEditoriale} suffix="*" data={data} errors={errors} />
          </div>
        </div>
        <div className="row">
          <div className={isCurrentMobileDevice() ? null : 'col-12'}>
            <WrpInput as={GenericInput} id="sostenibilita" type="checkbox" label={sostenibilita} suffix="*" data={data} errors={errors} />
          </div>
        </div>
        <div className="row">
          <div className={isCurrentMobileDevice() ? null : 'col-12'}>
            <WrpInput as={GenericInput} id="spazioRivista" type="checkbox" label={spazioRivista} suffix="*" data={data} errors={errors} />
          </div>
        </div>
      </Form>
    </GenericCard>
  )
}

interface FormDatiDichiarazioniProps {
  data: any
  errors: any
  title: string
  handleInput: any
  onInitInput?: any
  viewOnly?: boolean
}

export default FormDatiDichiarazioni
