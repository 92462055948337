import React from 'react'
import {FormGroup, TextArea} from '@patternfly/react-core'

export class GenericTextArea extends React.Component<GeneralTextAreaProps, any> {
  constructor(props) {
    super(props);
    this.state = {
      value: props.value,
      invalidText: '',
      validated: 'default',
      isRequired: props.isRequired,
      ariaLabel: props.ariaLabel,
      id: props.id,
      placeholder: props.placeholder,
      type:props.type,
      className: props.className,
      isDisabled: props.isDisabled,
      name: props.name,
      label: props.label,
      onChangeEvent:props.onChangeEvent,
    };
  }

  handleTextAreaChange = value => {
    this.setState({ value: value, validated: 'default', invalidText: '' })

    if ((value === '') && this.state.isRequired) {
      this.setState({ validated: 'error', invalidText: 'Campo obbligatorio' });
    }

    if (this.props.onChange !== undefined)
      this.props.onChange(value)
  };

  render() {
    const { name, value, validated, invalidText, isRequired, isDisabled, ariaLabel, id, placeholder, className, label } = this.state;

    return (
      <FormGroup helperTextInvalid={invalidText} validated={validated} label={label} isRequired={isRequired}>
        <TextArea
          className={className}
          id={id}
          value={value}
          type="textarea"
          onChange={(e) => {( this.handleTextAreaChange(e)); this.props.onChange(e)}}
          isRequired={isRequired}
          isDisabled={isDisabled}
          validated={validated}
          aria-label={ariaLabel}
          placeholder={placeholder}
          name={name}
          isReadOnly={this.props.isReadOnly}
        />
      </FormGroup>
    );
  }
}

export declare interface GeneralTextAreaProps {
  isRequired?: boolean;
  isDisabled?: boolean;
  ariaLabel?: any;
  id?: any;
  placeholder?: any;
  className?: any;
  value?: any;
  type?: any;
  onChange?: any;
  onChangeEvent?: any;
  name?: any;
  label?: any;
  isReadOnly?: any;
}

export default GenericTextArea;
