import React from 'react'
import {
    PATH_TO_FO_ANAG_ISCRIZIONE_STP_VISUALIZZA,
    PATH_TO_FO_ANAG_RICHIEDI_NUOVO_PROFILO
} from 'src/app/utils/RoutesConstants'
import {FormDatiGeneraliStpBO} from 'src/backoffice/app/pages/iscrizioneSTP/formIscrizioneSTP/FormDatiGeneraliStpBO'
import {FormDatiIscrizioneStpBO} from 'src/backoffice/app/pages/iscrizioneSTP/formIscrizioneSTP/FormDatiIscrizioneStpBO'
import {
    FormDatiProfessionaliStpBO
} from 'src/backoffice/app/pages/iscrizioneSTP/formIscrizioneSTP/FormDatiProfessionaliStpBO'
import {
    FormDatiRappresentanteLegaleStpBO
} from 'src/backoffice/app/pages/iscrizioneSTP/formIscrizioneSTP/FormDatiRappresentanteLegaleStpBO'
import {
    FormDipendentiIscrittiAlboStpBO
} from 'src/backoffice/app/pages/iscrizioneSTP/formIscrizioneSTP/FormDipendentiIscrittiAlboStpBO'
import {
    FormElencoSociIscrittiAlboStpBO
} from 'src/backoffice/app/pages/iscrizioneSTP/formIscrizioneSTP/FormElencoSociIscrittiAlboStpBO'
import {
    FormElencoSociIscrittiAltriOrdiniStpBO
} from 'src/backoffice/app/pages/iscrizioneSTP/formIscrizioneSTP/FormElencoSociIscrittiAltriOrdiniStpBO'
import {
    FormUbicazioneContattiStpBO
} from 'src/backoffice/app/pages/iscrizioneSTP/formIscrizioneSTP/FormUbicazioneContattiStpBO'
import {TokenStore} from 'src/keycloak/jwt/TokenStore'
import {getOrdineBySiglaProvinciaFO} from 'src/processes/Ordini'
import {
    getCodiceAtecoByIdFo,
    getFilesByIdIscrizioneFo,
    getFormaGiuridicaByIdFo,
    getIntegrazioniRichieste,
    getIscrizioneByCodiceFiscaleResponsabileStp,
    getIscrizioneByIdFO,
    getTipologiaSocietaByIdFo
} from 'src/processes/ProcessoIscrizione'
import {getProvinciaBySiglaFO} from 'src/processes/Province'
import {getComposizioneSocietariaByIdSocietaFO, getRappresentanteLegaleByIdSocietaFO} from 'src/processes/Societa'
import GenericBreadCrumb from 'src/frontend/app/components/GenericBreadCrumb'
import GenericCard from '../../components/GenericCard'
import GenericTab from '../../components/GenericTab'
import GenericChips from '../../components/GenericChips'
import {compareDate, getStatoById, returnMaxDateFromArray} from 'src/utilities/utility'
import GenericAlert from '../../components/GenericAlert'
import {getDenominazioneByCodiceGruppo} from 'src/processes/SelezioneProfilo'
import {FormFile} from 'src/backoffice/app/pages/iscrizione/formIscrizione/FormFile'


const title = 'Visualizza dati STP'

const linkTab = [
    { id: 1, label: 'Dati generali' },
    { id: 2, label: 'Soci' },
    { id: 3, label: 'Dati iscrizione' },
    { id: 4, label: 'File' },
]

var breadcrumbOptions = [
    { label: "Richiedi nuovo profilo", link: PATH_TO_FO_ANAG_RICHIEDI_NUOVO_PROFILO },
    { label: "Visualizza dati STP", link: PATH_TO_FO_ANAG_ISCRIZIONE_STP_VISUALIZZA },
]

class visualizzaDatiStpFO extends React.Component<any, any> {
    constructor(props) {
        super(props);
        this.state = {
            message: "",
            typeMessage: "default",
            isSubmitted: false,
            activeTab: 1,
            isValidatedForm: false,
            errors: [],
            stepTab: 1,
            provinciaSedeLegale: '',
            soggetto: '',
            iscrizione: '',
            datiRappresentanteLegale: '',
            listaComposizioneSocietaria: [],
            listaFiles: []
        };
    }

    async componentDidMount() {
        var groupName = TokenStore.getInstance().getCurrentAccountSpecificGroupName()
        var idIscrizione //= 151768 //IdIscrizione prova

        var dateFineValidita: any = []
        // Se sono autenticato invoco il servizio che mi recupa l'iscrizione PG by codice fiscale
        if (TokenStore.getInstance().isCurrentAccountAuthenticated() || await TokenStore.getInstance().isOperatoreResponsabilePropriaSTP()) {
            await getIscrizioneByCodiceFiscaleResponsabileStp(TokenStore.getInstance().getDecodedTokenJWT().fiscalNumber)
                .then(async (response: any) => {
                    if (response && response.data && response.data.httpStatus !== 200) {
                        throw new Error(response.data.error);
                    }

                    response?.data?.returnedObject?.elencoIscrizioni.map(iscrizione => {
                        dateFineValidita.push(new Date(iscrizione.dataFineValidita))
                    })
                    const maxDataFineValidita = returnMaxDateFromArray(dateFineValidita)
                    var iscrizioneDataMax = response?.data?.returnedObject?.elencoIscrizioni.filter(iscrizione => (compareDate(iscrizione.dataFineValidita, maxDataFineValidita, "eq")))
                    idIscrizione = iscrizioneDataMax[0]?.idIscrizione

                })
        } else if (TokenStore.getInstance().isCurrentAccountSTP()) {
            await getDenominazioneByCodiceGruppo(groupName).then(async (response) => {
                if (response.data && response.status !== 404) {
                    /**
                     * Nel caso di "Operatore STP" il servizio getDenominazioneByCodiceGruppo
                     * restituisce due elementi:
                     * - [0] Denominazione STP
                     * - [1] Id iscrizione STP
                     *
                     * In tutti gli altri casi, ossia, ODAF, FODAF, CONAF e Iscritto all'ordine
                     * restituisce solo la denominazione.
                     */
                    var denominazione = response.data.returnedObject;
                    idIscrizione = denominazione[1]
                }
            })
        } else {
            this.setState({ message: "Account non gestito", typeMessage: 'danger' })
            throw new Error("Account non gestito");
        }

        const tipologiaIscrizione = 'iscritto_stp'
        //chiamata al servizio  'getIscrizioneByIdBO' che restituisce i dati dell'iscrizione a partire dall'idIscrizione e tipologia_iscrizione
        await getIscrizioneByIdFO(idIscrizione, tipologiaIscrizione).then((response: any) => {
            this.setState({ iscrizione: response.data.iscrizioneOrdineTerritorialeDTO, soggetto: response.data.soggettoDTO })
            var statoIscrizione = response.data.iscrizioneOrdineTerritorialeDTO.statoIscrizione
            this.setState({statoIscrizione})

            // Controllo se lo stato dell'iscrizione è in "richiesta integrazioni"
            if (statoIscrizione === 3) {
                //recupera il messaggio delle integrazioni richieste dall'operatore
                getIntegrazioniRichieste(idIscrizione).then((response) => {
                    if (response && response.data && response.data.httpStatus !== 200) {
                        throw new Error(response.data.error);
                    }
                    this.setState({ isRichiestaIntegrazioni: true, integrazioni: response.data.returnedObject.testoRichiestaIntegrazione })
                })
            }

            var sedeLegale = response.data.soggettoDTO.elencoSedi.filter(sede => sede.idIscrizione === idIscrizione)[0]
            this.setState({sedeLegale})

            //chiamata al servizio 'getProvinciaBySigla' che restituisce la provincia della sede legale dello studio a partire dalla Sigla
            var siglaProvinciaSedeLegale = sedeLegale.slProvinciaStudio
            if (siglaProvinciaSedeLegale)
                getProvinciaBySiglaFO(siglaProvinciaSedeLegale).then((response: any) => {
                    this.setState({ provinciaSedeLegale: response.data.denominazioneUnitaTerritorialeSovracomunale })
                })

            //chiamata al servizio 'getProvinciaBySigla' che restituisce la provincia della sede operativa dello studio a partire dalla Sigla
            var siglaProvinciaSedeOperativa = sedeLegale.sopProvinciaStudio
            if (siglaProvinciaSedeOperativa)
                getProvinciaBySiglaFO(siglaProvinciaSedeOperativa).then((response: any) => {
                    this.setState({ provinciaSedeOperativa: response.data.denominazioneUnitaTerritorialeSovracomunale })
                })

            //chiamata al servizio 'getOrdineBySiglaProvincia' che restituisce l'ordine territoriale a partire dalla sigla della provincia
            var siglaProvinciaOrdine = response.data.iscrizioneOrdineTerritorialeDTO.ordineTerritorialeCompetente
            if (siglaProvinciaOrdine)
                getOrdineBySiglaProvinciaFO(siglaProvinciaOrdine).then((response: any) => {
                    this.setState({ ordineTerritoriale: response.data.denominazioneGruppo })
                })

            var idSocieta = response.data.soggettoDTO.idSoggetto
            if (idSocieta) {
                //chiamata al servizio 'getSociByIdSocieta' che restituisce la composizione societaria a partire dall'idSocieta (idSoggetto PG)
                getComposizioneSocietariaByIdSocietaFO(idSocieta).then((response: any) => {
                    this.setState({ listaComposizioneSocietaria: response.data })
                })

                //chiamata al servizio 'getRappresentanteLegaleByIdSocieta' che restituisce i dati del rappr. legale di una società
                getRappresentanteLegaleByIdSocietaFO(idSocieta).then((response: any) => {
                    this.setState({ datiRappresentanteLegale: response.data })
                })

                var idFormaGiuridica = response.data.soggettoDTO.formaGiuridica
                if (idFormaGiuridica){
                    //chiamata al servizio che restituisce la forma giuridica per id
                    getFormaGiuridicaByIdFo(idFormaGiuridica).then((response: any) => {
                        var formaGiuridica = response.data.descrizione
                    this.setState({ formaGiuridica })
                    }).catch((e) => {
                        console.error(e)
                    })
                }

                var idCodiceAteco = response.data.soggettoDTO.codAteco
                if (idCodiceAteco){
                    //chiamata al servizio che restituisce il codice ateco per id
                    getCodiceAtecoByIdFo(idCodiceAteco).then((response: any) => {
                        var codiceAteco = response.data.cod + " (" + response.data.descr + ")"
                    this.setState({ codiceAteco })
                    }).catch((e) => {
                        console.error(e)
                    })
                }

                var idTipologiaSocieta = response.data.soggettoDTO.tipolSoc
                if (idTipologiaSocieta){
                    //chiamata al servizio che restituisce la tipologia società per id
                    getTipologiaSocietaByIdFo(idTipologiaSocieta).then((response: any) => {
                        var tipologiaSocieta = response.data.descrizione
                    this.setState({ tipologiaSocieta })
                    }).catch((e) => {
                    })
                }
            }
        }).catch(() => {
            setTimeout(() => {
                this.setState({ message: "C'è stato un errore durante il recupero dei dati", typeMessage: 'danger' })
            }, 1000);
        })

        //chiamata al servizio 'getFilesByIdIscrizione' che restituisce tutti i file di un'iscrizione
        if (idIscrizione) {
            getFilesByIdIscrizioneFo(idIscrizione).then((response) => {
                this.setState({ listaFiles: response.data })
            })
        }
    }

    //Procedi allo step successivo
    nextStep = () => {
        this.setState({ activeTab: this.state.activeTab + 1 })
    }

    //Procedi allo step precedente
    previousStep = () => {
        this.setState({ activeTab: this.state.activeTab - 1 })
    }

    onSubmit = (event) => {
        event.preventDefault();
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        this.nextStep()
        this.setState({ message: "" })
    }


    render() {
        const { listaComposizioneSocietaria, soggetto, listaFiles, datiRappresentanteLegale, tipologiaSocieta, provinciaSedeLegale, provinciaSedeOperativa, ordineTerritoriale, iscrizione } = this.state
        const { message, typeMessage, codiceAteco, formaGiuridica, isSubmitted, activeTab, sedeLegale } = this.state
        const tabClick = (tab) => {
            this.setState({ activeTab: tab })
        }

        const listBodyTab =
            /** Lista del contenuto dei Tab per l'iscrizione */
            [
                {
                    id: 1,
                    body: <GenericCard cardHeader={"Dati responsabile legale"} cardBody={<FormDatiRappresentanteLegaleStpBO soggetto={soggetto} listaFiles={listaFiles} datiRappresentanteLegale={datiRappresentanteLegale} messaggio={message} />} />
                },
                {
                    id: 1,
                    body: <GenericCard cardHeader={'Ubicazione e contatti'} cardBody={<FormUbicazioneContattiStpBO sedeLegale={sedeLegale} messaggio={message} provinciaSedeLegale={provinciaSedeLegale} provinciaSedeOperativa={provinciaSedeOperativa} />} />
                },
                {
                    id: 1,
                    body: <GenericCard cardHeader={"Dati generali"} cardBody={<FormDatiGeneraliStpBO soggetto={soggetto} messaggio={message} codiceAteco = {codiceAteco} formaGiuridica = {formaGiuridica}/>} />
                },
                {
                    id: 1,
                    body: <GenericCard cardHeader={'Dati professionali'} cardBody={<FormDatiProfessionaliStpBO soggetto={soggetto} messaggio={message} tipologiaSocieta={tipologiaSocieta}/>} />
                },
                {
                    id: 2,
                    body: <GenericCard cardHeader={'Elenco soci iscritti all’Albo dei Dottori Agronomi e dei Dottori Forestali'} cardBody={<FormElencoSociIscrittiAlboStpBO composizioneSocietaria={listaComposizioneSocietaria} />} />
                },
                {
                    id: 2,
                    body: <GenericCard cardHeader={'Elenco soci iscritti ad altri ordini, albi e collegi professionali o in possesso del titolo di studio abilitante'} cardBody={<FormElencoSociIscrittiAltriOrdiniStpBO composizioneSocietaria={listaComposizioneSocietaria} />} />
                },
                {
                    id: 2,
                    body: <GenericCard cardHeader={'Dipendenti iscritti all’Albo dei Dottori Agronomi e dei Dottori Forestali'} cardBody={<FormDipendentiIscrittiAlboStpBO composizioneSocietaria={listaComposizioneSocietaria} />} />
                },
                {
                    id: 3,
                    body: <GenericCard cardHeader={'Dati di iscrizione'} cardBody={<FormDatiIscrizioneStpBO iscrizione={iscrizione} messaggio={message} />} />
                },
                {
                    id: 4,
                    body: <GenericCard cardHeader={'File'} cardBody={<><FormFile listaFiles={listaFiles} messaggio={this.state.message} /></>} />

                },

            ]
        return (
            <>
                <div className='container register-container'>
                    <GenericBreadCrumb paths={breadcrumbOptions}></GenericBreadCrumb>
                    <div><h1>{title}</h1></div>
                    <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
                        <GenericChips color={'default'} label={'Stato iscrizione: ' + getStatoById(this.state.statoIscrizione).toUpperCase()} />
                    </div>
                    <GenericAlert key="alertIntegrazioni"
                        hidden={!this.state.isRichiestaIntegrazioni}
                        label={<><b>Integrazioni richieste: </b>{this.state.integrazioni}</>}
                        color={'info'} />
                    <div className="justify-content-center">
                        <GenericTab
                            isClickable={true}
                            key={'visualizzaTab'}
                            hiddenAlert={this.state.message === ""}
                            alertTypeMessage={typeMessage}
                            labelAlert={this.state.message}
                            isSubmitted={isSubmitted}
                            onSubmit={this.onSubmit}
                            listBodyTab={listBodyTab}
                            listNavLink={linkTab}
                            activeTab={activeTab}
                            previousStep={this.previousStep}
                            tabClick={tabClick}
                        />
                    </div>
                </div>
            </>
        );
    }
}
export default visualizzaDatiStpFO;
