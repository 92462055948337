import React, {useState} from 'react'
import {Alert, Button, Form, Icon, Label} from 'design-react-kit'

import GenericCard from 'src/frontend/app/components/GenericCard'
import GenericInput from 'src/frontend/app/components/Form/Input'
import GenericUpload from 'src/frontend/app/components/Form/Upload'
import {getDefaultAxiosConfig, getFormazioneResourceApi} from 'src/app/utils/ManagerRestGateway'
import GenericButton from 'src/frontend/app/components/GenericButton'
import {generateRequestSort} from 'src/app/utils/ApiUtils'
import {GenericSpinner} from 'src/backoffice/app/components'
import SelezionaRelatore from './SelezionaRelatore'
import {useUserEnte} from 'src/hooks/useReqOptions'
import {TokenStore} from 'src/keycloak/jwt/TokenStore'
import {RO_GEST_FORM_CONAF} from 'src/app/utils/RoutesConstants'
import {DETAILS_ACTIONS, STATI_EVENTO_CATALOGO_VALUES} from 'src/formazione'

const FormDatiInterventi: React.FunctionComponent<FormDatiInterventiProps> = ({ title, data, handleInput, onInitInput, errors, viewOnly, modalita, stato }) => {
  const [searchRelatore, setSearchRelatore] = useState<any>([])
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string>('')
  const [isEmailEmpty, setIsEmailEmpty] = useState<boolean>(false)
  const [selectFormatori, setSelectFormatori] = useState<any>(false)
  const [formatoriToSelect, setFormatoriToSelect] = useState<any>([])
  const [relatoreToEdit, setRelatoreToEdit] = useState<any>(null)
  const userEnte = useUserEnte()
  const isConafGest = TokenStore.getInstance().currentAccountHasRolesOR([RO_GEST_FORM_CONAF])

  const isOrganizzatore = userEnte?.codiceEnte === data?.evento?.organizzatoda || (isConafGest && data?.organizzatoda === 'CONAF')

  const enableOverride =
    modalita === DETAILS_ACTIONS.MODIFICA &&
    isOrganizzatore &&
    ![STATI_EVENTO_CATALOGO_VALUES.RIFIUTATO, STATI_EVENTO_CATALOGO_VALUES.ANNULLATO, STATI_EVENTO_CATALOGO_VALUES.RICHIESTA].includes(stato)

  return (
    <GenericCard cardHeader={title}>
      <Form>
        <div className="row">
          <div className="col-6">
            <Label for="locandina">
              <strong>Locandina</strong>
            </Label>
          </div>
          <div className="col-6">
            <GenericUpload
              isDisabled={viewOnly}
              id="locandina"
              tipologiaAllegato={'corsi_locandina'}
              extensions={['pdf']}
              accept={['application/pdf']}
              data={data}
              onChange={handleInput}
            />
          </div>
        </div>
        <div className="d-flex justify-content-end">
          <Button
            color="secondary"
            onClick={(e) => {
              if (viewOnly) return
              e.preventDefault()
              handleInput('interventi', [...(data?.interventi || []), {}])
            }}
          >
            Aggiungi intervento
          </Button>
        </div>

        {!data?.interventi?.length && (
          <div className="pt-4">
            <Alert color="danger">Non hai ancora inserito un intervento</Alert>
          </div>
        )}
        {errorMessage && errorMessage.length !== 0 && (
          <div className="row">
            <div className="pt-4">
              <Alert color="danger">{errorMessage}</Alert>
            </div>
          </div>
        )}
        {isLoading && (
          <div className="col-3">
            <GenericSpinner />
          </div>
        )}
        {data?.interventi?.length > 0 && <hr className="mt-3 mb-3" />}
        {data?.interventi?.map((intervento, index) => {
          const titTema = `interventi.${index}.titTema`
          const dalle = `interventi.${index}.dalle`
          const alle = `interventi.${index}.alle`
          const titRelatore = `interventi.${index}.titRelatore`
          const relatore = `interventi.${index}.relatore`
          const datiRelatore = intervento?.relatore
          return (
            <div key={index}>
              <div className="row">
                <div className="col-6">
                  <GenericInput
                    isDisabled={viewOnly}
                    id={titTema}
                    type={'text'}
                    placeholder={'Nome intervento'}
                    label={'Nome intervento*'}
                    value={intervento?.titTema}
                    errorMessage={errors?.[titTema]}
                    onInit={onInitInput}
                    onChange={handleInput}
                  />
                </div>
                {!viewOnly && (
                  <div className="col-1">
                    <Button
                      onClick={() => {
                        if (viewOnly) return
                        const _interventi = data?.interventi?.filter((_, i) => i !== index)
                        handleInput('interventi', _interventi)
                      }}
                    >
                      <Icon icon={'it-delete'} size="sm" />
                    </Button>
                  </div>
                )}
                {errors.check_interventi && <div className={'text-danger'}>{errors.check_interventi}</div>}
              </div>
              <div className="row">
                <div className="col-6">
                  <GenericInput
                    isDisabled={viewOnly && !enableOverride}
                    id={dalle}
                    type={'datetime-local'}
                    placeholder={'Inizio intervento'}
                    label={'Inizio intervento'}
                    value={intervento?.dalle}
                    errorMessage={errors?.[dalle]}
                    onInit={onInitInput}
                    onChange={handleInput}
                  />
                </div>
                <div className="col-6">
                  <GenericInput
                    isDisabled={viewOnly && !enableOverride}
                    id={alle}
                    type={'datetime-local'}
                    placeholder={'Fine intervento'}
                    label={'Fine intervento'}
                    value={intervento?.alle}
                    errorMessage={errors?.[alle]}
                    onInit={onInitInput}
                    onChange={handleInput}
                  />
                </div>
                <div className="col-6">
                  <GenericInput
                    isDisabled={viewOnly}
                    id={titRelatore}
                    type={'text'}
                    placeholder={'Titolo Relatore'}
                    label={'Titolo Relatore'}
                    value={intervento?.titRelatore}
                    errorMessage={errors?.[titRelatore]}
                    onInit={onInitInput}
                    onChange={handleInput}
                  />
                </div>
                <div className="col-6">
                  <GenericInput
                    isDisabled={viewOnly}
                    id={`${relatore}.nome`}
                    type={'text'}
                    placeholder={'Nome Relatore'}
                    label={'Nome Relatore*'}
                    value={intervento?.relatore?.nome}
                    errorMessage={errors?.[`${relatore}.nome`]}
                    onInit={onInitInput}
                    onChange={handleInput}
                    isReadOnly={!!intervento?.relatore?.cf}
                  />
                </div>
                <div className="col-6">
                  <GenericInput
                    isDisabled={viewOnly}
                    id={`${relatore}.cognome`}
                    type={'text'}
                    placeholder={'Cognome Relatore'}
                    label={'Cognome Relatore*'}
                    value={intervento?.relatore?.cognome}
                    errorMessage={errors?.[`${relatore}.cognome`]}
                    onInit={onInitInput}
                    onChange={handleInput}
                    isReadOnly={!!intervento?.relatore?.cf}
                  />
                </div>
                {!viewOnly && (
                  <>
                    <div className="col-2">
                      <GenericButton
                        label={'Trova relatore'}
                        onClickEvent={async (e) => {
                          e.preventDefault()
                          setErrorMessage('')
                          setIsLoading(true)

                          try {
                            const { data } = await getFormazioneResourceApi().msfoFormazioneDettaglioFormatoriGet(
                              ['stato::EQUAL::2', 'nome::LIKE::' + intervento?.relatore?.nome, 'cognome::LIKE::' + intervento?.relatore?.cognome],
                              -1,
                              -1,
                              generateRequestSort('', ''),
                              getDefaultAxiosConfig()
                            )


                            if (data.items[0] && data.items.length === 1) {
                              const form = data.items[0]

                              const { nome, cognome, email, cod_fisc } = form.formatore as any

                              const formatore = {
                                nome: nome,
                                cognome: cognome,
                                email: email || null,
                                cf: cod_fisc,
                              }

                              if (formatore.email) {
                                setIsEmailEmpty(false)
                                handleInput(`${relatore}.email`, formatore.email)
                              } else {
                                setIsEmailEmpty(true)
                              }

                              handleInput(`${relatore}.nome`, formatore.nome)
                              handleInput(`${relatore}.cognome`, formatore.cognome)
                              handleInput(`${relatore}.cf`, formatore.cf)
                            } else if (data.items.length > 1) {
                              setSelectFormatori(true)
                              setFormatoriToSelect(data.items)
                              setRelatoreToEdit(relatore)
                            } else {
                              handleInput(`${relatore}`, {})
                              setErrorMessage('Formatore non trovato, riprova')
                            }
                          } catch {
                            setErrorMessage('Errore nella richiesta, riprova')
                          }
                          setIsLoading(false)
                        }}
                        color={'primary'}
                      />
                    </div>
                    {!!intervento?.relatore?.cf && (
                      <div className="col-2">
                        <GenericButton
                          label={'Rimuovi CF'}
                          onClickEvent={async (e) => {
                            e.preventDefault()
                            handleInput(`${relatore}.email`, '')
                            handleInput(`${relatore}.nome`, '')
                            handleInput(`${relatore}.cognome`, '')
                            handleInput(`${relatore}.cf`, null)
                          }}
                          color={'danger'}
                        />
                      </div>
                    )}
                  </>
                )}
                <div className="col-6">
                  <GenericInput
                    isDisabled={viewOnly || (modalita === DETAILS_ACTIONS.MODIFICA && isEmailEmpty)}
                    id={`${relatore}.email`}
                    type={'text'}
                    placeholder={'Email Relatore'}
                    label={'Email Relatore*'}
                    value={intervento?.relatore?.email}
                    errorMessage={errors?.[`${relatore}.email`]}
                    onInit={onInitInput}
                    onChange={handleInput}
                  />
                </div>
                <div className="col-2">{intervento?.relatore?.cf ? intervento?.relatore?.cf : ''}</div>
                {/* <div className="col-6">
                  <GenericInput
                    isDisabled={viewOnly}
                    id={`${relatore}.cf`}
                    type={'text'}
                    placeholder={'Codice Fiscale Relatore'}
                    label={'Codice Fiscale Relatore*'}
                    value={intervento?.relatore?.cf}
                    errorMessage={errors?.[`${relatore}.cf`]}
                    onInit={onInitInput}
                    onChange={(cod, value) => {
                      handleInput(cod, value)
                      setSearchRelatore(value)
                    }}
                  />
                </div> */}
                {/* <div className="col-6">
                  <GenericSelect
                    isSearchable
                    placeholder={'Cerca Relatore'}
                    label={'Relatore*'}
                    defaultOptions={[{value: 'relatore1', label: 'Relatore 1'}, {value: 'relatore2', label: 'Relatore 2'}]}
                    onChange={value=>{
                      handleInput(relatore, {
                        cf: "test CF",
                        nome: "test nome",
                        cognome: "test cognome",
                        email: "test email",
                      })
                    }}
                  />
                </div> */}
                {/* {datiRelatore && (
                  <div className='d-flex flex-column align-items-end'>
                    <b className=''>{datiRelatore?.cf}</b>
                    <b className=''>{datiRelatore?.nome}</b>
                    <b className=''>{datiRelatore?.cognome}</b>
                    <b className=''>{datiRelatore?.email}</b>
                  </div>
                )} */}
              </div>
              {data?.interventi?.length > 0 && <hr className="mt-3 mb-3" />}
            </div>
          )
        })}
      </Form>
      {formatoriToSelect && formatoriToSelect.length > 0 && (
        <SelezionaRelatore
          formatori={formatoriToSelect}
          selecting={selectFormatori}
          onClose={(form) => {
            if (!form) {
              setSelectFormatori(false)
              setFormatoriToSelect([])
              setRelatoreToEdit('')
              return
            }
            const { nome, cognome, email, cod_fisc } = form.formatore as any

            const formatore = {
              nome: nome,
              cognome: cognome,
              email: email || null,
              cf: cod_fisc,
            }

            if (formatore.email) {
              setIsEmailEmpty(false)
              handleInput(`${relatoreToEdit}.email`, formatore.email)
            } else {
              setIsEmailEmpty(true)
            }
            handleInput(`${relatoreToEdit}.nome`, formatore.nome)
            handleInput(`${relatoreToEdit}.cognome`, formatore.cognome)
            handleInput(`${relatoreToEdit}.cf`, formatore.cf)

            setSelectFormatori(false)
            setFormatoriToSelect([])
            setRelatoreToEdit('')
          }}
        />
      )}
      <GenericInput type={'hidden'} id={'check_interventi'} value={data.check_interventi} onInit={onInitInput} />
    </GenericCard>
  )
}

interface FormDatiInterventiProps {
  data: any
  errors: any
  title: string
  handleInput: any
  onInitInput?: any
  viewOnly?: boolean
  stato?: number
  modalita?: string
}

export default FormDatiInterventi
