import * as React from 'react'
import {EmptyState, EmptyStateBody, EmptyStateIcon, Form} from '@patternfly/react-core'
import {ExclamationTriangleIcon} from '@patternfly/react-icons'
import GenericTextInput from 'src/backoffice/app/components/GenericTextInput'
import GenericSpinner from 'src/backoffice/app/components/GenericSpinner'
import {getBigIntToConvertToStringSiNo} from 'src/utilities/utility'
import {isCurrentMobileDevice} from 'src/utilities/deviceUtility'

export class FormIscrizioneEPAP extends React.Component<GeneralFormIscrizioneEPAPProps, any> {
  render() {
    const { messaggio, iscrizione } = this.props
    return (
      <>
        {iscrizione === '' && messaggio === '' ? (
          <GenericSpinner />
        ) : messaggio !== '' ? (
          <EmptyState variant="full">
            <EmptyStateIcon icon={ExclamationTriangleIcon} />
            <EmptyStateBody>Non è stato possibile recuperare i dati. Contattare l'assistenza.</EmptyStateBody>
          </EmptyState>
        ) : (
          <div>
            <Form>
              <div className="row">
                <div className={isCurrentMobileDevice() ? null : 'col-6'}>
                  <GenericTextInput
                    type={'text'}
                    className="w-75"
                    id={'idIscrittoEPAP'}
                    label={'Iscritto EPAP'}
                    value={getBigIntToConvertToStringSiNo(iscrizione.flagIscrizioneEpap)}
                    isReadOnly
                  />
                </div>
                <div className={isCurrentMobileDevice() ? null : 'col-6'}>
                  <GenericTextInput
                    type={'date'}
                    className="w-75"
                    id={'idDataIscrizioneEPAP'}
                    label={'Data iscrizione'}
                    value={iscrizione.dataIscrizioneEpap}
                    style={{ paddingRight: '25px' }}
                    isReadOnly
                  />
                </div>
              </div>
              <div className="row">
                <div className={isCurrentMobileDevice() ? null : 'col-6'}>
                  <GenericTextInput type={'text'} className="w-75" id={'idMatricola'} label={'Matricola'} value={iscrizione.matricolaEpap} isReadOnly />
                </div>
              </div>
            </Form>
          </div>
        )}
      </>
    )
  }
}

export declare interface GeneralFormIscrizioneEPAPProps {
  iscrizione?: any
  messaggio?: any
}
