import React from 'react'
import {FormSection, Wizard} from '@patternfly/react-core'

class GenericWizard extends React.Component<GeneralWizardProps, any> {

  render() {
    const { height, width, backButtonText, nextButtonText, cancelButtonText, onClickCancel, onClickNext, onClickBack, isHidden, footer } = this.props;
    return (
      <FormSection>
        <div style={{ zIndex: 0 }}>
          <Wizard onGoToStep={this.props.tabClick} steps={this.props.steps} height={height} width={width} cancelButtonText={cancelButtonText} backButtonText={backButtonText} nextButtonText={nextButtonText} onClose={onClickCancel} onBack={onClickBack} onNext={onClickNext} hidden={isHidden} footer={footer ? footer : undefined} />
        </div>
      </FormSection>
    );
  }
}

export declare interface GeneralWizardProps {
    steps?: any;
    height?: any;
    width?: any;
    backButtonText?: any;
    nextButtonText?: any;
    cancelButtonText?: any;
    onClickCancel?: any;
    onClickNext?: any;
    onClickBack?: any;
    tabClick?: any;
    isHidden?: boolean;
    footer?: any;
}

export default GenericWizard;
