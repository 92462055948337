import * as React from 'react'
import GenericSelect from '../../../components/GenericSelect'
import {Form} from '@patternfly/react-core'
import GenericTextInput from 'src/backoffice/app/components/GenericTextInput'
import GenericSpinner from 'src/backoffice/app/components/GenericSpinner'
import {valutaIscrizioneOrdine} from 'src/processes/ProcessoIscrizione'
import {inputRequiredValidation} from 'src/utilities/utility'
import {isCurrentMobileDevice} from 'src/utilities/deviceUtility'

/* ----------------------------------- VARIABILI E COSTANTI ----------------------------------------- */
const age_senior = 75
const age_professional = 50

//opzioni select -> 0: 'No',  1: 'Si'
var flag_categoria_B = [
  { value: 1, label: 'Si', disabled: false },
  { value: 0, label: 'No', disabled: false },
]
var flag_categoria_C = [
  { value: 1, label: 'Si', disabled: false },
  { value: 0, label: 'No', disabled: false },
]
var flag_categoria_D = [
  { value: 1, label: 'Si', disabled: false },
  { value: 0, label: 'No', disabled: false },
]
var flag_esonero_quota_ordine = [
  { value: 1, label: 'Si', disabled: false },
  { value: 0, label: 'No', disabled: false },
]
var flag_esonero_quota_conaf = [
  { value: 1, label: 'Si', disabled: false },
  { value: 0, label: 'No', disabled: false },
]
var flag_iscritto_perito_tribunale_penale = [
  { value: 1, label: 'Si', disabled: false },
  { value: 0, label: 'No', disabled: false },
]
var flag_iscritto_ctu_tribunale_civile = [
  { value: 1, label: 'Si', disabled: false },
  { value: 0, label: 'No', disabled: false },
]

//flag di default select impostato a 'No'
const defaultValue = 0
/* ------------------------------------------------------------------------------------------------- */

export class FormModificaIscrizione extends React.Component<GeneralFormModificaIscrizioneProps, any> {
  constructor(props) {
    super(props)
    this.state = {
      isDateValiMartelloHidden: false,
      isDateValiTimbroHidden: false,
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.iscrizione !== prevProps.iscrizione) {
      this.setState({ isDateValiTimbroHidden: this.props.iscrizione.numeroTimbro ? false : true })
      this.setState({ isDateValiMartelloHidden: this.props.iscrizione.numeroMartello ? false : true })
    }
  }

  //onChange input numero timbro e numero martello
  inputOnChange = (event, handleFunction) => {
    switch (handleFunction) {
      //case 1: onChange numero timbro
      case 1:
        if (event === '') {
          this.setState({ isDateValiTimbroHidden: true })
        } else {
          this.setState({ isDateValiTimbroHidden: false })
        }
        break
      //case 2: onChange numero martello
      case 2:
        if (event === '') {
          this.setState({ isDateValiMartelloHidden: true })
        } else {
          this.setState({ isDateValiMartelloHidden: false })
        }
        break
    }
  }

  handleSubmit = async (event) => {
    /*--------------------------------------------- Validazione campi obbligatori ------------------------------------------------*/
    var requiredFields = [
      !this.state.isDateValiTimbroHidden ? { value: event.target.idDataIniValiTimbro.value, label: 'Data inizio validità timbro' } : null,
      !this.state.isDateValiMartelloHidden ? { value: event.target.idDataIniValiMartello.value, label: 'Data inizio validità martello' } : null,
    ]

    var errorRequired: any = inputRequiredValidation(requiredFields)
    if (errorRequired !== undefined && errorRequired !== '') {
      event.preventDefault()
      return this.props.submitMessageResult({ messaggio: errorRequired.messaggio, type: errorRequired.type })
    } else {
    /*-----------------------------------------------------------------------------------------------------------------------------*/
      //chiamata al servizio valutaIscrizione per modifica iscrizione
      var esitoValutazione = 'iscritto'
      var idIscrizione = this.props.idIscrizione
      var idSoggetto = this.props.soggetto.idSoggetto
      var parametriModifica = {
        numeroIscrizione: event.target.idNumeroIscrizione.value ? event.target.idNumeroIscrizione.value : null,
        dataIscrizione: event.target.idDataIscrizione.value ? event.target.idDataIscrizione.value : null,
        deliberaIscrizione: event.target.idDeliberaIscrizione.value ? event.target.idDeliberaIscrizione.value : '',
        numeroTimbro: event.target.idNumeroTimbro.value ? event.target.idNumeroTimbro.value : '',
        dataInizioValiTimbro: !this.state.isDateValiTimbroHidden && event.target.idDataIniValiTimbro.value ? event.target.idDataIniValiTimbro.value : null,
        dataFineValiTimbro: !this.state.isDateValiTimbroHidden && event.target.idDataFineValiTimbro.value ? event.target.idDataFineValiTimbro.value : null,
        numeroMartello: event.target.idNumeroMartello.value ? event.target.idNumeroMartello.value : '',
        dataInizioValiMartello:
          !this.state.isDateValiMartelloHidden && event.target.idDataIniValiMartello.value ? event.target.idDataIniValiMartello.value : null,
        dataFineValiMartello:
          !this.state.isDateValiMartelloHidden && event.target.idDataFineValiMartello.value ? event.target.idDataFineValiMartello.value : null,
        categoriaB: event.target.idCategoriaB.value ? event.target.idCategoriaB.value : defaultValue,
        categoriaC: event.target.idCategoriaC.value ? event.target.idCategoriaC.value : defaultValue,
        categoriaD: event.target.idCategoriaD.value ? event.target.idCategoriaD.value : defaultValue,
        esoneroPagaQuotaOrdine: event.target.idEsoneroQuotaOrdine.value ? event.target.idEsoneroQuotaOrdine.value : null,
        esoneroPagaQuotaConaf: event.target.idEsoneroQuotaConaf.value ? event.target.idEsoneroQuotaConaf.value : defaultValue,
        iscrittoPeritoTribunalePenale: event.target.idIscrittoPeritoTribunalePenale.value ? event.target.idIscrittoPeritoTribunalePenale.value : defaultValue,
        iscrittoCTUTribunaleCivile: event.target.idIscrittoCTUTribunaleCivile.value ? event.target.idIscrittoCTUTribunaleCivile.value : defaultValue,
      }
      try {
        event.preventDefault()
        await valutaIscrizioneOrdine(esitoValutazione, idIscrizione, idSoggetto, parametriModifica, [])
        return this.props.submitMessageResult({ messaggio: 'Dati salvati con successo', type: 'success' })
      } catch (err) {
        return this.props.submitMessageResult({ messaggio: "C'è stato un problema nell'invio dei dati", type: 'danger' })
      }
    }
  }

  render() {
    const { isDateValiTimbroHidden, isDateValiMartelloHidden } = this.state
    const { soggetto, iscrizione } = this.props
    return (
      <>
        {soggetto === '' || iscrizione === '' ? (
          <GenericSpinner />
        ) : (
          <div>
            <Form id="idModificaIscrizione" onSubmit={this.handleSubmit}>
              <div className="row">
                <div className={isCurrentMobileDevice() ? null : 'col-6'}>
                  <GenericTextInput type={'text'} className="w-75" id={'idNome'} label={'Nome **'} value={soggetto.nome} isReadOnly />
                </div>
                <div className={isCurrentMobileDevice() ? null : 'col-6'}>
                  <GenericTextInput type={'text'} className="w-75" id={'idCognome'} label={'Cognome **'} value={soggetto.cognome} isReadOnly />
                </div>
              </div>
              <div className="row">
                <div className={isCurrentMobileDevice() ? null : 'col-6'}>
                  <GenericTextInput
                    type={'text'}
                    className="w-75"
                    id={'idNumeroIscrizione'}
                    label={'Numero iscrizione **'}
                    value={iscrizione.numeroIscrizioneOrdine}
                    isReadOnly
                  />
                </div>
                <div className={isCurrentMobileDevice() ? null : 'col-6'}>
                  <GenericTextInput
                    type={'date'}
                    className="w-75"
                    id={'idDataIscrizione'}
                    label={'Data iscrizione **'}
                    value={iscrizione.dataIscrizioneOrdine}
                    style={{ paddingRight: '25px' }}
                    isReadOnly
                  />
                </div>
              </div>
              <div className="row">
                <div className={isCurrentMobileDevice() ? null : 'col-6'}>
                  <GenericTextInput
                    type={'text'}
                    className="w-75"
                    id={'idDeliberaIscrizione'}
                    label={'Delibera iscrizione **'}
                    value={iscrizione.deliberaIscrizione}
                    isReadOnly
                  />
                </div>
              </div>
              <div className="row">
                <div className={isCurrentMobileDevice() ? null : 'col-6'}>
                  <GenericTextInput
                    type={'text'}
                    className="w-75"
                    id={'idNumeroTimbro'}
                    label={'Numero timbro'}
                    placeholder={'Inserire numero timbro'}
                    defaultValue={iscrizione.numeroTimbro}
                    onChange={(e) => this.inputOnChange(e, 1)}
                  />
                </div>
              </div>
              {/*-------------------------------------------------------- Campi visibili solo se presente il numero timbro ----------------------------------------------------*/}
              <div className="row" hidden={isDateValiTimbroHidden}>
                <div className={isCurrentMobileDevice() ? null : 'col-6'}>
                  {/*Campo obbligatorio solo se è inserito il numero Timbro*/}
                  <GenericTextInput
                    type={'date'}
                    className="w-75"
                    id={'idDataIniValiTimbro'}
                    label={'Data inizio validità timbro'}
                    placeholder={'Selezionare data'}
                    defaultValue={iscrizione.dataInizioValiTimbro}
                    style={{ paddingRight: '25px' }}
                    isRequired={!isDateValiTimbroHidden}
                  />
                </div>
                <div className={isCurrentMobileDevice() ? null : 'col-6'}>
                  {/*Se c’è la data di inizio e non è compilato si intende privo di scadenza*/}
                  <GenericTextInput
                    type={'date'}
                    className="w-75"
                    id={'idDataFineValiTimbro'}
                    label={'Data fine validità timbro'}
                    placeholder={'Selezionare data'}
                    defaultValue={iscrizione.dataFineValiTimbro === '9999-12-31' ? '' : iscrizione.dataFineValiTimbro}
                    style={{ paddingRight: '25px' }}
                  />
                </div>
              </div>
              {/*------------------------------------------------------------------------------------------------------------------------------------------------------------- */}
              <div className="row">
                <div className={isCurrentMobileDevice() ? null : 'col-6'}>
                  <GenericTextInput
                    type={'text'}
                    className="w-75"
                    id={'idNumeroMartello'}
                    label={'Numero martello'}
                    placeholder={'Inserire numero martello'}
                    defaultValue={iscrizione.numeroMartello}
                    onChange={(e) => this.inputOnChange(e, 2)}
                  />
                </div>
              </div>
              {/*------------------------------------------------------ Campi visibili solo se presente il numero martello ----------------------------------------------------*/}
              <div className="row" hidden={isDateValiMartelloHidden}>
                <div className={isCurrentMobileDevice() ? null : 'col-6'}>
                  {/*Campo obbligatorio solo se è inserito il numero Matello*/}
                  <GenericTextInput
                    type={'date'}
                    className="w-75"
                    id={'idDataIniValiMartello'}
                    label={'Data inizio validità martello'}
                    placeholder={'Selezionare data'}
                    defaultValue={iscrizione.dataInizioValiMartello}
                    isRequired={!isDateValiMartelloHidden}
                    style={{ paddingRight: '25px' }}
                  />
                </div>
                <div className={isCurrentMobileDevice() ? null : 'col-6'}>
                  {/* Se c’è la data di inizio e non è compilato si intende privo di scadenza*/}
                  <GenericTextInput
                    type={'date'}
                    className="w-75"
                    id={'idDataFineValiMartello'}
                    label={'Data fine validità martello'}
                    placeholder={'Selezionare data'}
                    defaultValue={iscrizione.dataFineValiMartello === '9999-12-31' ? '' : iscrizione.dataFineValiMartello}
                    style={{ paddingRight: '25px' }}
                  />
                </div>
              </div>
              {/*------------------------------------------------------------------------------------------------------------------------------------------------------------- */}
              <div className="row">
                {/*------------------------------- Campo visibile solo per iscritti con anzianità superiore ai 50 anni. --------------------------*/}
                <div className={isCurrentMobileDevice() ? null : 'col-6'} hidden={!(soggetto.anniAnzianita > age_professional)}>
                  <GenericSelect
                    className="w-75"
                    id={'idCategoriaB'}
                    label={'Categoria B - Professional'}
                    value={iscrizione.categoriaAnzianitaB}
                    list={flag_categoria_B}
                  />
                </div>
                {/*--------------------------------------------------------------------------------------------------------------------------------*/}
                <div className={isCurrentMobileDevice() ? null : 'col-6'}>
                  <GenericSelect
                    className="w-75"
                    id={'idCategoriaC'}
                    label={'Categoria C - Emeriti'}
                    value={iscrizione.categoriaAnzianitaC}
                    list={flag_categoria_C}
                  />
                </div>
              </div>
              {/*---------------------------- Campo visibile solo per iscritti con anzianità superiore ai 75 anni. -----------------------------*/}
              <div className="row" hidden={!(soggetto.anniAnzianita > age_senior)}>
                <div className={isCurrentMobileDevice() ? null : 'col-6'}>
                  <GenericSelect
                    className="w-75"
                    id={'idCategoriaD'}
                    label={'Categoria D - Senior'}
                    value={iscrizione.categoriaAnzianitaD}
                    list={flag_categoria_D}
                  />
                </div>
              </div>
              {/*--------------------------------------------------------------------------------------------------------------------------------*/}
              <div className="row">
                <div className={isCurrentMobileDevice() ? null : 'col-6'}>
                  <GenericSelect
                    className="w-75"
                    id={'idEsoneroQuotaOrdine'}
                    label={'Esonerato da pagamento della quota all’ordine'}
                    value={iscrizione.flagEsoneratoPagamentoQuotaIscrizione}
                    list={flag_esonero_quota_ordine}
                  />
                </div>
                <div className={isCurrentMobileDevice() ? null : 'col-6'}>
                  <GenericSelect
                    className="w-75"
                    id={'idEsoneroQuotaConaf'}
                    label={'Esonerato da pagamento della quota CONAF'}
                    value={iscrizione.flagEsoneratoPagamentoQuotaConaf}
                    list={flag_esonero_quota_conaf}
                  />
                </div>
              </div>
              <div className="row">
                <div className={isCurrentMobileDevice() ? null : 'col-6'}>
                  <GenericSelect
                    className="w-75"
                    id={'idIscrittoPeritoTribunalePenale'}
                    label={'Iscritto perito tribunale penale'}
                    value={iscrizione.flagIscrittoPeritoTribunalePenale}
                    list={flag_iscritto_perito_tribunale_penale}
                  />
                </div>
                <div className={isCurrentMobileDevice() ? null : 'col-6'}>
                  <GenericSelect
                    className="w-75"
                    id={'idIscrittoCTUTribunaleCivile'}
                    label={'Iscritto CTU tribunale civile'}
                    value={iscrizione.flagIscrittoCtuTribunaleCivile}
                    list={flag_iscritto_ctu_tribunale_civile}
                  />
                </div>
              </div>
            </Form>
          </div>
        )}
      </>
    )
  }
}

export declare interface GeneralFormModificaIscrizioneProps {
  submitMessageResult?: any
  idIscrizione?: any
  iscrizione?: any
  soggetto?: any
}
