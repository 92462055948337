/* eslint-disable react/no-direct-mutation-state */
import * as React from 'react'
import GenericSpinner from 'src/backoffice/app/components/GenericSpinner'
import {PATH_TO_FO_NUOVO_STUDIO_ASSOCIATO, PATH_TO_FO_STUDI_ASSOCIATI_ELENCO} from 'src/app/utils/RoutesConstants'
import {getHome, goTo, inputRequiredValidation} from 'src/utilities/utility'
import GenericBreadCrumb from '../../components/GenericBreadCrumb'
import GenericAlert from '../../components/GenericAlert'
import {FormStudiAssociati} from './formStudiAssociati/FormStudiAssociati'
import GenericTitle from '../../components/GenericTitle'
import {TokenStore} from 'src/keycloak/jwt/TokenStore'
import {creaNuovoStudioAssociato} from 'src/processes/StudioAssociato'
import GenericModal from 'src/backoffice/app/components/GenericModal'

const title = 'Nuovo studio associato'

var breadcrumbOptions = [
  { label: "Studi associati", link: PATH_TO_FO_STUDI_ASSOCIATI_ELENCO },
  { label: "Elenco studi associati", link: PATH_TO_FO_STUDI_ASSOCIATI_ELENCO },
  { label: "Nuovo studio associato", link: PATH_TO_FO_NUOVO_STUDIO_ASSOCIATO}
]

class CreazioneStudioAssociato extends React.Component<any , any>{
    constructor(props) {
      super(props);
      this.state = {
          message:"",
          fields: {},
          errors: [],
          modalOpen:false,
          modalText:"testo finestra modale",
          modalTitle:"titolo finestra modale"
      };
  }
  goToElencoStudiAssociati = (redirectLink: any) => {
    this.props.history.push({
        pathname: redirectLink,
        state: {action: "studi-associati", studiAssociati: this.state.elencoStudiAssociati}

    });
  }
  async componentDidMount(): Promise<void> {
    let idSoggetto = TokenStore.getInstance().getCurrentAccount().idSoggetto;
    this.setState({idSoggetto: idSoggetto, isLoaded : true })

  }

  inviaStudioAssociato = async (idSoggetto, studioAssociato) => {
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });

      /************************** Chiamata per l'invio finale ******************************/


      if(this.state.elencoStudiAssociati !== null && studioAssociato !== undefined) {

          this.setState({message: "", typeMessage: "default" })
           /*--------------------------------------------- Validazione campi obbligatori ------------------------------------------------*/
          var requiredFields = [
            { value: studioAssociato.cfPiva, label: 'CF/PIVA'},
            { value: studioAssociato.ragioneSociale, label: 'Ragione Sociale'},
            { value: studioAssociato.provincia, label: 'Provincia'},
            { value: studioAssociato.comune, label: 'Comune'},
            { value: studioAssociato.indirizzo, label: 'Indirizzo'},
            { value: studioAssociato.cap, label: 'CAP'},
          ]
          var errorRequired: any = inputRequiredValidation(requiredFields)

          if (errorRequired !== undefined && errorRequired !== "") {
            return this.setState({ message: errorRequired.messaggio, typeMessage: errorRequired.type, isLoaded: true })
          }
          /*------------------------------------------------------------------------------------------------------------------------------*/
          else {
              creaNuovoStudioAssociato(idSoggetto, studioAssociato).then(response=>{

                this.showModal(true, "Creazione avvenuta con successo","Lo studio associato - CF/P.IVA " + studioAssociato.cfPiva + " - è stato creato con successo!");

              }).catch((err) => {
                //dal servizio ritorna il messaggio di errore quando i campi obbligatori non sono stati settati

                if(err.message.includes("Constraint Violation") || err.message.includes("obbligatori") ) {
                    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
                    this.setState({message: "Compila tutti i campi obbligatori correttamente", typeMessage: "danger", isLoaded: true })
                }
                else {
                  this.showModal(true, "Errore",err.message );
                }
                this.setState({studioAssociato: studioAssociato})
              })
          }
        } else {
          window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
          this.setState({message: "Compila i campi obbligatori!", typeMessage: "danger", isLoaded: true })
        }

  }
  /** Funzione per aggiornare i campi del form */
  getSelectValues = async (fields: any) => {

      await this.setState({fields: fields, data: fields, disciplinari: fields})
      this.setState({isLoaded: true})
  }

  showModal = async (booleanValue, title, text) => {
        await this.setState({
            modalOpen: booleanValue,
            modalTitle: title,
            modalText:text,
            isLoaded: true,
        });
  }

  closeModal = () => {
        this.setState({modalOpen:false});
        this.props.history.push({
            pathname: PATH_TO_FO_STUDI_ASSOCIATI_ELENCO,
            state: {action: "studi-associati", studiAssociati: this.state.elencoStudiAssociati}
          });
  }
  submitMessageResult = (alert: any) => {
        const element = document.getElementById("pageSectionId");
        if (element)
            element.scrollIntoView({ behavior: 'smooth' });
        this.setState({ message: alert.messaggio, typeMessage: alert.type })
        if (alert.type === 'success') {
            setTimeout(async () => {
                goTo(getHome())
            }, 3000);
        }
    }
  render() {

    const { typeMessage, message, idSoggetto} = this.state;

    return(
      <div key={"nuovaSanzioneDisciplinare"} id='formDisciplinari' className='container-fluid'>
          <div className="container-fluid">
            <div className='row'>
                <div className='col-10 offset-1'>
                    {
                    (this.state.isLoaded) ?
                      <div>
                          <div className="row">
                            <div className="col-10">
                              <GenericBreadCrumb paths={breadcrumbOptions} />
                              <GenericTitle Size="h1" title={title}></GenericTitle>
                              <GenericAlert hidden={message === ''} label={message} color={typeMessage}></GenericAlert>
                            </div>
                            <FormStudiAssociati message={this.submitMessageResult} idSoggetto={idSoggetto} creaStudioAssociato={this.inviaStudioAssociato} goToElencoStudiAssociati={this.goToElencoStudiAssociati}/>
                            <GenericModal
                                title={this.state.modalTitle}
                                text={this.state.modalText}
                                modalOpen={this.state.modalOpen}
                                closeButton={this.closeModal}
                              />
                          </div>

                      </div>
                      : <GenericSpinner></GenericSpinner>
                    }
                </div>
            </div>
          </div>
      </div>
    )
  }
};
export declare interface InserisciProps {
  modificaDatiFlag?:any;
  visualizzaFlag?:any;
}

export default CreazioneStudioAssociato;
