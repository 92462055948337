import {Button, Card, CardBody, CardCategory, CardReadMore, Icon, UncontrolledTooltip} from 'design-react-kit'
import React from 'react'

class GenericTodo extends React.Component<GeneralTodoProps, any>{
    render() {
        return (

                  <Card spacing className='card-bg card-big border-bottom-card'>
                      <div className='etichetta'>
                        <Button id="noNotificationId" hidden={this.props.disableMessageChoice === false || this.props.disableMessageChoice === null} onClick={this.props.onClickIcon}><Icon color="warning" icon='it-less-circle'/></Button>
                        <UncontrolledTooltip placement='top' target={"noNotificationId"}>
                          Clicca per silenziare la notifica
                        </UncontrolledTooltip>
                      </div>
                      <div>

                        <CardBody className="p-1">
                              <CardCategory date={this.props.date}>{this.props.category}</CardCategory>
                              <div><p className='lead'> {this.props.message}</p></div>
                              <div><br></br><br></br></div>
                              <CardReadMore href={this.props.url} text='Apri' iconName='it-arrow-right' />
                        </CardBody>
                      </div>
                  </Card>

        );
      }
    }

    export declare interface GeneralTodoProps {
        category?: any;
        message?: any;
        number?: any;                   //Se la todo prevede un numero questo viene passato attraverso questa props - esempio 4 assicurazioni in attesa di valutazione,viene passato number={4}
        date?: any;
        hidden?: boolean;
        onClickIcon?: any;              //Azione al click dell'icona
        onClickShow?: any;              //Azione al click della visualizzazione - NOT USED FOR NOW
        url?: any;                      //Link al click della visualizzazione
        disableMessageChoice?: any;     //Se si vuole consentire all'utente la possibilità di disattivare il messaggio
    }

export default GenericTodo;
