import React, {useCallback, useEffect, useRef, useState} from 'react'
import {useHistory, useLocation} from 'react-router-dom'
import {Label} from 'design-react-kit'
import {getDefaultAxiosConfig, getFormazioneResourceApi} from 'src/app/utils/ManagerRestGateway'
import GenericBreadCrumb from 'src/frontend/app/components/GenericBreadCrumb'
import GenericTab from 'src/frontend/app/components/GenericTab'
import {createFormData, getTabActiveFormId, handleChangeFormData, validateFormData} from 'src/app/utils/form-utils'
import formValidationRules from 'src/frontend/app/pages/iscrizioneAlboEF/formCreazioneIndividuale/FormRules'
import {convertBooleans} from 'src/utilities/utility'
import {STATI_EVENTO_NON_A_CATALOGO_VALUES} from 'src/formazione'

// Form components
import FormDatiEvento from 'src/frontend/app/pages/iscrizioneAlboEF/formCreazioneIndividuale/FormDatiEvento'
import FormSubmit, {STATUS} from 'src/frontend/app/components/Form/GenericSubmit'
import {PageSection, Title} from '@patternfly/react-core'
import moment from 'moment'

const title = 'Creazione Evento non a Catalogo o Individuale'
const listNavLink = [
  { id: 1, label: 'Dati Evento' },
  { id: 2, label: 'Conferma' },
]

const breadcrumbOptions = [
  { label: '', link: '/' },
  { label: '', link: '/' },
]

const CreazioneEvento: React.FunctionComponent = () => {
  const history = useHistory()
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const id = searchParams.get('id')
  const tab = searchParams.get('tab')

  // Tab component state
  const [activeTab, setActiveTab] = useState(1)
  const [message, setMessage] = useState('')

  // Form State
  const refForm = useRef<any>([])
  const [data, setData] = useState<any>({})
  const [errors, setErrors] = useState<any>({})
  const formData = createFormData(data, setData, errors, setErrors)

  // Submit state
  const [reqStatus, setReqStatus] = useState<any>()
  const [reqMessage, setReqMessage] = useState<any>()

  // update tab in search params
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search)
    searchParams.set('tab', `${activeTab}`)
    history.replace({ search: searchParams.toString() })
  }, [activeTab])

  // retrieve data from api
  useEffect(() => {
    const retrieveData = async () => {}

    // before the api retrieve the data from local storage
    const dataString = localStorage.getItem(`${id}`)
    const _data = dataString ? JSON.parse(dataString) : {}
    setData(_data)
    // if (id) retrieveData().catch(console.error)
  }, [id])

  // save data in local storage
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      localStorage.setItem(`${id}`, JSON.stringify(data))
    }, 200)

    return () => {
      clearTimeout(timeoutId)
    }
  }, [id, data])

  const onInitInput = (tab) => (cmp) => {
    refForm.current.push({
      tab: tab,
      cmp: cmp,
    })
  }

  const handleInput = (code, value) => {
    handleChangeFormData(formData, code, value, formValidationRules)
  }

  const onNextStep = useCallback(
    (e) => {
      e.preventDefault()
      const inputs = getTabActiveFormId(refForm, activeTab - 1)
      const isValid = validateFormData(formData, formValidationRules, inputs)
      if (!isValid) {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
        return
      }
      setMessage('')
      setActiveTab((tab) => tab + 1)
    },
    [activeTab, formData]
  )

  const previousStep = () => {
    if (reqStatus === STATUS.LOADING) {
      return
    }
    setReqStatus(null)
    setReqMessage(null)
    setActiveTab((tab) => tab - 1)
  }

  const onSubmit = useCallback(async () => {
    try {
      setReqStatus(STATUS.LOADING)

      const { data } = formData || {}
      const _data = convertBooleans(data)
      _data.stato = STATI_EVENTO_NON_A_CATALOGO_VALUES.RICHIESTA

      let max = null
      if (_data.evento && _data.dataInizio && _data.evento.dataFine) {
        const diffInDays = moment(_data.evento.data_fine).diff(moment(_data.evento.data_inizio), 'days')
        max = Math.floor(diffInDays)
      }

      if (!_data.tipologiaAttivita) {
        _data.crediti = 0
      } else {
        if (_data.tipologiaAttivita.um === 'H') {
          const totOre = _data.totOre
          if(_data.tipologiaAttivita.cod_macro_area != 'MREL'){
            if (totOre && totOre.indexOf(':') > 0) {
              const ore = parseInt(totOre.split(':')[0])
              const minuti = parseInt(totOre.split(':')[1])
              _data.crediti = ((ore + minuti / 60) / _data.tipologiaAttivita.ore_evt_battute) * _data.tipologiaAttivita.max_cfp_evt
            } else {
              _data.crediti = _data.tipologiaAttivita.max_cfp_evt
            }

          if (max) {
            _data.crediti = _data.crediti > max ? max : _data.crediti
          }
        }
        else{
          const ore = parseInt(totOre.split(':')[0])
          const minuti = parseInt(totOre.split(':')[1])
          if(minuti == 0){
            _data.crediti =  ore * _data.tipologiaAttivita.ore_evt_battute * _data.tipologiaAttivita.max_cfp_evt
          }
          else{
            _data.crediti =  (ore + 1) * _data.tipologiaAttivita.ore_evt_battute * _data.tipologiaAttivita.max_cfp_evt
          }
        }
        } else {
          if (_data.tipologiaAttivita.cod === 'AO' || _data.tipologiaAttivita.cod === 'AF') {
            _data.crediti = 0.25
          }
        }
      }

      _data.crediti = Math.round(_data.crediti * 1000) / 1000

      await getFormazioneResourceApi().msfoFormazioneInserisciEventoNonACatalogoPost(_data, getDefaultAxiosConfig())
      // localStorage.removeItem(`${id}`)
      setReqStatus(STATUS.SUCCESS)
      setReqMessage('Richiesta inviata con successo')
      setTimeout(() => {
        history.goBack()
      }, 500)
    } catch (e) {
      setReqStatus(STATUS.FAILURE)
      setReqMessage("Errore durante l'invio della richiesta")
    }
    return null
  }, [id, formData])

  return (
    <div className="container register-container">
      <GenericBreadCrumb paths={breadcrumbOptions}></GenericBreadCrumb>
      <div>
        <h1>{title}</h1>
      </div>
      <GenericTab
        hiddenAlert={message === ''}
        labelAlert={message}
        isSubmitted={false}
        onSubmit={onNextStep}
        previousStep={previousStep}
        activeTab={activeTab}
        listNavLink={listNavLink}
        listBodyTab={[
          {
            id: 1,
            body: <FormDatiEvento title="Dati Evento" data={data} errors={errors} onInitInput={onInitInput(0)} handleInput={handleInput} />,
          },
          {
            id: 2,
            body: (
              <FormSubmit title="Richiesta evento" buttonLabel="Invia Richiesta" alertMessage={reqMessage} status={reqStatus} onSubmit={onSubmit}>
                <Label>Per confermare e inviare la domanda cliccare "Invia Richiesta".</Label>
              </FormSubmit>
            ),
          },
        ]}
      />
      <PageSection>
        <div className="container register-container">
          <Title headingLevel="h3">Legenda</Title>
          <p>* Campo obbligatorio</p>
          <p>** Campo non modificabile compilato automaticamente</p>
        </div>
      </PageSection>
    </div>
  )
}

export default CreazioneEvento
