import * as React from 'react'
import {PageSection, Title} from '@patternfly/react-core'
import GenericBreadCrumb from 'src/frontend/app/components/GenericBreadCrumb'
import GenericCard from '../../components/GenericCard'
import GenericButton from '../../components/GenericButton'
import {
    PATH_TO_BO_ANAG_TRASF_ORDINE_ACCETTA,
    PATH_TO_BO_ANAG_TRASF_ORDINE_RICHIESTA
} from 'src/app/utils/RoutesConstants'
import GenericAlert from 'src/frontend/app/components/GenericAlert'
import {convertUrlParamsToObject} from 'src/utilities/utility'
import {FormAccettaTrasferimentoOrdine} from './formTrasferimentoOrdine/FormAccettaTrasferimentoOrdine'
import {getSoggettoByIdIscrizioneBO} from 'src/processes/Soggetto'
import {isCurrentMobileDevice} from 'src/utilities/deviceUtility'

class AccettaTrasferimentoOrdine extends React.Component<any, any> {
  constructor(props) {
    super(props)
    this.state = {
      message: '',
      typeMessage: 'default',
      idSoggetto: undefined,
      idIscrizione: undefined,
      idTrasferimentoOrdine: undefined,
    }
  }

  goToListaRichTrasferimento = (redirectLink: any) => {
    this.props.history.push({
      pathname: redirectLink,
    })
  }

  componentDidMount() {
    //recupero gli eventuali parametri dall'url
    var url = window.location.search?.split('?')[1]
    var urlParams = convertUrlParamsToObject(url)

    //recupero l'idIscrizione e l'idTrasferimentoOrdine dall'url
    var idTrasferimentoOrdine = urlParams?.idTrasferimentoOrdine
    var idIscrizione = urlParams?.idIscrizione

    getSoggettoByIdIscrizioneBO(idIscrizione).then((response) => {
      this.setState({ idSoggetto: response.data.idSoggetto })
    })

    this.setState({ idTrasferimentoOrdine: idTrasferimentoOrdine, idIscrizione: idIscrizione })
  }

  submitMessageResult = (alert: any) => {
    const element = document.getElementById('pageSectionId')
    if (element) element.scrollIntoView({ behavior: 'smooth' })
    this.setState({ message: alert.messaggio, typeMessage: alert.type })
    if (alert.type === 'success') {
      setTimeout(async () => {
        this.goToListaRichTrasferimento(PATH_TO_BO_ANAG_TRASF_ORDINE_RICHIESTA)
      }, 1000)
    }
  }

  render() {
    const { message, typeMessage, idIscrizione, idTrasferimentoOrdine, idSoggetto } = this.state
    return (
      <>
        <PageSection id="pageSectionId">
          <div className="container register-container">
            <GenericBreadCrumb
              paths={[
                { label: 'Anagrafica', link: '#' },
                { label: 'Elenco richieste trasferimento ordine', link: PATH_TO_BO_ANAG_TRASF_ORDINE_RICHIESTA },
                { label: 'Accetta', link: PATH_TO_BO_ANAG_TRASF_ORDINE_ACCETTA },
              ]}
            />
            <br></br>
            <Title headingLevel="h1" size="4xl">
              Accetta trasferimento ordine
            </Title>
            <br></br>
            <GenericAlert hidden={message === ''} label={message} color={typeMessage}></GenericAlert>
            <GenericCard
              isExpandibleCard={false}
              header={'Compila i seguenti campi'}
              body={
                <>
                  <FormAccettaTrasferimentoOrdine
                    messaggio={this.submitMessageResult}
                    idIscrizione={idIscrizione}
                    idSoggetto={idSoggetto}
                    idTrasferimentoOrdine={idTrasferimentoOrdine}
                  />
                </>
              }
            />
            <br></br>
            <div className="row">
              <div className={isCurrentMobileDevice() ? null : 'col-1'}>
                <GenericButton label={'INDIETRO'} color={'tertiary'} onClick={() => this.goToListaRichTrasferimento(PATH_TO_BO_ANAG_TRASF_ORDINE_RICHIESTA)} />
              </div>
              <div className={isCurrentMobileDevice() ? null : 'col-1'}>
                <GenericButton form={'idAccettaTrasfOrdine'} type={'submit'} label={'CONFERMA'} color={'primary'} isDisabled={typeMessage === 'success'} />
              </div>
            </div>
          </div>
        </PageSection>
        <br></br>
        <PageSection>
          <div className="container register-container">
            <Title headingLevel="h3">Legenda</Title>
            <p>* Campo obbligatorio</p>
          </div>
        </PageSection>
      </>
    )
  }
}

export default AccettaTrasferimentoOrdine
