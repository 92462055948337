import React, {useMemo} from 'react'
import {Form} from 'design-react-kit'
import useProvince from 'src/hooks/useProvince'
import {getValueAtPath} from 'src/utilities/utility'
import createWrpInput from 'src/frontend/app/components/Form/WrpInput'
import GenericCard from 'src/frontend/app/components/GenericCard'
import GenericInput from 'src/frontend/app/components/Form/Input'
import GenericSelect from 'src/frontend/app/components/Form/Select'
import {isCurrentMobileDevice} from 'src/utilities/deviceUtility'

const FormDatiSedeLegale: React.FunctionComponent<FormDatiSedeLegaleProps> = ({ title, data, handleInput, onInitInput, errors, viewOnly }) => {
  const objKey = 'datiSedeLegale'
  const WrpInput = useMemo(() => createWrpInput({ objKey, onInit: onInitInput, onChange: handleInput, viewOnly }), [])

  const provincia = getValueAtPath(data, `${objKey}.provincia`)?.value
  const comune = getValueAtPath(data, `${objKey}.comune`)?.value
  const { province, comuni } = useProvince(provincia, comune, () => {
    handleInput(`${objKey}.comune`, '')
  })

  return (
    <GenericCard cardHeader={title}>
      <Form>
        <div className="row">
          <div className={isCurrentMobileDevice() ? null : 'col-6'}>
            <WrpInput
              as={GenericSelect}
              id={'provincia'}
              placeholder="Seleziona Provincia"
              label="Provincia"
              suffix="**"
              defaultOptions={province}
              data={data}
              errors={errors}
            />
          </div>
          <div className={isCurrentMobileDevice() ? null : 'col-6'}>
            <WrpInput
              as={GenericSelect}
              id={'comune'}
              placeholder="Seleziona Comune"
              label="Comune"
              suffix="**"
              defaultOptions={comuni}
              data={data}
              errors={errors}
            />
          </div>
          <div className={isCurrentMobileDevice() ? null : 'col-6'}>
            <WrpInput as={GenericInput} id="indirizzo" type="text" label="Indirizzo" suffix="**" data={data} errors={errors} />
          </div>
          <div className={isCurrentMobileDevice() ? null : 'col-6'}>
            <WrpInput as={GenericInput} id="cap" type="text" label="Cap" suffix="**" data={data} errors={errors} />
          </div>
          <div className={isCurrentMobileDevice() ? null : 'col-6'}>
            <WrpInput as={GenericInput} id="codiceFiscaleGestore" type="text" label="Codice Fiscale Gestore" suffix="**" data={data} errors={errors} />
          </div>
        </div>
      </Form>
    </GenericCard>
  )
}

interface FormDatiSedeLegaleProps {
  data: any
  errors: any
  title: string
  handleInput: any
  onInitInput?: any
  viewOnly?: boolean
}

export default FormDatiSedeLegale
