import * as React from 'react'
import GenericSpinner from '../../components/GenericSpinner'
import {
    PATH_TO_BO_ANAG_CAMBIO_SEZIONE_RICHIESTA,
    PATH_TO_BO_ANAG_CAMBIO_SEZIONE_VISUALIZZA
} from 'src/app/utils/RoutesConstants'
import GenericCard from '../../components/GenericCard'
import {Form, PageSection, Title} from '@patternfly/react-core'
import GenericBreadCrumb from 'src/frontend/app/components/GenericBreadCrumb'
import GenericButton from '../../components/GenericButton'
import GenericTextInput from '../../components/GenericTextInput'
import {getCambioSezioneResourceApi, getDefaultAxiosConfig} from 'src/app/utils/ManagerRestGateway'
import {convertUrlParamsToObject, goTo} from 'src/utilities/utility'
import {FormFile} from '../iscrizione/formIscrizione/FormFile'
import {Label} from 'design-react-kit'
import GenericAlert from 'src/frontend/app/components/GenericAlert'
import {isCurrentMobileDevice} from 'src/utilities/deviceUtility'

const STATI = {
  1: 'INVIATA',
  2: 'CHIUSA',
  3: 'RESPINTA',
}

class VisualizzaCambioSezione extends React.Component<any, any> {
  constructor(props) {
    super(props)
    this.state = {
      isLoaded: false,
      datiCambioSezione: {},
      message: '',
      typeMessage: 'default',
    }
  }

  async componentDidMount() {
    //recupero gli eventuali parametri dall'url
    var url = window.location.search?.split('?')[1]
    var urlParams = convertUrlParamsToObject(url)

    //recupero l'idIscrizione e idCambioSezione dall'url
    var idCambioSezione = urlParams?.idCambioSezione
    var idIscrizione = urlParams?.idIscrizione

    this.setState({ isLoaded: false, idCambioSezione: idCambioSezione, idIscrizione: idIscrizione })

    var param: any = {
      idCambioSezione: idCambioSezione,
    }

    await getCambioSezioneResourceApi()
      .mswfCambioSezioneFindByIdCambioSezionePost(param, getDefaultAxiosConfig())
      .then(async (response) => {
        if (response && response.data && response.data.httpStatus !== 200)
          this.setState({ message: "C'è stato un errore durante il recupero dei dati: " + response.data.error, typeMessage: 'danger' })
        else {
          this.setState({ datiCambioSezione: response.data.returnedObject })
        }
      })
      .catch((err) => {
        setTimeout(() => {
          this.setState({ message: "C'è stato un errore durante il recupero dei dati: ", err, typeMessage: 'danger' })
        }, 1000)
      })

    this.setState({ isLoaded: true })
  }

  render() {
    const { isLoaded, datiCambioSezione, message, typeMessage } = this.state

    return isLoaded ? (
      <PageSection>
        <div className="container register-container">
          <GenericBreadCrumb
            paths={[
              { label: 'Anagrafica', link: '#' },
              { label: 'Lista richieste cambio sezione', link: PATH_TO_BO_ANAG_CAMBIO_SEZIONE_RICHIESTA },
              { label: 'Visualizza', link: PATH_TO_BO_ANAG_CAMBIO_SEZIONE_VISUALIZZA },
            ]}
          />
          <br></br>
          <Title headingLevel="h1" size="4xl">
            Visualizza richiesta cambio sezione
          </Title>
          <GenericAlert label={this.state.message} hidden={message === ''} color={typeMessage}></GenericAlert>
          <GenericCard
            header={'Dati cambio sezione'}
            body={
              <Form>
                <div className="row">
                  <div className={isCurrentMobileDevice() ? null : 'col-6'}>
                    <GenericTextInput
                      className="w-75"
                      id="cf"
                      type="text"
                      isReadOnly
                      defaultValue={datiCambioSezione.codiceFiscale ? datiCambioSezione.codiceFiscale : '-'}
                      label="Codice fiscale"
                    />
                  </div>
                  <div className={isCurrentMobileDevice() ? null : 'col-6'}>
                    <GenericTextInput
                      className="w-75"
                      id="nomeCognome"
                      type="text"
                      isReadOnly
                      defaultValue={datiCambioSezione.nome && datiCambioSezione.cognome ? datiCambioSezione.nome + ' ' + datiCambioSezione.cognome : '-'}
                      label="Nome e cognome"
                    />
                  </div>
                </div>
                <div className="row">
                  <div className={isCurrentMobileDevice() ? null : 'col-6'}>
                    <GenericTextInput
                      className="w-75"
                      id="sezione"
                      type="text"
                      isReadOnly
                      defaultValue={datiCambioSezione.cambioSezioneA ? 'Sezione ' + datiCambioSezione.cambioSezioneA : '-'}
                      label="Sezione richiesta"
                    />
                  </div>
                  <div className={isCurrentMobileDevice() ? null : 'col-6'}>
                    <GenericTextInput
                      className="w-75"
                      id="stato"
                      type="text"
                      isReadOnly
                      defaultValue={datiCambioSezione.stato ? STATI[datiCambioSezione.stato] : '-'}
                      label="Stato"
                    />
                  </div>
                </div>
                <div className="row">
                  <div className={isCurrentMobileDevice() ? null : 'col-6'}>
                    <GenericTextInput
                      className="w-75"
                      id="dataIscrizione"
                      type="date"
                      isReadOnly
                      defaultValue={datiCambioSezione.dataCambioSezione ? datiCambioSezione.dataCambioSezione : '-'}
                      label="Data richiesta cambio sezione"
                    />
                  </div>
                </div>
                <div className="row" hidden={datiCambioSezione?.flagTitoloEstero === 1}>
                  <div className={isCurrentMobileDevice() ? null : 'col-6'}>
                    <GenericTextInput
                      className="w-75"
                      id="tipoLaurea"
                      type="text"
                      isReadOnly
                      defaultValue={datiCambioSezione.tipologiaLaurea ? datiCambioSezione.tipologiaLaurea : '-'}
                      label="Tipo Laurea"
                    />
                  </div>
                  <div className={isCurrentMobileDevice() ? null : 'col-6'}>
                    <GenericTextInput
                      className="w-75"
                      id="classeLaurea"
                      type="text"
                      isReadOnly
                      defaultValue={datiCambioSezione.classeLaurea ? datiCambioSezione.classeLaurea : '-'}
                      label="Classe di laurea"
                    />
                  </div>
                </div>
                <div className="row" hidden={datiCambioSezione?.flagTitoloEstero === 1}>
                  <div className={isCurrentMobileDevice() ? null : 'col-6'}>
                    <GenericTextInput
                      className="w-75"
                      id="corsoLaurea"
                      type="text"
                      isReadOnly
                      defaultValue={datiCambioSezione.corsoLaurea ? datiCambioSezione.corsoLaurea : '-'}
                      label="Corso di laurea"
                    />
                  </div>
                  <div className={isCurrentMobileDevice() ? null : 'col-6'}>
                    <GenericTextInput
                      className="w-75"
                      type="text"
                      id="annoLaurea"
                      isReadOnly
                      defaultValue={datiCambioSezione.annoLaurea ? datiCambioSezione.annoLaurea : '-'}
                      label="Anno di laurea"
                    />
                  </div>
                </div>
                <div className="row" hidden={datiCambioSezione?.flagTitoloEstero === 1}>
                  <div className={isCurrentMobileDevice() ? null : 'col-6'}>
                    <GenericTextInput
                      className="w-75"
                      id="universita"
                      type="text"
                      isReadOnly
                      defaultValue={datiCambioSezione.universita ? datiCambioSezione.universita : '-'}
                      label="Università"
                    />
                  </div>
                  <div className={isCurrentMobileDevice() ? null : 'col-6'}>
                    <GenericTextInput
                      className="w-75"
                      id="tipologiaAbilitazione"
                      type="text"
                      isReadOnly
                      defaultValue={datiCambioSezione.tipologiaAbilitazione ? datiCambioSezione.tipologiaAbilitazione : '-'}
                      label="Tipologia di abilitazione"
                    />
                  </div>
                </div>
                <div hidden={datiCambioSezione?.flagTitoloEstero !== 1}>
                  <div className="row">
                    <div className={isCurrentMobileDevice() ? null : 'col-6'}>
                      <GenericTextInput
                        className="w-75"
                        type="date"
                        id="dataEstero"
                        isReadOnly
                        defaultValue={datiCambioSezione.dataAbilitazioneDecretoEstero ? datiCambioSezione.dataAbilitazioneDecretoEstero : '-'}
                        label="Data riconoscimento Titolo Estero"
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className={isCurrentMobileDevice() ? null : 'col-6'}>
                    <GenericTextInput
                      className="w-75"
                      type="text"
                      id="ateneo"
                      isReadOnly
                      defaultValue={datiCambioSezione.ateneo ? datiCambioSezione.ateneo : '-'}
                      label="Ateneo abilitazione"
                    />
                  </div>
                  <div className={isCurrentMobileDevice() ? null : 'col-6'}>
                    <GenericTextInput
                      className="w-75"
                      type="text"
                      id="annoAbilitazione"
                      isReadOnly
                      defaultValue={datiCambioSezione.annoAbilitazione ? datiCambioSezione.annoAbilitazione : '-'}
                      label="Anno abilitazione"
                    />
                  </div>
                </div>
                <div className="row">
                  <div className={isCurrentMobileDevice() ? null : 'col-6'}>
                    <GenericTextInput
                      className="w-75"
                      type="text"
                      id="decretoRicQualificaProf"
                      isReadOnly
                      defaultValue={datiCambioSezione.decretoRiconoscimentoQualProf ? datiCambioSezione.decretoRiconoscimentoQualProf : '-'}
                      label="Decreto di riconoscimento della qualifica prof.le"
                    />
                  </div>
                  <div className={isCurrentMobileDevice() ? null : 'col-6'}>
                    <GenericTextInput
                      className="w-75"
                      type="date"
                      id="dataRiconoscimento"
                      isReadOnly
                      defaultValue={datiCambioSezione.dataRiconoscimento ? datiCambioSezione.dataRiconoscimento : '-'}
                      label="Data di riconoscimento della qualifica prof.le"
                    />
                  </div>
                </div>
                <div className="row">
                  <Label>
                    <strong>File</strong>
                  </Label>
                  <div className={isCurrentMobileDevice() ? null : 'col-6'}>
                    <FormFile listaFiles={datiCambioSezione.listaAllegatoDTO != null ? datiCambioSezione.listaAllegatoDTO : []} messaggio={''} />
                  </div>
                </div>
              </Form>
            }
          ></GenericCard>
          <div className="row">
            <div className={isCurrentMobileDevice() ? null : 'col-1'}>
              <GenericButton label={'INDIETRO'} color={'secondary'} onClick={() => goTo(PATH_TO_BO_ANAG_CAMBIO_SEZIONE_RICHIESTA)} />
            </div>
          </div>
          <br></br>
        </div>
      </PageSection>
    ) : (
      <GenericSpinner />
    )
  }
}

export default VisualizzaCambioSezione
