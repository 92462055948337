import * as React from 'react'
import {Form} from '@patternfly/react-core'
import GenericTextInput from '../../../components/GenericTextInput'
import {inputRequiredValidation, isOnlyNumbers} from 'src/utilities/utility'
import {valutaIscrizioneSTP} from 'src/processes/ProcessoIscrizione'
import {isCurrentMobileDevice} from 'src/utilities/deviceUtility'

const isMobileDivice = isCurrentMobileDevice();

export class FormConfermaIscrizioneSTP extends React.Component<GeneralFormConfermaIscrizioneSTPProps, any> {

    handleSubmit = async event => {
        /*--------------------------------------------- Validazione campi obbligatori ------------------------------------------------*/
        var requiredFields = [
            isOnlyNumbers(event.target.idNumeroIscrizioneSTP.value) ? { value: event.target.idNumeroIscrizioneSTP.value, label: 'Numero iscrizione' } : { value: "", label: 'Numero iscrizione' },
            { value: event.target.idDataIscrizioneSTP.value, label: 'Data iscrizione' },
            { value: event.target.idDeliberaIscrizioneSTP.value, label: 'Delibera iscrizione' }
        ]

        var errorRequired: any = inputRequiredValidation(requiredFields)
        if (errorRequired !== undefined && errorRequired !== "") {
            event.preventDefault()
            return this.props.messaggio({ messaggio: errorRequired.messaggio, type: errorRequired.type })
        }
        /*------------------------------------------------------------------------------------------------------------------------------*/
        else {
            //chiamata al servizio per conferma iscrizione STP
            var esitoValutazione = "iscritto"
            var idIscrizione = this.props.idIscrizione
            var idSoggetto = this.props.idSoggetto
            var parametriConfermaSTP = {
                numeroIscrizione: event.target.idNumeroIscrizioneSTP.value ? event.target.idNumeroIscrizioneSTP.value : "",
                dataIscrizione: event.target.idDataIscrizioneSTP.value ? event.target.idDataIscrizioneSTP.value : null,
                deliberaIscrizione: event.target.idDeliberaIscrizioneSTP.value ? event.target.idDeliberaIscrizioneSTP.value : "",
            }
            try {
                event.preventDefault()
                await valutaIscrizioneSTP(esitoValutazione, idIscrizione, idSoggetto, parametriConfermaSTP, [])
                return this.props.messaggio({ messaggio: "Dati salvati con successo", type: "success" })
            }
            catch (err) {
                return this.props.messaggio({ messaggio: "C'è stato un problema nell'invio dei dati", type: "danger" })
            }
        }
    }

    render() {
        return (
            <div>
                <Form id='idConfermaIscrizioneSTP' onSubmit={this.handleSubmit}>
                    <div className='row'>
                        <div className={!isMobileDivice? 'col-6' : 'col-12' }>
                            <GenericTextInput type={'only-number'} className='w-100' label={"Numero iscrizione"} id={'idNumeroIscrizioneSTP'} placeholder={'Inserire numero iscrizione'} isRequired />
                        </div>
                        <div className={!isMobileDivice? 'col-6' : 'col-12' }>
                            <GenericTextInput type={'date'} className='w-100' label={"Data iscrizione"} id={'idDataIscrizioneSTP'} placeholder={'Selezionare data'} style={{ paddingRight: '25px' }} isRequired />
                        </div>
                    </div>
                    <div className='row'>
                    <div className={!isMobileDivice? 'col-6' : 'col-12' }>
                            <GenericTextInput type={'text'} className='w-100' label={"Delibera iscrizione"} id={'idDeliberaIscrizioneSTP'} placeholder={'Inserire delibera iscrizione'} isRequired />
                        </div>
                    </div>
                </Form>
            </div>
        )
    }
}

export declare interface GeneralFormConfermaIscrizioneSTPProps {
    messaggio?: any;
    idIscrizione?: any;
    idSoggetto?: any;
}
