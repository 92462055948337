/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-console */
import React, {FunctionComponent, useEffect, useState} from 'react'
import {
    ActionsColumn,
    ExpandableRowContent,
    IAction,
    TableComposable,
    Tbody,
    Td,
    Th,
    Thead,
    ThProps,
    Tr
} from '@patternfly/react-table'

import {
    ActionList,
    ActionListItem,
    PageSection,
    PageSectionVariants,
    Pagination,
    PaginationVariant
} from '@patternfly/react-core'
import {saveAs} from 'file-saver'
import GenericSearchInput from './GenericSearchInput'
import GenericDescriptionList from './GenericDescriptionList'
import GenericMenuToogle from './GenericMenuToggle'
import GenericInput from 'src/frontend/app/components/GenericInput'
import GenericTextInput from './GenericTextInput'
import {toCamelCase} from 'src/utilities/utility'
import {Icon} from 'design-react-kit'
import GenericButton from 'src/frontend/app/components/GenericButton'
import {useLocation} from 'react-router-dom'
import {PATH_TO_BO_POLIZZE_ELENCO, PATH_TO_FO_ASSICURAZIONI} from 'src/app/utils/RoutesConstants'
import GenericSpinner from './GenericSpinner'
import moment from 'moment'
import GenericSelect from 'src/frontend/app/components/GenericSelect'
import {isCurrentMobileDevice} from 'src/utilities/deviceUtility'
import GenericDatePicker from './GenericDatePicker'

export const GenericTable: FunctionComponent<GeneralTableProps> = (props) => {
  const initialExpandedrowNames = props.rows && props.rows?.filter((row) => !!row.nestedComponent).map((row) => row.name) // Default to all expanded
  const [expandedrowNames, setExpandedrowNames] = useState<string[]>(initialExpandedrowNames)
  const setrowExpanded = (row: any, isExpanding = true) =>
    setExpandedrowNames((prevExpanded) => {
      const otherExpandedrowNames = prevExpanded.filter((r) => r !== row.id)
      return isExpanding ? [...otherExpandedrowNames, row.id] : otherExpandedrowNames
    })
  const isrowExpanded = (rows: GeneralTableProps, id) => expandedrowNames.includes(id)
  const [perPage, setPerPage] = useState(props.perPageNumber)
  const [page, setPage] = useState(1)
  const [downloaded, setDownloaded] = useState(true)
  const [globalSearchParam, setGloablSearchParam] = useState('')
  const buildFilterparams = (params) => {
    return params.map((e, id) => ({ filterParamValue: '', columnIndex: id }))
  }
  const [filterParams, setfilterParams] = useState<any>(props.preFilteredTable ? props.preFilteredTable : buildFilterparams(props.columns))
  const [totalPage, setTotalPage] = useState(props.filterAndPaginationData?.totalPageCount)
  const [pageCount, setPageCount] = useState(props.filterAndPaginationData?.pageCount)
  const [rows, setRows] = useState(
    (props.rows && props.rows.slice(0, perPage) !== null) || (props.rows && props.rows.slice(0, perPage) !== undefined) ? props.rows.slice(0, perPage) : []
  )
  const [activeSortIndex, setActiveSortIndex] = useState<number>(-1)
  const [activeSortDirection, setActiveSortDirection] = useState<'asc' | 'desc' | undefined>(undefined)
  const [hiddenFilter, setHiddenFilter] = useState(true)
  const location = useLocation()
  const [currentPath, setCurrentPath] = useState('')
  const [isLoaded, setIsLoaded] = useState(false)
  const tableHeadFontSize = props.tableHeadFontSize
  const tableContentFontSize = props.tableContentFontSize
  const isMobileDivice = isCurrentMobileDevice()
  const defaultActions = (dropdownItemList: any): IAction[] => {
    return dropdownItemList.map((dropdownItem) => {
      return {
        title: dropdownItem.title,
        onClick: dropdownItem.onClick,
        disabled: dropdownItem.disabled,
        hidden: dropdownItem.hidden,
      }
    })
  }

  const listsCall = (newPerPage, currentPage, globalSearchParam, filterParams) => {
    var sortParam = {}
    setIsLoaded(false)
    if (props.columns[activeSortIndex]) sortParam = { [toCamelCase(props.columns[activeSortIndex])]: activeSortDirection }
    props
      .methodNameCall(
        props.filterAndPaginationData?.codiceProvinciaResidenza,
        globalSearchParam,
        newPerPage,
        props.filterAndPaginationData?.listaStati,
        currentPage,
        filterParams,
        sortParam
      )
      .then((response: any) => {
        if (response && response !== null) {
          var responseDataList = JSON.parse(response)
          setTotalPage(responseDataList.totalCount)
          setPageCount(responseDataList.pageCount)
          setRows(responseDataList.content)
          setIsLoaded(true)
        }
      })
      .catch((err) => {
        setRows([])
        setIsLoaded(false)
        console.error('Exception in GenericTable: ', err)
      })
  }

  /*
   *Start Pagination
   */
  useEffect(() => {
    setIsLoaded(false)
    setCurrentPath(location.pathname)
    if (props.methodNameCall !== undefined) {
      listsCall(perPage, page, globalSearchParam, filterParams)
    } else {
      if (rows) {
        setIsLoaded(true)
      }
    }
  }, [page, globalSearchParam, filterParams, activeSortDirection, location])

  const handlePerPageSelect = (_evt, newPerPage) => {
    setPerPage(newPerPage)
    if (props.methodNameCall !== undefined) listsCall(newPerPage, page, globalSearchParam, filterParams)
  }

  const handleNextClick = () => {
    setPage((prevState) => prevState + 1)
  }

  const handlePreviusClick = () => {
    setPage((prevState) => prevState - 1)
  }

  const handleLastClick = () => {
    setPage(pageCount)
  }

  const handleFirstClick = () => {
    setPage(1)
  }

  const getSortParams = (columnIndex: number): ThProps['sort'] => ({
    sortBy: {
      index: activeSortIndex,
      direction: activeSortDirection,
      defaultDirection: undefined, // starting sort direction when first sorting a column. Defaults to 'asc'
    },
    onSort: (_event, index, direction) => {
      setActiveSortIndex(index)
      setActiveSortDirection(direction)
    },
    columnIndex,
  })

  /**
   * Get word from searchInput
   */
  const searchTable = (event) => {
    if (props.isGlobalSearchActive !== undefined) props.isGlobalSearchActive(true)
    setGloablSearchParam(event)
  }

  /**
   * Filter the table
   */
  const handleInputFilter = (event, columnIndex) => {
    if (props.isFilterActive !== undefined) props.isFilterActive(true)
    filterDataTable(event, columnIndex)
  }

  const filterDataTable = (event, index) => {
    var newFilterParam = [...filterParams]
    var findColumnIndexInArray
    findColumnIndexInArray = filterParams.map((index) => index.columnIndex).indexOf(index)
    if (findColumnIndexInArray !== -1) {
      newFilterParam[findColumnIndexInArray] = { filterParamValue: event, columnIndex: index }
    }
    setfilterParams(newFilterParam)
  }

  /**
   * Open filter option bar
   */
  const openMenuToggle = () => {
    if (hiddenFilter) setHiddenFilter(false)
    else setHiddenFilter(true)
  }

  /**
   * Prendo il valore da select e optionMenu.
   * @param {Object|string|Event} e - L'oggetto evento o il valore selezionato.
   * @param {number} index - L'indice dell'elemento selezionato.
   * @param {any} selectedValue - valore dell'opzione selezionata da passare al filtro
   */
  const handleSelect = (e, index) => {
    let selectedValue: any
    if (e?.value) {
      selectedValue = e.value[0]
    } else if (e?.target?.value) {
      selectedValue = e.target.value
    } else {
      throw new Error('Elemento non gestito in HandleSelect')
    }
    filterDataTable(selectedValue, index)
  }
  /**
   * reset dei filtri
   */
  const onResetFilters = () => {
    const resetFilterParams = filterParams.map((param) => ({ ...param, filterParamValue: '' }))
    setfilterParams(resetFilterParams)
  }

  const optionsMenuItems = (array, index) => {
    return array?.map((data) => ({
      label: data.label,
      value: data.value ? data.value : data.label,
      selected: data.selected,
      onSelect: (e) => {
        handleSelect(e, index)
        props.selectOptionItem(e, index)
      },
    }))
  }

  const thElementStyle = () => {
    if (tableHeadFontSize) {
      return {
        fontSize: tableHeadFontSize,
      }
    } else {
      return {}
    }
  }
  const tdElementStyle = () => {
    if (tableContentFontSize) {
      return {
        fontSize: tableContentFontSize,
      }
    } else {
      return {}
    }
  }

  const manageCsv = async () => {
    setDownloaded(false)
    var sortParam = {}
    if (props.columns[activeSortIndex]) sortParam = { [toCamelCase(props.columns[activeSortIndex])]: activeSortDirection }
    if (props.methodNameCallForCsv !== undefined) {
      props
        .methodNameCallForCsv(
          props.filterAndPaginationData?.codiceProvinciaResidenza,
          globalSearchParam,
          props.filterAndPaginationData?.listaStati,
          filterParams,
          sortParam
        )
        ?.then((response: any) => {
          if (response && response !== null) {
            var responseDataList: any = JSON.parse(response)
            const separatorCSV = ';'
            const contentTypeCSV = 'text/csv;charset=utf-8'
            const fileNameCSV = 'estrazione_' + props.tipoElenco + '_' + moment(Date.now()).format('YYYYMMDDHHmmss') + '.csv' //es. formato filename: estrazione_assicurazioni_16032023155037.csv
            const rowsCsv = responseDataList.content.map((e) => e.data)
            const csvData = [props.columns, ...rowsCsv].map((row) => row.join(separatorCSV)).join('\n')
            const blob = new Blob([csvData], { type: contentTypeCSV })
            saveAs(blob, fileNameCSV)
            setDownloaded(true)
          }
        })
        .catch((err) => {
          console.error('Eccezione in genericTable --> ', err)
        })
    } else if (props.methodNameCallForCsvRaw !== undefined) {
      props
        .methodNameCallForCsvRaw(
          props.filterAndPaginationData.codiceProvinciaResidenza,
          globalSearchParam,
          props.filterAndPaginationData?.listaStati,
          filterParams,
          sortParam
        )
        ?.then((response: any) => {
          if (response && response !== null) {
            if (response.downloaded === true) {
              setDownloaded(true)
            } else {
              //che ci faccio?
              setDownloaded(true)
            }
          }
        })
        .catch((err) => {
          console.error('Eccezione in genericTable --> ', err)
        })
    }
  }

  return (
    <>
      <div className="row">
        <div className={!isMobileDivice ? 'col-10' : 'col-6'}>
          <ActionList hidden={props.hideFilters}>
            <ActionListItem className={!isMobileDivice ? 'col-1' : null}>
              <GenericMenuToogle onClick={() => openMenuToggle()} />
            </ActionListItem>
            <ActionListItem className={!isMobileDivice ? 'col-4' : null}>
              <GenericSearchInput
                isHidden={props.hideSearch}
                style={{ paddingLeft: '35px', width: '100%' }}
                placeholder="Cerca"
                onChange={searchTable}
              ></GenericSearchInput>
            </ActionListItem>
            <ActionListItem className={!isMobileDivice ? 'col-3' : null}>
              <GenericButton onClickEvent={manageCsv} isHidden={props.hideCsv}>
                {downloaded ? (
                  <>
                    <Icon icon={'it-download'} size="sm" />
                    Scarica CSV
                  </>
                ) : (
                  <>
                    <GenericSpinner size="lg" />
                    Download in corso...
                  </>
                )}
              </GenericButton>
            </ActionListItem>
            <ActionListItem className="col-6">
              <Pagination
                hidden={props.hidePagination}
                itemCount={totalPage}
                page={page}
                perPage={perPage}
                variant={PaginationVariant.top}
                isCompact={false}
                defaultToFullPage
                onPerPageSelect={handlePerPageSelect}
                onNextClick={handleNextClick}
                onPreviousClick={handlePreviusClick}
                onLastClick={handleLastClick}
                onFirstClick={handleFirstClick}
                perPageOptions={[
                  { title: '5', value: 5 },
                  { title: '10', value: 10 },
                  { title: '20', value: 20 },
                ]}
              />
            </ActionListItem>
          </ActionList>
        </div>
      </div>
      <div className="mt-2" hidden={hiddenFilter}>
        <PageSection
          className={!isMobileDivice ? 'filterSection' : 'filterSection col-8'}
          padding={{ default: 'noPadding' }}
          variant={PageSectionVariants.light}
        >
          <>
            {/*  la sezione restituiva un nuovo array escludendo gli elementi di filtersToHide, quindi gli indici erano sfalzati - Lorenzo 06-09-2023 */}
            {/* OLD {props.columns.filter((f, index) => !props.filtersToHide?.includes(index)).map((column, index) => { */}

            {/* evita la costruzione dell'elemento del filtro se  l'id viene incluso in filterToHide */}
            {props.columns.map((column, index) => {
              if (props.filtersToHide?.includes(index)) {
                return null
              }

              var optionsFiltri =
                props.filterAndPaginationData?.nestedDataInFilter && !props.columnsTypes[0].dropdownColumnID?.includes(index)
                  ? props.filterAndPaginationData.nestedDataInFilter[index]
                  : undefined

              return (
                <div key={index} className={'filterElem'} hidden={props.filtersToHide?.includes(index)}>
                  {props.columnsTypes === undefined ||
                    (props.columnsTypes[0].dropdownColumnID?.includes(index) === false &&
                      // <div className={'pe-3'}>
                      //   <GenericOptionsMenu
                      //     key={"dropdownIDPrimary"+ column  + index}
                      //     placeholder={column}
                      //     hidden={props.filtersToHide?.includes(index)}
                      //     optionsMenuItems={optionsMenuItems(optionsFiltri, index)}
                      //   />
                      // </div>
                      !props.filtersToHide?.includes(index) && (
                        <div className="pe-3" style={{ minWidth: '230px', display: 'auto', marginTop: '5%' }}>
                          <GenericSelect
                            placeholder={column}
                            name={column}
                            key={'dropdownIDPrimary' + index}
                            defaultOptions={optionsMenuItems(optionsFiltri, index)}
                            /* Uso value invece di defaultValue per pulire il valore al reset dei filtri*/
                            /*
                            defaultValue={
                              optionsFiltri && optionsFiltri.find((item) => item.selected === true)
                                ? optionsFiltri.find((item) => item.selected === true)
                                : null
                            }
                            */
                            value={optionsFiltri && optionsFiltri.find((item) => item.selected === true)
                              ? optionsFiltri.find((item) => item.selected === true)
                              : null}
                            onChange={(e) => {
                              handleSelect(e, index)
                              props.selectOptionItem(e, index)
                            }}
                          />
                        </div>
                      ))}
                  {props.columnsTypes === undefined ||
                    (props.columnsTypes[0].inputColumnID?.includes(index) === false && (
                      <>
                        <GenericTextInput
                          id={'idPrimaryFilterText' + index}
                          placeholder={'Cerca ' + column}
                          isHidden={props.filtersToHide?.includes(index)}
                          value={filterParams[index].filterParamValue}
                          type={'text'}
                          onChange={(e) => handleInputFilter(e, index)}
                        />
                      </>
                    ))}
                  {props.columnsTypes === undefined ||
                    (props.columnsTypes[0].dateColumnID?.includes(index) === false && (
                      <>
                        {/* <GenericTextInput
                          id={"idPrimaryFilterText" + index}
                          type={'date'}
                          isHidden={props.filtersToHide?.includes(index)}
                          onChange={e => handleInputFilter(e, index)}
                          placeholder={"Cerca " + column}
                        />  */}

                        <GenericDatePicker
                          id={'idPrimaryFilterText' + index}
                          isHidden={props.filtersToHide?.includes(index)}
                          onChange={(e) => handleInputFilter(e, index)}
                        />
                      </>
                    ))}
                </div>
              )
            })}
            <GenericButton
              type="button"
              onClickEvent={() => {
                onResetFilters()
                setRows(props.rows && props.rows.slice(0, perPage))
                props.resetFilter()
              }}
              children={<Icon key={'delete'} size="sm" color="danger" icon="it-delete" />}
            ></GenericButton>
          </>
        </PageSection>
      </div>
      <TableComposable hidden={props.hidden} aria-label="Sort table" variant={props.variant} gridBreakPoint={!isMobileDivice ? '' : 'grid-lg'}>
        <Thead className={props.className}>
          <>
            <Tr>
              <Th className={props.thClassName} style={thElementStyle()} width={30}></Th>

              {!props.autoWidthColumns &&
                props.columns.map((column, index) => {
                  return (
                    <Th
                      className={props.thClassName}
                      style={thElementStyle()}
                      key={index}
                      hidden={
                        props.columnsToHide?.includes(
                          index
                        ) /* || column === "idIscrizione" || column === "idPolizza" || column === "codiceGruppo" || column === "provincia"*/
                      }
                      sort={getSortParams(index)}
                    >
                      {column}
                    </Th>
                  )
                })}

              {props.autoWidthColumns &&
                props.columns.map((column, index) => {
                  return (
                    <th
                      key={index}
                      style={{ maxWidth: 'initial' }}
                      hidden={
                        props.columnsToHide?.includes(
                          index
                        ) /* && (column === "idIscrizione" || column === "idPolizza" || column === "codiceGruppo" || column === "provincia")*/
                      }
                    >
                      <span className={props.thClassName} style={thElementStyle()}>
                        {column}
                      </span>
                    </th>
                  )
                })}
              <Th className={props.thClassName} style={thElementStyle()} hidden={props.hideActionColumn}></Th>
            </Tr>
          </>
        </Thead>
        {rows.map((row, rowIndex, index) => (
          <Tbody key={rowIndex} isExpanded={isrowExpanded(row, row.id)} hidden={!isLoaded}>
            <Tr key={index} style={row?.data[4]?.includes('FODAF') ? props.styleRows : null}>
              <>
                <Td
                  expand={
                    row.nestedComponent
                      ? {
                          rowIndex,
                          isExpanded: isrowExpanded(row, row.id),
                          onToggle: () => setrowExpanded(row, !isrowExpanded(row, row.id)),
                          expandId: 'composable-nested-table-expandable-example',
                        }
                      : undefined
                  }
                />
              </>
              {row.data.map((rowData, id) => {
                return (
                  <Td
                    hidden={props.columnsToHide?.includes(id)}
                    key={id}
                    dataLabel={props.columns[id]}
                    style={!isMobileDivice ? null : { textAlign: 'center' }}
                  >
                    <span style={tdElementStyle()} hidden={!props.isEditableTable}>
                      <GenericInput type={'text'} defaultValue={rowData} onChange={(e) => (rowData = e.target.value)} />
                    </span>
                    <span style={tdElementStyle()} hidden={props.isEditableTable}>
                      {!props.columnsToHide?.includes(id) ? rowData : null}
                    </span>
                  </Td>
                )
              })}
              {currentPath === PATH_TO_BO_POLIZZE_ELENCO ? (
                <>
                  <Td dataLabel={'FILE'} style={!isMobileDivice ? null : { textAlign: 'center' }}>
                    {/* <a href="#!">Scarica certificato <Icon className="" color="primary" icon="it-download" size="" /></a> */}
                    <a
                      hidden={(row.data[0] && row.data[0].includes('Individuale')) || row.certificatoIsEmpty === true}
                      onClick={() => props.downloadFunction('certificato', row)}
                      style={tdElementStyle()}
                      href="#!"
                    >
                      CERTIFICATO
                    </a>{' '}
                    <a
                      hidden={(row.data[0] && row.data[0].includes('Individuale')) || row.pagamentoIsEmpty === true}
                      onClick={() => props.downloadFunction('copia_pagamento', row)}
                      style={tdElementStyle()}
                      href="#!"
                    >
                      PAGAMENTO
                    </a>
                  </Td>
                </>
              ) : currentPath === PATH_TO_FO_ASSICURAZIONI ? (
                <>
                  <Td dataLabel={'FILE'} style={!isMobileDivice ? null : { textAlign: 'center' }}>
                    {/* <a href="#!">Scarica certificato <Icon className="" color="primary" icon="it-download" size="" /></a> */}
                    <a
                      hidden={(row.data[9] && row.data[9].includes('Individuale')) || row.certificatoIsEmpty === true}
                      onClick={() => props.downloadFunction('certificato', row)}
                      style={tdElementStyle()}
                      href="#!"
                    >
                      CERTIFICATO
                    </a>{' '}
                    <a
                      hidden={(row.data[9] && row.data[9].includes('Individuale')) || row.pagamentoIsEmpty === true}
                      onClick={() => props.downloadFunction('copia_pagamento', row)}
                      style={tdElementStyle()}
                      href="#!"
                    >
                      PAGAMENTO
                    </a>
                  </Td>
                </>
              ) : null}
              <Td
                onClick={() => {
                  props.onSelect(row, rowIndex)
                }}
                dataLabel={'Actions'}
                hidden={props.hideActionColumn}
              >
                <ActionsColumn items={defaultActions(props.dropdownList != null ? props.dropdownList : [])} />
              </Td>
            </Tr>
            {row.nestedComponent && row.nestedComponent.length > 0 ? (
              <Tr className={!isMobileDivice ? null : 'col-8'} isExpanded={isrowExpanded(row, row.id)}>
                <Td></Td>
                <Td></Td>
                <Td>
                  <ExpandableRowContent>
                    <GenericDescriptionList items={row.nestedComponent} />
                  </ExpandableRowContent>
                </Td>
              </Tr>
            ) : null}
          </Tbody>
        ))}
      </TableComposable>

      {!isLoaded && (
        <div className={'mt-5 mb-5'}>
          <GenericSpinner size={'xl'} />
        </div>
      )}

      <Pagination
        hidden={props.hidePagination}
        itemCount={totalPage}
        page={page}
        perPage={perPage}
        isCompact={false}
        variant={PaginationVariant.bottom}
        defaultToFullPage
        onPerPageSelect={handlePerPageSelect}
        onNextClick={handleNextClick}
        onPreviousClick={handlePreviusClick}
        onLastClick={handleLastClick}
        onFirstClick={handleFirstClick}
        perPageOptions={[
          { title: '5', value: 5 },
          { title: '10', value: 10 },
          { title: '20', value: 20 },
        ]}
      />
    </>
  )
}

export declare interface GeneralTableProps {
  rows?: any
  hideCsv?: any
  tipoElenco?: any
  columns?: any
  hideActionColumn?: any
  hideCertificateColumn?: any
  dropdownList?: IAction[]
  downloadFunction?: any
  actions?: any
  onSelect?: any
  getCertificato?: any
  filters?: any
  hidePagination?: any
  hideSearch?: any
  hideDropdown?: any
  hideFilters?: any
  hidden?: any
  filtersToHide?: any //Specifica i filtri da Nascondere
  isEditableTable?: boolean
  filterAndPaginationData?: any
  perPageNumber?: number
  methodNameCall?: any
  methodNameCallForCsv?: any //ottiene un json data json e lo apre come csv
  methodNameCallForCsvRaw?: any //aspetta solo la notifica che il csv è stato scaricato
  autoWidthColumns?: any
  className?: any
  thClassName?: any
  variant?: any

  /**
   * Inserisci gli indici delle colonne che vuoi nascondere
   */
  columnsTypes?: any

  /**
   * Inserisci gli indici delle colonne che vuoi nascondere per tipo
   */
  columnsToHide?: any

  tableHeadFontSize?: any
  tableContentFontSize?: any
  styleRows?: any
  /*
   * definisce la modalità di visualizzazione della griglia, fitContentType: initial aggiusta le colonne e non va vedere il tooltip
   * se non valorizzato passa il valore standard fit-content
   */
  fitContentType?: any
  /**
   * Diventa true se vengono azionati i filtri
   */
  isFilterActive?: any
  /**
   * diventa true se viene azionata la ricerca
   */
  isGlobalSearchActive?: any
  /*
   * Funzione che aggiorna l'elemento selezionato in una select in un filtro
   */
  selectOptionItem?: any
  /*
   * Funzione che consente di avere una tabella prefiltrata in base a ciò che viene passata in questa props come filtro
   */
  preFilteredTable?: any

  resetFilter?: any
}

export default GenericTable
