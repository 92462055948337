import {FormGroup} from 'design-react-kit'
import React from 'react'
import {getAllProvince} from 'src/processes/Province'
import {getComuniByProvinciaMsga} from 'src/processes/Comuni'
import GenericCard from '../../../components/GenericCard'
import GenericInput from '../../../components/GenericInput'
import GenericSelect from '../../../components/GenericSelect'
import GenericSpinner from 'src/backoffice/app/components/GenericSpinner'
import {isCurrentMobileDevice} from 'src/utilities/deviceUtility'

export default class DatiResidenziali extends React.Component<DatiResidenzialiProps, any>{
  constructor(props) {
    super(props);
    this.state = {
      activeTab: props.activeTab,
      tabClick: props.tabClick,
      prevData: props.prevData,
      prevClicked: props.prevClicked,
      listaComuni: [],
      fields:  {},
      isLoaded: false,
      message: ''
    };
  }

  async componentDidMount(): Promise<void> {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    if (!this.state.isLoaded && !this.props.visualizzaFlag) {
      /*************************** GET Lista province ********************************/
      await getAllProvince().then((response: any) => {
        var province = [{ label: '', value: '' }];
        response.data.map((provincia, id) => {
          province[id] = {
            label: provincia['denominazioneUnitaTerritorialeSovracomunale'],
            value: provincia['siglaAutomobilistica']
          };
        })

        let sortedProvince = province.sort(
          (p1, p2) => (p1.label > p2.label) ? 1 : (p1.label < p2.label) ? -1 : 0);
        this.setState({ province: sortedProvince })
      })
    }
    if (!this.props.visualizzaFlag) {
      if (this.props.data) {
        let data = this.props.data;
        this.setState({ fields: data })
      }
    }


    if(this.props.data.provinciaResidenza
        && this.props.data.provinciaResidenza !== undefined
        && this.props.data.provinciaResidenza.label !== ""
        && this.props.data.provinciaResidenza?.label !== 'Stato Estero') {
        await getComuniByProvinciaMsga(this.props.data.provinciaResidenza?.label).then(async comuni => {
          this.setState({ listaComuni: comuni, provinciaSelected: true, fields: this.props.data })
          await this.props.getSelectedValue(this.props.data)
        });
    }
    else if(this.props.visualizzaFlag && this.props.data) {
          this.setState({ fields: this.props.data })
    }
    else if (this.props.data) {
      let data = this.props.data;
      this.setState({ fields: data })
    }
    this.setState({ isLoaded: true })

    if (this.props.data.provinciaResidenza?.label === 'Stato Estero') {
      this.setState({ listaComuni: [], isLoaded: true, provinciaSelected: true })
    }
  }

  render() {
    const { province } = this.state;
    const { tabNumber } = this.props;

    const setDataItem = async (e: any, type: any, field: any) => {

      let fields = this.state.fields;
      if (type === 'select') {
        fields[field] = { label: e.label, value: e.value };

        if(field === 'provinciaResidenza')
          this.props.getFlagSvuotaOrdine(true)
      }
      else if (type === 'checkbox') {
        fields[field] = e.target.checked;
      }
      else if (type === 'input') {
        if (this.state.fields['provinciaResidenza']?.label === 'Stato Estero' && field === 'comune') {
          fields['comune'] = { label: e.target.value, value: e.target.value };
        }
      }
      else {
        fields[field] = e.target.value;
      }
      this.setState({ fields });
      if (field === "provinciaResidenza") {

        if (this.state.fields['provinciaResidenza'].label === 'Stato Estero') {
          this.state.fields['provinciaResidenza'] = { label: 'Stato Estero', value: 'EEP'}
          this.setState({ listaComuni: [], isLoaded: true, provinciaSelected: true })
        }

        fields['comune'] = { label: '', value: '' };
        this.setState({provinciaSelected: false})

        await getComuniByProvinciaMsga(e.label).then(async comuni => {
          this.setState({ listaComuni: comuni, isLoaded: true, provinciaSelected: true })
        }).catch((err => {
          this.setState({ listaComuni: [], isLoaded: true, provinciaSelected: true })
        }))
      }

      this.props.getSelectedValue(fields)

    }

    /********************************* Form Iscrizione ************************************/

    const formIscrizione = [
      <GenericCard className="zero-padding" key={'datiResidenzialiIscrizione'} cardHeader="Dati residenziali e contatti" cardBody={
        <div key={'datiResidenzialiIscrizione'} id="datiResidenzialiIscrizione" className="">
          <div>
            <div className="row" hidden={this.props.visualizzaFlag}>
              <div className={isCurrentMobileDevice() ? "col-12" : "col-6"}>
                <FormGroup label={"provinciaResidenza"} >
                  <GenericSelect elementId="provinciaResidenza" name={'provinciaResidenza'} defaultValue={(this.state.fields?.provinciaResidenza && this.state.fields?.provinciaResidenza !== "") ? this.state.fields?.provinciaResidenza : null}
                    onChange={(e) => setDataItem(e, 'select', 'provinciaResidenza')} label='Provincia di residenza *'
                    placeholder='Selezionare provincia di residenza' defaultOptions={province} errorMessageAlert={this.props.message} isRequired currentStep={tabNumber} />
                </FormGroup>
              </div>
              {this.state.provinciaSelected ?
                    <div className={isCurrentMobileDevice() ? null : "col-6"} hidden={!this.state.fields['provinciaResidenza']}>
                      <FormGroup label={"comune"}>
                        {
                          (this.state.fields['provinciaResidenza']?.label === 'Stato Estero') ?
                            <GenericInput type={"text"} key="comune" id="comune" defaultValue={this.state.fields['comune'].label}
                            errorMessageAlert={this.props.message} isRequired currentStep={tabNumber}
                            onChange={(e) => setDataItem(e, 'input', 'comune')} label='Città di residenza *' placeholder='Inserire la città di residenza' />
                          :
                            <GenericSelect elementId="comune" name={'comune'} defaultValue={(this.state.fields?.comune && this.state.fields?.comune.label !== "") ? this.state.fields?.comune : null}
                            onChange={(e) => setDataItem(e, 'select', 'comune')} label='Città di residenza *' placeholder='Inserire la città di residenza' defaultOptions={this.state.listaComuni} errorMessageAlert={this.props.message} isRequired currentStep={tabNumber} />
                        }
                      </FormGroup>
                    </div>
              : null}
            </div>
            <div className="row" hidden={!this.props.visualizzaFlag}>
              <div className={isCurrentMobileDevice() ? null : "col-6"}> <FormGroup label={"provinciaResidenza"} >
                <GenericInput isDisabled id="provinciaResidenza" name={'provinciaResidenza'} defaultValue={(this.state.fields['provinciaResidenza']) ? this.state.fields['provinciaResidenza'].label : '-'}
                  onChange={(e) => setDataItem(e, 'select', 'provinciaResidenza')} label='Provincia di residenza *'
                  placeholder='Selezionare provincia di residenza' />
              </FormGroup></div>
              <div className={isCurrentMobileDevice() ? null : "col-6"}>
                <FormGroup label={"comune"} >
                  <GenericInput isDisabled id="comune" name={'comune'} defaultValue={(this.state.fields['comune']) ? this.state.fields['comune'] : '-'}
                    onChange={(e) => setDataItem(e, 'select', 'comune')} label='Comune di residenza *'
                    placeholder='Selezionare comune di residenza' />
                </FormGroup></div>
            </div>
            <div className="row" hidden={!this.state.fields['comune']}>
              <div className={isCurrentMobileDevice() ? null : "col-6"}><FormGroup label={"indirizzo"} >
                <GenericInput isDisabled={this.props.visualizzaFlag} id="indirizzo" name={'indirizzo'}
                  defaultValue={(this.state.fields['indirizzo']) ? this.state.fields['indirizzo'] :
                    (this.state.fields['indirizzo']) ? this.state.fields['indirizzo'] :
                      (this.props.visualizzaFlag && !this.state.fields['indirizzo']) ? '-' : null}
                  onChange={(e) => setDataItem(e, 'text', 'indirizzo')} label='Indirizzo di residenza *' placeholder='Inserire indirizzo di residenza' type={this.props.visualizzaFlag ? null : 'text'} errorMessageAlert={this.props.message} isRequired currentStep={tabNumber} />
              </FormGroup></div>
              <div className={isCurrentMobileDevice() ? null : "col-3"} ><FormGroup label={"numeroCivico"} >
                <GenericInput isDisabled={this.props.visualizzaFlag} id="numeroCivico" name={'numeroCivico'}
                  defaultValue={(this.state.fields['numeroCivico']) ? this.state.fields['numeroCivico'] : null}
                  onChange={(e) => setDataItem(e, 'text', 'numeroCivico')} label='Numero di residenza *' placeholder='Numero' type={this.props.visualizzaFlag ? null : 'text'} errorMessageAlert={this.props.message} isRequired currentStep={tabNumber} />
              </FormGroup></div>
              <div className={isCurrentMobileDevice() ? null : "col-3"} ><FormGroup label={"cap"} >
                <GenericInput isDisabled={this.props.visualizzaFlag} id="cap" name={'cap'} defaultValue={(this.state.fields['cap']) ? this.state.fields['cap'] : null}
                  onChange={(e) => setDataItem(e, 'text', 'cap')} label='CAP *' placeholder='CAP' type={"cap"} errorMessageAlert={this.props.message} isRequired currentStep={tabNumber} />
              </FormGroup></div>
            </div>
            <div className={isCurrentMobileDevice() ? null : "col-6"}><FormGroup label={"emailOrdinaria"} >
              <GenericInput isDisabled={this.props.visualizzaFlag} id="emailOrdinaria" name={'emailOrdinaria'} defaultValue={(this.state.fields['emailOrdinaria']) ? this.state.fields['emailOrdinaria'] : null}
                onChange={(e) => setDataItem(e, 'text', 'emailOrdinaria')} label='Email ordinaria *' placeholder='Inserire email' type={this.props.visualizzaFlag ? null : 'email'} errorMessageAlert={this.props.message} isRequired currentStep={tabNumber} />
            </FormGroup></div>
            <div className={isCurrentMobileDevice() ? null : "col-6"}><FormGroup label={"pec"} >
              <GenericInput isDisabled={this.props.visualizzaFlag} id="pec" name={'pec'} defaultValue={this.state.fields?.pec ? this.state.fields?.pec : null}
                onChange={(e) => setDataItem(e, 'text', 'pec')} label='PEC *' placeholder='Inserire pec' type={this.props.visualizzaFlag ? null : 'email'} errorMessageAlert={this.props.message} isRequired currentStep={tabNumber} />
            </FormGroup></div>
            <div className={isCurrentMobileDevice() ? null : "col-6"}><FormGroup label={"telefono"} >
              <GenericInput isDisabled={this.props.visualizzaFlag} id="telefono" name={'telefono'} defaultValue={(this.state.fields['telefono']) ? this.state.fields['telefono'] : null}
                onChange={(e) => setDataItem(e, 'text', 'telefono')} label='Telefono *' placeholder='Inserire numero' type={"tel"} errorMessageAlert={this.props.message} isRequired currentStep={tabNumber} />
            </FormGroup></div>
            <div className={isCurrentMobileDevice() ? null : "col-6"}><FormGroup label={"altroTelefono"} >
              <GenericInput isDisabled={this.props.visualizzaFlag} id="altroTelefono" name={'altroTelefono'} defaultValue={(this.state.fields['altroTelefono']) ? this.state.fields['altroTelefono'] : null}
                onChange={(e) => setDataItem(e, 'text', 'altroTelefono')} label='Altro Telefono' placeholder='Inserire numero' type={this.props.visualizzaFlag ? null : 'tel'} />
            </FormGroup></div>
          </div>

        </div>
      }></GenericCard>

    ]

    /********************************* Form Visualizza ************************************/

    const formVisualizza = [
      <GenericCard key={'datiResidenzialiIscrizione'} cardHeader="Dati residenziali e contatti" cardBody={
        <div key={'datiResidenzialiIscrizione'} id="datiResidenzialiIscrizione" className="row">
          <div className="row">
            <div className="row">
              <div className={isCurrentMobileDevice() ? null : "col-6"}>
                <GenericInput type={'text'} isDisabled id="provinciaResidenza" name={'provinciaResidenza'} placeholder=' ' defaultValue={(this.state.fields['provinciaResidenza'] && this.state.fields['provinciaResidenza'].label) ? this.state.fields['provinciaResidenza'].label : '-'} label={'Provincia di residenza'} />
              </div>
              <div className={isCurrentMobileDevice() ? null : "col-6"}>
                <GenericInput type={'text'} isDisabled id="comune" name={'comune'} placeholder=' ' defaultValue={(this.state.fields['comune'] && this.state.fields['comune'].label) ? this.state.fields['comune'].label : '-'} label='Città di residenza ' />
              </div>
            </div>
            <div className={isCurrentMobileDevice() ? null : "col-6"}>
              <GenericInput type={'text'} isDisabled id="indirizzo" name={'indirizzo'} placeholder=' ' defaultValue={(this.state.fields['indirizzo']) ? this.state.fields['indirizzo'] : '-'} label='Indirizzo di residenza ' />
            </div>
            <div className={isCurrentMobileDevice() ? null : "col-3"}>
              <GenericInput type={'text'} isDisabled id="numeroCivico" name={'numeroCivico'} defaultValue={(this.state.fields['numeroCivico']) ? this.state.fields['numeroCivico'] : '-'} label='Numero di residenza' placeholder=' ' />
            </div>
            <div className={isCurrentMobileDevice() ? null : "col-3"}>
              <GenericInput type={'text'} isDisabled id="cap" name={'cap'} defaultValue={(this.state.fields['cap']) ? this.state.fields['cap'] : '-'} label='CAP' placeholder=' ' />
            </div>
            <div className={isCurrentMobileDevice() ? null : "col-6"}>
              <GenericInput type={'text'} isDisabled id="emailOrdinaria" name={'emailOrdinaria'} defaultValue={(this.state.fields['emailOrdinaria']) ? this.state.fields['emailOrdinaria'] : '-'} label='Email ordinaria' placeholder=' ' />
            </div>
            <div className={isCurrentMobileDevice() ? null : "col-6"}>
              <GenericInput type={'text'} isDisabled id="pec" name={'pec'} defaultValue={(this.state.fields['pec']) ? this.state.fields['pec'] : '-'} label='PEC' placeholder=' ' />
            </div>
            <div className={isCurrentMobileDevice() ? null : "col-6"}>
              <GenericInput type={'text'} isDisabled id="telefono" name={'telefono'} label='Telefono' defaultValue={this.state.fields['telefono'] ? this.state.fields['telefono'] : '-'} placeholder=' ' />
            </div>
            <div className={isCurrentMobileDevice() ? null : "col-6"}>
              <GenericInput type={'text'} isDisabled id="altroTelefono" name={'altroTelefono'} defaultValue={(this.state.fields['altroTelefono']) ? this.state.fields['altroTelefono'] : '-'} label='Telefono 2' placeholder=' ' />
            </div>
          </div>

        </div>
      }></GenericCard>

    ]


    return (
      <> {(this.state.isLoaded) ? (this.props.visualizzaFlag) ? formVisualizza : formIscrizione : (<><GenericSpinner></GenericSpinner></>)}
      </>
    )

  }
}

export declare interface DatiResidenzialiProps {
  activeTab?: any;
  tabClick?: any;
  data?: any;
  prevData?: any;
  prevClicked?: any;
  processoIscrizione?: any;
  modificaDatiFlag?: any;
  reiscrizioneFlag?: any;
  visualizzaFlag?: any;
  key?: any;
  message?: any;
  getSelectedValue?: any;
  tabNumber?: number;
  getFlagSvuotaOrdine?: any;
}
