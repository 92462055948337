import {DropdownMenu, DropdownToggle, Icon, LinkList, LinkListItem, Table, UncontrolledDropdown} from 'design-react-kit'
import React from 'react'
import GenericButton from './GenericButton'

class GenericList extends React.Component<GeneralTableProps, any> {
  constructor(props) {
    super(props);
    this.state = {
      columns: props.columns,
      rows: props.rows,
      isBorderless: props.isBorderless,
      isButtonHidden: props.isButtonHidden,
      isHidden: props.isHidden,
      onSelect: props.onSelect,
      labelButton: props.labelButton,
      colorButton: props.colorButton,
      className: props.className,
      src: props.src,
      id: props.id,
      editable: (props.editable) ? props.editable : false,
      hideActionColumn: props.hideActionColumn,
    };
  }

  toggle() {
    this.setState({ openAction: !this.state.openAction });
  }
  render() {
    const { rows, className, onSelect, src } = this.state;
    return (
      <Table size='sm' key={this.props.id} aria-label="Sort table" className={className}>
        <thead hidden={!this.props.columns}>
          <>
            <tr>
              {this.props.columns?.map((column: any) => {
                return (
                  <th>{column}</th>
                )
              })}
            </tr>
          </>
        </thead>
        <tbody style={{ lineHeight: 'normal' }}>
          {rows?.map((row: any, id) => (
            <tr>
              {row.data?.map((rowData: any) => {
                return (
                  <>
                    <td><span>{rowData.label}</span></td>
                    <td width={30}>
                      <GenericButton label={this.props.labelButton.label} color='primary' isDisabled={rowData.isDisabled} src={src} onClickEvent={
                        () => { onSelect(row) }} /></td>
                    <td width={30} hidden={this.props.hideActionColumn}>
                      <UncontrolledDropdown nav tag="div">
                        <DropdownToggle caret nav>
                          <GenericButton id={"selectedRow" + id} size={'xs'}><Icon size="sm" color="primary" type="button" icon={'it-more-items'} onClick={e => { this.toggle(); this.props.onSelectActions(rowData) }} />
                          </GenericButton>
                        </DropdownToggle>
                        <DropdownMenu>
                          <div className="row">
                            <div className="col-12">
                              <LinkList>
                                {this.props.dropdownList.map(actionItem => {
                                  return (
                                    <LinkListItem disabled={!rowData.cancellato ? !rowData.isDisabled || actionItem.isDisabled : actionItem.isDisabled}
                                      onClick={actionItem.onClick}
                                    >
                                      <span>{actionItem.title}</span>
                                    </LinkListItem>
                                  )
                                })
                                }
                              </LinkList>
                            </div>
                          </div>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </td>

                  </>

                )
              })}

            </tr>
          ))}
        </tbody>
      </Table>
    );
  }
}

export declare interface GeneralTableProps {
  columns?: any;
  hideActionColumn?: any;
  onSelectActions?: any;
  dropdownList?: any;
  rows?: any;
  isBorderless?: boolean;
  isButtonHidden?: boolean;
  isHidden?: boolean;
  onSelect?: any;
  labelButton?: any;
  colorButton?: any;
  iconButton?: any;
  className?: any;
  src?: any;
  editable?: any;
  id?: any;
}

export default GenericList;
