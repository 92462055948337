import {Chip} from 'design-react-kit'
import React from 'react'

class GenericChips extends React.Component<GenericChipsProps, any>{
    constructor(props) {
        super(props);
        this.state = {
            tag: props.tag,
            simple: props.simple,
            large: props.large,
            disabled: props.disabled,
            color: props.color,
            label: props.label,
            hidden: props.hidden
        };
    }

    render() {
        const { color } = this.state;
        return (
          <Chip hidden={this.props.hidden} color={color}>{this.props.label}</Chip>
        );
      }
    }

    export declare interface GenericChipsProps {
        tag?: any;
        simple?: boolean;
        large?: boolean;
        disabled?: boolean;
        color?: any;
        label?: any;
        hidden?: boolean;
    }

export default GenericChips;
