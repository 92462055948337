import React, {useCallback, useRef, useState} from 'react'
import {Form} from 'design-react-kit'
import FormSubmit, {STATUS} from '../../../../../frontend/app/components/Form/GenericSubmit'
import {getDefaultAxiosConfig, getFormazioneResourceApi} from 'src/app/utils/ManagerRestGateway'
import {STATI_EVENTO_CATALOGO_VALUES} from 'src/formazione'
import TextArea from 'src/frontend/app/components/Form/TextArea'
import {createFormData, handleChangeFormData, validateFormData} from 'src/app/utils/form-utils'
import {useHistory} from 'react-router-dom'

const FormRigetto: React.FunctionComponent<FormRigettoProps> = ({ id }) => {
  const [status, setStatus] = useState<any>()
  const [alertMessage, setAlertMessage] = useState<any>()

  // Form State
  const refForm = useRef<any>([])
  const [data, setData] = useState<any>({})
  const [errors, setErrors] = useState<any>({})
  const history = useHistory()
  const formData = createFormData(data, setData, errors, setErrors)
  const rules = {
    motivi_non_autor: {
      isRequired: true,
    },
  }

  const onInitInput = (cmp) => {
    refForm.current.push({
      cmp: cmp,
    })
  }

  const handleInput = (code, value) => {
    handleChangeFormData(formData, code, value, rules)
  }

  const submit = useCallback(
    async (e) => {
      try {
        // TODO EVENTO CATALOGO API check
        const inputs = ['motivi_non_autor']
        const valid = validateFormData(formData, rules, inputs)
        const data = formData?.data
        if (!valid) {
          window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
          return
        }

        setStatus(STATUS.LOADING)
        await getFormazioneResourceApi().msfoFormazioneAggiornaEventoACatalogoPatch(
          {
            idcorso: id,
            motivi_non_autor: data?.motivi_non_autor,
            accreditato: STATI_EVENTO_CATALOGO_VALUES.RIFIUTATO,
          } as any,
          getDefaultAxiosConfig()
        )
        setStatus(STATUS.SUCCESS)
        setAlertMessage('Dati salvati con successo')
        setTimeout(() => {
          history.goBack()
        }, 500)
      } catch (e) {
        setStatus(STATUS.FAILURE)
        setAlertMessage('Impossibile salvare i dati')
      }
    },
    [id, data]
  )
  return (
    <FormSubmit title="Rigetto evento" buttonLabel="CONFERMA" alertMessage={alertMessage} status={status} onSubmit={submit}>
      <Form>
        <TextArea
          labelBottom
          id="motivi_non_autor"
          placeholder={'Motivo di rigetto'}
          label={'Motivo di rigetto*'}
          value={data?.motivi_non_autor}
          errorMessage={errors?.motivi_non_autor}
          onInit={onInitInput(0)}
          onChange={handleInput}
        />
      </Form>
    </FormSubmit>
  )
}

interface FormRigettoProps {
  id: any
}

export default FormRigetto
