import {conversioneIntToSiNo, conversioneSiNoToInt} from 'src/utilities/utility'


export namespace AppUtils{

    //formatter per currency
    const currencyFormatter = new Intl.NumberFormat('it-IT', {
        style: 'currency',
        currency: 'EUR',
        // These options are needed to round to whole numbers if that's what you want.
        //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
        //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
    });

    const numberFormatter = new Intl.NumberFormat('it-IT', {
        //style: 'currency',
        //currency: 'EUR',
        // These options are needed to round to whole numbers if that's what you want.
        //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
        //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
    });



    /*
    * normalizzazione dropdown (Si|1|No|0) => (1|0)
    */
    export function nd(value){

        if(value?.value){
            return conversioneSiNoToInt(value.value);
        }
        if(value===1)
            return 1;

        if(value===0)
            return 0;

        if(value===true)
            return 1;
        if(value===false||value===undefined)
            return 0;

        if(value ===undefined || value === null){
            ;
            return 0;
        }


        return undefined;
    }

    /*
    * normalizzazione booleani a dropdown (1|0) => (Si|No)
    */
    export function dn(value){

        //verifico se non è già dropdown
        if(value?.label && value?.value){
            return value;
        }


        if(value===1){
            let cast = conversioneIntToSiNo(value);
            return {"label": cast, "value": cast};
        }


        if(value===0){
            let cast = conversioneIntToSiNo(value);
            return {"label": cast, "value": cast};
        }


        if(value===true){
            let cast = conversioneIntToSiNo(1);
            return {"label": cast, "value": cast};
        }

        if(value===false || value===undefined || value===null){
            let cast = conversioneIntToSiNo(0);
            return {"label": cast, "value": cast};
        }

        return undefined;
    }



    /*
    * testa se booleano bloccante tbb
    */
    export function tbb(value){


        if(value===1 || value===0){
            return 1;
        }

        else {
            let varName = Object.keys({value})[0]
            alert("ERRORE SU UN CAMPO BOOLEANO: "+ varName);
            return 0;
        }
    }

    /*
    * testa se numerico bloccante tbb
    */
    export function tnb(value){
        if(isNaN(value)){
            let varName = Object.keys({value})[0]
            alert("ERRORE SU UN CAMPO NUMERICO: "+ varName);
            return 0;
        }else{
            return 1;
        }

    }


    export function sd(value){
        return conversioneIntToSiNo(value)
    }

    export function data_normalize(value){
        if(value === undefined || value === null){
            return undefined;
        }

        if(value.includes("T")){
            value = value.split("T")[0]
            return value;
        }

        return undefined;
    }

    export function currencyToFloat(num) {
        let dotPos = num.indexOf('.');
        let commaPos = num.indexOf(',');

        if (dotPos < 0)
            dotPos = 0;

        if (commaPos < 0)
            commaPos = 0;

        let sep;

        if ((dotPos > commaPos) && dotPos)
            sep = dotPos;
        else {
            if ((commaPos > dotPos) && commaPos)
                sep = commaPos;
            else
                sep = false;
        }

        if (sep === false)
            return parseFloat(num.replace(/[^\d]/g, ""));

        return parseFloat(
            num.substr(0, sep).replace(/[^\d]/g, "") + '.' +
            num.substr(sep+1, num.length).replace(/[^0-9]/, "")
        );

    }

    //formatta un valore numerico stringa in formato currency
    export function currency(value){
        return currencyFormatter.format(value);
    }

    //formatta un valore numerico in formato numerico italano
    export function itNumber(value){
        return numberFormatter.format(value);
    }

    //formatta un valore stringa timestamp in formato data + ora italiano
    export function itDate(value){

        let date = new Date(value).toLocaleDateString('it-IT',{
		    year: "numeric",
		    month: "2-digit",
		    day: "2-digit",
        });

        return date;
    }

    //formatta un valore stringa timestamp in formato data + ora italiano
    export function itTimestamps(value){

        let date = new Date(value).toLocaleDateString('it-IT',{
		    year: "numeric",
		    month: "2-digit",
		    day: "2-digit",
        });

        let time = new Date(value).toLocaleTimeString('it-IT',{
		    hour: "2-digit",
		    minute: "2-digit",
		    second: "2-digit",
        })


        let timestamp = date + " " + time;
        return timestamp;
    }


   /**
    * @description Controlla se il valore inserito contiene due decimali dopo la virgola
    * @param {*} obj
    * @returns boolean
    */
   export function isDecimalWithComma(obj) {
       let value = obj.toString();
       if (typeof value !== "undefined") {
           if (value.match(/^[0-9]+((?!\2)[,]\d{1,2})?$/)) {
               return true;
           }
           else return false
       }
       else return false
   }

}
