import React, {useCallback, useRef, useState} from 'react'
import {Form} from 'design-react-kit'
import FormSubmit, {STATUS} from '../../../../../frontend/app/components/Form/GenericSubmit'
import {getDefaultAxiosConfig, getFormazioneResourceApi} from 'src/app/utils/ManagerRestGateway'
import TextArea from 'src/frontend/app/components/Form/TextArea'
import {createFormData, handleChangeFormData, validateFormData} from 'src/app/utils/form-utils'
import {STATI_ESONERO} from 'src/model/Esoneri'
import GenericInput from 'src/frontend/app/components/Form/Input'
import {useHistory} from 'react-router-dom'

const FormRigetto: React.FunctionComponent<FormRigettoProps> = ({ id, stato }) => {
  const [status, setStatus] = useState<any>()
  const [alertMessage, setAlertMessage] = useState<any>()

  // Form State
  const refForm = useRef<any>([])
  const [data, setData] = useState<any>({})
  const [errors, setErrors] = useState<any>({})
  const history = useHistory()
  const formData = createFormData(data, setData, errors, setErrors)
  const rules = {
    motivo: {
      isRequired: true,
    },
    protocollo: {
      isRequired: true,
    },
  }

  const onInitInput = (cmp) => {
    refForm.current.push({
      cmp: cmp,
    })
  }

  const handleInput = (code, value) => {
    handleChangeFormData(formData, code, value, rules)
  }

  const submit = useCallback(
    async (e) => {
      try {
        // TODO AGENZIA API check
        const inputs = ['motivo', 'protocollo']
        const valid = validateFormData(formData, rules, inputs)
        const data = formData?.data

        let _stato = STATI_ESONERO.RIFIUTATA

        if (!valid) {
          window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
          return
        }

        setStatus(STATUS.LOADING)
        await getFormazioneResourceApi().msfoFormazioneFormatorePatch(
          {
            id,
            stato: _stato,
            prot_approvazione_rifiuto: data.motivo,
            protocollo_delibera: data.protocollo,
          } as any,
          getDefaultAxiosConfig()
        )
        setStatus(STATUS.SUCCESS)
        setAlertMessage('Dati salvati con successo')
        setTimeout(() => {
          history.goBack()
        }, 500)
      } catch (e) {
        setStatus(STATUS.FAILURE)
        setAlertMessage('Impossibile salvare i dati')
      }
    },
    [id, stato, data]
  )
  return (
    <FormSubmit title="Rigetto richiesta" buttonLabel="CONFERMA" alertMessage={alertMessage} status={status} onSubmit={submit}>
      <Form>
        <TextArea
          labelBottom
          id="motivo"
          placeholder={'Motivo di rigetto'}
          label={'Motivo di rigetto*'}
          value={data?.motivo}
          errorMessage={errors?.motivo}
          onInit={onInitInput(0)}
          onChange={handleInput}
        />
        <GenericInput
          id="protocollo"
          type="text"
          label="Protocollo delibera"
          suffix="**"
          value={data.protocollo}
          onChange={handleInput}
          onInit={onInitInput(0)}
          errorMessage={errors?.protocollo}
        />
      </Form>
    </FormSubmit>
  )
}

interface FormRigettoProps {
  id: any
  stato: any
}

export default FormRigetto
