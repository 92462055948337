import React, {useCallback, useRef, useState} from 'react'
import {createFormData, getTabActiveFormId, handleChangeFormData, validateFormData} from 'src/app/utils/form-utils'
import GenericTab from 'src/frontend/app/components/GenericTab'
import FormDatiPianoFormativo from './FormDatiPianoFormativo'
import FormCFP from './FormDatiCFP'
import FormDichiarazioni from './FormDatiDichiarazioni'
import FormComposizioneCFP from './FormDatiComposizioneCFP'
import formValidationRules from './FormRules'
import FormConferma from './FormDatiConferma'

const FormPOF: React.FunctionComponent<FormPOFProps> = (props) => {
  const [haveChange, setHaveChange] = useState<boolean>(false)
  // Tab component state
  const [activeTab, setActiveTab] = useState(1)
  const [message, setMessage] = useState('')

  // Form State
  const refForm = useRef<any>([])
  const [errors, setErrors] = useState<any>({})
  const formData = createFormData(props.data, props.setData, errors, setErrors)

  const onInitInput = (cmp, tab) => {
    refForm.current.push({
      tab: tab,
      cmp: cmp,
    })
  }
  const handleInput = (code, value, isUpdate = true) => {
    handleChangeFormData(formData, code, value, formValidationRules)
    if (isUpdate) {
      setHaveChange(true)
    }
  }

  const onNextStep = useCallback(
    (e) => {
      e.preventDefault()
      if (!props.isDisabled) {
        const inputs = getTabActiveFormId(refForm, activeTab - 1)
        let isValid = validateFormData(formData, formValidationRules, inputs)

        if (activeTab === 4) {
          const data = formData.data
          let cfpPreviCosti = 0
          for (const previcosti of data.previCostiAttFormativaList) {
            cfpPreviCosti += parseFloat(previcosti.tot_cfp ? previcosti.tot_cfp : 0)
          }

          let compoCFP = 0
          for (const row of data.pFormativo2016List) {
            compoCFP += isNaN(row.tot_cfp) || row.tot_cfp === '' ? 0 : parseFloat(row.tot_cfp)
          }
          if(cfpPreviCosti.toFixed(3) !== compoCFP.toFixed(3)) {
            isValid = false
            setMessage('I CFP nella composizione sono diversi da quelli specificati in precedenza')
            setErrors('I CFP nella composizione sono diversi da quelli specificati in precedenza')
          }
        }

        if (!isValid) {
          window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
          return
        }
        setMessage('')
      }
      if (props.onNextStep) {
        props.onNextStep(activeTab + 1, haveChange)
      }
      setHaveChange(false)
      setActiveTab((tab) => tab + 1)
    },
    [activeTab, formData, props]
  )

  const previousStep = () => {
    if (props.saving) {
      return
    }
    setActiveTab((tab) => tab - 1)
  }

  const onClickTab = (tab) => {
    if (props.isDisabled || tab < activeTab) {
      setActiveTab(tab)
    }
  }

  const listNavLink = [
    { id: 1, label: 'Dati Piano Formativo' },
    { id: 2, label: 'CFP' },
    { id: 3, label: 'Dichiarazioni' },
    { id: 4, label: 'Composizione CFP' },
  ]

  const tabs = [
    {
      id: 1,
      body: (
        <FormDatiPianoFormativo
          title={'Dati Piano Formativo'}
          data={props.data}
          errors={errors}
          isDisabled={props.isDisabled}
          onInitInput={(id) => onInitInput(id, 0)}
          handleInput={handleInput}
        />
      ),
    },
    {
      id: 2,
      body: (
        <FormCFP
          title={'CFP previsti per attività formativa'}
          data={props.data}
          errors={errors}
          isDisabled={props.isDisabled}
          onInitInput={(id) => onInitInput(id, 1)}
          handleInput={handleInput}
          showErrorMessage={props.setErrorMessage}
        />
      ),
    },
    {
      id: 3,
      body: (
        <FormDichiarazioni
          title={'Dichiarazioni'}
          data={props.data}
          errors={errors}
          isDisabled={props.isDisabled}
          onInitInput={(id) => onInitInput(id, 2)}
          handleInput={handleInput}
        />
      ),
    },
    {
      id: 4,
      body: (
        <FormComposizioneCFP
          title={'Composizione specifica del piano formativo'}
          data={props.data}
          errors={errors}
          isDisabled={props.isDisabled}
          onInitInput={(id) => onInitInput(id, 3)}
          handleInput={handleInput}
          showErrorMessage={props.setErrorMessage}
        />
      ),
    },
  ]

  if (!props.isDisabled) {
    tabs.push({
      id: 5,
      body: <FormConferma title={'Invio Richiesta'} loading={props.saving} onConfirm={props.onSubmit} />,
    })
    listNavLink.push({ id: 5, label: 'Conferma' })
  }
  return (
    <div className={`vertical-tab ${props.isDisabled ? 'disabled-form' : 'register-container'}`}>
      <GenericTab
        hiddenAlert={message === ''}
        alertTypeMessage={'danger'}
        labelAlert={message}
        isSubmitted={false}
        onSubmit={onNextStep}
        previousStep={previousStep}
        activeTab={activeTab}
        listNavLink={listNavLink}
        listBodyTab={tabs}
        isClickable={true}
        tabClick={onClickTab}
        hideHome={true}
      />
    </div>
  )
}

export declare interface FormPOFProps {
  saving?: boolean

  setErrorMessage?: (string) => void

  data: any

  setData: any

  onSubmit?: (e, data) => void

  isDisabled: boolean

  onNextStep?: (step: number, haveChange: boolean) => void
}

export default FormPOF
