import React from 'react'
import moment from 'moment/moment'
import GenericInput from 'src/frontend/app/components/Form/Input'
import TextArea from 'src/frontend/app/components/Form/TextArea'
import {ProtocolloInfo, TextStatus} from './styles'


const PofStatusPianoVerificato: React.FunctionComponent<any> = ({ pof, isApprovato }) => {
  return (
      <div className={'container pof-status'}>
        <div className={'row'}>
          <div className={'col-4'}>
            {isApprovato && (
              <TextStatus className={'text-success'}>
                Dichiarato conforme dal Consiglio nazionale il {moment(pof.data_prot, 'YYYY-MM-DD').format('DD/MM/YYYY')}
              </TextStatus>
            )}
            {!isApprovato && (
              <span className={'text-danger'}>
                Dichiarato non conforme dal Consiglio nazionale il {moment(pof.data_autorizzazione, 'YYYY-MM-DD').format('DD/MM/YYYY')}
              </span>
            )}
          </div>
          <ProtocolloInfo className={'col-8'}>
            <GenericInput
              type={'text'}
              isDisabled={true}
              label={'Nr. Protocollo'}
              value={pof.nr_prot}
            />
            <GenericInput
              className={'data'}
              type={'text'}
              isDisabled={true}
              label={'Data'}
              value={moment(pof.data_prot).format('DD/MM/YYYY')}
            />
            <TextArea
              className={'fill'}
              isDisabled={true}
              label={'delibera approvazione/seduta di consiglio'}
              value={pof.delibera_prot}
              rows={3}
            />
          </ProtocolloInfo>
        </div>
        {pof.suggerimenti && (
          <div className={"row"}>
            <div className={"col-12"}>
              <TextArea
                className={'fill'}
                isDisabled={true}
                label={'Suggerimenti'}
                value={pof.suggerimenti}
                rows={8}
              />
            </div>
          </div>
        )}
      </div>
  )
}

export default PofStatusPianoVerificato
