import {FormGroup} from 'design-react-kit'
import React from 'react'
import GenericSpinner from 'src/backoffice/app/components/GenericSpinner'
import GenericCard from '../../../components/GenericCard'
import GenericDataPicker from '../../../components/GenericDatePicker'
import GenericInput from '../../../components/GenericInput'
import GenericSelect from '../../../components/GenericSelect'
import {isCurrentMobileDevice} from 'src/utilities/deviceUtility'

const yes_no_options = [
  { label: "Si", value: "Si" },
  { label: "No", value: "No" }
]

export default class IscrizioneEPAP extends React.Component<IscrizioneEPAPProps, any>{
  constructor(props) {
    super(props);
    this.state = {
      prevData: props.prevData,
      prevClicked: props.prevClicked,
      fields: (props.prevData && props.prevClicked) ? props.prevData[0] : {},
      isLoaded: false,
      message: ''
    };
  }
  async componentDidMount(): Promise<void> {

    await window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });

    if (!this.props.modificaDatiFlag && !this.props.visualizzaFlag && !this.props.reiscrizioneFlag) {
      if (this.props.data) {
        let data = this.props.data;
        this.setState({ fields: data })
      }
    }

    else if ((this.props.visualizzaFlag || this.props.modificaDatiFlag) && this.props.data) this.setState({ fields: this.props.data })
    else this.setState({ fields: {} })
    await this.setState({ isLoaded: true })

  }
  render() {

    /********************************* Salvataggio dati del Form *********************************************/
    const setDataItem = (e: any, type: any, field: any) => {
      let fields = this.state.fields;
      if (type === 'select') {
        fields[field] = { label: e.label, value: e.label };
      }
      else {
        fields[field] = e.target.value;
      }
      this.setState({ fields });
      this.props.getSelectedValue(fields)

    }

    /********************************* Form Iscrizione ********************************************/
    const formIscrizione = [
      <GenericCard key={'iscrizioneEpap'} cardHeader="Iscrizione EPAP" cardBody={
        <div id="iscrizioneEpap" className="row">
          <div>
            <div className="row">
              <div className={isCurrentMobileDevice() ? null : "col-6"} >
                <FormGroup label={"iscrittoEpap"} >
                  <GenericSelect elementId="iscrittoEpap" name={'iscrittoEpap'} onChange={(e) => setDataItem(e, 'select', 'iscrittoEpap')} label='Iscritto EPAP' placeholder='Si / No'
                    defaultOptions={yes_no_options}
                    defaultValue={(this.state.fields['iscrittoEpap']) ? this.state.fields['iscrittoEpap'] : null}/>
                </FormGroup>
              </div>
              <div className={isCurrentMobileDevice() ? null : "col-6"}>
                <FormGroup label={"dataIscrizioneEpap"}>
                  <GenericDataPicker id={"idDataIscrizioneEpap"} onChange={(e) => setDataItem(e, 'text', 'dataIscrizioneEpap')}
                    defaultValue={(this.state.fields['dataIscrizioneEpap']) ? this.state.fields['dataIscrizioneEpap'] : null}
                    label='Data iscrizione' placeholder='Selezionare la data iscrizione' />
                </FormGroup>
              </div>
              <div className={isCurrentMobileDevice() ? null : "col-6"}>
                <FormGroup label={"matricolaEpap"}>
                  <GenericInput id="matricolaEpap" name={'matricolaEpap'} defaultValue={(this.state.fields['matricolaEpap']) ? this.state.fields['matricolaEpap'] : null}
                    onChange={(e) => setDataItem(e, 'text', 'matricolaEpap')} label='Matricola EPAP'
                    placeholder='Inserire il numero di matricola' type='text' />
                </FormGroup>
              </div>
            </div>
          </div>
        </div>
      }></GenericCard>
    ]

    /********************************* Form Visualizza *******************************************************/
    const formVisualizza = [
      <GenericCard key={'iscrizioneEpap'} cardHeader="Iscrizione EPAP" cardBody={
        <>
          <div id="formIscrizione" className="row">
            <div>
              <div className="row">
                <div className={isCurrentMobileDevice() ? null : "col-6"}>
                  <GenericInput type={'text'} isDisabled id="iscrittoEpap" name={'iscrittoEpap'} defaultValue={(this.state.fields['iscrittoEpap']) ? this.state.fields['iscrittoEpap'].label : '-'}
                    label="Iscritto EPAP" placeholder='Si / No' />
                </div>
                <div className={isCurrentMobileDevice() ? null : "col-6"}>
                  <GenericInput type={'date'} isDisabled id="dataIscrizioneEpap" name="dataIscrizioneEpap" defaultValue={(this.state.fields['dataIscrizioneEpap']) ? this.state.fields['dataIscrizioneEpap'] : '-'}
                    label='Data iscrizione' placeholder='Selezionare la data iscrizione' />
                </div>
                <div className={isCurrentMobileDevice() ? null : "col-6"}>
                  <GenericInput type={'text'} isDisabled id="matricolaEpap" name={'matricolaEpap'} defaultValue={(this.state.fields['matricolaEpap']) ? this.state.fields['matricolaEpap'] : '-'}
                    label='Matricola EPAP' placeholder='Inserire il numero di matricola' />
                </div>
              </div>
            </div>
          </div>
        </>
      }></GenericCard>
    ]

    if (this.state.isLoaded)
      return (
        <>
          {(this.props.visualizzaFlag) ? formVisualizza : formIscrizione}
        </>
      )
    else return (<><GenericSpinner /></>)
  }
}

export declare interface IscrizioneEPAPProps {
  activeTab?: any;
  tabClick?: any;
  data?: any;
  prevData?: any;
  prevClicked?: any
  processoIscrizione?: any;
  modificaDatiFlag?: any;
  reiscrizioneFlag?: any;
  visualizzaFlag?: any;
  message?: any;
  getSelectedValue?: any;
  tabNumber?: number;
}
