import * as React from 'react'
import {Form, FormGroup, ModalVariant, PageSection, Title} from '@patternfly/react-core'
import GenericCard from '../components/GenericCard'
import GenericDualList from '../components/GenericDualList'
import GenericTextInput from '../components/GenericTextInput'
import GenericBreadCrumb from 'src/frontend/app/components/GenericBreadCrumb'
import GenericButton from '../components/GenericButton'
import {TokenStore} from 'src/keycloak/jwt/TokenStore'
import {
    bindUserGroupRoles,
    bindUserGroupRolesDelibera,
    getKcRolesAssignedBySoggettoAndSpecificGroup,
    getKcRolesNotAssignedBySoggettoAndSpecificGroup
} from 'src/processes/GestioneUtenze'
import GenericUploadFront from 'src/frontend/app/components/GenericUpload'
import {getSoggettoByCfAndTipoAnagrafeBO} from 'src/processes/Soggetto'
import {inputRequiredValidation, tipoAnagrafeEsteso} from 'src/utilities/utility'
import {
    PATH_TO_BO_ANAG_ELENCO_OPERATORI,
    PATH_TO_BO_ANAG_ELENCO_OPERATORI_GESTIONE_UTENTI
} from 'src/app/utils/RoutesConstants'
import {getAnagrafeResourcesApi, getDefaultAxiosConfig} from 'src/app/utils/ManagerRestGateway'
import GenericChips from 'src/frontend/app/components/GenericChips'
import GenericModal from '../components/GenericModal'
import warningIcon from '../../images/warning-icon.png'
import {Icon} from 'design-react-kit'
import GenericAlert from 'src/frontend/app/components/GenericAlert'
import {GenericSpinner} from '../components'
import {getDenominazioneByCodiceGruppo} from 'src/processes/SelezioneProfilo'
import {logout} from 'src/frontend/app/components/GenericHeaderSlim'
import {isCurrentMobileDevice} from 'src/utilities/deviceUtility'

const isMobileDivice = isCurrentMobileDevice();


class GestioneUtenti extends React.Component<any, any> {
  constructor(props) {
    super(props)
    this.state = {
      specificGroupIds: [[{ label: 'Seleziona un ordine', value: '' }]],
      assignedRoles: [],
      notAssignedRoles: [],
      message: '',
      messageRequired: '',
      isDualListDisabled: false,
      isModalClosed: false,
      isLoaded: false,
      disabledButtons: false,
    }
  }

  nomeCognome = this.props.location.state?.row?.data[1].toUpperCase()
  codiceFiscale = this.props.location.state?.row?.data[0]  //codice fiscale del soggetto selezionato dalla lista degli operatori
  tipoAnagrafe = tipoAnagrafeEsteso('PF')
  specificGroup = Object.keys(TokenStore?.getInstance()?.getCurrentAccount()?.accounts)[0]
  specificGroupId = TokenStore.getInstance().getCurrentAccount().accounts[this.specificGroup]?.specificGroupId
  genericGroup = TokenStore.getInstance().getCurrentAccount().accounts[this.specificGroup]?.genericGroup
  codiceFiscaleOperatore = TokenStore.getInstance().getDecodedTokenJWT().fiscalNumber //codice fiscale dell'operatore loggato (ricavato dal token)

  goToElencoOperatori = (redirectLink: any) => {
    this.props.history.push({
      pathname: redirectLink,
    })
  }

  async componentDidMount(): Promise<void> {

    await this.setState({ genericGroup: this.genericGroup })
    if (this.props.history.location.state === undefined) this.goToElencoOperatori(PATH_TO_BO_ANAG_ELENCO_OPERATORI)
    if (this.specificGroup.startsWith('ODAF') || this.specificGroup.startsWith('FODAF')) {

        getDenominazioneByCodiceGruppo(this.specificGroup).then(response => {
            if (response && response.data && response.data.httpStatus !== 200) {
                throw new Error(response.data.error);
            }
            var denominazione = response?.data?.returnedObject;
            this.setState({ denominazione: denominazione.split(" ")[denominazione.split(" ").length - 1] })
        })

      var ordineCompetenteOperatorePOST: any = {
        specificGroup: this.specificGroup,
      }

      await getAnagrafeResourcesApi()
        .msgaAnagrafeBoGetOrdineCompetenteBySpecificGroupPost(ordineCompetenteOperatorePOST, getDefaultAxiosConfig())
        .then(async (response) => {
          var groups = response.data
            .map((specificGroupId) => ({
              label: specificGroupId,
              value: specificGroupId,
            }))
            .filter((e) => e.label !== '')

            this.setRoles()

          await this.setState({ groups: groups })
        })
    } else if (this.genericGroup === 'STP') {
      var partitaIva = this.specificGroup.split('_')[1]
      var selectContent = { label: partitaIva, value: partitaIva }
      this.setState({ groups: [selectContent] })
      this.setState({ denominazione: selectContent?.label })
    }


    if (this.specificGroup === 'CONAF') {
      this.setRoles()
    }

    if (this.specificGroup.startsWith('STP')) {
      this.setRoles()
    }

    //setto nello stato il genericGroup
    await this.setState({ genericGroup: this.genericGroup })
  }

  /**
   * Setta i ruoli all'interno del componente dualList
   */
  setRoles = async () => {
    var genericRequestDTO

    var response = await getSoggettoByCfAndTipoAnagrafeBO('' + this.codiceFiscale, '' + this.tipoAnagrafe)

    if (response.status === 200) {
      genericRequestDTO = {
        tipo: this.gestioneTipo(),
        idSoggetto: '' + response.data.idSoggetto,
      }
    } else {
      genericRequestDTO = {
        tipo: this.gestioneTipo(),
        idSoggetto: '',
      }
    }
    var assignedRoleResponse = await getKcRolesAssignedBySoggettoAndSpecificGroup(genericRequestDTO)
    if (assignedRoleResponse.status === 200 && assignedRoleResponse.data.length > 0) {
      await this.setState({ assignedRoles: assignedRoleResponse.data, isLoaded: true })
    }
    var notAssignedRoleResponse = await getKcRolesNotAssignedBySoggettoAndSpecificGroup(genericRequestDTO)
    if (notAssignedRoleResponse.status === 200 && notAssignedRoleResponse.data.length > 0)
      await this.setState({ notAssignedRoles: notAssignedRoleResponse?.data, isLoaded: true })

  }

  /**
   * Al click del tasto singolo che aggiunge a destra, setta i ruoli assegnati
   * @param newAvailableOptions
   * @param newChosenOptions
   */
  ruoliDaAssegnare = (newAvailableOptions: React.ReactNode[], newChosenOptions: React.ReactNode[]) => {
    this.setState({ ruoliAssegnati: newChosenOptions.sort(), deleted: false })
    this.setState({ ruoliEliminati: newAvailableOptions.sort(), deleted: false })
  }

  /**
   * Al click del tasto multiplo che aggiunge a destra, setta i ruoli assegnati
   * @param newAvailableOptions
   * @param newChosenOptions
   */
  aggiungiTuttiIRuoli = (newAvailableOptions: React.ReactNode[], newChosenOptions: React.ReactNode[]) => {
    this.setState({ ruoliAssegnati: newChosenOptions.sort(), deleted: false })
    this.setState({ ruoliEliminati: newAvailableOptions.sort(), deleted: false })
  }

  /**
   * Al click del tasto singolo che aggiunge a sinstra, rimuove i ruoli assegnati
   * @param newAvailableOptions
   */
  ruoliDaEliminare = (newAvailableOptions: React.ReactNode[], newChosenOptions: React.ReactNode[]) => {
    this.setState({ ruoliAssegnati: newChosenOptions.sort(), deleted: false })
    this.setState({ ruoliEliminati: newAvailableOptions.sort(), deleted: true })
  }

  /**
   * Al click del tasto multiplo che aggiunge a sinstra, rimuove i ruoli assegnati
   * @param newAvailableOptions
   */
  rimuoviTuttiIRuoli = (newAvailableOptions: React.ReactNode[], newChosenOptions: React.ReactNode[]) => {
    this.setState({ ruoliAssegnati: newChosenOptions.sort(), deleted: false })
    this.setState({ ruoliEliminati: newAvailableOptions.sort(), deleted: true })
  }

  handleChange(event, id) {
    this.setState({ messageRequired: '', typeMessage: 'default' })
    if (id === 'idEstremiAttoNomina') this.setState({ estremiAttoNomina: event })
    else if (id === 'idDataAttoNomina') this.setState({ dataAttoNomina: event })
  }

  /**
   * Gestisce il tipo da passare alla chiamata in base al gruppo generico
   * @returns
   */
  gestioneTipo() {
    if (this.genericGroup === 'CONAF') {
      return 'CONAF'
    } else if (this.state.tipo) {
      return this.state.tipo
    } else if (this.genericGroup === 'STP') {
      return this.specificGroup
    } else if (this.genericGroup === 'ODAF') {
      return this.state.groups[0].label
    } else if (this.genericGroup === 'FODAF') {
      return this.specificGroup
    }
  }

  /**
   * Salva i ruoli sul db
   */
  assegnaRuoli = async () => {
    const element = document.getElementById('idBreadCrumb')
    this.setState({ isLoaded: false, disabledButtons: true })

    const bindUserGroupRolesDTO = {
      cfToAssignee: this.codiceFiscale,
      cfOperatore: this.codiceFiscaleOperatore,
      genericGroupName: this.genericGroup,
      tipo: await this.gestioneTipo(),
      roleNamesDaCancellare: this.state.ruoliEliminati,
      roleNamesDaAssegnare: this.state.ruoliAssegnati,
      deleted: this.state.deleted,
      deleteProfile: false,
    }

    if (this.genericGroup === 'ODAF' || this.genericGroup === 'FODAF' || this.genericGroup === 'CONAF') {
      var requiredFields = [
        { value: this.state.estremiAttoNomina, label: 'Estremi atto di nomina' },
        { value: this.state.dataAttoNomina, label: 'Data atto nomina' },
      ]
      var errorRequired: any = inputRequiredValidation(requiredFields)

      if ((!this.state.dataAttoNomina || !this.state.estremiAttoNomina) && (this.genericGroup === 'ODAF' || this.genericGroup === 'FODAF')) {
        this.setState({ messageRequired: errorRequired.messaggio, typeMessage: errorRequired.type, isLoaded: true, disabledButtons: false })
      } else {
        if (element) element.scrollIntoView({ behavior: 'smooth' })

        await bindUserGroupRolesDelibera(bindUserGroupRolesDTO, this.state.dataAttoNomina, this.state.estremiAttoNomina, this.state.file)
          .then(() => {
            this.setState({ message: 'Assegnazione dei ruoli avvenuta con successo!', typeMessage: 'success', isLoaded: false })
            setTimeout(() => {
                this.goToElencoOperatori(PATH_TO_BO_ANAG_ELENCO_OPERATORI)
            }, 3000)
          })
          .catch((err) => {
            window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
            this.setState({
              message: "C'è stato un problema durante il salvataggio dei dati" + err,
              typeMessage: 'danger',
              isLoaded: false,
              disabledButtons: false,
            })
          })
      }
    } else {
      if (element) element.scrollIntoView({ behavior: 'smooth' })

      await bindUserGroupRoles(bindUserGroupRolesDTO)
        .then(() => {
          this.setState({ message: 'Assegnazione dei ruoli avvenuta con successo!', typeMessage: 'success' })
          setTimeout(() => {
            this.goToElencoOperatori(PATH_TO_BO_ANAG_ELENCO_OPERATORI)
          }, 3000)
        })
        .catch((err) => {
          window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
          this.setState({
            message: "C'è stato un problema durante il salvataggio dei dati: " + err,
            typeMessage: 'danger',
            isLoaded: false,
            disabledButtons: false,
          })
        })
    }
  }

  disabilitaDualList = () => {
    this.setState({ isModalClosed: true })
  }

  cancellaUtente = async () => {
    this.setState({ isModalClosed: true, isDualListDisabled: true })

    const bindUserGroupRolesDTO = {
      cfToAssignee: this.codiceFiscale,
      cfOperatore: this.codiceFiscaleOperatore,
      genericGroupName: this.genericGroup,
      tipo: await this.gestioneTipo(),
      roleNamesDaCancellare: [],
      roleNamesDaAssegnare: [],
      deleted: true,
      deleteProfile: true,
    }

    await bindUserGroupRoles(bindUserGroupRolesDTO)
      .then(() => {
        this.setState({ message: 'Cancellazione del profilo avvenuta con successo!', typeMessage: 'success', isModalClosed: false })
        setTimeout(() => {
           //contollo se mi sto auto-eliminando. Nel caso di auto-eliminzione effettuo il logout.
           if(this.codiceFiscale.length > 0 && this.codiceFiscale === this.codiceFiscaleOperatore)
             logout();
           else
          this.goToElencoOperatori(PATH_TO_BO_ANAG_ELENCO_OPERATORI)
        }, 2000)
      })
      .catch((err) => {
        this.setState({ message: "C'è stato un problema durante la cancellazione del profilo", typeMessage: 'danger', isModalClosed: false })
      })
  }

  render() {
    const {
      groups,
      assignedRoles,
      notAssignedRoles,
      genericGroup,
      message,
      typeMessage,
      isDualListDisabled,
      isModalClosed,
      messageRequired,
      isLoaded,
      disabledButtons,
    } = this.state

    const getDatiFile = (fileData) => {
      this.setState({ file: fileData })
    }

    const anagraficaUtente = (
      <>
        <div>
          <Form>
            <div className="row">
              <div className={!isMobileDivice? 'col-5' : 'col-12' }>
                <FormGroup className="p-2" label={'Cognome e nome'}>
                  {this.nomeCognome}
                </FormGroup>
              </div>
              <div className={!isMobileDivice? 'col-5' : 'col-12' }>
                <FormGroup className="p-2" label={'Codice fiscale'}>
                  {this.codiceFiscale}
                </FormGroup>
              </div>
            </div>
          </Form>
        </div>
      </>
    )

    const formAttoNomina = (
      <>
        <div>
          <Form>
            <div className="row" hidden={genericGroup !== 'CONAF'}>
              <div className="col-8">
                <FormGroup className="col-8 p-2" label={genericGroup === 'CONAF' ? 'Allegato' : 'Atto di Nomina'}>
                  <GenericUploadFront
                    datiAllegato={getDatiFile}
                    tipologiaAllegato={genericGroup === 'CONAF' ? 'allegato_conaf' : 'atto_di_nomina'}
                    acceptedExtensionFile={['pdf']}
                  />
                </FormGroup>
              </div>
            </div>

            <div className="row" hidden={genericGroup !== 'ODAF' && genericGroup !== 'FODAF'}>
              <div className="row">
                <div className="col-8">
                  <FormGroup className="col-8 p-2" label={'Atto di Nomina'}>
                    <GenericUploadFront datiAllegato={getDatiFile} tipologiaAllegato="allegato_iot" id={'attoNomina'} acceptedExtensionFile={['pdf']} />
                  </FormGroup>
                </div>
              </div>

              <div className="row">
              <div className={!isMobileDivice? 'col-6' : 'col-12' }>
                  <GenericTextInput
                    type={'text'}
                    className="w-75"
                    id={'idEstremiAttoNomina'}
                    label={'Estremi atto di nomina'}
                    placeholder={'Inserisci estremi atto'}
                    onChange={(e) => this.handleChange(e, 'idEstremiAttoNomina')}
                    isRequired
                  />
                </div>
                <div className={!isMobileDivice? 'col-6' : 'col-12' }>
                  <GenericTextInput
                    type={'date'}
                    className="w-75"
                    id={'idDataAttoNomina'}
                    label={'Data atto nomina'}
                    placeholder={'Selezionare data'}
                    style={{ paddingRight: '25px' }}
                    onChange={(e) => this.handleChange(e, 'idDataAttoNomina')}
                    isRequired
                  />
                </div>
              </div>
            </div>
          </Form>
        </div>
      </>
    )

    return (
      <PageSection>
        <div className="container register-container">
          <GenericBreadCrumb
            id={'idBreadCrumb'}
            paths={[
              { label: 'Anagrafica', link: '#' },
              { label: 'Elenco operatori', link: PATH_TO_BO_ANAG_ELENCO_OPERATORI },
              { label: 'Gestione Utenti', link: PATH_TO_BO_ANAG_ELENCO_OPERATORI_GESTIONE_UTENTI },
            ]}
          />
          <br></br>
          <Title size="4xl" headingLevel={'h1'}>
            Gestione utenti
          </Title>
          <br></br>

          <div className="col-2" hidden={this.state.genericGroup === 'CONAF'}>
            <GenericChips
              color={'primary'}
              label={
                groups && (
                  <>
                    <label style={{ marginRight: '2px' }} hidden={this.state.genericGroup !== 'ODAF'}>
                      Ordine:
                    </label>
                    <label style={{ marginRight: '2px' }} hidden={this.state.genericGroup !== 'FODAF'}>
                      Federazione:
                    </label>
                    <label style={{ marginRight: '2px'  }} hidden={this.state.genericGroup !== 'STP'}>
                      Stp:
                    </label>
                    { this.state.denominazione}
                  </>
                )
              }
            />
          </div>
          <div>
            <GenericAlert hidden={message === ''} label={message} color={typeMessage}></GenericAlert>
          </div>
          <GenericCard id={'idCard'} isExpandibleCard={true} header={'Anagrafica Utente'} body={anagraficaUtente} />
          <br></br>
          <div>
            <GenericModal
              modalOpen={isModalClosed}
              makeChoice
              choiceAction={() => this.cancellaUtente()}
              closeButton={() => this.setState({ isModalClosed: false })}
              title={
                <Title headingLevel={'h2'}>
                  <Icon icon={warningIcon} /> Sei sicuro di voler eliminare l'account?
                </Title>
              }
              text={this.codiceFiscale.length > 0 && this.codiceFiscale === this.codiceFiscaleOperatore ? "Eliminando l'account verranno rimossi con esso i relativi ruoli. Verrà effettuato il logout per completare l'operazione." : "Eliminando l'account verranno rimossi con esso i relativi ruoli"}
              size={ModalVariant.small}
            />
          </div>

          {isLoaded ? (
            <div className="d-flex justify-content-around">
              <GenericDualList
                isDisabled={isDualListDisabled}
                addAll={this.aggiungiTuttiIRuoli}
                removeAll={this.rimuoviTuttiIRuoli}
                addSelected={this.ruoliDaAssegnare}
                removeSelected={this.ruoliDaEliminare}
                labelSX={'Ruoli disponibili'}
                labelDX={'Ruoli assegnati all’utente'}
                options={notAssignedRoles}
                chosenOptions={assignedRoles}
              />
            </div>
          ) : (
            <GenericSpinner />
          )}
          <br></br>
          <GenericAlert hidden={messageRequired === ''} label={messageRequired} color={typeMessage}></GenericAlert>
          <GenericCard
            isExpandibleCard={true}
            isExpanded={true}
            header={'Compila i seguenti campi'}
            body={formAttoNomina}
            isHidden={genericGroup !== 'CONAF' && genericGroup !== 'ODAF' && genericGroup !== 'FODAF'}
          />
          <br></br>
          <div className="row">
          <div className={!isMobileDivice? 'col-1' : 'col-5' }>
              <GenericButton label={'ANNULLA'} color={'secondary'} onClick={() => this.goToElencoOperatori(PATH_TO_BO_ANAG_ELENCO_OPERATORI)} />
            </div>
            <div className={!isMobileDivice? 'col-1 mb-3' : 'col-5  mb-3' }>
              <GenericButton label={'CONFERMA'} color={'primary'} isDisabled={disabledButtons} onClick={() => this.assegnaRuoli()} />
            </div>
            <div className={!isMobileDivice? 'col-1' : 'col-4' } hidden={genericGroup === 'STP'}>
              <GenericButton label={'CANCELLA UTENTE'} color={'danger'} isDisabled={disabledButtons} onClick={() => this.disabilitaDualList()} />
            </div>
          </div>
        </div>
      </PageSection>
    )
  }
}

export default GestioneUtenti
