import {getAssicurazioneResourceApi, getDefaultAxiosConfig} from 'src/app/utils/ManagerRestGateway'
import {ErrorStore} from 'src/errorBoundary/ErrorStore'

/**
 * GET delle voci prestazioni per la tabella uno
 * @returns
 */
export async function getVociPrestazioni() {
  try {
    let response: any = await getAssicurazioneResourceApi().mspoGlobalElencoVociGet(getDefaultAxiosConfig());
//    return response.data.returnedObject;
    return ErrorStore.getInstance().checkResponse(response);

  } catch (err) {
    let errorMessage: any = await ErrorStore.getInstance().returnMessageError(err, 500)
    throw await new Error(errorMessage.title + errorMessage.message);
  }
}



