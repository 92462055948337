import moment from 'moment'
import {isTimeCFP} from 'src/utilities/utility'

export default {
  nomeCorso: {
    isRequired: true,
  },
  modalita: {
    isRequired: true,
  },
  tipologiaEvento: {
    isRequired: true,
  },
  tipologiaAttivita: {
    isRequired: true,
  },
  soggettoErogante: {
    isRequired: true,
  },
  descrizione: {
    isRequired: true,
  },
  settore: {
    isRequired: true,
  },
  regione: {
    isRequired: true,
  },
  luogo: {
    isRequired: true,
  },
  sede: {
    isRequired: true,
  },
  dataInizio: {
    _custom: {
      check: (code, rule, data) => {
        if(moment(data.dataInizio).get('year') > 3000 || moment(data.dataInizio).get('year') < 1000) {
          return false
        }
        if (data.__IS_EDIT_MODE) {
          return true
        }
        return true // moment().diff(data.dataInizio, 'days') <= 0
      },
    },
    isRequired: true,
  },
  dataFine: {
    _custom: {
      check: (code, rule, data) => {
        if(moment(data.dataFine).get('year') > 3000 || moment(data.dataFine).get('year') < 1000) {
          return false
        }
        if (data.__IS_EDIT_MODE) {
          return true
        }
        if (data.dataInizio) {
          return moment(data.dataInizio).diff(data.dataFine, 'days') <= 0
        } else {
          return true //moment().diff(data.dataFine, 'days') <= 0
        }
      },
    },
    isRequired: true,
  },
  totOre: {
    _custom: {
      check: (code, rule, data) => {
        if (isTimeCFP(data.totOre)) {
          if ( (data.dataInizio !== "") && (data.dataFine !== "") && data.totOre) {
            const startDate = moment(data.dataInizio);
            const endDate = moment(data.dataFine);
            const durationInDays = endDate.diff(startDate, 'days') + 1;
            const maxHours = durationInDays * 24;
            const oreEMinuti = code.split(':');

            if (oreEMinuti.length === 2) {
              const ore = parseInt(oreEMinuti[0]);
              const minuti = parseInt(oreEMinuti[1]);

              if (!isNaN(ore) && !isNaN(minuti)) {
                const totOreAsNumber = ore + minuti / 60;

                if (totOreAsNumber <= maxHours) {
                  return true;
                } else {
                  return false;
                }
              } else {
                return false;
              }
            } else {
              return false;
            }
          } else {
            if ((data?.dataInizio === null || data?.dataInizio === undefined || data.dataInizio === "" ) || (data?.dataFine === null || data?.dataFine === undefined || data.dataFine === ""))
             { return true;}
            else
            {return false;}
          }
        } else {
          return false;
        }
      },
      error: 'Inserisci una durata valida nel formato ore:minuti',
    },
  },
  crediti: {
    _custom: {
      check: (code, rule, data) => {
        if(data?.crediti > 0 )
        return true
      else
      return false
      },
      error: 'Il valore inserito non è valido',
    },
  },
  check_attestato: {
    _custom: {
      check: (code, rule, data) => {
        return !!data?.attestato
      },
    },
  },
}
