import * as React from 'react'
import {PageSection, Title} from '@patternfly/react-core'
import GenericTable from '../components/GenericTable'
import GenericBreadCrumb from 'src/frontend/app/components/GenericBreadCrumb'
import {elencoIscrittiList, elencoIscrittiListCsv, getOrdiniCompetentiList} from 'src/Query/ElencoIscrittiFilterQuery'
import {getCodificaStati} from 'src/processes/CodificaStati'
import {
    camelCaseToWord,
    convertObjectToArray,
    convertStatoToCodiceStato,
    convertUrlParamsToObject
} from 'src/utilities/utility'
import {
    PATH_TO_BO_ANAG_ELENCO_ISCRITTI,
    PATH_TO_BO_ANAG_ELENCO_ISCRITTI_CANCELLA_ISCRITTO,
    PATH_TO_BO_ANAG_ELENCO_ISCRITTI_CANCELLA_ISCRITTO_STP,
    PATH_TO_BO_ANAG_ELENCO_ISCRITTI_CONFERMA_ISCRITTO,
    PATH_TO_BO_ANAG_ELENCO_ISCRITTI_CONFERMA_ISCRITTO_STP,
    PATH_TO_BO_ANAG_ELENCO_ISCRITTI_MODIFICA_ISCRIZIONE,
    PATH_TO_BO_ANAG_ELENCO_ISCRITTI_REGISTRAZIONE_PAGAMENTO,
    PATH_TO_BO_ANAG_ELENCO_ISCRITTI_RICHIEDI_INTEGRAZIONI_ISCRIZIONE,
    PATH_TO_BO_ANAG_ELENCO_ISCRITTI_RICHIEDI_INTEGRAZIONI_ISCRIZIONE_STP,
    PATH_TO_BO_ANAG_ELENCO_ISCRITTI_RIGETTA_ISCRIZIONE,
    PATH_TO_BO_ANAG_ELENCO_ISCRITTI_RIGETTA_ISCRIZIONE_STP,
    PATH_TO_BO_ANAG_ELENCO_ISCRITTI_VISUALIZZA_DATI_ISCRITTO,
    PATH_TO_BO_ANAG_ELENCO_ISCRITTI_VISUALIZZA_DATI_STP,
    PATH_TO_BO_ATTI_DISCIPLINARI,
    PATH_TO_BO_CREDITI_FORMATIVI,
    PATH_TO_BO_ISCRITTI_EVENTO_CATALOGO,
    RO_CONS_PROV_DISC,
    RO_GEST_ATT_DISC,
    RO_GEST_QUOT_ASSO,
    RO_GEST_UTEN_ODAF
} from 'src/app/utils/RoutesConstants'
import {TokenStore} from 'src/keycloak/jwt/TokenStore'
import GenericChips from 'src/frontend/app/components/GenericChips'
import {getAllEnti} from 'src/model/EnteFormazione'

let columnsNames = [
  'Tipologia',
  'Ordine di appartenenza',
  'N. iscrizione',
  'Codice fiscale',
  'Nome/Cognome/Ragione sociale',
  'Stato',
  'Titolo',
  'PEC',
  'Iscrizione EPAP',
  'Stato smart card',
  'Stato lettore smart card',
  'Stato token',
  'Richiesta trasferimento ordine in corso',
  'Richiesta cambio sezione in corso',
  'idIscrizione', // sarà un campo nascosto [non eliminare]
]

/**
 * Viene utilizzato per determinare i filtri sul secondo livello.
 */
const filterParams = []

/**
 * Viene utilizzato per nascondere e visualizzare input e dropdown nei filtri.
 * Nell'array indicare l'indice della colonna che si vuole nascondere.
 */
const columnsTypes = [
  {
    dropdownColumnID: [2, 3, 4, 6, 7], //Input text
    inputColumnID: [0, 1, 5, 8, 9, 10, 11, 12, 13], // Select
  },
]

let columnsToHide = []
let filtersToHide = []

let codiceProvinciaResidenza
const PF = 'PERSONA FISICA'
const PG = 'PERSONA GIURIDICA'

class ElencoIscritti extends React.Component<any, any> {
  constructor(props) {
    super(props)
    this.state = {
      isLoading: false,
      selectedRow: {},
      rowsNames: [],
      listaStati: [],
      listaOrdini: [],
      listaOrdiniCompetenti: [],
      elencoIscrittiFilterParams: null,
      itemsPageCount: 10,
      filterAndPaginationData: '',
      hideConferma: true,
      hideCancella: true,
      hideAttiDisciplinari: true,
      hideRichiediInfo: true,
      hideRigetta: true,
      hideRegistrazionePagamento: true,
      hideModificaIscrizione: true,
      isFilterActiveTable: false,
      isGlobalSearchActiveTable: false,
      modaleCFP: false,
    }
  }

  nestedDataInFilter: any = []
  async componentDidMount() {
    const isOdaf = TokenStore.getInstance().isCurrentAccountODAF()
    const isConaf = TokenStore.getInstance().isOperatoreCONAF()

    if (isOdaf) {
      columnsToHide = [1, 6, 7, 8, 9, 10, 11, 12, 13, 14]
      filtersToHide = [1, 14]
    } else {
      columnsToHide = [6, 7, 8, 9, 10, 11, 12, 13, 14]
      filtersToHide = [14]
    }

    this.setState({ isLoading: true })

    try {
      const codificaStatiResponse = await getCodificaStati()
      this.setState({ listaStati: codificaStatiResponse })

      const entiResponse = await getAllEnti()
      const listaOrdini = Object.keys(entiResponse)
        .filter((code) => entiResponse[code].tipo === 'o')
        .map((code) => {
          return {
            label: entiResponse[code].nome,
            value: code,
          }
        })
      this.setState({ listaOrdini: listaOrdini })

      let listaOrdiniCompetenti = []

      if (!isConaf) {
        const ordiniCompetentiResponse = await getOrdiniCompetentiList()

        const listaOrdiniFiltrata = listaOrdini
          .filter((ordine) => ordiniCompetentiResponse[0].includes(ordine.value))
          .sort((a, b) => a.label.localeCompare(b.label))

        listaOrdiniCompetenti = listaOrdiniFiltrata
      } else {
        const listaOrdiniSorted = listaOrdini.sort((a, b) => a.label.localeCompare(b.label))
        listaOrdiniCompetenti = listaOrdiniSorted
      }

      this.setState({ listaOrdiniCompetenti: listaOrdiniCompetenti })
    } catch (err) {
      throw new Error('Errore durante il recupero delle liste: ' + err)
    }

    await elencoIscrittiList(codiceProvinciaResidenza, undefined, this.state.itemsPageCount, await this.state.listaStati, 1)
      .then((response: any) => {
        var statoLettoreSmartESmartCard = [
          { label: ['Non in possesso'], selected: false },
          { label: ['Dispositivo valido'], selected: false },
          { label: ['Dispositivo scaduto'], selected: false },
          { label: ['Dispositivo o certificato in scadenza'], selected: false },
          { label: ['Richiesta di fornitura in corso'], selected: false },
        ]
        var iscritti = JSON.parse(response)

        /**
         * contiene la lista delle dropdown presenti nei filtri
         */
        this.nestedDataInFilter = [
          [
            { label: [PF], selected: false },
            { label: [PG], selected: false },
          ], //tipo anagrafe
          this.state.listaOrdiniCompetenti.map((ordine) => ({ label: [ordine.label], value: [ordine.value], selected: false })), //ordini
          [], //numero iscrizione
          [], //codice fiscale
          [], //nome cognome e ragione sociale
          this.state.listaStati.map((stato) => ({ label: [stato.descrizioneStato], selected: false })), //stato
          [], //titolo
          [], //pec
          [
            { label: ['Si'], selected: false },
            { label: ['No'], selected: false },
          ], //iscrizione epap
          statoLettoreSmartESmartCard, //stato smart card
          statoLettoreSmartESmartCard, //stato lettore smart card
          [
            { label: ['Si'], selected: false },
            { label: ['No'], selected: false },
          ], //stato token
          [
            { label: ['Si'], selected: false },
            { label: ['No'], selected: false },
          ], //Richiesta trasferimento ordine in corso
          [
            { label: ['Si'], selected: false },
            { label: ['No'], selected: false },
          ], //Richiesta cambio sezione in corso
        ]
        var filterAndPaginationData = {
          pageCount: iscritti.pageCount,
          itemsPageCount: iscritti.size,
          totalPageCount: iscritti.totalCount,
          codiceProvinciaResidenza: codiceProvinciaResidenza,
          listaStati: this.state.listaStati,
          listaOrdiniCompetenti: this.state.listaOrdiniCompetenti,
          nestedDataInFilter: this.nestedDataInFilter,
        }
        this.setState({ rowsNames: iscritti.content, filterAndPaginationData: filterAndPaginationData })
        this.setState({ isLoading: false })
      })
      .catch((err) => {
        throw new Error("Errore durante il recupero dell'elenco iscritti: " + err)
      })
  }

  loadingFunction = () => {
    this.componentDidMount()
  }

  hideStateOptions = (tableRows) => {
    var columnIndex = columnsNames.map((column) => column.toUpperCase()).indexOf('STATO')
    var codiceStato = convertStatoToCodiceStato(tableRows.data[columnIndex], this.state.listaStati)
    var userODAFHasGestAnagRoles = TokenStore.getInstance().isCurrentAccountODAF() && TokenStore.getInstance().currentAccountHasRolesOR([RO_GEST_UTEN_ODAF])
    var userHasAttDiscRoles = TokenStore.getInstance().currentAccountHasRolesOR([RO_GEST_ATT_DISC, RO_CONS_PROV_DISC])
    var userODAFHasQuoteAssoORGestAnagRoles =
      TokenStore.getInstance().isCurrentAccountODAF() && TokenStore.getInstance().currentAccountHasRolesOR([RO_GEST_QUOT_ASSO])
    //reset hidden dropdown item
    this.setState({
      hideConferma: true,
      hideCancella: true,
      hideAttiDisciplinari: true,
      hideRichiediInfo: true,
      hideRigetta: true,
      hideRegistrazionePagamento: true,
      hideModificaIscrizione: true,
    })

    switch (codiceStato) {
      //Stato 'VALUTAZIONE': visualizza, conferma, richiedi integrazioni, rigetta, visualizza crediti
      case '2':
        this.setState({
          hideConferma: !userODAFHasGestAnagRoles,
          hideRichiediInfo: !userODAFHasGestAnagRoles,
          hideRigetta: !userODAFHasGestAnagRoles,
        })
        break

      //Stato 'ISCRITTO' e 'SOSPESO':  visualizza, atti disciplinari, richiedi integrazioni, cancella, modifica iscrizione, visualizza crediti
      case '4':
      case '7':
        this.setState({
          hideCancella: !userODAFHasGestAnagRoles,
          hideAttiDisciplinari: !userHasAttDiscRoles,
          hideModificaIscrizione: !userODAFHasGestAnagRoles,
          hideRegistrazionePagamento: !userODAFHasQuoteAssoORGestAnagRoles,
        })
        break

      //Stato 'RADIATO': visualizza, atti disciplinari, visualizza crediti
      case '8':
        this.setState({
          hideAttiDisciplinari: !userHasAttDiscRoles,
        })
        break

      case null:
        this.setState({
          hideCancella: !userODAFHasGestAnagRoles,
          hideAttiDisciplinari: !userHasAttDiscRoles,
          hideModificaIscrizione: !userODAFHasGestAnagRoles,
          hideRegistrazionePagamento: !userODAFHasQuoteAssoORGestAnagRoles,
        })
        break
      default:
        this.setState({
          hideCancella: true,
          hideAttiDisciplinari: true,
          hideConferma: true,
          hideRichiediInfo: true,
          hideRigetta: true,
          hideRegistrazionePagamento: true,
          hideModificaIscrizione: true,
        })
        break
    }

    var columnIndex2 = columnsNames.map((column) => column.toUpperCase()).indexOf('TIPOLOGIA')
    var tipologia = tableRows.data[columnIndex2]
    if (tipologia.includes('GIURIDICA')) {
      this.setState({
        hideAttiDisciplinari: true,
      })
    }
  }

  //Funzione che consente di settare l'elemento selezionato all'interno di ogni select dei filtri.
  onSelectOptionItem = async (e: any, index: any) => {
    let selectedValue = !e.label ? e.target.value : e.label[0]
    let filterOptions = this.state.filterAndPaginationData
    filterOptions.nestedDataInFilter[index].map((item) => (item.selected = false)) // set della opzione precedente a false
    filterOptions.nestedDataInFilter[index].find((item) => item.label[0] === selectedValue).selected = true
    this.setState({ filterAndPaginationData: filterOptions })
  }

  testReset = async () => {
    let filterOptions = this.state.filterAndPaginationData
    let newFilterOptions = filterOptions.nestedDataInFilter.map((item) =>
      item?.map((e) => (e.value ? { label: e.label, value: e.value, selected: false } : { label: e.label, selected: false }))
    )
    filterOptions.nestedDataInFilter = newFilterOptions
    this.setState({ filterAndPaginationData: filterOptions })
  }

  render() {
    const { filterAndPaginationData, isFilterActiveTable, isGlobalSearchActiveTable } = this.state
    /**
     * recupero i parametri dall'url
     */
    const urlParams = window.location.search?.split('?')[1]
    const filterParamsFromTodo = convertUrlParamsToObject(urlParams)
    const filterParamsListFromTodo = convertObjectToArray(filterParamsFromTodo)
    const dropdownActionList = [
      {
        title: 'Visualizza',
        onClick: (e) => onSelectAction(e, PATH_TO_BO_ANAG_ELENCO_ISCRITTI_VISUALIZZA_DATI_ISCRITTO, PATH_TO_BO_ANAG_ELENCO_ISCRITTI_VISUALIZZA_DATI_STP),
        itemKey: 'visualizza',
      },
      {
        title: 'Conferma',
        onClick: (e) => onSelectAction(e, PATH_TO_BO_ANAG_ELENCO_ISCRITTI_CONFERMA_ISCRITTO, PATH_TO_BO_ANAG_ELENCO_ISCRITTI_CONFERMA_ISCRITTO_STP),
        itemKey: 'conferma',
        hidden: this.state.hideConferma,
      },
      {
        title: 'Rigetta',
        onClick: (e) => onSelectAction(e, PATH_TO_BO_ANAG_ELENCO_ISCRITTI_RIGETTA_ISCRIZIONE, PATH_TO_BO_ANAG_ELENCO_ISCRITTI_RIGETTA_ISCRIZIONE_STP),
        itemKey: 'rigetta',
        hidden: this.state.hideRigetta,
      },
      {
        title: 'Richiedi integrazioni',
        onClick: (e) =>
          onSelectAction(
            e,
            PATH_TO_BO_ANAG_ELENCO_ISCRITTI_RICHIEDI_INTEGRAZIONI_ISCRIZIONE,
            PATH_TO_BO_ANAG_ELENCO_ISCRITTI_RICHIEDI_INTEGRAZIONI_ISCRIZIONE_STP
          ),
        itemKey: 'richiediIntegrazioni',
        hidden: this.state.hideRichiediInfo,
      },
      {
        title: 'Cancella',
        onClick: (e) => onSelectAction(e, PATH_TO_BO_ANAG_ELENCO_ISCRITTI_CANCELLA_ISCRITTO, PATH_TO_BO_ANAG_ELENCO_ISCRITTI_CANCELLA_ISCRITTO_STP),
        itemKey: 'cancella',
        hidden: this.state.hideCancella,
      },
      {
        title: 'Registrazione pagamento',
        onClick: (e) => onSelectAction(e, PATH_TO_BO_ANAG_ELENCO_ISCRITTI_REGISTRAZIONE_PAGAMENTO),
        itemKey: 'registrazionePagamento',
        hidden: this.state.isPG || this.state.hideRegistrazionePagamento,
      },
      {
        title: 'Modifica iscrizione',
        onClick: (e) => onSelectAction(e, PATH_TO_BO_ANAG_ELENCO_ISCRITTI_MODIFICA_ISCRIZIONE),
        itemKey: 'modificaIscrizione',
        hidden: this.state.isPG || this.state.hideModificaIscrizione,
      },
      {
        title: 'Atti disciplinari',
        onClick: (e) => onSelectAction(e, PATH_TO_BO_ATTI_DISCIPLINARI, PATH_TO_BO_ATTI_DISCIPLINARI),
        itemKey: 'attiDisciplinari',
        hidden: this.state.hideAttiDisciplinari,
      },
      {
        title: 'Visualizza Crediti',
        onClick: (e) => onSelectAction(e, PATH_TO_BO_CREDITI_FORMATIVI, PATH_TO_BO_CREDITI_FORMATIVI),
        itemKey: 'visualizzaCrediti',
        hidden: this.state.isPG,
      },
      {
        title: 'Approvazione Triennio',
        onClick: (e) => onSelectAction(e, PATH_TO_BO_CREDITI_FORMATIVI, 'approvaTriennio'),
        itemKey: 'approvaTriennio',
        hidden: !TokenStore.getInstance().isCurrentAccountODAF(),
      },
    ]

    const onSelectRow = async (row: any) => {
      this.hideStateOptions(row)
      await this.setState({ selectedRow: row })
      //recupero l'indice della colonna "TIPOLOGIA"
      var tipologiaIndex = columnsNames.toString().toUpperCase().split(',').indexOf('TIPOLOGIA')

      //recupero il valore della colonna "TIPOLOGIA" relativo alla riga selezionata
      var tipologia = this.state.selectedRow.data[tipologiaIndex]

      //se la tipologia è "PERSONA FISICA" -> isPF si setta a 'true', mentre isPG si setta a 'false'
      if (tipologia === PF) this.setState({ isPF: true, isPG: false })
      //se la tipologia è "PERSONA GIURIDICA" -> isPG si setta a 'true', mentre isPF si setta a 'false'
      else if (tipologia === PG) this.setState({ isPG: true, isPF: false })

      this.setState({ tipologiaIscrizione: tipologia })
    }

    /**
     * Seleziona l'evento nella dropdown e reindirizza verso il link indicato
     * @param event
     * @param link
     * @param linkSTP
     */
    const onSelectAction = (event, link, linkSTP?) => {
      if (link === PATH_TO_BO_ISCRITTI_EVENTO_CATALOGO) {
        this.setState({ modaleCFP: true })
        return
      }

      /**
       * Questo è l'array di riferimento -> this.state.selectedRow.data
       * ['tipologia', 'ordine di appartenenza', 'n iscrizione', 'cf', 'nome cognome', 'stato', id]
       */
      let idIscrizione = this.state.selectedRow.data[6]
      let cf = this.state.selectedRow.data[3]
      let tipologia = this.state.isPG ? 'PG' : 'PF'
      var pathName = getPathnameByItemTitle(link, linkSTP)

      if (linkSTP === 'approvaTriennio') {
        this.props.history.push(pathName + '?cf=' + cf + '&flagApprovazione=true');
      } else {
      this.props.history.push(pathName + '?cf=' + cf + '&idIscrizione=' + idIscrizione + '&tipologiaIscrizione=' + tipologia)
      }
    }

    /**
     * Recupera l'url verso il quale andare
     * @param link
     * @param linkSTP
     * @returns
     */
    const getPathnameByItemTitle = (link, linkSTP) => {
      if (this.state.isPF) return link
      else if (this.state.isPG) return linkSTP
      return
    }

    /**
     * Controlla se è attivo il filtro e rimuove i parametri nell'url
     * @param event
     */
    const isFilterActive = (event) => {
      window.history.replaceState({}, document.title, PATH_TO_BO_ANAG_ELENCO_ISCRITTI)
      this.setState({ isFilterActiveTable: event })
    }

    /**
     * Controlla se è attiva la ricerca e rimuove i parametri nell'url
     * @param event
     */
    const isGlobalSearchActive = (event) => {
      window.history.replaceState({}, document.title, PATH_TO_BO_ANAG_ELENCO_ISCRITTI)
      this.setState({ isGlobalSearchActiveTable: event })
    }

    const ShowActiveFilters: any = () => {
      var key
      return filterParamsListFromTodo.map(
        (param, id) => (
          (key = Object.keys(param).toString()), (<GenericChips key={id} color={'primary'} label={camelCaseToWord(key) + ': ' + param[key]}></GenericChips>)
        )
      )
    }

    return (
      <PageSection>
        <div className="container register-container">
          <GenericBreadCrumb
            paths={[
              { label: 'Anagrafica', link: '#' },
              { label: 'Elenco iscritti', link: PATH_TO_BO_ANAG_ELENCO_ISCRITTI },
            ]}
          />
          <Title headingLevel="h1" size="4xl">
            Elenco iscritti
          </Title>
          <div>
            <div hidden={isFilterActiveTable || isGlobalSearchActiveTable}>
              <ShowActiveFilters />
            </div>
            <GenericTable
              methodNameCall={elencoIscrittiList}
              hideCsv={false}
              methodNameCallForCsvRaw={elencoIscrittiListCsv}
              hideSearch
              tipoElenco={'Iscritti'}
              columnsTypes={columnsTypes}
              isEditableTable={false}
              filterAndPaginationData={filterAndPaginationData}
              columns={columnsNames}
              columnsToHide={columnsToHide}
              filtersToHide={filtersToHide}
              perPageNumber={this.state.itemsPageCount}
              rows={this.state.rowsNames}
              tableHeadFontSize="1em"
              tableContentFontSize="0.8em"
              hideActionColumn={false}
              onSelect={(e) => onSelectRow(e)}
              actions={[{ label: 'Aggiorna', function: '' }]}
              dropdownList={dropdownActionList}
              isFilterActive={(e) => isFilterActive(e)}
              isGlobalSearchActive={(e) => isGlobalSearchActive(e)}
              fitContentType="initial"
              selectOptionItem={(e, index) => this.onSelectOptionItem(e, index)}
              resetFilter={() => this.testReset()}
            />
          </div>
        </div>
      </PageSection>
    )
  }
}
export default ElencoIscritti
