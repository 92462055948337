import {Title} from '@patternfly/react-core'
import {Row} from 'design-react-kit'
import React from 'react'
import {PATH_TO_FO_TODO} from 'src/app/utils/RoutesConstants'
import GenericModal from 'src/backoffice/app/components/GenericModal'
import GenericSpinner from 'src/backoffice/app/components/GenericSpinner'
import GenericTodo from 'src/frontend/app/components/GenericTodo'
import {setDisabledTodoEventByID} from 'src/processes/TodoService'
import {goTo} from 'src/utilities/utility'

export default class FormTodoList extends React.Component<FormTodoListProps, any>{
  constructor(props) {
    super(props);
    this.state = {
      activeTab: props.activeTab,
      isLoaded: false,
    };
  }

  async componentDidMount(): Promise<void> {
    await window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    await this.setState({ isLoaded: true })

  }

  closeModal = (link?) => {
    this.setState({modalOpen:false, makeChoice: false});
    if(link !== undefined){
      window.location.href = link;
    }
  }

  onChoiceAction = async (link?) => {
    //setta la data di disattivazione della
    await setDisabledTodoEventByID(this.state.idTodoEvent)
    this.setState({ modalOpen: false, makeChoice: false });
    goTo(link)
  }

  showModal = async (element, booleanValue, title, text, makeChoice) => {
    if (element.disattivabile !== false) {
      await this.setState({
        modalOpen: booleanValue,
        modalTitle: title,
        modalText: text,
        makeChoice: makeChoice,
        idTodoEvent: element.idTodoEvent
      });
    }
  }

  render() {

    const currentBasePath = window.location.origin

    if (this.state.isLoaded)
      return (
        <>
            <Row className="pb-4">

              {this.props.list?.length === 1 ?
                          <Title headingLevel="h4"> Trovata <strong>{this.props.list?.length}</strong> corrispondenza </Title>
                          :
                          <Title headingLevel="h4"> Trovate <strong>{this.props.list?.length}</strong> corrispondenze </Title>
              }
            </Row>
          <div>
            {this.props.list?.map((element, index) => (
              <GenericTodo
                hidden={element.dataDisattivazione}
                category={element.modulo}
                message={element.messaggio}
                date={element.dataInserimento}
                disableMessageChoice={element.disattivabile}
                onClickIcon={() => this.showModal(element, true, "Messaggio", "Vuoi disattivare questo tipo di notifica?", true)}
                url={currentBasePath + element.urlParams}>
              </GenericTodo>
            ))
            }
          </div>

          <div>
            <GenericModal
              title={this.state.modalTitle}
              text={this.state.modalText}
              modalOpen={this.state.modalOpen}
              closeButton={() => this.closeModal()}
              choiceAction={() => this.onChoiceAction(PATH_TO_FO_TODO)}
              makeChoice={this.state.makeChoice}
            />
          </div>
        </>
      )
    else return (<><GenericSpinner /></>)
  }
}

export declare interface FormTodoListProps {
  activeTab?: any;
  list?: any;         //lista delle todo list
}
