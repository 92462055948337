import {Label} from 'design-react-kit'
import React from 'react'
import GenericButton from '../../../components/GenericButton'
import GenericCard from '../../../components/GenericCard'

export default class Invio extends React.Component<InvioProps, any>{
  constructor(props) {
    super(props);
    this.state = {
      activeTab: props.activeTab,
      tabClick: props.tabClick,
      prevData: props.prevData,
      annullaClick: props.annullaClick,
      modificaDatiFlag: props.modificaDatiFlag,
      polizza: props.polizza,
      visualizzaFlag: props.visualizzaFlag,
      fields: (props.prevData) ? props.prevData[0] : (props.data) ? props.data : {},
      message: ""
    };
  }


  async componentDidMount(): Promise<void> {

    if(this.props.prevData){
      await this.setState({fields : this.props.prevData[0]})
     }
     if(this.state.modificaDatiFlag === false && this.state.visualizzaFlag === false && this.state.reiscrizioneFlag === false ) {
       if(this.props.data) {
          this.setState({fields: this.props.data})
        }
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    }

    this.props.getSelectedValue(this.state.fields)
  }
    render() {
      return(
        <>
          <GenericCard cardHeader={"Invia Polizza"} cardBody={
            <div>
                <div>
                    <Label>Procedura di compilazione polizza completata correttamente. Per confermare e inviare la domanda cliccare "Invia".</Label>
                </div>
                <div className='col-lg-8 offset-lg-4 p-4'>
                    <GenericButton label="Invia" onClickEvent={()=>this.props.inviaPolizza(this.props.data)} color={'primary'}/>
                </div>
            </div>
        }></GenericCard>
          </>
       )
    }
}
export declare interface InvioProps {
  activeTab?: any;
  tabClick?: any;
  data?:any;
  polizza?:any;
  prevData?:any;
  processoIscrizione?: any;
  annullaClick?: any;
  modificaDatiFlag?: any;
  aumentoMassimaleFlag?: any;
  visualizzaFlag?: any;
  inviaPolizza?: any;
  tabNumber?: any;
  getDatiUploadFile?:any;
  datiAllegato?:any
  getSelectedValue?:any

}
