import {Form} from 'design-react-kit'
import React from 'react'
import GenericSpinner from 'src/backoffice/app/components/GenericSpinner'
import GenericInput from 'src/frontend/app/components/GenericInput'
import GenericSelect from 'src/frontend/app/components/GenericSelect'
import {getTipologiaSocietaByIdFo} from 'src/processes/ProcessoIscrizione'
import {getTipologieSocieta} from 'src/processes/TipologieSocieta'
import {isCurrentMobileDevice} from 'src/utilities/deviceUtility'

/* ----------------------------------- VARIABILI E COSTANTI ----------------------------------------- */
const checkTipologiaSocieta = '1'
/* -------------------------------------------------------------------------------------------------- */

export class FormDatiProfessionaliStpFO extends React.Component<GeneralFormDatiProfessionaliStpFOProps, any> {
  constructor(props) {
    super(props)
    this.state = {
      listaTipologieSocieta: [],
      oggettoProfessionaleSecondariaHidden: true,
    }
  }

  async componentDidMount() {
    //chiamata al servizio che restituisce la tipologia società per id
    var idTipologiaSocieta = this.props.datiIscrizione.soggettoDTO.tipolSoc
    if (idTipologiaSocieta && idTipologiaSocieta !== null) {
      await getTipologiaSocietaByIdFo(idTipologiaSocieta)
        .then((response: any) => {
          var tipolsoc: any = { label: '', value: '' }
          tipolsoc = {
            label: response.data.descrizione,
            value: response.data.id,
          }
          this.setState({ tipolsoc })
        })
        .catch(() => {
          this.setState({ dataLoaded: true })
        })
    }

    //chiamata al servizio che restituisce la lista delle tipologie di società
    getTipologieSocieta().then((response: any) => {
      var tipologieSocieta = [{ label: '', value: '' }]

      response.data.map((societa, id) => {
        return (tipologieSocieta[id] = {
          label: societa.descrizione,
          value: societa.id,
        })
      })
      this.setState({ listaTipologieSocieta: tipologieSocieta })
    })

    if (idTipologiaSocieta === checkTipologiaSocieta) {
      this.setState({ oggettoProfessionaleSecondariaHidden: false })
    } else {
      this.setState({ oggettoProfessionaleSecondariaHidden: true })
    }
    var soggetto = this.props.datiIscrizione?.soggettoDTO
    var tipologiaSocieta = soggetto?.tipolsoc ? { label: soggetto?.tipolsoc, value: soggetto?.tipolsoc } : undefined

    this.setState({ dataLoaded: true, tipologiaSocieta: tipologiaSocieta, soggetto: soggetto })
  }

  handleChange = (event, fieldId, type) => {
    if (type === 'input' && event.target.id === fieldId) {
      this.props.formData({ id: fieldId, value: event.target.value })
    } else if (type === 'select' && fieldId === 'idTipolSoc') {
      this.props.formData({ id: fieldId, value: event.value })

      if (event.value === Number(checkTipologiaSocieta)) {
        this.setState({ oggettoProfessionaleSecondariaHidden: false })
      } else {
        this.setState({ oggettoProfessionaleSecondariaHidden: true })
      }
    }
  }

  render() {
    const { oggettoProfessionaleSecondariaHidden, tipolsoc, soggetto, dataLoaded } = this.state
    const { tabNumber, flagReiscrizione } = this.props

    return (
      <>
        {dataLoaded === true ? (
          <div>
            {/*-------------------------------------------------------------------- FORM PER ISCRIZIONE STP ----------------------------------------------------------------------*/}
            <Form>
              <div className="row">
                <div className={isCurrentMobileDevice() ? null : 'col-6'}>
                  <GenericSelect
                    defaultValue={flagReiscrizione === 1 ? null : tipolsoc}
                    errorMessageAlert={this.props.message}
                    elementId={'idTipolSoc'}
                    placeholder={'Selezionare tipologia società'}
                    label={'Tipologia società*'}
                    defaultOptions={this.state.listaTipologieSocieta}
                    isSearchable={false}
                    currentStep={tabNumber}
                    onChange={(e) => this.handleChange(e, 'idTipolSoc', 'select')}
                    isRequired
                  />
                </div>
                <div className={isCurrentMobileDevice() ? null : 'col-6'}>
                  <GenericInput
                    defaultValue={flagReiscrizione === 1 ? null : soggetto?.socAttivitaProfessionalePrevalente}
                    errorMessageAlert={this.props.message}
                    type={'text'}
                    id={'idSocAttivitaProfessionalePrevalente'}
                    placeholder={'Oggetto professionale prevalente'}
                    label={'Oggetto professionale prevalente*'}
                    onChange={(e) => this.handleChange(e, 'idSocAttivitaProfessionalePrevalente', 'input')}
                    currentStep={tabNumber}
                    isRequired
                  />
                </div>
              </div>
              {/*Campo disponibile solo se tipologia società è pluridisciplinare*/}
              <div className="row">
                <div className={isCurrentMobileDevice() ? null : 'col-6'}>
                  <GenericInput
                    defaultValue={flagReiscrizione === 1 ? null : soggetto?.socAttivitaProfessionaleSecondaria}
                    errorMessageAlert={this.props.message}
                    type={'text'}
                    isHidden={oggettoProfessionaleSecondariaHidden}
                    id={'idSocAttivitaProfessionaleSecondaria'}
                    placeholder={'Oggetto professionale secondaria'}
                    label={'Oggetto professionale secondaria*'}
                    onChange={(e) => this.handleChange(e, 'idSocAttivitaProfessionaleSecondaria', 'input')}
                    currentStep={tabNumber}
                    isRequired={!oggettoProfessionaleSecondariaHidden}
                  />
                </div>
              </div>
            </Form>
            {/* ----------------------------------------------------------------------------------------------------------------------------------------------------------------- */}
          </div>
        ) : (
          <GenericSpinner />
        )}
      </>
    )
  }
}

export declare interface GeneralFormDatiProfessionaliStpFOProps {
  message?: any
  tabNumber?: number
  formData?: any
  isVisualizza?: boolean
  isModifica?: boolean
  datiIscrizione?: any
  flagReiscrizione?: any
}
