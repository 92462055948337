import {Icon, Label, Table} from 'design-react-kit'
import React, {Fragment} from 'react'
import GenericButton from './GenericButton'
import GenericInput from './GenericInput'
import {Checkbox} from '@patternfly/react-core'
import {AppUtils} from 'src/app/utils/FormUtils'
import {isCurrentMobileDevice} from 'src/utilities/deviceUtility'
import {TableComposable, Tbody, Thead} from '@patternfly/react-table'

class GenericEditableTable extends React.Component<GeneralTableProps, any> {
    constructor(props) {
      super(props);
      this.state = {
        rowOpen: {value: false, index: 0},
        rows: this.props.rows
      };
    }

    render() {

      const toggle = (index,row) => {
        // eslint-disable-next-line react/no-direct-mutation-state
        this.state.rows[index].isExpanded = !this.state.rows[index].isExpanded;
      };

      return (
       <div key={"genericEditableTable"}>
        {isCurrentMobileDevice() ?
          <TableComposable gridBreakPoint={'grid-lg'}  aria-label="Sort table" className={this.props.className} isStriped={this.props.striped}>
            <Thead>
              <tr >
                    {this.props.columns?.map((column:any, index:any) => {
                      return (
                        <th style={{fontSize:'initial'}} key={index}>{column}</th>
                      )
                    })}
                    {(this.props.editable || this.props.iconButton) && <th></th>}
              </tr>
            </Thead>
            <Tbody >
            {this.state.rows?.map((row:any, rowIndex:any) => (
              <Fragment key={this.state.rows[rowIndex].id}>
                <tr className='table-primary' key={this.state.rows[rowIndex].id} style={{lineHeight: 'normal'}}>
                  <th style={{fontSize:'initial'}}>
                    <div className="row">
                          <div style={{width: "100px"}} hidden={!this.state.rows[rowIndex].isExpanded}>
                              <Icon color="primary" onClick={()=>{toggle(rowIndex,this.state.rows[rowIndex]);this.props.onToggle(this.state.rows[rowIndex])}} size='sm' icon={'it-collapse'} />{'  '+row.id}
                          </div>
                          <div style={{width: "100px"}} hidden={this.state.rows[rowIndex].isExpanded}>
                              <Icon color="primary" onClick={()=>{toggle(rowIndex,this.state.rows[rowIndex]);this.props.onToggle(this.state.rows[rowIndex])}} size='sm' icon={'it-expand'} />{'  '+row.id}
                          </div>
                    </div>
                  </th>

                  {this.state.rows[rowIndex].data?.map((intestazione:any, indexIntestazione) => {
                        if(indexIntestazione>0) {
                          if(this.state.rows[rowIndex].isExpanded) {
                            return (<th style={{fontSize:'initial'}} key={indexIntestazione}>{intestazione.expandedValue}</th>)
                          }
                          else {
                            return (<th style={{fontSize:'initial'}} key={indexIntestazione}>{intestazione.value}</th>)
                          }
                        }
                        else return null
                    })

                  }
                  {(this.props.editable || this.props.iconButton) && <th></th>}
                </tr>
                {(this.state.rows[rowIndex].isExpanded) ? (
                      this.state.rows[rowIndex].children?.map((child:any, childIndex) => {

                        return (
                        <tr key={child.id} hidden={row.isExpanded === false} style={{color:'black'}}>
                                  {child.data.map((childData:any, childDataIndex) => {
                                      return (
                                        <td key={childDataIndex} width={childData.width} style={{lineHeight: 'normal', minHeight:'20px', fontSize:'initial'}}>

                                        { (childData.type === 'input' && this.props.editable)
                                            ? <GenericInput showName={true} type="decimal-comma" min={0} max={this.props.max} isDisabled={childData.disabled} key={childDataIndex} id={childData.id} helpTextLabel={childData.label} defaultValue={childData.value} onChange={(e)=>this.props.onChange(rowIndex,childIndex,childDataIndex,e,'text')}/>
                                            : (childData.type === 'input-integer') ? <GenericInput showName={true} type="numeric" min={0} max={this.props.max} isDisabled={childData.disabled} key={childDataIndex} id={childData.id} helpTextLabel={childData.label} defaultValue={childData.value} onChange={(e)=>this.props.onChange(rowIndex,childIndex,childDataIndex,e,'text')}/>
                                            : (childData.type === 'checkbox') ? <div style={{marginTop:'5px'}}><Checkbox key={childDataIndex} id={childData.id} isChecked={childData.value} isDisabled={childData.disabled || !this.props.editable} onChange={(e)=>this.props.onChange(rowIndex,childIndex,childDataIndex,e,'checkbox')}/></div>
                                            : (childData.type === 'label-currency') ? <Label style={{marginTop:'5px'}}>{AppUtils.currency(childData.value)}</Label>
                                            : (childData.type === 'label-it-number') ? <Label style={{marginTop:'5px'}}>{AppUtils.itNumber(childData.value)}</Label>
                                            : <Label style={{marginTop:'5px'}}>{childData.value}</Label>
                                        }
                                      </td>
                                      )
                                  })}
                                        <td width={50}  >
                                          <div style={{display:'flex'}} hidden={this.props.hideActionColumn}>
                                            <div hidden={this.props.editable || !this.props.editButton || child.data.find(item => item.id === 'compenso').type === 'input'} style={{marginTop:'-15px', fontSize:'initial'}}  >
                                              <GenericButton src={this.props.editButton} type={this.props.type} label={this.props.labelButton} color={this.props.colorButton} onClickEvent={(e)=>{this.props.onUpdate(rowIndex, childIndex, true)}}  />
                                            </div>
                                            <div hidden={!this.props.editable || !this.props.editButton || child.data.find(item => item.id === 'compenso').type !== 'input'} style={{marginTop:'-15px', fontSize:'initial'}} >
                                                <Icon color="primary" onClick={(e)=>{this.setState({editingFlag:false});this.props.onUpdate(rowIndex, childIndex, false)}} icon={'it-check'} size='sm' />
                                            </div>
                                            <div style={{marginTop:'-15px', fontSize:'initial'}} >
                                              <GenericButton className={""} src={!isCurrentMobileDevice() && this.props.iconButton} type={this.props.type} label={isCurrentMobileDevice() ? this.props.labelButton : null} color={this.props.colorButton} onClickEvent={(e)=>this.props.onSelect(rowIndex, childIndex)}/>
                                            </div>

                                          </div>
                                        </td>
                              </tr>
                        )
                      })

                ) : (<div key={"space"}></div>)}
              </Fragment>
            ))}
            </Tbody>
          </TableComposable>
        :
          <Table striped={this.props.striped} hover={this.props.hover} aria-label="Sort table" className={this.props.className}>
              <thead>
                <tr >
                      {this.props.columns?.map((column:any, index:any) => {
                        return (
                          <th style={{fontSize:'initial'}} key={index}>{column}</th>
                        )
                      })}
                      {(this.props.editable || this.props.iconButton) && <th></th>}
                </tr>
              </thead>
              <tbody >
              {this.state.rows?.map((row:any, rowIndex:any) => (
                <Fragment key={this.state.rows[rowIndex].id}>
                  <tr className='table-primary' key={this.state.rows[rowIndex].id} style={{lineHeight: 'normal'}}>
                    <th style={{fontSize:'initial'}}>
                      <div className="row">

                            <div style={{width: "100px"}} className="col-1" hidden={!this.state.rows[rowIndex].isExpanded}>
                                <Icon color="primary" onClick={()=>{toggle(rowIndex,this.state.rows[rowIndex]);this.props.onToggle(this.state.rows[rowIndex])}} size='sm' icon={'it-collapse'} />{'  '+row.id}
                            </div>
                            <div style={{width: "100px"}} className="col-1" hidden={this.state.rows[rowIndex].isExpanded}>
                                <Icon color="primary" onClick={()=>{toggle(rowIndex,this.state.rows[rowIndex]);this.props.onToggle(this.state.rows[rowIndex])}} size='sm' icon={'it-expand'} />{'  '+row.id}
                            </div>
                      </div>
                    </th>

                    {this.state.rows[rowIndex].data?.map((intestazione:any, indexIntestazione) => {
                          if(indexIntestazione>0) {
                            if(this.state.rows[rowIndex].isExpanded) {
                              return (<th style={{fontSize:'initial'}} key={indexIntestazione}>{intestazione.expandedValue}</th>)
                            }
                            else {
                              return (<th style={{fontSize:'initial'}} key={indexIntestazione}>{intestazione.value}</th>)
                            }
                          }
                          else return null
                      })

                    }
                    {(this.props.editable || this.props.iconButton) && <th></th>}
                  </tr>
                  {(this.state.rows[rowIndex].isExpanded) ? (
                        this.state.rows[rowIndex].children?.map((child:any, childIndex) => {

                          return (
                          <tr key={child.id} hidden={row.isExpanded === false} style={{color:'black'}}>
                                    {child.data.map((childData:any, childDataIndex) => {
                                        return (
                                          <td key={childDataIndex} width={childData.width} style={{lineHeight: 'normal', minHeight:'20px', fontSize:'initial'}}>

                                          { (childData.type === 'input' && this.props.editable)
                                              ? <GenericInput showName={true} type="decimal-comma" min={0} max={this.props.max} isDisabled={childData.disabled} key={childDataIndex} id={childData.id} helpTextLabel={childData.label} defaultValue={childData.value} onChange={(e)=>this.props.onChange(rowIndex,childIndex,childDataIndex,e,'text')}/>
                                              : (childData.type === 'input-integer') ? <GenericInput showName={true} type="numeric" min={0} max={this.props.max} isDisabled={childData.disabled} key={childDataIndex} id={childData.id} helpTextLabel={childData.label} defaultValue={childData.value} onChange={(e)=>this.props.onChange(rowIndex,childIndex,childDataIndex,e,'text')}/>
                                              : (childData.type === 'checkbox') ? <div style={{marginTop:'5px'}}><Checkbox key={childDataIndex} id={childData.id} isChecked={childData.value} isDisabled={childData.disabled || !this.props.editable} onChange={(e)=>this.props.onChange(rowIndex,childIndex,childDataIndex,e,'checkbox')}/></div>
                                              : (childData.type === 'label-currency') ? <Label style={{marginTop:'5px'}}>{AppUtils.currency(childData.value)}</Label>
                                              : (childData.type === 'label-it-number') ? <Label style={{marginTop:'5px'}}>{AppUtils.itNumber(childData.value)}</Label>
                                              : <Label style={{marginTop:'5px'}}>{childData.value}</Label>
                                          }
                                        </td>
                                        )
                                    })}
                                          <td width={50}  >
                                            <div style={{display:'flex'}} hidden={this.props.hideActionColumn}>
                                              <div hidden={this.props.editable || !this.props.editButton || child.data.find(item => item.id === 'compenso').type === 'input'} style={{marginTop:'-15px', fontSize:'initial'}}  >
                                                <GenericButton src={this.props.editButton} type={this.props.type} label={this.props.labelButton} color={this.props.colorButton} onClickEvent={(e)=>{this.props.onUpdate(rowIndex, childIndex, true)}}  />
                                              </div>
                                              <div hidden={!this.props.editable || !this.props.editButton || child.data.find(item => item.id === 'compenso').type !== 'input'} style={{marginTop:'-15px', fontSize:'initial'}} >
                                                  <Icon color="primary" onClick={(e)=>{this.setState({editingFlag:false});this.props.onUpdate(rowIndex, childIndex, false)}} icon={'it-check'} size='sm' />
                                              </div>
                                              <div style={{marginTop:'-15px', fontSize:'initial'}} >
                                                <GenericButton src={this.props.iconButton} type={this.props.type} label={this.props.labelButton} color={this.props.colorButton} onClickEvent={(e)=>this.props.onSelect(rowIndex, childIndex)}  />
                                              </div>

                                            </div>
                                          </td>
                                </tr>
                          )
                        })

                  ) : (<div key={"space"}></div>)}
                </Fragment>
              ))}
              </tbody>
          </Table>
        }
       </div>
      );
    }
  }

  export declare interface GeneralTableProps {
    columns?: any;
    rows?: any;
    isBorderless?: boolean;
    isButtonHidden?: boolean;
    isHidden?: boolean;
    onSelect?: any;
    onUpdate?: any;
    onClick?: any;
    labelButton?: any;
    iconButton?: any;
    editButton?: any;
    colorButton?: any;
    className?:any;
    src?:any;
    editable?: any;
    onToggle?: any;
    hideActionColumn: any;
    onChange?: any;
    type?: any;
    max?: any;
    striped?: boolean;
    hover?: boolean;
  }

  export default GenericEditableTable;
