import {matchPath, useLocation} from 'react-router-dom'

const useSwitchTipoForm = () => {
  const { pathname } = useLocation()
  if (matchPath(pathname, { path: '*elenco-agenzie*' })) {
    return 1
  } else if (matchPath(pathname, { path: '*elenco-riviste*' })) {
    return 2
  } else if (matchPath(pathname, { path: '*elenco-enti-esterni*' })) {
    return 3
  }
  return 1
}
export default useSwitchTipoForm
