import * as React from 'react'
import {PageSection, Title} from '@patternfly/react-core'
import GenericBreadCrumb from 'src/frontend/app/components/GenericBreadCrumb'
import GenericSpinner from '../components/GenericSpinner'
import {TokenStore} from 'src/keycloak/jwt/TokenStore'
import {
    PATH_TO_BO_ANAG_TRASF_ORDINE_ACCETTA,
    PATH_TO_BO_ANAG_TRASF_ORDINE_CONCEDI_NULLA_OSTA,
    PATH_TO_BO_ANAG_TRASF_ORDINE_RICHIESTA,
    PATH_TO_BO_ANAG_TRASF_ORDINE_RIFIUTA,
    PATH_TO_BO_ANAG_TRASF_ORDINE_VISUALIZZA,
    RO_GEST_UTEN_ODAF
} from 'src/app/utils/RoutesConstants'
import {getDefaultAxiosConfig, getTrasferimentoOrdineResourceApi} from 'src/app/utils/ManagerRestGateway'
import {GenericAsyncTableData} from '../components/GenericAsyncTableData'
import moment from 'moment'
import {generateRequestSort} from 'src/app/utils/ApiUtils'
import {getOrdineCompetenteOperatorePOST} from 'src/processes/Ordini'

//va chiama la funzione findAll (/mswf/trasferimentoOrdine/findAll) senza parametri e restituisce una lista di trasferimenti
// Dati della GenericTable
const STATI = {
  2: 'INVIATA',
  3: 'VALUTAZIONE',
  4: "CHIUSA",
  5: "RESPINTA"
}
const columnsNames =
  [
    { label: 'Codice fiscale', field: 'codFis', isSortable: true },
    { label: 'Cognome', field: 'cognome', isSortable: true },
    { label: 'Nome', field: 'nome', isSortable: true },
    { label: 'Stato', field: 'stato', isSortable: true, render: (row) => STATI[row.stato] },
    { label: 'Titolo', field: 'titoloEsteso', isSortable: false },
    { label: 'Data', field: 'dataTrasferimentoOrdine', isSortable: true },
    { label: 'Da', field: 'trasferimentoOrdineDa', isSortable: true },
    { label: 'A', field: 'trasferimentoOrdineA', isSortable: true },

  ];

//carico la lista delle richieste

const filters = [
  { label: "Cognome", field: "cognome", type: 'input' },
  { label: "Nome", field: "nome", type: 'input' },
  { label: "Codice Fiscale", field: "codFis", type: 'input' },
  { label: "Titolo", field: "titoloEsteso", type: 'input' },
  { label: "Da", field: "trasferimentoOrdineDa", type: 'input' },
  { label: "A", field: "trasferimentoOrdineA", type: 'input' },
];



// eslint-disable-next-line react-hooks/rules-of-hooks
class RichTrasferimentoOrdine extends React.Component<any, any> {

  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      selectedItem: {},
      history: props.history,
      rowsNames: [],
      itemsPageCount: 10,
      filterAndPaginationData: ""
      //isConcediNullaOsta: false,

    };
  }
  generateRequestFilter = (filters: any) => {
    if (filters.length === 0) return undefined;
    const f = filters.map(f => {
      const v = (typeof f.value === "object" && f.value['value']) ? f.value['value'] : f.value
      return f.field + '::' + 'LIKE' + '::' + v;
    });
    return f;
  }

  async componentDidMount() {
    this.setState({ isLoading: true });


    //this.setState({ isLoading: true });
    setTimeout(() => {
      this.setState({ isLoading: false });
    }, 2000);

    const param = { codiceFiscale: TokenStore.getInstance().getDecodedTokenJWT().fiscalNumber }

    var specificGroup = Object.keys(TokenStore?.getInstance()?.getCurrentAccount()?.accounts)[0]

    getOrdineCompetenteOperatorePOST(specificGroup).then((response)=>{
      this.setState({listaProvinceOrdine: response.data })
    }).catch(
      reason => {
      }
    )

    setTimeout(() => {
      this.setState({ isLoading: false });
    }, 1000);
  }


  loadingFunction = () => {
    this.componentDidMount()
  };

  onSelect = (event) => {
    this.setState({ selectedItem: event.data });

  };
  concediNullaOsta = (event) => {
    //this.setState({isConcediNullaOsta: true})
    //this.props.history = this.state.history;
  }

  rows = async (o: any) => {
    let filtersStr = "";
    let sorts = generateRequestSort(o.sortColumn, o.sortDirection);

    let filters = this.generateRequestFilter(o.filters);
    if (filters)
      filters.map((f, index) => { filtersStr = filtersStr + f + ',' });

    const param = {
      "page": o.currentPage,
      "items": o.pageSize,
      "filters": filtersStr.slice(0, -1),
      "sorts": sorts ? sorts[0] : "",
      "requestObject": {
        "codiceFiscale": TokenStore.getInstance().getDecodedTokenJWT().fiscalNumber,
        "codiceGruppo": Object.keys(TokenStore?.getInstance()?.getCurrentAccount()?.accounts)[0]
      }
    }
    let { data } = await getTrasferimentoOrdineResourceApi().mswfTrasferimentoOrdineFindAllByVisibilityPagedPost(param, getDefaultAxiosConfig())
    let items2 = data.items;
    items2.map((item)=>{
      item.dataTrasferimentoOrdine = moment(item.dataTrasferimentoOrdine).format('DD-MM-YYYY')
    })
    data.items = items2;
    return {
      items: data.items || [],
      total: data.count || 0,
    }
  }

  getAction = (row) => {

    var userODAFHasGestAnagRoles = TokenStore.getInstance().isCurrentAccountODAF() && TokenStore.getInstance().currentAccountHasRolesOR([RO_GEST_UTEN_ODAF])
    var isCurrentAccountODAF = TokenStore.getInstance().isCurrentAccountODAF()

    let out = [{label:'Visualizza', onClick: () => this.props.history.push(PATH_TO_BO_ANAG_TRASF_ORDINE_VISUALIZZA + '?idTrasferimentoOrdine=' + row.idTrasferimentoOrdine), hidden: !isCurrentAccountODAF}];

    if (STATI[row.stato] === "INVIATA" && this.state.listaProvinceOrdine.includes(row.codiceTrasferimentoOrdineDa))
      out.push({ label: 'Concedi Nulla Osta', onClick: () => this.props.history.push(PATH_TO_BO_ANAG_TRASF_ORDINE_CONCEDI_NULLA_OSTA + '?idTrasferimentoOrdine=' + row.idTrasferimentoOrdine + '&idIscrizione=' + row.idIscrizioneOrdineTerritoriale), hidden: !userODAFHasGestAnagRoles })

   //if ((STATI[row.stato] === "VALUTAZIONE" || STATI[row.stato] === "INVIATA") && (this.state.provinceNullaOsta.includes(row.codiceTrasferimentoOrdineDA) || this.state.provinceNullaOsta.includes(row.codiceTrasferimentoOrdineA)))
   if (STATI[row.stato] === "VALUTAZIONE" && this.state.listaProvinceOrdine.includes(row.codiceTrasferimentoOrdineA))
    out.push({ label: 'Rifiuta Trasferimento', onClick: () => this.props.history.push(PATH_TO_BO_ANAG_TRASF_ORDINE_RIFIUTA + '?idTrasferimentoOrdine=' + row.idTrasferimentoOrdine + '&idIscrizione=' + row.idIscrizioneOrdineTerritoriale), hidden: !userODAFHasGestAnagRoles })

  if (STATI[row.stato] === "INVIATA" && this.state.listaProvinceOrdine.includes(row.codiceTrasferimentoOrdineDa))
    out.push({ label: 'Rifiuta Trasferimento', onClick: () => this.props.history.push(PATH_TO_BO_ANAG_TRASF_ORDINE_RIFIUTA + '?idTrasferimentoOrdine=' + row.idTrasferimentoOrdine + '&idIscrizione=' + row.idIscrizioneOrdineTerritoriale), hidden: !userODAFHasGestAnagRoles })

  if (STATI[row.stato] === "VALUTAZIONE" && this.state.listaProvinceOrdine.includes(row.codiceTrasferimentoOrdineA))
    out.push({ label: 'Accetta Trasferimento', onClick: () => this.props.history.push(PATH_TO_BO_ANAG_TRASF_ORDINE_ACCETTA + '?idTrasferimentoOrdine=' + row.idTrasferimentoOrdine + '&idIscrizione=' + row.idIscrizioneOrdineTerritoriale), hidden: !userODAFHasGestAnagRoles })

    return out
  }
  render() {

    const { isLoading, filterAndPaginationData, selectedItem, rowsNames } = this.state;

    return (
      <div className="container">
        <PageSection>
          <GenericBreadCrumb paths={[{ label: "Anagrafica", link: "/" }, { label: "Lista richieste di trasferimento ordine", link: PATH_TO_BO_ANAG_TRASF_ORDINE_RICHIESTA }]} /><br></br>
          <Title headingLevel="h1" size="4xl">Lista richieste di trasferimento ordine </Title><br></br>
          {(isLoading) ?

            (

              <GenericSpinner></GenericSpinner>
            )
            : (
              <div>
                <GenericAsyncTableData

                  columns={columnsNames}
                  // getRows={this.state.rowsNames}

                  getRows={this.rows}
                  filters={filters}
                  //getRowActions={[{ label: "Aggiorna", function: this.loadingFunction }]}
                  getRowActions={this.getAction}
                />
              </div>
            )
          }
        </PageSection>
      </div>
    );
  }
}
export default RichTrasferimentoOrdine;
