import React, {useCallback, useEffect, useRef, useState} from 'react'
import {useHistory, useLocation} from 'react-router-dom'
import {TokenStore} from 'src/keycloak/jwt/TokenStore'
import {Label} from 'design-react-kit'
import GenericBreadCrumb from 'src/frontend/app/components/GenericBreadCrumb'
import GenericTab from 'src/frontend/app/components/GenericTab'

import {createFormData, getTabActiveFormId, handleChangeFormData, validateFormData} from 'src/app/utils/form-utils'
import {getDefaultAxiosConfig, getFormazioneResourceApi} from 'src/app/utils/ManagerRestGateway'
import {requestMapperAgenzia, responseMapperAgenzia} from 'src/frontend/app/pages/iscrizioneAlboEF/form-utils'
import {STATI_AGENZIE_VALUES} from 'src/formazione'

// Form components
import formValidationRules from 'src/frontend/app/pages/iscrizioneAlboEF/formIscrizioneEF/FormRules'
import FormDatiAgenzia from './formIscrizioneEF/FormDatiAgenzia'
import FormDatiSedeLegale from './formIscrizioneEF/FormDatiSedeLegale'
import FormDatiRappresentanteLegale from './formIscrizioneEF/FormDatiRappresentanteLegale'
import FormDatiResponsabileAF from './formIscrizioneEF/FormDatiResponsabileAF'
import FormDatiAccreditamento from './formIscrizioneEF/FormDatiAccreditamento'
import FormDatiDichiarazioni from './formIscrizioneEF/FormDatiDichiarazioni'
import FormDatiFormatori from './formIscrizioneEF/FormDatiFormatori'
import FormDatiAllegati from './formIscrizioneEF/FormDatiAllegati'
import FormSubmit, {STATUS} from 'src/frontend/app/components/Form/GenericSubmit'
import {debounce, PageSection, Title} from '@patternfly/react-core'
import {flagPivaAgeForm} from 'src/hooks/useReqOptions'

const title = 'Iscrizione Agenzia Formativa'
const listNavLink = [
  { id: 1, label: 'Dati Anagrafici' },
  { id: 2, label: 'Sede Legale' },
  { id: 3, label: 'Rappresentante Legale' },
  { id: 4, label: 'Responsabile Attività Formativa' },
  { id: 5, label: 'Dati Accreditamento' },
  { id: 6, label: 'Dati Formatori' },
  { id: 7, label: 'Dichiarazioni' },
  { id: 8, label: 'Allegati' },
  { id: 9, label: 'Conferma' },
]

const breadcrumbOptions = [
  { label: 'Scelta categoria', link: '/' },
  { label: 'Iscrizione Agenzia formativa', link: '/' },
]
const IscrizioneEF: React.FunctionComponent = () => {
  const history = useHistory()
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const id = searchParams.get('id')
  const tab = searchParams.get('tab')

  // Tab component state
  const [activeTab, setActiveTab] = useState(parseInt(tab || '1'))
  const [message, setMessage] = useState('')
  const [typeMessage, setTypeMessage] = useState('')

  // Form State
  const refForm = useRef<any>([])
  const [data, setData] = useState<any>({})
  const [errors, setErrors] = useState<any>({})
  const formData = createFormData(data, setData, errors, setErrors)

  const [idAgenzia, setIdAgenzia] = useState<any>(null)
  const [firstSave, setFirstSave] = useState<any>(false)
  let statoAgenzia: number;


  // Submit state
  const [reqStatus, setReqStatus] = useState<any>()
  const [reqMessage, setReqMessage] = useState<any>()

  // update tab in search params
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search)
    searchParams.set('tab', `${activeTab}`)
    history.replace({ search: searchParams.toString() })
  }, [activeTab])

  // retrieve data from api
  useEffect(() => {
    const retrieveData = async () => {
      const { data } = await getFormazioneResourceApi().msfoFormazioneDettaglioAccAgenziaFormativaGet(id as any, getDefaultAxiosConfig())
      const obj = await responseMapperAgenzia(data)
      setData(obj)
    }

    // before the api retrieve the data from local storage
    const dataString = localStorage.getItem(`${id}`)
    setIdAgenzia(localStorage.getItem(`idAgenzia_${id}`))
    const _data = dataString ? JSON.parse(dataString) : {}
    const keyData = TokenStore.getInstance().getDecodedTokenJWT()
    _data.datiRappresentanteLegale = {
      ...(_data.datiRappresentanteLegale || {}),
      nome: keyData?.given_name || ' ',
      cognome: keyData?.family_name || ' ',
      codiceFiscale: keyData?.fiscalNumber || keyData?.spid_fiscalNumber || ' ',
     // luogoNascita: keyData?.spid_placeOfBirth || ' ',
      dataNascita: keyData?.spid_dateOfBirth || '',
    }

    setData(_data)
    // if (id) retrieveData().catch(console.error)
  }, [id])

const aggiorna = useCallback(debounce(async (_data,idAgenzia,statoAgenzia) =>{
  _data.datiAccreditamento.id = idAgenzia
  _data.datiAccreditamento.stato = statoAgenzia
  await getFormazioneResourceApi().msfoFormazioneAggiornaAgenziaFormativaPost(_data, getDefaultAxiosConfig())
},8000),[])

  // save data in local storage
  useEffect(() => {
    const timeoutId = setTimeout(async () => {
    localStorage.setItem(`${id}`, JSON.stringify(data))
    localStorage.setItem(`idAgenzia_${id}`, idAgenzia)

     const inputs0 = getTabActiveFormId(refForm, 0)
     const isValid0 = validateFormData(formData, formValidationRules, inputs0)
     const inputs1 = getTabActiveFormId(refForm, 1)
     const isValid1 = validateFormData(formData, formValidationRules, inputs1)
     const inputs2 = getTabActiveFormId(refForm, 2)
     const isValid2 = validateFormData(formData, formValidationRules, inputs2)

      if(!isValid0 || !isValid1 || !isValid2 || firstSave)
      {
        return
      }

     const _data = await requestMapperAgenzia(data)
     _data.datiAccreditamento.stato = STATI_AGENZIE_VALUES.IN_COMPILAZIONE
     statoAgenzia = STATI_AGENZIE_VALUES.IN_COMPILAZIONE

     if(idAgenzia !== null)
      {
        aggiorna(_data,idAgenzia,statoAgenzia)
      }
       else{
        setFirstSave(true)
        const result = await getFormazioneResourceApi().msfoFormazioneAccAgenziaFormativaPost(_data, getDefaultAxiosConfig())
        setIdAgenzia(result.data.id)
        setFirstSave(false)
      }

    }, 200)

    return () => {
      clearTimeout(timeoutId)
    }
  }, [id, data])

  const onInitInput = (tab) => (cmp) => {
    refForm.current.push({
      tab: tab,
      cmp: cmp,
    })
  }

  const handleInput = (code, value) => {
    handleChangeFormData(formData, code, value, formValidationRules)
  }

  const onNextStep = useCallback(
    async (e) => {
      e.preventDefault();
      const inputs = getTabActiveFormId(refForm, activeTab - 1);
      const isValid = validateFormData(formData, formValidationRules, inputs);

      if(activeTab === 1){ // solo nella tab di datiAnagrafici controllo piva se già esistente
        try {
            const pIvaExist = await flagPivaAgeForm(formData?.data?.datiAnagrafici.piva);
            setMessage('');

            if (!isValid) {
              window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
              return;
            }

            if (!pIvaExist) {
              setMessage('Partiva Iva già esistente!')
              setTypeMessage('danger')
              window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
              return;
            }

            setActiveTab((tab) => tab + 1);
          } catch (error) {
            console.error("Errore durante l'esecuzione di flagPivaAgeForm:", error);
          }
        }
        else{
          if (!isValid) {
            window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
            return;
          }

          setMessage('');
          setActiveTab((tab) => tab + 1);
        }
    },
    [activeTab, formData]
  )

  const previousStep = () => {
    if (reqStatus === STATUS.LOADING) {
      return
    }
    setReqStatus(null)
    setReqMessage(null)
    setActiveTab((tab) => tab - 1)
  }

  const onSubmit = useCallback(async () => {
    try {
      setReqStatus(STATUS.LOADING)

      const { data } = formData || {}
      const _data = await requestMapperAgenzia(data)
      _data.datiAccreditamento.stato = STATI_AGENZIE_VALUES.ISTRUTTORIA
      _data.datiAccreditamento.id = idAgenzia

      await getFormazioneResourceApi().msfoFormazioneAggiornaAgenziaFormativaPost(_data, getDefaultAxiosConfig())

      localStorage.removeItem(`${id}`)

      setReqStatus(STATUS.SUCCESS)
      setReqMessage('Richiesta inviata con successo')
      setTimeout(() => {
        history.goBack()
      }, 500)
    } catch (e) {
      setReqStatus(STATUS.FAILURE)
      setReqMessage("Errore durante l'invio della richiesta")
    }
    return null
  }, [id, formData])

  return (
    <div className="container register-container">
      <GenericBreadCrumb paths={breadcrumbOptions}></GenericBreadCrumb>
      <div>
        <h1>{title}</h1>
      </div>
      <GenericTab
        hiddenAlert={message === ''}
        labelAlert={message}
        alertTypeMessage={typeMessage}
        isSubmitted={false}
        onSubmit={onNextStep}
        previousStep={previousStep}
        activeTab={activeTab}
        listNavLink={listNavLink}
        listBodyTab={[
          {
            id: 1,
            body: <FormDatiAgenzia title="Dati Anagrafici" data={data} errors={errors} onInitInput={onInitInput(0)} handleInput={handleInput} />,
          },
          {
            id: 2,
            body: <FormDatiSedeLegale title="Sede Legale" data={data} errors={errors} onInitInput={onInitInput(1)} handleInput={handleInput} />,
          },
          {
            id: 3,
            body: (
              <FormDatiRappresentanteLegale title="Rappresentante Legale" data={data} errors={errors} onInitInput={onInitInput(2)} handleInput={handleInput} />
            ),
          },
          {
            id: 4,
            body: (
              <FormDatiResponsabileAF
                title="Responsabile Attività Formativa"
                data={data}
                errors={errors}
                onInitInput={onInitInput(3)}
                handleInput={handleInput}
              />
            ),
          },
          {
            id: 5,
            body: <FormDatiAccreditamento title="Dati Accreditamento" data={data} errors={errors} onInitInput={onInitInput(4)} handleInput={handleInput} />,
          },
          {
            id: 6,
            body: <FormDatiFormatori title="Dati sui formatori" dataRef={data} onInitInput={onInitInput(5)} handleInput={handleInput} viewOnly={false} />,
          },
          {
            id: 7,
            body: <FormDatiDichiarazioni title="Dichiarazioni" data={data} errors={errors} onInitInput={onInitInput(6)} handleInput={handleInput} />,
          },
          {
            id: 8,
            body: <FormDatiAllegati title="Allegati" data={data} errors={errors} onInitInput={onInitInput(7)} handleInput={handleInput} />,
          },
          {
            id: 9,
            body: (
              <FormSubmit title="Invia richiesta" buttonLabel="Invia Richiesta" alertMessage={reqMessage} status={reqStatus} onSubmit={onSubmit}>
                <Label>Per confermare e inviare la domanda cliccare "Invia Richiesta".</Label>
              </FormSubmit>
            ),
          },
        ]}
      />
      <PageSection>
        <div className="container register-container">
          <Title headingLevel="h3">Legenda</Title>
          <p>* Campo obbligatorio</p>
          <p>** Campo non modificabile compilato automaticamente</p>
        </div>
      </PageSection>
    </div>
  )
}

export default IscrizioneEF
