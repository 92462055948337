import * as React from 'react'
import {Form} from 'design-react-kit'
import GenericInput from 'src/frontend/app/components/GenericInput'
import {compilaIscrizioneOEE} from 'src/processes/ProcessoIscrizione'
import {TokenStore} from 'src/keycloak/jwt/TokenStore'
import {getSoggettoByCfAndTipoAnagrafeFO} from 'src/processes/Soggetto'

export class FormIscrizioneOperatoreOEE extends React.Component<GeneralFormIscrizioneOperatoreOEEProps, any> {
  handleSubmit = async (event) => {
    /*--------------------------------------------- Validazione campi obbligatori ------------------------------------------------*/
    var error = { messaggio: 'Compila tutti i campi obbligatori', type: 'danger' }
    if (event.target.idEmailOperatoreOEE.value === '') {
      event.preventDefault()
      return this.props.messaggio(error)
    } else {
      /*------------------------------------------------------------------------------------------------------------------------*/
      event.preventDefault()
      var parametriCompilazioneIscrizioneOEE = {
        emailOperatoreOEE: event.target.idEmailOperatoreOEE.value ? event.target.idEmailOperatoreOEE.value : '',
      }

      const token = TokenStore.getInstance().getDecodedTokenJWT()
      const fiscalNumber = token.spid_fiscalNumber
      const spidData: any = TokenStore.getInstance().getSpidData()

      var cf = spidData.codiceFiscale ? spidData.codiceFiscale : fiscalNumber
      var tipoAnagrafe = 'PF'

      getSoggettoByCfAndTipoAnagrafeFO(cf, tipoAnagrafe)
        .then((response: any) => {
          compilaIscrizioneOEE(response.data.idSoggetto, parametriCompilazioneIscrizioneOEE)
            .then((response) => {
              if (response.status === 200) return this.props.messaggio({ messaggio: 'Iscrizione avvenuta con successo', type: 'success' })
            })
            .catch(() => {
              return this.props.messaggio({ messaggio: "C'è stato un problema nell'invio dei dati", type: 'danger' })
            })
        })
        .catch(() => {
          return this.props.messaggio({ messaggio: "C'è stato durante il recupero dei dati", type: 'danger' })
        })
    }
  }

  render() {
    return (
      <div>
        <Form id={'idIscrizioneOperatoreOEE'} onSubmit={this.handleSubmit}>
          <div className="row">
            <div className="col-6">
              <GenericInput type={'email'} id={'idEmailOperatoreOEE'} placeholder={'Email di contatto'} label={'Email di contatto*'} isRequired />
            </div>
          </div>
        </Form>
      </div>
    )
  }
}

export declare interface GeneralFormIscrizioneOperatoreOEEProps {
  messaggio?: any
}
