import {Card, CardBody, CardHeader, CardTitle, Col, Collapse, Icon, Row} from 'design-react-kit'
import React from 'react'


class GenericPanelAvvisi extends React.Component<GeneralPanelAvvisiProps,any>{
    constructor(props) {
        super(props);
        this.state = {
            cardHeader: props.cardHeader,
            cardBody: props.cardBody,
            id: props.id,
            open: props.open,
        };
    }
    render() {
        const toggle = () => {
            this.setState({open: !this.state.open});
          };

        const { open } = this.state;
        return(
            <Row>
                <Col>
                    {/* Start card */}
                    <Card className='card-bg'>
                        <CardHeader style={{paddingBottom:'20px'}} onClick={toggle} aria-expanded={open}>
                            <CardTitle tag='h5' >
                                <div className="row">
                                    <div style={{width: "100px"}} className="col-1" hidden={open}>
                                        <Icon color="primary" onClick={()=>toggle()} size='sm' icon={'it-expand'} />
                                    </div>

                                    <div style={{width: "100px"}} className="col-1" hidden={!open}>
                                        <Icon color="primary" onClick={toggle} size='sm' icon={'it-collapse'} />
                                    </div>
                                    <div className="col-8">
                                        {this.props.cardHeader}
                                    </div>
                                </div>
                            </CardTitle>
                        </CardHeader>

                        <Collapse isOpen={this.state.open}>
                            <CardBody style={{marginTop:'20px'}}>
                                <div className="row">

                                        <div className="col">
                                            {this.props.cardBody}
                                        </div>
                                </div>

                            </CardBody>

                        </Collapse>
                    </Card>
                </Col>
            </Row>

        );
    }
}

export declare interface GeneralPanelAvvisiProps {
    id?: any;
    cardHeader?: any;
    cardBody?: any;
    open?: boolean;
}

export default GenericPanelAvvisi;
