import {Breadcrumb, BreadcrumbItem, Nav} from 'design-react-kit'
import React from 'react'

class GenericBreadCrumb extends React.Component<GeneralBredcrumbProps, any> {
    constructor(props) {
        super(props);
        this.state = {
            paths: props.paths
        };
    }

    render() {
        const {paths} = this.props;
        return (
            <div>
                <Nav className="breadcrumb-container" aria-label="Percorso di navigazione">
                    <Breadcrumb className="breadcrumb" id={this.props.id}>
                        <>
                            {paths.map((path, id) => {
                                return(
                                    <BreadcrumbItem key={id} active={path.link === window.location.pathname} className="breadcrumb-item"><a href={path.link}>{path.label}</a><span className="separator">{path.label !== "" ? "/" : ""}</span></BreadcrumbItem>
                                )
                            })}
                        </>
                    </Breadcrumb>
                </Nav>
            </div>
        );
    }
}

export declare interface GeneralBredcrumbProps {
    paths?: any;
    id?: any;
  }

export default GenericBreadCrumb;
