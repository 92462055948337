import React, {useCallback, useRef, useState} from 'react'
import {Alert, Button, Form, Label, Modal, ModalBody, ModalFooter, ModalHeader} from 'design-react-kit'
import styled from 'styled-components'

import FormSubmit, {STATUS} from '../../../../../frontend/app/components/Form/GenericSubmit'
import {getDefaultAxiosConfig, getFormazioneResourceApi} from 'src/app/utils/ManagerRestGateway'
import GenericInput from 'src/frontend/app/components/Form/Input'
import {createFormData, getTabActiveFormId, handleChangeFormData, validateFormData} from 'src/app/utils/form-utils'
import {getSdaf, getTipologieAttivita} from 'src/hooks/useReqOptions'
import moment from 'moment'
import {TokenStore} from 'src/keycloak/jwt/TokenStore'
import {getDenominazioneByCodiceGruppo} from 'src/processes/SelezioneProfilo'
import GenericModal from 'src/frontend/app/components/GenericModal'

const FormSubmitStyled = styled.div`
  .card {
    padding: 0;
    .card-body {
      padding: 20px 20px 0 !important;
    }
  }
`
const AssegnaCFP: React.FunctionComponent<AssegnaCFP> = ({ iscritti, errori, evento, onClose, maxCFPCorso, cfpMassiva }) => {
  const [status, setStatus] = useState<any>([])
  const [alertMessage, setAlertMessage] = useState<any>([])

  const [isCfpMassiva, setIsCfpMassiva] = useState<any>(cfpMassiva !== undefined ? cfpMassiva : false)

  const [modalOpen, setModalOpen] = useState<any>(false)

  // Form State
  const refForm = useRef<any>([])
  const [data, setData] = useState<any>({ iscritti })
  const [errors, setErrors] = useState<any>({})
  const formData = createFormData(data, setData, errors, setErrors)
  const rules = {
    regex: [
      {
        regex: /iscritti\.[0-9]+\.cfp/,
        rules: {
          isRequired: true,
          isNumber: true,
        },
      },
    ],
  }

  const onInitInput = (index) => (cmp) => {
    refForm.current.push({
      tab: index,
      cmp: cmp,
    })
  }

  const handleInput = (code, value) => {
    // Rimuovi tutti i caratteri non numerici dalla stringa di input e converte la virgola in punto
    const numericValue = value.replace(/[^0-9,.]/g, '').replace(',', '.')

    // Limita a massimo tre cifre decimali
    const formattedValue = numericValue.replace(/^(\d*\.\d{0,3})\d*$/, '$1')

    handleChangeFormData(formData, code, formattedValue, rules)
  }

  const confermaTutti = useCallback(async () => {
    setModalOpen(false)
    setIsCfpMassiva(false)
    const operatoreCF = TokenStore.getInstance().getSpidData().codiceFiscale // codice fiscale operatore che esegue assegnazione crediti

    const specificGroup = Object.keys(TokenStore?.getInstance()?.getCurrentAccount()?.accounts)[0]
    const specificGroupId = TokenStore.getInstance().getCurrentAccount().accounts[specificGroup].specificGroup

    var denominazioneAgenzia = await getDenominazioneByCodiceGruppo(specificGroupId).then(async (response) => {
      if (response.data && response.status !== 404) {
        var denominazione = response.data.returnedObject
        if (
          denominazione !== undefined &&
          denominazione[0] !== undefined &&
          (denominazione[0].startsWith('Operatore STP') ||
            denominazione[0].startsWith('Operatore formazione') ||
            denominazione[0].startsWith('Operatore rivista') ||
            denominazione[0].startsWith('Formatore') ||
            denominazione[0].startsWith('Operatore ente esterno'))
        ) {
          denominazione = denominazione[0]
        }
        return denominazione
      }
    }) //restituisce la denominazione dell'azienda dell'operatore che sta assegnando i cfp

    const _status = [...status]
    const _alertMessage = [...alertMessage]
    const tipologieAttivita = await getTipologieAttivita()
    const tipologieMap = {}
    const settoriMap = {}
    for (const tipologia of tipologieAttivita) {
      tipologieMap[tipologia.id] = tipologia
    }

    for (let index = 0; index <= data?.iscritti.length; index++) {
      const iscritto = formData?.data?.iscritti[index]
      const inputs = getTabActiveFormId(refForm, index)
      const valid = validateFormData(formData, rules, inputs)

      // faccio ciclare per un elemento in piu in modo da poter caricare lo status dell'ultimo record. poi esco dal for
      if (iscritto === undefined) break

      const anno = evento?.data_fine === undefined || evento?.data_fine === null ? moment().get('year') : moment(evento.data_fine).get('year')

      let cfp_riconosciuti = parseFloat(iscritto.cfp)

      const tipologia = tipologieMap[parseInt(evento.tipo_corso)]
      const maxCFP = tipologia.max_cfp_evt
      const max_CFP_anno = tipologia.max_cfp_anno
      const triennioRifMovimento = anno <= 2013 ? Math.floor(anno / 3) : Math.floor((anno - 1) / 3)

      if (cfp_riconosciuti > maxCFPCorso) {
        cfp_riconosciuti = maxCFPCorso
      }

      try {
        if (!valid) {
          window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
          return
        }
        _status[index] = STATUS.LOADING
        setStatus(_status)

        let cf_iscritto
        if (iscritto.user_name) cf_iscritto = iscritto.user_name.toUpperCase()
        else cf_iscritto = iscritto.userName.toUpperCase()

        const result = await getFormazioneResourceApi().msfoFormazioneRiepilogoCreditiFormativiGet(cf_iscritto, getDefaultAxiosConfig())

        const listaSDAF = await getSdaf()

        const dataRes = result.data as any

        for (const SDAF of listaSDAF) {
          settoriMap[SDAF.id] = SDAF
        }

        const cfu_by_tipologia = {}

        const triennioRiferimento = Math.floor((anno - 1) / 3)

        const creditiACatalogoPerAnnoPerTipologia = {}

        let cfp_validi = 0

        for (const ogg of dataRes) {
          if (ogg.movimento && ogg.evento) {
            const annoMovimento = ogg.movimento.anno_calc_cfp
            const tipologia = tipologieMap[parseInt(ogg.evento.tipo_corso)]
            const crediti = ogg.movimento.crediti
            const isRiconosciuto = ogg.movimento.stato === 2
            const triennioRifMovimento = Math.floor((annoMovimento - 1) / 3)

            if (triennioRiferimento === triennioRifMovimento) {
              if (!cfu_by_tipologia[triennioRifMovimento]) {
                cfu_by_tipologia[triennioRifMovimento] = {}
              }

              if (!cfu_by_tipologia[triennioRifMovimento][tipologia.id]) {
                cfu_by_tipologia[triennioRifMovimento][tipologia.id] = 0
              }

              if (!creditiACatalogoPerAnnoPerTipologia[annoMovimento]) {
                creditiACatalogoPerAnnoPerTipologia[annoMovimento] = {}
              }

              if (!creditiACatalogoPerAnnoPerTipologia[annoMovimento][tipologia.id]) {
                creditiACatalogoPerAnnoPerTipologia[annoMovimento][tipologia.id] = 0
              }

              const cfp_riconosciuti = isRiconosciuto ? crediti : 0

              creditiACatalogoPerAnnoPerTipologia[annoMovimento][tipologia.id] += cfp_riconosciuti
            }
          }
        }

        const value =
          cfu_by_tipologia && cfu_by_tipologia[triennioRifMovimento] && cfu_by_tipologia[triennioRifMovimento][tipologia.id]
            ? cfu_by_tipologia[triennioRifMovimento][tipologia.id]
            : 0
        if (cfp_riconosciuti + value > maxCFPCorso) {
          cfp_validi = Math.max(maxCFPCorso - value, 0)
        } else {
          cfp_validi = cfp_riconosciuti
        }

        const valueanno =
          creditiACatalogoPerAnnoPerTipologia && creditiACatalogoPerAnnoPerTipologia[anno] && creditiACatalogoPerAnnoPerTipologia[anno][tipologia.id]
            ? creditiACatalogoPerAnnoPerTipologia[anno][tipologia.id]
            : 0

        if (cfp_validi + valueanno > max_CFP_anno) {
          cfp_validi = Math.max(max_CFP_anno - valueanno, 0)
        }
        const obj: any = {
          user_name: iscritto?.user_name,
          ref: evento?.idcorso,
          crediti: iscritto?.cfp,
          stato: 0,
          chi: operatoreCF + ' - ' + denominazioneAgenzia,
          crediti_validi: cfp_validi,
          anno_calc_cfp: anno,
        }

        let resultAssegnazione: any = await getFormazioneResourceApi().msfoFormazioneAssegnaCfpPut(obj, getDefaultAxiosConfig())
        if (resultAssegnazione.data.httpStatus === 200) {
          _status[index] = STATUS.SUCCESS
          _alertMessage[index] = 'Dati salvati con successo'
          setStatus(_status)
          setAlertMessage(_alertMessage)
        } else {
          _status[index] = STATUS.FAILURE
          _alertMessage[index] = 'Impossibile salvare i dati, assegnazione crediti non riuscita'
          setStatus(_status)
          setAlertMessage(_alertMessage)
        }
      } catch (e) {
        _status[index] = STATUS.FAILURE
        _alertMessage[index] = 'Impossibile salvare i dati'
        setStatus(_status)
        setAlertMessage(_alertMessage)
      }
    } // chiusura for
  }, [formData, status, alertMessage, evento])

  const submit = useCallback(
    async (index) => {
      const _status = [...status]
      const _alertMessage = [...alertMessage]
      const tipologieAttivita = await getTipologieAttivita()

      const tipologieMap = {}
      const settoriMap = {}

      for (const tipologia of tipologieAttivita) {
        tipologieMap[tipologia.id] = tipologia
      }
      const iscritto = formData?.data?.iscritti[index]

      const anno = evento?.data_fine === undefined || evento?.data_fine === null ? moment().get('year') : moment(evento.data_inizio).get('year')

      const cfp_riconosciuti = parseFloat(iscritto.cfp)

      const tipologia = tipologieMap[parseInt(evento.tipo_corso)]
      const maxCFP = tipologia.max_cfp_evt
      const max_CFP_anno = tipologia.max_cfp_anno
      const triennioRifMovimento = anno <= 2013 ? Math.floor(anno / 3) : Math.floor((anno - 1) / 3)

      if (cfp_riconosciuti <= maxCFPCorso) {
        try {
          // TODO EVENTO CATALOGO API check
          const inputs = getTabActiveFormId(refForm, index)
          const valid = validateFormData(formData, rules, inputs)

          if (!valid) {
            window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
            return
          }
          _status[index] = STATUS.LOADING
          setStatus(_status)

          let cf_iscritto
          if (iscritto.user_name) cf_iscritto = iscritto.user_name.toUpperCase()
          else cf_iscritto = iscritto.userName.toUpperCase()

          const result = await getFormazioneResourceApi().msfoFormazioneRiepilogoCreditiFormativiGet(cf_iscritto, getDefaultAxiosConfig())
          const listaSDAF = await getSdaf()

          const dataRes = result.data as any

          for (const SDAF of listaSDAF) {
            settoriMap[SDAF.id] = SDAF
          }

          const cfu_by_tipologia = {}

          const triennioRiferimento = Math.floor((anno - 1) / 3)

          const creditiACatalogoPerAnnoPerTipologia = {}

          let cfp_validi = 0

          for (const ogg of dataRes) {
            if (ogg.movimento && ogg.evento) {
              const annoMovimento = ogg.movimento.anno_calc_cfp
              const tipologia = tipologieMap[parseInt(ogg.evento.tipo_corso)]
              const crediti = ogg.movimento.crediti
              const isRiconosciuto = ogg.movimento.stato === 2
              const triennioRifMovimento = Math.floor((annoMovimento - 1) / 3)

              if (triennioRiferimento === triennioRifMovimento) {
                if (!cfu_by_tipologia[triennioRifMovimento]) {
                  cfu_by_tipologia[triennioRifMovimento] = {}
                }

                if (!cfu_by_tipologia[triennioRifMovimento][tipologia.id]) {
                  cfu_by_tipologia[triennioRifMovimento][tipologia.id] = 0
                }

                if (!creditiACatalogoPerAnnoPerTipologia[annoMovimento]) {
                  creditiACatalogoPerAnnoPerTipologia[annoMovimento] = {}
                }

                if (!creditiACatalogoPerAnnoPerTipologia[annoMovimento][tipologia.id]) {
                  creditiACatalogoPerAnnoPerTipologia[annoMovimento][tipologia.id] = 0
                }

                const cfp_riconosciuti = isRiconosciuto ? crediti : 0

                creditiACatalogoPerAnnoPerTipologia[annoMovimento][tipologia.id] += cfp_riconosciuti
              }
            }
          }

          const value =
            cfu_by_tipologia && cfu_by_tipologia[triennioRifMovimento] && cfu_by_tipologia[triennioRifMovimento][tipologia.id]
              ? cfu_by_tipologia[triennioRifMovimento][tipologia.id]
              : 0
          if (cfp_riconosciuti + value > maxCFPCorso) {
            cfp_validi = Math.max(maxCFPCorso - value, 0)
          } else {
            cfp_validi = cfp_riconosciuti
          }

          const valueanno =
            creditiACatalogoPerAnnoPerTipologia && creditiACatalogoPerAnnoPerTipologia[anno] && creditiACatalogoPerAnnoPerTipologia[anno][tipologia.id]
              ? creditiACatalogoPerAnnoPerTipologia[anno][tipologia.id]
              : 0

          if (cfp_validi + valueanno > max_CFP_anno) {
            cfp_validi = Math.max(max_CFP_anno - valueanno, 0)
          }

          const operatoreCF = TokenStore.getInstance().getSpidData().codiceFiscale // codice fiscale operatore che esegue assegnazione crediti

          const specificGroup = Object.keys(TokenStore?.getInstance()?.getCurrentAccount()?.accounts)[0]
          const specificGroupId = TokenStore.getInstance().getCurrentAccount().accounts[specificGroup].specificGroup

          var denominazioneAgenzia = await getDenominazioneByCodiceGruppo(specificGroupId).then(async (response) => {
            if (response.data && response.status !== 404) {
              var denominazione = response.data.returnedObject
              if (
                denominazione !== undefined &&
                denominazione[0] !== undefined &&
                (denominazione[0].startsWith('Operatore STP') ||
                  denominazione[0].startsWith('Operatore formazione') ||
                  denominazione[0].startsWith('Operatore rivista') ||
                  denominazione[0].startsWith('Formatore') ||
                  denominazione[0].startsWith('Operatore ente esterno'))
              ) {
                denominazione = denominazione[0]
              }
              return denominazione
            }
          }) //restituisce la denominazione dell'azienda dell'operatore che sta assegnando i cfp

          const obj: any = {
            user_name: iscritto?.user_name,
            ref: evento?.idcorso,
            crediti: iscritto?.cfp,
            stato: 0,
            chi: operatoreCF + ' - ' + denominazioneAgenzia,
            crediti_validi: cfp_validi,
            anno_calc_cfp: anno,
          }
          let resultAssegnazione: any = await getFormazioneResourceApi().msfoFormazioneAssegnaCfpPut(obj, getDefaultAxiosConfig())
          if (resultAssegnazione.data.httpStatus === 200) {
            _status[index] = STATUS.SUCCESS
            _alertMessage[index] = 'Dati salvati con successo'
            setStatus(_status)
            setAlertMessage(_alertMessage)
          } else {
            _status[index] = STATUS.FAILURE
            _alertMessage[index] = 'Impossibile salvare i dati, assegnazione crediti non riuscita'
            setStatus(_status)
            setAlertMessage(_alertMessage)
          }
        } catch (e) {
          _status[index] = STATUS.FAILURE
          _alertMessage[index] = 'Impossibile salvare i dati'
          setStatus(_status)
          setAlertMessage(_alertMessage)
        }
      } else {
        _status[index] = STATUS.FAILURE
        _alertMessage[index] = `I CFP riconosciuti superano il limite massimo (${maxCFPCorso})`
        setStatus(_status)
        setAlertMessage(_alertMessage)
      }
    },
    [formData, status, alertMessage, evento]
  )

  return (
    <>
      {' '}
      <Modal fade centered scrollable isOpen={!!iscritti} toggle={onClose} size="lg">
        <ModalHeader id="assegnaCFU">Assegna crediti</ModalHeader>
        <ModalBody>
          {errori?.map((err, index) => {
            return (
              <Alert key={index} color="danger">
                {err?.message}
              </Alert>
            )
          })}
          {data?.iscritti?.map((iscritto, index) => {
            const id = `iscritti.${index}.cfp`
            return (
              <FormSubmitStyled key={index}>
                <FormSubmit
                  title={`${iscritto.nome} ${iscritto.cognome}`}
                  buttonLabel="CONFERMA"
                  alertMessage={alertMessage[index]}
                  status={status[index]}
                  onSubmit={() => submit(index)}
                >
                  <Form onSubmit={(e) => e.preventDefault()}>
                    {/* disabilita la possibilita di confermare il modulo premendo il tasto invio */}
                    <GenericInput
                      id={id}
                      //   type={'number'}
                      type={'text'}
                      placeholder={'Crediti'}
                      label={'Crediti *'}
                      value={iscritto?.cfp}
                      errorMessage={errors?.[id]}
                      onInit={onInitInput(index)}
                      onChange={handleInput}
                    />
                    <div className="mb-3">
                      <Label>
                        Stai attribuendo CFP a n. <b>{iscritto?.barcode}</b> per l'evento <b>{evento?.nome_corso}</b> (CFP Riconosciuti dall'evento:{' '}
                        {maxCFPCorso})
                      </Label>
                    </div>
                  </Form>
                </FormSubmit>
              </FormSubmitStyled>
            )
          })}
        </ModalBody>
        <ModalFooter>
          <Button hidden={!isCfpMassiva} color="primary" onClick={() => setModalOpen(true)}>
            Conferma tutti
          </Button>
          <Button color="secondary" onClick={onClose}>
            Chiudi
          </Button>
        </ModalFooter>
      </Modal>
      <GenericModal
        centered
        title={'Conferma Assegnazione CFP massiva'}
        text={'Sei sicuro di voler confermare?'}
        modalOpen={modalOpen}
        closeButton={() => setModalOpen(false)}
        makeChoice={true}
        choiceAction={confermaTutti}
      />
    </>
  )
}

interface AssegnaCFP {
  iscritti: any
  errori: any
  evento: any
  onClose: () => void
  maxCFPCorso: number
  cfpMassiva?: any
}

export default AssegnaCFP
