import {getAnagrafeResourcesApi, getDefaultAxiosConfig} from 'src/app/utils/ManagerRestGateway'

/**
 * GET dei motivi di cancellazione
 * @returns
 */
export async function getMotiviCancellazioneIscrittoBO() {
    try {
        const response = await getAnagrafeResourcesApi().msgaAnagrafeBoListaMotiviCancellazioneGet(getDefaultAxiosConfig());
        //Manca il ResponseBuilder su Backend
        return response;
    } catch (err: any) {
        //TODO - Gestire meglio l'errore
        // let errorMessage : any = ErrorStore.getInstance().returnMessageError(err.message, 500)
        console.error("Errore durante il recupero della lista dei motivi di cancellazione" + err)
        throw await new Error("Errore durante il recupero della lista dei motivi di cancellazione" + err);
    }
}
