import * as React from 'react'
import '../../../../backoffice/style/style.scss'
import { PageSection, Title } from '@patternfly/react-core'
import GenericBreadCrumb from 'src/frontend/app/components/GenericBreadCrumb'
import GenericWizard from '../../components/GenericWizard'
import { FormDatiInformativi } from './formPolizze/FormDatiInformativi'
import { FormTabellaUno } from './formPolizze/FormTabellaUno'
import { FormDatiPolizzaIndividuale } from './formPolizze/FormDatiPolizzaIndividuale'
import { FormDatiPagamento } from './formPolizze/FormDatiPagamento'
import { getPolizzaById, getPolizzaStoricaById, getStorico } from 'src/processes/assicurazioni/Assicurazione'
import GenericSpinner from '../../components/GenericSpinner'
import { recuperoTabellaUnoByPolizza, recuperoTabellaUnoDefinitivaAssociataAllaPolizza } from 'src/processes/assicurazioni/TabellaUno'
import { FormRiepilogo } from './formPolizze/FormRiepilogo'
import { TokenStore } from 'src/keycloak/jwt/TokenStore'
import { PATH_TO_BO_POLIZZE_ELENCO, PATH_TO_BO_POLIZZE_ELENCO_CONFERMA, PATH_TO_BO_POLIZZE_ELENCO_RIGETTA, RO_GEST_POLI } from 'src/app/utils/RoutesConstants'
import { getSoggettoByIdIscrizioneBO } from 'src/processes/Soggetto'
import { AppUtils as U } from 'src/app/utils/FormUtils'
import { linkTabCollettivaVisualizzazione, linkTabIndividualeVisualizzazione } from 'src/processes/assicurazioni/CostantiPolizze'
import GenericChips from 'src/frontend/app/components/GenericChips'
import { Nav, NavItem, NavLink } from 'design-react-kit'
import GenericAlert from 'src/frontend/app/components/GenericAlert'
import { GenericButton, GenericTextInput } from '../../components'
import { AssicurazioneUtils } from 'src/processes/assicurazioni/AssicurazioneUtils'
import GenericAccordionCard from 'src/frontend/app/components/GenericAccordionCard'
import { isCurrentMobileDevice } from 'src/utilities/deviceUtility'

let listaStorico: any = []

class VisualizzaPolizza extends React.Component<any, any> {
  constructor(props) {
    super(props)
    this.state = {
      message: '',
      typeMessage: 'default',
      idPolizza: '',
      nome: '',
      cognome: '',
      codiceStudioAssociato: '',
      polizza: {},
      data: {},
      mySelection: [],
      tabellaUno: {},
      activeTab: 1,
      isLoaded: false,
      nessunaTabellaUnoFlag: false,
      utenteSTP: false,
    }
  }

  showFile(blob) {
    // It is necessary to create a new blob object with mime-type explicitly set
    // otherwise only Chrome works like it should
    var newBlob = new Blob([blob], { type: 'application/pdf' })

    // For other browsers:
    // Create a link pointing to the ObjectURL containing the blob.
    const data = window.URL.createObjectURL(newBlob)
    var link = document.createElement('a')
    link.href = data
    link.download = 'file.pdf'
    link.click()
    setTimeout(function () {
      // For Firefox it is necessary to delay revoking the ObjectURL
      window.URL.revokeObjectURL(data)
    }, 100)
  }

  async componentDidMount(): Promise<void> {
    this.setState({ selectedRow: this.props.history.location.state.row })
    var idPolizza = this.props.history.location.state.idPolizza
    this.setState({ idPolizza: idPolizza })
    if (idPolizza !== undefined) {
      await getPolizzaById(idPolizza)
        .then(async (response: any) => {
          await getSoggettoByIdIscrizioneBO(response.idIscrizione)
            .then(async (responseSoggetto: any) => {
              //casting da numerale a dropdown

              response.coperturaPostuma = U.dn(response.coperturaPostuma)
              response.danniPatrimoniali = U.dn(response.danniPatrimoniali)
              response.retroattivitaIllimitata = U.dn(response.retroattivitaIllimitata)
              response.operaStudioAssociato = U.dn(response.operaStudioAssociato)
              response.altrePolizze = U.dn(response.altrePolizze)
              response.maternita = U.dn(response.maternita)
              response.paternita = U.dn(response.paternita)
              response.sinistri = U.dn(response.sinistri)
              response.sinistriRicevutoLiberatoriaCompagnia = U.dn(response.sinistriRicevutoLiberatoriaCompagnia)
              response.idCategoriaDue = { label: response.categoria === 2 ? 'Si' : 'No', value: response.categoria === 2 ? 'Si' : 'No' }
              response.privacy = response.privacy === 1 ? true : false
              response.haLettoCircostanza = response.haLettoCircostanza === 1 ? true : false
              response.periodoValDaConaf = U.data_normalize(response.periodoValDaConaf)
              response.periodoValAConaf = U.data_normalize(response.periodoValAConaf)
              response.periodoValDaAltri = U.data_normalize(response.periodoValDaAltri)
              response.periodoValAAltri = U.data_normalize(response.periodoValAAltri)

              if (response.associatoConConaf === 1) this.setState({ tipologiaPolizza: 'Collettiva' })
              else this.setState({ tipologiaPolizza: 'Individuale' })

              this.setState({ polizza: response })

              //Inizializzazione dello storico (la polizza originaria)
              let obj: any = {
                id: response.id,
                dataCreazioneRichiesta: response.dataCreazioneRichiesta ? response.dataCreazioneRichiesta : response.dataRichiesta,
                stato: response.stato,
              }
              await listaStorico.push(obj)

              await this.setState({
                originalPolizza: response.id,
                selectedPolizza: { id: response.id, dataCreazioneRichiesta: response.dataCreazioneRichiesta },
              })

              var periodoValDa
              var periodoValA
              var name = ''
              var lastname = ''
              var cf = ''
              var partitaIva = ''
              var ragioneSociale = ''
              if (responseSoggetto.data.tipoAnagrafe === 'PF') {
                name = responseSoggetto.data.nome
                lastname = responseSoggetto.data.cognome
                cf = responseSoggetto.data.cf
                this.setState({ utenteSTP: false })
              } else {
                ragioneSociale = responseSoggetto.data.ragioneSociale
                partitaIva = responseSoggetto.data.partitaIva
                cf = responseSoggetto.data.codiceFiscaleResponsabileStp
                this.setState({ utenteSTP: true })
              }
              if (response.associatoConConaf === 1) {
                periodoValDa = response.periodoValDaConaf
                periodoValA = response.periodoValAConaf
              } else {
                periodoValDa = response.periodoValDaAltri
                periodoValA = response.periodoValAAltri
              }
              await this.setState({ periodoValDa: periodoValDa, periodoValA: periodoValA, name: name, lastname: lastname })

              this.setState({
                user: {
                  nome: name,
                  cognome: lastname,
                  cf: cf,
                  partitaIva: partitaIva,
                  ragioneSociale: ragioneSociale,
                },
              })
              await recuperoTabellaUnoDefinitivaAssociataAllaPolizza(idPolizza)
                .then(async (responseTabella: any) => {
                  if (
                    responseTabella === '' ||
                    responseTabella === null ||
                    responseTabella === undefined ||
                    (responseTabella && responseTabella.status === 204)
                  ) {
                    this.setState({ nessunaTabellaUnoFlag: true, isLoaded: true })
                  } else {
                    this.setState({ tabellaUno: responseTabella })
                    if (response.associatoConConaf === 1) {
                      this.setState({ polizzaIndividuale: false, polizzaCollettiva: true })
                      this.setState({ polizza: response, isLoaded: true })
                    } else if (response.associatoConAltri === 1) {
                      this.setState({ polizzaIndividuale: true, polizzaCollettiva: false })
                      this.setState({ polizza: response, isLoaded: true })
                    }
                  }
                  await getStorico(this.props.location.state.idPolizza)
                    .then(async (responseStorico) => {
                      if (responseStorico.length > 0) {
                        await this.setState({ isLoaded: false })
                        responseStorico.forEach((item) => {
                          let obj: any = {
                            id: item.id,
                            dataCreazioneRichiesta: item.dataCreazioneRichiesta,
                            stato: item.stato,
                          }
                          listaStorico.push(obj)
                        })

                        await this.setState({ polizza: response, isLoaded: true })
                      }
                    })
                    .catch((err) => {
                      this.setState({ message: err.message, typeMessage: 'danger' })
                    })
                })
                .catch((err) => {
                  this.setState({ message: err.message, typeMessage: 'danger' })

                  //throw new Error("Errore durante la get degli iscritti all'ordine " + err);
                })
            })
            .catch((err) => {
              this.setState({ message: err.message, typeMessage: 'danger' })
            })
        })
        .catch((err) => {
          this.setState({ message: err.message, typeMessage: 'danger' })
        })
    } else {
      window.location.href = PATH_TO_BO_POLIZZE_ELENCO
    }
  }

  //Procedi allo step successivo
  nextStep = async () => {
    if (this.state.activeTab === 1) this.setState({ polizza: this.state.polizza })
    this.state.polizza.codiceFiscale = TokenStore.getInstance().getDecodedTokenJWT().fiscalNumber
    this.setState({ polizzaCollettiva: true, polizza: this.state.polizza, data: this.state.polizza })
    let linkTab = linkTabCollettivaVisualizzazione
    if (this.state.polizza.associatoConAltri === 1) {
      this.setState({ polizzaCollettiva: false })
      linkTab = linkTabIndividualeVisualizzazione
    }
    if (this.state.activeTab + 1 <= linkTab.length) {
      if (this.state.activeTab === 4) {
        if (this.state.polizza.assicurazioneAllegati.length !== 0) {
          this.setState({ activeTab: this.state.activeTab + 1 })
        }
        this.state.polizza.assicurazioneAllegati.forEach((itemAllegato) => {
          this.setState({ itemAllegato: itemAllegato })
        })

        if (this.props.datiAllegato) {
          this.setState({ copiaPagamentoPolizza: this.state.fields.copiaPagamentoPolizza })
        }
      }
      this.setState({ activeTab: this.state.activeTab + 1 })
    }
  }

  //Procedi allo step precedente
  previousStep = async () => {
    await this.setState({ activeTab: this.state.activeTab - 1 })
  }

  //Sincronizzazione di tutti i valori
  getSelectValues = async (fields: any) => {
    await this.setState({ fields: fields, data: fields })
  }

  onSubmit = async (event) => {
    event.preventDefault()
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
    this.nextStep()
    this.setState({ message: '' })
  }

  setSelectedPolizza = async (polizzaId) => {
    let polizza = this.state.polizza

    await getPolizzaStoricaById(polizzaId)
      .then(async (response: any) => {
        polizza = response

        if (response === '' || response === null) {
          await getPolizzaById(polizzaId)
            .then(async (responsePolizza: any) => {
              await this.setState({
                originalPolizza: responsePolizza.id,
                selectedPolizza: { id: responsePolizza.id, dataCreazioneRichiesta: responsePolizza.dataCreazioneRichiesta },
                isLoaded: false,
                storicoFlag: false,
              })

              polizza = responsePolizza
              //casting da numerale a dropdown
              polizza.coperturaPostuma = U.dn(polizza.coperturaPostuma)
              polizza.danniPatrimoniali = U.dn(polizza.danniPatrimoniali)
              polizza.retroattivitaIllimitata = U.dn(polizza.retroattivitaIllimitata)
              polizza.operaStudioAssociato = U.dn(polizza.operaStudioAssociato)
              polizza.altrePolizze = U.dn(polizza.altrePolizze)
              polizza.maternita = U.dn(polizza.maternita)
              polizza.paternita = U.dn(polizza.paternita)
              polizza.sinistri = U.dn(polizza.sinistri)
              polizza.sinistriRicevutoLiberatoriaCompagnia = U.dn(polizza.sinistriRicevutoLiberatoriaCompagnia)
              polizza.idCategoriaDue = { label: polizza.categoria === 2 ? 'Si' : 'No', value: polizza.categoria === 2 ? 'Si' : 'No' }
              polizza.privacy = polizza.privacy === 1 ? true : false
              polizza.haLettoCircostanza = polizza.haLettoCircostanza === 1 ? true : false
              polizza.periodoValDaConaf = U.data_normalize(polizza.periodoValDaConaf)
              polizza.periodoValAConaf = U.data_normalize(polizza.periodoValAConaf)
              polizza.periodoValDaAltri = U.data_normalize(polizza.periodoValDaAltri)
              polizza.periodoValAAltri = U.data_normalize(polizza.periodoValAAltri)

              await recuperoTabellaUnoDefinitivaAssociataAllaPolizza(polizzaId)
                .then(async (responseTabella) => {
                  if (
                    responseTabella === '' ||
                    responseTabella === null ||
                    responseTabella === undefined ||
                    (responseTabella && responseTabella.status === 204)
                  ) {
                    this.setState({ nessunaTabellaUnoFlag: true, isLoaded: true, activeTab: 1 })
                  } else {
                    this.setState({ tabellaUno: responseTabella })
                    if (polizza.associatoConConaf === 1) this.setState({ polizzaIndividuale: false, polizzaCollettiva: true })
                    if (polizza.associatoConAltri === 1) this.setState({ polizzaIndividuale: true, polizzaCollettiva: false })
                    await this.setState({ polizza: polizza, isLoaded: true, activeTab: 1 })
                  }
                })
                .catch((err) => {
                  this.setState({ message: err.message, typeMessage: 'danger' })
                  // throw new Error("Errore durante il caricamento dei dati " + err);
                })
            })
            .catch((err) => {
              this.setState({ message: err.message, typeMessage: 'danger' })
            })
        } else {
          polizza = response
          //casting da numerale a dropdown
          polizza.coperturaPostuma = U.dn(polizza.coperturaPostuma)
          polizza.danniPatrimoniali = U.dn(polizza.danniPatrimoniali)
          polizza.retroattivitaIllimitata = U.dn(polizza.retroattivitaIllimitata)
          polizza.operaStudioAssociato = U.dn(polizza.operaStudioAssociato)
          polizza.altrePolizze = U.dn(polizza.altrePolizze)
          polizza.maternita = U.dn(polizza.maternita)
          polizza.paternita = U.dn(polizza.paternita)
          polizza.sinistri = U.dn(polizza.sinistri)
          polizza.sinistriRicevutoLiberatoriaCompagnia = U.dn(polizza.sinistriRicevutoLiberatoriaCompagnia)
          polizza.idCategoriaDue = { label: response.categoria === 2 ? 'Si' : 'No', value: response.categoria === 2 ? 'Si' : 'No' }
          polizza.privacy = response.privacy === 1 ? true : false
          polizza.haLettoCircostanza = response.haLettoCircostanza === 1 ? true : false

          polizza.periodoValDaConaf = U.data_normalize(response.periodoValDaConaf)
          polizza.periodoValAConaf = U.data_normalize(response.periodoValAConaf)
          polizza.periodoValDaAltri = U.data_normalize(response.periodoValDaAltri)
          polizza.periodoValAAltri = U.data_normalize(response.periodoValAAltri)

          this.setState({
            selectedPolizza: { id: response.id, dataCreazioneRichiesta: response.dataCreazioneRichiesta },
            isLoaded: false,
            polizza: response,
            storicoFlag: true,
          })
          await recuperoTabellaUnoByPolizza(polizzaId)
            .then(async (responseTabella) => {
              this.setState({ tabellaUno: responseTabella })
              if (response.associatoConConaf === 1) this.setState({ polizzaIndividuale: false, polizzaCollettiva: true })
              if (response.associatoConAltri === 1) this.setState({ polizzaIndividuale: true, polizzaCollettiva: false })
              await this.setState({ isLoaded: true })
            })
            .catch((err) => {
              this.setState({ message: err.message, typeMessage: 'danger' })
            })
        }
      })
      .catch((err) => {
        this.setState({ message: err.message, typeMessage: 'danger' })
      })
  }

  submitMessageResult = (alert: any) => {
    const element = document.getElementById('pageSectionId')
    if (element) element.scrollIntoView({ behavior: 'smooth' })
    this.setState({ message: alert.messaggio, typeMessage: alert.type })
  }
  render() {
    /************************ Dati Anagrafici non modificabili **************************************/
    const { name, lastname } = this.state
    const tabClick = (tab) => {
      this.setState({ activeTab: tab.id })
    }

    const onSelectAction = (pathname, event) => {
      const title = event.target.textContent
      this.props.history.push({
        pathname: pathname,
        state: { action: title, idPolizza: this.state.idPolizza, row: this.state.selectedRow },
      })
    }

    /************************ Dati Anagrafici non modificabili **************************************/
    const SpidData = (props) => {
      return (
        <>
          <div className="row">
            <div className={isCurrentMobileDevice() ? null : 'col-4'}>
              <GenericTextInput type={'text'} placeholder="nome" key="nome" id="nome" name="nome" label="Nome" isDisabled defaultValue={this.state.user.nome} />
            </div>
            <div className={isCurrentMobileDevice() ? null : 'col-4'}>
              <GenericTextInput
                type={'text'}
                placeholder="cognome"
                key="cognome"
                id="cognome"
                name="cognome"
                label="Cognome"
                isDisabled
                defaultValue={this.state.user.cognome}
              />
            </div>
            <div className={isCurrentMobileDevice() ? null : 'col-4'}>
              <GenericTextInput type={'text'} placeholder="cf" key="cf" id="cf" name="cf" label="Codice fiscale" isDisabled defaultValue={this.state.user.cf} />
            </div>
          </div>
        </>
      )
    }

    /************************ Dati Societa STP non modificabili **************************************/
    const SocietaSTPData = () => {
      return (
        <>
          <div className="row">
            <div className={isCurrentMobileDevice() ? null : 'col-4'}>
              <GenericTextInput
                type={'text'}
                placeholder="nome"
                key="nome"
                id="nome"
                name="nome"
                label="Ragione Sociale"
                isDisabled
                defaultValue={this.state.user.ragioneSociale}
              />
            </div>

            <div className={isCurrentMobileDevice() ? null : 'col-4'}>
              <GenericTextInput
                type={'text'}
                placeholder="nome"
                key="nome"
                id="nome"
                name="nome"
                label="Partita IVA"
                isDisabled
                defaultValue={this.state.user.partitaIva}
              />
            </div>
          </div>
        </>
      )
    }

    const AvvisiBO = () => {
      return (
        <>
          <div className="row">
            <div className={isCurrentMobileDevice() ? null : 'col-4'}>
              <GenericTextInput
                type={'text'}
                placeholder="stato"
                key="nome"
                id="stato"
                name="stato"
                label="Stato della polizza"
                isDisabled
                defaultValue={AssicurazioneUtils.decodificaStatiPolizza(this.state.polizza.stato)}
              />
            </div>
            <div className={isCurrentMobileDevice() ? null : 'col-4'}>
              <GenericTextInput
                type={'text'}
                placeholder="tipologia"
                key="tipologia"
                id="tipologia"
                name="tipologia"
                label="Tipologia"
                isDisabled
                defaultValue={
                  this.state.polizza.associatoConConaf === 1 ? 'COLLETTIVA' : this.state.polizza.associatoConAltri === 1 ? 'INDIVIDUALE' : 'INVALIDA'
                }
              />
            </div>
            <div className={isCurrentMobileDevice() ? null : 'col-4'}>
              <GenericTextInput
                type={'text'}
                placeholder="tipoRichiesta"
                key="tipoRichiesta"
                id="tipoRichiesta"
                name="tipoRichiesta"
                label="Tipo richiesta"
                isDisabled
                defaultValue={AssicurazioneUtils.decodificaTipoRichiesta(this.state.polizza.tipoRichiesta)}
              />
            </div>
          </div>
          <br></br>
          <div className="row" hidden={!this.state.polizza.noteConferma || this.state.polizza.stato !== 2}>
            Note conferma
            <GenericAlert key="noteConferma" id="noteConferma" label={this.state.polizza.noteConferma} />
          </div>
          <div
            className="row"
            hidden={
              !this.state.polizza.noteRifiuto ||
              (this.state.polizza.stato !== 3 && this.state.polizza.stato !== 4 && this.state.polizza.stato !== 1 && this.state.polizza.stato !== 6)
            }
          >
            Note
            <GenericAlert key="noteRifiuto" id="noteRifiuto" color={'danger'} label={this.state.polizza.noteRifiuto} />
          </div>
          <div className="row" hidden={!this.state.polizza.noteRichiedente}>
            Note richiedente
            <GenericAlert key="noteRichiedente" id="noteRichiedente" color={'secondary'} label={this.state.polizza.noteRichiedente} />
          </div>
        </>
      )
    }

    return (
      <div key={'visualizzaPolizza'}>
        <div className="container-fluid p-5">
          {this.state.isLoaded ? (
            <PageSection>
              <div>
                <GenericBreadCrumb
                  paths={[
                    { label: 'Polizze', link: PATH_TO_BO_POLIZZE_ELENCO },
                    { label: 'Elenco polizze', link: PATH_TO_BO_POLIZZE_ELENCO },
                    { label: 'Visualizza Polizza', link: '#' },
                  ]}
                />
                <br></br>
                <Nav tabs className="mb-3">
                  {listaStorico?.map((item, index) => (
                    <NavItem>
                      <NavLink
                        active={this.state.selectedPolizza && this.state.selectedPolizza.id === item.id ? true : false}
                        onClick={() => {
                          this.setState({ isLoaded: false })
                          this.setSelectedPolizza(item.id)
                        }}
                      >
                        {U.itDate(item.dataCreazioneRichiesta?.split('T')[0]) + ' ' + item.dataCreazioneRichiesta?.split('.')[0].split('T')[1]}
                      </NavLink>
                    </NavItem>
                  ))}
                </Nav>

                <div
                  className={isCurrentMobileDevice() ? null : 'd-flex justify-content-between ipi-title row'}
                  hidden={this.state.selectedPolizza === undefined}
                >
                  <div className={isCurrentMobileDevice() ? null : 'col-6'}>
                    <Title headingLevel="h1" size="4xl">
                      Visualizza polizza
                    </Title>
                  </div>

                  <div className={isCurrentMobileDevice() ? null : 'col-3 p-3'}>
                    <span className="me-1">
                      {this.state.polizza.stato === 1 &&
                        this.state.polizza.id === this.state.idPolizza &&
                        TokenStore.getInstance().isCurrentAccountCONAF() === true &&
                        TokenStore.getInstance().currentAccountHasRolesOR([RO_GEST_POLI]) && (
                          <GenericButton color={'primary'} label={'Accetta'} onClick={(e) => onSelectAction(PATH_TO_BO_POLIZZE_ELENCO_CONFERMA, e)} />
                        )}
                    </span>
                    <span>
                      {this.state.polizza.stato === 1 &&
                        this.state.polizza.id === this.state.idPolizza &&
                        TokenStore.getInstance().isCurrentAccountCONAF() === true &&
                        TokenStore.getInstance().currentAccountHasRolesOR([RO_GEST_POLI]) && (
                          <GenericButton color={'secondary'} label={'Rifiuta'} onClick={(e) => onSelectAction(PATH_TO_BO_POLIZZE_ELENCO_RIGETTA, e)} />
                        )}
                    </span>
                  </div>
                  <div className={isCurrentMobileDevice() ? 'col-12 p-2' : 'col-2'}>
                    <GenericChips label={'Polizza ' + this.state.tipologiaPolizza + ' ' + this.state.idPolizza}></GenericChips>
                  </div>
                </div>
                <GenericAlert hidden={this.state.message === ''} label={this.state.message} color={this.state.typeMessage} />
                {!this.state.utenteSTP && <GenericAccordionCard cardHeader={<h4>Dati anagrafici</h4>} cardBody={<SpidData></SpidData>} />}
                {this.state.utenteSTP && <GenericAccordionCard cardHeader={<h4>Dati società</h4>} cardBody={<SocietaSTPData></SocietaSTPData>} />}
                <GenericAccordionCard cardHeader={<h4>Messaggi e Avvisi</h4>} cardBody={<AvvisiBO></AvvisiBO>} />
                <br></br>
                <GenericWizard
                  tabClick={tabClick}
                  steps={
                    this.state.polizza.associatoConAltri
                      ? [
                          {
                            id: 1,
                            name: 'Dati informativi',
                            component:
                              this.state.activeTab === 1 ? (
                                <FormDatiInformativi data={this.state.polizza} tabellaUno={this.state.tabellaUno} storicoFlag={this.state.storicoFlag} />
                              ) : null,
                          },
                          {
                            id: 2,
                            name: 'Tabella 1',
                            component:
                              this.state.activeTab === 2 ? (
                                <FormTabellaUno
                                  name={name}
                                  lastname={lastname}
                                  data={this.state.polizza}
                                  tabellaUno={this.state.tabellaUno}
                                  storicoFlag={this.state.storicoFlag}
                                />
                              ) : null,
                          },
                          {
                            id: 3,
                            name: 'Riepilogo',
                            component:
                              this.state.activeTab === 3 ? (
                                <FormRiepilogo
                                  name={this.state.name}
                                  lastname={this.state.lastname}
                                  data={this.state.polizza}
                                  tabellaUno={this.state.tabellaUno}
                                  storicoFlag={this.state.storicoFlag}
                                />
                              ) : null,
                          },
                          {
                            id: 4,
                            name: 'Dati Polizza Individuale',
                            component:
                              this.state.activeTab === 4 ? (
                                <FormDatiPolizzaIndividuale data={this.state.polizza} tabellaUno={this.state.tabellaUno} storicoFlag={this.state.storicoFlag} />
                              ) : null,
                          },
                        ]
                      : [
                          {
                            id: 1,
                            name: 'Dati informativi',
                            component:
                              this.state.activeTab === 1 ? (
                                <FormDatiInformativi
                                  data={this.state.polizza}
                                  getSelectedValue={this.getSelectValues}
                                  tabellaUno={this.state.tabellaUno}
                                  storicoFlag={this.state.storicoFlag}
                                />
                              ) : null,
                          },
                          {
                            id: 2,
                            name: 'Tabella 1',
                            component:
                              this.state.activeTab === 2 ? (
                                <FormTabellaUno
                                  name={name}
                                  lastname={lastname}
                                  data={this.state.polizza}
                                  getSelectedValue={this.getSelectValues}
                                  tabellaUno={this.state.tabellaUno}
                                  storicoFlag={this.state.storicoFlag}
                                />
                              ) : null,
                          },
                          {
                            id: 3,
                            name: 'Riepilogo',
                            component:
                              this.state.activeTab === 3 ? (
                                <FormRiepilogo
                                  name={name}
                                  lastname={lastname}
                                  data={this.state.polizza}
                                  getSelectedValue={this.getSelectValues}
                                  tabellaUno={this.state.tabellaUno}
                                  storicoFlag={this.state.storicoFlag}
                                />
                              ) : null,
                          },
                          {
                            id: 4,
                            name: 'Dati Pagamento',
                            component:
                              this.state.activeTab === 4 ? (
                                <FormDatiPagamento
                                  data={this.state.polizza}
                                  polizza={this.state.polizza}
                                  getSelectedValue={this.getSelectValues}
                                  tabellaUno={this.state.tabellaUno}
                                  storicoFlag={this.state.storicoFlag}
                                />
                              ) : null,
                          },
                        ]
                  }
                  cancelButtonText={false}
                  backButtonText={'PRECEDENTE'}
                  nextButtonText={'SUCCESSIVO'}
                  onClickBack={this.previousStep}
                  onClickNext={this.nextStep}
                  height="4"
                />
                <br></br>
              </div>
            </PageSection>
          ) : (
            <GenericSpinner></GenericSpinner>
          )}
        </div>
      </div>
    )
  }
}

export default VisualizzaPolizza
