import * as React from 'react'
import {PageSection, Title} from '@patternfly/react-core'

const items =
  ([{label: 'Titolo', value: 'Dottore forestale'},
    {label: 'Stato smart card', value: 'Richiesta fornitura in corso'},
    {label: 'Richiesta trasferimento ordine in corso', value: 'No'},
    {label: 'PEC', value: 'email@pec.it'},
    {label: 'Stato lettore smart card', value: 'Dispositivo valido'},
    {label: 'Richiesta cambio sezione in corso', value: 'No'},
    {label: 'Iscrizione EPAP', value: 'Si'},
    {label: 'Stato tocken', value: 'valido'},
  ])

  const steps = [
    { name: 'First step', component: <p>Step 1 content</p> },
    { name: 'Second step', component: <p>Step 2 content</p> },
    { name: 'Third step', component: <p>Step 3 content</p> },
    { name: 'Fourth step', component: <p>Step 4 content</p> },
    { name: 'Five step', component: <p>Step 5 content</p>}
  ];

const Dashboard: React.FunctionComponent = () => (
  <PageSection>
    <Title headingLevel="h1" size="lg">Backoffice dashboard</Title>
  </PageSection>
)

export default Dashboard;
