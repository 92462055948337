import React, {useCallback, useEffect, useRef, useState} from 'react'
import {Alert, Button, Icon, Spinner} from 'design-react-kit'
import {ActionsColumn, TableComposable, Tbody, Td, Th, Thead, Tr} from '@patternfly/react-table'
import {
    ActionList,
    ActionListItem,
    debounce,
    MenuToggle,
    Pagination,
    PaginationVariant,
    SearchInput
} from '@patternfly/react-core'
import {Filter, GenericAsyncTableDataFilter} from './filters'
import {WrapCmp} from './styles'
import {getSessionItem, setSessionItem} from 'src/app/utils/Storage'
import FilterIcon from '@patternfly/react-icons/dist/esm/icons/filter-icon'

const STORAGE_CODE_PREFIX = 'asyntable_data_'
export const GenericAsyncTableData: React.FunctionComponent<GenericTableDataProps> = (props) => {
  const { manualRefreshCount } = props
  const pageSizeOptions = props.pageSizeOptions || [10, 20, 50]
  const { minWidth } = props

  const [isInit, setIsInit] = useState<boolean>(false)
  const needReloadRows = useRef<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [showFilter, setShowFilter] = React.useState<boolean>(false)
  const [error, setError] = React.useState<any>(null)
  // Pagination data
  const [page, setPage] = React.useState<number>(0)
  const [pageSize, setPageSize] = React.useState<number>(pageSizeOptions[0])
  const [rowTotals, setRowTotals] = React.useState<any>(null)
  // Rows
  const [rows, setRows] = React.useState<any>([])
  // Filtri
  const [filtersValue, setFiltersValue] = React.useState<any>({})
  // Sort
  const [activeSortIndex, setActiveSortIndex] = React.useState<number>(-1)
  const [activeSortDirection, setActiveSortDirection] = React.useState<'asc' | 'desc' | undefined>(undefined)

  const getActiveFilters = (filtersParam?: any) => {
    let filterValuesToUse = filtersParam ? filtersParam : filtersValue
    const filters = props.filters || []
    const list: any = []
    return filters.reduce((list, filter, i) => {
      const v = filterValuesToUse[i]
      if (v !== '' && v !== undefined && v !== null) {
        list.push({
          field: filter.field,
          value: v.value !== undefined ? v.value : v,
          operator: filter.operator ? filter.operator : null,
        })
      }
      return list
    }, list)
  }


  // inizializza i dati della lista dalla sessione
  useEffect(() => {
    if (props.tableKey) {
      const tableData = getSessionItem(STORAGE_CODE_PREFIX + props.tableKey)
      if (tableData) {
        setPage(tableData.page)
        setPageSize(tableData.pageSize)
        setFiltersValue(tableData.filters)
        setActiveSortIndex(tableData.activeSortIndex)
        setActiveSortDirection(tableData.activeSortDirection)
      }
    }
    setIsInit(true)
  }, [])

  const reload = useCallback(
    debounce(async (page, pageSize, filtersValue, activeSortIndex, activeSortDirection) => {
      try {
        setLoading(true)
        do {
          needReloadRows.current = false
          const data = await props.getRows({
            filters: getActiveFilters(filtersValue),
            pageSize: pageSize,
            currentPage: page,
            sortColumn: activeSortIndex >= 0 ? props.columns[activeSortIndex].field : undefined,
            sortDirection: activeSortDirection,
          })
          setError(false)
          setRows(data.items)
          setRowTotals({
            total: data.total,
            page: Math.ceil(data.total / pageSize),
          })
          if (props.tableKey) {
            // Save dati tabella nella sessione
            setSessionItem(STORAGE_CODE_PREFIX + props.tableKey, {
              page: page,
              pageSize: pageSize,
              filters: filtersValue,
              activeSortIndex: activeSortIndex,
              activeSortDirection: activeSortDirection,
            })
          }
        } while (needReloadRows.current)
        setLoading(false)
      } catch (e) {
        setLoading(false)
        setError("Ops c'è stato un errore nel caricamento dei dati!!")
      }
    }, 1000),
    []
  )

  // Load data ---
  useEffect(() => {
    if (!isInit) {
      return
    }
    if (!loading) {
      reload(page, pageSize, filtersValue, activeSortIndex, activeSortDirection)
    } else {
      needReloadRows.current = true
    }
  }, [page, pageSize, filtersValue, activeSortIndex, activeSortDirection, isInit, manualRefreshCount])

  const getSortParams = (column, index) => {
    if (column.isSortable) {
      return {
        sortBy: {
          index: activeSortIndex,
          direction: activeSortDirection,
          defaultDirection: undefined,
        },
        onSort: (_event, index, direction) => {
          setActiveSortIndex(index)
          setActiveSortDirection(direction)
        },
        columnIndex: index,
      }
    }
    return undefined
  }


/**
 * @description Function per prevenire che consente di inserire solo numeri, virgolette e punto.
 * TODO: bisogna trovare il modo di renderlo generico, al momento non verrà utilizzata, bisogna modificarla in modo che permetta di fare cntr+v
 * @param {string} field
 * @param {event}  event
 * @returns boolean || preventDefault()
 */
  const regexCheck = (field,event) => {
    const value = event.key;
    const keyCode = event.keyCode;
    if(!(keyCode === 8)){

      if(!value.match(/^[0-9.]*$/)){
        event.preventDefault();

      }
    }
    return true;
  }

  const getColumnValue = (column: Column, row: any) => {
    if (column.field && row[column.field] !== undefined) {
      const value = row[column.field]
      if (column.options && column.options[value]) {
        return column.options[value]
      }
      if (column.toFixed && row[column.field].indexOf(".") === 1)
        return Number(row[column.field]).toFixed(column.toFixed)
      else
        return row[column.field]
    }
    return ''
  }

  const haveFilters = (props.filters && props.filters.length > 0) || props.showSearch
  return (
    <WrapCmp>
      <div style={{ overflowX: 'auto', width: '100%', minHeight: '800px' }}>
        <div className="table-data-topbar" style={{ minWidth: minWidth ? minWidth : 'none' }}>
          <ActionList>
            <ActionListItem
              className="col-1"
              onClick={() => {
                setShowFilter(!showFilter)
              }}
            >
              {haveFilters && <MenuToggle icon={<FilterIcon />} variant="typeahead" />}
            </ActionListItem>
            <ActionListItem className="col-4">
              {props.showSearch && <SearchInput className={'search-input'} style={{ paddingLeft: '35px', width: '100%' }} placeholder="Cerca" />}
            </ActionListItem>
            <ActionListItem className="col-2"></ActionListItem>
            <ActionListItem className="col-4">
            {(!props.hidePagination || rowTotals !== null) && (
              <Pagination
                hidden={props.hidePagination}
                itemCount={rowTotals?.total}
                variant={PaginationVariant.top}
                page={page + 1}
                perPage={pageSize}
                defaultToFullPage
                onPageInput={(e, v) => setPage(v - 1)}
                onPerPageSelect={(e, v) => setPageSize(v)}
                onNextClick={() => setPage(page + 1)}
                onPreviousClick={() => setPage(page - 1)}
                onLastClick={() => setPage(rowTotals.page - 1)}
                onFirstClick={() => setPage(0)}
                perPageOptions={pageSizeOptions.map((e) => {
                  return {
                    title: e + '',
                    value: e,
                  }
                })}
              />
              )}
            </ActionListItem>
          </ActionList>
        </div>
        {haveFilters && (
          <div className={'filters-toolbar'} style={{ display: showFilter ? 'flex' : 'none' , alignItems: 'center' }}>
            {props.filters?.map((f, i) => {
              return (
                <div className={`filter-item filter-${f.field}`} key={`f${i}`} style={{ flex: '0 0 auto', marginRight: '2px' }} >
                  <GenericAsyncTableDataFilter
                    id={`fiter.${i}`}
                    filter={f}
                    value={filtersValue[i]}
                    onChange={(v) => {
                      setPage(0)
                      if (v === undefined) {
                        const f = filtersValue
                        delete f[i]
                        setFiltersValue({
                          ...f,
                        })
                      } else {
                        setFiltersValue({
                          ...filtersValue,
                          [i]: v,
                        })
                      }
                    }}
                  />
                </div>
              )
            })}
            <div className={'filter-item'}>
              <Button type="button" onClick={() => setFiltersValue({})}>
                <Icon size="sm" color="danger" icon="it-delete" />
              </Button>
            </div>
          </div>
        )}
        <div className={'table-data'} style={{ minWidth: minWidth ? minWidth : 'auto' }}>
          {error && (
            <div className={'error-notice'}>
              <Alert color={'danger'}>{error}</Alert>
            </div>
          )}
          <TableComposable aria-label="Sort table">
            <Thead>
              <Tr>
                {props.columns.map((column, index) => {
                  const sort = column.isSortable ? getSortParams(column, index) : undefined
                  return (
                    <Th key={`head${index}`} style={column.style || {}} hidden={column.hidden} sort={sort}>
                      {column.label}
                    </Th>
                  )
                })}
                {props.getRowActions && <Th className={'action-col'}>&nbsp;</Th>}
              </Tr>
            </Thead>
            <Tbody>
              {rows.map((row, index) => {
                return (
                  <Tr key={`row${index}`}>
                    {props.columns.map((column, index) => {
                      return (
                        <Td key={`col${index}`} style={column.style || {}}>
                          {column.render && column.render(row)}
                          {!column.render && getColumnValue(column, row)}
                        </Td>
                      )
                    })}
                    {props.getRowActions && (
                      <Td className={'action-col'}>
                        <ActionsColumn
                          items={props.getRowActions(row).map((a) => {
                            return {
                              title: a.label,
                              onClick: a.onClick,
                              hidden: a.hidden
                            }
                          })}
                        />
                      </Td>
                    )}
                  </Tr>
                )
              })}
            </Tbody>
          </TableComposable>
          {!loading && rowTotals && rowTotals.total == 0 && (
            <div className={'empty-table'}>{props.emptyMessage ? props.emptyMessage : 'Non sono stati trovati elementi'}</div>
          )}
          {loading && (
            <div className={'loading'}>
              <Spinner active={true} label={''} />
            </div>
          )}
          {(!props.hidePagination || rowTotals !== null) && (
            <Pagination
              hidden={props.hidePagination}
              itemCount={rowTotals?.total}
              isCompact={true}
              variant={PaginationVariant.bottom}
              page={page + 1}
              perPage={pageSize}
              defaultToFullPage
              onPageInput={(e, v) => setPage(v - 1)}
              onPerPageSelect={(e, v) => setPageSize(v)}
              onNextClick={() => setPage(page + 1)}
              onPreviousClick={() => setPage(page - 1)}
              onLastClick={() => setPage(rowTotals.page - 1)}
              onFirstClick={() => setPage(0)}
              perPageOptions={pageSizeOptions.map((e) => {
                return {
                  title: e + '',
                  value: e,
                }
              })}
            />
          )}
        </div>
      </div>
    </WrapCmp>
  )
}

export declare interface GenericTableDataProps {
  tableKey?: string

  emptyMessage?: string

  filters?: Filter[]

  columns: Column[]

  pageSizeOptions?: any

  showSearch?: boolean

  hidePagination?: boolean

  getRows: (request: RequestData) => Promise<ResponseData>

  getRowActions?: (row) => Action[]

  manualRefreshCount?: number

  minWidth?: string
}

export declare interface Column {
  label: string
  width?: boolean
  style?: any
  hidden?: boolean
  isSortable?: boolean
  field: string
  render?: any
  options?: any
  toFixed?: any // usata per i valori decimali da troncare in visualizzazione
}

export declare interface RequestData {
  filters: any
  currentPage: number
  pageSize: number
  sortColumn?: string
  sortDirection?: string
}

export declare interface ResponseData {
  items: any
  total: number
}

export declare interface Action {
  label: string
  onClick: (e: any) => void
  hidden?: boolean;
}
