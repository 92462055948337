import * as React from 'react'
import {PageSection} from '@patternfly/react-core'
import GenericBreadCrumb from 'src/frontend/app/components/GenericBreadCrumb'
import {TokenStore} from 'src/keycloak/jwt/TokenStore'
import {PATH_TO_FO_ASSICURAZIONI, PATH_TO_FO_TODO} from 'src/app/utils/RoutesConstants'
import GenericSpinner from 'src/backoffice/app/components/GenericSpinner'
import GenericTab from '../../components/GenericTab'
import FormTodoList from './form/FormTodoList'
import {getTodoEventList} from 'src/processes/TodoService'
import {getOrdineCompetenteOperatorePOST} from 'src/processes/Ordini'
import {getSoggettoByCf} from 'src/processes/Soggetto'

const title = 'Da fare'
var breadcrumbOptions = [
    { label: "Da fare", link: PATH_TO_FO_TODO }
]
const linkTabTodo = [
    {id:1,label:'Tutte'},
    {id:2,label:'Anagrafica'},
    {id:3,label:'Assicurazioni'},
    {id:4,label:'Formazione'},
]

class Todo extends React.Component<any, any> {
    constructor(props) {
        super(props);
        this.state = {
            activeTab: 1,
            message: "",
            typeMessage: "default",
            profilo: "",
            modalOpen: false,
            modalTitle: "",
            modalText: "",
            makeChoice: false,
            todoList: [],
            isLoaded: false
        };
    }



    async componentDidMount() {
        const profilo = await TokenStore.getInstance().getDenominazioneOfCurrentAccount()
        const idSoggetto = Number(TokenStore.getInstance().getCurrentAccount().idSoggetto)
        var specificGroup = Object.keys(TokenStore?.getInstance()?.getCurrentAccount()?.accounts)[0]
        var todoEventListDTO
        var tipologiaIscrizione
        var ordineCompetente

        this.setState({
            profilo: profilo
        })

        let fiscalNumber = TokenStore.getInstance().getDecodedTokenJWT().spid_fiscalNumber;
        const spidData = TokenStore.getInstance().getSpidData()
        await getSoggettoByCf(spidData.codiceFiscale ? spidData.codiceFiscale : fiscalNumber).then(async (response: any) => {
            response.data.map((soggetto) => {
                soggetto?.elencoIscrizioni?.map((iscrizione) => {
                    if (iscrizione.tipologiaIscrizioni.codice === 1) {
                        tipologiaIscrizione = iscrizione.tipologiaIscrizioni.codice
                    }
                })
            })
        }).catch((err) => {
            this.setState({message: err.message, typeMessage: "danger"})
        })

        if (tipologiaIscrizione === 1 || TokenStore.getInstance().isCurrentAccountIscrittoOrdine()) {
            tipologiaIscrizione = 1
        }

        else if (TokenStore.getInstance().isCurrentAccountODAF()) {
            tipologiaIscrizione = 2
        }

        else if (TokenStore.getInstance().isCurrentAccountCONAF()) {
            tipologiaIscrizione = 4
        }

        else if (TokenStore.getInstance().isCurrentAccountFODAF()) {
            tipologiaIscrizione = 11
        }

        else if (TokenStore.getInstance().isCurrentAccountEnteFormazione()) {
            tipologiaIscrizione = 5
        }

        else if (TokenStore.getInstance().isCurrentAccountOperatoreRivista()) {
            tipologiaIscrizione = 6
        }

        else if (TokenStore.getInstance().isCurrentAccountEnteEsterno()) {
            tipologiaIscrizione = 8
        }

        else if (TokenStore.getInstance().isCurrentAccountSTP()) {
            tipologiaIscrizione = 3
        }

        else {
            tipologiaIscrizione = -1 // -1 significa che l'utente non ha profili, non ha iscrizioni
        }

        var hasIdSoggetto = idSoggetto !== null && idSoggetto !== undefined && idSoggetto !== 0;
        if (TokenStore.getInstance().isCurrentAccountODAF()) {
            /**
             * Recupero la sigla ordine dallo specific group
             */
            await getOrdineCompetenteOperatorePOST(specificGroup).then(response => {
                if (response && response.data) {
                    ordineCompetente = response.data[0]
                }
            })
            todoEventListDTO = {
                idSoggetto: hasIdSoggetto ? idSoggetto : null,
                cf: !hasIdSoggetto ? fiscalNumber : null,
                tipologiaIscrizione: tipologiaIscrizione,
                siglaOrdine: ordineCompetente
            }
        } else {

            todoEventListDTO = {
                idSoggetto: hasIdSoggetto ? idSoggetto : null,
                cf: !hasIdSoggetto ? fiscalNumber : null,
                tipologiaIscrizione: tipologiaIscrizione
            }
        }

        /**
         * Recupero gli eventi todo list
         */
        await getTodoEventList(todoEventListDTO).then(response => {
            if (response == null || (response && response.data && response.data.httpStatus !== 200)) {
                this.setState({ todoList: []})

                // throw new Error(response.data.error);
            }

            else this.setState({ todoList: response.data.returnedObject })
        }).catch((err) => {
            this.setState({ todoList: []})

        })

        this.setState({ isLoaded: true })
    }

    render() {
        const { activeTab, todoList, isLoaded, message, typeMessage } = this.state;

        const filtraTodoListPerTab = () => {
            const { activeTab } = this.state
            if (activeTab === 1) {
                return  todoList
            }
            else if (activeTab === 2) {
                return todoList.filter(item => item.modulo === "Anagrafica" && item.dataDisattivazione === null)
            }
            else if (activeTab === 3) {
                return  todoList.filter(item => item.modulo === "Polizze" && item.dataDisattivazione === null)
            }
            else if (activeTab === 4) {
               return todoList.filter(item => item.modulo === "Formazione" && item.dataDisattivazione === null)
            }
        }

        const tabContentListTodo =
        /** Lista del contenuto dei Tab*/
        [
            {
            id:1,
            body: (this.state.activeTab === 1) ? <FormTodoList activeTab={this.state.activeTab} list={filtraTodoListPerTab()}></FormTodoList> : null
            },
            {
            id:2,
            body: (this.state.activeTab === 2) ? <FormTodoList activeTab={this.state.activeTab} list={filtraTodoListPerTab()}></FormTodoList> : null
            },
            {
            id:3,
            body: (this.state.activeTab === 3) ? <FormTodoList activeTab={this.state.activeTab} list={filtraTodoListPerTab()}></FormTodoList> : null
            },
            {
            id:4,
            body: (this.state.activeTab === 4) ? <FormTodoList activeTab={this.state.activeTab} list={filtraTodoListPerTab()}></FormTodoList> : null
            },

        ];

        const tabClick = (tab) => {
            this.setState({ activeTab: tab })
        }

        return (
            <> {isLoaded ?
                <PageSection id='pageSectionId'>
                    <div className='container register-container'>
                        <GenericBreadCrumb paths={breadcrumbOptions}></GenericBreadCrumb>
                        <div><h1>{title}</h1>
                        </div>
                        <div>
                            {(this.state.profilo === undefined) ? (<GenericSpinner />) : <h4 className='status-text'>Profilo corrente: <b>{this.state.profilo !== "" ? this.state.profilo : ""}</b></h4>}
                        </div>
                        <GenericTab
                            key={'genericTabTodo'}
                            listBodyTab={tabContentListTodo}
                            listNavLink={linkTabTodo}
                            activeTab={activeTab}
                            homeLink={PATH_TO_FO_ASSICURAZIONI}
                            isClickable={true}
                            tabClick={tabClick}
                            hiddenAlert={true}
                            hideHome={true}
                            hideStepButtons={true}
                            hideNumbers={true}
                        ></GenericTab>
                        <br></br>
                    </div>

                </PageSection>
                :
                <GenericSpinner></GenericSpinner>
            }
            </>
        );
    }
};


export default Todo;
