import * as React from 'react'
import {Form} from 'design-react-kit'
import GenericInput from 'src/frontend/app/components/GenericInput'
import {compilaIscrizioneOConaf} from 'src/processes/ProcessoIscrizione'
import {getSoggettoByCfAndTipoAnagrafeFO} from 'src/processes/Soggetto'
import {TokenStore} from 'src/keycloak/jwt/TokenStore'
import {inputRequiredValidation, isEmail} from 'src/utilities/utility'

export class FormIscrizioneConafNazionale extends React.Component<GeneralFormIscrizioneConafNazionaleProps, any> {
    constructor(props) {
        super(props);
        this.state = {
            listaOrdini: []
        };
    }

    handleSubmit = event => {
        //setto a true il flag submitted
        this.props.submitted(true)
        /*--------------------------------------------- Validazione campi obbligatori ------------------------------------------------*/
        var requiredFields = [
            { value: isEmail(event.target.idEmailOperatoreConafNazionale.value) ? event.target.idEmailOperatoreConafNazionale.value : '', label: "Email di contatto" }
        ]

        var errorRequired: any = inputRequiredValidation(requiredFields)
        if (errorRequired) {
            //setto a false il flag submitted
            this.props.submitted(false)
            event.preventDefault()
            return this.props.messaggio({ messaggio: errorRequired.messaggio, type: errorRequired.type })
        }
        /*-----------------------------------------------------------------------------------------------------------------------------*/
        else {
            event.preventDefault()
            var emailOperatoreConaf = event.target.idEmailOperatoreConafNazionale.value ? event.target.idEmailOperatoreConafNazionale.value : ''

            const token = TokenStore.getInstance().getDecodedTokenJWT();
            var cf = token.fiscalNumber
            var tipoAnagrafe = 'PF'

            //Chiamata al servizio 'getSoggettoByCfAndTipoAnagrafe' per recuperare l'idSoggetto
            getSoggettoByCfAndTipoAnagrafeFO(cf, tipoAnagrafe).then((response: any) => {
                //Compilazione Iscrizione OConaf
                compilaIscrizioneOConaf(response.data.idSoggetto, emailOperatoreConaf).then((response) => {
                    if (response.status === 200)
                    {
                        this.props.submitted(true)
                        return this.props.messaggio({ messaggio: "Iscrizione avvenuta con successo", type: "success" })
                    }
                }).catch(() => {
                    this.props.submitted(false)
                    return this.props.messaggio({ messaggio: "C'è stato un problema nell'invio dei dati", type: "danger" })
                })
            }).catch(() => {
                this.props.submitted(false)
                return this.props.messaggio({ messaggio: "C'è stato un problema durante il recupero dei dati", type: "danger" })
            })
        }
    }

    render() {
        return (
            <div>
                <Form id={'idIscrizioneOperatoreConafNazionale'} onSubmit={this.handleSubmit}>
                    <div className='row'>
                        <div className='col-6'>
                            <GenericInput type={'email'} id={'idEmailOperatoreConafNazionale'} placeholder={"Email di contatto"} label={"Email di contatto*"} isRequired />
                        </div>
                    </div>
                </Form>
            </div>
        )
    }
}

export declare interface GeneralFormIscrizioneConafNazionaleProps {
    messaggio?: any;
    idSoggetto?: any;
    submitted?: any;
}

export default FormIscrizioneConafNazionale;
