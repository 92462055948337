import * as React from 'react'
import {PageSection, Title} from '@patternfly/react-core'
import GenericBreadCrumb from 'src/frontend/app/components/GenericBreadCrumb'
import GenericCard from '../../components/GenericCard'
import GenericButton from '../../components/GenericButton'
import {
    PATH_TO_BO_ANAG_ELENCO_ISCRITTI,
    PATH_TO_BO_ANAG_ELENCO_ISCRITTI_REGISTRAZIONE_PAGAMENTO
} from 'src/app/utils/RoutesConstants'
import GenericAlert from 'src/frontend/app/components/GenericAlert'
import {FormRegistrazionePagamento} from './formRegistrazionePagamentoQuoteAnnuali/FormRegistrazionePagamento'
import {isCurrentMobileDevice} from 'src/utilities/deviceUtility'

class RegistrazionePagamento extends React.Component<any, any> {
  constructor(props) {
    super(props)
    this.state = {
      message: '',
      typeMessage: 'default',
    }
  }

  goToElencoIscritti = (redirectLink: any) => {
    this.props.history.push({
      pathname: redirectLink,
    })
  }

  submitMessageResult = (alert: any) => {
    const element = document.getElementById('pageSectionId')
    if (element) element.scrollIntoView({ behavior: 'smooth' })
    this.setState({ message: alert.messaggio, typeMessage: alert.type })
    if (alert.type === 'success') {
      setTimeout(async () => {
        this.goToElencoIscritti(PATH_TO_BO_ANAG_ELENCO_ISCRITTI)
      }, 1000)
    }
  }

  render() {
    const { message, typeMessage } = this.state
    return (
      <>
        <PageSection id="pageSectionId">
          <div className="container register-container">
            <GenericBreadCrumb
              paths={[
                { label: 'Anagrafica', link: '#' },
                { label: 'Elenco iscritti', link: PATH_TO_BO_ANAG_ELENCO_ISCRITTI },
                { label: 'Registrazione pagamento', link: PATH_TO_BO_ANAG_ELENCO_ISCRITTI_REGISTRAZIONE_PAGAMENTO },
              ]}
            />
            <br></br>
            <Title headingLevel="h1" size="4xl">
              Registrazione pagamento quota annuale
            </Title>
            <br></br>
            <GenericAlert hidden={message === ''} label={message} color={typeMessage}></GenericAlert>
            <GenericCard
              isExpandibleCard={false}
              header={'Compila i seguenti campi'}
              body={
                <>
                  <FormRegistrazionePagamento messaggio={this.submitMessageResult} />
                </>
              }
            />
            <br></br>
            <div className="row">
              <div className={isCurrentMobileDevice() ? null : 'col-1'}>
                <GenericButton label={'INDIETRO'} color={'tertiary'} onClick={() => this.goToElencoIscritti(PATH_TO_BO_ANAG_ELENCO_ISCRITTI)} />
              </div>
              <div className={isCurrentMobileDevice() ? null : 'col-1'}>
                <GenericButton form={'idRegistrazionePagamento'} type={'submit'} label={'CONFERMA'} color={'primary'} isDisabled={typeMessage === 'success'} />
              </div>
            </div>
          </div>
        </PageSection>
        <br></br>
        <PageSection>
          <div className="container register-container">
            <Title headingLevel="h3">Legenda</Title>
            <p>* Campo obbligatorio</p>
          </div>
        </PageSection>
      </>
    )
  }
}

export default RegistrazionePagamento
