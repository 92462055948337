import React, {useCallback, useEffect, useRef, useState} from 'react'
import {Link, useHistory, useLocation} from 'react-router-dom'
import useFrontOffice from 'src/hooks/useFrontOffice'
import {Button, PageSection, Title} from '@patternfly/react-core'
import {Label} from 'design-react-kit'
import styled from 'styled-components'

import {
    PATH_TO_BO_DETTAGLIO_FORMATORE,
    PATH_TO_BO_ELENCO_FORMATORI,
    PATH_TO_FO_DETTAGLIO_FORMATORE,
    PATH_TO_FO_ELENCO_FORMATORI
} from 'src/app/utils/RoutesConstants'
import {createFormData, getTabActiveFormId, handleChangeFormData, validateFormData} from 'src/app/utils/form-utils'
import formValidationRules from 'src/backoffice/app/pages/anagrafica/formFormatori/FormRules'
import {requestMapperFormatore, responseMapperFormatore} from 'src/frontend/app/pages/iscrizioneAlboEF/form-utils'
import {DETAILS_ACTIONS} from 'src/formazione'
import GenericWizard from 'src/backoffice/app/components/GenericWizard'

// Form components
import FormSubmit, {STATUS} from 'src/frontend/app/components/Form/GenericSubmit'

// Form azioni
import {getDefaultAxiosConfig, getFormazioneResourceApi} from 'src/app/utils/ManagerRestGateway'
import GenericBreadCrumb from 'src/frontend/app/components/GenericBreadCrumb'
import {GenericSpinner} from 'src/backoffice/app/components'
import GenericCard from 'src/frontend/app/components/GenericCard'
import FormAnagrafica from '../formFormatori/FormAnagrafica'
import FormDichiarazioni from '../formFormatori/FormDichiarazioni'
import FormAltriTitoli from '../formFormatori/FormAltriTitoli'
import FormAttivitaFormative from '../formFormatori/FormAttivitaFormative'
import FormEsperienzaProfessionale from '../formFormatori/FormEsperienzaProfessionale'
import FormEsperienzaDidattica from '../formFormatori/FormEsperienzaDidattica'
import FormEsperienzaScientifica from '../formFormatori/FormEsperienzaScientifica'
import {ottieniAllegato} from '../../formazione/formEntiEsterni/formUtils'
import FormApprovazione from './FormApprovazione'
import FormRigetto from './FormRigetto'
import FormIntegrazione from './FormIntegrazione'
import {LABEL_STATI, STATI_ESONERO} from 'src/model/Esoneri'
import {FlexDiv} from '../../formazione/DettaglioRichiestaEsonero'

const LinkStyled = styled.div`
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: end;
`
const Spacer = () => (
  <>
    <br></br>
  </>
)
const MyDiv = FlexDiv

const DettaglioFormatore: React.FunctionComponent = (props) => {
  const isFrontOffice = useFrontOffice()
  const pathToElenco = isFrontOffice ? PATH_TO_FO_ELENCO_FORMATORI : PATH_TO_BO_ELENCO_FORMATORI
  const pathToDettaglio = isFrontOffice ? PATH_TO_FO_DETTAGLIO_FORMATORE : PATH_TO_BO_DETTAGLIO_FORMATORE
  const basePath = { label: 'Elenco Formatori', link: pathToElenco }
  const myPath = {
    label: 'Dettaglio Formatore',
    link: pathToDettaglio,
  }

  const history = useHistory()
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const id = searchParams.get('id')
  const [stato, setStato] = useState<any>()
  const modalita = searchParams.get('modalita')
  const viewOnly = modalita !== DETAILS_ACTIONS.MODIFICA
  // Submit state
  const [editStatus, setEditStatus] = useState<any>()
  const [editMessage, setEditMessage] = useState<any>()
  const [isInCompilazione, setIsInCompilazione] = useState<boolean>(false)

  // Form State
  const refForm = useRef<any>([])
  const [data, setData] = useState<any>({})
  const [errors, setErrors] = useState<any>({})
  const formData = createFormData(data, setData, errors, setErrors)

  const [reqStatus, setReqStatus] = useState<any>()
  const [reqMessage, setReqMessage] = useState<any>()

  useEffect(() => {
    const doRequest = async (id) => {
      const { data: resp } = await getFormazioneResourceApi().msfoFormazioneFormatoreGet(id, getDefaultAxiosConfig())
      const obj = await responseMapperFormatore(resp)

      setData(obj)
      setStato(resp?.formatore?.stato)
    }
    doRequest(id)
  }, [id])

  useEffect(() => {
    setIsInCompilazione(stato === 0)
  }, [stato])

  const onInitInput = (tab) => (cmp) => {
    refForm.current.push({ tab, cmp })
  }

  const handleInput = (code, value) => {
    handleChangeFormData(formData, code, value, formValidationRules)
  }

  const onBack = () => {
    history.replace(pathToElenco)
  }

  const isValid = useCallback(
    (toIndex) => {
      for (let index = 0; index <= toIndex; index++) {
        const inputs = getTabActiveFormId(refForm, index)
        const isValid = validateFormData(formData, formValidationRules, inputs)
        if (!isValid) return false
      }
      return true
    },
    [formData]
  )

  const onSubmit = useCallback(
    async (noChangeState?: boolean) => {
      try {
        // TODO change to include allegati
        if (!isValid(6)) {
          setEditStatus(STATUS.FAILURE)
          setEditMessage('Controllare i dati inseriti')
          window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
          return null
        }
        setEditStatus(STATUS.LOADING)

        const _data = (await requestMapperFormatore(formData.data, !noChangeState ? STATI_ESONERO.INVIATA : stato)) as any
        if (!noChangeState) {
          _data.formatore.stato = STATI_ESONERO.INVIATA
        }
        _data.formatore.id = id

        await getFormazioneResourceApi().msfoFormazioneAggiornaFormatorePut(_data as any, getDefaultAxiosConfig())

        setEditStatus(STATUS.SUCCESS)
        setEditMessage('Dati salvati con successo')
        setTimeout(() => {
          onBack()
        }, 500)
      } catch (e) {
        setEditStatus(STATUS.FAILURE)
        setEditMessage('Impossibile salvare i dati')
      }
      return null
    },
    [formData, id]
  )

  const forms = [
    <FormAnagrafica title="Dati Anagrafici" data={data} errors={errors} onInitInput={onInitInput(0)} handleInput={handleInput} viewOnly={viewOnly} />,
    <FormDichiarazioni title="Dichiarazioni" data={data} errors={errors} onInitInput={onInitInput(1)} handleInput={handleInput} viewOnly={viewOnly} />,
    <FormAltriTitoli title="Altri titoli" data={data} errors={errors} onInitInput={onInitInput(2)} handleInput={handleInput} viewOnly={viewOnly} />,
    <FormAttivitaFormative
      title="Attività formative svolte"
      data={data}
      errors={errors}
      onInitInput={onInitInput(3)}
      handleInput={handleInput}
      viewOnly={viewOnly}
    />,
    <FormEsperienzaProfessionale
      title="Esperienza professionale"
      data={data}
      errors={errors}
      onInitInput={onInitInput(4)}
      handleInput={handleInput}
      viewOnly={viewOnly}
    />,
    <FormEsperienzaDidattica
      title="Esperienza didattica"
      data={data}
      errors={errors}
      onInitInput={onInitInput(5)}
      handleInput={handleInput}
      viewOnly={viewOnly}
    />,
    <FormEsperienzaScientifica title="Allegati" data={data} errors={errors} onInitInput={onInitInput(6)} handleInput={handleInput} viewOnly={viewOnly} />,
  ]
  const steps = [
    {
      id: 0,
      name: 'Dati Anagrafici',
      component: forms[0],
    },
    {
      id: 1,
      name: 'Dichiarazioni',
      component: forms[1],
    },
    {
      id: 2,
      name: 'Altri titoli',
      component: forms[2],
    },
    {
      id: 3,
      name: 'Attività formative svolte',
      component: forms[3],
    },
    {
      id: 4,
      name: 'Esperienza professionale',
      component: forms[4],
    },
    {
      id: 5,
      name: 'Esperienza didattica',
      component: forms[5],
    },
    {
      id: 6,
      name: 'Esperienza scientifica',
      component: forms[6],
    },
  ]

  if (!data) {
    return (
      <>
        <GenericSpinner size="lg" />
        Download in corso...
      </>
    )
  }

  return (
    <PageSection>
      <div className="container  register-container">
        <GenericBreadCrumb paths={[basePath, myPath]} />
        <Spacer />

        <Title headingLevel="h1" size="lg">
          {myPath.label}
        </Title>

        <MyDiv>
          {data && data.logoAllegato && data.logoAllegato.idAllegato && (
            <Button
              variant="primary"
              onClick={() => {
                let idAllegato: string | null = data.logoAllegato.idAllegato

                if (idAllegato) {
                  ottieniAllegato(idAllegato)
                } else {
                  console.error('IDALLEGATO MISSING')
                }
              }}
            >
              Scarica logo
            </Button>
          )}

          {data && <p>Stato : {LABEL_STATI[data.stato]}</p>}
        </MyDiv>
        <Spacer />

        {modalita === DETAILS_ACTIONS.RICHIESTA_INTEG && <FormIntegrazione id={id} />}
        {modalita === DETAILS_ACTIONS.APPROVA && <FormApprovazione id={id} sdaf_da_approvare={data?.sdafDaApprovare} />}
        {modalita === DETAILS_ACTIONS.RIGETTA && <FormRigetto id={id} stato={stato} />}
        {modalita === DETAILS_ACTIONS.MODIFICA ? (
          <>
            {stato === STATI_ESONERO.INTEGRAZIONE && (
              <GenericCard cardHeader={<Title headingLevel="h2">Sono richieste integrazioni. Motivo:</Title>}>
                <Title headingLevel="h3">{data.prot_approvazione_rifiuto}</Title>
              </GenericCard>
            )}
            <FormSubmit
              title="Conferma modifica"
              buttonLabel="Modifica"
              alertMessage={editMessage}
              status={editStatus}
              onSubmit={() => {
                onSubmit(isInCompilazione)
              }}
            >
              <Label>Per modificare il formatore cliccare "Modifica".</Label>
            </FormSubmit>
            {isInCompilazione && (
              <FormSubmit
                title="Conferma invio"
                buttonLabel="Invia"
                alertMessage={editMessage}
                status={editStatus}
                onSubmit={() => {
                  onSubmit()
                }}
              >
                <Label>Per inviare la richiesta del formatore cliccare "Invia".</Label>
              </FormSubmit>
            )}
            {forms}
            <LinkStyled>
              <Link to={pathToElenco}>Torna alla lista</Link>
            </LinkStyled>
          </>
        ) : (
          <GenericWizard
            steps={steps}
            backButtonText={'PRECEDENTE'}
            nextButtonText={'SUCCESSIVO'}
            cancelButtonText={'Torna alla lista'}
            onClickNext={null}
            onClickCancel={onBack}
            height="auto"
          />
        )}
      </div>
      <PageSection>
        <div className="container register-container">
          <Title headingLevel="h3">Legenda</Title>
          <p>* Campo obbligatorio</p>
          <p>** Campo non modificabile compilato automaticamente</p>
        </div>
      </PageSection>
    </PageSection>
  )
}

export default DettaglioFormatore
