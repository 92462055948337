import React from 'react'
import {STATI} from 'src/model/PianiFormativi'
import FormVerificaPof from './FormVerificaPof'
import PofStatus from 'src/frontend/app/pages/iscrizioneAlboEF/FormPOF/PofStatus'


const PofStatusForm: React.FunctionComponent<PofStatusFormProps> = (props) => {
  if(props.startAction === 4) {
    return <PofStatus  pof={props.pof} startAction={props.startAction} />
  } else {
    if(props.pof.stato===STATI.PRESENTATA){
      return <FormVerificaPof {...props} />
    }
    return <PofStatus  pof={props.pof} />
  }
}


export interface PofStatusFormProps {
  pof: any;
  onUpdate:any
  startAction:number
}


export default PofStatusForm
