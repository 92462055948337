import {Title} from '@patternfly/react-core'
import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from 'design-react-kit'
import React from 'react'

class GenericModal extends React.Component<GeneralModalProps, any>{
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div>
                <Modal
                    centered={this.props.centered}
                    isOpen={this.props.modalOpen}
                    onClose={this.props.closeButton}
                    size={this.props.size}
                >
                    <ModalHeader>
                        <Title headingLevel={"h3"}>{this.props.title}</Title>
                    </ModalHeader>
                    <ModalBody>
                        {this.props.text}
                    </ModalBody>
                    <ModalFooter>
                        <Button hidden={!this.props.makeChoice} color='primary' key="yes" onClick={this.props.choiceAction}>
                            Si
                        </Button>
                        <Button hidden={!this.props.makeChoice} key="no" onClick={this.props.closeButton}>
                            No
                        </Button>
                        <Button hidden={this.props.makeChoice} color='primary' onClick={this.props.closeButton}>
                            OK
                        </Button>
                    </ModalFooter>
                </Modal>
            </div>
        );
    }
}

export declare interface GeneralModalProps {
    centered?: boolean;
    text?: any;
    title?: any;
    modalOpen?: boolean;
    makeChoice?: boolean;
    choiceAction?: any;
    closeButton?: any;
    size?: any;
}

export default GenericModal
