import * as React from 'react'
import {Form} from '@patternfly/react-core'
import {inputRequiredValidation} from 'src/utilities/utility'
import {getDefaultAxiosConfig, getTrasferimentoOrdineResourceApi} from 'src/app/utils/ManagerRestGateway'
import GenericCheckbox from 'src/frontend/app/components/GenericCheckbox'
import GenericTextArea from 'src/backoffice/app/components/GenericTextArea'
import {isCurrentMobileDevice} from 'src/utilities/deviceUtility'

export class FormConcediNullaOsta extends React.Component<GeneralFormConcediNullaOstaProps, any> {
  handleSubmit = async (event) => {
    /*--------------------------------------------- Validazione campi obbligatori ------------------------------------------------*/
    var requiredFields = [{ value: event.target.dichiarazione.checked === false && '', label: 'Dichiarazione' }]

    var errorRequired: any = inputRequiredValidation(requiredFields)
    if (errorRequired !== undefined && errorRequired !== '') {
      event.preventDefault()
      return this.props.messaggio({ messaggio: errorRequired.messaggio, type: errorRequired.type })
    } else {
    /*----------------------------------------------------------------------------------------------------------------------------*/
      event.preventDefault()
      const param: any = {
        trasferimentoOrdineDTO: {
          flagNullaOsta: event.target.dichiarazione.checked === true ? 1 : 0,
          notaConcessioneNullaOsta: event.target.nota.value ? event.target.nota.value : '',
          //   motivoRifiutoTrasferimentoOrdine: event.target.nota.value ? event.target.nota.value : "",
          idTrasferimentoOrdine: this.props.idTrasferimentoOrdine,
          idIscrizioneOrdineTerritoriale: this.props.idIscrizione,
          idSoggettoIscritto: this.props.idSoggetto,
        },
      }

      await getTrasferimentoOrdineResourceApi()
        .mswfTrasferimentoOrdineConcediNullaOstaPost(param, getDefaultAxiosConfig())
        .then(() => {
          return this.props.messaggio({ messaggio: 'Dati salvati con successo', type: 'success' })
        })
        .catch((err) => {
          return this.props.messaggio({ messaggio: "C'è stato un problema nell'invio dei dati", type: 'danger' })
        })
    }
  }

  render() {
    return (
      <div>
        <Form id="concediNullaOsta" onSubmit={this.handleSubmit}>
          <div className="row">
            <div className={isCurrentMobileDevice() ? null : 'col-6'}>
              <GenericCheckbox
                className="w-75"
                label={"* Dichiarazione attestante l'assenza delle circostanze indicate all'art. 33, ultimo comma, della legge 3/1976"}
                id={'dichiarazione'}
                isRequired
              />
            </div>
          </div>
          <div className="row">
            <div className={isCurrentMobileDevice() ? null : 'col-6'}>
              <GenericTextArea className="w-75" label={'Nota concessione nulla osta'} placeholder="Inserire nota" id={'nota'} />
            </div>
          </div>
        </Form>
      </div>
    )
  }
}

export declare interface GeneralFormConcediNullaOstaProps {
  messaggio?: any
  idIscrizione?: any
  idTrasferimentoOrdine?: any
  idSoggetto?: any
}
