import {getAnagrafeResourcesApi, getDefaultAxiosConfig} from 'src/app/utils/ManagerRestGateway'

/**
 * GET Elenco forme giuridiche
 * @returns
 *
 */
export async function getFormeGiuridiche() {
  try {
    const response = await getAnagrafeResourcesApi().msgaAnagrafeFoListaFormeGiuridicheGet(getDefaultAxiosConfig());
    //Manca il ResponseBuilder su Backend
    return response;
  } catch (err: any) {
    //TODO - Gestire meglio l'errore
    // let errorMessage: any = ErrorStore.getInstance().returnMessageError(err.message, undefined)
    console.error("Errore durante la chiamata per il recupero delle forme giuridiche. " + err);
    throw new Error("Errore durante la chiamata per il recupero delle forme giuridiche. " + err);

  }
}
