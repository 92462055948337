import RichiediNuovoProfilo from 'src/frontend/app/pages/RichiediNuovoProfilo'
import {PATH_TO_FO_ANAG_RICHIEDI_NUOVO_PROFILO, PATH_TO_FO_TODO, PATH_TO_HOME} from '../app/utils/RoutesConstants'
import {GROUP_CONAF, GROUP_FODAF, GROUP_ODAF, GROUP_STP, TokenStore} from '../keycloak/jwt/TokenStore'
import Todo from 'src/frontend/app/pages/todo/Todo'

/**
 * @description Converte un bigInt in input del tipo 0/1 in un boolean true/false
 * @param {*} bigInt
 * @returns boolean
 */
export function getBigIntToConvertToBoolean(bigInt) {
    if (bigInt === 0)
        return false
    else if (bigInt === 1)
        return true
}

/**
 * @description Converte un boolean true/false IN un bigInt del tipo 0/1
 * @param {*} boolean
 * @returns bigint
 */
export function getBooleanToConvertToBigInt(boolean) {
    if (boolean === false)
        return 0
    else if (boolean === true)
        return 1
    else return undefined
}

/**
 * @description Converte un bigInt in input del tipo 0/1 in una stringa Si/No
 * @param {*} bigInt
 * @returns string
 */
export function getBigIntToConvertToStringSiNo(bigInt) {
    if (bigInt === 0)
        return 'No'
    else if (bigInt === 1)
        return 'Si'
}

/**
 * @description Converte un bigInt in input del tipo 0/1/2 in una stringa
 * @param {*} bigInt
 * @returns string
 */
export function convertFlagLiberaProfPubblicaAmministrazioneToString(bigInt) {
    if (bigInt === 0)
        return 'Si, secondo l’ordinamento dell’ente di appartenenza'
    else if (bigInt === 1)
        return 'Si, alcune volte (con autorizzazione)'
    else if (bigInt === 2)
        return 'No, salvo specifiche autorizzazioni (annotato a margine)'
}

/**
 * @description Converte un bigInt in input del tipo 0/1/2 in una stringa
 * @param {*} bigInt
 * @returns string
 */
export function convertFlagLiberaProfPubblicaAmministrazioneToInt(string) {

    switch (string) {
        case 'Si, secondo l’ordinamento dell’ente di appartenenza':
            return 0;
        case 'Si, alcune volte (con autorizzazione)':
            return 1;
        case 'No, salvo specifiche autorizzazioni (annotato a margine)':
            return 2;

    }

}

/**
 * @description Questo metodo ritorna l'estenzione del file
 * @param {string ! undefined} path
 * @returns string
 */
export function getFileNameFromPath(path, symbol) {
    if (path !== "" && path !== undefined)
        return path.split(symbol).pop();
    return ""
}

/**
 * @description Controlla se il valore inserito è una email
 * @param {*} obj
 * @returns boolean
 */
export function isEmail(obj) {
    if (typeof obj !== "undefined") {
        let lastAtPos = obj.lastIndexOf("@");
        let lastDotPos = obj.lastIndexOf(".");

        if (
            !(
                lastAtPos < lastDotPos &&
                lastAtPos > 0 &&
                obj.indexOf("@@") === -1 &&
                lastDotPos > 2 &&
                obj.length - lastDotPos > 2
            )
        ) {
            return false;
        } else return true
    }
    else return false
}
/**
 * @description Controlla se il valore inserito è un formato ore:minuti con il campo ore variabile da 1 a 3 cifre
 * @param {*} string
 * @returns boolean
 */
export function isTimeCFP(string) {
        var controlloTimeCFP = /^(\d{1,3}:(?:[0-5][0-9]))$/
        if (string?.match(controlloTimeCFP) )
            return true
        else
            return false
}
/**
 * @description Controlla se il valore inserito è vuoto
 * @param {*} obj
 * @returns boolean
 */
export function isEmpty(obj) {
    return obj.value === ""
}

export function isEmptyValue(value){
    return value===undefined || value===null || value==="" || value===false;
}

export function isEmptyOrZero(value) {
    return value === "" || value === 0 || value === "0"
}


/**
 * @description Controlla se il valore inserito è vuoto
 * @param {*} obj
 * @returns boolean
 */
export function isEmptyCheckbox(obj) {
    return obj.checked === false
}

/**
 * @description Controlla se il valore inserito contiene solo numeri
 * @param {*} obj
 * @returns boolean
 */
export function isOnlyNumbers(obj) {
    if (typeof obj !== "undefined") {
        if (!obj.match(/^[0-9]+$/)) {
            return false;
        }
        else return true
    }
    else return false
}

/**
 * @description Controlla se il valore inserito contiene due decimali dopo la virgola
 * @param {*} obj
 * @returns boolean
 */
export function isDecimalWithComma(obj) {
    let value = obj.toString();
    if (typeof value !== "undefined") {
        if (value.match(/^[0-9]+((?!\2)[,]\d\d)?$/)) {
            return true;
        }
        else return false
    }
    else return false
}

/**
 * @description Controlla se il valore inserito contiene due decimali dopo il punto
 * @param {*} obj
 * @returns boolean
 */
export function isDecimalWithPoint(obj) {
    let value = obj.toString();
    if (typeof obj !== "undefined") {
        if (value.match(/^[0-9]+((?!\2)[.]\d\d)?$/)) {
            return true;
        }
        else return false
    }
    else return false
}

/**
 * @description Questo metodo consente di tagliare la stringa fino ad una lunghezza desiderata
 * @param {string | undefined} text
 * @param {number} length
 * @returns string
 */
export function maxStringLength(text, length) {
    var cutText = ""
    if (text !== undefined) {
        if (text?.length > length)
            cutText = text?.substring(0, length) + "..."
        else
            cutText = text?.substring(0, length)
    }
    return cutText
}

/**
 * @description Questo metodo consente di validare il codice fiscale
 * @param {string} fiscalCode
 * @returns boolean
 */
export function fiscalCodePhysicalPersonValidation(fiscalCode) {
    var fiscalCodeRegex = "^([A-Za-z]{6}[0-9lmnpqrstuvLMNPQRSTUV]{2}[abcdehlmprstABCDEHLMPRST]{1}[0-9lmnpqrstuvLMNPQRSTUV]{2}[A-Za-z]{1}[0-9lmnpqrstuvLMNPQRSTUV]{3}[A-Za-z]{1})$"
    if (fiscalCode !== "" && fiscalCode.match(fiscalCodeRegex) && fiscalCode.length <= 16) {
        return true
    }
    else {
        return false
    }
}

/**
 * @description Questo metodo consente di validare il codice fiscale
 * @param {string} fiscalCode
 * @returns boolean
 */
export function fiscalCodeValidation(fiscalCode) {
    var fiscalCodeRegex = "^([A-Za-z]{6}[0-9lmnpqrstuvLMNPQRSTUV]{2}[abcdehlmprstABCDEHLMPRST]{1}[0-9lmnpqrstuvLMNPQRSTUV]{2}[A-Za-z]{1}[0-9lmnpqrstuvLMNPQRSTUV]{3}[A-Za-z]{1})$|([0-9]{11})$"
    if (fiscalCode !== "" && fiscalCode.match(fiscalCodeRegex) && fiscalCode.length <= 16) {
        return true
    }
    else {
        return false
    }
}

/**
 * @description Questo metodo consente di validare la partita iva
 * @param {string} partitaIva
 * @returns boolean
 */
export function partitaIvaValidation(partitaIva) {
    var partitaIvaRegex = "^([A-Za-z]{6}[0-9lmnpqrstuvLMNPQRSTUV]{2}[abcdehlmprstABCDEHLMPRST]{1}[0-9lmnpqrstuvLMNPQRSTUV]{2}[A-Za-z]{1}[0-9lmnpqrstuvLMNPQRSTUV]{3}[A-Za-z]{1})$|([0-9]{11})$"
    if (partitaIva !== "" && partitaIva.match(partitaIvaRegex) && partitaIva.length <= 16)
        return true
    else
        return false
}

/**
 * @description Questo metodo consente di validare il numero di telefono
 * @param {string} telefono
 * @returns boolean
 */
export function telefonoValidation(telefono) {
    var telefonoRegex = /^(?!-)(\+\d{2})?[-. ]?(\d{9}|\d{10}|\d{11}|\d{12}|\d{13}|\d{14}|\d{15})$/;
    if (telefono !== "" && telefono.match(telefonoRegex) && telefono.length < 16)
        return true
    else
        return false
}

/**
 * @description Questo metodo consente di validare il numero di telefono
 * @param {string} telefono
 * @returns boolean
 */
export function faxValidation(telefono) {
    var telefonoRegex = /^(?!-)(\+\d{2})?[-. ]?(\d{9}|\d{10}|\d{11}|\d{12}|\d{13}|\d{14}|\d{15})$/;
    if (((!telefono || telefono === "") || telefono.match(telefonoRegex)) && telefono.length < 16)
        return true
    else
        return false
}

/**
 * @description Questo metodo consente di validare il cap
 * @param {string} cap
 * @returns boolean
 */
export function capValidation(cap) {
    var capRegex = "^[0-9]{5}$"
    if (cap !== "" && cap.match(capRegex))
        return true
    else
        return false
}


/**
 * @description Questo metodo consente di validare il codice iban
 * @param {string} iban
 * @returns boolean
 */
export function ibanValidation(iban) {
    var ibanRegex = "^[0-9A-Za-z]{25,35}$"
    if (iban !== "" && iban.match(ibanRegex))
        return true
    else
        return false
}

/**
 * @description Questo metodo consente di validare l'obbligatorietà dei campi.
 * Accetta in input la lista di campi obbligatori sottoforma di oggetti:
 * - "label": la label del campo
 * - "value": il valore del campo
 * Si costruice un messaggio di errore contenente la lista dei campi richiesti non inseriti dall'utente
 * @param inputList
 * @returns error
 */
export function inputRequiredValidation(inputList) {
    var error = ''
    var missingFields = [] //lista che conterrà tutti i campi richiesti mancanti

    if (inputList.length > 0) {
        inputList.map((input) => {
            //CASO 1: Gestione errori tramite array "errors"
            //controlla se il campo obbligatorio è stato inserito o meno dall'utente (error = true)
            if (input !== null && input.error !== undefined) {
                if (input?.error === true) {
                    //inserisce nella lista missingFields il campo mancante
                    missingFields.push(input?.label)
                    return missingFields;
                }
            }

            //CASO 2: Validazione semplice sul singolo campo
            //controlla se il campo obbligatorio è stato inserito o meno dall'utente
            else if (input != null && (input?.value === "" || input?.value === undefined || input?.value === null)) {
                //inserisce nella lista missingFields il campo mancante
                missingFields.push(input?.label)
                return missingFields;
            }
        })
    }

    //Build error message
    if (missingFields.length > 0)
        return error = { messaggio: "Compila tutti i campi obbligatori: " + missingFields.toString().replaceAll(',', ', ').replaceAll(' *', '').replaceAll('*', '').replaceAll('¹', ''), type: "danger" }
    else
        return error
}

/**
 * @description Questo metodo restituisce un valore Si/No in number 1/0
 * @param {string} stringValue
 * @returns number
 */
export function conversioneSiNoToInt(stringValue) {
    switch (stringValue) {
        case "Si":
        case "Sì":
        case "si":
        case "sì":
        case "1":
        case 1:
        case true:
        case "true":
            return 1;

        default:
            return 0;
    }

}

/**
 * @description Questo metodo restituisce un valore Si/No in number 1/0
 * @param {string} stringValue
 * @returns number
 */
export function conversioneIntToSiNo(value) {

    switch (value) {
        case "1":
        case 1:
        case true:
            return "Si";

        default:
            return "No";
    }


}

/**
 * @description Questo metodo restituisce l'elenco delle tipologie iscrizine decodificate
 * @param array
 * @returns array
 */
export function conversioneTipologie(array) {

    let newArray = [];
    array.map(element => {
        switch (element) {
            case GROUP_ODAF: {
                newArray.push("operatore_ordine_territoriale");
                break;
            }
            case GROUP_FODAF: {
                newArray.push("operatore_enti_formazione");
                break;
            }
            case GROUP_CONAF: {
                newArray.push("operatore_conaf_nazionali");
                break;
            }
            case GROUP_STP: {
                newArray.push("operatore_stp");
                break;
            }
            default: newArray.push("altro");
        }

    })
    return newArray;
}

/**
* @description Questo metodo restituisce la descrizione dello stato dell'iscrizione dato in input l'id
* @param listaStati
* @param idStato
* @returns decoDescrizione
*/
export function getDescrizioneStatoIscrizione(listaStati, idStato) {
    var descrizioneStato
    listaStati?.map((stato) => {
        if (stato.idStato === idStato)
            descrizioneStato = stato.descrizioneStato.toUpperCase()
    })

    return descrizioneStato
}

/**
* @description Questo metodo restituisce si o no in base alla valorizzazione del flag: se 0 -> Si | se 1: NO
* @param flag
* @returns string
*/
export function convertFlagSiNo(flag) {
    if (flag === 0)
        return "No"
    else
        return "Si"
}


/**
 * @description Questo metodo restituisce gli idStato selezionati nella ricerca
 * @param searchedParam
 * @param listaStati
 * @returns array di idStato
*/
export function convertStatoToCodiceStato(searchedParam, listaStati) {
    if (listaStati) {
        var findStato = listaStati?.filter(statoDB => statoDB.descrizioneStato?.toUpperCase().includes(searchedParam?.toUpperCase()))
        var idStatoList = findStato?.map(stato => stato.idStato).toString()

        if (idStatoList?.length === 0)
            return null
        return idStatoList;
    }
    return null
}

/**
* @description Questo metodo restituisce PF se persona fisica e PG se persona giuridica
* @param tipoAnagrafe
* @returns string
*/
export function tipoAnagrafeEsteso(tipoAnagrafe) {
    if (tipoAnagrafe !== undefined && tipoAnagrafe !== "") {
        if ("persona fisica".includes(tipoAnagrafe.toLowerCase()))
            return "PF"
        else if ("persona giuridica".includes(tipoAnagrafe.toLowerCase()))
            return "PG"
        else
            return tipoAnagrafe
    } else
        return ""
}

/**
* @description Questo metodo restituisce PF se persona fisica e SC se persona giuridica - per le polizze le persone giuridiche sono censite come SC e non PG
* @param tipoAnagrafe
* @returns string
*/
export function tipoAnagrafeEstesoPerPolizze(tipoAnagrafe) {
    if (tipoAnagrafe !== undefined && tipoAnagrafe !== "") {
        if ("persona fisica".includes(tipoAnagrafe.toLowerCase()))
            return "PF"
        else if ("persona giuridica".includes(tipoAnagrafe.toLowerCase()))
            return "SC"
        else
            return tipoAnagrafe
    } else
        return ""
}

/**
 * Questo metodo restituisce la lista addatta alla Generic table senza duplicati
 * @param {*} index
 * @param {*} array
 * @param {*} primaryOrSecondaryID Questo parametro serve per decidere se si vogliono selezionare i dati primari (1) o secondari (2)
 * @returns array
 */
export function removeOptionDuplicatesRows(index, array, primaryOrSecondaryID) {
    if (primaryOrSecondaryID === 1) {
        return array.map(row => row.data[index]).filter((fieldValue, id, duplicateColumnArray) => duplicateColumnArray.indexOf(fieldValue) === id)
    }
    else {
        return array.map((row) => row.nestedComponent?.map(nestedComponent => nestedComponent.value)[index]).filter((fieldValue, id, duplicateColumnArray) => duplicateColumnArray.indexOf(fieldValue !== undefined ? fieldValue : "") === id)
    }
}

/**
 * Ritorna la prima lettera in lowerCase
 * @param {string} text
 * @returns
 */
export function capitalizeFirstLetter(text) {
    return text.charAt(0).toLowerCase() + text.slice(1);
}

/**
 * Questo metodo ritorna una stringa spezzettata con ritorno
 * a capo in base alla lunghezza massima passata in input
 * @param {string} label
 * @param {number | undefined} maxLength
 * @returns string
 */
export function breakLineIfLabelTooLong(label, maxLength) {
    if (maxLength !== undefined && label !== undefined) {
        var regex = new RegExp('.{1,' + maxLength + '}', "g")
        label.match(regex).toString().replaceAll(",", "\n")
        return label
    }
    return label
}

export function acceptedFileExtension(path, acceptedExtensions) {
    var fileExtension = getFileNameFromPath(path, ".")
    if (!acceptedExtensions?.includes(fileExtension?.toLocaleLowerCase())) {
        return false
    }
    return true
}

/**
* Questa funzione restituisce la descrizione dello stato di un'iscrizione dato in input il codice
* @param idStato
* @returns string
*/
export function getStatoById(idStato) {
    switch (idStato) {
        case 1:
            return 'Compilazione'
        case 2:
            return 'Valutazione'
        case 3:
            return 'Richiesta Integrazioni'
        case 4:
            return 'Iscritto'
        case 5:
            return 'Cancellato'
        case 6:
            return 'Rigettato'
        case 7:
            return 'Sospeso'
        case 8:
            return 'Radiato'
        default:
            return ''
    }
}

/**
 * Trasforma le stringhe in camel case
 * @param {string} string
 * @returns
 */
export function toCamelCase(sortDirection) {
    var result = sortDirection?.replace(/[^a-zA-Z0-9]/g, ' ');
    var words = result?.split(' ');
    var camelString = words?.map((word, index) => {
        if (index === 0) {
            return word?.toLowerCase();
        }
        return word?.charAt(0).toUpperCase() + word?.slice(1).toLowerCase();
    }).join('');
    return camelString;
}

/**
 * Rende la prima lettera minuscola
 * @param {string} str
 * @returns
 */
export function toLowerCaseFirstLetter(str) {
    return str.replace(/^[A-Z]/, match => match.toLowerCase());
  }

/**
 * Rimuove la prima parola e trasforma la prima lettera in minuscola
 * @param {string} str
 * @param {string} strToEdit
 * @returns
 */
export function replaceStartWordAndLowerCaseFirstLetter(str, strToEdit) {
    var replacedString = ""
    if(strToEdit?.startsWith(str)){
        replacedString = strToEdit?.slice(str?.length)
        return toLowerCaseFirstLetter(replacedString)
    }
    return toLowerCaseFirstLetter(strToEdit)
  }

export function goTo(redirectLink) {
    window.location.href = redirectLink;
}

export function goToWithParamsinLocalStorage(redirectLink, param) {
    localStorage.setItem("param", param);
    window.location.href = redirectLink;
}

/**
 * Restituisce la home page, Todo per gli autenticati.
 * @returns
 */
export function getHome() {
    if (TokenStore.getInstance().getKeycloak() && TokenStore.getInstance().getKeycloak() !== "") {
        if (TokenStore.getInstance().hasAtLeastOneProfile())
            return PATH_TO_FO_TODO;
        else
            return PATH_TO_FO_ANAG_RICHIEDI_NUOVO_PROFILO;
    } else {
        return PATH_TO_HOME
    }
}

/**
 * Restituisce il componente per la pagina home, ossia Todo.
 * @returns
 */
export function homeComponent() {
    if (TokenStore.getInstance().hasAtLeastOneProfile())
        return Todo;
    else
        return RichiediNuovoProfilo;
}

/**
 * Questo servizio pulisce una lista di allegati.
 * Solitamente è usato prima di inviare la lista al server.
 * Quando un file è stato caricato e cancellato nel portale
 * ma non è ancora stato inviato al server deve essere rimosso dalla lista
 * in modo da non caricarlo inutilmente.
 * @param {*} allegati
 */
export function cleanUploadedFilesBeforeSend(allegati) {
    //si dichiara un nuovo array che conterrà la lista di allegati non cancellati e che possiedono l'idAttachment
    var allegati_new = []

    if (allegati === undefined || allegati === null || allegati.length === 0)
        return allegati;
    allegati.map((file, id) => {
        if (!(file.cancellato === true && (file.idAttachment === undefined || file.idAttachment === null))) {
            //inserisco in allegati_new il file non cancellato che possiede l'idAttachment
            allegati_new.push(allegati[id])
        }
    })
    return allegati_new;
}

/**
 * Setta i dati se siamo in modifica o visualizzazione
 * Serve ad evitare di inserire condizioni inline all'interno degli attributi dei componenti
 * @param {*} value
 * @param {boolean | undefined} operation Inserire la condizione se modifica o visualizza
 */
export function returnDataIsModOrVis(value, operation) {
    if (operation)
        return value
    else
        return undefined
}

/**
 * Controlla che l'anno non sia superiore alle 4 cifre
 * @param {*} anno
 * @returns
 */
export function checkAnno(anno) {
    var controlloAnno = /^([0-9]{4})$/
    if (anno.match(controlloAnno))
        return true
    else
        return false
}

/**
 * Controlla la correttezza di un importo
 * @param {*} valuta
 * @returns
 */
export function checkValuta(valuta) {
    var controlloValuta = /^(?:\d+|\d{1,3}(?:,\d{3})+)?(?:\,\d+)?$/
    if (valuta.match(controlloValuta))
        return true
    else
        return false
}

/**
 * Controllo errori per ogni tipo di campo
 * @param {*} error
 * @param {*} errorRef
 */
export const handleErrors = errorRef => (error) => {
    const { id, hasError } = error || {}
    const index = errorRef.current.findIndex((e) => e.id === id)
    const hasErrorAndIsNotPresent = hasError && index === -1
    const hasNoErrorAndIsPresent = !hasError && index > -1
    const hasErrorAndIsPresent = hasError && index > -1

    if (hasErrorAndIsNotPresent) errorRef.current.push(error)
    if (hasNoErrorAndIsPresent) errorRef.current.splice(index, 1)
    if (hasErrorAndIsPresent) errorRef.current[index] = error
}

/**
 * Costruisce una copia di un oggetto JSON dato in input.
 * @param {*} jsonObject
 * @returns
 */
export function deepJsonCopy(jsonObject) {
    return JSON.parse(JSON.stringify(jsonObject));
}

export function generateUUID() {
    return ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c =>
        (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
    );
}

/**
 * Recursive function that checks if a value is a boolean and changes it to 1 or 0
 * @param {*} value
 * @returns
 */
export const convertBooleans = value => {
    if (typeof value === 'boolean') {
        return value ? 1 : 0
    } else if (typeof value === 'object') {
        for (var key in value) {
        value[key] = convertBooleans(value[key])
        }
    }
    return value
}

/**
 * Converte un array in un oggetto DTO
 * @param {*} formData
 * @returns
 */
export const convertArrayToObject = (formData) => {
    let dto = {}
    formData.forEach((item) => {
        dto[`${item.id}`] = item;
    });

    return dto;
}


/**
 * Returns the value at the specified path in the given object.
 * @param {Object} obj - The object to retrieve the value from.
 * @param {string} path - A string representing the path to the desired value. The keys should be separated by '.'.
 * @returns {*} The value at the specified path in the given object.
 */
export const getValueAtPath = (obj={}, path="") => {
  const keys = path?.split('.')
  return keys?.reduce((acc, key) => acc?.[key], obj)
}

export const removeDuplicatesFromArray = (array) => {
    return array?.filter((value, index) => array?.indexOf(value) === index)
}

/**
 * Ritorna il record con la data maggiore in un array di date
 * @param {*} array
 * @returns
 */
export const returnMaxDateFromArray = (array) =>{
    if(array && array.length !== 0){
        return new Date(Math.max(...array));
    }
    return null
}

/**
 * Compara due date
 * @param {*} date1
 * @param {*} date2
 * @param {*} operator "eq" -> equal | "grT" -> greater | "mrT" -> minor
 * @returns
 */
export const compareDate = (date1, date2, operator) =>{
    if(operator === "eq"){
        return new Date(date1).getTime() === new Date(date2).getTime()
    }

    if(operator === "grT"){
        return new Date(date1).getTime() > new Date(date2).getTime()
    }

    if(operator === "mrT"){
        return new Date(date1).getTime() < new Date(date2).getTime()
    }
}

/**
 * Restituisce se presenti, i parametri dell'url in un oggetto chiave valore
 * @param {string} url
 * @returns
 */
export const convertUrlParamsToObject = (url) => {
    if (url) {
        if (url.includes("%20")) {
            url = url.replaceAll("%20", " ")
        }
        const params = url.split("&")
        const result = {}

        for (const param of params) {
            const [key, value] = param.split("=");
            result[key] = value
        }
        return result
    }
    return null
}

/**
 * Ritorna un oggetto trasformato in un array chiave valore
 * @param {*} obj
 * @returns
 */
export const convertObjectToArray = (obj) => {
    if (obj || obj !== null) {
        return Object.entries(obj).map(([key, value]) => ({ [key]: value }))
    }
    return []
}

/**
 * Converte una stringa camel case in parole divise
 * @param {string} str
 * @returns
 */
export const camelCaseToWord = (str) => {
    if(str && str !== ""){
        return str.replace(/([A-Z])/g, ' $1').replace(/^./, function (str) { return str.toUpperCase(); })
    }
    return ""
}

/**
 * Ritorna si o no in base allo stato in input, decretando quindi se la richiesta di trasferimento è in corso o meno
 * @param {*} stato
 * @returns
 */
export const isRichiestaTrasferimentoOrdineInCorso = (stato) => {
    const SI = "Si"
    const NO = "No"

    if (stato && stato !== null) {
        if(stato  === 1)
            return SI;
    }
    return NO
}

/**
 * Ritorna si o no in base allo stato in input, decretando quindi se la richiesta di trasferimento è in corso o meno
 * @param {*} stato
 * @returns
 */
export const isRichiestaCambioSezioneInCorso = (stato) => {
    const SI = "Si"
    const NO = "No"

    if (stato && stato !== null) {
        if(stato  === 1)
            return SI;
    }
    return NO
}

/**
 * Ritorna una lista di stati in base al flag in input in input, decretando quindi se la richiesta di trasferimento è in corso o meno
 * @param {*} stato
 * @returns
 */
export const statoRichiestaTrasferimentoOrdineBySiNo = (flag) => {
    const SI = "Si"
    const NO = "No"

    if (flag && flag !== null && flag !== "") {
        if (flag === SI) {
            return [3]
        }

        if (flag === NO) {
            return [2, 4, 5,-1]
        }

        if (flag === "-") {
            return null
        }

    }
    return undefined
}

/**
 * Ritorna una lista di stati in base al flag in input in input, decretando quindi se la richiesta di trasferimento è in corso o meno
 * @param {*} stato
 * @returns
 */
export const statoCambioSezioneBySiNo = (flag) => {
    const NO = "No"
    const SI = "Si"

    if (flag && flag !== null && flag !== "") {
        if (flag === NO) {
            return [2, 3, -1]
        }

        if (flag === SI) {
            return [1]
        }

        if (flag === "-") {
            return null
        }
    }
    return undefined
}

export const convertSiNoIscrizioneEpapToInt = (flag) => {
    const SI = "Si"
    if (flag && flag !== null && flag !== "") {
        if(flag === SI)
            return 1
        else
            return 0
    }
    return null
}

/**
 * Converte un formato localdate in un formato dd/mm/yyyy
 * @param {*} localDate
 * @returns formattedDate
 */
export const convertDataFormat = (localDate) => {
    if(localDate !== null)
    {
        // Divide la data in anno, mese e giorno utilizzando il carattere "-"
        const [year, month, day] = localDate.split("-");

        // Costruisce una nuova data nel formato "dd/mm/yyyy"
        const formattedDate = `${day}/${month}/${year}`;
        return formattedDate;
    }

    else
        return null
}
