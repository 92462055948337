/* eslint-disable react/no-direct-mutation-state */
import * as React from 'react'
import AccessoProfessione from './form/AccessoProfessione'
import DatiResidenziali from './form/DatiResidenziali'
import SedeLegale from './form/SedeLegale'
import Professione from './form/Professione'
import IscrizioneEPAP from './form/IscrizioneEPAP'
import Documenti from './form/Documenti'
import Invio from './form/Invio'
import {
    compilaIscrizioneOrdine,
    getIntegrazioniRichieste,
    inviaCompilazione,
    recuperoIscrizioneInattivaByCf,
    recuperoIscrizioneInCompilazioneByCf
} from 'src/processes/ProcessoIscrizione'
import GenericSpinner from 'src/backoffice/app/components/GenericSpinner'
import {UserModel} from 'src/model/User'
import GenericAlert from '../../components/GenericAlert'
import GenericTitle from '../../components/GenericTitle'
import DatiAnagrafici from './form/DatiAnagrafici'
import GenericBreadCrumb from 'src/frontend/app/components/GenericBreadCrumb'
import GenericTab from '../../components/GenericTab'
import {
    cleanUploadedFilesBeforeSend,
    conversioneIntToSiNo,
    convertFlagLiberaProfPubblicaAmministrazioneToString,
    getBigIntToConvertToBoolean,
    getHome,
    getStatoById,
    inputRequiredValidation
} from 'src/utilities/utility'
import {TokenStore} from 'src/keycloak/jwt/TokenStore'
import GenericChips from '../../components/GenericChips'
import {
    PATH_TO_FO_ANAG_ISCRIZIONE_ORDINE,
    PATH_TO_FO_ANAG_ISCRIZIONE_ORDINE_MODIFICA,
    PATH_TO_FO_ANAG_ISCRIZIONE_ORDINE_VISUALIZZA,
    PATH_TO_FO_ANAG_RICHIEDI_NUOVO_PROFILO
} from 'src/app/utils/RoutesConstants'
import DatiIscrizione from './form/DatiIscrizione2'
import {isCurrentMobileDevice} from 'src/utilities/deviceUtility'

const isMobileDivice = isCurrentMobileDevice()

var title = ''
var breadcrumbOptions: any = []
var linkTab = [
  { id: 1, label: 'Dati anagrafici' },
  { id: 2, label: 'Residenza e contatti' },
  { id: 3, label: 'Sede legale' },
  { id: 4, label: 'Dati accesso professione' },
  { id: 5, label: 'Professione' },
  { id: 6, label: 'Iscrizione EPAP' },
]

const legenda = [
  { title: 'Legenda', Size: 'h2' },
  { title: '* Campo obbligatorio', Size: 'p' },
  { title: '** Campo non modificabile compilato automaticamente', Size: 'p' },
]

const listLegenda = [
  <div key="legendaIscrizioneOrdine" className={!isMobileDivice ? 'card' : null}>
    {legenda.map((title, i, Size) => {
      return <GenericTitle key={i} title={legenda[i].title} Size={legenda[i].Size} />
    })}
  </div>,
]

class IscrizioneOrdine extends React.Component<any, any> {
  constructor(props) {
    super(props)
    this.state = {
      activeTab: 0,
      idIscrizione: props.match.params.idProcessoIscrizione,
      message: '',
      isSubmitted: false,
      typeMessage: 'default',
      isLoaded: false,
      fields: {},
      processoIscrizione: {},
      errors: [],
      tipologiaIscrizione: 'iscritto_all_ordine',
      provinciaNascita: '',
      provinciaNascitaSigla: '',
      provinciaResidenza: '',
      provinciaResidenzaSigla: '',
      ordineTerritorialeCompetente: '',
      ordineTerritorialeCompetenteSigla: '',
      isRichiestaIntegrazioni: false,
      isVisualizza: props.isVisualizza,
    }
  }

  async componentDidMount(): Promise<void> {
    const tipoAnagrafe = 'PF'
    const tipologiaIscrizione = 'iscritto_all_ordine'

    await recuperoIscrizioneInCompilazioneByCf(TokenStore.getInstance().getDecodedTokenJWT().fiscalNumber, tipologiaIscrizione, tipoAnagrafe)
      .then(async (response: any) => {
        var iscrizioneResponse = response
        if (response === undefined || (response.data && response?.data?.httpStatus !== 200)) {
          if (response === undefined || response?.data?.httpStatus === 404) {
            await recuperoIscrizioneInattivaByCf(TokenStore.getInstance().getDecodedTokenJWT().fiscalNumber, tipologiaIscrizione, tipoAnagrafe).then(
              (response) => {
                if (response && response.data && response.data.httpStatus !== 200) {
                  throw new Error(response.data.error)
                }
                iscrizioneResponse = response
              }
            )
          } else {
            throw new Error(response.data.error)
          }
        }

        var iscrizione = iscrizioneResponse.data.returnedObject
        var iot = iscrizioneResponse.data.returnedObject.iotDTO
        var statoIscrizione = iot.statoIscrizione
        this.setState({ statoIscrizione: statoIscrizione })

        if (this.props.isVisualizza) {
          title = 'Visualizza dati personali: '
          breadcrumbOptions.push({ label: 'Richiedi nuovo profilo', link: PATH_TO_FO_ANAG_RICHIEDI_NUOVO_PROFILO })
          breadcrumbOptions.push({ label: 'Visualizza dati personali', link: PATH_TO_FO_ANAG_ISCRIZIONE_ORDINE_VISUALIZZA })
          breadcrumbOptions.push({ label: 'Dati Anagrafici', link: PATH_TO_FO_ANAG_ISCRIZIONE_ORDINE_VISUALIZZA })
          linkTab.push({ id: 7, label: 'Dati iscrizione' })
          linkTab.push({ id: 8, label: 'File' })
        } else {
          linkTab.push({ id: 7, label: 'Documenti' })
          linkTab.push({ id: 8, label: 'Invio' })

          // Se sono in compilazione (1) gestisco la pagina come Iscrizione
          if (statoIscrizione === 1) {
            title = "Iscrizione all'ordine: "
            breadcrumbOptions.push({ label: 'Richiedi nuovo profilo', link: PATH_TO_FO_ANAG_RICHIEDI_NUOVO_PROFILO })
            breadcrumbOptions.push({ label: "Iscrizione all'ordine", link: PATH_TO_FO_ANAG_ISCRIZIONE_ORDINE })
            breadcrumbOptions.push({ label: 'Dati anagrafici', link: PATH_TO_FO_ANAG_ISCRIZIONE_ORDINE })
          }

          // Altrimenti gestisco la pagina come Modifica
          else {
            title = 'Modifica dati personali: '
            breadcrumbOptions.push({ label: 'Richiedi nuovo profilo', link: PATH_TO_FO_ANAG_RICHIEDI_NUOVO_PROFILO })
            breadcrumbOptions.push({ label: 'Modifica dati personali', link: PATH_TO_FO_ANAG_ISCRIZIONE_ORDINE_MODIFICA })
            breadcrumbOptions.push({ label: 'Dati anagrafici', link: PATH_TO_FO_ANAG_ISCRIZIONE_ORDINE_MODIFICA })
          }
        }

        // Controllo se lo stato dell'iscrizione è in "richiesta integrazioni"
        if (statoIscrizione === 3) {
          //recupera il messaggio delle integrazioni richieste dall'operatore
          getIntegrazioniRichieste(iot.idIscrizione)
            .then((response) => {
              if (response && response.data && response.data.httpStatus !== 200) {
                throw new Error(response.data.error)
              }
              this.setState({ isRichiestaIntegrazioni: true, integrazioni: response.data.returnedObject.testoRichiestaIntegrazione })
            })
            .catch((err) => {
              console.error(err)
              this.setState({ message: err.message, typeMessage: 'danger', isLoaded: true })
            })
        }

        if (iscrizione.provinciaResidenza && iscrizione.provinciaResidenza)
          this.setState({
            provinciaResidenza: iscrizione.provinciaResidenza.denominazioneUnitaTerritorialeSovracomunale,
            provinciaResidenzaSigla: iscrizione.soggettoDTO.codiceProvinciaResidenza,
          })
        if (iscrizione.provinciaNascita && iscrizione.provinciaResidenza)
          this.setState({
            provinciaNascita: iscrizione.provinciaNascita.denominazioneUnitaTerritorialeSovracomunale,
            provinciaNascitaSigla: iscrizione.soggettoDTO.codiceProvinciaNascita,
          })
        if (iscrizione.provinciaSedeLegale && iscrizione.provinciaResidenza)
          this.setState({
            provinciaSedeLegale: iscrizione.provinciaSedeLegale.denominazioneUnitaTerritorialeSovracomunale,
            provinciaSedeLegaleSigla: iscrizione.sedeLegale ? iscrizione.sedeLegale.slProvinciaStudio : '-',
          })
        if (iscrizione.ordineTerritorialeCompetente && iscrizione.provinciaResidenza)
          this.setState({
            ordineTerritorialeCompetente: iscrizione.ordineTerritorialeCompetente.denominazioneUnitaTerritorialeSovracomunale,
            ordineTerritorialeCompetenteSigla: iot.ordineTerritorialeCompetente,
          })
        if (iscrizione.sezione) this.setState({ sezione: iscrizione.sezione.descrizione, codiceSezione: iot.codiceSezioneRichiesta })
        if (iscrizione.titoloRichiesto)
          this.setState({ titoloRichiesto: iscrizione.titoloRichiesto.titoloEsteso, codiceTitoloRichiesto: iot.codiceTitoloRichiesto })
        if (iscrizione.classeLaurea)
          this.setState({ classeLaurea: iscrizione.classeLaurea.descrizione, codiceClasseLaurea: iscrizione.classeLaurea.codiceClasseLaurea })
        this.setState({ fields: FillUserModel(iscrizioneResponse), isLoaded: true })
        this.setState({ activeTab: this.state.activeTab + 1 })
        this.setState({ message: '', typeMessage: '' })
      })
      .catch((err) => {
        console.error(err)
        this.setState({ message: err.message, typeMessage: 'danger', isLoaded: true })
      })
  }

  //Procedi allo step successivo
  nextStep = async () => {
    var tipologiaIscrizione = 'iscritto_all_ordine'
    var tipoAnagrafe = 'PF'

    if (this.state.activeTab + 1 <= linkTab.length) {
      await recuperoIscrizioneInCompilazioneByCf(TokenStore.getInstance().getDecodedTokenJWT().fiscalNumber, tipologiaIscrizione, tipoAnagrafe)
        .then(async (response: any) => {
          var iscrizioneResponse = response
          if (response && response.data && response.data.httpStatus !== 200) {
            if (response.data.httpStatus === 404) {
              await recuperoIscrizioneInattivaByCf(TokenStore.getInstance().getDecodedTokenJWT().fiscalNumber, tipologiaIscrizione, tipoAnagrafe).then(
                (response) => {
                  if (response && response.data && response.data.httpStatus !== 200) {
                    throw new Error(response.data.error)
                  }
                  iscrizioneResponse = response
                }
              )
            } else {
              throw new Error(response.data.error)
            }
          }

          // Pulisce la lista dei file
          this.state.fields.uploadFiles = cleanUploadedFilesBeforeSend(this.state.fields?.uploadFiles)

          if (this.state.activeTab === 7 && !this.props.isVisualizza && this.state.statoIscrizione !== 4) {
            validaPagamenti(this.state.fields.uploadFiles)
          }

          if (
            this.state.activeTab === 3 &&
            !this.props.isVisualizza &&
            this.state.fields.provinciaResidenza.label === 'Stato Estero' &&
            this.state.fields.provinciaStudio.label === 'Stato Estero'
          ) {
            throw new Error('È necessario inserire almeno una provincia italiana tra la residenza e la sede legale')
          }

          if (!this.props.isVisualizza) {
            compilaIscrizioneOrdine(
              this.state.activeTab,
              this.state.fields,
              iscrizioneResponse.data.returnedObject.iotDTO.idIscrizione,
              iscrizioneResponse.data.returnedObject.iotDTO.idSoggetto
            )
              .then(async (response2: any) => {
                if (response2 && response2.data && response2.data.httpStatus !== 200) {
                  throw new Error(response2.data.error)
                }

                this.state.fields?.uploadFiles?.map(
                  (uploadFile, id) => (uploadFile['idAttachment'] = response2.data.returnedObject.allegatoDTOS[id].idAttachment)
                )
                breadcrumbOptions.push({
                  label: linkTab[this.state.activeTab].label,
                  link: this.props.isVisualizza ? PATH_TO_FO_ANAG_ISCRIZIONE_ORDINE_VISUALIZZA : PATH_TO_FO_ANAG_ISCRIZIONE_ORDINE_MODIFICA,
                })

                if (this.state.fields && this.state.fields.sedeLegaleStudio) {
                  this.state.fields.sedeLegaleStudio = {
                    label: conversioneIntToSiNo(
                      response2.data.returnedObject.compilazioneIscrittoDTO
                        ? response2.data.returnedObject.compilazioneIscrittoDTO.flagSedeLegaleDiversoDaProvinciaResidenza
                        : null
                    ),
                    value: response2.data.returnedObject.compilazioneIscrittoDTO
                      ? response2.data.returnedObject.compilazioneIscrittoDTO.flagSedeLegaleDiversoDaProvinciaResidenza
                      : null,
                  }
                }
                this.setState({ activeTab: this.state.activeTab + 1 })
              })
              .catch((error: Error) => {
                this.setState({ message: "C'è stato un errore durante il recupero dei dati: " + error.message, typeMessage: 'danger' })
              })
          } else {
            breadcrumbOptions.push({
              label: linkTab[this.state.activeTab].label,
              link: this.props.isVisualizza ? PATH_TO_FO_ANAG_ISCRIZIONE_ORDINE_VISUALIZZA : PATH_TO_FO_ANAG_ISCRIZIONE_ORDINE_MODIFICA,
            })
            this.setState({ activeTab: this.state.activeTab + 1 })
          }
        })
        .catch((error) => {
          setTimeout(async () => {
            this.setState({ message: error.message, typeMessage: 'danger' })
          }, 1000)
        })
    }
  }

  //Procedi allo step precedente
  previousStep = async () => {
    breadcrumbOptions.pop()
    await this.setState({ activeTab: this.state.activeTab - 1 })
  }

  //Ottiene tutti i valori delle select riguardanti la sezione Dati Anagrafici
  getSelectValues = async (fields: any) => {
    await this.setState({ fields: fields, data: fields })
  }

  //se cambia la provincia di residenza o sede legale setta a true il flag così da poter svuotare il campo dell'ordine territoriale
  getFlagSvuotaOrdine = (flagSvuotaOrdine: boolean) => {
    this.setState({ flagSvuotaOrdine })
  }

  inviaIscrizione = async () => {
    if (this.state.activeTab === linkTab.length) {
      /************************** Chiamata per l'invio finale dell'iscrizione ******************************/
      await recuperoIscrizioneInCompilazioneByCf(TokenStore.getInstance().getDecodedTokenJWT().fiscalNumber, this.state.tipologiaIscrizione, 'PF')
        .then(async (response: any) => {
          var iscrizioneResponse = response
          if (response && response.data && response.data.httpStatus !== 200) {
            if (response.data.httpStatus === 404) {
              await recuperoIscrizioneInattivaByCf(TokenStore.getInstance().getDecodedTokenJWT().fiscalNumber, this.state.tipologiaIscrizione, 'PF').then(
                (response) => {
                  if (response && response.data && response.data.httpStatus !== 200) {
                    throw new Error(response.data.error)
                  }
                  iscrizioneResponse = response
                }
              )
            } else {
              throw new Error(response.data.error)
            }
          }

          await inviaCompilazione(
            this.state.fields,
            iscrizioneResponse.data.returnedObject.iotDTO.idIscrizione,
            iscrizioneResponse.data.returnedObject.iotDTO.idSoggetto
          )
          this.setState({
            activeTab: this.state.activeTab,
            message: 'La tua richiesta è stata inviata con successo!',
            typeMessage: 'success',
          })
        })
        .catch((err) => {
          this.setState({ message: err.message, typeMessage: 'danger' })
        })

      /**Redirect alla Home */
      setTimeout(async () => {
        window.location.href = getHome()
      }, 1000)
    }
  }

  onSubmit = async (event) => {
    event.preventDefault()
    window.scrollTo({ top: 0, behavior: 'smooth' })
    var labelProvinciaResidenza = this.state.fields?.provinciaResidenza?.label
    var isProvinciaResidenzaEstera = labelProvinciaResidenza?.toLowerCase() === 'stato estero' || labelProvinciaResidenza?.toLowerCase() === 'stato estero'
    var errorRequired: any = inputRequiredValidation(
      this.state.errors.filter((e) => !e.isDisabled && !e.isHidden && e.stepTab === this.state.activeTab) || this.state.errors.length === 0
    )

    if (isProvinciaResidenzaEstera && this.state.fields?.sedeLegaleStudio?.label === 'No' && this.state.activeTab === 3 && !this.props.isVisualizza) {
      return this.setState({ message: 'Selezionare Si nel campo "Sede legale diversa dalla residenza"', typeMessage: 'danger' })
    }

    if (
      isProvinciaResidenzaEstera &&
      this.state.fields?.provinciaStudio?.label?.toLowerCase() === 'stato estero' &&
      this.state.activeTab === 3 &&
      !this.props.isVisualizza
    ) {
      return this.setState({ message: 'Selezionare una provincia italiana', typeMessage: 'danger' })
    }

    if (errorRequired) {
      event.preventDefault()
      return this.setState({ message: errorRequired.messaggio, typeMessage: errorRequired.type })
    } else {
      this.nextStep()
      this.setState({ message: '' })
    }
  }

  hasFormErrors = (alert) => {
    if (this.props.isVisualizza) return
    var findItem = this.state.errors.find((a) => a.id === alert.id)
    var errorID = this.state.errors.indexOf(findItem)
    if (findItem) {
      if (alert.isHidden)
        this.state.errors[errorID] = {
          id: alert.id,
          isDisabled: alert.isDisabled,
          isHidden: alert.isHidden,
          stepTab: alert.stepTab,
          value: alert.value,
          label: alert.label,
        }
      else
        this.state.errors[errorID] = {
          error: alert.hasError,
          id: alert.id,
          isDisabled: alert.isDisabled,
          isHidden: alert.isHidden,
          stepTab: alert.stepTab,
          value: alert.value,
          label: alert.label,
        }
    } else {
      this.state.errors.push({
        error: alert.hasError,
        id: alert.id,
        isDisabled: alert.isDisabled,
        isHidden: alert.isHidden,
        stepTab: alert.stepTab,
        value: alert.value,
        label: alert.label,
      })
    }
  }

  render() {
    const { typeMessage, isSubmitted, activeTab, processoIscrizione, statoIscrizione, integrazioni, isRichiestaIntegrazioni, isVisualizza } = this.state

    const tabClick = (tab) => {
      this.setState({ activeTab: tab })
    }
    const listBodyTab = [
      {
        id: 1,
        body:
          activeTab === 1 ? (
            <DatiAnagrafici
              key={'1'}
              visualizzaFlag={isVisualizza}
              data={this.state.fields}
              activeTab={activeTab}
              processoIscrizione={processoIscrizione}
              getSelectedValue={this.getSelectValues}
              tabNumber={1}
              message={this.hasFormErrors}
              statoIscrizione={statoIscrizione}
            />
          ) : null,
      },
      {
        id: 2,
        body:
          activeTab === 2 ? (
            <DatiResidenziali
              key={'2'}
              visualizzaFlag={isVisualizza}
              data={this.state.fields}
              activeTab={activeTab}
              processoIscrizione={processoIscrizione}
              getSelectedValue={this.getSelectValues}
              tabNumber={2}
              message={this.hasFormErrors}
              getFlagSvuotaOrdine={this.getFlagSvuotaOrdine}
            />
          ) : null,
      },
      {
        id: 3,
        body:
          activeTab === 3 ? (
            <SedeLegale
              key={'3'}
              visualizzaFlag={isVisualizza}
              data={this.state.fields}
              activeTab={activeTab}
              processoIscrizione={processoIscrizione}
              getSelectedValue={this.getSelectValues}
              tabNumber={3}
              message={this.hasFormErrors}
              getFlagSvuotaOrdine={this.getFlagSvuotaOrdine}
            />
          ) : null,
      },
      {
        id: 4,
        body:
          activeTab === 4 ? (
            <AccessoProfessione
              key={'4'}
              visualizzaFlag={isVisualizza}
              data={this.state.fields}
              activeTab={activeTab}
              processoIscrizione={processoIscrizione}
              getSelectedValue={this.getSelectValues}
              tabNumber={4}
              message={this.hasFormErrors}
              statoIscrizione={statoIscrizione}
              flagSvuotaOrdine={this.state.flagSvuotaOrdine}
              getFlagSvuotaOrdine={this.getFlagSvuotaOrdine}
            />
          ) : null,
      },
      {
        id: 5,
        body:
          activeTab === 5 ? (
            <Professione
              key={'5'}
              visualizzaFlag={isVisualizza}
              data={this.state.fields}
              activeTab={activeTab}
              processoIscrizione={processoIscrizione}
              getSelectedValue={this.getSelectValues}
              tabNumber={5}
              message={this.hasFormErrors}
            />
          ) : null,
      },

      {
        id: 6,
        body:
          activeTab === 6 ? (
            <IscrizioneEPAP
              key={'6'}
              visualizzaFlag={isVisualizza}
              data={this.state.fields}
              activeTab={activeTab}
              processoIscrizione={processoIscrizione}
              getSelectedValue={this.getSelectValues}
              tabNumber={6}
              message={this.hasFormErrors}
            />
          ) : null,
      },
    ]

    // Nel caso di visualizza cambiano gli ultimi due step
    if (!this.props.isVisualizza) {
      listBodyTab.push({
        id: 7,
        body: (
          <Documenti
            key={'7'}
            visualizzaFlag={isVisualizza}
            data={this.state.fields}
            activeTab={activeTab}
            processoIscrizione={processoIscrizione}
            getSelectedValue={this.getSelectValues}
            tabNumber={7}
            message={this.hasFormErrors}
            statoIscrizione={statoIscrizione}
          />
        ),
      })
      listBodyTab.push({
        id: 8,
        body: (
          <Invio
            key={'8'}
            visualizzaFlag={isVisualizza}
            iscrizioneGiaInviata={this.state.statoIscrizione === 3 || this.state.statoIscrizione === 4}
            data={this.state.fields}
            activeTab={activeTab}
            processoIscrizione={processoIscrizione}
            inviaIscrizione={this.inviaIscrizione}
            tabNumber={8}
            message={this.hasFormErrors}
          />
        ),
      })
    } else {
      listBodyTab.push({
        id: 7,
        body: <DatiIscrizione key={'7'} visualizzaFlag={true} data={this.state.fields} tabNumber={7} />,
      })
      listBodyTab.push({
        id: 8,
        body: (
          <Documenti
            key={'8'}
            visualizzaFlag={true}
            data={this.state.fields}
            activeTab={activeTab}
            processoIscrizione={processoIscrizione}
            getSelectedValue={this.getSelectValues}
            tabNumber={8}
            message={this.hasFormErrors}
          />
        ),
      })
    }

    return (
      <>
        {this.state.processoIscrizione && this.state.isLoaded ? (
          <div className={isCurrentMobileDevice() ? 'container register-container-zero-padding' : 'container register-container'}>
            <GenericBreadCrumb paths={breadcrumbOptions} />
            <h1>
              {title + ' Step '}
              {activeTab}
            </h1>
            <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
              <GenericChips color={'default'} label={'Stato iscrizione: ' + getStatoById(statoIscrizione).toUpperCase()} />
            </div>
            <GenericAlert
              key="alertIntegrazioni"
              hidden={!isRichiestaIntegrazioni}
              label={
                <>
                  <b>Integrazioni richieste: </b>
                  {integrazioni}
                </>
              }
              color={'info'}
            ></GenericAlert>
            <div className="justify-content-center p-0">
              <GenericTab
                isClickable={this.props.isVisualizza}
                tabClick={this.props.isVisualizza ? tabClick : null}
                key={'genericTabIscrizione'}
                hiddenAlert={this.state.message === ''}
                alertTypeMessage={typeMessage}
                labelAlert={this.state.message}
                isSubmitted={isSubmitted}
                onSubmit={this.onSubmit}
                listBodyTab={listBodyTab}
                listNavLink={linkTab}
                activeTab={activeTab}
                previousStep={this.previousStep}
              ></GenericTab>
              <div hidden={this.state.message !== '' && this.state.typeMessage === 'danger'}>{listLegenda}</div>
            </div>
          </div>
        ) : this.state.message === '' ? (
          <div className="container register-container">
            <GenericSpinner></GenericSpinner>
          </div>
        ) : (
          <div className="container register-container p-5">
            <GenericAlert
              key="alertIscrizione"
              label={
                <>
                  {this.state.message + '. Torna alla '}
                  <a href={getHome()} className="alert-link">
                    home page
                  </a>
                </>
              }
              color={'danger'}
            ></GenericAlert>
          </div>
        )}
      </>
    )
  }
}
export declare interface ModificaDatiProps {
  activeTab?: any
  idIscrizione?: any
}

export default IscrizioneOrdine

export const FillUserModel = (response) => {
  var soggetto = response.data.returnedObject.soggettoDTO
  var iot = response.data.returnedObject.iotDTO
  var provinciaNascita = response.data.returnedObject.provinciaNascita
  var provinciaResidenza = response.data.returnedObject.provinciaResidenza
  var provinciaSedeLegale = response.data.returnedObject.provinciaSedeLegale
  var ordineTerritorialeCompetente = response.data.returnedObject.ordineTerritorialeCompetente
  var sedeLegale = response.data.returnedObject.sedeLegale
  var tipoLaurea = response.data.returnedObject.tipoLaurea
  var classeLaurea = response.data.returnedObject.classeLaurea
  var sezione = response.data.returnedObject.sezione
  var titoloRichiesto = response.data.returnedObject.titoloRichiesto
  var tipologiaAbilitazione = response.data.returnedObject.tipologiaAbilitazione
  var documentoRiconoscimento = response.data.returnedObject.soggettoDocumentoDTO
  let userModel: UserModel = {
    nome: soggetto ? soggetto.nome : null,
    cognome: soggetto ? soggetto.cognome : null,
    codiceFiscale: soggetto ? soggetto.cf : null,
    sesso: soggetto ? soggetto.sesso : null,
    annoAbilitazione: iot ? iot.annoAbilitazione : null,
    annoLaurea: iot ? iot.annoDiLaurea : null,
    ateneoAbilitazione: iot ? iot.ateneoAbilitazione : null,
    tipoLaurea:
      tipoLaurea && tipoLaurea.tipoLaurea && iot.codiceTipoLaurea
        ? {
            label: tipoLaurea.tipoLaurea,
            value: iot.codiceTipoLaurea,
          }
        : null,
    classeLaurea:
      classeLaurea && classeLaurea.descrizione && iot.codiceClasseLaurea
        ? {
            label: classeLaurea.descrizione,
            value: iot.codiceClasseLaurea,
          }
        : null,
    sezioneRichiesta:
      sezione && sezione.descrizione && sezione.idSezione
        ? {
            label: sezione.descrizione,
            codice: sezione.codiceSezione,
          }
        : null,
    titoloRichiesto:
      titoloRichiesto && titoloRichiesto.titoloEsteso && titoloRichiesto.idTitolo
        ? {
            label: titoloRichiesto.titoloEsteso,
            value: titoloRichiesto.idTitolo,
          }
        : null,
    condanna: getBigIntToConvertToBoolean(iot ? iot.condanne : null),
    condotta: getBigIntToConvertToBoolean(iot ? iot.condotta : null),
    dirittiCivili: getBigIntToConvertToBoolean(iot ? iot.dirittiCivili : null),
    corsoLaurea: iot ? iot.corsoLaureaSpecificato : null,
    dataRiconoscimentoTitolo: iot ? iot.dataAbilitazioneDecretoEstero : null,
    dataIscrizioneEpap: iot ? iot.dataIscrizioneEpap : null,
    dataRiconoscimento: iot ? iot.dataRiconoscimentoQualificaProfessionale : null,
    decretoRiconoscimentoQualificaProfessionale: iot ? iot.decretoRiconoscimentoQualificaProfessionale : null,
    decretoRiconoscimentoTitoloEstero: iot ? getBigIntToConvertToBoolean(iot.flagAbilitazioneConDecretoEstero) : null,
    flagAbilitazioneConDecretoEstero: {
      label: conversioneIntToSiNo(iot.flagAbilitazioneConDecretoEstero),
      value: getBigIntToConvertToBoolean(iot.flagAbilitazioneConDecretoEstero),
    },
    statutoEnte: iot ? iot.fileStatutoEnteAtto : null,
    dipendentePrivato: {
      label: conversioneIntToSiNo(iot ? iot.flagDipendentePrivato : null),
      value: iot ? iot.flagDipendentePrivato : null,
    },
    flagSocioDipendenteStpPrivato: {
      label: conversioneIntToSiNo(iot ? iot.flagSocioDipendenteStpPrivato : null),
      value: iot ? iot.flagSocioDipendenteStpPrivato : null,
    },
    ordinamentoAmministrazione: {
      label: iot ? convertFlagLiberaProfPubblicaAmministrazioneToString(iot.flagLiberaProfPubblicaAmministrazione) : null,
      value: iot ? iot.flagLiberaProfPubblicaAmministrazione : null,
    },
    dipendenteStp: {
      label: conversioneIntToSiNo(iot ? iot.flagDipendenteStp : null),
      value: iot ? iot.flagDipendenteStp : null,
    },
    nonEsercenteAttivita: {
      label: conversioneIntToSiNo(iot ? iot.flagIncompatibilitaEsercenteAttivitaProfessionale : null),
      value: iot ? iot.flagIncompatibilitaEsercenteAttivitaProfessionale : null,
    },
    iscrittoEpap: {
      label: conversioneIntToSiNo(iot ? iot.flagIscrizioneEpap : null),
      value: iot ? iot.flagIscrizioneEpap : null,
    },
    dipendenteStatoOAltro: {
      label: iot ? conversioneIntToSiNo(iot.flagDipendentePubblicaAmministrazione) : null,
      value: iot ? iot.flagDipendentePubblicaAmministrazione : null,
    },
    liberoProfessionista: {
      label: conversioneIntToSiNo(iot ? iot.flagLiberoProfessionista : null),
      value: iot ? iot.flagLiberoProfessionista : null,
    },
    professionistaFormaAssociata: {
      label: conversioneIntToSiNo(iot ? iot.flagLiberoProfessionistaInFormaAssociata : null),
      value: iot ? iot.flagLiberoProfessionistaInFormaAssociata : null,
    },
    socioStp: {
      label: conversioneIntToSiNo(iot ? iot.flagSocioStp : null),
      value: iot ? iot.flagSocioStp : null,
    },
    flagRichSmartCard: {
      label: conversioneIntToSiNo(iot ? iot.flagRichSmartCard : null),
      value: iot ? iot.flagRichSmartCard : null,
    },
    flagRichBusinessKey: {
      label: conversioneIntToSiNo(iot ? iot.flagRichBusinessKey : null),
      value: iot ? iot.flagRichBusinessKey : null,
    },
    flagRichPec: {
      label: conversioneIntToSiNo(iot ? iot.flagRichPec : null),
      value: iot ? iot.flagRichPec : null,
    },
    sedeLegaleStudio: {
      label: conversioneIntToSiNo(iot ? iot.flagSedeLegaleDiversoDaProvinciaResidenza : null),
      value: iot ? iot.flagSedeLegaleDiversoDaProvinciaResidenza : null,
    },
    foto: iot ? iot.foto : null,
    idIscrizione: iot ? iot.idIscrizione : null,
    idSoggetto: iot ? iot.idSoggetto : null,
    matricolaEpap: iot ? iot.matricolaEpap : null,
    idOrdine:
      ordineTerritorialeCompetente && ordineTerritorialeCompetente.nomeOrdine && ordineTerritorialeCompetente.codiceOrdine
        ? {
            label: ordineTerritorialeCompetente.nomeOrdine,
            value: ordineTerritorialeCompetente.codiceOrdine,
          }
        : '',
    tipologiaAbilitazione:
      iot && iot.tipologiaAbilitazione
        ? {
            label: tipologiaAbilitazione.titoloEsteso,
            value: tipologiaAbilitazione.idTitolo,
          }
        : null,
    universita: iot ? iot.universitaLaurea : null,
    cittaNascita: soggetto ? soggetto.cittaNascita : null,
    provinciaNascita: {
      label: provinciaNascita ? provinciaNascita.denominazioneUnitaTerritorialeSovracomunale : null,
      value: provinciaNascita ? provinciaNascita.provinciaNascitaSigla : null,
    },
    cittadinanza: {
      label: soggetto ? soggetto.cittadinanza : null,
      value: soggetto ? soggetto.cittadinanza : null,
    },
    scadenza_permesso_soggiorno: soggetto?.scadenzaPermessoSoggiorno ? soggetto.scadenzaPermessoSoggiorno : null,
    provinciaResidenza:
      provinciaResidenza && provinciaResidenza.denominazioneUnitaTerritorialeSovracomunale && provinciaResidenza.codiceOrdineCompetente
        ? {
            label: provinciaResidenza.denominazioneUnitaTerritorialeSovracomunale,
            value: provinciaResidenza.siglaAutomobilistica,
          }
        : null,
    comune: {
      label: soggetto ? soggetto.comuneResidenza : null,
      value: soggetto ? soggetto.comuneResidenza : null,
    },
    indirizzo: soggetto ? soggetto.indirizzoResidenza : null,
    numeroCivico: soggetto ? soggetto.numeroCivico : null,
    cap: soggetto ? soggetto.capResidenza : null,
    emailOrdinaria: soggetto ? soggetto.emailOrdinaria : null,
    pec: soggetto ? soggetto.pec : null,
    telefono: soggetto ? soggetto.telefono : null,
    altroTelefono: soggetto ? soggetto.altroTelefono : null,
    autocertificazione: soggetto ? soggetto.socFlagAutocertificazioneDati : null,
    comprensioneAdempimenti: soggetto ? soggetto.comprensioneAdempimenti : null,
    privacy: getBigIntToConvertToBoolean(soggetto ? soggetto.consensoPrivacy : null),

    indirizzoSedeLegaleStudio: sedeLegale ? sedeLegale.slIndirizzoStudio : null,
    numeroCivicoSedeLegaleStudio: sedeLegale ? sedeLegale.slNumeroCivicoStudio : null,
    provinciaStudio: {
      label: provinciaSedeLegale ? provinciaSedeLegale.denominazioneUnitaTerritorialeSovracomunale : null,
      value: sedeLegale ? sedeLegale.slProvinciaStudio : null,
    },
    cittaSedeLegaleStudio: {
      label: sedeLegale ? sedeLegale.slCittaStudio : null,
      value: sedeLegale ? sedeLegale.slCittaStudio : null,
    },
    capSedeLegaleStudio: sedeLegale ? sedeLegale.slCapStudio : null,
    telefonoSedeLegaleStudio: sedeLegale ? sedeLegale.slTelefonoStudio : null,
    faxSedeLegaleStudio: sedeLegale ? sedeLegale.slFaxStudio : null,
    mailSedeLegaleStudio: sedeLegale ? sedeLegale.slMailStudio : null,
    pecSedeLegaleStudio: sedeLegale ? sedeLegale.slPecStudio : null,
    sitoInternet: sedeLegale ? sedeLegale.sitoInternet : null,
    facebook: sedeLegale ? sedeLegale.facebook : null,
    twitter: sedeLegale ? sedeLegale.twitter : null,

    numeroIscrizione: iot ? iot.numeroIscrizioneOrdine : null,
    dataIscrizione: iot ? iot.dataIscrizioneOrdine : null,
    deliberaIscrizione: iot ? iot.deliberaIscrizione : null,
    numeroTimbro: iot ? iot.numeroTimbro : null,
    dataInizValiTimbro: iot ? iot.dataInizioValiTimbro : null,
    dataFineValiTimbro: iot ? iot.dataFineValiTimbro : null,
    numeroMartello: iot ? iot.numeroMartello : null,
    dataInizioValiMartello: iot ? iot.dataInizioValiMartello : null,
    dataFineValiMartello: iot ? iot.dataFineValiMartello : null,
    esoneroPagaQuotaOrdine: {
      label: conversioneIntToSiNo(iot ? iot.flagEsoneratoPagamentoQuotaIscrizione : null),
      value: iot ? iot.flagEsoneratoPagamentoQuotaIscrizione : null,
    },
    anzianitaSezioneA: soggetto.anzianitaSezioneA ? soggetto.anzianitaSezioneA : 0,
    anzianitaSezioneB: soggetto.anzianitaSezioneB ? soggetto.anzianitaSezioneB : 0,
    anzianita: soggetto ? soggetto.anzianitaSezioneA + soggetto.anzianitaSezioneB : null,
    uploadFiles: iot.elencoAllegatiIscrizione ? iot.elencoAllegatiIscrizione : [],
    tipoDocumento: {
      label: documentoRiconoscimento ? documentoRiconoscimento.tipoDocumento : null,
      value: documentoRiconoscimento ? documentoRiconoscimento.idTipoDocumento : null,
    },
    numeroDocumento: documentoRiconoscimento ? documentoRiconoscimento.numeroDocumento : null,
    emessoDa: documentoRiconoscimento ? documentoRiconoscimento.emessoDa : null,
    emessoIl: documentoRiconoscimento ? documentoRiconoscimento.emessoIl : null,
    scadenzaIl: documentoRiconoscimento ? documentoRiconoscimento.scadenzaIl : null,
  }
  return userModel
}

//questo metodo vierne chiamato allo step 7 dell'iscrizione e controlla che ci siano almeno 1 bollo, 1 tassa ed 1 quota di iscrizione
function validaPagamenti(files: any) {
  var countBollo = 0
  var countTassa = 0
  var countQuota = 0
  files.map((e) => {
    if (e.codiceTipoAllegato === 'bollo_iot' && e.cancellato !== true) countBollo++
    if (e.codiceTipoAllegato === 'quota_iscrizione_iot' && e.cancellato !== true) countQuota++
    if (e.codiceTipoAllegato === 'tassa_iot' && e.cancellato !== true) countTassa++
  })

  var msg = ''
  if (countQuota === 0) {
    msg = "quota d'iscrizione"
  }
  if (countTassa === 0) {
    if (msg === '') msg = 'tassa'
    else msg = msg + ', tassa'
  }
  if (countBollo === 0) {
    if (msg === '') msg = 'bollo'
    else msg = msg + ', bollo'
  }
  if (msg !== '') {
    msg = 'Pagamenti mancanti: ' + msg
    throw new Error(msg)
  }
}
