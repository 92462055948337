import {Form, Label} from 'design-react-kit'
import React from 'react'
import GenericSpinner from 'src/backoffice/app/components/GenericSpinner'
import GenericCheckbox from 'src/frontend/app/components/GenericCheckbox'
import GenericInput from 'src/frontend/app/components/GenericInput'
import GenericUploadFront from 'src/frontend/app/components/GenericUpload'
import {TokenStore} from 'src/keycloak/jwt/TokenStore'
import {getSoggettoByCf} from 'src/processes/Soggetto'
import {getBigIntToConvertToBoolean} from 'src/utilities/utility'
import {isCurrentMobileDevice} from 'src/utilities/deviceUtility'

/* ----------------------------------- VARIABILI E COSTANTI ----------------------------------------- */
const labelCheckboxPrivacy =
  'Dichiaro di essere informato, ai sensi e per gli effetti dell’art. 13 del D.Lgs. 196/2003 e all’art. 13 GDPR 679/16, che i dati personali raccolti saranno trattati, anche con strumenti informatici, esclusivamente nell’ambito del procedimento per il quale la presente dichiarazione viene resa.'
const statoCompilazione = 1
/* -------------------------------------------------------------------------------------------------- */

export class FormDatiRappresentanteLegaleStpFO extends React.Component<GeneralFormDatiRappresentanteLegaleStpFOProps, any> {
  constructor(props) {
    super(props)
    this.state = {
      dataLoaded: false,
    }
  }

  async componentDidMount(): Promise<void> {
    var fileName = this.props.listaFiles?.filter((e) => e.codiceTipoAllegato === 'mandato_stp')[0]?.filename
    var idAttachment = this.props.listaFiles?.filter((e) => e.codiceTipoAllegato === 'mandato_stp')[0]?.idAttachment
    var isResponsabilePropriaSTP = await TokenStore.getInstance().isOperatoreResponsabilePropriaSTP()
    await getSoggettoByCf(this.props.datiIscrizione?.soggettoDTO?.codiceFiscaleResponsabileStp).then((response) => {
      if (response && response.data) {
        this.setState({ soggettoDTO: response.data[0] })
      }
    })
    this.setState({
      datiIscrizione: this.props.datiIscrizione,
      fileName: fileName,
      idAttachment: idAttachment,
      dataLoaded: true,
      isResponsabilePropriaSTP: isResponsabilePropriaSTP,
    })
  }

  handleChange = (event, fieldId, type) => {
    if (type === 'input' && event.target.id === fieldId) this.props.formData({ id: fieldId, value: event.target.value })
    else if (type === 'checkbox' && event.target.id === fieldId) this.props.formData({ id: fieldId, value: event.target.checked })
  }

  render() {
    const { tabNumber, flagReiscrizione } = this.props
    const { datiIscrizione, fileName, idAttachment, dataLoaded, isResponsabilePropriaSTP, soggettoDTO } = this.state
    const getDatiMandato = (fileData) => {
      this.props.fileMandato(fileData)
    }

    let fiscalNumber
    let given_name
    let family_name
    let genere
    let dataNascita

    //Se l'utente corrente è rappresentante legale setta i dati dal token altrimenti li recupera dalla chiamata getSoggettoByCf
    if (isResponsabilePropriaSTP) {
      const keyData = TokenStore.getInstance().getDecodedTokenJWT()
      fiscalNumber = keyData.fiscalNumber
      given_name = keyData.given_name
      family_name = keyData.family_name
      genere = keyData.spid_gender
      dataNascita = keyData.spid_dateOfBirth
    } else {
      fiscalNumber = soggettoDTO?.cf
      given_name = soggettoDTO?.nome
      family_name = soggettoDTO?.cognome
      genere = soggettoDTO?.sesso
      dataNascita = soggettoDTO?.dataNascita
    }

    return (
      <>
        {dataLoaded === true ? (
          <div>
            <Form>
              {/* ---------------------------------------------------------------------- DATI RECUPERATI DALLO SPID ----------------------------------------------------------------*/}
              <div className="row">
                <div className={isCurrentMobileDevice() ? null : 'col-6'}>
                  <GenericInput id={'idNome'} type={'text'} placeholder={'Nome'} label={'Nome**'} value={given_name} isReadOnly isRequired />
                </div>
                <div className={isCurrentMobileDevice() ? null : 'col-6'}>
                  <GenericInput id={'idCognome'} type={'text'} placeholder={'Cognome'} label={'Cognome**'} value={family_name} isReadOnly isRequired />
                </div>
              </div>
              <div className="row">
                <div className={isCurrentMobileDevice() ? null : 'col-6'}>
                  <GenericInput
                    id={'idCodiceFiscale'}
                    type={'text'}
                    placeholder={'Codice fiscale'}
                    label={'Codice fiscale**'}
                    value={fiscalNumber}
                    isReadOnly
                    isRequired
                  />
                </div>
                <div className={isCurrentMobileDevice() ? null : 'col-6'}>
                  <GenericInput id={'idSesso'} type={'text'} placeholder={'Sesso'} label={'Sesso**'} value={genere} isReadOnly isRequired />
                </div>
              </div>
              <div className="row">
                <div className={isCurrentMobileDevice() ? null : 'col-6'}>
                  <GenericInput
                    id={'idDataNascita'}
                    type={'date'}
                    placeholder={'Data di nascita'}
                    label={'Data di nascita**'}
                    value={dataNascita}
                    isReadOnly
                    isRequired
                  />
                </div>
              </div>
              {/* ------------------------------------------------------------------------------------------------------------------------------------------------------------------*/}
              <div>
                <div className="row">
                  <div className={isCurrentMobileDevice() ? null : 'col-6'}>
                    <GenericInput
                      key={'idEmailOrdinaria'}
                      defaultValue={flagReiscrizione === 1 ? null : datiIscrizione?.soggettoDTO?.emailOrdinaria}
                      errorMessageAlert={this.props.message}
                      id={'idEmailOrdinaria'}
                      type={'email'}
                      placeholder={'Email ordinaria'}
                      label={'Email ordinaria*'}
                      onChange={(e) => this.handleChange(e, 'idEmailOrdinaria', 'input')}
                      currentStep={tabNumber}
                      isRequired
                      isDisabled={this.props.statoIscrizione !== statoCompilazione}
                    />
                  </div>
                  <div className={isCurrentMobileDevice() ? null : 'col-6'}>
                    <GenericInput
                      defaultValue={flagReiscrizione === 1 ? null : datiIscrizione?.soggettoDTO?.pec}
                      errorMessageAlert={this.props.message}
                      id={'idPec'}
                      type={'email'}
                      placeholder={'PEC'}
                      label={'PEC*'}
                      onChange={(e) => this.handleChange(e, 'idPec', 'input')}
                      currentStep={tabNumber}
                      isRequired
                      isDisabled={this.props.statoIscrizione !== statoCompilazione}
                    />
                  </div>
                </div>
                <div className="row">
                  <Label for="mandatoID">
                    <strong>Mandato*</strong>
                  </Label>
                </div>
                <div className="row">
                  <div className={isCurrentMobileDevice() ? null : 'col-6'}>
                    <GenericUploadFront
                      name={'Mandato'}
                      datiAllegato={getDatiMandato}
                      value={fileName}
                      idAttachment={idAttachment}
                      tipologiaAllegato={'mandato_stp'}
                      acceptedExtensionFile={['pdf']}
                      errorMessageAlert={this.props.message}
                      isRequired
                      id={'idFileMandato'}
                      currentStep={tabNumber}
                      isDisabled={this.props.statoIscrizione !== statoCompilazione}
                      hideResetFile={this.props.statoIscrizione !== statoCompilazione}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col mb-4">
                    <GenericCheckbox
                      title={'Consenso privacy'}
                      defaultChecked={flagReiscrizione === 1 ? null : getBigIntToConvertToBoolean(datiIscrizione?.soggettoDTO?.consensoPrivacy)}
                      errorMessageAlert={this.props.message}
                      id={'idConsensoPrivacy'}
                      label={'* ' + labelCheckboxPrivacy}
                      onChange={(e) => this.handleChange(e, 'idConsensoPrivacy', 'checkbox')}
                      currentStep={tabNumber}
                      isRequired
                      isDisabled={this.props.statoIscrizione !== statoCompilazione}
                    />
                  </div>
                </div>
              </div>
            </Form>
          </div>
        ) : (
          <GenericSpinner />
        )}
      </>
    )
  }
}

export declare interface GeneralFormDatiRappresentanteLegaleStpFOProps {
  message?: any
  tabNumber?: number
  formData?: any
  fileMandato?: any
  isVisualizza?: boolean
  datiIscrizione?: any
  provinciaSedeLegale?: any
  listaFiles?: any
  statoIscrizione?: any
  flagReiscrizione?: any
}
