import {StringParam} from '@parsec/msga'
import {getAnagrafeResourcesApi, getDefaultAxiosConfig} from 'src/app/utils/ManagerRestGateway'

/**
 * Get Comuni by Provincia
 * @returns
 * @param provincia
 */
export async function getComuniByProvinciaMsga(provincia: any){
  try {
    if (provincia === undefined || provincia === null)
      return [];

    var param: StringParam = {
      "param": provincia
    }
    var response: any;
    response = await (await getAnagrafeResourcesApi().msgaAnagrafeComuniByProvinciaPost(param, getDefaultAxiosConfig()));

    let comuni = response.data.map((comune:any, id) => {
      return {
        label: comune['denominazione'],
        value: comune['denominazione']
      };
    });

    return comuni;
  } catch (err: any) {
    console.error("Errore durante la chiamata msgaAnagrafeComuniByProvinciaPost - " + err);
    throw new Error("Errore durante la chiamata msgaAnagrafeComuniByProvinciaPost - " + err);
  }
}
