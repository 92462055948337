import * as React from 'react'
import {PageSection} from '@patternfly/react-core'
import GenericCard from '../../components/GenericCard'
import GenericButton from '../../components/GenericButton'
import GenericBreadCrumb from 'src/frontend/app/components/GenericBreadCrumb'
import {
    FormIscrizioneOperatoreOrdineTerritoriale
} from './formIscrizioneOperatoreOrdineTerritoriale/FormIscrizioneOperatoreOrdineTerritoriale'
import GenericTitle from '../../components/GenericTitle'
import GenericAlert from '../../components/GenericAlert'
import {getHome, goTo} from 'src/utilities/utility'
import {PATH_TO_FO_ANAG_ISCRIZIONE_OOT, PATH_TO_FO_ANAG_RICHIEDI_NUOVO_PROFILO} from 'src/app/utils/RoutesConstants'

/* ----------------------------------- VARIABILI E COSTANTI ----------------------------------------- */
const title = 'Iscrizione operatore ordine territoriale'

var breadcrumbOptions = [
    { label: "Richiedi nuovo profilo", link: PATH_TO_FO_ANAG_RICHIEDI_NUOVO_PROFILO },
    { label: "Iscrizione operatore ordine territoriale", link: PATH_TO_FO_ANAG_ISCRIZIONE_OOT }
]

const legenda = ([
    { title: 'Legenda', Size: 'h2' },
    { title: '* Campo obbligatorio', Size: 'p' },
])

const listLegenda =
    [
        <div key={'legenda'} className="Card">
            {legenda.map((title, i, Size) => {
                return (<GenericTitle key={i} title={legenda[i].title} Size={legenda[i].Size} />)
            })}
        </div>
    ]
/* -------------------------------------------------------------------------------------------------- */

class IscrizioneOperatoreOrdineTerritoriale extends React.Component<any, any> {
    constructor(props) {
        super(props);
        this.state = {
            message: "",
            typeMessage: "default"
        };
    }

    submitMessageResult = (alert: any) => {
        const element = document.getElementById("pageSectionId");
        if (element)
            element.scrollIntoView({ behavior: 'smooth' });
        this.setState({ message: alert.messaggio, typeMessage: alert.type })
        if (alert.type === 'success') {
            setTimeout(async () => {
                goTo(getHome())
            }, 1000);
        }
    }

    onSubmit = (submitted: boolean) => {
        this.setState({ isSubmitted: submitted })
    }

    render() {
        const { typeMessage, message, isSubmitted } = this.state
        return (
            <>
                <PageSection id='pageSectionId'>
                    <div className='container register-container'>
                        <GenericBreadCrumb paths={breadcrumbOptions}></GenericBreadCrumb>
                        <div><h1>{title}</h1></div>
                        <GenericAlert hidden={message === ''} label={message} color={typeMessage}></GenericAlert>
                        <GenericCard cardHeader={'Compila i seguenti campi'} cardBody={<FormIscrizioneOperatoreOrdineTerritoriale messaggio={this.submitMessageResult} submitted={this.onSubmit} />} /><br></br>
                        <div className='row'>
                            <div className='col-1'>
                                <GenericButton label={"INDIETRO"} color={"tertiary"} onClickEvent={() => goTo(getHome())} />
                            </div>
                            <div className='col-1'>
                                <GenericButton form={"idIscrizioneOperatoreOrdine"} type={"submit"} label={"CONFERMA"} color={"primary"} isDisabled={isSubmitted} />
                            </div>
                        </div>
                        <div>{listLegenda}</div>
                    </div>
                </PageSection>
            </>
        );
    }
};

export default IscrizioneOperatoreOrdineTerritoriale;
