import {getAnagrafeResourcesApi, getDefaultAxiosConfig} from 'src/app/utils/ManagerRestGateway'

/**
 * GET delle cittadinanze
 * @returns
 */
export async function getCittadinanze() {
  try {
    const response = await getAnagrafeResourcesApi().msgaAnagrafeCittadinanzeGet(getDefaultAxiosConfig());
    return response;
  } catch (err) {
    console.error("Errore durante la chiamata msgaAnagrafeCittadinanzeGet - " + err);
    throw new Error("Errore durante la chiamata msgaAnagrafeCittadinanzeGet - " + err);
  }
}
