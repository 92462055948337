import React from 'react'
import {STATI} from 'src/model/PianiFormativi'
import moment from 'moment'
import PofStatusIntegrazioni from './PofStatusIntegrazioni'
import PofStatusPianoVerificato from './PofStatusPianoVerificato'

const StatusPof:React.FunctionComponent<StatusPofPops> = (props) => {
  if(props.startAction && props.startAction === 4) {
    return <PofStatusIntegrazioni pof={props.pof} isSegnala={true} />
  } else {
    switch (props.pof.stato){
      case STATI.NON_COMPLETA:
        return (
          <div className={"status"}>Da completare</div>
        )
      case STATI.PRESENTATA:
        const dataPresentazione = props.pof.data_di_presentazione || props.pof.data_modifica;
        return (
          <div className={"status"}>In approvazione. La richiesta è stata inviata il {moment(dataPresentazione).format("DD/MM/YYYY")} </div>
        )
      case STATI.RICHIESTA_INTEGRAZIONI:
        return <PofStatusIntegrazioni pof={props.pof} />
      case STATI.ACCETTATA:
        return <PofStatusPianoVerificato pof={props.pof} isApprovato={true} />
      case STATI.RIFIUTATA:
        return <PofStatusPianoVerificato pof={props.pof} isApprovato={false} />
    }
  }

  return null;
}


interface StatusPofPops {
  pof:any
  startAction?: number
}

export default StatusPof;
